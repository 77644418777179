export const ColorArray2: string[] = [
    '#0000ff',
    '#0300fc',
    '#0500fa',
    '#0800f7',
    '#0a00f5',
    '#0d00f2',
    '#0f00f0',
    '#1200ed',
    '#1500ea',
    '#1700e8',
    '#1a00e5',
    '#1c00e3',
    '#1f00e0',
    '#2100de',
    '#2400db',
    '#2700d8',
    '#2900d6',
    '#2c00d3',
    '#2e00d1',
    '#3100ce',
    '#3400cb',
    '#3600c9',
    '#3900c6',
    '#3b00c4',
    '#3e00c1',
    '#4000bf',
    '#4300bc',
    '#4600b9',
    '#4800b7',
    '#4b00b4',
    '#4d00b2',
    '#5000af',
    '#5200ad',
    '#5500aa',
    '#5800a7',
    '#5a00a5',
    '#5d00a2',
    '#5f00a0',
    '#62009d',
    '#64009b',
    '#670098',
    '#6a0095',
    '#6c0093',
    '#6f0090',
    '#71008e',
    '#74008b',
    '#760089',
    '#790086',
    '#7c0083',
    '#7e0081',
    '#81007e',
    '#83007c',
    '#860079',
    '#890076',
    '#8b0074',
    '#8e0071',
    '#90006f',
    '#93006c',
    '#95006a',
    '#980067',
    '#9b0064',
    '#9d0062',
    '#a0005f',
    '#a2005d',
    '#a5005a',
    '#a70058',
    '#aa0055',
    '#ad0052',
    '#af0050',
    '#b2004d',
    '#b4004b',
    '#b70048',
    '#b90046',
    '#bc0043',
    '#bf0040',
    '#c1003e',
    '#c4003b',
    '#c60039',
    '#c90036',
    '#cb0034',
    '#ce0031',
    '#d1002e',
    '#d3002c',
    '#d60029',
    '#d80027',
    '#db0024',
    '#de0021',
    '#e0001f',
    '#e3001c',
    '#e5001a',
    '#e80017',
    '#ea0015',
    '#ed0012',
    '#f0000f',
    '#f2000d',
    '#f5000a',
    '#f70008',
    '#fa0005',
    '#fc0003',
    '#ff0000',
];

export const ColorArray1: string[] = [
    '#FF0000',
    '#FF8B00',
    '#0800FF',
    '#70FF00',
    '#00FFC9',
    '#0080FF',
    '#F0D218',
    '#9E00FF',
    '#FF00B9',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
];

export const DateAliasIta: Object = {
    firstDayOfWeek: 1,
    dayNames: [
      'Domenica',
      'Lunedi',
      'Martedi',
      'Mercoledi',
      'Giovedi',
      'Venerdi',
      'Sabato',
    ],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    monthNames: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    monthNamesShort: [
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lug',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dic',
    ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk',
};