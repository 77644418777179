import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Utente } from 'src/app/MODELS/USER/utente';
import { EnvService, UsersService } from 'src/app/SERVICES/index';
import { TecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/tecnico.service';

@Component({
  selector: 'app-dettagli-tecnico',
  templateUrl: './dettagli-tecnico.component.html',
  styleUrls: ['./dettagli-tecnico.component.scss']
})
export class DettagliTecnicoComponent implements OnInit {

  @Output() cambia = new EventEmitter<boolean>();
  @Input() utenteInfo;
  loaded = false;
  dettaglioTecnico;

  constructor(
    private userService: UsersService,
    private tecnicoService: TecnicoService
  ) {

  }

  ngOnInit(): void {
    this.tecnicoService.getInfoTecnico(this.utenteInfo).subscribe((res) => {
      this.dettaglioTecnico =
      {
        nome: res.data.nomeTecnico,
        cognome: res.data.cognomeTecnico,
        email: res.data.emailTecnico,
        linkPhoto: res.data.linkThumbnail
      }
      this.loaded = true; 
    });
}

  indietro(chiudi: boolean) {//torna in gestione utenti

    this.cambia.emit(chiudi);

  }

}
