<div class="container" style="margin-left: 30px;">
    <div>
      <div>
          <div>
            <span style="margin-left: -5px;">Foto obbligatorie</span>
          <input type="checkbox" style="margin-left: 5px;" [checked]="chiediFoto" (change)="chiediFoto = !chiediFoto">
          <div  class="row">
            <label class="col-sm-2 label-on-left" style="margin-top: 31px; margin-right:auto">Distanza massima</label>
            <div class="col-sm-10">

                <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                  
                         
                    <mat-slider  thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="10" min="100"
                        max="500" [(ngModel)]="distanzaMassima" aria-label="units"></mat-slider>
                 
                    <small class="text-muted">Determina la massima distanza consentita rispetto alla prima posizione rilevata.</small>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
</div> <div><button class="btn btn-primary pull-right" (click)="applica()">Applica</button></div> 

