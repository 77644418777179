import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService, EnvService } from '../SERVICES/index';
import { AlertService } from '../SERVICES/GENERAL/alert.service';
import { ShareService } from '../SERVICES/GENERAL/share.service';
import { Router } from '@angular/router';
import { GoToClassroomService } from '../SERVICES/CLASSROOM/go-to-classroom.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private service: ShareService,
    private router: Router,
    private envService: EnvService,
    private injector: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let regex =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    if (
      regex.test(JSON.stringify(request.body).split('’').join("'")) ||
      regex.test(JSON.stringify(request.params).split('’').join("'"))
    ) {
      this.alertService.error('Non puoi inserire Emoji nei campi');
      return throwError('Errore Emoji');
    }
    return next.handle(request).pipe(
      catchError((err) => {
        // const isApiUrl = request.url.startsWith(this.env.apiUrl);
        // if (err.status === 401 && isApiUrl) {
        //   //this.accountService.logout();
        //   this.router.navigate(['logout']);
        // }
        if (err.status === 401) {
          //this.accountService.logout();
          this.router.navigate(['logout']);
        }

        if (err.status === 428) {
          this.service.changePassword(true);
        }

        if (this.envService.goTo && err.error) {
          const msg = err.error.data?.msg;
          if (err.status === 409 && msg) {
            const tokenMsg = msg.split(' ')[1];
            if (tokenMsg === 'invalido') {
              //0 PASSO
              //IL BE manda un errore, qui lo intercettiamo, dobbiamo quindi chiedere autenticazione a gotowebinar
              const goToService = this.injector.get(GoToClassroomService);
              goToService.setGoToToken();
            }
          }
        }

        if (err.status !== 500 && err.status !== 0 && err.status !== 404) {
          if (err.error) {
            if (
              err.error.status.description &&
              !this.alertService.isComingFromLogin
            ) {
              this.alertService.error(err.error.status.description);
            }
          }
        }

        if (err.status === 404) {
          err.error = { message: err };
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
