<div class="content" *ngIf="utente && profilo">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card" id="profile-main">

                    <div class="card-content">

                        <h3>Ciao {{utente.nome}}!</h3>



                        <div role="tabpanel" >
                            <ul class="nav nav-pills">
                                <li class="active"><a href="#profile11" aria-controls="profile11" role="tab" data-toggle="tab">Informazioni Account</a>
                                </li>
                                <li *ngIf="assistenza"><a href="#activities11" aria-controls="messages11" role="tab" data-toggle="tab" >Attività Recenti</a>
                                </li>
                               

                            </ul>

                            <div class="tab-content">

                                <div role="tabpanel" class="tab-pane active" id="profile11">
                                  
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="p-15">
                                                <h4>Dettagli personali</h4>
                                                <hr>
                                                <div class="row m-l-5">
                                                    <div class="col-sm-4 p-5">
                                                        <p><i class="zmdi zmdi-face w5"></i>&nbsp;<span class="col-10">NOME</span></p>
                                                        <p><i class="zmdi zmdi-email w5"></i>&nbsp;<span class="col-10">EMAIL</span></p>
                                                        <p><i class="zmdi zmdi-phone w5"></i>&nbsp;<span class="col-10">TELEFONO</span></p>
                                                        <p><i class="zmdi zmdi-account w5"></i>&nbsp;<span class="col-10">PROFILO</span></p>
                                                        <p><i class="zmdi zmdi-accounts w5"></i>&nbsp;<span class="col-10">GRUPPI</span></p>
                                                        <p><i class="zmdi zmdi-collection-text w5"></i>&nbsp;<span class="col-10">DETTAGLI AGGIUNTIVI</span></p>
                                                        <p><i class="zmdi zmdi-account w5"></i>&nbsp;<span class="col-10">RUOLO</span></p>


                                                    </div>
                                                    <div class="col-sm-8 p-5">
                                                        <p>{{utente.nome || "---" }} {{utente.cognome || "---" }}</p>
                                                        <p>{{utente.email || "---" }}</p>
                                                        <p>{{utente.telefono || "---" }}</p>
                                                        <p>{{ruolo || "---" }}</p>
                                                        <p *ngIf="gruppiInfo.length!==0"> <span *ngFor="let gruppo of gruppiInfo; let i=index">
                                                            <span *ngIf="i === gruppiInfo.length-1">{{gruppo?.nomeGruppo}}</span>
                                                            <span *ngIf="i <= gruppiInfo.length-2">{{gruppo?.nomeGruppo + ', '}}</span>
                                                                </span>
                                                            </p>
                                                            <p *ngIf="gruppiInfo.length===0">Non risulti appartenente ad alcun gruppo.</p>
                                                            <p *ngIf="dettaglio">{{dettaglio}}</p>
                                                            <p *ngIf="!dettaglio">Non sono presenti dettagli.</p>
                                                        <p>{{utente.tag?.nome || "---"}}</p>
                                                    </div>
                                                    </div>
                                                    <!--<div *ngIf="alfaintes && !alfaCallCenter" class="row m-l-5">
                                                    <div  class="col-sm-4 p-5 pl-3">
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >email</i>&nbsp;Consenso</p>
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >badge</i>&nbsp;Utente Registrato</p>
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >home</i>&nbsp;Ragione Sociale Struttura</p>
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >medication</i>&nbsp;Informatore Scientifico</p>
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >remember_me</i>&nbsp;Identificativo Medico</p>
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >person</i>&nbsp;Carica</p>
                                                        <p style="text-transform:uppercase"> <i  class="material-icons notranslate pointer fs15 w5"  >lock</i>&nbsp;Attività</p>
                                                       
                                                    </div>
                                                    <div class="col-sm-8  p-5">
                                                        <p>{{consensoLable}}</p>
                                                        <p>{{utenteRegistratoLable}}</p>
                                                        <p>{{utente.afi?.struttura.struttura}}</p>
                                                      <p>{{utente.afi?.isf}}</p>
                                                      <p>{{this.utente.afi?.idMedico}}</p>
                                                      <p>{{utente.afi?.carica?.nomeCarica}}</p>
                                                      <p>{{utente.afi?.attivita?.nomeAttivita}}</p>
                                                    </div>
                                                </div>-->
                                                <button class="btn btn-primary pull-right" (click)="modificaUtente()" *ngIf="ruolo==='Admin'">Modifica</button>
                                             
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div role="tabpanel" class="tab-pane" id="activities11">
                                    <div class="widget">

                                        <div  class="col-xs-12 col-sm-12 col-md-6  col-lg-6 col-xl-6">
                                            <div style="min-height: 465px; max-height: 465px;">
                                                <div class="card-header">
                                                    <h4 class="card-title">Ultime chiamate</h4>
                                                </div>
                                                <div class="card-content">
                                                    <div class="streamline"  *ngFor="let chiamata of listaUltimeChiamateUtente; let i = index">
                                                        <div class="sl-item sl-primary" *ngIf="chiamata.motivoChiamata !== 'Persa'">
                                                            <div class="sl-content">
                                                                <p>{{chiamata.nome}} {{chiamata.cognome}} -
                                                                    {{chiamata.motivoChiamata}} <small class="text-muted">{{ chiamata.data | date: 'dd-MM-yyyy HH:mm:ss' }}</small></p>
                                                            </div>
                                                        </div>
                                                        <div class="sl-item sl-danger" *ngIf="chiamata.motivoChiamata === 'Persa'">
                                                            <div class="sl-content">
                                                                <p>{{chiamata.nome}} {{chiamata.cognome}} 
                                                                  <small class="text-muted">{{ chiamata.data | date: 'dd-MM-yyyy HH:mm:ss' }}</small></p>
                                                            </div>
                                                        </div>
                                                </div>
                                                    <!-- <div class="streamline"  *ngFor="let chiamata of listaUltimeChiamateUtente; let i = index">
                                                        <div class="sl-item sl-primary">
                                                            <div class="sl-content">
                                                                <p>{{chiamata.nome}} {{chiamata.cognome}} -
                                                                    {{chiamata.motivoChiamata}} <small class="text-muted">{{ chiamata.data | date: 'dd-MM-yyyy HH:mm:ss' }}</small></p>
                                                            </div>
                                                        </div>
                                                </div> -->
                        
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>

                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>



<!-- MODIFICA -->
<div *ngIf="modificaUser">
<div class="content" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <ng-container *ngIf="modifica">
                  <form [formGroup]="editForm" (ngSubmit)="onEdit()" class="form-horizontal">
                        <div class="card-header card-header-text">
                            <h4 class="card-title" >Modifica Utente</h4>
                        </div>
                        <div class="card-content">
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Nome<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" class="form-control" placeholder="Nome"  
                                          formControlName="nome"
                     
                                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" maxlength="30">
                                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                          <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
                                          <div *ngIf="f.nome.errors.pattern">Campo nome invalido</div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Cognome<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" class="form-control" placeholder="Cognome"
                                        
                                        formControlName="cognome" 
                                        [ngClass]="{ 'is-invalid': submitted && f.cognome.errors }"
                                        maxlength="30" />
                                        <div *ngIf="submitted && f.cognome.errors" class="invalid-feedback">
                                            <div *ngIf="f.cognome.errors.required">Campo cognome obbligatorio</div>
                                            <div *ngIf="f.cognome.errors.pattern">Campo cognome invalido</div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Email<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="email" class="form-control" placeholder="Email" 
                                        formControlName="email"               
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="50">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                          <div *ngIf="f.email.errors.pattern">Formato email non corretto</div>
                                          <div *ngIf="f.email.errors.required">Campo email obbligatorio</div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Recapito Telefonico</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" class="form-control" 
                                        pKeyFilter="pint" placeholder="Recapito Telefonico"
                                        formControlName="recapito"
                                        [ngClass]="{ 'is-invalid': submitted && f.recapito.errors }"
                                        maxlength="11" >
                                    
                                    </div>
                                </div></div>
                              <div class="row">
                                    <label class="col-sm-2 label-on-left" >Dettagli Aggiuntivi</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" class="form-control" 
                                            placeholder="Descrizione"
                                            formControlName="descrizione"
                                            maxlength="100"
                                            >
                                          
                                        </div>
                                    </div></div>
                            <!-- <br>
                           &nbsp;  &nbsp; -->
                        <div class="row" *ngIf="ruoli">
                            <label class="col-sm-2 label-on-left" >Profilo<span class="colorRed"> *</span></label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">
                                
                                    <p-selectButton [options]="ruoli" formControlName="ruolo"></p-selectButton>
                                
                                <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback-2">
                                    <div *ngIf="f.ruolo.errors.required">Campo ruolo obbligatorio</div>
                                </div>
                            </div></div>
                        </div> 
                        <div class="row">
                          <label class="col-sm-2 label-on-left" >Gruppo</label>
                          <div class="col-sm-10">
                              <div class="form-group label-floating is-empty">
                              
                                  <p-selectButton [options]="gruppi" formControlName="gruppo" multiple="multiple"></p-selectButton>
                              
                              <div *ngIf="submitted && f.gruppo.errors" class="invalid-feedback-2">
                                  <div *ngIf="f.gruppo.errors.required">Campo gruppo obbligatorio</div>
                              </div>
                            </div>
                            </div>
                        </div>
                        
                          <div class="row">
                            <label class="col-sm-2 label-on-left" >Ruolo</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <select formControlName="tag" class="form-control">
                                        <option value="">-</option>
                                        <option value="Admin">Admin</option>
                                        <option value="AM">AM</option>
                                        <option value="FSM">FSM</option>
                                        <option value="ISF">ISF</option>
                                        <option value="SP">SP</option>
                                    </select>
                                  </div>
                            </div>
                        </div>
        <br>                               
                        </div>
                        <div class="text-right" style="margin-right: 5%;"> 
                        <hr>
                        <div class="btn-group" role="group" aria-label="Basic example">
                                    
                          <a (click)="indietro(true)"><button type="button" class="btn btn-secondary" aria-pressed="true">Indietro</button></a>
                        </div>
                    <div class="btn-group" role="group" aria-label="Basic example">
                                
                        <a (click)="cancella()"><button type="button" class="btn btn-secondary" aria-pressed="true">Cancella</button></a>
                      </div>
                      <div class="btn-group" role="group" aria-label="Basic example" aria-pressed="true">
                        <button [disabled]="loading"  type="submit" class="btn btn-primary">Salva
                            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                        </button>
                      </div></div>
                          <br>
                    </form>
                </ng-container>
                </div></div>
            </div> </div> </div>  </div>
