import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmationService } from 'primeng/api';
import { AccountService, AlertService, EnvService, WebSocketService } from 'src/app/SERVICES';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';

@Component({
  selector: 'app-shellybutton1-conf',
  templateUrl: './shellybutton1-conf.component.html',
  styleUrls: ['./shellybutton1-conf.component.scss']
})
export class Shellybutton1ConfComponent implements OnInit {

  @Input() device;
  @Input() crea;
  @Input() edit;
  @Input() info;

  @Input()  deviceConfigured!: boolean | string;
  @Output() deviceConfiguredChange = new EventEmitter<boolean>();

  @Output() macChange = new EventEmitter<String>();

  mac;
  hostname;

  resetAvvenuto=false;
  
  wifiConfigDialog = false;
  caricamentoConnessione = false;
  caricamentoConfigurazione = false;
  caricamentoIndietro = false;
  caricamentoReset = false;
  dialogLoading = false;

  isDesktop = true;

  stompClient;
  riconnessioneWs = false;
  intervalReconnectionWs;


  constructor(
    private alertService: AlertService,
     private iotService: IotService, 
     private accountService: AccountService,
     private deviceService: DeviceDetectorService,
     private wsService: WebSocketService,
     private router: Router,
     private confirmationService: ConfirmationService,) { }

  ngOnInit(): void {
    //console.log("deviceConfigured: " + this.deviceConfigured);
    this.isDesktop = this.deviceService.isDesktop();
      this.iscrizioneTopicWebSocket();      
  }

  ngAfterViewInit(): void {
    this.intervalReconnectionWs = setInterval(() => {
      if (!this.riconnessioneWs) {
        if (this.stompClient && !this.stompClient?.connected) {
          this.wsService.attivo = false;
        }
      }
    }, 20000);
    if (!this.wsService.attivo) {
      this.riconnessioneWs = true;
      this.iscrizioneTopicWebSocket();
    }

    setTimeout(() => {
      if (!this.riconnessioneWs) {
        if (this.stompClient && !this.stompClient?.connected) {
          this.wsService.attivo = false;
        }
      }
    }, 20000);
    if (!this.wsService.attivo) {
      this.riconnessioneWs = true;
      this.iscrizioneTopicWebSocket();
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.stompClient.disconnect();
    clearInterval(this.intervalReconnectionWs);
    if (!this.accountService.userValue.data) {
      this.router.navigate['logout'];
    }
  }


  checkShellyButton1Connection()
  {
    this.caricamentoConnessione = true;

    this.iotService.connessioneShellyButton1().subscribe((res) => {
      this.mac = res.device.mac;
      this.hostname = res.device.hostname;
      this.alertService.success('Connessione al dispositivo ' + this.mac + " riuscita.");
      if(res.device.mac != this.device.deviceId)
      {
        this.syncDeviceId();
      }
      else
      {
        this.wifiConfigDialog = true;
        this.caricamentoConnessione = false;
      }

      this.iotService.impostaUrlAzioneShellyButton1(res.device.mac, "shortpush", "S").subscribe((res) => {
        //this.alertService.success("URL per lo short push impostato!");
      })
      
      this.iotService.impostaUrlAzioneShellyButton1(res.device.mac,"longpush", "L").subscribe((res)=> {
        //this.alertService.success("URL per il long push impostato!");
      })

      this.iotService.impostaUrlAzioneShellyButton1(res.device.mac,"double_shortpush", "SS").subscribe((res)=> {
        //this.alertService.success("URL per il doppio short push impostato!");
      })

      this.iotService.impostaUrlAzioneShellyButton1(res.device.mac,"triple_shortpush", "SSS").subscribe((res)=> {
        //this.alertService.success("URL per il triplo short push impostato!");
      })
    }, (error) => {
      this.alertService.error('Connessione al dispositivo fallita. Verificare di essere connessi alla rete Wi-Fi corretta.');
      this.caricamentoConnessione = false;
    });
  }

  passwordType="password"
  visibility="visibility"
  passwordTypeB=true;
  showPassword(){
    this.passwordTypeB =!this.passwordTypeB;
    if(this.passwordTypeB)
    {
      this.passwordType="password";
      this.visibility="visibility";
    }
    else
    {
      this.passwordType="text";
      this.visibility="visibility_off";
    }
  }

  configure(){
    this.caricamentoConfigurazione=true;
    let apName: any = document.getElementById('apname');
    let apPass: any = document.getElementById('appass');
    apName = apName.value;
    apPass = apPass.value;
    
    this.iotService.configuraWifiShellyButton1(apName, apPass).subscribe((res)=>{
      this.caricamentoConfigurazione=false;
      this.deviceConfigured = true;
      this.deviceConfiguredChange.emit(this.deviceConfigured);
      this.alertService.success("Rete Wi-Fi configurata con successo.");
    });
  }

  responsiveWidth() {}

  resetConfiguration() {
    this.confirmationService.confirm({
      key: 'resetConfiguration',
      message:
        'Sicuro di voler continuare? ' +
        '<br>' +
        ' Questa operazione va effettuata solo in caso di reset alle impostazioni di fabbrica.' +
        '<br>' +
        'Questa operazione non resetterà il dispositivo automaticamente.',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.dialogLoading=false;
        this.caricamentoReset = false;
        this.confirmationService.close();
      },
    });
  }

  syncDeviceId() {
    this.confirmationService.confirm({
      key: 'syncDeviceId',
      message:
        ' L\'id inserito nello Step 1 differisce da quello fornito dal dispositivo ' + this.hostname +
        '<br>' +
        'Sincronizzare automaticamente?',
      icon: 'pi pi-question-circle',
      reject: () => {
        this.wifiConfigDialog = false;
        this.caricamentoConnessione = false;
        this.confirmationService.close();
      },
    });
  }

  accettaResetConfigurazione()
  {
    this.caricamentoReset=true;

    this.iotService.confermaconfigurazioneWifiShellyButton1(this.device.id, false).subscribe((res)=>{
      this.caricamentoReset=false;
      this.deviceConfigured=false;
      this.deviceConfiguredChange.emit(this.deviceConfigured);
      this.resetAvvenuto=true;
      this.confirmationService.close();
      this.alertService.success(res.status.description);
    })
  }

  accettaSincronizzazioneID()
  {
    this.macChange.emit(this.mac);
    this.device.deviceId = this.mac;
    this.confirmationService.close();
    this.alertService.info("L'id dispositivo inserito è stato sincronizzato automaticamente.");
    this.wifiConfigDialog = true;
  }



  indietro()
  {
    this.caricamentoIndietro = true;
    this.accountService.controllo().subscribe((res)=>{
      this.wifiConfigDialog=false;
      this.caricamentoIndietro=false;
    },
    (error)=>{
      this.alertService.error("Impossibile raggiungere GAP. Assicurarsi di aver riprisitnato la connessione alla propria rete.");
      this.caricamentoIndietro=false;
    })
  }

  iscrizioneTopicWebSocket() {
    this.stompClient = this.wsService.connect();
    this.stompClient.connect({}, (frame) => {
      this.riconnessioneWs = false;
      this.stompClient.subscribe('/topic/device/allarmi', (res) => {
        let allarme = JSON.parse(res.body.toString());
        //console.log("Ricevuto dal WS: " + res + "\n alarm.monitoring.device: " + allarme.monitoring.device);
        if (allarme.monitoring.device == this.device.id) {
          this.iotService.confermaconfigurazioneWifiShellyButton1(this.device.id, true).subscribe((res)=>
          {
            this.deviceConfigured = true;
            this.deviceConfiguredChange.emit(this.deviceConfigured);
          });
        }
      });
    });
    //console.log("Componente Configurazione Shelly Button1 - Iscritto al topic '/topic/device/allarmi' !")
  }



}
