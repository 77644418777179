import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';

@Component({
  selector: 'app-ricerca-avanzata',
  templateUrl: './ricerca-avanzata.component.html',
  styleUrls: ['./ricerca-avanzata.component.scss']
})
export class RicercaAvanzataComponent implements OnInit {
  @Output() tornaGestione = new EventEmitter<boolean>()
  @Output() listaFile = new EventEmitter<any>()
  formRicerca : FormGroup;
  loading=false;
  submitted=false;
  ita = {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
    monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
    monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
};
  constructor(private formBuilder : FormBuilder, private fileService : FileService, private alert : AlertService) {
    this.formRicerca = this.formBuilder.group({
      nomeFile: [''],
      categoria: [''],
      gruppo: [''],
      autore: [''],
      estensione: [''],
      dataDa: [''],
      dataA: [''],
      contenuto: ['', Validators.minLength(5)],
      proprietario: [''],
    })
   }

  ngOnInit(): void {
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    if(this.formRicerca.invalid){
      this.loading = false;
      return
    }
    if(this.formRicerca.value.dataDa && this.formRicerca.value.dataA){
      if(this.formRicerca.value.dataDa > this.formRicerca.value.dataA){
        this.loading = false;
        this.alert.warn('Formato data non valido')
        return
      }
    }
    this.fileService.ricercaAvanzata(encodeURI(this.formRicerca.value.nomeFile),encodeURI(this.formRicerca.value.categoria),encodeURI(this.formRicerca.value.autore),
    encodeURI(this.formRicerca.value.gruppo),encodeURI(this.formRicerca.value.proprietario),encodeURI(this.formRicerca.value.contenuto),encodeURI(this.formRicerca.value.estensione),
    encodeURI(this.formRicerca.value.dataDa),encodeURI(this.formRicerca.value.dataA)).subscribe((res)=>{
      this.loading = false;
      this.submitted = false;
      this.listaFile.emit(res.data)
    },
    (error)=>{
      this.loading = false;
      this.submitted = false;
    })
  }

  goBack(){
    this.tornaGestione.emit(true);
  }
}
