import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { Utente } from 'src/app/MODELS/USER/utente';
import {Message} from 'primeng/api';
import { AlertService, UsersService } from 'src/app/SERVICES';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';


@Component({
  selector: 'app-family-utente',
  templateUrl: './family-utente.component.html',
  styleUrls: ['./family-utente.component.scss']
})
export class FamilyUtenteComponent implements OnInit {
  @Input() utentefamily;
  @Output() tornaGestione = new EventEmitter<boolean>();
  family=true;
  listaFamiliari: Utente[];
  crea:boolean;
  modifica:boolean;
  gruppoModForm: FormGroup;
  searchForm: FormGroup;
  searchForm2: FormGroup;
  listautenti=[]//totale per aggiungere utente
listautentiGruppo=[]
  allListautenti: Utente[]=[];
  idGruppo: string;
  users: Utente[]=[];//filtrati
  allUser: Utente[];
  allUserGruppo: Utente[];
  submitted = false;
  //visibilità html
  add=false;
  flag=false;
  cols: any[];
 msgs: Message[] = [];
  selectedUsers3: Utente[];
  utentiSelezionati: Utente[];
  idUtentiSelezionati: String[];
  nomeGruppo: string;
  descrizioneGruppo: string;
  vaiGestioneFamiglia: any;
  loading = false;
  caricatoFamiliari =false;
  caricatoUser =false;


  constructor(  private userService: UsersService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private dispositiviService: IotService) {





this.cols = [
  { field: 'email', header: 'Email' },
  { field: 'nome', header: 'Nome' },
  { field: 'cognome', header: 'Cognome' }
];

    this.searchForm2 = this.formBuilder.group({
      search2: ['', Validators.required]
      })
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.dispositiviService.getAllFamily(this.utentefamily.id).subscribe((res) => {
      this.caricatoFamiliari=true;
    this.listaFamiliari=res.data;


      });

}

get l() { return this.gruppoModForm.controls; }


  eliminaUtenteFamily(idFamily) {//elimina utente da gruppo

    let utenteFam: any = {};
      utenteFam = {
        id : idFamily
      }
    //user.isDeleting = true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler rimuovere l\'utente dal gruppo?',

      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.dispositiviService.cancellaFamily(this.utentefamily.id, utenteFam).subscribe((res) => {
        this.aggiornalistafamiliari();
        this.alertService.success('Utente rimosso con successo')
        this.confirmationService.close();

        });

      },
      reject: () => {
        this.confirmationService.close();

      }
  })

  }

aggiornalistafamiliari(){
  this.dispositiviService.getAllFamily(this.utentefamily.id).subscribe((res) => {
  this.listaFamiliari=res.data;

    });
}



  tornaGesGruppi(vai){//riporta gestione gruppi

    this.vaiGestioneFamiglia.emit(vai);

  }

  aggiungi(vai){//compare cmp per aggiungere utente
 this.add=true;
 this.dispositiviService.getAllUserFamily(this.utentefamily.id).subscribe((res) =>{
  this.listautenti=res.data;
  this.allUser=this.listautenti;
  this.caricatoUser=true;
})
  }

  salvaFamiglia(){

    this.submitted = true;
    if (this.gruppoModForm.invalid) {
      return;
    }
    let nome = this.gruppoModForm.value.nomeMod;
    let descrizione = this.gruppoModForm.value.descrizioneMod;
  }


  searchInUser2() {
    let valore: string = this.searchForm2.value.search2;

    this.listautenti=this.allUser;


    if (valore) {
      let users = []
      this.listautenti.forEach(user => {
      let nomeCognome = user.nome + ' ' + user.cognome;
      let cognomeNome = user.cognome + ' ' + user.nome;

      if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
      ) {
        if (!(users.find(x => x === user))) {
        users.push(user);
        }
      }
    });
    this.listautenti= users;

    } else {
      this.listautenti= this.allUser ;
    }
  }
//METODI SECONDA PARTE AGGIUNTA UTENTE


  salvaFamiliari() {
    this.loading=true;
    this.alertService.clear();

    if (this.selectedUsers3 && this.selectedUsers3.length > 0) {

      this.utentiSelezionati = this.selectedUsers3;
      this.idUtentiSelezionati = new Array;
      this.selectedUsers3.forEach(user => {
        let utente: any = {}
        utente = {
          id : user.id
        }
        this.idUtentiSelezionati.push(utente);
      });

      this.dispositiviService.PostFamily(this.utentefamily.id, this.idUtentiSelezionati).subscribe((res) => {
        this.alertService.success(res.status.description);
        this.aggiornalistafamiliari();
        this.add=false;
        this.modifica=true;
        this.loading=false;
      }, (error) => {
        this.loading = false;
      });
    }
    else {
      this.alertService.error("Seleziona uno o più utenti");
      this.loading = false;
    }
    // INSERIRE SERVIZIO INVIO FAMILY
    this.selectedUsers3=[];
    this.listautenti= this.allUser ;
    this.searchForm2.reset();
  }

indietro2(){//fa ricomparire modifica
  this.selectedUsers3=[];
  this.listautenti= this.allUser ;
  this.searchForm2.reset();
  this.add=false;
  this.modifica=true;

}




goBack(chiudi: boolean){
    this.family=false;
    this.tornaGestione.emit(chiudi);


}

responsiveWidth() {    }


}
