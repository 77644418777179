import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { timeStamp } from 'console';

@Component({
  selector: 'app-storico-assistito',
  templateUrl: './storico-assistito.component.html',
  styleUrls: ['./storico-assistito.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('120ms ease-out')),
      transition('default => rotated', animate('120ms ease-in')),
    ]),
  ],
})
export class StoricoAssistitoComponent implements OnInit {
  @Output() cambia = new EventEmitter<boolean>();
  @Input() clienteInfo;
  modificheUser = [];
  dataDaPassare;
  storico = [];
  loading = true;
  rangeDates;
  subscriptionStorico: Subscription;
  today = new Date();
  ita;
  fromDate;
  fromMonth;
  fromYear;
  toDate;
  toMonth;
  toYear;
  stato = 'default';
  statusCalendar;
  storicoTable = false;
  assistitoModifiche = [];

  constructor(private clienteService: ClienteService) {
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domenica',
        'Lunedi',
        'Martedi',
        'Mercoledi',
        'Giovedi',
        'Venerdi',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'yy-mm-dd',
      weekHeader: 'Wk',
    };
    this.ita = JSON.stringify(this.ita);
  }

  ngOnInit(): void {
    this.statusCalendar = false;
    this.getStorico(this.clienteInfo);
  }

  getStorico(id, data_inizio?, data_fine?) {
    this.loading = true;
    this.subscriptionStorico = this.clienteService
      .getStorico(id, data_inizio, data_fine)
      .subscribe(
        (res) => {
          this.modificheUser = [];
          this.storico = res.data.storia;
          this.storico.forEach((element) => {
            this.modificheUser.push({
              data: element.updateAt,
              operatore: element.who.nomeUtente,
              modifiche: element.assistito,
            });
          });

          this.loading = false;
        },
        (err) => {
          this.indietro(true);
        }
      );
  }

  filterStorico() {
    if (this.subscriptionStorico) {
      this.subscriptionStorico.unsubscribe();
    }
  }

  indietro(chiudi: boolean) {
    this.cambia.emit(chiudi);
  }

  responsiveWidth() {}

  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDate = yyyy + '-' + mm + '-' + dd;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDate = yyyy + '-' + mm + '-' + dd;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDate = yyyy + '-' + mm + '-' + dd;
    }
    if (this.rangeDates == undefined) {
      this.fromDate = '1990-01-01';
      this.toDate = new Date();
    }
  }

  cambiaStato(stato) {
    this[stato] = this[stato] === 'default' ? 'rotated' : 'default';
  }

  changeStatusCalendar() {
    this.statusCalendar = !this.statusCalendar;
  }

  filtraStorico() {
    this.getStorico(this.clienteInfo, this.fromDate, this.toDate);
    this.changeStatusCalendar();
  }

  openTable(modifiche, data) {
    this.assistitoModifiche = modifiche;
    this.dataDaPassare = data;
    this.storicoTable = true;
  }

  backFromTable(vai) {
    this.assistitoModifiche = [];
    this.storicoTable = !this.storicoTable;
  }
}
