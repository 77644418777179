import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utente } from 'src/app/MODELS/USER/utente';
import { Doctor } from 'src/app/MODELS/USER/doctor';
import { AlertService,UsersService } from './../../../../SERVICES';
import { ConfirmationService, Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { FormFilterComponent } from '../../gestione-medici/form-filter/form-filter.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aggiungi-gruppo',
  templateUrl: './aggiungi-gruppo.component.html',
  styleUrls: ['./aggiungi-gruppo.component.scss']
})
export class AggiungiGruppoComponent implements OnInit {

  @Output() backGruppi = new EventEmitter<boolean>();

  form: FormGroup;
  submitted = false;
  tornaGesGruppi;
  users: Doctor[];

  allListaMedici: Doctor[];
  idUtentiSelezionati = [];
  selectedUsers3: Doctor[];
  searchform: FormGroup;
  // listautenti: Utente[];
  listautenti: Doctor[];
  allListautenti: Utente[];
  msgs: Message[] = [];
  carica: boolean;
  appoggioclick: number;

  filtersVisible = false;
  removeFilters = false;
  filters: any = {};
  @ViewChild(FormFilterComponent) formFilterComponent: FormFilterComponent;
  refreshView = true;
  numeroMaxPagine: number;
  items;
  maxItems;
  isClickable = true;
  pagina;
  size = 10;
  pagination = 0;
  selectedValue;
  paginator: boolean;
  isSelectingValue: boolean;
  isSearchingOn: boolean;
  valoreRicerca;

  subscriptionListaMedici:Subscription
  caricato: boolean;
  isClickableCancellati: boolean;
  paginatorCancellati: boolean;
  isSelectingValueCancellati: boolean;
  showTable: boolean;
  idRipristina;
  
  loading=false;
  infouser;
  gestione: boolean;
  dettagli: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService
  ) {
    this.pagina = 0;
    this.selectedValue = 10;
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.form = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.pattern(/.*\S.*/)]],
      descrizione: ['', [Validators.required, Validators.pattern(/.*\S.*/)]]
    });
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    });
    this.getList(this.pagina, this.size);
    // this.getUtenti();
  }

  /*getUtenti(){
    this.userService.getAll().subscribe((res) => {
      this.listautenti = res.data.listaUtentiAttivi;
      this.allListautenti = this.listautenti;
    }, (error) => {

    });
  }*/

  getList(page?, size?, filters?): void {
    this.refreshView = false;
    this.userService.getDoctors(page, size, filters).subscribe(
      (response: any) => {
        this.listautenti = response.data.content;
        
        this.numeroMaxPagine = Number(response.data.totalPages);
        this.maxItems = response.data.totalElements;
        this.items = response.data.numberOfElements;
        this.isClickable = true;
        this.refreshView = true;
      },
      (error) => {
        console.error('Errore durante il recupero dei dati:', error);
      }
    );
  }

  get f() {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const nome = this.form.value.nome;
    const descrizione = this.form.value.descrizione;

    this.idUtentiSelezionati = [];

    if (this.selectedUsers3) {
      this.selectedUsers3.forEach(user => {
        const id = user.id;
        this.idUtentiSelezionati.push(id);
      });
    } else {
      this.idUtentiSelezionati = [];
    }

    this.userService.createGruppo(nome, descrizione, this.idUtentiSelezionati).subscribe(
      (res) => {
        this.alertService.success(res.status.description);
        this.backGruppi.emit(true);
      },
      (error) => {
        console.error('Errore durante la creazione del gruppo:', error);
      }
    );
  }

  tornaGestioneGruppi(vai: boolean): void {
    this.backGruppi.emit(vai);
  }

  /*searchInUser(): void {
    let valore: string = this.searchform.value.search;

    this.listautenti = this.allListautenti;
    if (valore) {
      let listautenti = [];
      this.listautenti.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase())) {
            if (!listautenti.find(x => x === user)) {
              listautenti.push(user);
            }
          }
        });
        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email_privata.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!listautenti.find(x => x === user)) {
            listautenti.push(user);
          }
        }
      });
      this.listautenti = listautenti;
    } else if (!valore) {
      this.listautenti = this.allListautenti;
    }
  }*/

  openFilters(): void {
    this.filtersVisible = !this.filtersVisible;
  }

  deleteFilters(): void {
    this.removeFilters = true;
    this.filters = {};
    this.pagina = 0;
    this.getList(this.pagina, this.size, this.filters);
  }

  submitFilters(): void {
    this.pagina = 0;
    this.formFilterComponent.onSubmitFilters();
    this.filtersVisible = false;
    
  }

  updateList(filtersObj: any): void {
    this.removeFilters = false;
    Object.keys(filtersObj).reduce((acc, k) => {
      if (!filtersObj[k]) {
        delete acc[k];
      }
      return acc;
    }, filtersObj);

    this.filters = { ...filtersObj };
    this.getList(this.pagina, this.size, this.filters);
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    this.size = quantità;

    /*if(attivo)
    {*/
      this.subscriptionListaMedici = this.userService.getDoctors(pagina, quantità).subscribe({
      next: (res) => {

      if (attivo == true) {
        if (res?.data?.content?.length > 0) {
          this.listautenti = res.data.content;
        } else {
          this.listautenti = []
        }
        this.allListaMedici = this.listautenti;
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements

        this.carica = true;
        this.caricato = true;
        this.isClickable = true;
        this.isClickableCancellati = true;
        this.paginatorCancellati = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.isSelectingValueCancellati = false;
        this.refreshView = true;
        this.showTable = true;
      }
    },
    error: (err) => {
      console.log(err)
    }})
  }
  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

}
