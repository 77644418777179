import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AccountService } from 'src/app/SERVICES/index';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guida',
  templateUrl: './guida.component.html',
  styleUrls: ['./guida.component.scss'],
  providers: [MessageService]
})
export class GuidaComponent implements OnInit {

  activeState: boolean[] = [true, false, false];
  utente=false;
  operatore=false;
  admin=false;
  insegnante = false;
  callcenter = false;
  iot = false;
  cr = false;
  ar = false;
  classroom = false;
  dms = false;
  geo = false;
  chatbot = false;
  viewer360 = false;
  ambiente
  alfa
  ruolo
  newsLetter

  constructor(
    private messageService: MessageService,
    private accountService: AccountService,
    private envService : EnvService
    ) {
      this.iot = this.envService.IOT
      this.cr = this.envService.CR
      this.ar = this.envService.AR
      this.classroom = this.envService.CLASSROOM
      this.dms = this.envService.DMS
      this.geo = this.envService.GEO
      this.chatbot = this.envService.CHATBOT
      this.viewer360 = this.envService.VIEWER360
      this.ambiente = environment; 
      this.alfa = this.envService.alfaintes;
      this.ruolo = this.accountService.ruolo;
      this.newsLetter =this.envService.NEWSLETTER
     }

  ngOnInit(): void {
    if (this.accountService.userValue) {
      if( this.accountService.ruolo===3){
        this.admin=false;
        this.utente=true;
        this.operatore=false;
        this.insegnante = false;
        this.callcenter = false;
      }  else if(this.accountService.ruolo===1){
        this.admin=true;
        this.utente=false;
        this.operatore=false;
        this.insegnante = false;
        this.callcenter = false;
      }else if(this.accountService.ruolo===2){
        this.admin=false;
        this.utente=false;
        this.operatore=true;
        this.insegnante = false;
        this.callcenter = false;
      }
      else if(this.accountService.ruolo === 4){
        this.admin=false;
        this.utente=false;
        this.operatore=false;
        this.insegnante = true;
        this.callcenter = false;
      }
      else if(this.accountService.ruolo === 5){
        this.admin=false;
        this.utente=false;
        this.operatore=false;
        this.insegnante = false;
        this.callcenter = true;
      }
    }
  }
  onTabClose(event) {
    this.messageService.add({severity:'info', summary:'Tab Closed', detail: 'Index: ' + event.index})
}

onTabOpen(event) {
    this.messageService.add({severity:'info', summary:'Tab Expanded', detail: 'Index: ' + event.index});
}

toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
}
}