<div class="container box">
    <div class="row">
        <div  class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            
                <div class="card card-login ">
                    <div class="card-header text-center">
                        <h4 class="card-title" style="margin-bottom: 2%;">Benvenuto su GAP!</h4> <br>
                        <p>Per sfruttare al massimo le potenzialità da mobile, ti invitiamo a scaricare l'app.</p>
                    </div>
                  
                    <div class="buttons">
                        <div style="text-align: center;">
                            <a href="https://play.google.com/store/apps/details?id=com.protom.gap"><button style="padding: 9px 13px 9px 13px; margin-right: 5px;"  class="btn btn-primary btn-round"><i style="font-size: x-large;" class="zmdi zmdi-google-play"></i></button></a>
                            <a href="https://apps.apple.com/it/app/gap/id1537371216"><button style="padding: 9px 13px 9px 13px; margin-left: 5px;"  class="btn btn-primary btn-round"><i style="font-size: x-large;" class="zmdi zmdi-apple"></i></button></a>

                        </div></div>
                </div>
            
        </div>
    </div>
</div>