import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { NotificaGruppo } from './notifica-gruppo';
import { IChatParticipant, Message } from 'ng-chat';
import { ServiceChat } from 'src/app/SERVICES/CHAT/chat.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-chatlive',
  templateUrl: './chatlive.component.html',
  styleUrls: ['./chatlive.component.scss']
})
export class ChatliveComponent implements OnChanges{
  @Output() VarMessaggioChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() VarMessaggio;

  public adapter: NotificaGruppo;
  messaggio = null;
  vecchioMessaggio = null;
  username;
  listCanaliFinale: Array<String> = [];
  userId: number = -1;
  constructor(private serviceChat: ServiceChat, private http: HttpClient, 
    private accountStorage: AccountService, private homeService: ShareService) {
    this.adapter = new NotificaGruppo(this.serviceChat, this.homeService, this.accountStorage);
    this.userId = this.accountStorage.userValue.data.id;
    this.adapter.userId = this.userId.toString();
    this.username = this.accountStorage.userValue.data.cognome
  }

  ngOnChanges(changes : SimpleChanges) {
    let nome = changes.VarMessaggio.currentValue;
    let utente : any = {};
    this.serviceChat.lista_utenti().subscribe((res)=>{
      res.data.forEach(user => {
        if(user.displayName === nome){
          utente.id = user.id;
          utente.avatar = user.avatar;
          utente.displayName = user.displayName;
          utente.status = user.status;
          utente.participantType = 0;
        }
    })
  //this.onEventTriggeredPartecipanti(utente); 
  if(utente){
  //this.adapter.listFriends(utente);
}
})
}
  title = 'app';
  currentTheme = 'light-theme';
  triggeredEvents = [];

  switchTheme(theme: string): void {

    this.currentTheme = theme;
  }
  idCanale;
  onEventTriggeredPartecipanti(event): void {
    if (!this.idCanale) {
      this.serviceChat.creazione_canale(this.accountStorage.userValue.data.id, event.id).subscribe(res => {
        this.homeService.changeIdCanale(res.data);
        this.adapter.idCanale = res.data;
        this.idCanale = res.data;
      })  
    } else {
      this.serviceChat.creazione_canale(this.accountStorage.userValue.data.id, event.id).subscribe(res => {
        this.homeService.changeIdCanale(res.data);
        this.adapter.idCanale = res.data;
        this.idCanale = res.data;
      })
    }
  }

  chiudi(){
    this.homeService.changeDataChat(false);
  }
  
}

