export class EventoCalendario {
  chiamataInizio? = '';
  chiamataFine? = '';
  nomeOperatore? = '';
  ragioneSociale? = '';
  codiceAlfaIntes? = '';
  piva? = '';
  provincia? = '';
  dataUltimoOrdine? = '';
  importoUltimoOrdine? = '';
  propostaOrdine? = '';
  dataPropostaOrdine? = '';
  motiviNoOrdine? = '';
  nomeCentroAcquisto? = '';
  note? = '';
  target? = '';
  ricercaMercato? = '';
  ordine? = '';
  listaDomande? = '';
  listaRisposte? = '';
  disposition? = '';
  duration? = '';
  to_Number? = '';

}
