import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AccountService,
  AlertService,
  EnvService,
  UsersService,
} from 'src/app/SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import * as JSZip from 'jszip';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';
import { HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard'
@Component({
  selector: 'app-crea-aula',
  templateUrl: './crea-aula.component.html',
  styleUrls: ['./crea-aula.component.scss'],
})
export class CreaAulaComponent implements OnInit {
  @ViewChild('fileInput') selectedFile: ElementRef;
  formAula: FormGroup;
  formNotifiche: FormGroup;
  searchDocentiForm: FormGroup; //cerca in lista docenti
  selectedDocente = [];
  searchStudentiForm: FormGroup; //cerca in lista studenti
  selectedStudente = [];
  loading = false;
  listaDocenti = [];
  listaStudenti = [];
  allListaDocenti = [];
  allListaStudenti = [];
  opzioniInd: SelectItem[];
  selectedOptionInd: number;
  listaDettagli = [];
  i = 0;
  NotificationsModal = false;

  secondoStep = false;

  auleListSelected = [];
  listaClassi = [];

  addAula = false;
  caricataLista = false;
  ruolo;
  templateScelto;
  j = 0;
  erroreListaDettagli: boolean = false;
  checkIos;
  ita = {
    firstDayOfWeek: 1,
    dayNames: [
      'Domenica',
      'Lunedi',
      'Martedi',
      'Mercoledi',
      'Giovedi',
      'Venerdi',
      'Sabato',
    ],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    monthNames: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    monthNamesShort: [
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lug',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dic',
    ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk',
  };
  submitted = false;
  studenti = false; //mostra al click la lista studenti
  docenti = false; //mostra al click la lista docenti
  corso = false;
  // selectedFile: File = null;
  nomeFile;
  createForm: FormGroup;
  corsoView = false;
  loadingCorso;
  listaCorsiUp = [];
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  apriPopup;
  corsoCaricato;
  submittedStep1;
  submittedStep2;
  submittedStep3;
  submittedStep4;
  aulaId;
  errore = false;
  loadingListe = false;
  ambiente;
  caricaCorsoBol = false;
  @ViewChild('modale') modale: ElementRef<HTMLElement>;
  @ViewChild('closeModal') closeModal: ElementRef<HTMLElement>;
  @Output() tornaLista = new EventEmitter<void>(); //torna ad elenco aula virtuale
  corsiAmbiente = false;
  selectedFileCopertina: File = null;
  erroreIMG = false;
  afi;
  nomeFileCopertina;
  showTable = true;
  chekAll = false;
  listaTemplate = [];
  goToMode: boolean;
  chekAllNotifications: boolean = false;
  newsLettersValue: boolean = false;
  classLink: string;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private classroomService: ClassroomService,
    private accountService: AccountService,
    private scormService: ScormService,
    private env: EnvService,
    private clipboard: Clipboard
  ) {
    this.goToMode = env.goTo;

    this.corsiAmbiente = env.SCORM;
    this.afi = env.alfaintes;
    this.ambiente = environment;
    this.opzioniInd = [
      { label: 'Sì', value: '1' },
      { label: 'No', value: '0' },
    ];
  }
  ngOnInit(): void {
    this.checkIos = this.iOS();
    if (this.afi) {
      this.classroomService.lista_attestati().subscribe((res) => {
        this.listaTemplate = res.data;
        this.listaTemplate.push({
          id: -1,
          titolo: 'Nessuno',
        });
      });
      this.listaTemplate.push({
        id: -1,
        titolo: 'Nessuno',
      });
    }

    this.loadingListe = true;
    this.userService.getAll().subscribe((res) => {
      this.listaDocenti = res.data.listaUtentiAttivi;
      this.listaDocenti.forEach((docente) => {
        if (docente.ruolo.id !== 4) {
          this.listaDocenti = this.listaDocenti.filter((x) => x !== docente);
        }
      });
      this.allListaDocenti = this.listaDocenti;
      this.listaStudenti = res.data.listaUtentiAttivi;
      this.listaStudenti.forEach((studente) => {
        if (studente.ruolo.id === 4) {
          this.listaStudenti = this.listaStudenti.filter((x) => x !== studente);
        }
      });
      this.allListaStudenti.forEach((e) => {
        e.isSelected = false;
      });
      this.listaStudenti.forEach((e) => {
        e.isSelected = false;
      });

      this.allListaDocenti.forEach((e) => {
        e.isSelected = false;
      });
      this.listaDocenti.forEach((e) => {
        e.isSelected = false;
      });
      this.allListaStudenti = this.listaStudenti;
      this.loadingListe = false;
    });
    this.formAula = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern(`[^!?@|/'"$£%=€/(?!^+$)/]+|[A-Ú a-ù0-9]+`),
        ],
      ],
      descrizione: ['', Validators.required],
      dataDa: [''],
      dataA: [''],
    });
    this.searchDocentiForm = this.formBuilder.group({
      search: ['', Validators.required],
    });

    this.searchStudentiForm = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.createForm = this.formBuilder.group({
      titolo: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      autore: ['', [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      categoria: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      gruppo: [''],
    });
    this.formNotifiche = this.formBuilder.group({
      newsletters: ['', Validators.required],
    });
  }
  get f() {
    return this.formAula.controls;
  }
  get h() {
    return this.createForm.controls;
  }
  searchInDocente() {
    let valore: string = this.searchDocentiForm.value.search;
    this.listaDocenti = this.allListaDocenti;
    if (valore) {
      let users = [];
      this.listaDocenti.forEach((user) => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!users.find((x) => x === user)) {
            users.push(user);
          }
        }
      });
      this.listaDocenti = users;
    } else if (!valore) {
      this.listaDocenti = this.allListaDocenti;
    }
  }
  searchInStudenti() {
    let valore: string = this.searchStudentiForm.value.search;
    this.listaStudenti = this.allListaStudenti;
    if (valore) {
      let users = [];
      this.listaStudenti.forEach((user) => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!users.find((x) => x === user)) {
            users.push(user);
          }
        }
      });
      this.listaStudenti = users;
    } else if (!valore) {
      this.listaStudenti = this.allListaStudenti;
    }
  }
  listaIdUtenti = [];
  checkControll() {
    this.allListaStudenti.forEach((element) => {
      if (element.isSelected) {
        if (!this.listaIdUtenti.find((x) => x == element)) {
          this.listaIdUtenti.push(element);
        }
      }
    });
  }
  listaIdDocenti = [];
  checkControllDocenti() {
    this.allListaDocenti.forEach((element) => {
      if (element.isSelected) {
        if (!this.listaIdDocenti.find((x) => x == element)) {
          this.listaIdDocenti.push(element);
        }
      }
    });
  }

  checkNewsLettersValue(event, value) {
    if (event.target.checked == true && value != false) {
      this.newsLettersValue = true;
    } else {
      this.newsLettersValue = false;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.afi) {
      if (!this.templateScelto) {
        this.alertService.error('Scegli un template');
        return;
      }
    }
    if (!this.errore) {
      if (this.listaIdDocenti?.length < 1) {
        return;
      }
      if (this.formAula.invalid) {
        return;
      }
      if (!this.selectedOptionInd) {
        return;
      }

      if (this.selectedOptionInd == 1) {
        if (!this.formAula.value.dataDa || !this.formAula.value.dataA) {
          return;
        }
        if (this.formAula.value.dataDa >= this.formAula.value.dataA) {
          return;
        }
      }
      if (
        this.listaCorsiUp &&
        this.listaCorsiUp.length > 0 &&
        this.corsiAmbiente
      ) {
        let toReturn = false;
        let strArray: Array<string> = [];
        this.listaCorsiUp.forEach((element) => {
          strArray.push(element.nomeCorso);
        });
        for (let i = 0; i < strArray.length; i++) {
          for (let j = i + 1; i < strArray.length; i++) {
            if (strArray[i] === strArray[j]) {
              toReturn = true;
            }
          }
        }
        if (toReturn) {
          this.alertService.error(
            'Impossibile creare due corsi che possiedono lo stesso nome'
          );
          return;
        }
      }
      this.checkControll();
      let aula: any = {
        nomeAula: this.formAula.value.nome.trim(),
        descrizioneAula: this.formAula.value.descrizione,
        listaDocenti: this.listaIdDocenti,
        listaUtenti: this.listaIdUtenti,
        creatore: this.accountService.userValue.data.id,
        start: this.formAula.value.dataDa.toString(),
        end: this.formAula.value.dataA.toString(),
        dettagli: this.listaDettagli,
        // notifiche: [
        //   {
        //     newsletters: this.newsLettersValue
        //     // social: this.formNotifiche.get('social').value,
        //     // whatsapp: this.formNotifiche.get('whatsapp').value
        //   }
        // ]
      };
      if (this.selectedOptionInd != 1) {
        aula.start = null;
        aula.end = null;
      }
      if (this.afi) {
        if (this.templateScelto !== -1) {
          aula.idAttestato = this.templateScelto;
        } else {
          aula.idAttestato = null;
        }
      }
      if (!aula.start) {
        aula.start = null;
        aula.end = null;
      }
      this.loading = true;
      this.classroomService.createAula(aula, this.templateScelto).subscribe(
        (res) => {
          if (res.data) {
            this.classLink = res.data;
            this.aulaId = this.classLink.split('/').pop();
            if (this.selectedFileCopertina) {
              const uploadFileCopertina = new FormData();
              this.selectedFileCopertina;
              uploadFileCopertina.append(
                'imageFile',
                this.selectedFileCopertina,
                this.selectedFileCopertina.name
              );
              this.classroomService
                .upload_afi(this.aulaId, uploadFileCopertina)
                .subscribe((res) => { });
            }
            if (this.listaCorsiUp.length > 0 && this.corsiAmbiente) {
              let i = 0;
              let j = 0;
              let primaVolta = false;
              this.listaCorsiUp.forEach((element) => {
                const uploadFile = new FormData();
                uploadFile.append('file', element.file, element.fileNome);
                uploadFile.append('categoria', element.categoria);
                uploadFile.append('gruppo', element.gruppo);
                uploadFile.append('nomeCorso', element.nomeCorso);
                uploadFile.append('autore', element.autore);
                let test = true;
                this.scormService
                  .creaCorsoDue(this.aulaId, uploadFile)
                  .subscribe(
                    (res) => {
                      let percentDone;
                      if (res.status == 200 && element.caricato == 0) {
                        element.caricato = 1;
                        i++;
                      }
                      if (res.type === HttpEventType.UploadProgress) {
                        percentDone = Math.round(
                          (100 * res.loaded) / res.total
                        );
                        element.caricamento = percentDone;
                      }
                      if (i > this.listaCorsiUp.length - 1 && !primaVolta) {
                        // this.tornaLista.emit();
                        this.alertService.success(
                          this.ambiente.NOMEAULA +
                          ' creat' +
                          this.checktypeEducation() +
                          ' con successo'
                        );
                        primaVolta = true;
                      }
                      if (i + j > this.listaCorsiUp.length - 1) {
                        this.loading = false;
                      }
                    },
                    (error) => {
                      element.caricato = 2;
                      this.errore = true;
                      j++;
                      if (i + j > this.listaCorsiUp.length - 1) {
                        this.loading = false;
                      }
                    }
                  );
              });
            } else {
              this.alertService.success(
                this.ambiente.NOMEAULA +
                ' creat' +
                this.checktypeEducation() +
                ' con successo'
              );
              // this.tornaLista.emit();

            }
            this.NotificationsModal = true;
          }
        },
        (error) => {
          this.loading = false;
          return;
        }
      );
    } else {
      this.listaCorsiUp.forEach((element) => {
        let i = 0;
        let j = 0;
        let primaVolta = false;
        if (element.caricato !== 1) {
          const uploadFile = new FormData();
          uploadFile.append('file', element.file, element.fileNome);
          uploadFile.append('categoria', element.categoria);
          uploadFile.append('gruppo', element.gruppo);
          uploadFile.append('nomeCorso', element.nomeCorso);
          uploadFile.append('autore', element.autore);
          element.caricato = 0;
          this.scormService.creaCorsoDue(this.aulaId, uploadFile).subscribe(
            (res) => {
              let percentDone;
              if (res.status == 200 && element.caricato == 0) {
                element.caricato = 1;
                i++;
              }
              if (res.type === HttpEventType.UploadProgress) {
                percentDone = Math.round((100 * res.loaded) / res.total);
                element.caricamento = percentDone;
              }
              if (i > this.listaCorsiUp.length - 1) {
                this.tornaLista.emit();
                this.alertService.success(
                  this.ambiente.NOMEAULA +
                  ' creat' +
                  this.checktypeEducation() +
                  ' con successo'
                );
                primaVolta = true;
              }
              if (i + j > this.listaCorsiUp.length - 1) {
                this.loading = false;
              }
            },
            (error) => {
              element.caricato = 2;
              this.errore = true;
              j++;
              if (i + j > this.listaCorsiUp.length - 1) {
                this.loading = false;
              }
            }
          );
        } else {
          i++;
          if (i > this.listaCorsiUp.length - 1) {
            this.tornaLista.emit();
            this.alertService.success(
              this.ambiente.NOMEAULA +
              ' creat' +
              this.checktypeEducation() +
              ' con successo'
            );
          }
        }
      });
    }
  }

  addDocente() {
    //mostra al click la lista docenti
    this.studenti = false;
    this.docenti = true;
  }
  addStudente() {
    //mostra al click la lista studenti
    this.studenti = true;
    this.docenti = false;
  }
  goBack() {
    //torna ad elenco aula virtuale
    this.tornaLista.emit();
  }

  aggiungiDettaglio() {
    let dettaglio = {
      id: this.i,
      attributo: '',
      valore: '',
    };
    this.listaDettagli.push(dettaglio);
    this.i++;
  }

  deleteDettaglio(dettaglio) {
    this.listaDettagli = this.listaDettagli.filter((x) => x.id != dettaglio.id);
  }
  caricaCorso() {
    this.createForm.reset();
    this.selectedFile.nativeElement.value = '';
    this.nomeFile = null;
    this.submittedStep4 = false;
    this.caricaCorsoBol = true;
    // let el: HTMLElement = this.modale.nativeElement;
    // el.click();
  }
  chiudiModal(fromSalva = false) {
    if (fromSalva) {
      if (
        this.h.autore.valid &&
        this.h.titolo.valid &&
        this.h.categoria.valid &&
        this.h.gruppo.valid
      ) {
        this.caricaCorsoBol = false;
      } else {
        this.alertService.error("Errore nell'inserimento dei dati");
      }
    } else {
      this.caricaCorsoBol = false;
    }
  }

  closeNotificationModal() {
    this.NotificationsModal = false;
    this.tornaLista.emit()
  }

  copyLink() {
    this.clipboard.copy(this.classLink)
    this.alertService.success('Link copiato!')
  }

  sendNotification() {
    if (this.newsLettersValue) {
      let body = {
        token: this.aulaId,
        newsletters: this.newsLettersValue
      }
      this.classroomService.sendNotification(body).subscribe((res) => {
        this.alertService.success('Notifiche inviate!')
        this.closeNotificationModal();
      }, (err) => {
        this.alertService.error(err)
      })
    } else this.closeNotificationModal()
  }

  public onFileChanged(event, corso?) {
    this.nomeFile = event.target.files[0].name;
    this.corso = false;
    this.secondoStep = false;
    switch (event.target.files[0].type) {
      case 'application/x-zip-compressed': {
        this.cercaNelZip();
        break;
      }
      case 'application/x-zip-compressed': {
        this.cercaNelZip();
        break;
      }
      case 'application/zip': {
        this.cercaNelZip();
        break;
      }
      case 'multipart/x-zip': {
        this.cercaNelZip();
        break;
      }
      default: {
        this.alertService.error(
          'Il file che stai caricando non ha un formato valido'
        );
        this.nomeFile = null;
        this.selectedFile.nativeElement.value = '';

        break;
      }
    }
  }
  cercaNelZip() {
    let i = 1;
    let zipFileLenght;
    let trovato = false;
    this.corsoCaricato = true;
    let file: File = this.selectedFile.nativeElement.files[0];
    //CANCELLA

    //-------
    JSZip.loadAsync(file).then((zip) => {
      // <----- HERE
      mimeType: 'application/octet-stream';
      zipFileLenght = Object.keys(zip.files).length;
      Object.keys(zip.files).forEach((filename) => {
        // <----- HERE
        if (filename === 'imsmanifest.xml') {
          trovato = true;
          this.alertService.warn('Stai caricando un corso');
        }

        i++;
      });

      if (i >= zipFileLenght) {
        if (trovato === true) {
          this.corso = true;
          this.apriPopup = true;
          this.corsoCaricato = false;
        } else {
          this.selectedFile.nativeElement.value = '';
          this.nomeFile = null;
          this.corsoCaricato = false;
          this.alertService.error('Il file non è valido');
        }
      }
    });
  }
  cancellaCorso(id) {
    if (this.listaCorsiUp.find((x) => x.idMomentaneo == id)) {
      this.listaCorsiUp.splice(
        this.listaCorsiUp.findIndex((x) => x.idMomentaneo == id),
        1
      );
    }
  }

  //menu visione
  nextStep() {
    if (
      this.step1 &&
      this.f.nome.value.trim().length !== 0 &&
      this.f.descrizione.value.trim().length !== 0
    ) {
      this.goStep2();
      return;
    }
    if (
      (this.f.nome.value.trim().length === 0 ||
        this.f.descrizione.value.trim().length === 0) &&
      this.step1
    ) {
      this.alertService.error('Inserire un campo nome e/o descrizione valido');
    }
    if (this.step2) {
      this.goStep3();
      return;
    }
    if (this.step3) {
      if (this.afi) {
        this.goStep4();
      } else
        // {
        //   this.goStep5();
        // }
        return;
    }
    // if (this.step4) {
    //   if (this.templateScelto) {
    //     // this.goStep5();
    //     return;
    //   } else {
    //     this.alertService.error('scegli un template');
    //   }
    // }
  }

  previusStep() {
    if (this.step2) {
      this.goStep1();
      return;
    }
    if (this.step3) {
      this.goStep2();
      return;
    }
    if (this.step4) {
      this.goStep3();
      return;
    }
    // if (this.step5) {
    //   this.goStep4();
    //   return;
    // }
  }
  goStep1() {
    //TMS 6-10-2021 GAP-438

    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
  }

  goStep2() {
    //TMS 6-10-2021 GAP-438
    if (this.salvaStep()) return;
    if (
      !this.formAula.invalid &&
      this.selectedOptionInd &&
      !this.erroreListaDettagli
    ) {
      this.step2 = true;
      this.step1 = false;
      this.step3 = false;
      this.step4 = false;
      this.step5 = false;
    }
  }
  goStep3() {
    //TMS 6-10-2021 GAP-438
    if (this.salvaStep()) return;
    if (
      !this.formAula.invalid &&
      this.selectedOptionInd &&
      this.listaIdDocenti?.length > 0 &&
      !this.erroreListaDettagli
    ) {
      this.step3 = true;
      this.step2 = false;
      this.step1 = false;
      this.step4 = false;
      this.step5 = false;
    }
  }
  goStep4() {
    if (
      !this.formAula.invalid &&
      this.selectedOptionInd &&
      this.listaIdDocenti?.length > 0 &&
      !this.erroreListaDettagli
    ) {
      //TMS 6-10-2021 GAP-438
      if (this.salvaStep()) return;
      this.step4 = true;
      this.step3 = false;
      this.step2 = false;
      this.step1 = false;
      this.step5 = false;
    }
  }
  // goStep5() {
  //   if (
  //     !this.formAula.invalid &&
  //     this.selectedOptionInd &&
  //     this.listaIdDocenti?.length > 0 &&
  //     !this.erroreListaDettagli
  //   ) {
  //     //TMS 6-10-2021 GAP-438
  //     if (this.salvaStep()) return;
  //     this.step4 = false;
  //     this.step3 = false;
  //     this.step2 = false;
  //     this.step1 = false;
  //     this.step5 = true;
  //   }
  // }

  salvaStep(): boolean {
    if (this.step1) {
      //TMS 6-10-2021 GAP-438
      return this.salvaStep1();
    }
    if (this.step2) {
      //TMS 6-10-2021 GAP-438
      return this.salvaStep2();
    }
    if (this.step3) {
      //TMS 6-10-2021 GAP-438
      return this.salvaStep3();
    }
  }
  salvaStep1(): boolean {
    this.submittedStep1 = true;
    this.erroreListaDettagli = false;
    if (this.formAula.invalid) {
      return this.submittedStep1;
    }

    if (!this.selectedOptionInd) {
      return this.submittedStep1;
    }
    this.listaDettagli.forEach((dettaglio) => {
      if (!dettaglio.attributo || !dettaglio.valore) {
        this.erroreListaDettagli = true;
      }
    });

    if (this.erroreListaDettagli) {
      return this.submittedStep1;
    }
    //TMS 6-10-2021 GAP-438
    //start
    if (this.selectedOptionInd == 1) {
      if (!this.formAula.value.dataDa || !this.formAula.value.dataA) {
        return this.submittedStep1;
      }
      if (this.formAula.value.dataDa >= this.formAula.value.dataA) {
        return this.submittedStep1;
      }
      //end
    }
    this.submittedStep1 = false;
    return this.submittedStep1;
  }
  //TMS 6-10-2021 GAP-438
  salvaStep2(): boolean {
    this.checkControllDocenti();

    this.submittedStep2 = true;
    if (this.listaIdDocenti?.length < 1) {
      return this.submittedStep2;
    }
    return (this.submittedStep2 = false);
  }
  //TMS 6-10-2021 GAP-438
  salvaStep3(): boolean {
    return false;
  }
  //TMS 6-10-2021 GAP-438
  salvaStep4(): boolean {
    this.submittedStep4 = true;
    if (this.createForm.invalid) {
      return this.submittedStep4;
    }
    if (this.selectedFile.nativeElement.value == '') {
      return this.submittedStep4;
    }
    let corsoFileForm = {
      nomeCorso: this.createForm.value.titolo,
      autore: this.createForm.value.autore,
      categoria: this.createForm.value.categoria,
      gruppo: 'Corso',
      file: this.selectedFile.nativeElement.files[0],
      fileNome: this.nomeFile,
      idMomentaneo: this.j,
      caricato: 0,
      caricamento: '0',
    };
    this.j++;
    let listaAppoggio = this.listaCorsiUp.filter(
      (x) => x.idMomentaneo === corsoFileForm.idMomentaneo
    );
    if (listaAppoggio.length < 1) {
      this.listaCorsiUp.push(corsoFileForm);
    }
    this.submittedStep4 = false;
    let el: HTMLElement = this.closeModal.nativeElement;
    el.click();
    return this.submittedStep4;
  }

  caricaCopertina() { }

  caricaCopertinaChanged(event) {
    switch (event.target.files[0].type) {
      case 'image/png': {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      case 'image/jpeg': {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      case 'image/svg+xml': {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      case 'image/webp': {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      default: {
        this.alertService.error(
          'Il file che stai caricando non ha un formato valido'
        );
        this.selectedFileCopertina = null;
        break;
      }
    }
  }

  controlloValiditàImmagine(event) {
    this.selectedFileCopertina = null;
    this.nomeFileCopertina = event.target.files[0].name;
    this.selectedFileCopertina = event.target.files[0];
    if (event.target.files && this.selectedFileCopertina) {
      // Size Filter Bytes
      const max_size = 1000000;
      //const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 600;
      const max_width = 600;
      if (this.selectedFileCopertina.size > max_size) {
        this.alertService.warn('Peso massimo deve essere di 1MB');
        this.selectedFileCopertina = null;
        this.nomeFileCopertina = null;
        return true;
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFileCopertina);
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height !== max_height && img_width !== max_width) {
            this.alertService.warn("L'immagine deve avere dimensioni 600x600");
            this.selectedFileCopertina = null;
            this.nomeFileCopertina = null;
            return true;
          }
        };
      };
    }
  }

  checkAll(evt, type?) {
    if (type == 'studenti') {
      this.showTable = false;
      let appoggio = [];
      if (!this.chekAll) {
        this.chekAll = !this.chekAll;
        this.listaStudenti.forEach((c) => {
          c.isSelected = true;
          this.allListaStudenti.forEach((element) => {
            if (c.id == element.id) {
              element.isSelected = true;
            }
          });
        });
      } else {
        this.chekAll = !this.chekAll;
        this.listaStudenti.forEach((c) => {
          c.isSelected = false;
          this.allListaStudenti.forEach((element) => {
            if (c.id == element.id) {
              element.isSelected = false;
            }
          });
        });
      }
      this.showTable = true;
    }
  }
  checkOne(id) {
    let controllo = this.allListaStudenti.find(
      (x) => x.id.toString() === id.toString()
    ).isSelected;
    if (!controllo) {
      this.allListaStudenti.find(
        (x) => x.id.toString() === id.toString()
      ).isSelected = true;
      this.listaStudenti.find(
        (x) => x.id.toString() == id.toString()
      ).isSelected = true;
    } else {
      this.allListaStudenti.find(
        (x) => x.id.toString() === id.toString()
      ).isSelected = false;
      this.listaStudenti.find(
        (x) => x.id.toString() == id.toString()
      ).isSelected = false;
      if (this.chekAll) {
        this.chekAll = false;
      }
    }
    let counter = 0;
    this.listaStudenti.forEach((element) => {
      if (element.isSelected == false) {
        counter++;
      }
    });
    if (counter == 0) {
      this.chekAll = true;
    }
  }
  refreshSelezionati(e) {
    setTimeout(() => {
      for (let index = 0; index < this.listaStudenti.length; index++) {
        if (!this.listaStudenti[index].isSelected) {
          this.chekAll = false;
          return;
        }
      }
    });
  }

  showTableInsegnante = true;
  chekAllInsegnante = false;

  checkAllInsegnante(evt) {
    this.showTableInsegnante = false;
    let appoggio = [];
    if (!this.chekAllInsegnante) {
      this.chekAllInsegnante = !this.chekAll;
      this.listaDocenti.forEach((c) => {
        c.isSelected = true;
        this.allListaDocenti.forEach((element) => {
          if (c.id == element.id) {
            element.isSelected = true;
          }
        });
      });
    } else {
      this.chekAllInsegnante = !this.chekAllInsegnante;
      this.listaDocenti.forEach((c) => {
        c.isSelected = false;
        this.allListaDocenti.forEach((element) => {
          if (c.id == element.id) {
            element.isSelected = false;
          }
        });
      });
    }

    this.showTable = true;
  }
  checkOneInsegnante(id) {
    let controllo = this.allListaDocenti.find(
      (x) => x.id.toString() === id.toString()
    ).isSelected;
    if (!controllo) {
      this.allListaDocenti.find(
        (x) => x.id.toString() === id.toString()
      ).isSelected = true;
      this.listaDocenti.find(
        (x) => x.id.toString() == id.toString()
      ).isSelected = true;
    } else {
      this.allListaDocenti.find(
        (x) => x.id.toString() === id.toString()
      ).isSelected = false;
      this.listaDocenti.find(
        (x) => x.id.toString() == id.toString()
      ).isSelected = false;
      if (this.chekAllInsegnante) {
        this.chekAllInsegnante = false;
      }
    }
    let counter = 0;
    this.listaDocenti.forEach((element) => {
      if (element.isSelected == false) {
        counter++;
      }
    });
    if (counter == 0) {
      this.chekAllInsegnante = true;
    }
  }

  refreshSelezionatiInsegnante(e) {
    setTimeout(() => {
      for (let index = 0; index < this.listaDocenti.length; index++) {
        if (!this.listaDocenti[index].isSelected) {
          this.chekAll = false;
          return;
        }
      }
    });
  }
  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  checktypeEducation() {
    if (this.ambiente.NOMEAULA === 'Webinar') {
      return 'o';
    } else {
      return 'a';
    }
  }
}
