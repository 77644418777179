//ng build --configuration=alfaintes-dev
//ng serve --configuration=alfaintes-dev
export const environment = {
  production: false,
  PROJECTNAME: 'devAlfaintes',
  AMBIENTE: 'Alfa Academy',
  NOMEAULA: 'Webinar',
  NOMEAULAIL: 'i Webinar',
  NOMEAULANEL: 'nel Webinar',
  NOMEAULAALLE: 'ai webinar',
  LEZIONI: "Webinar",
  LEZIONILE: "i webinar",
  LEZIONIDELLE: "dei webinar",
  LEZIONIUNA: 'un webinar',
  LEZIONILA: 'un webinar',
  LEZIONE: "webinar",
  LEZIONIINCORSO: 'Webinar e Live Chirurgici',
  CALENDARIOLEZIONI: 'Percorsi formativi in programma',
  ESERCITAZIONI: 'Materiali utili e letteratura scientifica',
  DOCUMENTAZIONE: 'Letteratura scientifica',
  LEZIONIREGISTRATE: 'Archivio e Registrazioni',
  CORSI: 'Percorsi formativi',
  CORSO: 'Percorso formativo',
  PARTECIPAZIONEAULA: 'Partecipa al Webinar',
  nomeLogin: 'Benvenuto in Alfa Academy!',
  nomeLogoAlto: 'Eye Health Education Hub',
  nessunEducation: 'Nessun Webinar',
  colore: '#35b09b',
  percorsiFormativi: 'Percorso Formativo',
  //TODO - rendere dinamico questo campo
  go_to_client_id: '2b3ca85-6b6d-4540-911f-d3a52b8a1916',
  CONFIGURATION_LINK: 'https://dev-cms.alfaintes.it/it/materiale-divulgativo'
};
