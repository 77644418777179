import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe utilizzata per le soglie
 * @see transform -> {@link transform}
 */
@Pipe({ name: 'soglia' })
export class SogliePipe implements PipeTransform{
    /**
     * Elimina i caratteri in eccesso è aggiunge ...
     * 
     * Esempio: 10000-12000 | soglia:'-':2, otteniamo:
     * 
     * 100...-120...
     * @param valore in stringa dei valori
     * @param separatore in rif all'esempio il separatore è '-'
     * @param soglia numero di caratteri accettati per parti, in riferimento all'esempio 2 caratteri 
     * @returns una stringa con minor caratteri
     */
    transform(valore: string, separatore:string,soglia: number) {
        let sogliaCheck = soglia || 5 //10000    
        let risultato = ''
        let soglie:string[] = valore.split(separatore) 
        let sogliaMin=soglie[0]
        let sogliaMax=soglie[1]
    
        if(sogliaMin.length>sogliaCheck){
          risultato = sogliaMin.substring(0,sogliaCheck)
          risultato = risultato + '...-'
        }else{
          risultato=sogliaMin+"-"
        }
        if(sogliaMax.length>sogliaCheck){
          risultato = risultato+sogliaMax.substring(0,sogliaCheck)
          risultato = risultato + '...'
        }else{
          risultato=risultato+sogliaMax
        }
        return risultato
    }

}
