import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertService, UsersService } from 'src/app/SERVICES';
import { CallCenterService } from 'src/app/SERVICES/FARMACIE/callCenter';
import { CampagneService } from 'src/app/SERVICES/FARMACIE/campagne';

@Component({
  selector: 'app-info-chiamata',
  templateUrl: './info-chiamata.component.html',
  styleUrls: ['./info-chiamata.component.scss'],
})
export class InfoChiamataComponent implements OnInit {
  @Input() idDaVisualizzare;
  @Output() indietroEmit = new EventEmitter<any>();
  showPage = false;
  loading = true;
  campagnaScelta;
  infoChiamata;
  infoCampagnaDomande = [];
  constructor(
    private callCenterService: CallCenterService,
    private campagnaService: CampagneService,
    private alertService: AlertService,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    this.callCenterService
      .recuperaInformazioni(this.idDaVisualizzare.id)
      .subscribe((resChiamata) => {
        this.infoChiamata = resChiamata.data;
        if (this.infoChiamata.idCampagna) {
          this.campagnaService
            .recuperaCampagna(this.infoChiamata.idCampagna)
            .subscribe(async (resCampagna) => {
              let risposte = this.infoChiamata.listaRisposte.split(',');
              this.campagnaScelta = resCampagna.data;
              this.campagnaScelta.domande_Associate = [];
              await this.populateDomande(0,risposte);
              this.showPage = true;
              this.loading = false;
            });
        } else {
          this.infoCampagnaDomande = [];
          this.showPage = true;
          this.loading = false;
        }
      });
  }

  async populateDomande(i,risposte) {
    this.userService.getQuestionarioById(this.campagnaScelta.listaQuestionari[i].id).subscribe((res)=>{
      res.data.listaDomande.forEach(element => {
        this.campagnaScelta.domande_Associate.push(element);
      });
      if(this.campagnaScelta.listaQuestionari[i+1]) {this.populateDomande(i+1,risposte);}
      else {

        this.campagnaScelta.domande_Associate.forEach((element, index) => {
          this.infoCampagnaDomande.push({
            domanda: element.domanda,
            risposta: risposte[index],
          });
        });
      }
    })
    return;
}
  indietro() {
    this.indietroEmit.emit();
  }
  elimina() {
    this.callCenterService
      .eliminaChiamata(this.idDaVisualizzare.id)
      .subscribe((res) => {
        this.alertService.success('Chiamata eliminata con successo');
        this.indietro();
      });
  }
}
