
<div *ngIf="showPage" class="content"
    style="margin-top: -10px;margin-left: -10px!important;margin-right: -12px!important; background-color: white;">
    <div class="container-fluid" style="padding: 0!important;overflow: hidden;">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-text">
                        <h4 *ngIf="!modifica" class="card-title text-center">Aggiungi {{ambiente.percorsiFormativi}}
                        </h4>
                        <h4 *ngIf="modifica" class="card-title text-center">Modifica {{ambiente.percorsiFormativi}}</h4>

                    </div>

                    <div class="card-content">
                        <form [formGroup]="createForm" class="form-horizontal">
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Titolo<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Nome" formControlName="nome"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" maxlength="30" />
                                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                            <div *ngIf="f.nome.errors.required">Campo titolo obbligatorio</div>
                                            <div *ngIf="f.nome.errors.pattern">Campo titolo invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Docente<span class="colorRed"> *</span></label>

                                <div class="col-sm-10">
                                    <!-- listaDocente va messo un array vuoto se null in ts -->
                                    <div class="form-group">
                                        <!-- Div in eventualità che il compilatorre non riaggiorni l'html,costringendoci a richiamare la
        variabile di listainsegnanti,in modo da forzare l'aggiornamento
    -->
                                        <p *ngIf="listaInsegnanti.length < 1" class="form-control">Nessun docente
                                            assegnato a quest'aula</p>


                                        <div *ngIf="listaInsegnanti.length > 0" class="select">
                                            <select class="form-control" formControlName="docente"
                                                [ngClass]="{ 'is-invalid': submitted && f.docente.errors }">
                                                <option *ngFor="let docente of listaInsegnanti"
                                                    [value]="docente.nome + ' ' + docente.cognome">{{docente.nome}}
                                                    {{docente.cognome}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.docente.errors?.required"
                                                class="invalid-feedback">Inserisci almeno un docente</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                       <div class="form-horizontal">
                        <div class="row">
                            <label class="col-sm-2 label-on-left">Ripetizione<span class="colorRed"> *</span></label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty char">

                                    <p-selectButton [options]="opzioni" [(ngModel)]="selectedOption"
                                        [ngModelOptions]="{standalone: true}"></p-selectButton>
                                    <div *ngIf="submitted" class="invalid-feedback">
                                        <div *ngIf="!selectedOption">Campo tipo di lezione obbligatorio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
</div>
                        <ng-container *ngIf="selectedOption === '0'">
                            <form [formGroup]="createFormDue" class="form-horizontal">

                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Data<span class="colorRed"> *</span></label>
                                    <div class="col-sm-2">
                                        <div class="form-group label-floating is-empty char">

                                            <p-calendar [locale]="ita" placeholder="Da" formControlName="dataDa"
                                                dateFormat="dd.mm.yy"></p-calendar>
                                            <div *ngIf="submitted && h.dataDa.errors" class="invalid-feedback">
                                                <div *ngIf="h.dataDa.errors.required">Campo data d'inizio obbligatorio
                                                </div>
                                            </div>
                                            <div *ngIf="erroreData" class="invalid-feedback">
                                                <div >Formato non corretto
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <div class="form-group label-floating is-empty char">

                                            <p-calendar [locale]="ita" placeholder="A" formControlName="dataA"
                                                dateFormat="dd.mm.yy"></p-calendar>
                                            <div *ngIf="submitted && h.dataA.errors" class="invalid-feedback">
                                                <div *ngIf="h.dataA.errors.required">Campo data di fine obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Ora<span class="colorRed"> *</span></label>
                                    <div class="col-sm-2">
                                        <div class="form-group label-floating is-empty char">

                                            <p-calendar [locale]="ita" placeholder="Dalle ore" formControlName="oraDa"
                                                timeOnly="true" showTime="true" hourFormat="24" dateFormat="dd.mm.yy">
                                            </p-calendar>
                                            <div *ngIf="submitted && h.oraDa.errors" class="invalid-feedback">
                                                <div *ngIf="h.oraDa.errors.required">Campo ora d'inizio obbligatorio
                                                </div>
                                                
                                            </div>
                                            <div *ngIf="erroreOra" class="invalid-feedback">
                                                <div >Formato non corretto
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <div class="form-group label-floating is-empty char">

                                            <p-calendar [locale]="ita" placeholder="Alle ore" formControlName="oraA"
                                                timeOnly="true" showTime="true" hourFormat="24" dateFormat="dd.mm.yy">
                                            </p-calendar>
                                            <div *ngIf="submitted && h.oraA.errors" class="invalid-feedback">
                                                <div *ngIf="h.oraA.errors.required">Campo ora di fine da obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </ng-container>

                        <ng-container *ngIf="selectedOption === '1'">
                            <form [formGroup]="createFormDue" class="form-horizontal">

                            <div class="row">
                                <label class="col-sm-2 label-on-left">Data<span class="colorRed"> *</span></label>
                                <div class="col-sm-2">
                                    <div class="form-group label-floating is-empty char">

                                        <p-calendar [locale]="ita" placeholder="Da" formControlName="dataDa"
                                            dateFormat="dd.mm.yy"></p-calendar>
                                        <div *ngIf="submitted && h.dataDa.errors" class="invalid-feedback">
                                            <div *ngIf="h.dataDa.errors.required">Campo data d'inizio obbligatorio</div>
                                        </div>
                                        <div *ngIf="erroreData" class="invalid-feedback">
                                            <div >Formato non corretto
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <div class="form-group label-floating is-empty char">

                                        <p-calendar [locale]="ita" placeholder="A" formControlName="dataA"
                                            dateFormat="dd.mm.yy"></p-calendar>
                                        <div *ngIf="submitted && h.dataA.errors" class="invalid-feedback">
                                            <div *ngIf="h.dataA.errors.required">Campo data di fine obbligatorio</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Ora<span class="colorRed"> *</span></label>
                                <div class="col-sm-2">
                                    <div class="form-group label-floating is-empty char">

                                        <p-calendar [locale]="ita" placeholder="Dalle ore" formControlName="oraDa"
                                            timeOnly="true" showTime="true" hourFormat="24" dateFormat="dd.mm.yy">
                                        </p-calendar>
                                        <div *ngIf="submitted && h.oraDa.errors" class="invalid-feedback">
                                            <div *ngIf="h.oraDa.errors.required">Campo ora d'inizio obbligatorio</div>
                                        </div>
                                        <div *ngIf="erroreOra" class="invalid-feedback">
                                            <div >Formato non corretto
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <div class="form-group label-floating is-empty char">

                                        <p-calendar [locale]="ita" placeholder="Alle ore" formControlName="oraA"
                                            timeOnly="true" showTime="true" hourFormat="24" dateFormat="dd.mm.yy">
                                        </p-calendar>
                                        <div *ngIf="submitted && h.oraA.errors" class="invalid-feedback">
                                            <div *ngIf="h.oraA.errors.required">Campo ora di fine da obbligatorio</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Nei giorni<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty char">

                                        <p-selectButton [options]="giorni" [(ngModel)]="selectedGiorni"
                                            [ngModelOptions]="{standalone: true}"  [multiple]="true"></p-selectButton>
                                        <div *ngIf="submitted && selectedOption === '1'" class="invalid-feedback">
                                            <div *ngIf="selectedGiorni?.length < 1">Campo giorni obbligatorio</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </form>
                        </ng-container>


                    </div>
                    <br>
                    <div class="text-right" style="margin-right: 5%;">
                        <hr>
                        <div class="btn-group" role="group" aria-label="Basic example">

                            <button type="button" (click)="clear()" class="btn btn-secondary"
                                aria-pressed="true">Indietro</button>
                        </div>
                        <div class="btn-group"  role="group" aria-label="Basic example" aria-pressed="true">
                            <button type="button" (click)="salvaForm()" [disabled]="loadingProva" class="btn btn-primary">Salva
                                <span *ngIf="loadingProva"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                            </button>
                        </div>
                    </div><br>
                </div>
            </div>
        </div>
    </div>
</div>