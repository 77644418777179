import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CreaAssistitoComponent } from './crea-assistito/crea-assistito.component';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ExternalComponentsModule } from '../../MODELS/COMPONENTS/externalComponents.module';
import { DettagliAssistitoComponent } from './dettagli-assistito/dettagli-assistito.component';
import { ModificaAssistitoComponent } from './modifica-assistito/modifica-assistito.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AssistitiComponent } from './assistiti.component';
import { AssociaGruppoComponent } from './associa-gruppo/associa-gruppo.component';
import { MatTableModule } from '@angular/material/table';
import { Checkbox, CheckboxModule } from 'primeng/checkbox';
import { ErrorInterceptor } from '../../HELPERS/error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoricoAssistitoComponent } from './storico-assistito/storico-assistito.component';
import { CalendarModule } from 'primeng/calendar';
import { StoricoTableComponent } from './storico-assistito/storico-table/storico-table.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    ExternalComponentsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatTableModule,
    CheckboxModule,
    CalendarModule,
    MatSlideToggleModule,
    MatChipsModule,
  ],

  declarations: [
    CreaAssistitoComponent,
    DettagliAssistitoComponent,
    ModificaAssistitoComponent,
    AssistitiComponent,
    AssociaGruppoComponent,
    StoricoAssistitoComponent,
    StoricoTableComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AssistitiModule {}
