import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, Message, PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { is } from 'date-fns/locale';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { AccountService, ShareService } from 'src/app/SERVICES/index';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-esercitazioni',
  templateUrl: './esercitazioni.component.html',
  styleUrls: ['./esercitazioni.component.scss']
})
export class EsercitazioniComponent implements OnInit {
  aulaInfo: any;
  selectedFile: File = null;
  fileSelezionato;
  searchform: FormGroup;
  searchformCancellati: FormGroup;
  selectedValue;
  selectedValueCancellati;
  appoggioPagina;
  appoggioPaginaCancellati;
  listafileappoggio;
  pagina = 1;
  paginaCancellati = 1;
  mostraInfo: boolean = false;
  abilita = true;
  abilitaCancellati = true;
  disabilitaAvanti = false;
  disabilitaAvantiCancellati = false;
  informazioni = false;
  idFile;
  cancellaFile = false;
  ricerca;
  listaFiltrata = []
  cliccato = true;
  carica;
  appoggioclick;
  appoggioclickCancellati;
  pagine = [];
  pagineCancellati = [];
  dettaglio = [];
  k;
  idRipristina;
  loading = false;
  j;
  infofile;
  listaFile;
  fileCancellati;
  allFileCancellati;
  allListaFile;
  numeroFile;
  numeroFileCancellati;
  gestione = true;
  dettagli = false;
  modifica = false;
  ricordaPagina = 1;
  ricordaPaginaCancellati = 1;
  ricordaLunghezza = 0;
  ricordaLunghezzaCancellati = 0;
  msgs: Message[] = [];
  vediCancellati = false;
  vediFile = true;
  position: string;
  flag = false;
  secondo = false;
  flagCancellati = false;
  secondoCancellati = false;
  link;
  @Output() vaiModificaFile = new EventEmitter<string>();
  @Output() vediDettagliFile = new EventEmitter<string>();
  gestioneFile: boolean = false;
  userID;
  userRole;
  ricercaAvanzata = false;
  visualizzaEsercitazioni = false;
  nomeEsercitazione;
  pil8 = true;
  pil9 = false;
  isDesktop = true;
  ambiente;
  constructor(
    private deviceService: DeviceDetectorService,
    private fileService: FileService,
    private alertService: AlertService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private env: EnvService,
    private router: Router,
    private shareService: ShareService,
  ) {
    this.ambiente = environment;
    this.isDesktop = deviceService.isDesktop();
    if (!this.env.DMS) {
      this.router.navigate([''])
    }
    this.userID = this.accountService.userValue.data.id
    this.userRole = this.accountService.ruolo
    this.link = this.env.apiUrl + "/elastic/download_doc?id="
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.searchformCancellati = this.formBuilder.group({
      searchCancellati: ['', Validators.required]
    })
    this.shareService.scegliAula$.subscribe((res) => {
      this.aulaInfo = res;
    });
  }

    ngOnInit(): void {

      this.primengConfig.ripple = true;
      this.pagina = 1;

    this.fileService.getAllAula(this.aulaInfo.idAula).subscribe((res) => {
      this.listaFile = res.data.risultati;
      // this.listaFile.forEach((element)=> {
      //   if(element && element.title) {
      //     element.title = decodeURI(element.title);
      //   }
      // })
      this.allListaFile = this.listaFile;
      // this.allListaFile.forEach((element)=> {
      //   if(element && element.title) {
      //     element.title = decodeURI(element.title);
      //   }
      // })
      this.fileCancellati = res.data.eliminati;
      // this.fileCancellati.forEach((element)=> {
      //   if(element && element.title) {
      //     element.title = decodeURI(element.title);
      //   }
      // })
      this.allFileCancellati = this.fileCancellati
      // this.allFileCancellati.forEach((element)=> {
      //   if(element && element.title) {
      //     element.title = decodeURI(element.title);
      //   }
      // })
      this.numeroFile = this.listaFile.length
      this.numeroFileCancellati = this.allFileCancellati.length;

        this.selectedValue = 10;
        this.selectedValueCancellati = 10;
        this.getNumeroPagine();
        this.getNumeroPagineCancellati();
        this.carica = true;
        this.appoggioclick = 1;
        this.appoggioclickCancellati = 1;
        this.gestioneFile = true;
      }, (error) => {

      });
    }

  ngDoCheck(){//quando nel filtra utenti non ritorna alcun utente la pagina si setta a 0, dunque cancellando il valore inserito riporta a pagina 1
    if(this.pagina===0){
      this.pagina=1;
    }
    if(this.paginaCancellati===0){
      this.paginaCancellati=1;
    }
  }

  //METODI PER OTTENERE LA QUANTITà DI PAGINE DA MOSTRARE APPENA CARICATA LA PAGINA, QUINDI CON SELECTEDVALUE PREIMPOSTATO A 10
    getNumeroPagine() {

      this.pagine.length = 0;//array per paginazione, richiamato in html per far comparire numero di pagine
      this.k = 1;//var per la paginazione
      this.appoggioclick = this.pagina;//si imposta la classe active sulla pagina in modo tale che risulta evidente quella su cui ci si trova

      if(this.listaFile){
        if (this.listaFile.length % this.selectedValue === 0) {//divide numero utenti per il valare da mostrare scelto per capire quante pagine servono
          this.appoggioPagina = this.listaFile.length / this.selectedValue;
        }
        else {
          this.appoggioPagina = Math.trunc((this.listaFile.length / this.selectedValue)) + 1;
        }
        for (let i = 0; i < this.appoggioPagina; i++) {//scorre appggioPagina che contiene il numero totale di pagine che bisogna generare
          this.pagine.push(this.k);
          this.k++;
        }
      }

    }
    getNumeroPagineCancellati() {//stessa logica di sopra, per utenti cancellato e in Cestino tab
      this.pagineCancellati.length = 0;
      this.j = 1;
      this.appoggioclickCancellati = this.paginaCancellati;

      if(this.fileCancellati){
        if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
          this.appoggioPaginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
        }
        else {
          this.appoggioPaginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
        }
        for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
          this.pagineCancellati.push(this.j);
          this.j++;
        }
      }
    }


    //STESSA LOGICA DI GETNUMEROPAGINE, MA STAVOLTA RICHIAMATO OGNI VOLTA CHE SI CAMBIA SELECTED VALUE
    cambiaValue(){

      if(this.listaFile){
        if (this.listaFile.length % this.selectedValue === 0) {
          this.appoggioPagina = this.listaFile.length / this.selectedValue;
          this.pagina=1;//ogni volta che si cambia il valore si fa partire l'utente da pagina 1
        }
        else {
          this.appoggioPagina = Math.trunc((this.listaFile.length / this.selectedValue)) + 1;
          this.pagina=1;

        }
        for (let i = 0; i < this.appoggioPagina; i++) {
          this.pagine.push(this.k);
          this.k++;
        }}
    }
    cambiaValueCancellati(){//stessa logica di sopra, per utenti cancellato e in Cestino tab

      if(this.listaCancellati){
        if (this.listaCancellati.length % this.selectedValueCancellati === 0) {
          this.appoggioPaginaCancellati = this.listaCancellati.length / this.selectedValueCancellati;
          this.paginaCancellati=1;
        }
        else {
          this.appoggioPaginaCancellati = Math.trunc((this.listaCancellati.length / this.selectedValueCancellati)) + 1;
          this.paginaCancellati=1;
        }
        for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
          this.pagineCancellati.push(this.k);
          this.k++;
        }}
    }

    //CLICK SU TASTO AVANTI
    next() {
      if (!(this.pagina * this.selectedValue > this.listaFile.length - 1))//se la pagina su cui ci troviamo moltiplicato per il valore da mostrare scelto è minore della quantità di utenti, allora va avanti; quindi non va avanti se stai all'ultima pagina
      {
        this.pagina++;
        this.appoggioclick = this.pagina;//per bottone attivo
      }
    }

    nextCancellati(){//stessa logica sopra
      if (!(this.paginaCancellati * this.selectedValueCancellati > this.fileCancellati.length - 1))
      {
        this.paginaCancellati++;
        this.appoggioclickCancellati = this.paginaCancellati;
      }
    }

    //CLICK SU TASTO INDIETRO PAGINAZIONE
    back() {
      if (this.pagina > 1) {
        this.pagina--;
      }
      if (this.pagina === 1) {
        this.abilita = true;
      }
      if (this.pagina * this.selectedValue <= this.listaFile.length - 1) {
        this.disabilitaAvanti = false;
      }

      this.appoggioclick = this.pagina;
    }
    backCancellati(){

      if (this.paginaCancellati > 1) {
        this.paginaCancellati--;
      }
      if (this.paginaCancellati === 1) {
        this.abilitaCancellati = true;
      }
      if (this.paginaCancellati * this.selectedValueCancellati <= this.fileCancellati.length - 1) {
        this.disabilitaAvantiCancellati = false;
      }
      this.appoggioclickCancellati = this.paginaCancellati;
    }

    //METODI PER CAMBIARE PAGINE QUANDO SI CLICCA PROPRIO SULLE PAGINE
    cambiaPagina(num) {
      this.cliccato = true;

      if (this.listaFile.length > num * this.selectedValue) {
        this.pagina = num + 1;
        this.appoggioclick = this.pagina;
      }

    }
    cambiaPaginaCancellati(num){
      this.cliccato = true;

      if (this.fileCancellati.length > num * this.selectedValueCancellati) {
        this.paginaCancellati = num + 1;
        this.appoggioclickCancellati = this.paginaCancellati;
      }
    }


    //CLICK INIZIO PAGINAZIONE
    paginaUno() {

      this.pagina = 1;
      this.appoggioclick = this.pagina;
    }
    paginaUnoCancellati() {

      this.paginaCancellati = 1;
      this.appoggioclickCancellati = this.paginaCancellati;
    }


    //CLICK FINE PAGINAZIONE
    fine() {

      if (this.listaFile.length % this.selectedValue === 0) {
        this.pagina = this.listaFile.length / this.selectedValue;
        this.appoggioclick = this.pagina;

      } else {
        this.pagina = Math.trunc((this.listaFile.length / this.selectedValue)) + 1;
        this.appoggioclick = this.pagina;
      }

    }
    fineCancellati(){
      if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
        this.paginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
        this.appoggioclickCancellati = this.paginaCancellati;

      } else {
        this.paginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
        this.appoggioclickCancellati = this.paginaCancellati;
      }

    }

    //RICERCA FILTRO PER LISTA UTENTI TAB
    searchInFile() {

      this.ricordaPagina=1;
      if(this.searchform.value.search.length>0){

        this.ricordaLunghezza = this.searchform.value.search.length;
        this.flag = true

        if (this.pagina !== 1 ) {
          this.secondo=true
          this.ricordaPagina = this.pagina;
        }
        if(!this.secondo){
        this.pagina=1;
        }
      } else if (this.ricordaLunghezza !== this.searchform.value.search.length && this.flag)
      {

        this.ricordaPagina=this.pagina;
        this.flag = false
      }

      let valore: string = this.searchform.value.search;
      this.listaFile = this.allListaFile;

      if (valore) {

        this.listafileappoggio = []

        this.listaFile.forEach(file => {


          if (file.title.toLowerCase().includes(valore.toLowerCase()) ||
              file.category.toLowerCase().includes(valore.toLowerCase()) ||
              file.group.toLowerCase().includes(valore.toLowerCase()) ||
              file.authors[0]?.name?.toLowerCase().includes(valore.toLowerCase()) ||
              file.extension.toLowerCase().includes(valore.toLowerCase())
          ) {
            if (!(this.listafileappoggio.find(x => x === file)))
            {
              this.listafileappoggio.push(file);
            }
          }
        });

        this.listaFile = this.listafileappoggio;
        this.numeroFile = this.listaFile.length
        this.getNumeroPagine();

        if(this.pagina>this.appoggioPagina)
        {
          this.pagina=this.appoggioPagina;
          this.appoggioclick = this.pagina;
        }


      } else if (!valore)
      {
        this.listaFile = this.allListaFile;
        if(this.listaFile)
        {
        this.numeroFile = this.listaFile.length;
        }
        else {
          this.numeroFile = 0;
        }
        this.getNumeroPagine();
      }
    }




    searchInFileCancellati(){
      this.ricordaPaginaCancellati =1;

      if(this.searchformCancellati.value.searchCancellati.length>0)
      {
        this.ricordaLunghezzaCancellati = this.searchformCancellati.value.searchCancellati.length;
        this.flagCancellati = true

        if (this.paginaCancellati !== 1 )
        {
          this.secondoCancellati=true
          this.ricordaPaginaCancellati = this.paginaCancellati;
        }

        if(!this.secondoCancellati){
        this.paginaCancellati=1;
        }
      } else if (this.ricordaLunghezzaCancellati !== this.searchformCancellati.value.searchCancellati.length && this.flagCancellati)
      {
        this.paginaCancellati = this.ricordaPaginaCancellati;
        this.flagCancellati = false
      }

      let valore: string = this.searchformCancellati.value.searchCancellati;
      this.fileCancellati = this.allFileCancellati;

      if (valore) {

        let listaFile = []
        this.fileCancellati.forEach(file =>
          {

          if (file.title.toLowerCase().includes(valore.toLowerCase()) ||
          file.category.toLowerCase().includes(valore.toLowerCase()) ||
          file.group.toLowerCase().includes(valore.toLowerCase()) ||
          file.authors[0]?.name?.toLowerCase().includes(valore.toLowerCase()) ||
          file.extension.toLowerCase().includes(valore.toLowerCase())
          ) {
            if (!(listaFile.find(x => x === file)))
            {
            listaFile.push(file);
            }
          }
        });
        this.fileCancellati = listaFile;
        this.numeroFileCancellati = this.fileCancellati.length

        if(this.numeroFileCancellati<=this.selectedValueCancellati)
        {
          this.paginaCancellati=1;
        }

        this.getNumeroPagineCancellati();

        if(this.paginaCancellati>this.appoggioPaginaCancellati)
        {
          this.paginaCancellati=this.appoggioPaginaCancellati;
          this.appoggioclickCancellati = this.paginaCancellati;
        }
      } else if (!valore)
      {
        this.fileCancellati = this.allFileCancellati;
        if(this.fileCancellati)
        {
        this.numeroFileCancellati = this.fileCancellati.length
        }
        else {
          this.numeroFileCancellati = 0;
        }
        this.getNumeroPagineCancellati();
      }
    }








    cancella(id,i) {

      this.cancellaFile=true;
      this.idFile=id;

      this.confirmationService.confirm({
        key:'esercitazioni',
        message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',

        icon: 'pi pi-exclamation-triangle',

        reject: () => {
          this.cancellaFile=false;
          this.loading=false;
          this.confirmationService.close();

        }
      });
    }

      //METODO PER PASSARE DALLA GESTIONE UTENTI AL VISUALIZZA INFO DI UN UTENTE
      visualizza(file) {
        this.fileSelezionato = file;
        this.gestione = false;
        this.dettagli=true;
      }

    vediInfo(id) {
      this.idFile = id;
      this.gestione=!this.gestione;
      this.dettagli=!this.dettagli;

    }

    indietroDainfo(){
      this.gestione=!this.gestione;
      this.dettagli=!this.dettagli;
      this.fileSelezionato= null;
    }

    indietroDaRicerca(){
      this.gestione=!this.gestione;
      this.ricercaAvanzata=!this.ricercaAvanzata;

    }

    vaiRicerca(){
      this.gestione = false;
      this.ricercaAvanzata=true;
    }

    onVoted(agreed: boolean) {
      agreed ? this.informazioni = false : this.informazioni = true;
    }

    editFile(id) {
      this.idFile = id;
      this.gestione=false;
      this.modifica=true;

    }

    ripristina(id, indice){
      this.idRipristina = id;
      this.confirmationService.confirm({
        message: 'Ripristinare il file?',

        icon: 'pi pi-exclamation-triangle',

        reject: () => {
          this.confirmationService.close();
          this.loading=false;
           //this.msgs = [{severity:'error', summary:'Rejected'}];
        }
    });
    }

    listaCancellati(){
      this.vediFile=false;
      this.vediCancellati=true;
    }

    listaPresenti(){
      this.vediFile=true;
      this.vediCancellati=false;
    }

    indietro(){

    this.fileService.getAllAula(this.aulaInfo.idAula).subscribe((res) => {
      this.listaFile = res.data.risultati;
      this.allListaFile = this.listaFile;

        this.numeroFile = this.listaFile.length
        this.selectedValue = 10;
        this.getNumeroPagine();
        this.carica = true;
        this.appoggioclick = 1;

        this.gestione=!this.gestione;
      this.modifica=!this.modifica;
      this.idFile= null;
      }, (error) => {

      });

      //this.listaFile.filter
    }

    accettaCancella(){
      this.loading=true;
        this.fileService.delete(this.idFile).subscribe((res1) => {

      this.fileService.getAllAula(this.aulaInfo.idAula).subscribe((res) => {

            this.listaFile = res.data.risultati;
            this.allListaFile = this.listaFile;

          this.fileCancellati=res.data.eliminati;
          this.allFileCancellati=this.fileCancellati

            this.numeroFile = this.listaFile.length
            this.numeroFileCancellati = this.allFileCancellati.length;


            if(this.listaFile){
              if (this.listaFile.length % this.selectedValue === 0) {
                this.appoggioPagina = this.listaFile.length / this.selectedValue;
                this.pagina=this.appoggioPagina;
              }
              else {
                this.appoggioPagina = Math.trunc((this.listaFile.length / this.selectedValue)) + 1;
              }
              for (let i = 0; i < this.appoggioPagina; i++) {
                this.pagine.push(this.k);
                this.k++;
              }}
              if(this.fileCancellati){
                if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
                  this.appoggioPaginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
                  this.paginaCancellati=this.appoggioPaginaCancellati;
                }
                else {
                  this.appoggioPaginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
                }
                for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
                  this.pagineCancellati.push(this.j);
                  this.j++;
                }}
            this.carica = true;
            this.gestioneFile = true;
            this.alertService.success(res1.status.description);
            this.confirmationService.close();
            this.cancellaFile=false;
            this.loading=false;
            this.idFile=null;
          }, (error) => {
            this.loading=false;
          }
         );
        });
    }

  accettaRipristina() {
    this.loading = true;
    this.fileService.restore(this.idRipristina).subscribe((res1) => {
      this.fileService.getAllAula(this.aulaInfo.idAula).subscribe((res) => {


            this.listaFile = res.data.risultati;
            this.allListaFile = this.listaFile;

          this.fileCancellati=res.data.eliminati;
          this.allFileCancellati=this.fileCancellati

            this.numeroFile = this.listaFile.length
            this.numeroFileCancellati = this.allFileCancellati.length;


            if(this.listaFile){
              if (this.listaFile.length % this.selectedValue === 0) {
                this.appoggioPagina = this.listaFile.length / this.selectedValue;
                this.pagina=this.appoggioPagina;
              }
              else {
                this.appoggioPagina = Math.trunc((this.listaFile.length / this.selectedValue)) + 1;
              }
              for (let i = 0; i < this.appoggioPagina; i++) {
                this.pagine.push(this.k);
                this.k++;
              }}
              if(this.fileCancellati){
                if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
                  this.appoggioPaginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
                  this.paginaCancellati=this.appoggioPaginaCancellati;
                }
                else {
                  this.appoggioPaginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
                }
                for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
                  this.pagineCancellati.push(this.j);
                  this.j++;
                }}
            this.carica = true;
            this.gestioneFile = true;
            this.loading=false;
            this.confirmationService.close();
            this.alertService.success(res1.status.description);
          }, (error) => {
            this.loading=false;
          });

        });
    }

    addFile(){
      this.gestione= false;
      this.modifica = true;
    }

  public onFileChanged(event, id) {
    this.selectedFile = null;
    this.selectedFile = event.target.files[0];
    const uploadFile = new FormData();
    uploadFile.append('imageFile', this.selectedFile);
    uploadFile.append('aulaInfo.idAula', this.aulaInfo.idAula);
    uploadFile.append('idEsercitazione', id);
    uploadFile.append('title', this.accountService.userValue.data.nome + ' ' + this.accountService.userValue.data.cognome);
    uploadFile.append('imageFile', this.selectedFile, this.selectedFile.name);
    uploadFile.append('visibGruppo', "");
    uploadFile.append('visibRuolo', "");
    uploadFile.append('visibUtente', "");
    uploadFile.append('category', "Aula Virtuale")
    uploadFile.append('group', this.aulaInfo.idAula.nomeAula)
    uploadFile.append('owner', this.accountService.userValue.data.id)
    uploadFile.append('author', "")
    uploadFile.append('index', "0")
    this.fileService.upload(uploadFile).subscribe((res) => {
      this.alertService.success(res.status.description)
    })
  }

      getEsercitazioni(esercitazione){
        this.fileService.getAllEsercitazione(esercitazione.id).subscribe((res)=>{
          this.listaFile = res.data.risultati;
          this.allListaFile = this.listaFile;
          this.nomeEsercitazione = esercitazione.title;
          this.visualizzaEsercitazioni = true;
        })
      }

  indietroVisualizzaEsercitazioni() {
    this.fileService.getAllAula(this.aulaInfo.idAula).subscribe((res) => {
      this.listaFile = res.data.risultati;
      this.allListaFile = this.listaFile;

          this.numeroFile = this.listaFile.length
          this.selectedValue = 10;
          this.getNumeroPagine();
          this.carica = true;
          this.appoggioclick = 1;
          this.visualizzaEsercitazioni = false;
        })
      }

    ordinaNome = false;
    ordinaCategoria = false;
    ordinaGruppo = false;
    ordinaData = false;
    ordinaEstensione = false;

    ordinaArray(filtro){
      if(filtro === 'nome'){
      if(!this.ordinaNome){
        this.listaFile.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : a.title.toLowerCase() > b.title.toLowerCase() ? 1 : 0);
        this.ordinaNome = true;
      }
      else{
        this.listaFile.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? -1 : a.title.toLowerCase() < b.title.toLowerCase() ? 1 : 0);
        this.ordinaNome = false;
      }
      this.allListaFile = this.listaFile
      this.ordinaCategoria = false;
      this.ordinaGruppo = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if(filtro === 'categoria'){
      if(!this.ordinaCategoria){
      this.listaFile.sort((a, b) => a.category.toLowerCase() < b.category.toLowerCase() ? -1 : a.category.toLowerCase() > b.category.toLowerCase() ? 1 : 0);
      this.ordinaCategoria = true;
      }
      else{
        this.listaFile.sort((a, b) => a.category.toLowerCase() > b.category.toLowerCase() ? -1 : a.category.toLowerCase() < b.category.toLowerCase() ? 1 : 0);
        this.ordinaCategoria=false;
      }
      this.allListaFile = this.listaFile
      this.ordinaNome = false;
      this.ordinaGruppo = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if(filtro === 'gruppo'){
      if(!this.ordinaGruppo){
      this.listaFile.sort((a, b) => a.group.toLowerCase() < b.group.toLowerCase() ? -1 : a.group.toLowerCase() > b.group.toLowerCase() ? 1 : 0);
      this.ordinaGruppo = true;
      }
      else{
      this.listaFile.sort((a, b) => a.group.toLowerCase() > b.group.toLowerCase() ? -1 : a.group.toLowerCase() < b.group.toLowerCase() ? 1 : 0);
      this.ordinaGruppo = false;
      }
      this.allListaFile = this.listaFile
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if(filtro === 'data'){
      if(!this.ordinaData){
      this.listaFile.sort((a, b) => a.uploadDate < b.uploadDate ? -1 : a.uploadDate > b.uploadDate ? 1 : 0);
      this.ordinaData = true;
      }
      else{
      this.listaFile.sort((a, b) => a.uploadDate > b.uploadDate ? -1 : a.uploadDate < b.uploadDate ? 1 : 0);
      this.ordinaData = false;
      }
      this.allListaFile = this.listaFile
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaGruppo = false;
      this.ordinaEstensione = false;
    }
    if(filtro === 'estensione'){
      if(!this.ordinaEstensione){
        this.listaFile.sort((a, b) => a.extension.toLowerCase() < b.extension.toLowerCase() ? -1 : a.extension.toLowerCase() > b.extension.toLowerCase() ? 1 : 0);
        this.ordinaEstensione = true;
      }
      else {
        this.listaFile.sort((a, b) => a.extension.toLowerCase() > b.extension.toLowerCase() ? -1 : a.extension.toLowerCase() < b.extension.toLowerCase() ? 1 : 0);
        this.ordinaEstensione = false;
      }
      this.allListaFile = this.listaFile
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaData = false;
      this.ordinaGruppo = false;
    }
    }

  cambiaPil(valore){
    if(valore === 'file'){
      this.pil8 = true;
      this.pil9 = false;
    }
    else{
      this.pil9 = true;
      this.pil8 = false;
    }
  }
  scaricaFile(link) {
    window.open(link, "_blank");
  }

  responsiveWidth() {    }

  }
