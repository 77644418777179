import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, DoCheck, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { Map } from 'leaflet';
import { UIChart } from 'primeng/chart';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { WebSocketService } from 'src/app/SERVICES/GENERAL/socket-client.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService, AlertService } from 'src/app/SERVICES/index';
import { DeviceTelemetryMetadata, JsonDevice, JsonStorico, StoricoIot } from 'src/app/MODELS/IOT/device';
import { threadId } from 'worker_threads';
import { Subscription } from 'rxjs';
import { isThisSecond } from 'date-fns';
declare var $: any;

@Component({
  selector: 'app-embed-control',
  templateUrl: './embed-control.component.html',
  styleUrls: ['./embed-control.component.scss']
})
export class EmbedControlComponent implements OnInit, OnDestroy, AfterViewInit,DoCheck {
  @ViewChild('chart') chart: UIChart;
  @ViewChild('chartPressione') chartPressione: UIChart;
  @ViewChild('zoomBPMDiv') zoomBPMDiv;
  @ViewChild('zoomDBPDiv') zoomDBPDiv;
  @ViewChild('zoomRESDiv') zoomRESDiv;
  @ViewChild('zoomBODiv') zoomBODiv;
  @ViewChild('zoomSCDiv') zoomSCDiv;
  searchform: FormGroup;
  ruolo;
  iot = false;
  caricato = false;
  ordinaBatteria = false;
  ordinaHeartRate = false;
  ordinaBreathingRate = false;
  ordinaNome = false;
  ordinaStato = false;
  ordinaAllarme = false;
  ordinaMovements = false;
  ordinamovementsIntensity = false;
  dispositivo;
  allListaDispositivi = [];
  listaSOS = [];
  bpm = null;
  bpmAllarme = false;
  bpmTempo;
  bpmSoglia;
  passi = null;
  passiAllarme = false;
  passiTempo;
  passiSoglia;
  tooltipTextMovement = "L’indicatore del movimento calcola il benessere del paziente basandosi su uno studio della Qilu University of Technology secondo il quale se in un arco temporale di 5 secondi il movimento raggiunge una certa ampiezza, questo indica una situazione di disagio o agitazione. Jiyong Xu et al 2020 J. Phys.: Conf. Ser. 1633 012137";
  temp = null;
  bloodOxygen = null;
  tempAllarme = false;
  tempTempo;
  tempBloodOxygen;
  bloodOxygenTempo;
  bloodOxygenSoglia;
  tempSoglia;
  pmax = null;
  pmaxAllarme = false;
  pmaxTempo;
  pmaxSoglia;
  pmin = null;
  pminAllarme = false;
  pminTempo;
  pminSoglia;
  spo2 = null;
  spo2Allarme = false;
  spo2Tempo;
  spo2Soglia = '';
  wearState = null;
  wearStateAllarme = false;
  wearStateTempo;
  wearStateSoglia;
  power = null;
  powerAllarme = false;
  powerTempo;
  powerSoglia;
  selectedAllarmi = false;
  showTable = true;
  showZoom = true;
  idDispositivoSelezionatoAzioni;
  //smartbed
  /**
   * respirazione
   */
  breathingRate:number
  /**
   * tempo della respirazione
   */
  breathingRateTempo
  /**
   * Intensità del movimento a letto
   * range:[0,5]
   */
  movementsStats:number = 0
  /**
   * mostra Allarme
   */
  movementsAllarme = false
  /**
   * Lo stato
   * range:0,1
   */
  movements
  /**
   * Il timestamp della misurazione
   */
  movementsStatsTempo
  movementsTempo
  /**
   * indica la soglia
   */
  respirazioneSoglia
  incremento = false;
  intervallo: any;
  intervallo2: any;
  intervallo3: any;
  nomeDispositivo;
  vuoto = false;
  mostraChartPressioneIot = false;
  mostraChartBattitoCardiacoIot = false;
  mostraChartTemperaturaIot = false;
  mostraChartContaPassiIot = false;
  mostraChartRespirazioneIot = false
  mostraChartBloodOxygenIot = false;
  optionsSPO2
  optionsSPO22
  dataPressioneIot: any;
  dataBattitoCardiacoIot: any;
  dataTemperaturaIot: any;
  dataBloodOxygenIot

  dataContaPassiIot: any;
  currentDate = new Date();
  optionsBPM: any;
  optionsPressione: any;
  optionsTemp: any;
  optionsPassi: any;
  dataOggi = this.datePipe.transform(this.currentDate, 'yyyy/MM/dd');
  dataOggiParsata = Date.parse(this.dataOggi);
  dataOggiTimestamp = this.dataOggiParsata / 1000;
  dataFineGiornata = this.dataOggiTimestamp + 86340;
  listaVuota = false;
  infoNota = false;
  delay = false;
  //VARIABILI AGGIUNTE
  graficoClicked = false;
  nomeTelemetriaClicked;
  dataOraClicked;
  idMisurazione;
  pressureData;
  heartRateData;
  bodyTemperatureData;
  respirazioneData
  bodyBloodOxygenData;
  stepCounterData;
  graficoTypeClicked: number;
  pressioneMinimaMarked: boolean;
  pressioneMassimaMarked: boolean;
  graficoPressioneWidth;
  graficoPressioneWidthConst = 3000;
  graficoBattitoWidth = 3000;
  graficoBattitoWidthConst = 3000;
  graficoTemperaturaWidth;
  graficoTemperaturaWidthConst = 3000;
  graficoRespirazioneWidth = 3000;
  graficoRespirazioneWidthConst = 3000;
  graficoBloodOxygenWidth = 3000;
  graficoBloodOxygenWidthConst = 3000;
  graficoPassiWidth = 3000;
  graficoPassiWidthConst = 3000;
  optionsBPM2: any;
  optionsPressione2: any;
  optionsTemp2: any;
  optionsRespirazione: any
  optionsRespirazione2: any
  optionsPassi2: any;
  dataPressioneIot2: any;
  dataBattitoCardiacoIot2: any;
  dataTemperaturaIot2: any;
  dataBloodOxygenIot2
  dataContaPassiIot2: any;
  dataRespirazioneIot
  dataRespirazioneIot2

  arraySelezionato = [];

  //-----Mappa-------
  mappaCaricata = false;
  errorMap = false;
  map: Map;
  public fullscreenOptions: { [key: string]: any } = {
    position: 'topleft',
    title: 'View Fullscreen',
    titleCancel: 'Exit Fullscreen',
  };
  fullScreenMap: boolean = false;
  today = new Date();
  mainLayer;
  traccia = true;
  open = true;
  markers = [];
  i = 0;
  latitudine;
  longitudine;
  firstTime = true;
  // -----/mappa------
  allarme;
  errorTxt;

  dataPressione;
  dataPassi;
  dataBattito
  dataTemperatura
  pressioneVisual = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
  PassiVisual = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
  BattitoVisual = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
  TempVisual = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
  date = this.getStartToday();
  noteExists : boolean;
  //#region Filtro telemetria
  /**
   * raggruppamento dispositivi, ogni gruppo mostra diversi grafici
   */
  gruppiDispositivi: string[] = []
  /**
  * Lista dispositivi
  */
  listaDispositivi = [];
  /**
   * filtra la listaDispositivi in base al tipo
   * se è una smartband, smartwatch etc...
   * @see {@link listaDispositivi}
   */
  filtroTipoDispositivo = []
  /**
   * quale dispositivo deve mostrare
   */
  filtroScelto = ""

  /**
   * Innesco del filtro precedente
   */
  triggerFiltroPrecendente=''

  /**
   * Indica il numero di caratteri della barra di ricerca.
   *
   * Se è minore del precedente ricaricare la lista e filtra di nuovo
   */
  lunghezzaCaratteriRicerca=0
  //#endregion

  //#region Parametrizzazione

lunghezzaGrafici=4000;

  //#endregion

  constructor(private shareService: ShareService, private iotService: IotService, private envService: EnvService,
    private router: Router, private webSocketService: WebSocketService, private datePipe: DatePipe,
    private alertService: AlertService,
    private accountService: AccountService, private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef){
    this.ruolo = this.accountService.ruolo
    this.iot = this.envService.IOT;

    if (localStorage.getItem('sos')) {
      this.listaSOS = JSON.parse(localStorage.getItem('sos'));
    }
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.shareService.datiEmbedded$.subscribe((res) => {
      if (res) {
        this.getTelemetria(res);
        this.vuoto = true;
      }
    })

    this.shareService.allarme$.subscribe((res) => {
      if (res) {
        if (res.monitoring) {

          this.listaSOS.push(res.monitoring)
          if (this.listaDispositivi) {
            this.listaDispositivi.forEach((dispositivo) => {
              if (dispositivo.id == res.monitoring.device) {
                if (!dispositivo.allarme) {
                  dispositivo.allarme = [];
                }
                dispositivo.allarme.push(res.monitoring)
              }
            })
            this.allListaDispositivi.forEach((dispositivo) => {
              if (dispositivo.id == res.monitoring.device) {
                if (!dispositivo.allarme) {
                  dispositivo.allarme = [];
                }
                dispositivo.allarme.push(res.monitoring)
              }
            })
          }
          // this.listaDispositivi.sort(function (x, y) { return !x.allarme ? 1 : !y.allarme ? -1 : 0; });
          // this.allListaDispositivi.sort(function (x, y) { return !x.allarme ? 1 : !y.allarme ? -1 : 0; });
          // this.listaDispositivi.sort((a, b) => a.allarme?.time < b.allarme?.time ? -1 : a.allarme?.time > b.allarme?.time ? 1 : 0);
          // this.allListaDispositivi.sort((a, b) => a.allarme?.time < b.allarme?.time ? -1 : a.allarme?.time > b.allarme?.time ? 1 : 0);

        }
        else if (res.emergenza) {
          this.listaSOS.push(res.emergenza)
        }
      }
      if(res && res.monitoring && res.monitoring.device === this.dispositivo.id) {
        this.dispositivo.allarme = [res.monitoring];
      }
      this.checkAllarme();
      if(this.filtroScelto=="" || this.filtroScelto!=this.triggerFiltroPrecendente){
        this.mostraTipoDispositivo('primaVolta')
      }else{
        this.mostraTipoDispositivo('allarmeSocket')
      }
    })


    //chiedo al BE quanti gruppi ci sono e costruisco i relativi pulsanti
    this.iotService.getListaDispositivi().subscribe((res)=>{
        let arrayObject:any[] = res.data
        this.popolaControlloGrafico(arrayObject[0])
        for(var i=0;i<arrayObject.length;i++){
          let ownerDevice = arrayObject[i]
          let deviceType = ownerDevice.deviceType
          let id = deviceType.id as number
          let tipologia = deviceType.tipologia as string
          if(tipologia == null){
            tipologia="test device"
            this.iotService.tipoDispositivi.set(tipologia,id)
          }else{
            this.iotService.tipoDispositivi.set(tipologia,id)
          }
        }
        //creo la mia lista, chiave rappresenta il gruppo, value è id per i grafici
        for (let [key, value]  of this.iotService.tipoDispositivi) {
          //value potrebbe essere utile per sviluppi futuri
          this.gruppiDispositivi.push(key)
      }
      let indexFiltroScelto = 0;
      while(!this.controllaSeMostrareGruppo(this.gruppiDispositivi[indexFiltroScelto]) && indexFiltroScelto<this.gruppiDispositivi.length)
      {
        indexFiltroScelto++;
      }
      if(indexFiltroScelto<this.gruppiDispositivi.length)
      {
        this.filtroScelto=this.gruppiDispositivi[indexFiltroScelto];
      }
    })

   this.getStoricoAllarmiDispositivi();

   this.callListTelemetrie();
    this.intervallo2 = setInterval(() => {
      if (this.filtroScelto !== 'smartbed') {
      this.iotService.aggiornaStato().subscribe((res) => {
        this.listaDispositivi.forEach(element => {
          res.data.forEach(element2 => {
            if (element.id == element2.device.id) {
              element.wearState = element2.telemetryData;
            }
          })
        })
        this.allListaDispositivi.forEach(element => {
          res.data.forEach(element2 => {
            if (element.id == element2.device.id) {
              element.wearState = element2.telemetryData;
            }
          })
        })
        this.filtroTipoDispositivo.forEach(element =>{
          res.data.forEach(element2 => {
            if (element.id == element2.device.id) {
              if(element2.telemetryData !==undefined && element2.telemetryData !==null){
                element.wearState = element2.telemetryData;
              }
            }
          })
        })
      })
      this.iotService.aggiornaBatteria().subscribe((res) => {
        this.listaDispositivi.forEach(element => {
          if (element.wearState == 3 || element.wearState == 2) {
            element.power = 101;
          }
          else {
            res.data.forEach(element2 => {
              if (element.id == element2.device.id) {
                element.power = element2.telemetryData;
              }
            })
          }
        })
        this.allListaDispositivi.forEach(element => {
          if (element.wearState == 3 || element.wearState == 2) {
            element.power = 101;
          }
          else {
            res.data.forEach(element2 => {
              if (element.id == element2.device.id) {
                element.power = element2.telemetryData;
              }
            })
          }
        })
        this.filtroTipoDispositivo.forEach(element =>{
          if (element.wearState == 3 || element.wearState == 2) {
            element.power = 101;
          }
          else {
            res.data.forEach(element2 => {
              if (element.id == element2.device.id) {
                if (element2.telemetryData !== undefined && element2.telemetryData !== null) {
                  element.power = element2.telemetryData;
                }
              }
            })
        }
        })
      })
      this.iotService.aggiornaMovimenti().subscribe((res)=>{
        this.listaDispositivi.forEach(element => {
          res.data.forEach(element2 => {
            //if(this.dispositivo.id==element2.device.id) return
            if (element.id == element2.device.id) {
              if(element2.telemetryData !==undefined && element2.telemetryData !==null){
                element.MovementsStats = element2.telemetryData;
              }
            }
          })
        })
        this.allListaDispositivi.forEach(element => {
          res.data.forEach(element2 => {
            //if(this.dispositivo.id==element2.device.id) return
            if (element.id == element2.device.id) {
              if(element2.telemetryData !==undefined && element2.telemetryData !==null){
                element.MovementsStats = element2.telemetryData;
              }
            }
          })
        })
        this.filtroTipoDispositivo.forEach(element =>{
          res.data.forEach(element2 => {
            //if(this.dispositivo.id==element2.device.id) return
            if (element.id == element2.device.id) {
              if(element2.telemetryData !==undefined && element2.telemetryData !==null){
                element.MovementsStats = element2.telemetryData;
              }
            }
          })
        })
      })
    }
    }, 10000)


  }
  showTelemetry = true;
  subscriptionSmartbed: Subscription;
  timeOutcallListTelemetrie: NodeJS.Timeout;
  callListTelemetrie() {
    if(this.subscriptionSmartbed) {
      this.subscriptionSmartbed.unsubscribe();
    }
    if (this.filtroScelto === 'smartbed'){
      this.subscriptionSmartbed = this.iotService.getListTelemetrieSmartBed().subscribe(resTelemetrie=> {
        this.showTelemetry = false;
        let heartRate = '--';
        let movementsIntensity = '--';
        let breathingRate = '--';
        let movements = '--';
        let wearState = '--';
        let date:any = '';
        if(resTelemetrie && resTelemetrie.data)
        resTelemetrie.data.forEach(telemetrie => {
          this.listaDispositivi.forEach(dispositivo => {
            if(telemetrie.deviceId == dispositivo.id) {
              if(telemetrie) {
                heartRate = '--';
                date = '--';
                movementsIntensity = '--';
                breathingRate = '--';
                movements = '--';
                wearState = '--';
              telemetrie.telemetry.forEach(telemetria => {

              // if (date == '') {
              //   date = telemetria.timestamp
              // } else if (date < telemetria.timestamp){
              //   date = telemetria.timestamp;
              // }
              if (telemetria.telemetryName == 'MovementsStats') {
                movementsIntensity = telemetria.telemetryData
              } else if (telemetria.telemetryName == 'BreathingRate') {
                breathingRate = telemetria.telemetryData
              } else if (telemetria.telemetryName == 'HeartRate') {
                heartRate = telemetria.telemetryData
              } else if (telemetria.telemetryName == 'Movements') {
                movements = telemetria.telemetryData
              } else if (telemetria.telemetryName == 'WearState') {
                wearState = telemetria.telemetryData;
                date = telemetria.timestamp;
              }
              });
              dispositivo.wearState = wearState;
              if (dispositivo.wearState == 4) {
                dispositivo.heartRate = heartRate;
              } else {
                dispositivo.heartRate = 0;
              }
              if (dispositivo.wearState == 4) {
                dispositivo.breathingRate = breathingRate;
              } else {
                dispositivo.breathingRate = 0;
              }
              if (dispositivo.wearState == 4) {
                dispositivo.movementsIntensity = movementsIntensity;
              } else {
                dispositivo.movementsIntensity = -1;
              }
              dispositivo.date = date;
              dispositivo.movements = movements;
            }
          }
          })
          this.allListaDispositivi.forEach(dispositivo => {
            heartRate = '--';
            date = '--';
            movementsIntensity = '--';
            breathingRate = '--';
            movements = '--';
            wearState = '--';
            if(telemetrie.deviceId == dispositivo.id) {
              if(telemetrie) {
              telemetrie.telemetry.forEach(telemetria => {

              // if (date == '') {
              //   date = telemetria.timestamp
              // } else if (date < telemetria.timestamp){
              //   date = telemetria.timestamp;
              // }
              if (telemetria.telemetryName == 'MovementsStats') {
                movementsIntensity = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'BreathingRate') {
                breathingRate = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'HeartRate') {
                heartRate = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'Movements') {
                movements = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'WearState') {
                wearState = telemetria.telemetryData;
                date = telemetria.timestamp;
              }
          })
              dispositivo.heartRate = heartRate;
              dispositivo.date = date;
              dispositivo.movementsIntensity = movementsIntensity;
              dispositivo.breathingRate = breathingRate;
              dispositivo.movements = movements;
              dispositivo.wearState = wearState;
            }
          }
          })
          this.filtroTipoDispositivo.forEach(dispositivo => {
            if(telemetrie.deviceId == dispositivo.id) {
              heartRate = '--';
              date = '--';
              movementsIntensity = '--';
              breathingRate = '--';
              movements = '--';
              wearState = '--';
              if(telemetrie) {
              telemetrie.telemetry.forEach(telemetria => {

              // if (date == '') {
              //   date = telemetria.timestamp
              // } else if (date < telemetria.timestamp){
              //   date = telemetria.timestamp;
              // }
              if (telemetria.telemetryName == 'MovementsStats') {
                movementsIntensity = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'BreathingRate') {
                breathingRate = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'HeartRate') {
                heartRate = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'Movements') {
                movements = telemetria.telemetryData;
              } else if (telemetria.telemetryName == 'WearState') {
                wearState = telemetria.telemetryData;
                date = telemetria.timestamp;
              }
          })
          dispositivo.wearState = wearState;
          if (dispositivo.wearState == 4) {
            dispositivo.heartRate = heartRate;
          } else {
            dispositivo.heartRate = 0;
          }
          if (dispositivo.wearState == 4) {
            dispositivo.breathingRate = breathingRate;
          } else {
            dispositivo.breathingRate = 0;
          }
          if (dispositivo.wearState == 4) {
            dispositivo.movementsIntensity = movementsIntensity;
          } else {
            dispositivo.movementsIntensity = -1;
          }
              dispositivo.date = date;
              dispositivo.movements = movements;
            }
          }
          })
          this.showTelemetry = true;
        });
        setTimeout(()=>{
          this.callListTelemetrie();
        },5000)
      })
    } else {

      this.timeOutcallListTelemetrie = setTimeout(()=>{
        this.callListTelemetrie();
      },5000)
    }
  }

  ngDoCheck(): void {
    if (this.accountService.listaEnv && !this.iot) {
      this.router.navigate(['./'])
    }
    // if (!this.iot) {
    //   this.router.navigate([''])
    // }
    this.cdRef.detectChanges();
  }


  ngAfterViewInit(): void {
    //-----Mappa-------
    this.mainLayer = L.tileLayer('https://{s}.tile.openStreetMap.org/{z}/{x}/{y}.png', {
      minZoom: 6,
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    //-------!mappa-------
  }

  ngOnDestroy(): void {
    if(this.subscriptionSmartbed) {
      this.subscriptionSmartbed.unsubscribe();
    }
    if(this.timeOutcallListTelemetrie) {
      clearTimeout(this.timeOutcallListTelemetrie)
    }
    localStorage.setItem('sos', JSON.stringify(this.listaSOS))
    this.shareService.changeIdDispositivo(null)
    this.shareService.changeAllarme(null)
    this.iotService.getSocket().subscribe((res) => { })
    clearInterval(this.intervallo);
    clearInterval(this.intervallo2);
    clearInterval(this.intervallo3);
    //pulisco le mappe del servizio, essendo un singleton
    this.iotService.clearMapTelemetry()
  }

  ngOnInit(): void {
    this.getAllarmiNonRisolti();
  }
  //FIX BUG-494 async altrimenti i valori di soglia potrebbero non apparire
  async initDispositivo() {
    this.nomeDispositivo = this.dispositivo.deviceName
    await this.ottieniTelemetria().then((data)=>{
      //FIX BUG-494
    });
    setTimeout(() => {
      this.shareService.changeIdDispositivo(this.dispositivo.id);
      this.iotService.getSocket().subscribe((res) => { })
    }, 15000);

    this.intervallo = setInterval(() => {
      if (!this.incremento) {
        if (this.bpmTempo) { this.bpmTempo++ }
        if (this.passiTempo) { this.passiTempo++ }
        if (this.tempTempo) { this.tempTempo++ }
        if (this.pmaxTempo) { this.pmaxTempo++ }
        if (this.pminTempo) { this.pminTempo++ }
        if (this.spo2Tempo) { this.spo2Tempo++ }
        if (this.wearStateTempo) { this.wearStateTempo++ }
        if (this.powerTempo) { this.powerTempo++ }
        if (this.breathingRateTempo) {this.breathingRateTempo++}
        if (this.movementsStatsTempo) {this.movementsStatsTempo++}
        this.incremento = true;
      }
      else {
        if (this.bpmTempo) { this.bpmTempo-- }
        if (this.passiTempo) { this.passiTempo-- }
        if (this.tempTempo) { this.tempTempo-- }
        if (this.pmaxTempo) { this.pmaxTempo-- }
        if (this.pminTempo) { this.pminTempo-- }
        if (this.spo2Tempo) { this.spo2Tempo-- }
        if (this.wearStateTempo) { this.wearStateTempo-- }
        if (this.powerTempo) { this.powerTempo-- }
        if (this.breathingRateTempo) {this.breathingRateTempo--}
        if (this.movementsStatsTempo) {this.movementsStatsTempo--}
        this.incremento = false;
      }
    }, 60000
    )
    this.opzioniGrafici()
    this.cambiaGrafici();
    this.caricato = true;
  }

  initGrafici() {
    console.warn = () => { };
    const dataOggiParsata = Date.parse(this.date.toString());
    const dataOggiTimestamp = dataOggiParsata / 1000;
    const dataFineGiornata = dataOggiTimestamp + 86340;

    this.opzioniGrafici();
    this.graficoPressione(dataOggiTimestamp, dataFineGiornata);
    this.graficiTelemetria(dataOggiTimestamp, dataFineGiornata)
  }

  /**
  * Aggiorna i valori telemetrici
  * @param res dalla socket
  */
  getTelemetria(res) {
    /**
     * deviceId: 88
     * timestamp: 1633611532000
     * values:
     * MovementsStats: 0 //Intensità
     */
    if (this.dispositivo.id == res.deviceId) {
      if (res.values.HeartRate || res.values.HeartRate == 0) {
        this.bpm = res.values.HeartRate;
        this.bpmTempo = res.timestamp;
      }
      if (res.values.StepCounter || res.values.StepCounter == 0) {
        this.passi = res.values.StepCounter;
        this.passiTempo = res.timestamp;
      }
      if (res.values.BodyTemperature || res.values.BodyTemperature == 0) {
        this.temp = res.values.BodyTemperature;
        this.tempTempo = res.timestamp;
      }
      if (res.values.SBP || res.values.SBP == 0) {
        this.pmax = res.values.SBP;
        this.pmaxTempo = res.timestamp;
      }
      if (res.values.DBP || res.values.DBP == 0) {
        this.pmin = res.values.DBP;
        this.pminTempo = res.timestamp;
      }
      if (res.values.BloodOxygen || res.values.BloodOxygen == 0) {
        this.spo2 = res.values.BloodOxygen;
        this.spo2Tempo = res.timestamp;
      }
      if (res.values.WearState || res.values.WearState == 0) {
        this.wearState = res.values.WearState;
        this.wearStateTempo = res.timestamp;
      }
      if (res.values.Power || res.values.Power == 0) {
        this.power = res.values.Power;
        this.powerTempo = res.timestamp;
      }
      if(res.values.BreathingRate || res.values.BreathingRate == 0){
        this.breathingRate = res.values.BreathingRate
        this.breathingRateTempo = res.timestamp
      }
      if ((res.values.MovementsStats !== undefined && res.values.MovementsStats !== null) || res.values.MovementsStats == 0) {
        this.movementsStats = res.values.MovementsStats;
        this.movementsStatsTempo = res.timestamp
        this.filtroTipoDispositivo.forEach(element=>{
          if(element.id==this.dispositivo.id){
            element.MovementsStats = this.movementsStats
          }
        })
      }
      if ((res.values.Movements !== undefined && res.values.Movements !== null) || res.values.Movements == 0) {
        this.movements = res.values.Movements;
        this.movementsTempo = res.timestamp
      }
    }
    this.mostraTipoDispositivo('socketGetTelemetry')
  }

  // indietro() {
  //   this.back.emit(true)
  //listaDispositivi
  // }
  //FIX BUG-494
  async ottieniTelemetria():Promise<any>{
    var promise = new Promise((resolve)=>{

      this.iotService.getTelemetry(this.dispositivo.id).subscribe((res) => {
        //si attiva quando selezioni il dispositivo e aggiorni i grafici
        //e il tempo iniziale nel monitoraggio
        //non agisce sugli array
        //se le trova negli allarmi popolo il set
        //in modo che dopo evito di sovrascriverle
        //duranta la chiamata al servizio getListaSoglie
        let soglieTrovate:Set<string> = new Set<string>() //add e has metodi utili
        if (res.data.length > 0) {
          res.data.forEach(element => {
            if (element.telemetryName == "HeartRate") {
              this.bpm = element.telemetryData;
              this.bpmTempo = new Date(element.timestamp).getTime();
              // console.log(element.timestamp);
              // console.log(new Date(element.timestamp));
              // console.log(this.bpmTempo);
              element.device.allarme.forEach(allarme => {
                if (allarme.telemetria == 'HeartRate') {
                  soglieTrovate.add(allarme.telemetria)
                  this.bpmSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            } //MODIFICA
            if (element.telemetryName == "BreathingRate") {
              this.breathingRate = element.telemetryData;
              this.breathingRateTempo = new Date(element.timestamp).getTime();
              element.device.allarme.forEach(allarme => {
                if (allarme.telemetria == 'BreathingRate') {
                  soglieTrovate.add(allarme.telemetria)
                  this.respirazioneSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
             //MODIFICA
             if (element.telemetryName == 'Movements') {
               if(element.telemetryData!==undefined && element.telemetryData !==null){
                this.movementsStats = element.telemetryData;
               }
              this.movementsStatsTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'Movements') {
                  soglieTrovate.add(allarme.telemetria)
                  //this.powerSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "StepCounter") {
              this.passi = element.telemetryData;
              this.passiTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'StepCounter') {
                  soglieTrovate.add(allarme.telemetria)
                  this.passiSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "BodyTemperature") {
              this.temp = element.telemetryData;
              this.tempTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'BodyTemperature') {
                  soglieTrovate.add(allarme.telemetria)
                  this.tempSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "BloodOxygen") {
              this.bloodOxygen = element.telemetryData;
              this.bloodOxygenTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'BloodOxygen') {
                  soglieTrovate.add(allarme.telemetria)
                  this.bloodOxygenSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "DBP") {
              this.pmin = element.telemetryData;
              this.pminTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'DBP') {
                  soglieTrovate.add(allarme.telemetria)
                  this.pminSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "BloodOxygen") {
              this.spo2 = element.telemetryData;
              this.spo2Tempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'BloodOxygen') {
                  soglieTrovate.add(allarme.telemetria)
                  this.spo2Soglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "SBP") {
              this.pmax = element.telemetryData;
              this.pmaxTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'SBP') {
                  soglieTrovate.add(allarme.telemetria)
                  this.pmaxSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "WearState") {
              this.wearState = element.telemetryData;
              //BUG-GAP-552 device appena creato dà wearstate 3 e timestamp null
              if(element.timestamp==null) return
              //il null dentro al costruttore restituisce 0 che non va bene
              this.wearStateTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'WearState') {
                  soglieTrovate.add(allarme.telemetria)
                  this.wearStateSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });
            }
            if (element.telemetryName == "Power") {
              this.power = element.telemetryData;
              this.powerTempo = new Date(element.timestamp).getTime();
              element.device.allarme?.forEach(allarme => {
                if (allarme.telemetria == 'Power') {
                  soglieTrovate.add(allarme.telemetria)
                  this.powerSoglia = allarme.minimo + '-' + allarme.massimo
                }
              });

            }
          });
        }
        this.iotService.getListaSoglieOdierne(this.dispositivo.id).subscribe((res)=>{
          //recuper la lista delle soglie nel campo thresholds
          let soglie:any[] = res.data.thresholds
          soglie.forEach(element => {
            if (element.telemetria == "HeartRate"&&!soglieTrovate.has(element.telemetria)) {
              this.bpmSoglia = element.minimo + '-' + element.massimo
            } //MODIFICA
            if (element.telemetria == "BreathingRate"&&!soglieTrovate.has(element.telemetria)) {
               this.respirazioneSoglia = element.minimo + '-' + element.massimo
            }
             //MODIFICA
             if (element.telemetria == 'Movements'&&!soglieTrovate.has(element.telemetria)) {

            }
            if (element.telemetria == "StepCounter"&&!soglieTrovate.has(element.telemetria)) {
                 this.passiSoglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "BodyTemperature"&&!soglieTrovate.has(element.telemetria)) {
                this.tempSoglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "BloodOxygen"&&!soglieTrovate.has(element.telemetria)) {
                this.bloodOxygenSoglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "DBP"&&!soglieTrovate.has(element.telemetria)) {
                  this.pminSoglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "BloodOxygen"&&!soglieTrovate.has(element.telemetria)) {
                 this.spo2Soglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "SBP"&&!soglieTrovate.has(element.telemetria)) {
                 this.pmaxSoglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "WearState"&&!soglieTrovate.has(element.telemetria)) {
                  this.wearStateSoglia = element.minimo + '-' + element.massimo
            }
            if (element.telemetria == "Power"&&!soglieTrovate.has(element.telemetria)) {
              this.powerSoglia = element.minimo + '-' + element.massimo
            }
          })
          //svuoto la lista per il prossimo controllo
          soglieTrovate.clear()
          this.vuoto=true
          resolve(res)
        })
        //-----Mappa-------
        if (this.firstTime && this.filtroScelto !== 'smartbed') {
          this.iotService.getDeviceLocationForId(this.dispositivo.id).subscribe((res) => {
            if (res.data) {
              this.latitudine = res.data.latitudine;
              this.longitudine = res.data.longitudine;
            }
            this.creaMappa();
          })
          this.firstTime = false;
        }
        //-----!Mappa-------
        // this.initGrafici();
      })
    })
    return promise
  }

  graficoPressione(dataOggi, dataFine) {
    let arrayPressMin = [];
    let arrayPressMax = [];
    let arrayLable = [];
    //arrayLable.push('24:00')
    //arrayLable.unshift('00:00')
    // arrayPressMin.push(null)
    // arrayPressMin.unshift(null)
    // arrayPressMax.push(null)
    // arrayPressMax.unshift(null)
    if (dataOggi && dataFine) {
      this.iotService.getMediaPressioneForDate(dataOggi, dataFine, this.dispositivo.id).subscribe((resPressioneIot) => {
        this.pressureData = resPressioneIot.data;
        this.graficoPressioneWidth = this.lunghezzaGrafici
        //this.changeWidth(this.pressureData);
        resPressioneIot.data.forEach(element2 => {
          //console.log("Provo ad aggiungere l'elemento ", element2),
          arrayPressMin.push(
            {
              id: element2.id,
              x: element2.ts, // + " GMT+2",
              y: element2.DBP
            }
          );
          //arrayPressMin.push(element2.DBP);
          arrayPressMax.push(
            {
              id: element2.id,
              x: element2.ts, // + " GMT+2",
              y: element2.SBP
            }
          );
          //arrayPressMax.push(element2.SBP);
          //arrayLable.push(this.datePipe.transform(element2.ts, 'HH:mm'))

        })
        let sogliaMinimaDBP;
        let sogliaMassimaDBP;
        let sogliaMinimaSBP;
        let sogliaMassimaSBP;
        if (this.pminSoglia) {
          let temp = this.pminSoglia.split('-');
          sogliaMinimaDBP = temp[0];
          sogliaMassimaDBP = temp[1];
        //   arrayPressMin.forEach(element => {
        //     sogliaMinimaDBP.push(temp[0])
        //     sogliaMassimaDBP.push(temp[1])
        //   });
        }
        if (this.pmaxSoglia) {
          let temp = this.pmaxSoglia.split('-');
          sogliaMinimaSBP = temp[0];
          sogliaMassimaSBP = temp[1];
        }

        this.dataPressioneIot = {
          labels: arrayLable,
          datasets: [
            {
              label: 'Pressione minima',
              borderColor: '#42A5F5',
              sogliaMinima: sogliaMinimaDBP,
              sogliaMassima: sogliaMassimaDBP,
              data: arrayPressMin,
              fill: false,
              lineTension: 0
            },
            {
              label: 'Pressione massima',
              borderColor: '#4CAF50',
              sogliaMinima: sogliaMinimaSBP,
              sogliaMassima: sogliaMassimaSBP,
              data: arrayPressMax,
              fill: false,
              lineTension: 0
            },
            // {
            //   label: 'Soglia Massima DBP',
            //   data: sogliaMassimaDBP,
            //   fill: false,
            //   borderColor: '#f54248',
            //   pointBackgroundColor: 'transparent',
            //   pointBorderColor: 'transparent',
            //   borderDash: [5, 10]
            // },
            // {
            //   label: 'Soglia Minima DBP',
            //   data: sogliaMinimaDBP,
            //   fill: false,
            //   borderColor: '#f54248',
            //   pointBackgroundColor: 'transparent',
            //   pointBorderColor: 'transparent',
            //   borderDash: [5, 10]
            // },
            // {
            //   label: 'Soglia Massima SBP',
            //   data: sogliaMassimaSBP,
            //   fill: false,
            //   borderColor: '#FFA726',
            //   pointBackgroundColor: 'transparent',
            //   pointBorderColor: 'transparent',
            //   borderDash: [5, 10]
            // },
            // {
            //   label: 'Soglia Minima SBP',
            //   data: sogliaMinimaSBP,
            //   fill: false,
            //   borderColor: '#FFA726',
            //   pointBackgroundColor: 'transparent',
            //   pointBorderColor: 'transparent',
            //   borderDash: [5, 10]
            // }
          ]
        };
        // this.dataPressioneIot2 = JSON.parse(JSON.stringify(this.dataPressioneIot));
        // for (const dataset of this.dataPressioneIot2.datasets) {
        //   dataset.hidden = true;
        // }
        this.changePointStyle(this.dataPressioneIot, this.pressureData, Grafico.Pressione);
        this.mostraChartPressioneIot = true;
      });

    }

    // console.log(arrayLable);

  }

  private changePointStyle(data: any, list: any[] = [], type?: Grafico): void {
    const pointDefaultStyle = 'circle';
    const pointNoteStyle = 'rectRot';
    const increment = type == Grafico.Pressione ? 2 : 1;
    const defaultSize = 3;
    const noteSize = 6;

    data.datasets[0].pointStyle = type == Grafico.Pressione ? [pointDefaultStyle, pointDefaultStyle] : [];
    data.datasets[0].radius = type == Grafico.Pressione ? [defaultSize, defaultSize] : [];
    if (type == Grafico.Pressione) {
      data.datasets[1].pointStyle = [pointDefaultStyle, pointDefaultStyle];
      data.datasets[1].radius = [defaultSize, defaultSize];
    }
    list.forEach((x, i: number) => {
      if(x && x.note && x.note.content) {
        if (type !== Grafico.Pressione && (i == 0 || i == list.length - 1)) {
          data.datasets[0].pointStyle[i] = pointDefaultStyle;
          data.datasets[0].radius[i] = defaultSize;
        }
        data.datasets[0].pointStyle[i + increment] = (x.note && x.note.content) ? pointNoteStyle : pointDefaultStyle;
        data.datasets[0].radius[i + increment] = (x.note && x.note.content) ? noteSize : defaultSize;
        if (type == Grafico.Pressione) {
          data.datasets[1].pointStyle[i + increment] = (x.note && x.note.content) ? pointNoteStyle : pointDefaultStyle;
          data.datasets[1].radius[i + increment] = (x.note && x.note.content) ? noteSize : defaultSize;
        }
      }
    });
  }

  graficiTelemetria(dataOggi, dataFine) {
    let arrayTimestamp1 = [];
    let arrayBattitoCardiaco = [];
    let arrayTimestamp = [];
    let arrayTemperatura = [];
    let arrayTimestamp2 = [];
    let arrayContaPassi = [];
    if (dataOggi && dataFine) {
      this.iotService.getTelemetryForDate(dataOggi, dataFine, this.dispositivo.id).subscribe((resContaPassiIot) => {
        resContaPassiIot.data.forEach(element2 => {

          let time = this.datePipe.transform(element2.timestamp, 'HH:mm');
          if (element2.telemetryName == 'StepCounter') {
            arrayTimestamp2.push(time);
            arrayContaPassi.push(element2.telemetryData);
          } else if (element2.telemetryName == 'HeartRate') {
            arrayTimestamp1.push(time);
            arrayBattitoCardiaco.push(element2.telemetryData);
          } else if (element2.telemetryName == 'BodyTemperature') {
            arrayTimestamp.push(time);
            arrayTemperatura.push(element2.telemetryData);
          } else if (element2.telemetryName == 'BloodOxygen') {
            arrayTimestamp.push(time);
            arrayTemperatura.push(element2.telemetryData);
          }

        })
        this.graficoPassi(arrayTimestamp2, arrayContaPassi);
        this.graficoBattito(arrayBattitoCardiaco);
        this.graficoTemperatura(arrayTimestamp, arrayTemperatura);
        this.graficoBloodOxygen(arrayTimestamp, arrayTemperatura);
      })
    }
  }
  graficoPassi(arrayTimestamp2, arrayContaPassi) {
    let sogliaMinima;
    let sogliaMassima;
    if (this.passiSoglia) {
      let temp = this.passiSoglia.split('-');
      sogliaMinima = temp[0];
      sogliaMassima = temp[1];
    }
    this.dataContaPassiIot = {
      labels: arrayTimestamp2,
      datasets: [
        {
          label: 'Passi',
          data: arrayContaPassi,
          sogliaMinima: sogliaMinima,
          sogliaMassima: sogliaMassima,
          fill: false,
          borderColor: '#ffff00',
          lineTension: 0
        },
      ]
    }

    this.dataContaPassiIot2 = JSON.parse(JSON.stringify(this.dataContaPassiIot));
    for (const dataset of this.dataContaPassiIot2.datasets) {
       dataset.hidden = true;
    }
    this.yAxisStepCounter();
    this.changePointStyle(this.dataContaPassiIot, this.stepCounterData);
    //this.scaleGraph(this.dataContaPassiIot,arrayTimestamp2,8,this.optionsPassi,this.optionsPassi2)
    this.mostraChartContaPassiIot = true;
  }
  graficoBattito(arrayBattitoCardiaco) {
    // arrayTimestamp1.push('24:00')
    // arrayTimestamp1.unshift('00:00')
    // arrayBattitoCardiaco.push(null)
    // arrayBattitoCardiaco.unshift(null)
    let sogliaMinima;
    let sogliaMassima;
    // console.log("Bpm Soglia: ", this.bpmSoglia)
    if (this.bpmSoglia) {
      let temp = this.bpmSoglia.split('-');
      sogliaMinima = temp[0];
      sogliaMassima = temp[1];
    }
    this.dataBattitoCardiacoIot = {
      // labels: arrayTimestamp1,
      datasets: [
        {
          label: 'Battito Cardiaco',
          data: arrayBattitoCardiaco,
          borderColor: '#ea4c89',
          sogliaMinima: sogliaMinima,
          sogliaMassima: sogliaMassima
        }
      ],
    }
    // this.dataBattitoCardiacoIot2 = JSON.parse(JSON.stringify(this.dataBattitoCardiacoIot));
    // for (const dataset of this.dataBattitoCardiacoIot2.datasets) {
    //    dataset.hidden = true;
    // }
    this.changePointStyle(this.dataBattitoCardiacoIot, this.heartRateData);
    this.mostraChartBattitoCardiacoIot = true;
  }

  /**
   * Serve per cambiare le opzioni di un grafico
   * @param dataOptions opzioni del grafico
   * @param unitStepSize distanza temporale del grafico, 1 un ora, 2 due ore etc...
   */
  changeOptions(dataOptions:any,unitStepSize:number){
    dataOptions.scales.xAxes[0].time.unitStepSize = unitStepSize
  }

  graficoTemperatura(arrayTimestamp, arrayTemperatura) {
    //arrayTimestamp.push('24:00')
    //arrayTimestamp.unshift('00:00')
    arrayTemperatura.push(null)
    arrayTemperatura.unshift(null)
    let sogliaMinima = [];
    let sogliaMassima = [];
    if (this.tempSoglia) {
      let temp = this.tempSoglia.split('-');
      arrayTemperatura.forEach(element => {
        sogliaMinima.push(temp[0])
        sogliaMassima.push(temp[1])
      });
    }
    this.dataTemperaturaIot = {
      labels: arrayTimestamp,
      datasets: [
        {
          label: 'Temperatura',
          data: arrayTemperatura,
          fill: false,
          borderColor: '#42A5F5',
          lineTension: 0
        },
        {
          label: 'Soglia Massima',
          data: sogliaMassima,
          fill: false,
          borderColor: '#f54248',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          borderDash: [5, 10]
        },
        {
          label: 'Soglia Minima',
          data: sogliaMinima,
          fill: false,
          borderColor: '#f54248',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          borderDash: [5, 10]
        }
      ],
    }
    this.dataTemperaturaIot2 = JSON.parse(JSON.stringify(this.dataTemperaturaIot));
    for (const dataset of this.dataTemperaturaIot2.datasets) {
       dataset.hidden = true;
    }
    this.changePointStyle(this.dataTemperaturaIot, this.bodyTemperatureData);
    this.mostraChartTemperaturaIot = true;
  }
  graficoBloodOxygen(arrayTimestamp, arrayTemperatura) {
    let sogliaMinima;
    let sogliaMassima;
    if (this.bloodOxygenSoglia) {
      let temp = this.bloodOxygenSoglia.split('-');
      sogliaMinima = temp[0];
      sogliaMassima = temp[1];
    }
    this.dataBloodOxygenIot = {
      labels: arrayTimestamp,
      datasets: [
        {
          label: 'Blood Oxygen',
          data: arrayTemperatura,
          fill: false,
          borderColor: '#42A5F5',
          lineTension: 0,
          sogliaMinima: sogliaMinima,
          sogliaMassima: sogliaMassima
        },
      ],
    }
    // this.dataBloodOxygenIot2 = JSON.parse(JSON.stringify(this.dataBloodOxygenIot));
    // for (const dataset of this.dataBloodOxygenIot2.datasets) {
    //    dataset.hidden = true;
    // }
    this.changePointStyle(this.dataBloodOxygenIot, this.bodyBloodOxygenData);
    this.mostraChartBloodOxygenIot = true;
  }


  graficoRespirazione(arrayTimestamp, arrayRespirazione) {
    let sogliaMinima;
    let sogliaMassima;
    if (this.respirazioneSoglia) {
      let temp = this.respirazioneSoglia.split('-');
      sogliaMinima = temp[0];
      sogliaMassima = temp[1];
    }
    this.dataRespirazioneIot = {
      labels: arrayTimestamp,
      datasets: [
        {
          label: 'Respirazione',
          data: arrayRespirazione,
          fill: false,
          borderColor: '#42A5F5',
          lineTension: 0,
          sogliaMinima: sogliaMinima,
          sogliaMassima: sogliaMassima,
        },
      ],
    }
    // this.dataRespirazioneIot2 = JSON.parse(JSON.stringify(this.dataRespirazioneIot));
    // for (const dataset of this.dataRespirazioneIot2.datasets) {
    //    dataset.hidden = true;
    // }
    this.changePointStyle(this.dataRespirazioneIot, this.respirazioneData);
    this.mostraChartRespirazioneIot = true;
  }







  //-----mappa-----
  creaMappa() {
    let cordinate = {
      lat: 40.86,
      lng: 14.28
    }
    navigator.geolocation.getCurrentPosition(res => {
      cordinate.lat = res.coords.latitude;
      cordinate.lng = res.coords.longitude;
      let a = 14
      let zoomLevel = a;
      //La mappa dava errore in quanto il dom non era caricato in tempo
      //questo è dovuto al fatto che il metodo viene richiamato
      //nel costruttore quindi non carica il dom (andrebbe richiamato in AfterViewInit)
      //quindi controllo se nel dom esiste se non esiste esco con return
      let element = document.getElementById('map') || null
      if(element==null) return
        this.map = L.map('map', {
          center: [cordinate.lat, cordinate.lng],
          zoom: zoomLevel,
          dragging: true
        })
        this.mainLayer.addTo(this.map);
        if (this.latitudine && this.longitudine) {
          this.addMarker2(this.latitudine, this.longitudine);
          this.zoom(this.latitudine, this.longitudine)
        }
        this.mappaCaricata = (true);
    },
      error => {
        this.mappaCaricata = (true);
        this.errorMap = true;
        // this.alertService.error('Per continuare, è necessario fornire i permessi di geolocalizzazione')
        // this.router.navigate([''])
      })

  }

  addMarker2(cordinate1, cordinate2) {
    this.markers = [];
    if (this.open) {
      let markerHtmlStyles = `
color: #2196F3;
font-size: xx-large;
display: block;
left: -1.5rem;
top: -1.5rem;
position: relative;
-webkit-text-stroke: 1px black;
`
      let smallIcon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
//        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`
      });
      //se la mappa non è definita non proseguire oltre
      if(this.map==undefined||this.map==null) return
      let marker =  L.marker([cordinate1, cordinate2], { icon: smallIcon }).addTo(this.map);
      this.markers.push(marker);
      this.i = this.i + 1;
      this.zoom(cordinate1, cordinate2)
    }
  }
  zoom(lat, lng) {
    var latlng = L.latLng(lat, lng);
    this.map.setView(latlng, 10);
  }

  checkAllarme() {
    if (this.dispositivo) {
      if (this.dispositivo.allarme) {
        this.bpmAllarme = false;
        this.passiAllarme = false;
        this.tempAllarme = false;
        this.pmaxAllarme = false;
        this.pminAllarme = false;
        this.spo2Allarme = false;
        this.wearStateAllarme = false;
        this.powerAllarme = false;
        this.movementsAllarme = false
        this.dispositivo.allarme.forEach(element => {
          if (element.telemetryName.includes("HeartRate")) {
            this.bpmAllarme = true;
          }
          if (element.telemetryName.includes("StepCounter")) {
            this.passiAllarme = true;
          }
          if (element.telemetryName.includes("BodyTemperature")) {
            this.tempAllarme = true;
          }
          if (element.telemetryName.includes("DBP")) {
            this.pminAllarme = true;
          }
          if (element.telemetryName.includes("BloodOxygen")) {
            this.spo2Allarme = true;
          }
          if (element.telemetryName.includes("SBP")) {
            this.pmaxAllarme = true;
          }
          if (element.telemetryName.includes("WearState")) {
            this.wearStateAllarme = true;
          }
          if (element.telemetryName.includes("Power")) {
            this.powerAllarme = true;
          }
          if(element.telemetryName.includes("MovementsStats")){
            this.movementsAllarme = true
          }
        });
      }
      else {
        this.bpmAllarme = false;
        this.passiAllarme = false;
        this.tempAllarme = false;
        this.pmaxAllarme = false;
        this.pminAllarme = false;
        this.spo2Allarme = false;
        this.wearStateAllarme = false;
        this.powerAllarme = false;
        this.movementsAllarme = false
      }
    }
  }
  async selezionaDispositivo(dispositivo) {
    if(!this.delay){
    this.popolaControlloGrafico(dispositivo)
    this.arraySelezionato = [];
    this.date = this.getStartToday();
    this.delay = true;
    this.svuotaTelemetria();
    this.pulisciGrafici()
    this.dispositivo = dispositivo;
    this.shareService.changeIdDispositivo(null)
    this.iotService.getSocket().subscribe((res) => { })
    setTimeout(() => {
      this.shareService.changeIdDispositivo(dispositivo.id)
      this.iotService.getSocket().subscribe((res) => {
        setTimeout(() => {
          this.delay = false;
        }, 5000);
       })
    }, 1000);
    this.filtroTipoDispositivo.forEach(device => {
      let selezionato = false;
      if (device.id == dispositivo.id) {
        selezionato = true;
      }
      this.arraySelezionato.push(selezionato)
    })
    await this.ottieniTelemetria().then((data)=>{
      //FIX BUG-494
      this.cambiaGrafici();
    // this.cambiaBattito();
    // this.cambiaTemp();
    // this.cambiaPassi();
    this.checkAllarme();
    if (this.filtroScelto !== 'smartbed')
    this.iotService.getDeviceLocationForId(this.dispositivo.id).subscribe((res) => {
      if (this.markers[0]) {
        this.map.removeLayer(this.markers[0]);
      }
      if (res.data) {
        this.addMarker2(res.data.latitudine, res.data.longitudine);
      }
    }, (error) => { });
    });
  }
  }
  /**
   * Pulisco i dati dei vecchi grafici
   */
  pulisciGrafici() {
    this.dataPressioneIot2=null
    this.dataBattitoCardiacoIot2=null
    this.dataTemperaturaIot2=null
    this.dataContaPassiIot2=null
    this.dataRespirazioneIot2=null
    this.dataBloodOxygenIot2 = null;

    this.dataPressioneIot=null
    this.dataBattitoCardiacoIot=null
    this.dataTemperaturaIot=null
    this.dataContaPassiIot=null
    this.dataRespirazioneIot=null
    this.dataBloodOxygenIot = null;

  }

  cleanSOS(sos) {
    this.listaSOS = this.listaSOS.filter(x => x.id !== sos.id);
    this.listaDispositivi.forEach(dispositivo => {
      if (sos.device == dispositivo.id) {
        dispositivo.allarme = null;
      }
    })
    this.checkAllarme();
  }

  svuotaTelemetria() {
    this.bpm = null;
    this.bpmAllarme = false;
    this.bpmTempo=undefined;
    this.passi = null;
    this.passiAllarme = false;
    this.passiTempo=undefined;
    this.temp = null;
    this.tempAllarme = false;
    this.tempTempo=undefined;
    this.pmax = null;
    this.pmaxAllarme = false;
    this.pmaxTempo=undefined;
    this.pmin = null;
    this.pminAllarme = false;
    this.pminTempo=undefined;
    this.spo2 = null;
    this.spo2Allarme = false;
    this.spo2Tempo=undefined;
    this.wearState = null;
    this.wearStateAllarme = false;
    this.wearStateTempo=undefined;
    this.power = null;
    this.powerAllarme = false;
    this.movementsAllarme = false
    this.movementsStatsTempo=undefined
    this.breathingRateTempo=undefined
    this.powerTempo=undefined
    this.bpmSoglia = null;
    this.tempSoglia = null;
    this.bloodOxygenSoglia = null;
    this.powerSoglia = null;
    this.pmaxSoglia = null;
    this.pminSoglia = null;
    this.wearStateSoglia = null;
    this.passiSoglia = null;
    this.respirazioneSoglia = null
    this.breathingRate=null
  }

  searchDispositivo() {
    let valore: string = this.searchform.value.search;
    this.listaDispositivi = this.allListaDispositivi;
    if (valore!=undefined || valore!=null) {
      if(this.lunghezzaCaratteriRicerca>valore.length){
        //utente ha cancellato un carattere ricarichiamo la lista
        this.listaDispositivi = this.allListaDispositivi;
        this.mostraTipoDispositivo('searchDispositivo')
        this.lunghezzaCaratteriRicerca=valore.length
      }else{
        this.lunghezzaCaratteriRicerca=valore.length
      }
      //filtriamo
      let listaAppoggio = []
      this.filtroTipoDispositivo.forEach(element => {
        if (element.deviceName.toLowerCase().includes(valore.toLowerCase())) {
          listaAppoggio.push(element)
        }
      });
      this.filtroTipoDispositivo = listaAppoggio
    }
  }


  ordinaArray(valore) {
    if (valore == 'power') {
      if (!this.ordinaBatteria) {
        this.listaDispositivi.sort((a, b) => b.power - a.power);
        this.allListaDispositivi.sort((a, b) => b.power - a.power);
        // this.listaDispositivi.sort((a, b) => a.power > b.power ? -1 : a.power < b.power ? 1 : 0);
        // this.allListaDispositivi.sort((a, b) => a.power > b.power ? -1 : a.power < b.power ? 1 : 0);
        this.listaDispositivi.sort(function (x, y) { return x.power != 101 ? -1 : y.power != 101 ? 1 : 0; });
        this.allListaDispositivi.sort(function (x, y) { return x.power != 101 ? -1 : y.power != 101 ? 1 : 0; });
        this.ordinaBatteria = true;
      }
      else {
        this.listaDispositivi.sort((a, b) => a.power - b.power);
        this.allListaDispositivi.sort((a, b) => a.power - b.power);
        // this.listaDispositivi.sort((a, b) => a.power < b.power ? -1 : a.power > b.power ? 1 : 0);
        // this.allListaDispositivi.sort((a, b) => a.power < b.power ? -1 : a.power > b.power ? 1 : 0);
        this.listaDispositivi.sort(function (x, y) { return x.power != 101 ? -1 : y.power != 101 ? 1 : 0; });
        this.allListaDispositivi.sort(function (x, y) { return x.power != 101 ? -1 : y.power != 101 ? 1 : 0; });

        this.allOrdinaFalse();

      }
    }
    else if (valore == 'nome') {
      if (!this.ordinaNome) {
        this.listaDispositivi.sort((a, b) => a.deviceName.toLowerCase() > b.deviceName.toLowerCase() ? -1 : a.deviceName.toLowerCase() < b.deviceName.toLowerCase() ? 1 : 0);
        this.allListaDispositivi.sort((a, b) => a.deviceName.toLowerCase() > b.deviceName.toLowerCase() ? -1 : a.deviceName.toLowerCase() < b.deviceName.toLowerCase() ? 1 : 0);
        this.ordinaNome = true;
      }
      else {
        this.listaDispositivi.sort((a, b) => a.deviceName.toLowerCase() < b.deviceName.toLowerCase() ? -1 : a.deviceName.toLowerCase() > b.deviceName.toLowerCase() ? 1 : 0);
        this.allListaDispositivi.sort((a, b) => a.deviceName.toLowerCase() < b.deviceName.toLowerCase() ? -1 : a.deviceName.toLowerCase() > b.deviceName.toLowerCase() ? 1 : 0);

        this.allOrdinaFalse();
      }
    }
    else if (valore == 'stato') {
      if (!this.ordinaStato) {
        this.listaDispositivi.sort((a, b) => a.wearState > b.wearState ? -1 : a.wearState < b.wearState ? 1 : 0);
        this.allListaDispositivi.sort((a, b) => a.wearState > b.wearState ? -1 : a.wearState < b.wearState ? 1 : 0);
        this.ordinaStato = true;
      }
      else {
        this.listaDispositivi.sort((a, b) => a.wearState < b.wearState ? -1 : a.wearState > b.wearState ? 1 : 0);
        this.allListaDispositivi.sort((a, b) => a.wearState < b.wearState ? -1 : a.wearState > b.wearState ? 1 : 0);

        this.allOrdinaFalse();
      }
    }
    else if (valore == 'allarme') {
      if (!this.ordinaAllarme) {
        this.listaDispositivi.sort(function (x, y) { return !x.allarme ? 1 : !y.allarme ? -1 : 0; });
        this.allListaDispositivi.sort(function (x, y) { return !x.allarme ? 1 : !y.allarme ? -1 : 0; });
        this.listaDispositivi.sort((a, b) => a.allarme?.time < b.allarme?.time ? -1 : a.allarme?.time > b.allarme?.time ? 1 : 0);
        this.allListaDispositivi.sort((a, b) => a.allarme?.time < b.allarme?.time ? -1 : a.allarme?.time > b.allarme?.time ? 1 : 0);
        this.ordinaAllarme = true;
      }
      else {
        this.listaDispositivi.sort(function (x, y) { return !x.allarme ? -1 : !y.allarme ? 1 : 0; });
        this.allListaDispositivi.sort(function (x, y) { return !x.allarme ? -1 : !y.allarme ? 1 : 0; });
        this.listaDispositivi.sort((a, b) => a.allarme?.time < b.allarme?.time ? 1 : a.allarme?.time > b.allarme?.time ? -1 : 0);
        this.allListaDispositivi.sort((a, b) => a.allarme?.time < b.allarme?.time ? 1 : a.allarme?.time > b.allarme?.time ? -1 : 0);
        this.allOrdinaFalse();
      }
    }
    else if(valore == 'Movements'){
      if (!this.ordinaMovements) {
        this.listaDispositivi.sort((a, b) => b.mov - a.mov);
      }
      // if (!this.ordinaMovements) {
      //   this.listaDispositivi.sort((a, b) => b.mov - a.power);
      // }
    } else if(valore == 'schedule') {
        // this.showTable = false;

        // this.listaDispositivi.sort((a,b)=> {
        //   return <any>new Date(b.date) - <any>new Date(a.date);
        // })
        // this.allListaDispositivi.sort((a,b)=> {
        //   return <any>new Date(b.date) - <any>new Date(a.date);
        // })
        // this.filtroTipoDispositivo.sort((a,b)=> {
        //   return <any>new Date(b.date) - <any>new Date(a.date);
        // })

        // this.showTable = true;


    } else if(valore == 'heartRate') {
        if(!this.ordinaHeartRate) {

          // this.listaDispositivi.sort((a, b) => {
          //   if (a.heartRate == '--') {
          //     a.heartRate = 0
          //   }
          //   if (b.heartRate == '--') {
          //     b.heartRate = 0
          //   }
          //   return a.heartRate < b.heartRate ? -1 : a.heartRate > b.heartRate ? 1 : 0

          // });

          // this.allListaDispositivi.sort((a, b) => {
          //   if (a.heartRate == '--') {
          //     a.heartRate = 0
          //   }
          //   if (b.heartRate == '--') {
          //     b.heartRate = 0
          //   }
          //   return a.heartRate < b.heartRate ? -1 : a.heartRate > b.heartRate ? 1 : 0

          // });
          this.filtroTipoDispositivo.sort((a, b) => {
            if (a.heartRate == '--') {
              a.heartRate = 0
            }
            if (b.heartRate == '--') {
              b.heartRate = 0
            }
            return a.heartRate < b.heartRate ? -1 : a.heartRate > b.heartRate ? 1 : 0

          });
          // let reDo = false;
          // do {
          //   reDo = false;
          //   this.filtroTipoDispositivo.forEach((element,index) => {
          //     const i = index + 1;
          //       if(element.wearState == )
          //     if(element.heartRate < this.filtroTipoDispositivo[i].heartRate) {
          //       const temp = this.filtroTipoDispositivo[i];
          //       this.filtroTipoDispositivo[i] = element;
          //       element = temp;
          //       reDo = true;
          //     }
          // })
          // }while(reDo)
          this.ordinaHeartRate = true;
          return;
        } else {

          // let reDo = false;
          // do {
          //   reDo = false;
          //   this.filtroTipoDispositivo.forEach((element,index) => {
          //     const i = index + 1;
          //     if(element.heartRate > this.filtroTipoDispositivo[i].heartRate) {
          //       const temp = this.filtroTipoDispositivo[i];
          //       this.filtroTipoDispositivo[i] = element;
          //       element = temp;
          //       reDo = true;
          //     }
          // })
          // }while(reDo)

          // this.allListaDispositivi.sort((a, b) => {
          //   if (a.heartRate == '--') {
          //     a.heartRate = 0
          //   }
          //   if (b.heartRate == '--') {
          //     b.heartRate = 0
          //   }
          //   return a.heartRate > b.heartRate ? -1 : a.heartRate < b.heartRate ? 1 : 0
          this.filtroTipoDispositivo.sort((a, b) => {
            if (a.heartRate == '--') {
              a.heartRate = 0
            }
            if (b.heartRate == '--') {
              b.heartRate = 0
            }
            return a.heartRate > b.heartRate ? -1 : a.heartRate < b.heartRate ? 1 : 0

          });
          this.allOrdinaFalse();
          return;
        }


    } else if(valore == 'co2') {
      if(!this.ordinaBreathingRate) {
        this.filtroTipoDispositivo.sort((a, b) => {
          if (a.breathingRate == '--') {
            a.breathingRate = 0
          }
          if (b.breathingRate == '--') {
            b.breathingRate = 0
          }
          return a.breathingRate > b.breathingRate ? -1 : a.breathingRate < b.breathingRate ? 1 : 0

        });
          // this.listaDispositivi.sort((a, b) => {
          //   if (a.breathingRate == '--') {
          //     a.breathingRate = -1
          //   }
          //   if (b.breathingRate == '--') {
          //     b.breathingRate = -1
          //   }
          //   return a.breathingRate < b.breathingRate ? -1 : a.breathingRate > b.breathingRate ? 1 : 0

          // });

          // this.allListaDispositivi.sort((a, b) => {
          //   if (a.breathingRate == '--') {
          //     a.breathingRate = -1
          //   }
          //   if (b.breathingRate == '--') {
          //     b.breathingRate = -1
          //   }
          //   return a.breathingRate < b.breathingRate ? -1 : a.breathingRate > b.breathingRate ? 1 : 0

          // });
          this.ordinaBreathingRate = true;
          return;
        } else {
          // this.listaDispositivi.sort((a, b) => {
          //   if (a.breathingRate == '--') {
          //     a.breathingRate = -1
          //   }
          //   if (b.breathingRate == '--') {
          //     b.breathingRate = -1
          //   }
          //   return a.breathingRate > b.breathingRate ? -1 : a.breathingRate < b.breathingRate ? 1 : 0
          // });

          // this.allListaDispositivi.sort((a, b) => {
          //   if (a.breathingRate == '--') {
          //     a.breathingRate = -1
          //   }
          //   if (b.breathingRate == '--') {
          //     b.breathingRate = -1
          //   }
          //   return a.breathingRate > b.breathingRate ? -1 : a.breathingRate < b.breathingRate ? 1 : 0

          // });

        this.filtroTipoDispositivo.sort((a, b) => {
          if (a.breathingRate == '--') {
            a.breathingRate = 0
          }
          if (b.breathingRate == '--') {
            b.breathingRate = 0
          }
          return a.breathingRate < b.breathingRate ? -1 : a.breathingRate > b.breathingRate ? 1 : 0

        });
          this.allOrdinaFalse();
          return;
        }


  } else if(valore == 'movementsIntensity') {
    if(!this.ordinamovementsIntensity) {

      this.filtroTipoDispositivo.sort((a, b) => {
        if (a.movementsIntensity == '--') {
          a.movementsIntensity = 0
        }
        if (b.movementsIntensity == '--') {
          b.movementsIntensity = 0
        }
        return a.movementsIntensity < b.movementsIntensity ? -1 : a.movementsIntensity > b.movementsIntensity ? 1 : 0

      });
        // this.listaDispositivi.sort((a, b) => {
        //   if (a.movementsIntensity == '--') {
        //     a.movementsIntensity = -1
        //   }
        //   if (b.movementsIntensity == '--') {
        //     b.movementsIntensity = -1
        //   }
        //   return a.movementsIntensity < b.movementsIntensity ? -1 : a.movementsIntensity > b.movementsIntensity ? 1 : 0

        // });

        // this.allListaDispositivi.sort((a, b) => {
        //   if (a.movementsIntensity == '--') {
        //     a.movementsIntensity = -1
        //   }
        //   if (b.movementsIntensity == '--') {
        //     b.movementsIntensity = -1
        //   }
        //   return a.movementsIntensity < b.movementsIntensity ? -1 : a.movementsIntensity > b.movementsIntensity ? 1 : 0

        // });
        this.ordinamovementsIntensity = true;
        return;
      } else {
        // this.listaDispositivi.sort((a, b) => {
        //   if (a.movementsIntensity == '--') {
        //     a.movementsIntensity = -1
        //   }
        //   if (b.movementsIntensity == '--') {
        //     b.movementsIntensity = -1
        //   }
        //   return a.movementsIntensity > b.movementsIntensity ? -1 : a.movementsIntensity < b.movementsIntensity ? 1 : 0
        // });

        // this.allListaDispositivi.sort((a, b) => {
        //   if (a.movementsIntensity == '--') {
        //     a.movementsIntensity = -1
        //   }
        //   if (b.movementsIntensity == '--') {
        //     b.movementsIntensity = -1
        //   }
        //   return a.movementsIntensity > b.movementsIntensity ? -1 : a.movementsIntensity < b.movementsIntensity ? 1 : 0

        // });

      this.filtroTipoDispositivo.sort((a, b) => {
        if (a.movementsIntensity == '--') {
          a.movementsIntensity = 0
        }
        if (b.movementsIntensity == '--') {
          b.movementsIntensity = 0
        }
        return a.movementsIntensity > b.movementsIntensity ? -1 : a.movementsIntensity > b.movementsIntensity ? 1 : 0

      });
        this.allOrdinaFalse();
        return;
      }


}

    this.mostraTipoDispositivo('ordinaArray')
  }

  allOrdinaFalse() {
    this.ordinaHeartRate = false;
    this.ordinaBatteria = false;
    this.ordinaStato = false;
    this.ordinaNome = false;
    this.ordinaAllarme = false;
    this.ordinaBreathingRate = false;
    this.ordinamovementsIntensity = false;
  }

  onSubmit() {
    if (this.errorTxt) {
      if (this.graficoClicked) {
        this.iotService.addDescrizioneAllarmGrafici(this.idMisurazione, this.errorTxt)
          .subscribe(res => {
            if (res.status.code == 200) {
              this.refreshGrafici();
            }
          });
      }
      else {
        this.iotService.addDescrizioneAllarm(this.allarme.id, this.errorTxt).subscribe((res) => {
          this.listaSOS.find(x => x.id == this.allarme.id).nota = this.errorTxt
          //quando inserisci la nota dalla lista allarmi (no dalla lista storico)
          //aggiorno i grafici
          this.cambiaGrafici()
        }, (error) => {
        });
      }
    }
  }

  private refreshGrafici() {
    switch (this.graficoTypeClicked) {
      case Grafico.Pressione: {
        this.cambiaPressione();
      } break;
      case Grafico.Battito: {
        this.cambiaBattito();
      } break;
      case Grafico.Temperatura: {
        this.cambiaTemp();
      } break;
      case Grafico.Contapassi: {
        this.cambiaPassi();
      } break;
      case Grafico.Respirazione:{
        this.cambiaRespirazione()
      }break;
    }
  }

  onNoteDelete() {
    if (this.graficoClicked) {
      this.iotService.deleteDescrizioneAllarmiGrafici(this.idMisurazione).subscribe(res => {
        if (res.status.code == 200) {
          this.refreshGrafici();
        }
      })
    }
  }

  /**
   * L'utente preme icona nota dell'allarme nello storico
   */
  salvaAllarme(allarme, info?) {
    this.graficoClicked = false;
    this.allarme = allarme;
    this.errorTxt = allarme.nota;
    this.infoNota = false;
    if (info == 1) {
      this.infoNota = true;
    }
  }

  cambiaPressione() {
    if (this.date) {
      const dataOggiParsata = Date.parse(this.date.toString());
      const dataOggiTimestamp = dataOggiParsata / 1000;
      const dataFineGiornata = dataOggiTimestamp + 86340;

      this.graficoPressione(dataOggiTimestamp, dataFineGiornata);
    }
  }
  cambiaBattito() {
    if (this.date) {
      const dataOggiParsata = Date.parse(this.date.toString());
      const dataOggiTimestamp = dataOggiParsata / 1000;
      const dataFineGiornata = dataOggiTimestamp + 86340;
      const arrayTimestamp1 = [];
      const arrayBattitoCardiaco = [];
      this.iotService.getTelemetryHeart(this.dispositivo.id, dataOggiTimestamp, dataFineGiornata).subscribe((res) => {
        this.heartRateData = res.data;
        this.showZoom = false;
        this.graficoBattitoWidth = this.lunghezzaGrafici
        this.graficoBattitoWidthConst = this.graficoBattitoWidth;
        setTimeout(()=>{this.showZoom = true;})
        // arrayBattitoCardiaco.push(
        //       {
        //         x: this.datePipe.transform(dataOggiParsata, 'yyyy-MM-ddTHH:mm:ss.SSSZ z'),
        //         y: null
        //       }
        //     );
        // arrayBattitoCardiaco.unshift(
        //   {
        //     x: this.datePipe.transform(dataFineGiornata*1000, 'yyyy-MM-ddTHH:mm:ss.SSSZ z'),
        //     y: null
        //   }
        // );
        this.changeWidth(this.heartRateData);
        res.data.forEach(el => {
          if (el) {
            // arrayTimestamp1.push(this.datePipe.transform(el.timestamp, 'HH:mm'));
            //console.log("ELEMENTO RICEVUTO: ", el);
            // arrayBattitoCardiaco.push(el.telemetryData);
            arrayBattitoCardiaco.push(
              {
                id: el.id,
                x: el.timestamp, // + " GMT+2",
                y: el.telemetryData
              }
            );
          } else {

            // arrayTimestamp1.push(null);
            arrayTimestamp1.push(arrayTimestamp1[arrayTimestamp1.length-2])
            arrayBattitoCardiaco.push(null);
            // arrayBattitoCardiaco.push(null);
          }
        });
        this.graficoBattito(arrayBattitoCardiaco);
        if(this.filtroScelto=="smartbed"){
          this.changeOptions(this.optionsBPM,1)
        }else{
          this.changeOptions(this.optionsBPM,2)
        }
      });
    }
  }

  cambiaTemp() {
    if (this.date) {
      const dataOggiParsata = Date.parse(this.date.toString());
      const dataOggiTimestamp = dataOggiParsata / 1000;
      const dataFineGiornata = dataOggiTimestamp + 86340;
      const arrayTimestamp = [];
      const arrayTemperatura = [];
      this.iotService.getTelemetryTemperature(this.dispositivo.id, dataOggiTimestamp, dataFineGiornata).subscribe((res) => {
        this.bodyTemperatureData = res.data;
        this.graficoTemperaturaWidth = this.lunghezzaGrafici
        //  this.changeWidth(this.bodyTemperatureData);
        res.data.forEach(el => {
          arrayTimestamp.push(this.datePipe.transform(el.timestamp, 'HH:mm'));
          arrayTemperatura.push(el.telemetryData);
        });
        this.graficoTemperatura(arrayTimestamp, arrayTemperatura);
      });
    }
  }
  cambiaRespirazione() {
    if (this.date) {
      const dataOggiParsata = Date.parse(this.date.toString());
      const dataOggiTimestamp = dataOggiParsata / 1000;
      const dataFineGiornata = dataOggiTimestamp + 86340;
      const arrayTimestamp = [];
      const arrayRespirazione = [];
      this.iotService.getTelemetryRespirazione(this.dispositivo.id, dataOggiTimestamp, dataFineGiornata).subscribe((res) => {
        this.respirazioneData = res.data;
        this.showZoom = false;
        this.graficoRespirazioneWidth = this.lunghezzaGrafici
        this.graficoRespirazioneWidthConst = this.graficoRespirazioneWidth;
        setTimeout(()=>{this.showZoom = true;})
        //this.changeWidth(this.respirazioneData);
        res.data.forEach(el => {
          if(el){
            arrayRespirazione.push(
              {
                id: el.id,
                x: el.timestamp, // + " GMT+2",
                y: el.telemetryData
              }
            );
          } else {
            // // arrayTimestamp.push(null)
            // arrayTimestamp.push(arrayTimestamp[arrayTimestamp.length-2])
            // arrayRespirazione.push(null);
            // // arrayRespirazione.push(null)
          }
        });
        this.graficoRespirazione(arrayTimestamp, arrayRespirazione);
        if(this.filtroScelto=="smartbed"){
          this.changeOptions(this.optionsRespirazione,1)
        }
      });
    }
  }


  cambiaBloodOxygen() {
    if (this.date) {
      const dataOggiParsata = Date.parse(this.date.toString());
      const dataOggiTimestamp = dataOggiParsata / 1000;
      const dataFineGiornata = dataOggiTimestamp + 86340;
      const arrayTimestamp = [];
      const arrayBloodOxygen = [];
      this.iotService.getTelemetryBloodOxygen(this.dispositivo.id, dataOggiTimestamp, dataFineGiornata).subscribe((res) => {
        this.bodyBloodOxygenData = res.data;
        this.showZoom = false;
        this.graficoBloodOxygenWidth = this.lunghezzaGrafici
        this.graficoBloodOxygenWidthConst = this.graficoBloodOxygenWidth;
        setTimeout(()=>{this.showZoom = true;})
        res.data.forEach(el => {
          if(el){
            arrayBloodOxygen.push(
              {
                id: el.id,
                x: el.timestamp, // + " GMT+2",
                y: el.telemetryData
              }
            );
          } else {
            // arrayTimestamp.push(null)
            arrayTimestamp.push(arrayTimestamp[arrayTimestamp.length-2])
            arrayBloodOxygen.push(null);
            // arrayRespirazione.push(null)
          }
        });
        this.graficoBloodOxygen(arrayTimestamp, arrayBloodOxygen);
        if(this.filtroScelto!=="smartbed"){
          this.changeOptions(this.optionsSPO2,1)
        }
      });
    }
  }

  cambiaPassi() {
    if (this.date) {
      const dataOggiParsata = Date.parse(this.date.toString());
      const dataOggiTimestamp = dataOggiParsata / 1000;
      const dataFineGiornata = dataOggiTimestamp + 86340;
      const arrayTimestamp2 = [];
      const arrayContaPassi = [];
      this.iotService.getTelemetryPassi(this.dispositivo.id, dataOggiTimestamp, dataFineGiornata).subscribe((res) => {
        this.stepCounterData = res.data;
        this.graficoPassiWidth =this.lunghezzaGrafici
        // this.changeWidth(this.stepCounterData);
        res.data.forEach(el => {
          arrayContaPassi.push(
            {
              id: el.id,
              x: el.timestamp, //+ " GMT+2",
              y: el.telemetryData
            }
          );
        });
        this.graficoPassi(arrayTimestamp2, arrayContaPassi);
      });
    }
  }

  opzioniGrafici() {
    this.optionsBPM = {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 210,
            min: 30,
            stepSize: 30
          }
        }],
        xAxes: [{
          ticks: {
            max: '24:00',
            min: '00:00',
            stepSize: '60'
          },
          type: 'time',
          time: {
            format: "HH:mm",
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              'minute': 'HH:mm',
              'hour': 'HH:mm',
              min: '00:00',
              max: '23:59'
            },
          }
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 1;
            if (i < 0) return '';
            const element = this.heartRateData[i];
            if (element)
              return element.note ? element.note.content : '';
            return '';
          }
        }
      }

    }

    this.optionsBPM2 = {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        fontColor:'transparent',
        yAxes: [{
          ticks: {
            max: 210,
            min: 30,
            stepSize: 30,
          },
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        xAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
          },
          gridLines: {
            drawBorder: false,
            display: true
          }
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    }

    this.optionsTemp = {
      scales: {
        yAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 40,
            min: 32,
            stepSize: 2
          }
        }],
        xAxes: [{
          ticks: {
            max: '24:00',
            min: '00:00',
            stepSize: 2
          },
          type: 'time',
          time: {
            format: "HH:mm",
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              'minute': 'HH:mm',
              'hour': 'HH:mm',
              min: '00:00',
              max: '24:00'
            },
          }
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 1;
            if (i < 0) return '';
            const element = this.bodyTemperatureData[i];
            if (element)
              return element.note ? element.note.content : '';
            return ''

          }
        }
      }
    }

    this.optionsTemp2 = {
      scales: {
        fontColor:'transparent',
        yAxes: [{
          ticks: {
            max: 40,
            min: 32,
            stepSize: 5
          },
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        xAxes: [{
          fontColor:'transparent',
          ticks: {
          fontColor:'transparent',
        },
        gridLines: {
          drawBorder: false,
          display: true
        }
        }]
      },
      legend: {
        display: false
      },
    }

    this.optionsSPO2 = {
      scales: {
        yAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 40,
            min: 0,
            stepSize: 5
          }
        }],
        xAxes: [{
          ticks: {
            max: '24:00',
            min: '00:00',
            stepSize: '60'
          },
          type: 'time',
          time: {
            format: "HH:mm",
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              'minute': 'HH:mm',
              'hour': 'HH:mm',
              min: '00:00',
              max: '24:00'
            },
          }
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 1;
            if (i < 0) return '';
            const element = this.bodyBloodOxygenData[i];
            if (element)
              return element.note ? element.note.content : '';
            return ''

          }
        }
      }
    }

    this.optionsSPO22 = {
      scales: {
        fontColor:'transparent',
        yAxes: [{
          ticks: {
            max: 100,
            min: 0,
            stepSize: 10
          },
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        xAxes: [{
          fontColor:'transparent',
          ticks: {
          fontColor:'transparent',
        },
        gridLines: {
          drawBorder: false,
          display: true
        }
        }]
      },
      legend: {
        display: false
      },
    }

    this.optionsRespirazione = {
      scales: {
        yAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 40,
            min: 0,
            stepSize: 5
          }
        }],
        xAxes: [{
          ticks: {
            max: '24:00',
            min: '00:00',
            stepSize: '60'
          },
          type: 'time',
          time: {
            format: "HH:mm",
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              'minute': 'HH:mm',
              'hour': 'HH:mm',
              min: '00:00',
              max: '24:00'
            },
          }
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 1;
            if (i < 0) return '';
            const element = this.respirazioneData[i];
            if (element)
              return element.note ? element.note.content : '';
            return ''
          }
        }
      }

    }

    this.optionsRespirazione2 = {
      scales: {
        fontColor:'transparent',
        yAxes: [{
          ticks: {
            max: 40,
            min: 0,
            stepSize: 5
          },
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        xAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
          },
          gridLines: {
            drawBorder: false,
            display: true
          }
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    }

    this.optionsPassi = {
      scales: {
        yAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
          }
        }],
        xAxes: [{
          ticks: {
            max: '24:00',
            min: '00:00',
            stepSize: '60'
          },
          type: 'time',
          time: {
            format: "HH:mm",
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              'minute': 'HH:mm',
              'hour': 'HH:mm',
              min: '00:00',
              max: '24:00'
            },
          }
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 1;
            if (i < 0) return '';
            const element = this.stepCounterData[i];
            if (element)
              return element.note ? element.note.content : '';
            return '';
          }
        }
      }
    }

    this.optionsPassi2 = {
      scales: {
        yAxes: [{
          ticks: {},
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        fontColor: 'transparent',
        xAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
          },
          gridLines: {
            drawBorder: false,
            display: true
          }
        }]
      },
      legend: {
        display: false
      },
    }

    this.optionsPressione = {
      legend: {
        display: false,
        labels: {
           filter: function(label) {
              if (label.text == 'Pressione minima' || label.text == 'Pressione massima') return true;           }
        }
     },
      scales: {
        yAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 210,
            min: 30,
            stepSize: 30
          }
        }],
        xAxes: [{
          ticks: {
            max: '24:00',
            min: '00:00',
            stepSize: '60'
          },
          type: 'time',
          time: {
            format: "HH:mm",
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              'minute': 'HH:mm',
              'hour': 'HH:mm',
              min: '00:00',
              max: '24:00'
            },
          }
        }],
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 2;
            if (i < 0) return '';
            const element = this.pressureData[i];
            if (element)
              return element.note ? element.note.content : '';
            return ''
          }
        }
      },pan: {
        enabled: true,
        mode: "x",
        speed: 10,
        threshold: 10
      },
      zoom: {
        enabled: true,
        drag: false,
        mode: "xy",
       speed: 0.01,
       // sensitivity: 0.1,
        limits: {
          max: 10,
          min: 0.5
        }
      }
    }

    this.optionsPressione2 = {
      scales: {
        fontColor:'transparent',
        yAxes: [{
          ticks: {
            max: 210,
            min: 30,
            stepSize: 30
          },
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        xAxes: [{
          fontColor:'transparent',
          ticks: {
          fontColor:'transparent',
        },pan: {
          enabled: true,
          mode: "x",
          speed: 10,
          threshold: 10
        },
        zoom: {
          enabled: true,
          drag: false,
          mode: "xy",
         speed: 0.01,
         // sensitivity: 0.1,
          limits: {
            max: 10,
            min: 0.5
          }
        },
        gridLines: {
          drawBorder: false,
          display: true
        }
        }]
      },
      legend: {
        display: false
      },
    }

  }

  // EVENTO CHE PERMETTE IL COLLEGAMENTO AL CALENDARIO DEI VARI GRAFICI CONTEMPORANEAMENTE
  cambiaGrafici() {
    this.cambiaBloodOxygen();
    this.cambiaPressione();
    this.cambiaBattito();
    this.cambiaTemp();
    this.cambiaPassi();
    this.cambiaRespirazione()
    this.getStoricoAllarmiDispositivi(true);
  }

  getAllarmiNonRisolti(){
    this.iotService.getAllarmiNonRisolti().subscribe((res)=>{
      if(res.status.code == 200){
        this.listaSOS = res.data
        //tipologia in questo metodo si trova diversamente rispetto ai dati ricevuti
        //dalla socket
        if (this.listaSOS){
          this.listaSOS.forEach((element)=>{
            element.tipologia = element.device.deviceType.tipologia
          })
        }
      }
    })
  }

  getConfermaAllarme(id, stato){
    if(this.ruolo == 3 || this.ruolo == 2 || this.ruolo == 4 || this.ruolo == 5) return;
    this.iotService.getConfermaAllarme(id, stato).subscribe((res)=>{})
  }

  /**
   * EVENTO PER LA NOTA DEL GRAFICO
   * @param event
   * @param grafico
   */
  selectData(event, grafico: number) {
    //FIX bug-440
    this.infoNota=false
    if (this.ruolo !== 3 && this.ruolo !== 4 && this.ruolo !== 5) {
      const datasetIndex: number = event.element._datasetIndex;
      const dataIndex: number = event.element._index;
      const data = event.element._chart.data;
      const labelTelemetria: string = data.datasets[datasetIndex].label;
      const oraTelemtria = data.labels[dataIndex];
      let nomeTelemetria = '';

      this.graficoTypeClicked = grafico;

      const date = new Date(this.date);
      date.setHours(+`${oraTelemtria[0] + oraTelemtria[1]}`);
      date.setMinutes(+`${oraTelemtria[3] + oraTelemtria[4]}`);

      const timpestampDate = +date;
      let element;

      this.graficoClicked = true;
      this.errorTxt = '';
      switch (grafico) {
        // pressione
        case 0: {
          element = this.pressureData[dataIndex - 2];
          if (labelTelemetria.includes('DBP')) {
            nomeTelemetria = 'DBP';
          } else if (labelTelemetria.includes('SBP')) {
            nomeTelemetria = 'SBP';
          } else {
            nomeTelemetria = labelTelemetria.includes('minima') ? 'DBP' : 'SBP';
          }
          this.idMisurazione = this.pressureData[dataIndex - 2].id;
        } break;
          // battito cardiaco
          case 1: {
            element = this.heartRateData[dataIndex - 1];
            nomeTelemetria = 'HeartRate';
            this.idMisurazione = this.heartRateData[dataIndex - 1].id;
          } break;
          // temperatura
          case 2: {
            element = this.bodyTemperatureData[dataIndex - 1];
            nomeTelemetria = 'BodyTemperature';
            this.idMisurazione = this.bodyTemperatureData[dataIndex - 1].id;
          } break;
          // contapassi
          case 3: {
            element = this.stepCounterData[dataIndex - 1];
            nomeTelemetria = 'StepCounter';
            this.idMisurazione = this.stepCounterData[dataIndex - 1].id;
          } break;
          //Respirazione:
          case 4:{
            element = this.respirazioneData[dataIndex - 1];
            nomeTelemetria = 'BreathingRate'
            this.idMisurazione = this.respirazioneData[dataIndex - 1].id
          }break;
      }
      if (element){
        this.errorTxt = element.note ? element.note.content : '';
        this.noteExists = element.note ? true : false;
      }
      else {
        this.errorTxt = '';
        this.noteExists = false;
      }
        $('#exampleModal').modal('show');
    } else {
      this.alertService.error('Non hai i permessi per poter accedere a questo servizio');
    }
  }

  onClickLabelPressione(isMinima: boolean) {
    if (isMinima) {
      this.pressioneMinimaMarked = !this.pressioneMinimaMarked;
      if (this.chartPressione) {
        this.chartPressione.data.datasets[0].hidden = this.pressioneMinimaMarked ? true : false;
        this.chartPressione.chart.update();
      }
    }
    else {
      this.pressioneMassimaMarked = !this.pressioneMassimaMarked;
      if (this.chartPressione) {
        this.chartPressione.data.datasets[1].hidden = this.pressioneMassimaMarked ? true : false;
        this.chartPressione.chart.update();
      }
    }
  }

  changeWidth(list: any[] = []): number {
    const startWidth = 2000;
    // return 4000;
    return (list && list.length > 48) ? startWidth * Math.trunc(list.length  % 48 !== 0 ? list.length / 48 + 1 : list.length / 48) : null;
  }

  yAxisStepCounter() {
          const data: any[] = this.dataContaPassiIot.datasets[0].data;
          if (data.length > 2) {
            const result = 500 + (500 * (Math.trunc(data[data.length - 2] / 500)));
              this.optionsPassi.scales.yAxes[0].ticks.max = result;
              this.optionsPassi2.scales.yAxes[0].ticks.max = result;
          }else{
            //Fix Gap-574
            if(this.passiSoglia){
              let soglie = this.passiSoglia.split('-');
              this.optionsPassi2.scales.yAxes[0].ticks.max = Number(Math.floor(soglie[1]/10)*10);
              this.optionsPassi2.scales.yAxes[0].ticks.min = 0
              this.optionsPassi.scales.yAxes[0].ticks.max = Number(Math.floor(soglie[1]/10)*10);
              this.optionsPassi.scales.yAxes[0].ticks.min = 0
            }

          }
            // this.optionsPassi2.scales.yAxes[0].ticks.max = 3000;
            // this.optionsPassi2.scales.yAxes[0].ticks.min = 0
            // this.optionsPassi.scales.yAxes[0].ticks.max = 3000;
            // this.optionsPassi.scales.yAxes[0].ticks.min = 0
  }
/**
 * Serve per cambiare la scala del grafico
 * @param data
 * @param dataTime
 * @param scaleTime
 * @param option1
 * @param option2
 * @returns
 */
  scaleGraph(data:any,dataTime:any,scaleTime:number,option1:any,option2:any){
      //ricavo il massimo e il minimo
      let max = data.datasets[0].data[3] || -1
      let min = data.datasets[0].data[3] || -1
      const dimDataSets = data.datasets[0].data.length
      const check = dimDataSets>2 //vero se ci sono + di 2, se falso non prosegue con il for
      for(var i=0;i<data.datasets[0].data.length&&check;i++){
        let dato = data.datasets[0].data[i]
        if(dato==null) continue
        if((max<=dato)){
          max=dato
        }
        if(min>=dato){
          min=dato
        }
      }
      if(!check){return}
      option1.scales.yAxes[0].ticks.max = max
      option1.scales.yAxes[0].ticks.min = min
      option2.scales.yAxes[0].ticks.max = max
      option2.scales.yAxes[0].ticks.min = min
      //più il tempo è tra loro vicino più devo diminuire lo stepsize
      var secondsScale = 1000000
      for(var i=0;i<dataTime.length-1;i++){
        var a = dataTime[i].split(':'); // split it at the colons
        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var secondsA = (+a[0]) * 60 * 60 + (+a[1]) * 60;
        var b = dataTime[i+1].split(':')
        var secondsB = (+b[0]) * 60 * 60 + (+b[1]) * 60;
        var delta = secondsB - secondsA
        if(delta<secondsScale){
          secondsScale = delta
        }
      }
      secondsScale = secondsScale/scaleTime
      option1.scales.xAxes[0].ticks.stepSize = ""+secondsScale
      option2.scales.xAxes[0].ticks.stepSize = ""+secondsScale

      option1.scales.xAxes[0].time.unitStepSize = scaleTime
     // option2.scales.xAxes[0].time.unitStepSize = secondsScale
  }

  // printDataInConsole()
  // {
  //   console.log(this.respirazioneData);
  // }

  controllaSeMostrareGruppo(gruppo:String)
  {
    if(gruppo!='sos_trigger' && gruppo!='sos_handler' && gruppo!='tracciamento_asset')
    {
      return true;
    }
    return false;
  }

  private getStoricoAllarmiDispositivi(notInitDispositivo?: boolean) {
    const dataOggiParsata = Date.parse(this.date.toString());
    const dataOggiTimestamp = dataOggiParsata / 1000;
    const dataFineGiornata = dataOggiTimestamp + 86340;
    this.iotService.storicoAllarmiDispositivi(dataOggiTimestamp, dataFineGiornata).subscribe((res) => {

      if (res.data) {
        //let testJson:JsonStorico = <JsonStorico> res || null
        this.listaDispositivi = [];
        if (res.data.length < 1) {
          this.listaDispositivi = [];
        }
        //MODIFICA
        this.iotService.getListTelemetrieSmartBed().subscribe(resTelemetrie=> {
          this.listaDispositivi = [];
        res.data.forEach(element => {

          element.device.wearState = element.wearState;
          element.device.power = element.power;
          element.device.storico = element.storico;
          if(element.MovementsStats!==undefined && element.MovementsStats!==null){
            element.device.MovementsStats = element.MovementsStats
          }
          resTelemetrie.data.forEach(elementTelemetrie => {
            if(elementTelemetrie.deviceId == element.device.id) {
              let heartRate = '--';
              let movementsIntensity = '--';
              let breathingRate = '--';
              let movements = '--';
              let wearState = '--';
              let date:any = '';
              elementTelemetrie.telemetry.forEach(telemetria => {
                // if (date == '') {
                //   date = telemetria.timestamp
                // } else if (date < telemetria.timestamp){
                //   date = telemetria.timestamp;
                // }
                if (telemetria.telemetryName == 'MovementsStats') {
                  movementsIntensity = telemetria.telemetryData
                } else if (telemetria.telemetryName == 'BreathingRate') {
                  breathingRate = telemetria.telemetryData
                } else if (telemetria.telemetryName == 'HeartRate') {
                  heartRate = telemetria.telemetryData
                } else if (telemetria.telemetryName == 'Movements') {
                  movements = telemetria.telemetryData
                } else if (telemetria.telemetryName == 'WearState') {
                  wearState = telemetria.telemetryData
                  date = telemetria.timestamp;
                }
              });

              element.device.wearState = wearState;
              if (element.device.wearState == 4) {
                element.device.heartRate = heartRate;
              } else {
                element.device.heartRate = 0;
              }
              if (element.device.wearState == 4) {
                element.device.breathingRate = breathingRate;
              } else {
                element.device.breathingRate = 0;
              }
              if (element.device.wearState == 4) {
                element.device.movementsIntensity = movementsIntensity;
              } else {
                element.device.movementsIntensity = -1;
              }
              element.device.date = date;
              element.device.movements = movements;
            }
          });
          this.listaDispositivi.push(element.device);
          if(this.dispositivo && element.device.id==this.dispositivo.id){
            this.dispositivo.storico=element.device.storico
          }

          })
        this.filtroTipoDispositivo.forEach(element => {
          // element.selezionato = false;
          if (this.dispositivo && element.id == this.dispositivo.id) {
            element = this.dispositivo
          }
          if ((element.wearState == 3 || element.wearState == 2)&&element.deviceType.tipologia!="smartbed") {
            element.power = 101;
          }
        });
        if (this.listaDispositivi[0] || this.filtroTipoDispositivo[0]) {
          if (!notInitDispositivo) {
            this.arraySelezionato.push(true)
            this.listaDispositivi[0].selezionato = true;
            this.dispositivo = this.listaDispositivi[0];
            this.initDispositivo();
          }
          this.allListaDispositivi = this.listaDispositivi;
        }

        if (this.listaDispositivi.length < 1) {
          this.listaVuota = true;
        }
        this.mostraTipoDispositivo('allarmeStorico')
        this.caricato = true;





      });
      }
    }, (error)=>{
      this.caricato = true;
      this.filtroTipoDispositivo = [];
      this.listaVuota = true;
    });
  }
  private getStartToday(): Date {
    const today = new Date();
    today.setHours(0,0,0,0);
    return today;
  }

  /**
   * Questo metodo viene richiamato nel tag HTML, serve per filtrare i vari dispositivi.
   *
   * Questo metodo ne richiama un altro che contiene il campo DeviceType.tipoDevice utili
   * per il filtro
   * @param e evento html per leggere innerHtml il testo dentro al pulsante del tag li, oppure richiesta del filtro
   * @see {@link filtraTipoDispositivo(filtro)}
   */
   mostraTipoDispositivo(e:string){
    let eseguiFiltro = true
    let isEvent = false
    //evento 'e' quando viene richiamato dentro a qualche funzione contiene il tipo di azione
    //altrimenti e corrisponde all'utente preme sul pulsante
      let tipologia = e
      let isButton = (this.iotService.tipoDispositivi.get(tipologia) == null) ? false:true
      if(isButton){  //se è falso il filtro avviene nei metodi
      if(this.filtroScelto==tipologia){
        //se ripreme sul pulsante è inutile filtrare
        return
      }
      this.filtroScelto=tipologia
      e='clickButton'
    }
      switch(e){
        //gestione del filtro in base agli eventi
        case 'primaVolta':
          if(this.filtroScelto==''){
            this.filtroScelto=this.gruppiDispositivi[0]
          }
          eseguiFiltro=true
          break
        case 'allarmeStorico':
          if(this.triggerFiltroPrecendente==e){
            eseguiFiltro=false
          }else{
            this.triggerFiltroPrecendente = e
          }
          break
        case 'allarmeSocket':
        case 'ordinaArray':
        case 'searchDispositivo':
        case 'socketGetTelemetry':
        case 'clickButton':
          eseguiFiltro=true
          this.triggerFiltroPrecendente = e
          break
          default:
            eseguiFiltro=false
      }
        //Verifica se esiste il tipo di dispositivo scelto
        let gruppo = this.iotService.tipoDispositivi.get(this.filtroScelto) || null
        if(gruppo==null){
          //se non esiste non eseguire il filtro
          eseguiFiltro = false
        }else{
          if(e=="clickButton"){
            //se l'evento è dato, dal click sui pulsanti inerenti al filtro
            //allora è un evento e dobbiamo selezionare il PRIMO dispositivo
            isEvent=true
          }
       }

    if(eseguiFiltro){
      //il filtro viene eseguito solo se il filtro esiste
      //e un metodo richiede di eseguirlo
      this.filtraTipoDispositivo(this.filtroScelto)
      if(isEvent){
        //seleziona dispositivo viene eseguito solo se è un clickbutton
        //in questo modo il dispositivo cambia
        //(mette il primo) ogni volta che si preme su un pulsante
        this.delay = false
        this.selezionaDispositivo(this.filtroTipoDispositivo[0])
      }
    }
  }

  dropdDownUp = false;
  isAlreadyClicked = false;
  choseDownUp(event) {
    if(!this.isAlreadyClicked) {
      if (110 < (event.clientY-(250 - document.documentElement.scrollTop)) ) {

        this.dropdDownUp = false;
        return;
      }
      this.dropdDownUp = true;
    }
    this.isAlreadyClicked=false;
    //  console.log(e.clientX);
    //  console.log(e.clientY);
  }

  openJ2032ActionMenu(deviceId) {
    setTimeout(()=>{
      this.isAlreadyClicked=true
      this.idDispositivoSelezionatoAzioni=deviceId
    });
  }

  inviaComandoJ2032(comando)
  {
    //console.log(this.idDispositivoSelezionatoAzioni, comando)
    this.iotService.inviaComandoJ2032(this.idDispositivoSelezionatoAzioni, comando).subscribe(
      (res) => {
        this.alertService.success(res.status.description);
      },
      (error) => {
        this.alertService.error(error.description);
      }
    );
  }
/**
 * Filtra i dispositivi in base al tipo, in base al loro campo "tipoDevice"
 * @param tipologia dispositivo
 */
  filtraTipoDispositivo(tipologia:string){
    //riazzeriamo la lista prima di filtrarla di nuovo
    this.filtroTipoDispositivo.splice(0,this.filtroTipoDispositivo.length)
    for(var i=0;i<this.listaDispositivi.length;i++){
      let tipologiaDispositivo = this.listaDispositivi[i].deviceType.tipologia as string || "test device"
      if(tipologia==tipologiaDispositivo){
        this.filtroTipoDispositivo.push(this.listaDispositivi[i])
      }
    }
  }
  /**
   * Il metodo permette di visualizzare o meno il grafico in base al fatto se il dispositivo fornisce quel tipo di grafico
   * @param nomeGrafico
   * @returns vero se il dispositivo possiede quella telemetria
   */
  mostraGrafico(nomeGrafico:string):boolean{
    let trovato = this.iotService.telemetryToShow.has(nomeGrafico)
    return trovato
  }

  /**
   * Serve per popolare la mappa e filtrare poi i grafici
   * @param dispositivo oggetti che contengono id, telemetrie allarmi etc...
   */
  popolaControlloGrafico(dispositivo:any){
    if(dispositivo==undefined){
      return
    }
    //chiedo al BE, per quel tipo di dispositivo quale grafico mostrare
    this.iotService.telemetryToShow.clear()
    //adesso l'oggetto dispositivo, conosce i parametri telemetrici
    let telemetrie: any[] = dispositivo.deviceType.deviceTelemetryMetadata
      if (telemetrie) {
        for (var i = 0; i < telemetrie.length; i++) {
          let telemetria = telemetrie[i].telemetry as string
          this.iotService.telemetryToShow.add(telemetria)
        }
      }
  }

  selezionaDivZoom(nomeTelemetria)
  {
    switch(nomeTelemetria)
    {
      case "BPM":
        {
          return this.zoomBPMDiv;
        }
      case "DBP":
        {
          return this.zoomDBPDiv;
        }
      case "BloodOxygen":
        {
          return this.zoomBODiv;
        }
      case "StepCounter":
        {
          return this.zoomSCDiv;
        }
      default:
        {
          return undefined;
        }
      
    }
  }

  modificaZoomGrafico(widthConst, div:ElementRef, oldWidth, percent)
  {
    let newWidth = (widthConst*percent)/100;
    div.nativeElement.scrollLeft = div.nativeElement.scrollLeft + ((newWidth - oldWidth)/2)
    // console.log("Grafico Width: " + oldWidth);
    // console.log("New grafico width: " + newWidth)
    // console.log(div.nativeElement.scrollLeft);
    return newWidth;
  }

  // zoomBPM(percent) {
  //   const oldGraficoBattitoWidth = this.graficoBattitoWidth;
  //   this.graficoBattitoWidth = (this.graficoBattitoWidthConst*percent)/100;
  //   this.zoomBPMDiv.nativeElement.scrollLeft = this.zoomBPMDiv.nativeElement.scrollLeft + ((this.graficoBattitoWidth - oldGraficoBattitoWidth)/2)
  // }
  zoomRespirazione(percent) {
    const oldGraficoBattitoWidth = this.graficoRespirazioneWidth;
    this.graficoRespirazioneWidth = (this.graficoRespirazioneWidthConst*percent)/100;
    this.zoomRESDiv.nativeElement.scrollLeft = this.zoomRESDiv.nativeElement.scrollLeft + ((this.graficoRespirazioneWidth - oldGraficoBattitoWidth)/2)
  }
  openModalEliminateAllAllarms() {
    if(!this.selectedAllarmi)
      this.selectedAllarmi = !this.selectedAllarmi;
  }
  eliminateAllAllarms() {
    this.listaSOS = []
    this.listaDispositivi.forEach(dispositivo => {
        dispositivo.allarme = null;
    })
    this.selectedAllarmi = false;
    if(this.ruolo == 3 || this.ruolo == 2 || this.ruolo == 4 || this.ruolo == 5) return;
    this.iotService.getConfermaAllarmeAll().subscribe((res)=>{});
  }
}

enum Grafico {
  Pressione = 0,
  Battito,
  Temperatura,
  Contapassi,
  Respirazione
}
