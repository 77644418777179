<div class="content">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content" style="margin-bottom: 2%;">
                        <h5>Sfoglia la guida</h5>
                        <p-accordion>
                            <p-accordionTab *ngIf="!alfa" header="Che cos'è GAP" [selected]="true">
                                <p ><i>Guided Activities Platform</i> è una piattaforma collaborative pensata per
                                    sviluppare una nuova concezione di apprendimento in ambito aziendale.
                                    GAP è lo strumento per le aziende che vogliano puntare sui progetti formativi e le
                                    competenze individuali per favorire la crescita del business e, allo stesso tempo,
                                    pianificare ed ottimizzare ogni aspetto delle attività di assistenza.<br>
                                    <br><b style="color: rgba(35,80,153,1) ;">DON'T MIND THE SKILL</b> <br>
                                    <b>Utilizza GAP per programmare ed erogare formazione in Azienda.</b> L’e-learning è
                                    uno strumento essenziale per lo sviluppo del business e il raggiungimento degli
                                    obiettivi a breve e a lungo termine.
                                    <br><br> <b style="color: rgba(35,80,153,1) ;">DON'T MIND THE DISTANCE</b><br>
                                    <b>Interventi a distanza risparmiando tempo e fatica.</b> GAP si configura come un
                                    software di pronto intervento e fornisce strumenti innovativi per gestire numerose e
                                    svariate richieste di assistenza.
                                    <br> <br><b style="color: rgba(35,80,153,1) ;"> DON'T MIND THE CUSTOMIZATION</b><br>
                                    <b>Massima flessibilità e totale customizzazione.</b> GAP può essere adattato alle
                                    esigenze specifiche di ciascun Cliente, sia in termini di personalizzazione
                                    dell’interfaccia sia in termini di sviluppo di specifiche features.
                                    <br> <br><b style="color: rgba(35,80,153,1) ;"> DON'T MIND THE DATA</b> <br>
                                    <b>Ottieni facilmente dati riguardanti la tua azienda.</b> GAP fornisce un sistema
                                    integrato di <b>report</b>: può tracciare gli spostamenti degli utenti, la durata delle
                                    chiamate e i motivi degli interventi, filtrati per utente e date.
                                    <br><br>
                                    Gap ha una struttura modulare che lo rende totalmente parametrizzabile.
                                    I moduli vengono scelti in fase d’acquisto, l’elenco di quelli disponibili in GAP è:  </p>
                                <ul>
                                    <li><b>Pannello di Controllo</b></li>
                                    <li *ngIf="cr"><b>Assistenza</b> (opzionale)</li>
                                    <li *ngIf="classroom"><b>Education</b> (opzionale)</li>
                                    <li *ngIf="dms"><b>Documentazione</b> (opzionale)</li>
                                    <li *ngIf="ar"><b>AR</b> (opzionale)</li>
                                    <li *ngIf="alfa"><b>Live Chat</b></li>
                                    <li *ngIf="chatbot"><b>Chatbot</b> (opzionale)</li>
                                </ul>


                            </p-accordionTab>

                            <p-accordionTab header="Primo Accesso">
                                <p>
                                    Per effettuare il primo accesso, l'utente, a seguito della creazione del suo
                                    account, riceve una e-mail contenente un link di conferma.
                                    Si viene quindi reindirizzati ad una schermata per impostare la
                                    password, che deve essere di almeno otto caratteri, contenere
                                    almeno una lettera maiuscola, una minuscola, un numero e un
                                    carattere speciale  ($, @, !, %, *, ?, &).
                                    Bisogna poi reinserire la stessa password per confermare.
                                    Completato questo processo, si viene reindirizzati alla schermata di
                                    login, dove l'utente può quindi accedere inserendo l'e-mail con la
                                    quale è stato creato l’account e la password appena impostata.
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Assistenza" *ngIf="cr">
                                <p>In caso di password dimenticata, l'utente può effettuare una richiesta di reset
                                    password cliccando
                                    sul tasto "<b>Assistenza</b>" nella schermata di login.
                                    Può dunque inserire la propria e-mail, e, cliccando su prosegui, ricevere un link
                                    che lo indirizza
                                    alla pagina di reimposta password, dove può inserire una nuova password rispettando
                                    gli stessi
                                    criteri del primo accesso.
                                    Effettuata questa operazione, l’utente viene reindirizzato alla pagina di login da
                                    cui può
                                    nuovamente eseguire l'accesso alla piattaforma.
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Profilo Personale">
                                <p>Cliccando sull’icona dell’utente in alto a destra,
                                    tramite apposito menù a tendina, è possibile visualizzare
                                    le seguenti opzioni: profilo personale, cambia password e
                                    log out.
                                    Nel profilo personale è possibile visualizzare due pagine:
                                    Informazioni Account ed Attività Recenti.
                                    in Informazioni Account si trovano i dettagli dell’account:</p>
                                <ul>
                                    <li>Nome</li>
                                    <li>Email</li>
                                    <li>Telefono</li>
                                    <li>Profilo</li>
                                    <li>Gruppi</li>
                                    <li>Dettagli Aggiuntivi</li>
                                    <ng-container *ngIf="alfa"> <li>Informatore Scientifico</li></ng-container>
                                    <ng-container *ngIf="alfa"> <li>Ragione Sociale Struttura</li></ng-container>
                                    <ng-container *ngIf="alfa"> <li>Consenso</li></ng-container>
                                    <ng-container *ngIf="alfa"> <li>Utente Registrato</li></ng-container>
                                    <ng-container *ngIf="alfa"> <li>Identificativo Medico</li></ng-container>
                                    <ng-container *ngIf="alfa"> <li>Carica</li></ng-container>
                                    <ng-container *ngIf="alfa"> <li>Attività</li></ng-container>
                                </ul>
                                In Attività Recenti si trovano i log di tutte le attività effettuate dall’utente.

                            </p-accordionTab>
                            <p-accordionTab header="Modifica Password">
                                <p>Cliccando sull’icona dell’utente in alto a destra,
                                    tramite apposito menù a tendina, è possibile modificare la propria password.
                                    Nella sezione Cambia Password è possibile
                                    cambiare la password compilando i seguenti campi:</p>
                                    <ul>
                                        <li>Password Attuale</li>
                                        <li>Nuova Password</li>
                                        <li>Conferma Password</li>
                                    </ul>
                                    La password deve rispettare i seguenti standard:
                                    Deve essere di almeno otto caratteri, contenere almeno
                                    una lettera maiuscola, una minuscola, un numero
                                    e un carattere speciale ($, @, !, %, *, ?, &).

                            </p-accordionTab>
                            <p-accordionTab *ngIf="admin" header="Pannello di Controllo">
                                <p>La sezione <b>Pannello di Controllo</b> permette agli utenti con profilo <b>Admin</b>  di effettuare
                                    operazioni gestionali riguardanti gli utenti.
                                    <br> <br>
                                    Gli amministratori potranno infatti accedere alle seguenti sezioni:
                                    <br> <br>
                                    - <b>Crea utente</b> <br>
                                    -  <b>Gestione utente</b> <br>
                                    - <b>Gestione Gruppi</b> <br>
                                    - <b>Gestione Profili</b> <br>
                                    - <b>Report</b> <br>
                                    - <b>Configurazione</b>
                                </p>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="admin" header="Crea Utente">
                                    Questa sezione ti permette di creare un nuovo utente sulla piattaforma. <br><br>
                                    Sarà necessario fornire i seguenti dati:<br><br>

                                    <b>Nome</b>: lunghezza massima 30 caratteri, non sono consentiti numeri e caratteri
                                    speciali. Non sarà possibile creare un utente con un’e-mail già presente nel
                                    sistema.<br>
                                    <b>Cognome</b>: lunghezza massima 30 caratteri, non sono consentiti numeri e
                                    caratteri speciali.<br>
                                    <b>E-mail</b>: dovrà rispettare il formato nomeutente@dominio.it  (oppure
                                    @dominio.com,@dominio.net, .org etc.).<br>
                                    <b>Recapito Telefonico</b>: lunghezza massima 11 caratteri.<br>
                                    <ng-container *ngIf="!alfa"> <b>Ruolo</b>: a scelta tra <b>Admin</b>, <b>Operatore</b>, <b>User</b>, <b>Insegnante</b>, <b>Call_center</b>.<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Ruolo</b>: a scelta tra <b>Admin</b>, <b>User</b>, <b>Insegnante</b>.<br></ng-container>
                                   <b>Gruppi</b>: sarà possibile sceglierne una tra quelli disponibili.<br>
                                   <ng-container *ngIf="alfa"> <b>Informatore Scientifico</b>: rappresentante Farmaceutico<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Ragione Sociale Struttura</b>: a scelta una tra le varie ragioni sociali e strutture disponibili<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Consenso</b>: spuntare nel caso l'utente in crezione ha dato il consenso al trattamento dei dati<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Utente Registrato</b>: Utente che si è autonomamente registrato sul sito istituzionale<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Identificativo Medico</b>: id univoco che identifica il medico nel crm<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Carica</b>: a scelta tra i vari ruoli disponibili<br></ng-container>
                                   <ng-container *ngIf="alfa"> <b>Attività</b>: a scelta tra le varie specializzazioni disponibili<br></ng-container>
                                    <br>
                                    Una volta inseriti i dati basterà cliccare su Salva e verificare che vi sia il
                                    messaggio “Operazione effettuata con successo”.
                            </p-accordionTab>
                            <p-accordionTab *ngIf="admin" header="Gestione Utenti">
                                <p>Cliccando su <b>Gestione Utenti</b>, si avrà la possibilità di navigare su due liste, Utenti
                                    e Cestino. <br> Nella prima sono mostrati gli utenti effettivamente registrati alla
                                    piattaforma; nella seconda, cliccando sulla tab Cestino, si visualizzeranno gli
                                    utenti eliminati.</p>
                                Nella lista <b>Utenti </b> sarà possibile effettuare le seguenti operazioni: <br><br>
                                <ul>
                                    <li>
                                        <b>Inviare l'email</b>: <i class="material-icons notranslate  pointer"
                                            style="color: rgb(114, 253, 114);">email</i> la seguente icona compare solo
                                        per gli utenti che non hanno ancora impostato la password. In caso di necessità,
                                        cliccarla permette il reinvio del link di impostazione password all'email
                                        dell'utente interessato. 
                                    </li>
                                    <li>
                                        <b>Disattivare l'utente</b>: <i class="material-icons notranslate  pointer"
                                            style="color: rgb(42, 45, 182);">toggle_on</i> cliccando sull'icona, sarà
                                        possibile rendere inattivo l'utente.
                                    </li>
                                    <li>
                                        <b>Attivare l'utente</b>: <i class="material-icons notranslate  pointer"
                                            style="color: red;">toggle_off</i> cliccando sull'icona, sarà possibile
                                        rendere attivo l'utente.
                                    </li>
                                    <li>
                                        <b>Modifica l'utente</b>: <i class="material-icons notranslate  pointer"
                                            style="color: rgb(42, 45, 182);">create</i> cliccando sull'icona, sarà
                                        possibile modificare l'utente.
                                    </li>
                                    <li>
                                        <b>Informazioni utente</b>: <i class="material-icons notranslate  pointer"
                                            style="color: #FFC107;">info</i> cliccando sull'icona, sarà possibile
                                        visualizzare le informazioni dell'utente.
                                    </li>
                                    <li>
                                        <b>Eliminare l'utente</b>: <i class="material-icons notranslate  pointer"
                                            style="color: red;">delete</i> cliccando sull'icona, sarà possibile
                                        eliminare l'utente, che risulterà di conseguenza nella lista Cestino.
                                    </li>
                                </ul>
                                Nella lista <b>Cestino </b> è possibile effettuare l'operazione di ripristino utente
                                attraverso la seguente icona <i class="material-icons notranslate  pointer"
                                    style="color: red;">restore_from_trash</i> . L'utente risulterà di conseguenza nella
                                lista Utenti. <br>
                                Sarà possibile visualizzare la lista degli utenti in base al valore numerico selezionato
                                in Limita Risultati, e filtrare tramite la funzione di ricerca.
                            </p-accordionTab>
                            <p-accordionTab *ngIf="admin" header="Gestione Profili">
                                <p>Cliccando su <b>Gestione Profili</b> sarà mostrata la lista dei profili assegnabili sulla
                                    piattaforma.
                                    Per ogni profilo, sarà possibile visualizzare la lista di tutti gli utenti avente
                                    tale ruolo cliccando<i class="material-icons notranslate  pointer"
                                        style="color: rgb(42, 45, 182);">remove_red_eye</i> <br>
                                    Successivamente sarà possibile accedere alla sezione “Modifica Utente” attraverso la
                                    seguente icona <i class="material-icons notranslate  pointer"
                                        style="color: rgb(42, 45, 182);">create</i>

                                </p>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="admin" header="Gestione Gruppi">
                                <p>Cliccando su <b>Gestione Gruppi</b> verrà mostrata la lista dei gruppi disponibili.
                                    Cliccando sull'icona <i class="material-icons notranslate  pointer"
                                        style="color: rgb(42, 45, 182);">create</i> del gruppo di interesse,
                                    sarà possibile modificarlo. All'interno della pagina di modifica, sarà possibile
                                    modificare nome e descrizione del gruppo, rimuovere o aggiungere eventuali utenti.
                                    Cliccando sull'icona <i class="material-icons notranslate  pointer"
                                        style="color: red;">delete</i> sarà invece possibile eliminarlo.
                                    Il tasto "Crea Gruppo" permette la creazione di un gruppo, di cui bisogna
                                    specificare nome, descrizione, e selezionare eventuali utenti che possono far parte
                                    di quel gruppo.

                                </p>
                            </p-accordionTab>
                            <!-- <p-accordionTab *ngIf="admin" header="Report">
                                <p>Nella sezione Report sarà visualizzata la lista degli utenti attivi sulla
                                    piattaforma.
                                    Selezionandone uno o più, e cliccando su Avanti, sarà possibile indicare la data o
                                    il periodo interessato.
                                    Infine, cliccando su <img src="../../../../assets/img/geo.jpg"
                                        style="width: 25px;height: auto;" alt="Geolocalizzazione"> sarà possibile
                                    scaricare il report sulla geolocalizzazione relativo all’utente/agli utenti
                                    riguardante il periodo/la data selezionato/a.
                                    Diversamente, cliccando su <img src="../../../../assets/img/video.jpg"
                                        style="width: 25px;height: auto;" alt="Videochiamata"> sarà possibile scaricare
                                    il report sulla geolocalizzazione relativo all’utente/agli utenti riguardante il
                                    periodo/la data selezionato/a.Qualora non sarà presente alcun report per quella data
                                    e/o per quell’utente comparirà il messaggio “Nessun report presente per gli utenti
                                    e/o le date selezionate”.</p>
                            </p-accordionTab> -->
                            <p-accordionTab *ngIf="admin && !alfa" header="Configurazione">
                                <p>Nella sezione <b>Configurazione</b>, si avrà la possibilità di personalizzare il tema e il
                                    logo del sito. Se il modulo di <b>Assistenza</b> è attivo, è possibile anche personalizzare i motivi chiamata.
                                    Se il modulo di <b>Education</b> è attivo, è possibile gestire {{ambiente.NOMEAULAIL}}.<br>
                                    <br> <b>Tema</b><br>
                                    <i class="material-icons notranslate  pointer"
                                        style="color:rgb(255, 153, 0); font-size: xx-large;">fiber_manual_record</i>
                                    <i class="material-icons notranslate  pointer"
                                        style="color:  rgba(35,80,153,1);font-size: xx-large;">fiber_manual_record</i>
                                    <i class="material-icons notranslate  pointer"
                                        style="color: rgb(255, 0, 0);font-size: xx-large;">fiber_manual_record</i>
                                    <i class="material-icons notranslate  pointer"
                                        style="color: rgb(53, 167, 49);font-size: xx-large;">fiber_manual_record</i>
                                    <i class="material-icons notranslate  pointer"
                                        style="color: rgb(172,47,163);font-size: xx-large;">fiber_manual_record</i>
                                    <i class="material-icons notranslate  pointer"
                                        style="color: rgb(255, 230, 0);font-size: xx-large;">fiber_manual_record</i>
                                    <br>Cliccando su uno dei colori, si potrà visualizzare un'anteprima del nuovo tema
                                    della navigation bar.<br> Scelto il colore desiderato, si potrà cliccare il pulsante
                                    "Applica" per applicare il colore al sito in modo permanente.<br> Sarà possibile
                                    cambiarlo a piacimento.
                                    <br>
                                    <br> <b>Logo</b><br>
                                    Nella sezione logo, si potrà caricare il logo aziendale. <br>L'immagine deve
                                    rispettare un peso massimo di 1MB e deve avere dimensioni 400x125 pixel. <br>Se
                                    necessario, sarà possibile rimuovere l'immagine con "Rimuovi Logo" oppure sosituirla
                                    caricandone una nuova.
                                    <br>
                                    <ng-container *ngIf="cr"><br> <b>Motivi Chiamata</b><br>
                                    In motivi chiamata, è possibile aggiungere o rimuovere le motivazioni tra cui gli
                                    utenti possono scegliere per ottenere assistenza, e che compariranno quindi nella
                                    sezione Videochiamata.</ng-container>
                                    <ng-container *ngIf="classroom" ><br><br> <b>Education</b><br>
                                    In <b>Education</b>, è possibile creare nuove {{ambiente.NOMEAULA}} o modificare {{ambiente.NOMEAULAIl}} esistenti.
                                    </ng-container>
                                    <br><br>
                                    <ng-container *ngIf="admin" >
                                        <b>IOT</b><br>
                                        Una volta aperta la schermata della <b>Configurazione</b>,  è necessario cliccare sulla scritta <b>IOT</b>  e in seguito su <b>CREA DISPOSITIVO</b>
                                        situato in alto alla pagina.
                                        <br>
                                        Effettuata questa operazione, viene aperta una pagina contenente un form da compilare nel quale devono essere inseriti tutti i parametri che compongono il nuovo dispositivo.
                                        <br>
                                        Nella prima schermata viene richiesto di inserire il
                                        <b>nome</b> del nuovo dispositivo ed un <b>ID identificativo.</b>
                                        Inoltre da questa pagina è possibile anche associare
                                        il dispositivo ad un utente tramite il pulsante Associa a
                                        paziente, che permette di selezionare l’utente di interesse
                                        dalla lista messa a disposizione.
                                        <br>
                                        Per effettuare l’aggiunta di un nuovo dispositivo IoT, si effettua l’accesso con un’utenza di tipo <b>Admin</b>, in quanto è l’unico profilo che può eseguire tale operazione.
                                        <br>
                                        Una volta soddisfatta la condizione descritta sopra, bisogna recarsi nel <b>Pannello di Controllo</b> e cliccare su <b>Configurazione</b>.
                                        <br>
                                        Una volta aperta la schermata della Configurazione,  è necessario cliccare sulla scritta CREA DISPOSITIVO e in seguito su
                                        situato in alto alla pagina.
                                        <br>
                                        Effettuata questa operazione, viene aperta una pagina contenente un form da compilare nel quale devono essere inseriti tutti i parametri che compongono il nuovo dispositivo.
                                        <br>
                                        <br>
                                        Nella prima schermata viene richiesto di inserire il
                                       <b>nome</b>  del nuovo dispositivo ed un <b>ID identificativo</b>.
                                        Inoltre da questa pagina è possibile anche associare
                                        il dispositivo ad un utente tramite il pulsante <b>Associa a
                                        paziente</b>, che permette di selezionare l’utente di interesse
                                        dalla lista messa a disposizione.
                                        <br>
                                        Nella <b>seconda schermata</b> bisogna scegliere il tipo di dispositivo che si sta configurando tra un H003 (Ketron smartwatch per applicazioni di smart health) e H002 (Smartband), H006 (Smartwatch per applicazioni di smart health), Smartbed.
                                        <br>
                                        Nella <b>terza schermata </b>viene effettuata la configurazione dei parametri del nuovo dispositivo. Va indicato l’Intervallo di misurazione battito cardiaco e pressione, l’Intervallo di misurazione Temperatura, il Volume, il Numero Rosso, il Numero Giallo, il Numero Verde, la Whitelist e lo Sleep Time.
                                        <br>
                                      <small>  NB: I numeri rosso, giallo e verde sono i recapiti telefonici che vengono inseriti nella rubrica del dispositivo. In particolare, il numero rosso equivale a quello che viene chiamato in automatico nel momento in cui o viene premuto il pulsante SOS o viene rilevata un’emergenza da parte del dispositivo.
                                        </small>
                                        <br><br>

                                        Nella <b>quarta e ultima schermata</b> è possibile impostare i dati relativi alla telemetria e i loro valori nel nuovo dispositivo. I dati telemetrici che possono essere scelti sono Battiti al minuto, Contapassi, Temperatura corporea, Pressione diastolica, Pressione sistolica e Batteria. Una volta effettuati tutti i passaggi, per completare la configurazione è necessario cliccare su                   .
                                        Nella prima schermata di configurazione di un dispositivo, è possibile anche associare quest’ultimo a un paziente. Il paziente sarebbe l’utente presente sulla piattaforma al quale vengono effettuate tutte le misurazioni dei vari parametri.
                                        <br>

                                        Per effettuare la configurazione o per gestire la family di un utente, è necessario effettuare l’accesso con un’utenza di tipo <b>Admin</b>.
                                        Nel caso in cui si soddisfi questa condizione, bisogna cliccare su <b>Pannello di Controllo</b>
                                        e in seguito su <b>Gestione utenti</b>. In seguito a questa operazione, il sistema apre una lista contenente tutti gli utenti: una volta individuato l’utente interessato, è necessario cliccare sull’icona della family <i class="material-icons notranslate  pointer"
                                        style="color: rgb(2, 217, 255);">family_restroom</i>    . Cliccando su  <b>Modifica famiglia</b>compare un’altra lista contenente tutti gli utenti dal quale deve essere selezionato colui che deve essere inserito nella family dell’utente scelto in precedenza. Per confermare l’operazione bisogna cliccare su  <b>Aggiungi Familiari</b>  mentre per rimuovere il familiare è sufficiente cliccare sull’icona <i class="material-icons notranslate  pointer"
                                        style="color: red;">close</i>.
                                        <br>
                                    </ng-container>
                                </p>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="admin && alfa" header="Configurazione">
                                <p>
                                    <ng-container *ngIf="classroom" ><b>Education</b><br>
                                        <b>Per creare un webinar</b>, si clicca sul relativo pulsante, e si procede a compilare il form seguendo 4 step:
                                        <br> -	Step 1: Inserimento di identificativo, descrizione, di eventuali dettagli aggiuntivi, eventuale immagine di copertina, e possibilità di temporizzare l’aula, scegliendo quindi le date di decorrenza del webinar
                                        <br>   -	Step 2: Si selezionano uno o più docenti da associare al webinar
                                        <br>   -	Step 3: Si selezionano uno o più studenti da associare al webinar
                                        <br>   -	Step 4: si aggiunge eventualmente un Corso da associare al webinar
                                        <br>  <b>Per modificare un webinar</b>, si clicca sull’icona <i style="color:  green"
                                        class="material-icons notranslate ">edit</i> del webinar di interesse, e si procede ad effettuare le volute modifiche
                                        <br>  <b>Per visualizzare le info di un webinar</b> , si clicca sull’icona <i style="color:  yellow;"
                                        class="material-icons notranslate ">info</i> del webinar di interesse, e se ne visualizzeranno le informazioni.
                                        <br>  <b>Per eliminare un webinar</b>, si clicca sull’icona <i style="color:  red;"
                                        class="material-icons notranslate  ">close</i> del webinar di interesse, si procede cliccando su sul pop up di conferma, e si elimina il webinar.”
                                    </ng-container>
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Reportistica" *ngIf="alfa">
                                <p>
                                    <ng-container *ngIf="admin">Dalla sezione <b>reportistica</b>  è possibile scaricare i file excel e visualizzare i grafici relativi ai dati statistici sia per quanto riguarda i Webinar che per la Customer Satisfaction</ng-container>
                                    <ng-container *ngIf="!admin"> Dalla sezione <b>reportistica</b> è possibile scaricare i file excel e visualizzare i grafici relativi ai dati statistici per quanto riguarda i Webinar.</ng-container>
                                    <br> <br><b>Webinar</b> : cliccando sulla tab <b>Education</b> ,si selezionano uno o più webinar di cui si vogliono visualizzare i dati e si clicca avanti; si selezionano una o più date di cui si vogliono ottenere i dati del webinar scelto, e si clicca sul bottone avanti. Qualora non fossero disponibili dati per quel webinar per quella data, comparirà un alert informativo. Diversamente, seguirà il download di un file excel in cui verranno indicati i seguenti dati, mostrati anche su un piano grafico: nome e cognome dell’utente, data e ora di entrata nell’aula, data e ora di uscita dall’aula, durata di permanenza all’interno dell’aula, numero di alzata di mani, periodo di inattività.
                                    <br> <br>  <ng-container *ngIf="admin" ><b>Customer Satisfaction</b>: cliccando sulla tab customer satisfaction, si selezionano una o più date di cui si vogliono visualizzare i dati, e si clicca sul bottone avanti. Qualora non fossero disponibili dati di customer satisfaction per quella data, comparirà un alert informativo. Diversamente, si visualizzeranno i grafici dei relativi dati e verrà eseguito il download del file excel, da cui è possibile visualizzare le reazioni degli utenti sia per quanto riguarda la piattaforma Alfaintens sia per quanto riguarda il sito istituzionale</ng-container>
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Reportistica"  *ngIf="!alfa">
                                <!-- Dalla sezione di <b>Reportistica</b> è possibile scaricare tutti i report relativi alle varie sezioni, tra cui <ng-container *ngIf="cr" >l’Assistenza,</ng-container> <ng-container *ngIf="geo" >la Geolocalizzazione,</ng-container>  <ng-container *ngIf="classroom" >Education</ng-container>  <ng-container *ngIf="iot" >e l’IOT</ng-container> .
<br><br>
                                Una volta scelta la sezione interessata, compare la lista relativa a tutti gli utenti,<ng-container *ngIf="classroom" >aule,</ng-container><ng-container *ngIf="iot" >dispositivi</ng-container>   IOT presenti sulla piattaforma. Per scegliere un singolo utente bisogna spuntare sulla relativa casella presente vicino alla cartella di quest’ultimo.
                                <br>
                                In alternativa è possibile anche selezionare direttamente tutti gli utenti. Per farlo è necessario cliccare sulla checkbox situata in alto vicino all’intestazione.
                                Dopo aver scelto l’utente/i,<ng-container *ngIf="classroom" >l’aula virtuale/i,</ng-container><ng-container *ngIf="iot" >o il dispositivo/i interessati,</ng-container>   per proseguire è necessario cliccare sul pulsante <b>Avanti</b>
                               <br>
                               <br>
                                Effettuata questa operazione si viene reindirizzati ad un’altra schermata nella quale è presente un calendario, dal quale deve essere indicato il periodo di tempo a cui si deve riferire il report che deve essere scaricato.
                                Una volta scelto il range di date interessato per effettuare il download del report basta cliccare sulla seguente icona <i class="fa fa-folder"></i>. Se nel range di date selezionate non è presente nessun report, il sistema visualizza un messaggio in cui avvisa l’utente.
                                <br>
                                Se invece il report è presente nel range di date indicato, esso viene scaricato all’interno dell’elenco dei file presenti nel proprio dispositivo. Nel caso dei report relativi alla Geolocalizzazione, all’Aula Virtuale e ai dispositivi IOT, l’utente viene reindirizzato ad un ulteriore schermata dove sono presenti dei grafici compilati indicativamente del report scaricato.
                                <br> -->



                                Dalla sezione di <b>Reportistica</b> è possibile scaricare tutti i report relativi alle varie sezioni, tra cui <ng-container *ngIf="cr" >l’Assistenza,</ng-container>  <ng-container *ngIf="geo" >la Geolocalizzazione,</ng-container><ng-container *ngIf="classroom" >con l’Aula Virtuale,</ng-container> <ng-container *ngIf="iot" >la sezione IOT,</ng-container> <ng-container *ngIf="classroom" >la Customer Satisfaction</ng-container>.
                                <br><br>

                                Una volta scelta la sezione interessata, compare la lista relativa a tutti gli utenti, <ng-container *ngIf="classroom" >aule</ng-container>  <ng-container *ngIf="iot" >o dispositivi IOT </ng-container>presenti sulla piattaforma. Per scegliere un singolo utente bisogna spuntare sulla relativa casella presente vicino alla cartella di quest’ultimo.
                                In alternativa è possibile anche selezionare direttamente tutti gli utenti. Per farlo è necessario cliccare sulla checkbox situata in prossimità dell’intestazione.
                                Dopo aver scelto l’utente/i,<ng-container *ngIf="classroom" >l’aula virtuale/i,</ng-container> <ng-container *ngIf="iot" >o il dispositivo/i interessati</ng-container> , per proseguire è necessario cliccare sul pulsante <b>Avanti</b>
                                <br><br>

                                Effettuata questa operazione si viene reindirizzati ad un’altra schermata nella quale è presente un calendario, dal quale deve essere indicato il periodo di tempo a cui si riferirà il report che deve essere scaricato.
                                Una volta scelto il range di date interessato per effettuare il download del report basta cliccare sulla seguente icona   <i class="fa fa-folder"></i>.
                                <br><br>
                                <!-- <i style="color:  #2196F3" class="material-icons notranslate ">download</i> -->
                                Se nel range di date selezionate non è presente nessun report, il sistema visualizza un messaggio in cui avvisa l’utente dell’assenza di report relativi alle date selezionate. <br>
                                Se invece il report è presente nel range di date indicato, esso viene scaricato all’interno dell’elenco dei file presenti nel proprio dispositivo. <br><br>
                                Una volta completato il download di un report, se questi appartiene ad una delle seguenti categorie: <ng-container *ngIf="iot" >IOT, </ng-container> <ng-container *ngIf="geo" >Geolocalizzazione, </ng-container> <ng-container *ngIf="classroom" >Aula Virtuale,</ng-container> <ng-container *ngIf="classroom" >Customer Satisfaction,</ng-container>  avviene un reindirizzamento verso una schermata dalla quale sarà possibile consultare dei grafici inerenti alla scelta effettuata.<br>
                                <ng-container *ngIf="iot" >In caso di download completato di un report relativo ad un dispositivo IOT, si verrà reindirizzati in una pagina che contiene dei grafici inerenti alle telemetrie impostate per il dispositivo, sia consultabili che scaricabili singolarmente in formato immagine.</ng-container><br>
                                <ng-container *ngIf="classroom" > In caso di download completato di un report relativo ad un’Aula Virtuale, si verrà reindirizzati ad una pagina contenente grafici inerenti ad informazioni sulla partecipazione in aula degli utenti, a quante volte siano state scaricate le esercitazioni e alle volte in cui è stata effettuata un’interazione con i sondaggi durante la partecipazione all’aula virtuale.</ng-container>




                            </p-accordionTab>
                            <p-accordionTab *ngIf="(admin || operatore) && cr" header="Control Room">
                                <p>Cliccando su Control Room sarà possibile aprire una nuova sessione di videochiamata
                                    per fornire assistenza agli utenti.<br> Nel caso in cui ci sia già una sessione
                                    attiva, verrà mostrato il messaggio “Control Room già esistente”. <br>
                                    L'<b>Operatore</b> che sta utilizzando la control room, potrà controllare gli accessi degli
                                    utenti attraverso il bottone Libero/Occupato.
                                    Se il bottone risulta impostato su Libero,<img
                                        src="../../../../assets/img/liberoCR.jpg" style="width: 50px;height: auto;"
                                        alt="Libero"> può permettere l'entrata di un utente. Cliccando su Libero, si
                                    imposterà lo stato della Control Room
                                    ad occupato, cosi da bloccare eventuali entrate di altri utenti.<br>
                                    <img src="../../../../assets/img/listacr.jpg" style="width: 50px;height: auto;"
                                        alt="Lista">Qualora dovessero esserci utenti in attesa, comparirà la lista in
                                    alto a sinistra. Cliccando sulla lista,
                                    sarà possibile selezionare l'utente da far entrare, che riceverà dunque una
                                    notifica.
                                    <br>
                                    Dalle varie icone presenti sulla finestra della chiamata <img
                                        src="../../../../assets/img/cr.jpg" style="width: 200px;height: auto;"
                                        alt="icone"> sarà possibile disattivare il microfono, la webcam, condividere lo
                                    schermo, visualizzare la videochiamata a schermo intero e aprire la chat.  <br>
                                    L'icona <i style="color:  rgb(42, 45, 182);"
                                        class="material-icons notranslate ">camera_enhance</i> permette di selezionare un utente di
                                    interesse e di effettuarne lo screenshot. Seguirà quindi il download dell'immagine.
                                    <br>
                                    L'icona <i class="material-icons notranslate " style="color:  rgb(42, 45, 182);;">3d_rotation</i>
                                    permette invece l'inserimento di oggetti 3D, al fine di migliorare il supporto a
                                    distanza. Quando è attiva, si colora di verde. <br>
                                    Con <i class="material-icons notranslate " style="color: rgb(42, 45, 182);;">undo</i>, si possono
                                    invece eliminare gli oggetti 3D che vengono selezionati. <br>
                                    La seguente icona <i class="material-icons notranslate "
                                        style="color:  rgb(42, 45, 182);;">delete</i> cestina tutte le operazioni.
                                </p>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="(admin || operatore || callcenter) && geo" header="Geolocalizzazione">
                                <p>Dalla sezione <b>Geolocalizzazione</b> sarà possibile visualizzare la posizione e la lista
                                    degli utenti che hanno effettuato il login sulla piattaforma. 
                                    Sarà consentito filtrarli grazie alla casella di ricerca. 
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Videochiamata" *ngIf="utente && cr">
                                <p>Cliccando sulla sezione Videochiamata sarà possibile stabilire una connessione video
                                    con un <b>Operatore</b> per ricevere assistenza riguardo una motivazione selezionata.
                                    Nel caso l'<b>Operatore</b> non fosse disponibile, comparirà il messaggio "Nessuna sessione
                                    attiva".
                                    Nel caso l'<b>Operatore</b> fosse già occupato, comparirà il messaggio “Control Room
                                    occupata, ti verrà inviata una notifica appena sarà liberata”.
                                    L'utente riceverà quindi una notifica appena la Control Room sarà libera o
                                    l'<b>Operatore</b> ne permetterà l'accesso.
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Documentazione" *ngIf="dms">
                                <p>Cliccando sulla sezione <b>Documentazione</b> è possibile accedere ai file caricati sulla piattaforma.
                                    Cliccando il bottone + e compilando opportunamente il form, comprensivo di aggiunta del file
                                    desiderato, è possibile creare un nuovo documento. Quest’ultimo, fin da subito, è accessibile
                                    dagli utenti o dai gruppi di utenti scelti in fase di creazione.
                                    Nella sezione Cestino è possibile ripristinare i file eliminati.
                                </p>
                               <br><br> <p *ngIf="ruolo==1">
                                   <b>CORSI</b>

<br>Si carica da sezione <b>Documentazione</b> uno zip che segue il protocollo SCORM 1.0, SCORM 1.1, SCORM 1.2, SCORM 2004 “1st Edition”, SCORM 2004 2nd Edition, SCORM 2004 3rd Edition, SCORM 2004 4th Edition
<br>Si caricano alla creazione dell'aula nel <b>Pannello di Controllo</b>, una serie di zip che seguono i protocolli sopra citati
<br>Nella sezione è possibile associare un corso tramite apposito bottone tra quelli già caricati
<br>Inoltre sia per l'<b>Admin</b> che per l’<b>Insegnante</b> dell’aula è possibile tramite la sezione Corsi attraverso le info verificare quale degli utenti ha completato tramite le info del corso
</p>
<p *ngIf="ruolo != 1" >
Nella sezione corsi è possibile effettuare il corso associato all'aula e verificare le informazioni relative ad esso

                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Education" *ngIf="classroom && !alfa">
                                <p>Accedendo alla sezione <b>Education</b>, l’<b>Insegnante</b> vede {{ambiente.NOMEAULAIl}} a lui assegnate dall’<b>Admin</b>. Con
                                    l’icona dell'occhio, si entra {{ambiente.NOMEAULANEL}} ed è possibile vedere {{ambiente.NOMEAULAIl}} programmate ed accedere {{ambiente.NOMEAULAALLE}} in corso.
                                    Al click su "partecipa" viene avviata una videochiamata in cui l’<b>Insegnante</b> può
                                    svolgere la sua {{ambiente.LEZIONE}}, condividere schermo e webcam, mentre gli utenti devono richiedere la
                                    parola attraverso l’apposita icona di alzata di mano o attraverso l’uso della chat.
                                    L’<b>Insegnante</b> può cliccare in qualsiasi momento sul pulsante per poter dare i poteri di
                                    relatore (massimo 4 alla volta) e quindi abilitare l’utente ad attivare il microfono, condividere lo
                                    schermo e attivare la webcam.
                                    L’<b>Insegnante</b> in qualsiasi momento ha la facoltà di rimuovere i poteri di relatore.
                                    <br>
                                    <b>Creazione {{ambiente.LEZIONI}}</b>
                                    <br>
                                    Cliccando inoltre sulla voce "{{ambiente.CALENDARIOLEZIONI}}" è possibile visualizzare il calendario {{ambiente.LEZIONIDELLE}} pianificate per quella specifica {{ambiente.NOMEAULA}}.
                                    Eventualmente l'<b>Insegnante</b> può, cliccando sul tasto + , pianificare una nuova {{ambiente.LEZIONE}}, potendo
                                    selezionare anche la cadenza della stessa.
                                    Le opzioni relative alla cadenza di {{ambiente.LEZIONIUNA}} sono:
                                    Singolo: Modalità in cui l’<b>Insegnante</b> può selezionare una o più date in cui si terranno {{ambiente.LEZIONILE}}.
                                    Ripetuto: Modalità in cui l’<b>Insegnante</b> ha la possibilità di scegliere, per un range di date da lui
                                    selezionate, il giorno/i giorni della settimana in cui si deve ripetere {{ambiente.LEZIONILE}}.
                                    (Esempio: L’<b>Insegnante</b> vuole pianificare {{ambiente.LEZIONIUNA}} per tutti i Lunedì dal 1/02/2021 al
                                    28/02/2021. Creando con modalità ripetuto {{ambiente.LEZIONILA}}, questa viene pianificata
                                    automaticamente in tutti i Lunedì presenti nel range di date selezionate, senza il bisogno che
                                    l’<b>Insegnante</b> pianifichi ogni singola {{ambiente.LEZIONE}}.)
                                    <br>
                                    <b>{{ambiente.Esercitazioni}}</b>
                                    <br>
                                    Cliccando su {{ambiente.Esercitazioni}}, si accede alla suddetta sezione. L’<b>Insegnante</b> è in grado di creare una nuova
                                    {{ambiente.Esercitazioni}} alla quale tutti gli studenti che partecipano {{ambiente.NOMEAULANEL}} possono accedere scaricandola.
                                    A loro volta, gli studenti che completano {{ambiente.Esercitazioni}}, possono ricaricarla cliccando sul tasto di risposta.
                                    L’<b>Insegnante</b> vede le risposte caricate da tutti gli utenti.
                                    <br>
                                    <b>{{ambiente.LEZIONIREGISTRATE}}</b>

                                    <br>
                                </p>
                            </p-accordionTab>

                               <p-accordionTab header="Education" *ngIf="classroom && alfa">

                                Dalla sezione <b>Education</b>, è possibile visualizzare l’elenco dei webinar attivi e non, modificarli, e accedervi.
                              <br> <b>Webinar non attivo</b> : se nella colonna Azioni del webinar di interesse è presente l’icona con il lucchetto<i class="material-icons notranslate " style="color:#35b09b;">lock</i>, vuol dire che il webinar è passato, di conseguenza non è più possibile accedervi.
                              <br>  <b>Webinar attivo</b> : se nella colonna Azioni del webinar di interesse è presente l’icona con la freccia <i class="material-icons notranslate " style="color:#35b09b;">forward</i>, vuol dire che il webinar è ancora attivo e che quindi è possibile accedervi
                              <br>  <b> Modificare Webinar</b>: per modificare un webinar da questa sezione, è sufficiente cliccare sull’icona <i class="material-icons notranslate " style="color:#FFC107">info</i>, effettuare le modifiche volute e cliccare Salva.
                              <br>    Al click della freccia <i class="material-icons notranslate " style="color:#35b09b;">forward</i> del webinar di interesse compaiono le seguenti sezioni:
                              <br>    -	Webinar e Live chirurgici
                              <br>     -	Percorsi Formativi in programma
                              <br>     -	Materiali utili e letteratura scientifica
                              <br>    -	Archivio e Registrazioni
                              <br>      -	Percorsi formativi
                              <br> <br>    <b> Webinar e Live chirurgici</b>: permette l’accesso live ai webinar. Cliccando su Partecipa, l’<b>Insegnante</b> può svolgere la sua lezione, condividere schermo e webcam, mentre gli utenti devono richiedere la parola attraverso l’apposita icona di alzata di mano o attraverso l’uso della chat. L’<b>Insegnante</b> può cliccare in qualsiasi momento sul pulsante per poter dare i poteri di relatore (massimo 4 alla volta) e quindi abilitare l’utente ad attivare il microfono, condividere lo schermo e attivare la webcam. L’<b>Insegnante</b> in qualsiasi
                                momento ha la facoltà di rimuovere i poteri di relatore.
                                <br><br>    Ha la possibilità inoltre di creare la <b>Lavagna virtuale </b>cliccando l’apposito tasto Lavagna, che permette un fermo immagine per approfondire la parte voluta del webinar, e che l’utente visualizzerà con una seconda schermata, mentre il video del webinar proseguirà in background.
                                <br>      Di seguito le funzionalità della lavagna:
                                <br>   <i class="material-icons notranslate " style="color:#2196f3;">circle</i> Cerchio: Inserisce una forma geometrica circolare
                                <br>  <i class="material-icons notranslate " style="color:#2196f3;">arrow_upward</i> Freccia: Inserisce una freccia
                                <br>   <i class="material-icons notranslate " style="color:#2196f3;">image</i> Immagini: Inserisce un immagine a scelta tra quelle presenti nell' apposita galleria
                                <br>  <i class="material-icons notranslate " style="color:#2196f3;">text_fields</i> Testo: Inserisce un testo personalizzabile
                                <br>   <i class="material-icons notranslate " style="color:#2196f3;">mode_edit</i> Penna: Disegno a mano libera
                                <br>    <i class="material-icons notranslate " style="color:#2196f3;">auto_fix_high</i> Gomma: Cancella i disegni sull'immagine
                                <br>    <i class="material-icons notranslate " style="color:#2196f3;">highlight_alt</i> Selezionatore: Permette di selezionare uno o più elementi presenti sulla lavagna (ad eccezione della linea rilasciata dalla penna)
                                <br>   Spessore linee: Permette di cambiare la grandezza della penna, della gomma e delle forme
                                <br>     Grandezza testo: Permette di cambiare la grandezza del testo
                                <br>     <i class="material-icons notranslate " style="color:#2196f3;">delete_forever</i> Cestino: Rimuove tutti gli elementi dall'immagine
                                <br>     <i class="material-icons notranslate " style="color:#2196f3;">undo</i> Indietro: Permette annullare l'ultima modifica effettuata
                                <br>    <i class="material-icons notranslate " style="color:#2196f3;">redo</i> Avanti: Se è stato cliccato il bottone indietro è possibile tornare alla modifica precedentemente annullata
                                <br>     L’<b>Insegnante</b> ha inoltre la possibilità di registrare la lezione fino ad un massimo di due ore cliccando l’icona (inserire icona),
                                <br>    Durante una lezione in corso, è possibile verificare inoltre l’attività di uno studente inoltrandogli un messaggio generale cliccando l’icona  <i class="material-icons notranslate " style="color:white;">videocam</i>, che blocca la visualizzazione della pagina dello studente fino a quando non si clicca sulla casella “ok”, o un sondaggio al quale l’utente deve scegliere una casella tra “si” e “no”. I risultati delle risposte possono essere scaricati dal <b>report</b> di quell’aula.
                                <br> <br>  <b>Percorsi Formativi in Programma</b>: è possibile visualizzare il calendario delle lezioni pianificate per quella specifica Webinar. Eventualmente l'<b>Insegnante</b> può, cliccando sul tasto + , pianificare una nuova lezione, potendo selezionare anche la cadenza della stessa. Le opzioni relative alla cadenza di una lezione sono: Singolo: Modalità in cui l’<b>Insegnante</b> può selezionare una o più date in cui si terranno le lezioni. Ripetuto: Modalità in cui l’<b>Insegnante</b> ha la possibilità di scegliere, per un range di date da lui selezionate, il giorno/i giorni della settimana in cui si deve ripetere la lezione. (Esempio: L’<b>Insegnante</b> vuole pianificare una lezione per tutti i Lunedì dal 1/02/2021 al 28/02/2021. Creando con modalità ripetuto la lezione, questa viene pianificata automaticamente in tutti i Lunedì presenti nel range di date selezionate, senza il bisogno che l’<b>Insegnante</b> pianifichi ogni singola lezione.)
                                <br> <br>    <b>Materiali Utili e Letteratura Scientifica</b>: da questa sezione è possibile per l’<b>Insegnante</b> caricare materiale didattico, cliccando sull’icona <i class="material-icons notranslate " style="color:#35b09b;">add</i>, e per lo studente scaricarlo o inserire una risposta visualizzabile solo a <b>Insegnante</b> e <b>Admin</b>
                                <br> <br>   <b> Archivio Registrazioni</b>: da questa sezione è possibile visualizzare le registrazioni delle lezioni
                                <br> <br>   <b>Percorsi Formativi</b>: da questa sezione è possibile per ogni studente visualizzare le informazioni personali del corso relativo al webinar; gli <b>insegnanti</b> e gli <b>Admin</b> possono invece visualizzare le info di progresso dei corsi di tutti gli studenti, oltre che associarne uno nuovo al relativo webinar.
                               </p-accordionTab>

                               <p-accordionTab header="Newsletter" *ngIf="newsLetter && admin">

                                La sezione <b>Newsletter</b> permette di creare campagne informative tramite email temporizzate, programmate per essere inviate ad una lista di utenti. È possibile inoltre, per le campagne desiderate, creare dei reminder.
                              <br>  Arrivati nell’apposita sezione si può verificare il numero di messaggi programmati*,  e il numero di messaggi elaborati**, creare o modificare campagne e/o reminder.
                              <br>   Creazione campagna: per creare una nuova campagna, si clicca sul pulsante Crea Nuova Campagna, che prevede 4 steps:
                              <br>  1)Scelta del template
                              <br>   2)Modifica del template
                              <br>   3)Scelta della data e dell’orario di invio della campagna
                              <br>   4)Scelta del nome della campagna e della lista degli utenti a cui inviare l’email
                              <br>  <br>     Dalla lista delle campagne presente nella sezione <b>Newsletter</b> è possibile per ogni campagna associare uno o più reminder, modificarla, visualizzare le sue informazioni oppure eliminarla
                              <br>    Visualizzazione info campagna: dalla sezione <b>Newsletter</b> è possibile cliccare sull’icona <i class="material-icons notranslate " style="color: #FFC107;">info</i> per visualizzare le info della campagna di interesse
                              <br>   Visualizzare reminder campagna: dalla sezione <b>Newsletter</b>, è possibile cliccare sull’icona <i class="material-icons notranslate " style="color: #35b09b;">alarm_add</i> per visualizzare, se presenti, la lista dei reminder di quella campagna
                              <br>  <br>   Creazione reminder: per creare il reminder di una campagna, si clicca sull’icona <i class="material-icons notranslate " style="color: #35b09b;">alarm_add</i> della campagna di interesse, e sul pulsante Crea Nuovo Reminder, che prevede 4 steps:
                              <br>   1)Scelta del template
                              <br>     2)Modifica del template
                              <br>    3)Scelta della data e dell’orario del reminder
                              <br>4)Scelta del nome del reminder e della lista degli utenti a cui inviarla
                              <br>  <br>    *Messaggi programmati: È il conto di ogni messaggio non ancora inviato per utente
                              <br>    **Messaggi elaborati: Sono tutti i messaggi inviati per utente nel mese corrente


                               </p-accordionTab>

                               <p-accordionTab header="IOT" *ngIf="iot">
                                Per visualizzare il menù principale del dispositivo bisogna cliccare
                                nel menu laterale <b>Dispositivi IOT</b>.
                                <br>
                                <br>
                                Una volta effettuata questa operazione, si viene reindirizzati ad una schermata contenente la lista di tutti i dispositivi associati al relativo utente.
                                Selezionando un dispositivo e scorrendo la pagina verso il basso, possono essere visualizzate varie finestre contenenti diverse sezioni relative ai dettagli sul dispositivo e sugli ultimi monitoraggi.
                                <br>
                                <br>
                                Nella sezione <b>Allarmi</b>, che si trova alla destra dell’elenco con tutti i dispositivi associati all’utente, è presente una schermata che avvisa l’utente dell’eventuale presenza di qualche allarme.
                                <br>

                                Nel caso in cui dovessero scattare uno o più allarmi, la sezione si aggiorna, evidenziando il tipo di allarme che si è presentato e il nome del relativo dispositivo. Inoltre, nell’elenco dei dispositivi, viene visualizzata l’icona       che lampeggia vicino al nome del dispositivo o dei dispositivi coinvolti.
                                <br>
                                <br>
                                Scorrendo la pagina verso il basso, è presente la sezione di <b>Monitoring</b> nel quale viene visualizzata una schermata contenente i dati relativi all’ultimo monitoraggio effettuato con il dispositivo selezionato:
                                <br>  <small> NB: Quando la soglia viene evidenziata in rosso, significa che il valore riportato dalla misurazione si trova fuori dal range normale.
                                </small><br><br>

                                Proseguendo, è presente la sezione dello <b>Storico</b> nel quale è possibile consultare tutti gli allarmi che vengono registrati relativi al dispositivo.


                               </p-accordionTab>
                            <p-accordionTab header="Live Chat" *ngIf="!alfa">
                                <p>La sezione <b>Live Chat</b> permette di visualizzare la lista degli utenti online e offline
                                    registrati alla piattaforma.
                                    Cliccando sull’utente interessato sarà possibile avviare quindi una conversazione in
                                    tempo reale. 
                                </p>
                            </p-accordionTab>
                            <p-accordionTab header="Chatbot" *ngIf="chatbot">
                                <p>Il Chat-bot è un assistente automatizzato.
                                    L'utente potrà fare delle domande, e il <b>Chatbot</b> sarà in grado di rispondere in
                                    qualsiasi momento fornendo un’assistenza specifica sulle funzionalità della
                                    piattaforma. 
                                </p>
                            </p-accordionTab>


                        </p-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
