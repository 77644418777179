
<div class="container">
    <div class="row">
        <div  class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" >
                <div class="card card-login ">
                    <div class="card-header text-center">
                        <h4 class="card-title" style="margin-bottom: 2%;">Recupero password</h4>
                        <p>Ti invieremo le istruzioni per recuperare la password al seguente indirizzo</p>
                    </div>
                    <div class="card-content">
                        
                                                  
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons notranslate">face</i>
                            </span>
                            <div class="form-group label-floating">
                                <label class="control-label">Email<span class="colorRed"> *</span></label>
                                <input type="text" formControlName="email"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback" maxlength="100">
                                    <div *ngIf="f.email.errors.required">Email obbligatoria</div>
                                    <div *ngIf="f.email.errors.email">Formato non corretto</div>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                    <div class="buttons">
                        <div class="form-group">
                            <a routerLink="../login" class="btn btn-link">Annulla</a>
                            <button [disabled]="loading" class="btn btn-primary">
                                Prosegui
                                <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                
                            </button>
                            
                        </div></div>
                </div>
            </form>
        </div>
    </div>
</div>
