import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statoTemporale',
  pure: false
})
export class StatoTemporale implements PipeTransform {

  transform(value, args?: any): any {
    let dataAttuale = new Date();
   let valuee=new Date(value)
    if (value && new Date(value) < dataAttuale) {
      return "Completo"
    }
    return "Incompleto";
  }
}
