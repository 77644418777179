import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { stringify } from 'querystring';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AccountService } from 'src/app/SERVICES/index';
import { UsersService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-dettagli-profilo',
  templateUrl: './dettagli-profilo.component.html',
  styleUrls: ['./dettagli-profilo.component.scss']
})
export class DettagliProfiloComponent implements OnInit {
  
  @Input() profiloInfo: string;
  @Output() cambia= new EventEmitter<boolean>();
  @Output() vaiModifica= new EventEmitter<string>();

  listaGruppi=[];
  users: Utente[];
  gruppi=[];

  idUtente;
  listaUtentiByProfilo=[];
  searchform: FormGroup;
  listautenti: Utente[];
  allListautenti: Utente[];
  tag;
  constructor(
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private accountService : AccountService
    ) {
    
     }

  ngOnInit(): void {
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.userService.getListaGruppi().subscribe((res) => {

      this.listaGruppi = res.data;
      this.listaGruppi.forEach(gruppo =>{
        this.gruppi.push(
          {label:gruppo.nomeGruppo, value:gruppo.id}
        )
      }); 
    }, (error) => {
    
    });

    this.dettagliProfilo(this.profiloInfo);
   
  }

  dettagliProfilo(id : string){
    
    this.userService.getAllRuolo(id).subscribe((res)=>{
   
      let temp;
      temp = res.data;
      //this.tag = temp?.tag?.nome;
      this.users = temp;
      this.allListautenti= this.users;
      this.allListautenti= this.allListautenti.filter(x =>x.id!==this.accountService.userValue.data.id)
    });
  }

  indietro(chiudi: boolean){//fa tornare a gestione profili
    
      this.cambia.emit(chiudi);
          
  }

  modificaUtente(id){//passa utente da modificare 
    
    this.idUtente=id;   
    this.vaiModifica.emit(this.idUtente);

  }
  searchInUser() {
      
      
    let valore: string = this.searchform.value.search;
   this.users = this.allListautenti;
    if (valore) {
      let listautenti = []
      this.users.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase())) {
            if (!(listautenti.find(x => x === user))) {
            listautenti.push(user);
          }
          }
        })
        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) || 
            nomeCognome.toLowerCase().includes(valore.toLowerCase()) || 
            user.nome.toLowerCase().includes(valore.toLowerCase()) || 
            user.cognome.toLowerCase().includes(valore.toLowerCase()) || 
            user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(listautenti.find(x => x === user))) {
          listautenti.push(user);
          }
        }
      });
      this.users = listautenti;
      
      
      
    } else if (!valore) {
      this.users = this.allListautenti;
    
     
    }
  }
}
