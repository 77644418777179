<ng-container *ngIf="!isFromElimina">
  <h4 class="card-title">Crea il tuo Form</h4>

  <mat-horizontal-stepper linear #stepper (selectionChange)="changeIndex($event)">
    <mat-step [completed]="checkValidityName()">
      <ng-template matStepLabel>Nome Form</ng-template>

      <div class="flex mt-10">
        <div class="ml-none">
          <label class="names"><strong>Seleziona Nome:</strong></label>
        </div>

        <div class="form-outline ml-15">
          <input type="text" class="form-control" placeholder="Nome Form" [(ngModel)]="nameForm" />
        </div>
      </div>
    </mat-step>
    <mat-step [completed]="checkLeastOneSelected()">
      <ng-template matStepLabel>Campi</ng-template>

      <div class="flex mt-10">
        <div class="container ml-none">
          <p-checkbox [(ngModel)]="isAgentsSelected" [binary]="true" (onChange)="checkUncheckAllAgents()"> </p-checkbox>
          <label class="names"><strong>Seleziona Campi:</strong></label>
        </div>

        <div class="form-outline">
          Esegui una ricerca
          <input type="search" id="form1" class="form-control" placeholder="Filtra Campi"
            (keyup)="checkallCheckboxAgents()" [(ngModel)]="searchwordAgents" />
        </div>
      </div>

      <ng-container *ngFor="let agente of arrayAgent">
        <div class="container" *ngIf="agente.name.toLowerCase().includes(searchwordAgents.toLowerCase())">
          <p-checkbox [(ngModel)]="agente.selected" [binary]="true" (onChange)="checkallCheckboxAgents()"> </p-checkbox>
          <label class="names">{{agente.name}}</label>
        </div>
      </ng-container>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Attrezzature disponibili</ng-template>

      <div class="flex">
        <div class="container ml-none">
          <p-checkbox [(ngModel)]="isEquipmentsSelected" [binary]="true" (click)="checkUncheckAllEquipments()">
          </p-checkbox>
          <label class="names"><strong>Seleziona attrezzature:</strong></label>
        </div>

        <div class="form-outline mt-10">
          Esegui una ricerca
          <input type="search" id="form1" class="form-control" placeholder="Filtra Attrezzature"
            (keyup)="checkallCheckboxEquipments()" [(ngModel)]="searchwordEquipments" />
        </div>
      </div>
      <div class="flex f-wrap">
        <ng-container *ngFor="let attrezzatura of arrayEquipments">
          <div class="container m-0 pr-0 flex w-fit-content"
            *ngIf="attrezzatura.name.toLowerCase().includes(searchwordEquipments.toLowerCase())">
            <p-checkbox [(ngModel)]="attrezzatura.selected" [binary]="true" (onChange)="checkallCheckboxEquipments()">
            </p-checkbox>
            <label class="names">{{attrezzatura.name}}</label>
          </div>
        </ng-container>

      </div>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>Prodotti</ng-template>
      <p-checkbox [ngModel]="prodotti" [binary]="true" (onChange)="prodotti = !prodotti"></p-checkbox>
      <label class="names">Abilita la possibilità di aggiungere prodotti</label>
      <!-- <app-lista-file (emitFilesSelected)="getFiles($event)" [fromPrenotaTecnico]="true"></app-lista-file> -->
    </mat-step>
  </mat-horizontal-stepper>
  <div class="flex">
    <button class="btn btn-primary" (click)="goBack(stepper)" type="button">Indietro</button>
    <button class="btn btn-primary ml-auto" *ngIf="stepperIndex!==3" (click)="goForward(stepper)"
      type="button">Avanti</button>
    <button class="btn btn-primary ml-auto" *ngIf="stepperIndex===3" (click)="saveForm()" type="button">Salva</button>
  </div>
</ng-container>

<ng-container *ngIf="isFromElimina">
  <h4 class="card-title center">Elimina una Tipologia</h4>
  <div class="center">
    <mat-form-field appearance="standard">
      <mat-label>Filtra</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filtro" #input>
    </mat-form-field>
    <div class="mat-elevation-z8">

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <th class="w-100" mat-header-cell *matHeaderCellDef mat-sort-header>
            Nome Tipologia
          </th>
          <td class="w-100 left center-vertically" mat-cell *matCellDef="let element">
            {{element.name}}
          </td>

        </ng-container>
        <ng-container matColumnDef="azioni">
          <th mat-header-cell *matHeaderCellDef >
            Elimina
          </th>
          <td mat-cell *matCellDef="let element" class="iconaElimina">
            <i (click)="deleteTipologia(element.id)" class="material-icons deleteIcon no-translate">delete</i>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsSmartwatch"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSmartwatch;"></tr>
      </table>
      <div class="flex" *ngIf="refreshView">
        <label class="form-group ml-auto mr-10">Limita Risultati </label>
            <select [(ngModel)]="numeroRisultati" (ngModelChange)="cambiaValue()"
                aria-controls="datatables" class="w-auto form-control input-sm optionNoGray">
                <option class="optionNoGray" value="5">5</option>
                <option class="optionNoGray" value="10">10</option>
                <option class="optionNoGray" value="20">20</option>
                <option class="optionNoGray" value="50">50</option>

            </select>
      <app-paginator class="p-10 right"  [pageSize]="numeroMaxPagine" [(isClickable)]="isClickable"
        (currentPageEmitter)="cambiaPagina($event)" [startingPage]="pagina+1"></app-paginator>

      </div>
    </div>
  </div>
  <button class="btn btn-primary" (click)="this.backShowForm.emit()" type="button">Indietro</button>
</ng-container>
