
<div class="container">
    <div class="row">
        <div  class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" >
                <div class="card card-login ">
                    <div class="card-header text-center">
                        <h4 class="card-title" style="margin-bottom: 2%;">Imposta password</h4>
                    </div>
                    <div class="card-content">


                        <div class="input-group">
                            <span class="input-group-addon">
                                <i [class]="hide1 ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off' " style="font-size: 20px;"  (click)="hide1 = !hide1"></i>
                            </span>
                            <div class="form-group label-floating">
                                <label class="control-label">Password<span class="colorRed"> *</span></label>

                                <input [type]="hide1 ? 'password' : 'text'" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" maxlength="100">

                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password obbligatoria</div>
                                        <div *ngIf="f.password.errors.pattern">La password deve iniziare con una lettera ed essere lunga tra gli 8 e i 16 caratteri, deve contenere almeno: una lettera minuscola e maiuscola, un numero e un carattere speciale ($, @, !, %, *, ?, &)</div>

                                    </div>


                            </div> </div>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i [class]="hide2 ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off' " style="font-size: 20px;"  (click)="hide2 = !hide2"></i>
                                </span>

                            <div class="form-group label-floating">
                                <label class="control-label">Conferma Password<span class="colorRed"> *</span></label>

                                <input [type]="hide2 ? 'password' : 'text'" class="form-control" formControlName="confermapassword" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" maxlength="100">

                                <div *ngIf="submitted && f.confermapassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confermapassword.errors.required">Conferma password obbligatoria</div>
                                    <div *ngIf="f.confermapassword.errors.mustMatch">Le password devono coincidere</div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div class="buttons">
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-primary">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Conferma
                            </button>
                        </div></div>
                </div>
            </form>
        </div>
    </div>
</div>
