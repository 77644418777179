<p-confirmDialog #cd icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth}" [acceptLabel]="'Si'"
  [baseZIndex]="5">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>
<!-- visioneWeb -->
<!-- smartVision inizio Modifiche-->
<div *ngIf="!smartVision">
  <!-- smartVision inizio Modifiche-->

  <!-- Vista principale Video+chat+listaUtentu START -->
  <div class="content"
    style="margin-top: -10px;margin-left: -10px!important;margin-right: -12px!important; background-color: white;"
    #fullScreen>
    <div class="container-fluid" [ngClass]="{'h-100 overflow-auto':isFullScreen}" style="padding: 0!important"
      *ngIf="permessi">
      <!-- <a class="screen btn-link"  style="margin-left: 5px;" (click)="alzaLaMano()">Modalità fullscreen</a> -->
      <!-- <a class="screen btn-link" *ngIf="isFullScreen" (click)="closeFullscreen()">Esci dalla modalità fullscreen</a> -->
      <div class="row" style="margin-left: 5px;" [ngClass]="{'centered': screenSize<800}">

        <!-- OpenVidu START -->

        <!-- #containerOpenVidu serve per cattutare altezza
              formattazioneSchermo gli assegna una grid-->
        <div #containerOpenvidu [ngClass]="formattazioneSchermo('openVidu')" style="z-index: 10;" class="openVidu"
          id="openviduAltezza">
          <ng-container class="openVidu" *ngIf="session && modified === undefined && urlEnv !== ''">
            <opv-session (cambiaEvidenzaEmit)="cambiaEvidenza($event)" #ovSessionComponent [ovSettings]="ovSettings" [nicknameBigDefault]="rememberEvent"
              [url]="urlEnv" [idAula]="idAula" [sessionName]="mySessionId" [user]="myUserName" [tokens]="tokens"
              (sessionCreated)="handlerSessionCreatedEvent($event)" (leaveSession)="close($event)"
              (publisherCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)"
              (settingCamButtonClicked)="settingCamButtonClicked()">
            </opv-session>
          </ng-container>
          <ng-container class="openVidu" *ngIf="session && modified && urlEnv !== ''">
            <opv-session (cambiaEvidenzaEmit)="cambiaEvidenza($event)" #ovSessionComponent [ovSettings]="ovSettings" [nicknameBigDefault]="rememberEvent"
              [url]="urlEnv" [idAula]="idAula" [sessionName]="mySessionId" [user]="myUserName"
              [micSelected]="microphone" [camSelected]="camera" [tokens]="tokens"
              (sessionCreated)="handlerSessionCreatedEvent($event)" (leaveSession)="close($event)"
              (publisherCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)"
              (settingCamButtonClicked)="settingCamButtonClicked()">
            </opv-session>
          </ng-container>

          <!-- Chat videochiamata START -->
          <div style="position: absolute; z-index: 100; top: 0;">
            <div class="alert-personalizzato" role="alert" *ngFor="let messaggio of msgs">
              <span aria-hidden="true"><i class="material-icons notranslate"
                  style="font-size: small; margin-right: 5px;">pan_tool</i></span>
              <strong>{{messaggio.summary}}</strong> {{messaggio.detail}}
              <button style="color: #224a23; margin-top: 4px; margin-left: 14px;" type="button" class="close"
                data-dismiss="alert" aria-label="Close" (click)=cancellaMessaggio(messaggio)>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <!-- Chat videochiamata END -->
          <!-- dropup button lista utenti connessi -->
          <div *ngIf="checkButtonInvisible()" (click)="changeMenu()" style="z-index: 1000000">
            <div class="btn-group dropup"
              style="z-index: 999999;position: absolute;left: 5px;margin-top: 0;margin-bottom: 0; bottom: 0;"
              [ngClass]="{'fullscreenCento': !openLavagna,'fullscreenMeta': openLavagna}">
              <button type="button" class="dropdown-toggle btnListaUtenti invisibile" data-boundary="viewport"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">#
              </button>
              <div *ngIf="showMenu">

                <div class="dropdown-menu" style="height: 65vh;
                  width: 37vh; overflow-x: auto;">
                  <table class="table table-sm table-responsive table-borderless">
                    <thead>
                      <tr style="display: none;">
                        <th style="width: 70%"></th>
                        <th style="width: 30%"></th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngIf="utenteOriginario.ruolo === 4 || utenteOriginario.ruolo === 1">
                        <td>
                      <tr style="background-color: transparent!important;">
                        <a>
                          <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                            style="font-size: small; color:#689f38">circle</i>
                          <!-- <span
                          class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                          style="color: transparent; min-width: 0.9rem!important; height: 1.5rem!important;">
                          1</span> -->
                        </a>
                        {{utenteOriginario.userValue.data.nome}} {{utenteOriginario.userValue.data.cognome}}
                      </tr>
                      </td>

                      <td style="white-space: nowrap">
                        <div class="row" class="pull-right">
                          <a *ngIf="isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                            (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                              class="material-icons notranslate">record_voice_over</i></a>
                          <a *ngIf="!isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                            (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                              class="material-icons notranslate">voice_over_off</i></a>
                          <!-- <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">record_voice_over</i></a>
                      <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">voice_over_off</i></a> -->
                          <!-- <a *ngIf="j%2===0"><i class="material-icons notranslate" style="color: rgb(190, 0, 0);">mic_off</i></a> -->
                        </div>
                      </td>
                      </tr>
                      <tr *ngFor="let utente of listaUtenti ; let j= index">
                        <td>
                      <tr style="background-color: transparent!important;">
                        <a *ngIf="utente.presenza === 1">
                          <!-- <span class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                  style="color: transparent; min-width: 0.9rem!important; height: 0.9rem!important;">1</span> -->
                          <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                            style="font-size: small; color: #689f38;">circle</i>
                        </a>
                        <a *ngIf="utente.presenza === 2">
                          <!-- <span class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                  style="color: transparent; min-width: 0.9rem!important; height: 0.9rem!important;">1</span> -->
                          <i data-toggle="tooltip" title="In Attesa" class="material-icons notranslate"
                            style="font-size: small; color: #FFE600;">circle</i>
                        </a>
                        <a *ngIf="!utente.presenza || utente.presenza === 0">
                          <!-- <span class="p-badge p-badge-danger" data-toggle="tooltip" data-placement="top" title="assente"
                  style="color: transparent;min-width: 0.9rem!important; height: 0.9rem!important;">0</span> -->
                          <i data-toggle="tooltip" title="Assente" class="material-icons notranslate"
                            style="font-size: small; color: #d32f2f;">circle</i>
                        </a>
                        {{utente.nome}} {{utente.cognome}}
                      </tr>
                      </td>

                      <td style="white-space: nowrap">
                        <div class="row" class="pull-right">
                          <a *ngIf="((ruoloUser !== 1)||(ruoloUser !== 4)) && utente.id === user.id"
                            (click)="alzaLaMano()"><i class="material-icons notranslate"
                              style="font-size: medium; margin-right: 3px;">pan_tool</i></a>
                          <a *ngIf="utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))&& utente.id !== user.id"
                            (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                              class="material-icons notranslate">record_voice_over</i></a>
                          <a *ngIf="!utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4)) && utente.id !== user.id"
                            (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                              class="material-icons notranslate">voice_over_off</i></a>
                              <a *ngIf="isRelatoreInsegnante && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))"
                                (click)="muteMicrophone(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                                  class="material-icons notranslate">mic</i></a>
                          <a *ngIf="utente.presenza === 2 && ((ruoloUser === 1)||(ruoloUser === 4))" class="flex"
                            [ngClass]="{'disabled': isStillLoading}"><i class="material-icons notranslate"
                              (click)="accettaRifiutaUser(utente)">close</i>
                            <i class="material-icons notranslate"
                              (click)="accettaRifiutaUser(utente,true)">check</i></a>
                        </div>
                      </td>

                      </tr>
                      <tr *ngIf="!listaUtenti">
                        <td colspan="4" class="text-center">
                          <span class="spinner-border spinner-border-lg align-center"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>



                </div>
              </div>
            </div>
          </div>
          <!-- dropup button lista utenti connessi fine -->

          <!-- Pulsante Sondaggio START-->
          <div
            style="z-index: 1000000;position: absolute;margin-top: -24px;left: 0px;color: white;width: 40px;height: 40px;opacity: 0;"
            *ngIf="isActiveLavagnaComponent">
            <!-- icona graafica che se premuta apre il popup -->
            <i *ngIf="(ruoloUser==4||ruoloUser==1)" class="material-icons notranslate"
              (click)="showUserLavagnaActivated()" style="cursor: pointer;">group</i>
          </div>

          <!-- Pulsante Sondaggio END-->

          <!-- Pulsanti in basso schermata START -->
          <div *ngIf="envLavagna && (ruoloUser==4||ruoloUser==1)" class="btn-group dropup"
            style="z-index: 999999;position: absolute;margin-top: -24px;right: 55px;color: white;">

            <!-- Pulsante lavagna START -->
            <a type="button" style="z-index: 99999;color: white;" class="collapsed dropdown-toggle"
              *ngIf="lavagnaOpenable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Lavagna
            </a>
            <!-- Pulsante lavagna END -->

            <!-- Dropdown screenshot per lavagna START -->
            <!-- caso utenti con stream attivi START -->
            <div *ngIf="verificaUsersAttivi()" class="dropdown-menu" style="margin-left: -162px;
                      display: grid;
                      max-height: 1000px;
                      overflow-y: scroll;
                      min-width: 215px;
                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                      text-align: center;">
              <a *ngFor="let utenti of userStreams,let i=index">
                <a *ngIf="utenti.videoActive" style="margin-top: 10px; color: 4388b9;"
                  (click)="apriFinestraConLavagna(i)">{{utenti.email}}
                </a>
              </a>
            </div>
            <!-- caso utenti con stream attivi END -->

            <!-- caso nessun stream video attivo START -->
            <div *ngIf="!verificaUsersAttivi()" class="dropdown-menu" style="margin-left: -162px;
                      display: grid;
                      max-height: 1000px;
                      overflow-y: scroll;
                      min-width: 215px;
                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                      text-align: center;">
              <a *ngFor="let utenti of messaggioDefault">
                <a style="margin-top: 10px; color: 4388b9;">{{utenti.email}}</a>
              </a>
            </div>
            <!-- caso nessun stream video attivo END -->
          </div>
          <!-- Dropdown screenshot per lavagna END -->

          <!-- Pulsante Sondaggio START-->
          <div style="z-index: 999999;position: absolute;margin-top: -24px;right: 5px;color: white;">
            <!-- icona graafica che se premuta apre il popup -->
            <i data-toggle="tooltip" title="Verifica inattività" *ngIf="(ruoloUser==4||ruoloUser==1)"
              class="material-icons notranslate" (click)="apriPopUpSondaggio()" style="cursor: pointer;">poll</i>
          </div>

          <!-- Pulsante Sondaggio END-->

          <!--cam START-->
          <div *ngIf="envRecording"
            style="z-index: 999999;position: absolute;margin-top: -24px;right: 30px;color: white;">
            <!-- icona graafica che se premuta apre il popup -->
            <i data-toggle="tooltip" title="Registra" *ngIf="(ruoloUser==4||ruoloUser==1)"
              class="material-icons notranslate" [ngClass]="{'red': isRecording}" (click)="toggleRecording()"
              style="cursor: pointer;">videocam</i>
          </div>

          <!--cam END-->

          <!-- Pulsante schermo intero START -->
          <!-- <div style="z-index: 999999;position: absolute;margin-top: -24px;right: 5px;color: white;">
            <i data-toggle="tooltip" title="Schermo intero" class="material-icons notranslate" *ngIf="!isFullScreen" (click)="openFullscreen()"
              style="cursor: pointer;">fullscreen</i>
            <i data-toggle="tooltip" title="Togli schermo intero" class="material-icons notranslate" *ngIf="isFullScreen" (click)="closeFullscreen()"
              style="cursor: pointer;">fullscreen_exit</i>
          </div> -->

          <!-- Pulsante schermo intero END -->

        </div>
        <!-- OpenVidu END -->

        <!-- Lavagna AlfaIntes START -->
        <!-- id parentElement serve per il responsive della lavagna, il metodo si trova dentro al componente lavagna [ngClass]="formattazioneSchermo('lavagna')" -->
        <div id="parentElement" class="col-xs-7" *ngIf="isActiveLavagnaComponent && closedLavagna">
          <!-- ngClass aggiunge classi singole -->
          <button *ngIf="(ruoloUser==4||ruoloUser==1)"
            [ngClass]="{'btn':true, 'btn-round':true, 'closeLavagna': !isFullScreen, 'closeLavagnaFull' : isFullScreen }"
            (click)="richiediChiusuraLavagna()">
            <i class="material-icons btnChiusuraLavagna" style="cursor: pointer; right: 8px;">close</i>
          </button>
          <!--
          <div class="closeLavagna">
             il metodo richiediChiusuraLavagna agisce sul behavior al quale il componente lavagna ha fatto la sub
                 servirà per chiedere il salvataggio dell'immagine
            <i class="material-icons notranslate btnChiusuraLavagna" (click)="richiediChiusuraLavagna()"
              style="cursor: pointer;">close</i>
          </div>-->
          <div style="overflow: hidden;height: 100%;">
            <!-- La lavagna emettere il formdata coma evento, il padre recupero tale evento e esegue la chiusura -->
            <app-whiteboard-page [openedByOtherUser]="openedByOtherUser" [ruoloUser]="ruoloUser"></app-whiteboard-page>
            <!-- [ruoloUser]="ruoloUser" (formDataDone)="chiudiLavagnaComponent($event)" (enableImagesEmitter)="refreshVideo()" -->
          </div>
        </div>

        <!-- Lavagna AlfaIntes END -->

        <!-- Lista UTENTI START -->
        <!-- la formattazioneSchermo assegna una grid (spartita da openVidu e lavagna)
             la classe listaUtentiLavagna gli assegna lo style
             ngStyle nasconde il componente se la lavagna non è attiva attraverso display:none
             visto che in ts tale div deve essere catturato non potevo fare uso di ngIf
        -->
        <ng-container *ngIf="screenSize > 800">
          <div class="col-xs-3" [ngClass]="formattazioneSchermo('listaUtenti')"
            [ngClass]="{'listaUtentiLavagna': showUserLavagna}"
            [ngStyle]="{'display': !(isActiveLavagnaComponent && closedLavagna)  ? '' : 'none' }"
            style="padding-left: 0px!important;">
            <div id="listaUtenti" [ngClass]="{scroll: !isFullScreen, scroll2: isFullScreen}"
              style="border: 1px solid #d1d1d1; background-color: #f9f9f9;">
              <!-- <form [formGroup]="searchform" >
                        <div   style="width: 100%!important; margin-left: 3px;">
                          <input type="text" formControlName="search" class="form-control" placeholder="Cerca..." aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm">
                        </div>
                      </form>
                      <div *ngIf="searchInUser()"></div> -->

              <!-- <div id="datatables_filter" class="dataTables_filter">
                        <form [formGroup]="searchform">
                            <label class="form-group" style="margin-top:0px; padding-bottom:0px">
                            <input type="text" formControlName="search" class="form-control" placeholder="Cerca..." maxlength="30" style="width: 40vw;">
                        </label>
                        </form>
                          <div *ngIf="searchInUser()"></div>
                    </div> -->
              <table class="table table-sm table-responsive table-borderless showPartecipant">
                <thead>
                  <tr style="display: none;">
                    <th style="width: 70%"></th>
                    <th style="width: 30%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="utenteOriginario.ruolo === 4 || utenteOriginario.ruolo === 1">
                    <td>
                  <tr style="background-color: transparent!important;">
                    <a>
                      <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                        style="font-size: small; color:#689f38">circle</i>
                      <!-- <span
                          class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                          style="color: transparent; min-width: 0.9rem!important; height: 1.5rem!important;">
                          1</span> -->
                    </a>
                    {{utenteOriginario.userValue.data.nome}} {{utenteOriginario.userValue.data.cognome}}
                  </tr>
                  </td>

                  <td style="white-space: nowrap">
                    <div class="row" class="pull-right">
                      <a *ngIf="isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                        (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">record_voice_over</i></a>
                      <a *ngIf="!isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                        (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">voice_over_off</i></a>
                      <!-- <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">record_voice_over</i></a>
                      <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">voice_over_off</i></a> -->
                      <!-- <a *ngIf="j%2===0"><i class="material-icons notranslate" style="color: rgb(190, 0, 0);">mic_off</i></a> -->
                    </div>
                  </td>
                  </tr>
                  <tr *ngFor="let utente of listaUtenti ; let j= index">
                    <td>
                  <tr style="background-color: transparent!important;">
                    <a *ngIf="utente.presenza === 1">
                      <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                        style="font-size: small; color:#689f38">circle</i>
                      <!-- <span
                      class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                      style="color: transparent; min-width: 0.9rem!important; height: 1.5rem!important;">
                      1</span> -->
                    </a>

                    <a *ngIf="utente.presenza === 2">
                      <!-- <span class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                      style="color: transparent; min-width: 0.9rem!important; height: 0.9rem!important;">1</span> -->
                      <i data-toggle="tooltip" title="In Attesa" class="material-icons notranslate"
                        style="font-size: small; color: #FFE600;">circle</i>
                    </a>
                    <a *ngIf="!utente.presenza || utente.presenza === 0">
                      <i data-toggle="tooltip" title="Assente" class="material-icons notranslate"
                        style="font-size: small; color: #d32f2f;">circle</i>
                      <!-- <span class="p-badge p-badge-danger" data-toggle="tooltip"
                      data-placement="top" title="assente"
                      style="color: transparent;min-width: 0.9rem!important; height: 1.5rem!important;">
                      0</span> -->
                    </a>
                    {{utente.nome}} {{utente.cognome}}
                  </tr>
                  </td>

                  <td style="white-space: nowrap">
                    <div class="row" class="pull-right">
                      <a *ngIf="((ruoloUser !== 1)||(ruoloUser !== 4)) && utente.id === user.id"
                        (click)="alzaLaMano()"><i class="material-icons notranslate"
                          style="font-size: medium; margin-right: 3px;">pan_tool</i></a>
                      <a *ngIf="utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))&& utente.id !== user.id"
                        (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">record_voice_over</i></a>
                      <a *ngIf="!utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4)) && utente.id !== user.id"
                        (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">voice_over_off</i></a>
                          <a *ngIf="isRelatoreInsegnante && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))"
                            (click)="muteMicrophone(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                              class="material-icons notranslate">mic</i></a>
                      <a *ngIf="utente.presenza === 2 && ((ruoloUser === 1)||(ruoloUser === 4))" class="flex"
                        [ngClass]="{'disabled': isStillLoading}"><i class="material-icons notranslate"
                          (click)="accettaRifiutaUser(utente)">close</i>
                        <i class="material-icons notranslate" (click)="accettaRifiutaUser(utente,true)">check</i></a>
                      <!-- <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">record_voice_over</i></a>
                      <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">voice_over_off</i></a> -->
                      <!-- <a *ngIf="j%2===0"><i class="material-icons notranslate" style="color: rgb(190, 0, 0);">mic_off</i></a> -->
                    </div>
                  </td>

                  </tr>
                  <tr *ngIf="!listaUtenti">
                    <td colspan="4" class="text-center">
                      <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </ng-container>
        <!-- Lista UTENTI END -->

        <ng-container *ngIf="screenSize > 800">
          <div class="col-xs-3" [ngClass]="formattazioneSchermo('listaUtenti')"
            [ngClass]="{'listaUtentiLavagna': showUserLavagna}"
            [ngStyle]="{'display': (isActiveLavagnaComponent && closedLavagna && showUserLavagna)  ? '' : 'none','bottom.px':41}"
            style="padding-left: 0px!important;z-index: 10;">
            <div id="listaUtenti" class="h-300" [ngClass]="{scroll: !isFullScreen, scroll2: isFullScreen}"
              style="border: 1px solid #d1d1d1; background-color: #f9f9f9;height: 300px;">
              <!-- <form [formGroup]="searchform" >
                          <div   style="width: 100%!important; margin-left: 3px;">
                            <input type="text" formControlName="search" class="form-control" placeholder="Cerca..." aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm">
                          </div>
                        </form>
                        <div *ngIf="searchInUser()"></div> -->

              <!-- <div id="datatables_filter" class="dataTables_filter">
                          <form [formGroup]="searchform">
                              <label class="form-group" style="margin-top:0px; padding-bottom:0px">
                              <input type="text" formControlName="search" class="form-control" placeholder="Cerca..." maxlength="30" style="width: 40vw;">
                          </label>
                          </form>
                            <div *ngIf="searchInUser()"></div>
                      </div> -->
              <table class="table table-sm table-responsive table-borderless showPartecipant">
                <thead>
                  <tr style="display: none;">
                    <th style="width: 70%"></th>
                    <th style="width: 30%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="utenteOriginario.ruolo === 4 || utenteOriginario.ruolo === 1">
                    <td>
                  <tr style="background-color: transparent!important;">
                    <a>
                      <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                        style="font-size: small; color:#689f38">circle</i>
                      <!-- <span
                            class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                            style="color: transparent; min-width: 0.9rem!important; height: 1.5rem!important;">
                            1</span> -->
                    </a>
                    {{utenteOriginario.userValue.data.nome}} {{utenteOriginario.userValue.data.cognome}}
                  </tr>
                  </td>

                  <td style="white-space: nowrap">
                    <div class="row" class="pull-right">
                      <a *ngIf="isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                        (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">record_voice_over</i></a>
                      <a *ngIf="!isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                        (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">voice_over_off</i></a>
                      <!-- <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">record_voice_over</i></a>
                        <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">voice_over_off</i></a> -->
                      <!-- <a *ngIf="j%2===0"><i class="material-icons notranslate" style="color: rgb(190, 0, 0);">mic_off</i></a> -->
                    </div>
                  </td>
                  </tr>
                  <tr *ngFor="let utente of listaUtenti ; let j= index">
                    <td>
                  <tr style="background-color: transparent!important;">
                    <a *ngIf="utente.presenza === 1">
                      <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                        style="font-size: small; color:#689f38">circle</i>
                      <!-- <span
                        class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                        style="color: transparent; min-width: 0.9rem!important; height: 1.5rem!important;">
                        1</span> -->
                    </a>

                    <a *ngIf="utente.presenza === 2">
                      <!-- <span class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                      style="color: transparent; min-width: 0.9rem!important; height: 0.9rem!important;">1</span> -->
                      <i data-toggle="tooltip" title="In Attesa" class="material-icons notranslate"
                        style="font-size: small; color: #FFE600;">circle</i>
                    </a>
                    <a *ngIf="!utente.presenza || utente.presenza === 0">
                      <i data-toggle="tooltip" title="Assente" class="material-icons notranslate"
                        style="font-size: small; color: #d32f2f;">circle</i>
                      <!-- <span class="p-badge p-badge-danger" data-toggle="tooltip"
                        data-placement="top" title="assente"
                        style="color: transparent;min-width: 0.9rem!important; height: 1.5rem!important;">
                        0</span> -->
                    </a>
                    {{utente.nome}} {{utente.cognome}}
                  </tr>
                  </td>

                  <td style="white-space: nowrap">
                    <div class="row" class="pull-right">
                      <a *ngIf="((ruoloUser !== 1)||(ruoloUser !== 4)) && utente.id === user.id"
                        (click)="alzaLaMano()"><i class="material-icons notranslate"
                          style="font-size: medium; margin-right: 3px;">pan_tool</i></a>
                      <a *ngIf="utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))&& utente.id !== user.id"
                        (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">record_voice_over</i></a>
                      <a *ngIf="!utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4)) && utente.id !== user.id"
                        (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                          class="material-icons notranslate">voice_over_off</i></a>
                          <a *ngIf="isRelatoreInsegnante && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))"
                            (click)="muteMicrophone(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                              class="material-icons notranslate">mic</i></a>
                      <a *ngIf="utente.presenza === 2 && ((ruoloUser === 1)||(ruoloUser === 4))" class="flex"
                        [ngClass]="{'disabled': isStillLoading}"><i class="material-icons notranslate"
                          (click)="accettaRifiutaUser(utente)">close</i>
                        <i class="material-icons notranslate" (click)="accettaRifiutaUser(utente,true)">check</i></a>
                      <!-- <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">record_voice_over</i></a>
                        <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">voice_over_off</i></a> -->
                      <!-- <a *ngIf="j%2===0"><i class="material-icons notranslate" style="color: rgb(190, 0, 0);">mic_off</i></a> -->
                    </div>
                  </td>

                  </tr>
                  <tr *ngIf="!listaUtenti">
                    <td colspan="4" class="text-center">
                      <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Vista principale Video+chat+listaUtentu END -->

<!-- LAVAGNA modalità popup START -->
<!--
<p-dialog [draggable]="true" [keepInViewport]="true" [resizable]="true"
[modal]="false" [blockScroll]="false" [closable]="true"
position="right" [(visible)]="isActiveLavagnaComponent">
  <div>
    <i class="material-icons notranslate btnChiusuraLavagna" (click)="chiudiLavagnaComponent()"
      style="cursor: pointer;position: absolute;right: 20px;">close</i>
  </div>
  <div style="overflow: auto;" *ngIf="isActiveLavagnaComponent">
    <app-lavagna></app-lavagna>
  </div>
</p-dialog>
-->
<!-- LAVAGNA modalità popup END -->

<!-- LAVAGNA sotto START -->
<!--
  <div *ngIf="isActiveLavagnaComponent" class="content">
    <div>
      <i class="material-icons notranslate btnChiusuraLavagna" (click)="chiudiLavagnaComponent()"
        style="cursor: pointer;position: absolute;right: 20px;">close</i>
    </div>
    <div style="overflow: auto;">
      <app-lavagna></app-lavagna>
    </div>
  </div>
-->
<!-- LAVAGNA sotto END -->

<!-- Visione Web fine -->
<!-- smartVision inizio-->
<!-- documentazione: openVidu prende le dimensioni di tutto il container per poter inserire altro oltre il componente di open vidu
bisogna creare un altro contenitore  attenzione allo scope delle variabili -->

<!-- inizio componente openVidu -->
<div *ngIf="smartVision && permessi" class="card-content" id="grandezzaDinamicaOV" #fullScreen>
  <div class="cento openVidu" style="overflow: auto;" style="z-index: 10;">
    <ng-container class="openVidu" *ngIf="session && modified === undefined && urlEnv !== ''">
            <opv-session (cambiaEvidenzaEmit)="cambiaEvidenza($event)" #ovSessionComponent [ovSettings]="ovSettings" [nicknameBigDefault]="rememberEvent"
              [url]="urlEnv" [idAula]="idAula" [sessionName]="mySessionId" [user]="myUserName" [tokens]="tokens"
              (sessionCreated)="handlerSessionCreatedEvent($event)" (leaveSession)="close($event)"
              (publisherCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)"
              (settingCamButtonClicked)="settingCamButtonClicked()">
            </opv-session>
          </ng-container>
          <ng-container class="openVidu" *ngIf="session && modified">
            <opv-session (cambiaEvidenzaEmit)="cambiaEvidenza($event)" #ovSessionComponent [ovSettings]="ovSettings" [nicknameBigDefault]="rememberEvent"
              [url]="urlEnv" [idAula]="idAula" [sessionName]="mySessionId" [user]="myUserName"
              [micSelected]="microphone" [camSelected]="camera" [tokens]="tokens"
              (sessionCreated)="handlerSessionCreatedEvent($event)" (leaveSession)="close($event)"
              (publisherCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)"
              (settingCamButtonClicked)="settingCamButtonClicked()">
            </opv-session>
    <!-- <ng-container class="openVidu" *ngIf="session && urlEnv !== ''">
      <opv-session #ovSessionComponent [ovSettings]="ovSettings" [url]="urlEnv" [idAula]="idAula"
        [sessionName]="mySessionId" [user]="myUserName" [tokens]="tokens"
        (sessionCreated)="handlerSessionCreatedEvent($event)" (leaveSession)="close($event)"
        (publisherCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
      </opv-session> -->
    </ng-container>
    <!-- controlli menu in basso -->

    <!-- dropup button lista utenti connessi -->
    <div class="btn-group dropup"
      style="z-index: 999999;position: absolute;left: 5px;margin-top: 0;margin-bottom: 0; bottom: 0;"
      [ngClass]="{'fullscreenCento': !openLavagna,'fullscreenMeta': openLavagna}">
      <button type="button" class="dropdown-toggle btnListaUtenti invisibile" data-boundary="viewport"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">#
      </button>
      <div class="dropdown-menu" style="height: 65vh;
      width: 37vh; overflow-x: auto;">
        <table class="table table-sm table-responsive table-borderless">
          <thead>
            <tr style="display: none;">
              <th style="width: 70%"></th>
              <th style="width: 30%"></th>
            </tr>
          </thead>
          <tbody>

            <tr *ngIf="utenteOriginario.ruolo === 4 || utenteOriginario.ruolo === 1">
              <td>
            <tr style="background-color: transparent!important;">
              <a>
                <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                  style="font-size: small; color:#689f38">circle</i>
                <!-- <span
                    class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                    style="color: transparent; min-width: 0.9rem!important; height: 1.5rem!important;">
                    1</span> -->
              </a>
              {{utenteOriginario.userValue.data.nome}} {{utenteOriginario.userValue.data.cognome}}
            </tr>
            </td>

            <td style="white-space: nowrap">
              <div class="row" class="pull-right">
                <a *ngIf="isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                  (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                    class="material-icons notranslate">record_voice_over</i></a>
                <a *ngIf="!isRelatoreInsegnante && ((ruoloUser === 1)||(ruoloUser === 4))"
                  (click)="modificaRelatore(user, true)" [ngClass]="{'disabled': isStillLoading}"><i
                    class="material-icons notranslate">voice_over_off</i></a>
                <!-- <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">record_voice_over</i></a>
                <a  (click)="modificaRelatore(utente)"><i class="material-icons notranslate">voice_over_off</i></a> -->
                <!-- <a *ngIf="j%2===0"><i class="material-icons notranslate" style="color: rgb(190, 0, 0);">mic_off</i></a> -->
              </div>
            </td>
            </tr>
            <tr *ngFor="let utente of listaUtenti ; let j= index">
              <td>
            <tr style="background-color: transparent!important;">
              <a *ngIf="utente.presenza === 1">
                <!-- <span class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                  style="color: transparent; min-width: 0.9rem!important; height: 0.9rem!important;">1</span> -->
                <i data-toggle="tooltip" title="Presente" class="material-icons notranslate"
                  style="font-size: small; color: #689f38;">circle</i>
              </a>
              <a *ngIf="utente.presenza === 2">
                <!-- <span class="p-badge p-badge-success" data-toggle="tooltip" data-placement="top" title="presente"
                  style="color: transparent; min-width: 0.9rem!important; height: 0.9rem!important;">1</span> -->
                <i data-toggle="tooltip" title="In Attesa" class="material-icons notranslate"
                  style="font-size: small; color: #FFE600;">circle</i>
              </a>
              <a *ngIf="!utente.presenza || utente.presenza === 0">
                <!-- <span class="p-badge p-badge-danger" data-toggle="tooltip" data-placement="top" title="assente"
                  style="color: transparent;min-width: 0.9rem!important; height: 0.9rem!important;">0</span> -->
                <i data-toggle="tooltip" title="Assente" class="material-icons notranslate"
                  style="font-size: small; color: #d32f2f;">circle</i>
              </a>
              {{utente.nome}} {{utente.cognome}}
            </tr>
            </td>

            <td style="white-space: nowrap">
              <div class="row" class="pull-right">
                <a *ngIf="((ruoloUser !== 1)||(ruoloUser !== 4)) && utente.id === user.id" (click)="alzaLaMano()"><i
                    class="material-icons notranslate" style="font-size: medium; margin-right: 3px;">pan_tool</i></a>
                <a *ngIf="utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))&& utente.id !== user.id"
                  (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                    class="material-icons notranslate">record_voice_over</i></a>
                <a *ngIf="!utente.relatore && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4)) && utente.id !== user.id"
                  (click)="modificaRelatore(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                    class="material-icons notranslate">voice_over_off</i></a>
                    <a *ngIf="isRelatoreInsegnante && utente.presenza === 1 && ((ruoloUser === 1)||(ruoloUser === 4))"
                      (click)="muteMicrophone(utente)" [ngClass]="{'disabled': isStillLoading}"><i
                        class="material-icons notranslate">mic</i></a>
                <a *ngIf="utente.presenza === 2 && ((ruoloUser === 1)||(ruoloUser === 4))" class="flex"
                  [ngClass]="{'disabled': isStillLoading}"><i class="material-icons notranslate"
                    (click)="accettaRifiutaUser(utente)">close</i>
                  <i class="material-icons notranslate" (click)="accettaRifiutaUser(utente,true)">check</i></a>
              </div>
            </td>

            </tr>
            <tr *ngIf="!listaUtenti">
              <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          </tbody>
        </table>



      </div>
    </div>
    <!-- dropup button lista utenti connessi fine -->

    <!-- div messaggio alazata di mano -->
    <div style="position: absolute; z-index: 999999; top: 0;">
      <div class="alert-personalizzato" role="alert" *ngFor="let messaggio of msgs">
        <span aria-hidden="true"><i class="material-icons notranslate"
            style="font-size: small; margin-right: 5px;">pan_tool</i></span>
        <strong>{{messaggio.summary}}</strong> {{messaggio.detail}}
        <button style="color: #224a23; margin-top: 4px; margin-left: 14px;" type="button" class="close"
          data-dismiss="alert" aria-label="Close" (click)=cancellaMessaggio(messaggio)>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <!-- div messaggio alazata di mano fine -->
    <!-- Pulsante Sondaggio START-->
    <div class="mobileActions">
      <div
        style="z-index: 1000000;position: absolute;margin-top: -24px;left: 0px;color: white;width: 40px;height: 40px;opacity: 0;"
        *ngIf="isActiveLavagnaComponent">
        <!-- icona graafica che se premuta apre il popup -->
        <i *ngIf="(ruoloUser==4||ruoloUser==1)" class="material-icons notranslate" (click)="showUserLavagnaActivated()"
          style="cursor: pointer;">group</i>
      </div>

      <!-- Pulsante Sondaggio END-->

      <!-- Pulsanti in basso schermata START -->
      <div *ngIf="envLavagna && (ruoloUser==4||ruoloUser==1)" class="btn-group dropup"
        style="z-index: 999999;position: absolute;margin-top: -24px;right: 55px;color: white;">

        <!-- Pulsante lavagna START -->
        <a type="button" style="z-index: 99999;color: white;" class="collapsed dropdown-toggle" *ngIf="lavagnaOpenable"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Lavagna
        </a>
        <!-- Pulsante lavagna END -->

        <!-- Dropdown screenshot per lavagna START -->
        <!-- caso utenti con stream attivi START -->
        <div *ngIf="verificaUsersAttivi()" class="dropdown-menu" style="margin-left: -162px;
                      display: grid;
                      max-height: 1000px;
                      overflow-y: scroll;
                      min-width: 215px;
                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                      text-align: center;">
          <a *ngFor="let utenti of userStreams,let i=index">
            <a *ngIf="utenti.videoActive" style="margin-top: 10px; color: 4388b9;"
              (click)="apriFinestraConLavagna(i)">{{utenti.email}}
            </a>
          </a>
        </div>
        <!-- caso utenti con stream attivi END -->

        <!-- caso nessun stream video attivo START -->
        <div *ngIf="!verificaUsersAttivi()" class="dropdown-menu" style="margin-left: -162px;
                      display: grid;
                      max-height: 1000px;
                      overflow-y: scroll;
                      min-width: 215px;
                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                      text-align: center;">
          <a *ngFor="let utenti of messaggioDefault">
            <a style="margin-top: 10px; color: 4388b9;">{{utenti.email}}</a>
          </a>
        </div>
        <!-- caso nessun stream video attivo END -->
      </div>
      <!-- Dropdown screenshot per lavagna END -->

      <!-- Pulsante Sondaggio START-->
      <div style="z-index: 999999;position: absolute;margin-top: -24px;right: 5px;color: white;">
        <!-- icona graafica che se premuta apre il popup -->
        <i data-toggle="tooltip" title="Verifica inattività" *ngIf="(ruoloUser==4||ruoloUser==1)"
          class="material-icons notranslate" (click)="apriPopUpSondaggio()" style="cursor: pointer;">poll</i>
      </div>

      <!-- Pulsante Sondaggio END-->

      <!--cam START-->
      <div *ngIf="envRecording" style="z-index: 999999;position: absolute;margin-top: -24px;right: 30px;color: white;">
        <!-- icona graafica che se premuta apre il popup -->
        <i data-toggle="tooltip" title="Registra" *ngIf="(ruoloUser==4||ruoloUser==1)"
          class="material-icons notranslate" [ngClass]="{'red': isRecording}" (click)="toggleRecording()"
          style="cursor: pointer;">videocam</i>
      </div>

    </div>

    <!-- bottone fullscreen tooltip mobile-->
    <!-- <div style="z-index: 999999;position: absolute;right: 5px;color: white; bottom: -4px;"
      [ngClass]="{'fullscreenCento': !openLavagna,'fullscreenMeta': openLavagna}">
      <i data-placement="top" data-toggle="tooltip" title="Schermo Intero" class="material-icons notranslate" *ngIf="!isFullScreen" (click)="openFullscreen()"
        style="cursor: pointer;">fullscreen</i>
      <i data-placement="top" data-toggle="tooltip" title="Esci da schermo intero" class="material-icons notranslate" *ngIf="isFullScreen" (click)="closeFullscreen()"
        style="cursor: pointer;">fullscreen_exit</i>
    </div> -->
    <!-- bottone fullscreen  fine -->
    <!-- bottone record -->
    <!-- <div style="z-index: 999999;position: absolute;right: 5px;color: white;"[ngClass]="{'fullscreenCento': !openLavagna,'fullscreenMeta': openLavagna}">
  <i data-placement="top" data-toggle="tooltip" title="iniza la registrazione" class="material-icons notranslate" *ngIf="!isFullScreen" (click)="openFullscreen()"
    style="cursor: pointer;">videocam</i>
  <i data-placement="top" data-toggle="tooltip" title="ferma la registrazione" class="material-icons notranslate" *ngIf="isFullScreen" (click)="closeFullscreen()"
    style="cursor: pointer;">videocam_off</i>
</div> -->
    <!-- bottone record  fine -->
    <!-- fine controlli menu in basso -->
    <!-- fine controlli menu in basso -->

  </div>
</div>
<!-- fine componente openVidu -->

<!--  lavagna virtuale smart sotto start-->
<div *ngIf="openLavagna && smartVision && closedLavagna" class="card-content col-xs-8" style="padding: 0">
  <div class="centraLavagna">
    <i class="material-icons notranslate btnChiusuraLavagna" (click)="chiudiLavagna()"
      style="cursor: pointer;">close</i>
  </div>
  <div style="overflow: auto;" id="grandezzaDinamica">
    <!-- [ruoloUser]="ruoloUser" (enableImagesEmitter)="refreshVideo()" -->
    <app-whiteboard-page [openedByOtherUser]="openedByOtherUser" [ruoloUser]="ruoloUser"></app-whiteboard-page>
  </div>
</div>
<!--  lavagna virtuale smart sotto fine-->

<!-- smartVision fine-->

<!-- START Sondaggio -->
<!-- Docente con p-dialog Start in p-dialog (onHide) viene richiamato quando il popup si chiude -->
<p-dialog [modal]="true" [(visible)]="popupInsegnante" [style]="{width: '50vw'}" [draggable]="false"
  [resizable]="false">
  <form [formGroup]="sondaggioForm" class="form-horizontal">
    <h4 style="text-align: left;">Inserisci messaggio</h4>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input type="text" class="form-control" placeholder="Questo è il messaggio" formControlName="messaggio"
            maxlength="45">
        </div>
      </div>
    </div>
    <div>
      <label>
        <input type="checkbox" formControlName="sondaggio" name="optionsCheckboxes"><span
          class="checkbox-material"><span class="check"></span></span> Sondaggio
      </label>
    </div>
  </form>
  <div style="text-align: right;">
    <button type="button" class="btn btn-secondary" (click)="inviaSondaggio(false)">Chiudi</button>
    <button type="button" class="btn btn-primary" (click)="inviaSondaggio(true)">Invia</button>
  </div>
</p-dialog>
<!-- Docente con p-dialog end -->

<!-- Studente con p-dialog start-->
<p-dialog [modal]="true" [(visible)]="popupStudente" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
  <div style="display: flex; justify-content: left;">
    <i *ngIf="isSondaggio" class="material-icons notranslate"
      style="cursor: pointer;padding:15px;font-size: 27px;">poll</i>
    <h5>{{messaggioDocente}}</h5>
  </div>
  <div *ngIf="isSondaggio" style="text-align: center;">
    <div style="display: flex; justify-content: right;">
      <button type="button" style="margin-right: 10px;" class="btn btn-primary" icon="pi pi-check" label="Sì"
        (click)="sondaggio('true')">Sì</button>
      <button type="button" icon="pi pi-times" class="btn btn-primary" label="No"
        (click)="sondaggio('false')">No</button>
    </div>
  </div>
  <div *ngIf="!isSondaggio" style="text-align: center;">
    <div style="display: flex; justify-content: right;">
      <button type="button" icon="pi pi-check" class="btn btn-primary" label="Chiudi"
        (click)="sondaggio('null')">Chiudi</button>
    </div>
  </div>
</p-dialog>


<p-dialog [draggable]="false" [resizable]="false" [(visible)]="recording" [closeOnEscape]="false" modal="true">
  <div class="mlr-15-rem">

    <h5 class="centered fs-large">Scegli il nome della registrazione<span class="colorRed"> *</span></h5>

    <div class="centered fs-large">
      <form [formGroup]="formVideo">
        <input class="form-control" [maxLength]="44" placeholder="Ex.. Trigonometria" formControlName="nome">
        <div *ngIf="erroreNomeVideo && f.nome.errors" class="invalid-feedback">
          <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
          <div *ngIf="f.nome.errors.pattern">Campo nome invalido</div>
        </div>
      </form>
    </div>
    <div style="text-align: right;">
      <button type="button" class="btn btn-secondary fs-small"
        (click)="toggleRecording();envRecording = true">Chiudi</button>
      <button type="button" class="btn btn-primary fs-small" (click)="recordingService()">Invia</button>
    </div>
  </div>
</p-dialog>

<p-dialog [draggable]="false" [resizable]="false" [(visible)]="stopRecording" [closeOnEscape]="false" modal="true">
  <div class="mlr-15-rem">
    <h5 class="centered fs-large">Sei sicuro di voler fermare la registrazione {{nomeFile.replace("_", " ")}}?</h5>

    <div style="text-align: right;">
      <button type="button" class="btn btn-secondary fs-small" (click)="toggleRecording();envRecording = true;">No</button>
      <button type="button" class="btn btn-primary fs-small" (click)="recordingService()">Si</button>
    </div>
  </div>
</p-dialog>

<p-dialog [draggable]="false" [resizable]="false" [(visible)]="registrazioneOver" [closeOnEscape]="false" modal="true">
  <div class="mlr-15-rem">
    <h5 class="centered fs-large">La registrazione del filmato si è interrotta automaticamente. Durata: 2 ore</h5>

    <div style="text-align: right;">
      <button type="button" class="btn btn-secondary fs-small" (click)="toggleRecordingOver()">ok</button>
    </div>
  </div>
</p-dialog>
<!-- Studente con p-dialog end -->
<!-- END Sondaggio -->

<p-dialog [draggable]="false" [resizable]="false" [(visible)]="showCameraSettings" [closeOnEscape]="true" modal="true"
  width="500" [dismissableMask]="true">
  <div class="mlr-15-rem">
    <div class="w-250 mb-20">

      <!-- <div *ngFor="let item of videoDevices"
      class="optionCamera flex"
      (click)="selectedCamera = item.label">
      <mat-icon>camera</mat-icon>
      <div class="one-line">
        <span>
          {{ item.label }}
        </span>
      </div>
    </div> -->
      <!-- <mat-form-field class="fieldChange">
        <mat-label>Cambia camera</mat-label>
        <mat-select [(ngModel)]="selectedCamera">
          <mat-option *ngFor="let item of videoDevices" [value]="item.label">{{item.label}}</mat-option>
        </mat-select>
      </mat-form-field> -->
										<div (click)="rotate()" class="classSelect flex" #cameraButton>
											<div class="one-line">
												<span>
													{{selectedCamera}}
												</span>
											</div>
											<span *ngIf="!selectedCamera">
												None
											</span>


											<div *ngIf="isOpen" class="absolute bg-Dropdown" style="top: 100px;">
												<div *ngFor="let camera of videoDevices" class="optionCamera flex"
													(click)="selectedCamera = camera.label">
															<mat-icon>camera</mat-icon>
													<div class="one-line">
														<span>
															{{ camera.label }}
														</span>
													</div>
												</div>
											</div>
											<div class="ml-auto">
												<mat-icon [@rotatedState]='state'>arrow_drop_down</mat-icon>
											</div>
										</div>
    </div>
    <div class="w-250">
      <!-- <mat-form-field class="fieldChange">
        <mat-label>Cambia Microfono</mat-label>
        <mat-select [(ngModel)]="selectedMicrophone">
          <mat-option [value]="item.label" *ngFor="let item of audioDevices">{{item.label}}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <div (click)="rotateMicrophone()" class="classSelect flex" #micButton>
        <div class="one-line">
          <span>
            {{selectedMicrophone}}
          </span>
        </div>
        <span *ngIf="!selectedMicrophone">
          None
        </span>

        <div *ngIf="isOpenMicrophone" class="absolute bg-Dropdown" style="top: 50px;">

          <div *ngFor="let microphone of audioDevices"
            class="optionCamera flex"
            (click)="selectedMicrophone = microphone.label">

            <mat-icon>mic</mat-icon>
            <div class="one-line">
              <span>
                {{ microphone.label }}
              </span>
            </div>
          </div>
        </div>

        <div class="ml-auto" >
          <mat-icon [@rotatedStateMicrophone]='stateMicrophone'>arrow_drop_down
          </mat-icon>
        </div>
      </div> -->
      <div (click)="rotateMicrophone()" class="classSelect flex" #micButton>
        <div class="one-line">
          <span>
            {{selectedMicrophone}}
          </span>
        </div>
        <span *ngIf="!selectedMicrophone">
          None
        </span>

        <div *ngIf="isOpenMicrophone" class="absolute bg-Dropdown" style="top: 50px;">

          <div *ngFor="let microphone of audioDevices"
            class="optionCamera flex"
            (click)="selectedMicrophone = microphone.label">

            <mat-icon>mic</mat-icon>
            <div class="one-line">
              <span>
                {{ microphone.label }}
              </span>
            </div>
          </div>
        </div>

        <div class="ml-auto" >
          <mat-icon [@rotatedStateMicrophone]='stateMicrophone'>arrow_drop_down
          </mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="!checkNumUtenti(listaUtenti)">
      <div class="fs-small">
        Attenzione, Procedendo con questa operazione
      </div>
      <div class="fs-small">
        la Registrazione verrà fermata
      </div>
    </div>
    <div style="text-align: right;">
      <button type="button" class="btn btn-primary fs-small" (click)="publishNewUser()">Conferma</button>
    </div>
  </div>
</p-dialog>
