import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "../GENERAL/env.service";
import { AccountService } from "../USER/account.service";

@Injectable({
  providedIn: 'root'
})
export class CallCenterService {
  orarioProssimaChiamata: Date;
  idProssimaChiamata: number;
  idFarmacia: number;
  idCampagna: number;
  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService, private accountService: AccountService) {
    this.baseUrl = this.env.apiUrl;
  }

  getAllFile(dataInizio, dataFine, farmacia?) {
    let url = this.baseUrl + '/lista_calendario?idOperatore=' + this.accountService.userValue.data.id + '&dataInizio=' + dataInizio + '&dataFine=' + dataFine;
    if (farmacia) url = url + '&idFarmacia=' + farmacia
    return this.http.get<any>(url);
  }

  salvaCalendario(calendario) {
    return this.http.post<any>(this.baseUrl + '/salva_calendario', calendario);
  }

  getFarmacie() {
    return this.http.get<any>(this.baseUrl + '/lista_farmacie_leggera');
  }

  recuperoCampagna(data) {
    return this.http.get<any>(this.baseUrl + '/campagna_attuale?data=' + data);
  }

  getProssimaChiamata() {
    return this.http.get<any>(this.baseUrl + '/recupero_prossima_chiamata?idOperatore=' + this.accountService.userValue.data.id);
  }

  aggiungiInformazioni(idChiamata, body) {
    return this.http.post<any>(this.baseUrl + '/aggiungi_informazioni?idChiamata=' + idChiamata, body);
  }

  modificaChiamata(idChiamata, body) {
    return this.http.post<any>(this.baseUrl + '/modifica_chiamata?idChiamata=' + idChiamata, body);
  }

  recuperaInformazioni(idChiamata) {
    return this.http.get<any>(this.baseUrl + '/recupera_informazioni?idChiamata=' + idChiamata);
  }

  eliminaChiamata(idChiamata) {
    return this.http.post<any>(this.baseUrl + '/elimina_chiamata?idChiamata=' + idChiamata, null);
  }

  recuperaDatiReport(dataInizio,dataFine,resCallHistory, id) {
    if (resCallHistory) return this.http.post<any>(this.baseUrl + '/read_report_campaign?idUser=' + id + '&dataInizio='+dataInizio+'&dataFine='+dataFine,resCallHistory);
    return this.http.post<any>(this.baseUrl + '/read_report_campaign?idUser=' + id + '&dataInizio='+dataInizio+'&dataFine='+dataFine, null)
  }
}
