import { Component, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';


@Component({
selector: 'app-account-layout',
templateUrl: 'layout.component.html' ,
styleUrls: ['./layout.component.scss']})

export class LayoutComponent implements DoCheck {
    deviceInfo = null;
    isMobile = false;
    isTablet = false;
    isDesktopDevice = false;
    retrievedImage;
    ifep;
    acque;
    ipem;
    nabacom;
    MOBILEVIEW;
    activeRoute;
    afi
    novamaint
    alfaCallCenter

    constructor(
        private router: Router,
        private accountService: AccountService,
        private shareService : ShareService,
        private env : EnvService,
        private deviceService: DeviceDetectorService
        )
        {
            this.acque = this.env.acque
            this.ifep = this.env.ifep
            this.nabacom = this.env.nabacom;
            this.ipem = this.env.ipem;
            this.afi=this.env.alfaintes
            this.alfaCallCenter=this.env.ALFACALLCENTER;
            this.MOBILEVIEW = true;
            this.novamaint =this.env.novamaint;
            this.shareService.passaImmagine$.subscribe(res=>{
              this.retrievedImage = res;
          })
        this.deviceFunction();
        this.shareService.changeIsLoggedIn(false);
        // redirect to home if already logged in
        if (this.accountService.userValue) {
            this.shareService.changeIsLoggedIn(true);
            this.router.navigate(['/']);
         }


    }
    ngDoCheck(): void {
        if(this.router.url.startsWith('/account/recuperapassword')){
        this.activeRoute = 'recuperapassword';
    }
        else {
            this.activeRoute = this.router.url;
        }
    }

    deviceFunction() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
        if(this.isTablet){
            this.isDesktopDevice = true;
          }
        if(this.MOBILEVIEW){
            this.isDesktopDevice = true;
        }
      }

}
