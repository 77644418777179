<!-- <div class="card_container" *ngIf="permessi"></div> -->

<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  [style]="{ width: responsiveWidth }"
  [acceptLabel]="'Si'"
  [baseZIndex]="5"
>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Sì"
      (click)="cd.accept()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cd.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>
<div
  class="content"
  style="
    margin-top: -10px;
    margin-right: -12px !important;
    background-color: rgb(226, 226, 226);
    height: 92vh;
  "
  #fullScreen
>
  <div
    class="container-fluid"
    style="padding: 0 !important; overflow: hidden; height:100%"
    *ngIf="permessi && access"
  >
    <div class="card shadow" style="margin-left: 10px; width: 98%; height: 100%">
      <div class="card-header">
        <div class="row" style="margin-left: 10px">
          <div
            class="col-xs-8"
            style="background-color: #2488b9; max-height: 22px"
            [ngClass]="{ videoFull: isFullScreen }"
          >
            <div>
              <div class="col-xs-7">
                <ng-container *ngIf="!stato">
                  <a
                    style="
                      outline: none !important;
                      border: none !important;
                      box-shadow: none !important;
                    "
                  >
                    <i
                      class="material-icons notranslate"
                      style="font-size: x-large; color: white"
                      >people_alt</i
                    ></a
                  >
                  <p-multiSelect
                    *ngIf="usersInt > 0"
                    [filter]="false"
                    [options]="whitelistUsers"
                    [(ngModel)]="selectedUsers"
                    optionLabel="email"
                    [showHeader]="false"
                    [showToggleAll]="false"
                    (onChange)="onSelectChange()"
                  >
                  </p-multiSelect>
                  <span
                    class="badge badge-pill badge-danger"
                    style="background-color: red; color: black"
                    >{{ usersInt }}</span
                  >

                  <span
                    class="badge badge-secondary"
                    style="font-size: medium; margin-left: 10px"
                    ><a (click)="cambiaStato()" style="color: white !important"
                      >Occupato</a
                    ></span
                  >
                  <!-- <div style="margin-top: 10px; max-width: 300px;z-index: 999999; position: absolute;" *ngIf="whitelistView">



              <p-table [value]="whitelistUsers" selectionMode="single" [(selection)]="selectedUsers" dataKey="email"
                [scrollable]="true" scrollHeight="15rem">

                <ng-template pTemplate="header">
                  <tr>

                    <th style="width: 9rem">Utenti in attesa</th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-user>
                  <tr>
                    <td style="width: 3rem">
                      <p-tableCheckbox [value]="user" (click)="onSelectChange()"></p-tableCheckbox>
                    </td>

                    <td>{{user.email}}</td>

                  </tr>

                </ng-template>

              </p-table>


            </div> -->
                </ng-container>

                <span
                  class="badge badge-success"
                  style="font-size: medium; background-color: rgb(0, 204, 0)"
                  *ngIf="stato"
                  ><a (click)="cambiaStato()" style="color: white"
                    >Libero</a
                  ></span
                >
              </div>

              <!--AR-->
              <ng-container *ngIf="AR">
                <!-- pulsante per la Scelta della forma e del colore -->
                <div
                  class="pull-right"
                  style="
                    text-align: end;
                    justify-content: end;
                    width: 5%;
                    padding-left: 1px;
                    padding-right: 1px;
                    width: 32px;
                  "
                >
                  <!-- edit -->
                  <button
                    *ngIf="statoAR && azioneScelta === 'aggiungi forma'"
                    rel="tooltip"
                    title="Scegli Forma e colore"
                    (click)="apreTendina()"
                    style="
                      margin-top: -8px;
                      background-color: transparent;
                      box-shadow: none;
                    "
                    type="button"
                    [ngClass]="
                      statoAR
                        ? 'btn btn-default btn-round btn-fab btn-fab-mini btn-sm'
                        : 'btn btn-success btn-round btn-fab btn-fab-mini btn-sm'
                    "
                  >
                    <i class="material-icons notranslate">color_lens</i>
                  </button>
                  <!-- prova -->
                  <!-- tendina per scelta colore e forma -->
                  <div
                    class="card"
                    style="
                      max-width: 200px;
                      min-width: 200px;
                      z-index: 999999;
                      position: absolute;
                      margin-top: -3px;
                    "
                    *ngIf="formeView"
                  >
                    <p-table
                      [value]="forme"
                      selectionMode="single"
                      [(selection)]="selectedForma"
                      dataKey="nome"
                      [scrollable]="true"
                      scrollHeight="15rem"
                    >
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="width: 9rem">Seleziona</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-forma>
                        <tr [pSelectableRow]="forma">
                          <td (click)="onClickShape(forma.nome)">
                            {{ forma.nome }}
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>

                    <!-- pulsanti per il colore -->
                    <p
                      style="
                        margin-top: 2%;
                        margin-bottom: 2%;
                        text-align: center;
                      "
                    >
                      <button
                        (click)="onClickColor('blu')"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-check"
                        style="
                          color: transparent;
                          font-size: xx-small;
                          margin-right: 3px;
                        "
                        class="p-button-rounded p-button-primary p-button-sm"
                      ></button>
                      <button
                        (click)="onClickColor('giallo')"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-check"
                        style="
                          color: transparent;
                          font-size: xx-small;
                          margin-right: 3px;
                        "
                        class="p-button-rounded p-button-warning p-button-sm"
                      ></button>
                      <button
                        (click)="onClickColor('rosso')"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-check"
                        style="
                          color: transparent;
                          font-size: xx-small;
                          margin-right: 3px;
                        "
                        class="p-button-rounded p-button-danger p-button-sm"
                      ></button>
                    </p>

                    <!-- pulsanti per il colore -->
                  </div>

                  <!-- tendina per scelta colore e forma END -->
                  <!-- fine prova -->
                </div>
                <!-- pulsante per la Scelta dela forma e del colore -->

                <!-- Scelta persona e azione -->
                <div
                  class="pull-right"
                  *ngIf="
                    !vrConnesso || (vrConnesso && userStreams.length !== 2)
                  "
                >
                  <button
                    pRipple
                    style="padding: 0%"
                    type="button"
                    [disabled]="userStreams.length === 1"
                    pButton
                    #btn
                    class="p-button-text btn-round"
                    [ngStyle]="{ color: statoColor() }"
                    style="padding: 0%"
                    label="AR"
                    rel="tooltip"
                    title="Cliccando su AR potrai selezionare l'utente su cui far comparire la forma"
                    (click)="eliminaAscolti()"
                    (click)="menu.toggle($event)"
                  ></button>
                  <div #myDiv style="position: absolute">
                    <p-slideMenu
                      #menu
                      autoZIndex="true"
                      [appendTo]="myDiv"
                      [model]="items"
                      [popup]="true"
                      backLabel="Indietro"
                    >
                    </p-slideMenu>
                  </div>
                </div>

                <!-- Scelta persona e  azione END-->
              </ng-container>
              <!--FINE AR-->

              <!-- Zoom -->
              <div class="pull-right">
                <button
                  rel="tooltip"
                  title="Zoom"
                  style="
                    background-color: transparent;
                    box-shadow: none;
                    margin: auto;
                    border: none;
                  "
                  class="btn-round btn-info dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i style="font-size: 18px" class="material-icons notranslate"
                    >zoom_in</i
                  >
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right dropdownZoom"
                  role="menu"
                >
                  <div *ngFor="let utente of userStreams; let i = index" >
                    <div *ngIf="checkUsers(utente)" class="zoomList">
                      <div class="centered">
                        {{ utente.email
                        }}<mat-slider
                          (change)="sendZoom(utente.email, utente.zoom)"
                          thumbLabel
                          tickInterval="auto"
                          step="1"
                          min="0"
                          max="2"
                          [(ngModel)]="utente.zoom"
                          aria-label="units"
                          ><input matSliderThumb [(ngModel)]="utente.zoom"
                        /></mat-slider>
                      </div>
                    </div>
                  </div>
                  <div class="centered" *ngIf="(userStreams.length -1) == 0">
                  Nessun utente disponibile
                </div>
                </div>
              </div>

              <!-- Zoom END-->

              <!-- Screenshot -->

              <div class="pull-right">
                <button
                  rel="tooltip"
                  title="Screenshot"
                  style="
                    background-color: transparent;
                    box-shadow: none;
                    margin: auto;
                    border: none;
                  "
                  class="btn-round btn-info dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i style="font-size: 18px" class="material-icons notranslate"
                    >camera_enhance</i
                  >
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  id="dropdown"
                  role="menu"
                >
                <ng-container *ngFor="let utente of userStreams; let i = index">
                  <li *ngIf="checkUsers(utente)" >
                    <a (click)="screenShot(i)">{{ utente.email }}</a>
                  </li>
                </ng-container>
                <div class="centered" *ngIf="(userStreams.length -1) == 0">
                  Nessun utente disponibile
                </div>
                  <!-- <div class="dropdown-divider">a</div> -->
                  <hr *ngIf="(userStreams.length -1) != 0" />
<!--
                  <div class="centered" *ngIf="(userStreams.length -1) != 0">
                    <label (click)="keepOpenDropdown($event)">
                      <input
                        type="checkbox"
                        [(ngModel)]="saveScreenshotOnCloud"
                      /><span class="checkbox-material"></span>
                      Salva screenshot sul cloud
                    </label>
                  </div> -->
                </ul>
              </div>

              <!-- Screenshot END -->

              <!-- Carica File -->

              <div class="pull-right">
                <div style="color: white; margin-top: -1px">
                  <i
                    class="material-icons notranslate"
                    rel="tooltip"
                    title="Carica file"
                    (click)="openUploadDialog()"
                    style="cursor: pointer"
                    >add_photo_alternate</i
                  >
                </div>
              </div>

              <!-- Carica File END -->

              <!-- Lampadina -->
              <!--
            <div class="pull-right" *ngIf="!statoLuce">
              <button rel="tooltip"  title="Accendi"
              style="background-color: transparent; box-shadow: none; margin:auto; border: none;" class="btn-round btn-info dropdown-toggle" data-toggle="dropdown"
                aria-expanded="false">
                <i style="font-size:18px;" class="material-icons notranslate">brightness_low</i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right" role="menu">
                <div class="row" style="margin-left: 5px;">
                  <div class="col-xs-10">
                  <input type="text" [(ngModel)]="url" type="text" placeholder="Inserisci l'url"
                  class="form-control"></div>
                  <div class="col-xs-2"> <i class="material-icons notranslate" style="cursor: pointer; margin-top: 5px; color: dodgerblue;" (click)="accendiLuce()">forward</i></div>

                </div>

              </ul>
            </div> -->

              <div class="pull-right" *ngIf="statoLuce">
                <button
                  rel="tooltip"
                  title="Accendi"
                  (click)="spegniLuce()"
                  style="
                    background-color: transparent;
                    box-shadow: none;
                    margin: auto;
                    border: none;
                  "
                  class="btn-round btn-info"
                  aria-expanded="false"
                >
                  <i style="font-size: 18px" class="material-icons notranslate"
                    >dark_mode</i
                  >
                </button>
              </div>
              <!-- Lampadina -->
              <!-- fullscreen -->
              <div class="pull-right">
                <div style="color: white; margin-top: -1px">
                  <i
                    class="material-icons notranslate"
                    *ngIf="!isFullScreen"
                    (click)="openFullscreen()"
                    rel="tooltip"
                    title="Schermo intero"
                    style="cursor: pointer"
                    >fullscreen</i
                  >
                  <i
                    class="material-icons notranslate"
                    *ngIf="isFullScreen"
                    (click)="closeFullscreen()"
                    rel="tooltip"
                    title="Esci dalla modalità schermo intero"
                    style="cursor: pointer"
                    >fullscreen_exit</i
                  >
                </div>
              </div>
              <!-- fullscreen -->
              <!-- file upload-->
              <div class="pull-right">
                <div style="color: white; margin-top: -1px">
                  <i
                    class="material-icons notranslate"
                    rel="tooltip"
                    title="Carica file sul visore"
                    *ngIf="vrConnesso"
                    (click)="openUploadDialog(true)"
                    style="cursor: pointer"
                    >upload</i
                  >
                </div>
              </div>
              <!-- file upload-->
            </div>
          </div>
          <div class="col-xs-4"></div>
        </div>

        <div
          class="row"
          style="margin-left: 10px"
          [ngClass]="{ fullScreen: isFullScreen }"
        >
          <div
            class="col-xs-8"
            style="z-index: 3"
            [ngClass]="{ videoFull: isFullScreen }"
          >
            <ng-container class="openVidu">
              <div style="height: 100%;">
                <iframe src="../../../../assets/dist/meetingV2.html" frameborder="0" style="height: 100%;width: 100%;" *ngIf="isChime"></iframe>
                <app-open-vidu *ngIf="!isChime"
                  (localUser)="updateUserStreams($event)"
                  (userIn)="cambiaStatoAuto($event)"
                  (access)="editAccess()"
                ></app-open-vidu>
              </div>
            </ng-container>
          </div>

          <div class="col-xs-4 map-margin" [ngClass]="{ mappaFull: isFullScreen }">
            <div class="card-body scroll" >
              <app-geo></app-geo>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <button (click)="showDialog = true;hideModal = false;">Cliccami</button> -->
<div
  class="minimized c-pointer"
  (click)="hideModal = false; showDialog = true"
  *ngIf="hideModal"
>
  Vedi Immagine
</div>
<p-dialog
  *ngIf="showDialog"
  [(visible)]="showDialog"
  [draggable]="true"
  [resizable]="true"
  [ngClass]="{ 'd-none': hideModal, dialogFullscreen: showDialog }"
>
  <div class="flex">
    <div class="ml-auto fs-18">
      <span class="c-pointer" (click)="hideModal = true">- &nbsp;</span>
      <span class="c-pointer" (click)="showDialog = false">x</span>
    </div>
  </div>
  <app-edit-image [file]="fileLink"></app-edit-image>
</p-dialog>

<!--

<div id="container" style="display: block; width: 100%; " *ngIf="permessi">
  <div class="row"style="margin-right: 10px; margin-left: 10px; z-index:0;">

    <div class="col-xs-9" style="margin-top: 11px;">
      <div>

        <ng-container class="openVidu" *ngIf="access">
          <div style="z-index: 99999999; position: absolute;">
            <ng-container *ngIf="!stato">



              <a (click)="visualizzaWhitelist()"><i class="material-icons notranslate"
                  style="font-size:x-large; color: white; margin-top: 7px">people_alt</i></a>
              <span class="badge badge-pill badge-danger"
                style="background-color: red; color: black;">{{usersInt}}</span>



              <span class="badge badge-secondary" style="font-size:medium; margin-left: 10px; margin-top: 7px;"><a
                  (click)="cambiaStato()" style="color:white!important">Occupato</a></span>
              <div style="margin-top: 10px; max-width: 300px;" *ngIf="whitelistView">


                <p-table [value]="whitelistUsers" selectionMode="single" [(selection)]="selectedUsers" dataKey="email"
                  [scrollable]="true" scrollHeight="15rem">

                  <ng-template pTemplate="header">
                    <tr>

                      <th style="width: 9rem">Utenti in attesa</th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-user>
                    <tr>
                      <td style="width: 3rem">
                        <p-tableCheckbox [value]="user" (click)="onSelectChange()"></p-tableCheckbox>
                      </td>

                      <td>{{user.email}}</td>

                    </tr>

                  </ng-template>

                </p-table>


              </div>


            </ng-container>
            <span class="badge badge-success"
              style="font-size:medium; margin-left: 10px; margin-top: 7px; background-color: rgb(0, 204, 0);"
              *ngIf="stato"><a (click)="cambiaStato()" style="color: white;">Libero</a></span>
          </div>
          <div style="max-height:50vh; height: 50vh; min-height: 50vh;">
            <app-open-vidu (userIn)=cambiaStatoAuto($event)></app-open-vidu>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="col-xs-3">
    <app-geo></app-geo>
  </div>

</div> -->

<!--Dialog file ricevuto-->
<!-- <p-dialog [draggable]="false" [resizable]="false" [(visible)]="immagineRicevuta" [closeOnEscape]="false" modal="true">
  <h5 class="centered" >L'utente ha condiviso un file</h5>

  <div class="flex">
    <button class="btn btn-secondary" (click)="immagineRicevuta = false">Ignora</button>
    <button class="btn btn-primary ml-auto" (click)="downloadFile()">Scarica</button>
  </div>
</p-dialog> -->
<!--Dialog file ricevuto-->

<!--Dialog caricamento file-->
<p-dialog
  [draggable]="false"
  [resizable]="false"
  [(visible)]="uploadDialog"
  (onHide)="uploadFromCloud = false;showCR = true;"
  [closeOnEscape]="false"
  modal="true"
  class="fileDialog"
  [ngClass]="{ fileDialog: !uploadFromCloud, fileDialogDMS: uploadFromCloud }"
>
  <app-lista-file
    *ngIf="uploadFromCloud"
    [fromCR]="true"
    (emitterFileInCR)="
      fileLink = $event; showDialog = true; uploadFromCloud = false
    "
  ></app-lista-file>
  <div class="centered" >
    <label *ngIf="showCR" class="label-on-top dialogLabel">Seleziona il file</label>
    <div *ngIf="showCR" class="flex">
      <div *ngIf="fileInputShow" class="buttonContainer col-sm-4 no-pl">
        <div class="flex">
          <input
            style="display: none"
            type="file"
            (input)="onFileChange($event)"
            #fileInput
            [accept]="!uploadFileVisore ? 'image/png, image/jpg' : ''"
          />
          <button
            (click)="fileInput.click()"
            class="btn btn-primary"
            type="button"
          >
            Scegli File
          </button>
          <div class="img-space"></div>
        </div>

        <div class="flex">
          <!-- <input
            style="display: none"
            type="file"
            (input)="onFileChange($event)"
            #fileInput
            [accept]="!uploadFileVisore ? 'image/pn+g, image/jpg' : ''"
          /> -->
          <button
            (click)="uploadFromCloud = true; showCR = false"
            class="btn btn-primary"
            type="button"
          >
            Scegli File dal cloud
          </button>
          <div class="img-space"></div>
        </div>
      </div>

      <p *ngIf="nomeFile" class="fileLabel col-sm-6">{{ nomeFile }}</p>
      <p *ngIf="!nomeFile" class="fileLabel col-sm-6">
        Nessun file selezionato
      </p>
      <div *ngIf="caricamentoUpload">
        <div class="text-center">
          <p-progressSpinner
            [style]="{ width: '40px', height: '40px' }"
            strokeWidth="5"
            styleClass="custom-spinner2"
            animationDuration=".5s"
          ></p-progressSpinner>
        </div>
      </div>
    </div>

    <div class="flex">
      <button
        class="btn btn-secondary"
        (click)="uploadFromCloud ? goBack() : uploadDialog = false; nomeFile = null"

      >
        {{uploadFromCloud ? 'Indietro' : 'Annulla'}}
      </button>
      <ng-container *ngIf="!uploadFileVisore && showCR">
        <button
          class="btn btn-primary ml-auto"
          (click)="openInEditor()"
          [disabled]="!nomeFile"
        >
          Carica
        </button>
      </ng-container>

      <ng-container *ngIf="uploadFileVisore && showCR">
        <button
          class="btn btn-primary ml-auto"
          (click)="uploadFile()"
          [disabled]="!nomeFile"
        >
          Carica
        </button>
      </ng-container>

    </div>
  </div>
</p-dialog>
<!--Dialog caricamento file-->

<!-- <button (click)="uploadDialog=true">Cliccami - Upload file</button> -->
<!-- <button (click)="vrConnesso=true">Cliccami - Simula connessione VR</button> -->
<!-- <button (click)="showDialog = true; hideModal = false">
  Cliccami - Lavagna
</button>
<button (click)="uploadDialog = true">Cliccami - Upload file</button>
<button (click)="vrConnesso = true">Cliccami - Simula connessione VR</button> -->
