import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "../GENERAL/env.service";

@Injectable({
  providedIn: 'root'
})
export class CampagneService {

  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }

  getAllFile(page, size, campo=null, ricerca=null) {
    if(campo && ricerca)
      return this.http.get<any>(this.baseUrl + '/lista_campagne?page=' + page + '&size=' + size + '&campo='+campo+'&ricerca='+ricerca);

    return this.http.get<any>(this.baseUrl + '/lista_campagne?page=' + page + '&size=' + size)
  }


  getRicercaDomande(page, size, campo) {
      return this.http.get<any>(this.baseUrl + '/ricerca_domande?page=' + page + '&size=' + size + '&ricerca='+campo);
  }

  deleteFile(filesId) {
    return this.http.post<any>(this.baseUrl + '/elimina_campagne', [filesId]);
  }

  inserisciFarmacia(farmacia) {
    return this.http.post<any>(this.baseUrl + '/crea_campagna', farmacia);
  }

  modificaFarmacia(farmacia) {
    return this.http.post<any>(this.baseUrl + '/modifica_campagna?id=' + farmacia.id, farmacia);
  }

  recuperaCampagna(id) {
    return this.http.get<any>(this.baseUrl + '/recupero_campagna?idCampagna=' + id);
  }
}
