
<div *ngIf="dispositivi" class="card-content">
<div *ngIf="!caricato" >
    <div class="text-center" >
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

        <p-confirmDialog #cd icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'"
            [baseZIndex]="5">
            <p-footer>

                <button [disabled]="loading" *ngIf="cancellaDispositivo" type="button" pButton icon="pi pi-check"
                    label="Sì" (click)="accettaCancella()">
                    <span *ngIf="loading">
                        <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                            styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                    </span>
                </button>
                <button *ngIf="!cancellaDispositivo" type="button" pButton icon="pi pi-check" label="Sì"
                    (click)="cd.accept()"></button>
                <button [disabled]="loading && cancellaDispositivo" type="button" pButton icon="pi pi-times" label="No"
                    (click)="cd.reject()"></button>

            </p-footer>
        </p-confirmDialog>
        <div *ngIf="caricato" class="text-right">

            <!-- <button (click)="vaiCreazioneConfigurazione()" class="btn btn-primary"
                style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Crea Configurazione Globale</button> -->
            <button (click)="vaiCreazione()" class="btn btn-primary"
                style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Crea Dispositivo</button>

        </div>


        <div class="material-datatables">
            <div class="row">
                <div class="col-sm-3 col-xs-3">
                    <div class="dataTables_length" id="datatables_length" [ngClass]="{'disabled':isSelectingValue}">
                        <div style="display: flex;">
                            <label class="form-group">Limita Risultati
                                <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                                    aria-controls="datatables" class="form-control input-sm optionNoGray">
                                    <option class="optionNoGray" value="10">10</option>
                                    <option class="optionNoGray" value="20">20</option>
                                    <option class="optionNoGray" value="50">50</option>

                                </select>
                                <div *ngIf="isSelectingValue" style="display: flex;align-items: end;">
                                    <div class="text-center" style="position: absolute;right: 0px;">
                                        <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                            styleClass="custom-spinner2" animationDuration=".5s">
                                        </p-progressSpinner>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9 col-xs-9">
                    <div id="datatables_filter" class="dataTables_filter"
                        style="display: flex; justify-content: flex-end;">
                        <form [formGroup]="searchform">
                            <label class="form-group pull-right">Esegui una ricerca
                                <div style="display: flex;">
                                    <input (input)="search()" type="text" formControlName="search"
                                        class="form-control" placeholder="Cerca..." maxlength="30"
                                        style="width: 40vw;">
                                    <div *ngIf="isSearchingOn" style="display: flex;align-items: end;">
                                        <div class="text-center" style="position: absolute;right: 0px;">
                                            <p-progressSpinner [style]="{width: '20px', height: '20px'}"
                                                strokeWidth="5" styleClass="custom-spinner2"
                                                animationDuration=".5s"></p-progressSpinner>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </form>

                        <!-- <div *ngIf="searchInUser()"></div>   -->
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf=" listadispositivi.length  < 1 && caricato">Nessun dispositivo disponibile</p>

        <table *ngIf="listadispositivi.length > 0" id="datatables" class="table table-no-bordered table-hover"
            cellspacing="0" width="100%" style="width:100%">

            <thead>
                <tr>
                    <!-- <th>#</th> -->
                    <th width="60%"  (click)="ordinaArray('deviceName')" style="cursor: pointer;">Dispositivo
                        <i class="material-icons notranslate" style="font-size: 15px;">unfold_more</i></th>

                    <th width="30%" (click)="ordinaArray('deviceType')" style="cursor: pointer;">
                        Tipo <i class="material-icons notranslate" style="font-size: 15px;">unfold_more</i></th>

                    <th class="text-right">Azioni</th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <th width="60%">Dispositivo</th>
                    <th width="30%">Tipo</th>
                    <th class="disabled-sorting text-right" width="10%">Azioni</th>
                </tr>
            </tfoot>

            <tbody>
                <ng-container *ngFor="let dispositivo of listadispositivi;let i =index;" id="myGroup">
                    <tr>
                        <td width="60%" style="font-weight: bold;">{{dispositivo.deviceName}}</td>
                        <td width="30%">{{dispositivo.deviceType.tipoDevice}}</td>
                        <td style="text-align: right;" width="10%">
                            <a (click)="vaiModifica(dispositivo.id)" rel="tooltip" title="Modifica Dispositivo"
                                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px;"><i
                                    class="material-icons notranslate pointer" style="font-size: 20px;">edit</i></a>
                            <a (click)="vaiInfo(dispositivo.id)" rel="tooltip" title="Info Dispositivo"
                                style="padding: 2px 2px 2px 2px;" class="btn btn-warning btn-simple"><i
                                    class="material-icons notranslate pointer" style="font-size: 20px;">info</i></a>
                            <a (click)="elimina(dispositivo.id)" rel="tooltip" title="Elimina Dispositivo"
                                class="btn btn-simple btn-danger btn-icon remove" style="padding: 2px 2px 2px 2px;"><i
                                    class="material-icons notranslate pointer" style="font-size: 20px;">close</i></a>
                        </td>


                    </tr>

                </ng-container>
            </tbody>
        </table>




</div>
<div [ngClass]="{'displayNone':!dispositivi}" class="row">
    <div class="col-sm-5">
        <div class="dataTables_info" id="datatables_info" role="status"
            aria-live="polite" style="margin-top: 5%;">{{items}} di {{maxItems}}
                Device</div>
        </div>
        <div class="col-sm-7 text-right giustificaDestra">
            <app-paginator  *ngIf="refreshView" [pageSize]="numeroMaxPagine"
                [(isClickable)]="isClickable" [startingPage]="pagina"
                (currentPageEmitter)="cambiaPagina($event)"></app-paginator>
        </div>
    </div>
<div *ngIf="crea">
    <app-dispositivo (tornaLista)="vediLista($event)" [dispositivo]="dispositivo" [crea]="true"></app-dispositivo>
</div>
<div *ngIf="info">
    <app-dispositivo (tornaLista)="vediLista($event)" [dispositivo]="dispositivo" [info]="true"></app-dispositivo>
</div>
<div *ngIf="edit">
    <app-dispositivo (tornaLista)="vediLista($event)" [dispositivo]="dispositivo" [edit]="true"></app-dispositivo>
</div>
<div *ngIf="configurazione">
    <app-crea-configurazione (tornaLista)="vediLista($event)"></app-crea-configurazione>
</div>
