<div class="content" *ngIf="crea">

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="padd">
                    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="form-horizontal">

                        <div class="card-header card-header-text">
                            <h4 class="card-title text-center" *ngIf="addMode">Aggiungi un file</h4>
                            <h4 class="card-title text-center" *ngIf="editMode">Modifica un file</h4>

                        </div>

                        <!-- <div class="card-content"> -->
                            <div class="row" *ngIf="addMode">
                                <label class="col-sm-2 label-on-left">Seleziona il file<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div>
                                        <input style="display: none" multiple type="file" (change)="onFileChanged($event)"
                                            #fileInput>
                                        <button (click)="fileInput.click()" class="btn btn-secondary"
                                            type="button">Scegli
                                            File</button>
                                        <div class="img-space">
                                        </div>
                                    </div>

                                    <p *ngIf="nomeFile" style="margin-top: 8px">
                                      <span *ngFor="let file of nomeFile; let i = index">{{file.name}} <span *ngIf="i < nomeFile.length - 1">, </span></span>
                                    </p>
                                    <p *ngIf="!nomeFile && !submitted" style="margin-top: 8px">Nessun file selezionato
                                    </p>
                                    <p *ngIf="!nomeFile && submitted"
                                        style="margin-top: 8px; color: rgb(189, 0, 0); font-size: xx-small;">Campo File
                                        obbligatorio</p>
                                </div>
                            </div>
                            <div *ngIf="caricaCorso">
                                <div class="text-center">
                                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                                        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                                </div>
                            </div>

                                <div style="padding: 30px;" *ngIf="corso">
                                    <div class="btn-group" role="group" aria-label="Basic example" aria-pressed="true"><button type="button"
        class="btn btn-primary"  (click)="addlistAula()">Seleziona un'aula</button> </div>

                                   <div *ngIf="addAula" style="max-height: 300px;overflow: scroll; overflow-x: hidden;">
                                       <div *ngIf="caricataLista">
                                           <div class="text-center" >
                                               <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                                               styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                                           </div>
                                       </div>
                                       <div *ngIf="!caricataLista">
                                       <div style="margin-bottom: 2%;">
                                           <p  *ngIf="listaClassi.length < 1 ">Nessun aula da associare</p>
                                             <table *ngIf="listaClassi.length > 0" id="datatables" class="table table-striped table-no-bordered table-hover table-fixed" cellspacing="0" width="100%" style="width:100%">
                                                   <thead style="position:sticky ;
                                                   z-index:1 ;
                                                    background-color:white  ;">
                                                       <tr style="position:sticky ;
                                                       z-index:1 ;
                                                        background-color:white;">
                                                           <th style="position:sticky ;
                                                           z-index:1 ;
                                                            background-color:white ;" width="10%">#</th>
                                                           <th style="position:sticky;
                                                           z-index:1 ;
                                                            background-color:white ;" width="30%">Nome Aula</th>
                                                           <th style="position:sticky;
                                                           z-index:1 ;
                                                            background-color:white;"width="30%">Descrizione</th>
                                                           <th style="position:sticky;
                                                           z-index:1 ;
                                                            background-color:white ;"width="30%">Stato</th>

                                                   </thead>
                                                           <tbody >

                                                               <tr *ngFor="let aula of listaClassi;let i =index;">
                                                                   <td width="10%"><input type="checkbox" placeholder="Aule" style="margin-top: 30px;" [checked]="aula.selezionata"   (click)="aggiungiAula(aula.id)" ></td>
                                                                   <td width="30%">{{aula.nomeAula}}</td>
                                                                   <td width="30%">{{aula.descrizioneAula}}</td>
                                                                   <td *ngIf="!aula.start" width="30%">Attiva</td>
                                                                   <td *ngIf="aula.start" width="30%">Attiva dal {{aula.start | date: 'dd/MM/yyyy'}} al {{aula.end | date: 'dd/MM/yyyy'}}</td>
                                                               </tr>
                                                       </tbody>
                                               </table>
                                           </div>
                                       </div>

                                   </div>
                               </div>




                            <br>
                             <hr>
                            <div class="flexRight" >



                                    <button type="button" (click)="clear()" class="btn btn-secondary"
                                        aria-pressed="true">Indietro</button>


                                    <button *ngIf="secondoStep" [disabled]="loading" type="sumbit" class="btn btn-primary"
                                        (click)="convalidaForm()">Salva
                                        <span *ngIf="loading">
                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                        </span>
                                    </button>
                                </div>


                            <br>

                    </form>
                </div>
            </div>
        </div>
</div></div>
