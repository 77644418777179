import { Component, DoCheck, EventEmitter, OnInit, Output } from '@angular/core';
import { Id_gruppo } from 'src/app/MODELS/USER/id_gruppo';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { UsersService } from 'src/app/SERVICES/index';
import {ConfirmationService} from 'primeng/api';
import {Message} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { AccountService } from 'src/app/SERVICES';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestione-gruppi',
  templateUrl: './gestione-gruppi.component.html',
  styleUrls: ['./gestione-gruppi.component.scss'],
  providers: [ConfirmationService]
})
export class GestioneGruppiComponent implements OnInit,DoCheck {

gestione=true;
aggiungi=false;
modifica=false;
crea=false;
gestioneGruppi : boolean  = false;
  gruppo: Id_gruppo={};
  newGruppo: boolean;
  @Output() modificaGruppo= new EventEmitter<string>();
  @Output() nuovoGruppo= new EventEmitter<boolean>();
  listaGruppi: any;
  allListaGruppi: any;
  group: Id_gruppo;
  msgs: Message[] = [];
  constructor( private userService: UsersService,
    private alertService: AlertService,
    private confirmationService: ConfirmationService, private primengConfig: PrimeNGConfig,
    private accountService : AccountService,
    private routing : Router) { }

    ngDoCheck(): void {
      if(this.accountService.listaEnv && (this.accountService.ruolo !== 1)){
        this.routing.navigate(['./'])
      }  }
  ngOnInit(): void {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
    this.primengConfig.ripple = true;
    this.userService.getListaGruppi().subscribe((res) => {
      this.listaGruppi = res.data;
      this.allListaGruppi = this.listaGruppi;
      this.gestioneGruppi = true

    }, (error) => {

    });
  }
  cancella(id){

      const gruppo = this.listaGruppi.find(x => x.id === id);
      gruppo.isDeleting = true;
      this.confirmationService.confirm({
        message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',
        icon: 'pi pi-exclamation-triangle',

        accept: () => {
          this.userService.deleteGruppo(id).subscribe((res) => {
            this.listaGruppi = this.listaGruppi.filter(x => x.id !== id);
            this.alertService.success(res.status.description)
            this.msgs = [{severity:'info', summary:'Gruppo rimosso!'}];
          });
          this.confirmationService.close();
        },
        reject: () => {
          this.confirmationService.close();
        }
    })


  }

  edit(id, nome, descrizione){
    this.gruppo.id=id;
    this.gruppo.nomeGruppo=nome;
    this.gruppo.descrizione=descrizione;

    this.gestione=false;
    this.modifica=true;

  }
  creaGruppo(crea){
    this.gestione=false;
    this.aggiungi=true;

  }

  tornaGestione(vai){
    this.listaGruppi=[]
    this.userService.getListaGruppi().subscribe((res) => {
      this.listaGruppi = res.data;

        this.gestione=!this.gestione;
        this.aggiungi=!this.aggiungi;


    }, (error) => {

    });

  }
  torna(vai){
    this.userService.getListaGruppi().subscribe((res) => {
      this.listaGruppi = res.data;
      this.gestione=!this.gestione;
      this.modifica=!this.modifica;


    }, (error) => {

    });
  }
  responsiveWidth() {    }

}
