<div class="center listAllarmi" [ngClass]="{'heightSmartbed': iotService.tipoDispositivo === 'smartbed'}">
  <strong>ALLARMI:{{jsonDati.length}}</strong>
  <div class="mb10" *ngFor="let dispositivo of jsonDati">
    <mat-expansion-panel class="redf55a4e">
      <mat-expansion-panel-header>
        <mat-panel-title class="colorWhite fw500">
          {{dispositivo.dispositivo}}
        </mat-panel-title>
        <mat-panel-description class="colorWhite fw500">
          {{dispositivo.data | date:'HH:mm dd/MM/yyyy'}}
          <div class="flex alignCenter">
            <mat-icon>{{dispositivo.icona}}</mat-icon>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <button type="button" aria-hidden="true" class="close float-left">
          <i class="material-icons notranslate">close</i>
        </button>
        <button type="button" aria-hidden="true" class="close float-right">
          <i class="material-icons notranslate">check</i>
        </button>
      </div>

      <button *ngIf="dispositivo.nota" type="button" data-toggle="modal"
        data-target="#exampleModal" aria-hidden="true" class="close fs-20 bottom-10 absolute right-24">
        <i class="material-icons notranslate">edit</i>
      </button>
      <button *ngIf="!dispositivo.nota" type="button" data-toggle="modal"
        data-target="#exampleModal" aria-hidden="true" class="close close fs-20 bottom-10 absolute right-24">
        <i class="material-icons notranslate">post_add</i>
      </button>
      <span class="colorWhite fw500">
        {{dispositivo.allarme}}
      </span>

    </mat-expansion-panel>
  </div>
</div>
