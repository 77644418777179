import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';
import { AccountService } from '../USER/account.service';

@Injectable({
  providedIn: 'root'
})
export class IotService {
  baseUrl: string;
  codec = new HttpUrlEncodingCodec();

  /**
   * mappa che contiene come chiave il tipo di dispositivo, come valore id.
   *
   * Esempio: Key:"wearable" Value:1
   *
   * valore intero serve per ricavare i grafici da mostrare, corrisponde all'id
   *
   * chiave al tipo, utile per filtrare
   */
  tipoDispositivi:Map<string, number> = new Map<string, number>()
  /**
   * La mappa contiene tutte le telemetrie fornite da un determinato dispositivo
   */
  telemetryToShow:Set<string> = new Set<string>()

  constructor(private http: HttpClient, private accountService: AccountService, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }


  /**
   * Json di ritorno
   *
   * "data": [
        {

            "id": 2,
            "active": true,
            "deviceId": "000000111112222333",
            "deviceName": "Smartwatch 1",
            "utente": {
                "id": 143,
                "ruolo": {
                    "id": 3,
                    "descrizioneRuolo": null,
                    "nomeRuolo": "User"
                },
                "nome": "Angela",
                "telefono": "098765432",
                "cognome": "Amato",
                "email": "user1@protom.com",
                "gruppos": [],
                "deletedAt": null,
                "attivo": 1,
                "passwordSet": 0,
            },
            "deviceType": {
                "id": 1,
                "tipoDevice": "H003",
                "descrizione": "Ketron smartwatch per applicazioni di smart health",
                "tipologia": "wearable",
                "deviceTelemetryMetadata": [
                    {
                        "id": 1,
                        "telemetry": "HeartRate",
                        "telemetryName": "Frequenza cardiaca",
                        "telemetryDescription": "Frequenza cardiaca misurata al paziente",
                        "telemetryUnit": "BPM"
                    },...]
            },
            "createdAt": "2021-04-09T13:30:51.992+00:00",
            "allarme": [
                {
                    "id": 24,
                    "nome": "temp",
                    "massimo": 38,
                    "device": {
                        "id": 2,
                        "deviceName": "Smartwatch 1",
                        "utente": {
                            "id": 143,
                            ...
                        }
                    },
                    "telemetria": "BodyTemperature",
                    "minimo": 33
                },
   * @returns json
   */

  getListaDispositivi(page?,size?,ricerca?,campo?,cardinalita?): Observable<any> {
    if((page || page == 0) && size && ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/device?page='+page+'&size='+size+'&ricerca='+ricerca)
    }else if((page || page == 0) && size && !ricerca && campo && cardinalita){
      return this.http.get<any>(this.baseUrl + '/device?page='+page+'&size='+size+'&campo='+campo+'&ord='+cardinalita)
    }else if((page || page == 0) && size && !ricerca && campo && !cardinalita){
      return this.http.get<any>(this.baseUrl + '/device?page='+page+'&size='+size+'&campo='+campo)
    }else if((page || page == 0) && size && !ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/device?page='+page+'&size='+size)
    }else{
      return this.http.get<any>(this.baseUrl + "/device")
    }
  }
  getListaDispositiviPeculiar(ricercaTipo, page?,size?,ricerca?,campo?,cardinalita?): Observable<any> {
    if((page || page == 0) && size && ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/device?device_type_id='+ricercaTipo + '&page='+page+'&size='+size+'&ricerca='+ricerca)
    }else if((page || page == 0) && size && !ricerca && campo && cardinalita){
      return this.http.get<any>(this.baseUrl + '/device?device_type_id='+ricercaTipo + '&page='+page+'&size='+size+'&campo='+campo+'&ord='+cardinalita)
    }else if((page || page == 0) && size && !ricerca && campo && !cardinalita){
      return this.http.get<any>(this.baseUrl + '/device?device_type_id='+ricercaTipo + '&page='+page+'&size='+size+'&campo='+campo)
    }else if((page || page == 0) && size && !ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/device?device_type_id='+ricercaTipo + '&page='+page+'&size='+size)
    }else{
      return this.http.get<any>(this.baseUrl + "/device?device_type_id="+ricercaTipo)
    }
  }

  getListTelemetrieSmartBed(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/device/get_smartbed_telemetry")
  }

  getListaTelemetrie(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/meta")
  }

  /**
   * fornisce i valori delle telemetrie in questo formato:
   *
   * {
   *
            "id": null,
            "timestamp": "2021-06-29T12:43:09.000+00:00",
            "telemetryName": "BloodOxygen",
            "note": null,
            "device": {
                "id": 81,
                "allarme": [
                    {
                        "id": 82,
                        "nome": null,
                        "massimo": 40,
                        "telemetria": "BodyTemperature",
                        "minimo": 25
                    }
                ]
            },
            "telemetryData": 96.0
        },
        @param id del dispositivo
   */
  getTelemetry(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/device/' + id + "/telemetry2")
  }

  getSocket(): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/device/wakeUp", null)
  }

  storicoAllarmi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/storico_allarmi')
  }

  storicoAllarmiDispositivi(start,end): Observable<any> {
  return this.http.get<any>(this.baseUrl + `/storico_allarmi_dispositivi?start=${start}&end=${end}`)
}

  getListaAllarmi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_allarmi')
  }

  getUltimiAllarmi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/ultimi_allarmi')
  }

  creaAllarme(allarme): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/crea_allarme', allarme)
  }

  modificaAllarmeLocale(allarme): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_allarme_dispositivo', allarme)
  }

  modificaAllarmeGlobale(allarme): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_allarme_generale', allarme)
  }

  dispositiviAttivi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/device/telemetry/status')
  }

  deleteAllarmeLocale(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/cancella_allarme_dispositivo?&id=' + id, null)
  }

  deleteAllarmeGlobale(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/cancella_allarme_generale?&id=' + id, null)
  }

  getTelemetryForDate(inizio, fine, id): Observable<any> {
    return this.http.get(this.baseUrl + '/device/' + id + '/telemetry?start=' + inizio + '&end=' + fine);
  }

  getPowerForDate(inizio, fine, id): Observable<any> {
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/Power?start=${inizio}&end=${fine}`);
  }

  getCountTelemetriaForDate(inizio, fine): Observable<any> {
    return this.http.get(`${this.baseUrl}/device/telemetry/count?start=${inizio}&end=${fine}`);
  }

  getMediaPressioneForDate(inizio, fine, id): Observable<any> {
    return this.http.get(this.baseUrl + '/device/' + id + '/grafico_pressione?start=' + inizio + '&end=' + fine);
  }

  getTipoDevice(): Observable<any> {
    return this.http.get(`${this.baseUrl}/deviceType`);
  }

  creaDevice(creaDispositivo): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/device", creaDispositivo)
  }

  modificaDevice(modDispositivo, id): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/device/" + id, modDispositivo)
  }

  cancellaDevice(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/device/" + id + "/delete/", null)
  }

  creaConfGlobale(creaConfGlobale): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/device/configuration", creaConfGlobale)
  }

  getListaSoglieOdierne(idDevice:number):Observable<any>{
    const today = new Date();
    today.setHours(0,0,0,0);
    const dataOggiParsata = Date.parse(today.toString());
    const epochOggiInizioGiornata = dataOggiParsata / 1000;
    const epochFineInizioGiornata = epochOggiInizioGiornata + 86340;
    return this.http.get<any>(this.baseUrl+"/device/"+idDevice+"/telemetry2?start="+epochOggiInizioGiornata+"&end="+epochFineInizioGiornata)
  }

  trovaConfigurazione(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/device/" + id + "/configuration")
  }
  /**
   * Servizio per capire in base al dispositivo quale telemetria fornisce
   *
   *  "data": [
        {

            "id": 1,
            "telemetry": "HeartRate",
            "telemetryName": "Frequenza cardiaca",
            "telemetryDescription": "Frequenza cardiaca misurata al paziente",
            "telemetryUnit": "BPM"
        },
        {

            "id": 2,
            "telemetry": "StepCounter",
            "telemetryName": "Numero passi",
            "telemetryDescription": "Numero passi paziente",
            "telemetryUnit": null
        },
        ...
      ]
    }
   * @param id del tipo di dispositivo
   * @returns un json con tutte le telemetrie da mostrare
   */
  getTelemetryMetadata(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/device/" + id + "/telemetryMetadata")
  }


  // FAMILY END POINT

  getAllFamily(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/family/" + id)
  }
  //Get lista familiari (/family/{id})

  getAllUserFamily(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/family/" + id + "/lista_associabili")
  }
  //Get tutti i familiari associabili (/family/{id}/lista_associabili)
  PostFamily(id, object): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/family/" + id, object)
  }
  //Set utente in family (/family/{id})

  cancellaFamily(id, object): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/family/" + id + "/delete/", object)
  }
  //Delete family member (/family/{id}/delete)


  //---------Geolocalizzazione-------


  getDeviceLocationForId(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/device/" + id + "/location")
  }

  getAllDeviceLocation(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/device/location")
  }
  graficiTelemetry(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/device/" + id + "/telemetry/latest")
  }

  getTelemetryHeart(id,inizio,fine): Observable<any> {
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/HeartRate?start=${inizio}&end=${fine}`);
  }

  getTelemetryBreathing(id,inizio,fine): Observable<any> {
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/BreathingRate?start=${inizio}&end=${fine}`);
  }

  getTelemetryTemperature(id,inizio,fine): Observable<any> {
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/BodyTemperature?start=${inizio}&end=${fine}`);
  }

  /**
   * Lista dati telemetrici di quel dispositivo in un intervallo di tempo
   * @param id dispositivo
   * @param inizio in epoch ex: 1633705387
   * @param fine in epoch
   * @returns json con lo storico 2 dati importanti: timestamp e telemetryData della respirazione
   */
  getTelemetryRespirazione(id:number,inizio,fine):Observable<any>{
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/BreathingRate?start=${inizio}&end=${fine}`);
  }
  getTelemetryBloodOxygen(id:number,inizio,fine):Observable<any>{
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/BloodOxygen?start=${inizio}&end=${fine}`);
  }

  getTelemetryPassi(id,inizio,fine): Observable<any> {
    return this.http.get(`${this.baseUrl}/device/` + id + `/telemetry/StepCounter?start=${inizio}&end=${fine}`);
  }


  aggiornaStato(): Observable<any>{
    return this.http.get<any>(this.baseUrl + "/device/sincronizza_stato")
  }

  aggiornaBatteria(): Observable<any>{
    return this.http.get<any>(this.baseUrl + "/device/sincronizza_batteria")
  }

  /**
   * Metodo per aggiornare i movimentsStats dello smartbed
   */
  aggiornaMovimenti(): Observable<any>{
    return this.http.get<any>(this.baseUrl + "/device/sincronizza_movimenti")
  }

  getFamily(): Observable<any>{
    return this.http.get<any>(this.baseUrl + "/family/" + this.accountService.userValue.data.id + "/get_family_devices")
  }

  addDescrizioneAllarm(id,nota): Observable<any>{
    return this.http.post<any>(this.baseUrl +"/"+id+"/aggiungi_nota",nota)
  }

  getAllarmiNonRisolti(): Observable<any>{
    return this.http.get<any>(this.baseUrl + '/non_risolti');
  }

  getConfermaAllarme(id, stato = 1) : Observable<any>{
    return this.http.post<any>(this.baseUrl + "/" + id + "/conferma_allarme", {
      "feedback" : stato
      })
  }
  getConfermaAllarmeAll() : Observable<any>{
    return this.http.post<any>(this.baseUrl  + "/conferma_tutti_allarmi", null)
  }
  addDescrizioneAllarmGrafici(idMisurazione, note): Observable<any> {
    const request = {
      note
    }
    return this.http.post<any>(this.baseUrl + `/device/telemetry/${idMisurazione}/note`, request);
  }

  deleteDescrizioneAllarmiGrafici(idMisurazione): Observable<any> {
    return this.http.post<any>(this.baseUrl + `/device/telemetry/${idMisurazione}/note/delete`, null);
  }

  /**
   * Pulisce le mappe inerenti al tipo di dispositivo e alle mappe da mostrare
   */
  clearMapTelemetry(){
    this.telemetryToShow.clear()
    this.tipoDispositivi.clear()
  }


  recuperoDatiSmartwatch(id){
    return this.http.get<any>(this.baseUrl + "/user/" + id + "/smartwatch");
  }

  recuperoOTP(id){
    return this.http.get<any>(this.baseUrl + "/smartwatch/" + id + "/generateOtp");
  }

  inserimentoDatiSmartwatch(smartwatch){
    return this.http.post<any>(this.baseUrl + "/smartwatch", smartwatch);
  }
  eliminazioneDatiSmartwatch(id){
    return this.http.post<any>(this.baseUrl + "/smartwatch/" + id + '/delete', null);
  }

  connessioneShellyButton1(){
    return this.http.get<any>("http://192.168.33.1:80/settings");
  }


  impostaUrlAzioneShellyButton1(deviceId, action, actionSymbol){
    let tmp = this.env.apiUrl.split("http://")[1];
    let server = tmp.split(":")[0];
    let port = Number(tmp.split(":")[1]);
    return this.http.get<any>("http://192.168.33.1:80/settings/actions/?index=0&enabled=true&name=" + action + "_url&urls[]=http%3A%2F%2F" + server + "%3A" + port + "%2Fshellybutton1%2Fsos_button%3FdeviceId%3D" + deviceId + "%26pressType%3D" + actionSymbol)
  }

  test(text)
  {
    
  }

  configuraWifiShellyButton1(apName, apPass)
  {
    return this.http.get<any>("http://192.168.33.1/settings/sta?enabled=1&ssid=" + this.codec.encodeValue(apName) + "&key=" + this.codec.encodeValue(apPass) + "&ipv4_method=dhcp");
  }

  confermaconfigurazioneWifiShellyButton1(deviceId, configured)
  {
    return this.http.post<any>(this.baseUrl + "/shellybutton1/"  + deviceId + "/set_configuration_status?configured=" + configured, null);
  }

  inviaComandoJ2032(deviceId, command)
  {
    return this.http.post<any>(this.baseUrl + "/j2032/"  + deviceId + "/send?cmd=" + command, null);
  }

  inviaAllarmeAdOperatoreAssociato(deviceId)
  {
    return this.http.post<any>(this.baseUrl + "/device/"  + deviceId + "/user/notify", null);
  }

}
