<div class="card-header">
  <!-- INIZIO WIZARD -->

  <div class="card-title">
    <button (click)="goBack()" id="back-button" class="btn btn-round btn-just-icon" style="
        margin-top: 3px;
        color: rgb(54, 54, 54);
        box-shadow: none !important;
      ">
      <i class="material-icons notranslate">arrow_back</i>
    </button>
    <!-- step1 -->
    <h4 *ngIf="crea && step1" style="display: inline">
      Crea un nuovo Dispositivo
    </h4>
    <h4 *ngIf="edit && step1" style="display: inline">Modifica Dispositivo</h4>
    <h4 *ngIf="info && step1" style="display: inline">Dispositivo</h4>
    <!-- step2 -->
    <h4 *ngIf="crea && step2" style="display: inline">Scegli il Modello</h4>
    <h4 *ngIf="edit && step2" style="display: inline">Modifica il Modello</h4>
    <h4 *ngIf="info && step2" style="display: inline">Modello Dispositivo</h4>
    <!-- step3 -->
    <h4 *ngIf="crea && step3" style="display: inline">
      Scegli la Configurazione
    </h4>
    <h4 *ngIf="edit && step3" style="display: inline">
      Modifica la Configurazione
    </h4>
    <h4 *ngIf="info && step3" style="display: inline">
      Configurazione Dispositivo
    </h4>
    <!-- step4 -->
    <h4 *ngIf="crea && step4 && (valoreRadio !== 6 && valoreRadio !== 7)" style="display: inline">
      Scegli le Soglie
    </h4>
    <h4 *ngIf="edit && step4 && valoreRadio !== 6 && valoreRadio !== 7" style="display: inline">
      Modifica le Soglie
    </h4>
    <h4 *ngIf="info && step4 && valoreRadio !== 6 && valoreRadio !== 7" style="display: inline">
      Configurazione le Soglie
    </h4>
  </div>

  <div id="formwizard_validation" class="form-wizard form-wizard-horizontal">
    <form class="form floating-label form-validation" role="form" novalidate="novalidate"></form>
    <div class="form-wizard-nav">
      <div class="progress" id="respoBar" style="width: 100%">
        <div class="progress-bar progress-bar-primary" [ngClass]="{
            primo: step1,
            secondo: step2,
            terzo: step3,
            quarto: step4
          }"></div>
      </div>
      <ul class="nav nav-justified nav-pills bugNr1013" id="respo">
        <li>
          <a (click)="vaiCreadisp()" data-toggle="tab" class="responsiveMobileMenu"><span class="step"
              [ngClass]="{ attiva: !step1, disattiva: step1 }">1</span>
            <span class="title" [ngClass]="{ attivaTitolo: !step1, disattivaTitolo: step1 }">Crea Device</span></a>
        </li>
        <li>
          <a (click)="vaiTipo()" data-toggle="tab" class="responsiveMobileMenu"><span class="step"
              [ngClass]="{ attiva: !step2, disattiva: step2 }">2</span>
            <span class="title" [ngClass]="{ attivaTitolo: !step2, disattivaTitolo: step2 }">Scegli Tipo</span></a>
        </li>
        <li>
          <a (click)="vaiConf()" data-toggle="tab" class="responsiveMobileMenu"><span class="step"
              [ngClass]="{ attiva: !step3, disattiva: step3 }">3</span>
            <span class="title" [ngClass]="{ attivaTitolo: !step3, disattivaTitolo: step3 }">Configurazione</span></a>
        </li>
        <li *ngIf="valoreRadio !== 6 && valoreRadio !== 7 && valoreRadio !==9 && valoreRadio !==10">
          <a (click)="vaiSoglie()" data-toggle="tab" class="responsiveMobileMenu"><span class="step"
              [ngClass]="{ attiva: !step4, disattiva: step4 }">4</span>
            <span class="title" [ngClass]="{ attivaTitolo: !step4, disattivaTitolo: step4 }">Soglie</span></a>
        </li>
      </ul>
    </div>
    <!--end .form-wizard-nav -->
    <div class="tab-content clearfix p-30">
      <div *ngIf="step1">
        <form class="form-horizontal" [formGroup]="formDispositivo">
          <div class="row flex">
            <label class="col-sm-2 label-on-left responsiveLabel">Nome<span class="colorRed"> *</span></label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <label class="control-label"></label>
                <input type="text" placeholder="Nome" formControlName="nome" class="form-control" maxlength="30"
                  [readonly]="info" />
                <div *ngIf="submittedStep1 && f.nome.errors" class="invalid-feedback">
                  <div *ngIf="f.nome.errors.required">
                    Campo nome obbligatorio
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row flex">
            <label class="col-sm-2 label-on-left responsiveLabel">Id Device<span class="colorRed"> *</span></label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <label class="control-label"></label>
                <input type="text" placeholder="Identificativo/IMEI" formControlName="idDevice" class="form-control"
                  maxlength="30" [readonly]="info" />
                <div *ngIf="submittedStep1 && f.idDevice.errors" class="invalid-feedback">
                  <div *ngIf="f.idDevice.errors.required">
                    Campo id obbligatorio
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="info && valoreRadio !== 6 && valoreRadio !== 7" class="row flex">
            <label class="col-sm-2 label-on-left responsiveLabel">Paziente associato</label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <label class="control-label"></label>
                <input type="text" formControlName="paziente" class="form-control" maxlength="30" [readonly]="info" />
              </div>
            </div>
          </div>
        </form>

        <div *ngIf="!info && valoreRadio !== 6 && valoreRadio !== 7">
          <button class="btn btn-primary right" (click)="addPaziente()">
            Associa a Paziente
          </button>

          <div class="row">
            <div class="col-xs-12" *ngIf="pazienti">
              <div *ngIf="!caricaPazienti">
                <div class="text-center">
                  <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="5"
                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                </div>
              </div>
              <div *ngIf="caricaPazienti">
                <p *ngIf="!allUser || allUser.length < 1">
                  Nessun Paziente disponibile
                </p>

                <div *ngIf="allUser.length > 0" id="datatables_filter" class="dataTables_filter">
                  <form [formGroup]="searchPazientiForm">
                    <input type="text" formControlName="search" placeholder="Cerca utente" maxlength="28"
                      style="margin-bottom: 2%" class="form-control" />
                  </form>

                  <div *ngIf="searchInPazienti()"></div>
                </div>

                <table *ngIf="allUser.length > 0" id="datatables" class="table table-no-bordered table-hover"
                  cellspacing="0" width="100%" style="width: 100%">
                  <thead>
                    <tr>
                      <th *ngIf="!info" width="10%">#</th>
                      <th width="40%">Nome</th>
                      <th width="40%">cognome</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="!info">
                    <ng-container *ngFor="let paziente of listaPazienti; let i = index" id="myGroup">
                      <tr>
                        <td *ngIf="!info" width="10%">
                          <input type="radio" (click)="getValueRadioPaziente(paziente.id)" class="form-check-input"
                            name="idDevice" [checked]="paziente.id === valoreRadioPaziente" />
                        </td>
                        <td width="40%" style="font-weight: bold">
                          {{ paziente.nome }}
                        </td>
                        <td width="40%">{{ paziente.cognome }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>

                <!-- <div *ngIf="submittedStep2 && !valoreRadio" class="invalid-feedback">
                                    Campo obbligatorio
                                </div> -->
              </div>

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- qui scegli i tipi  -->
      <div *ngIf="step2">
        <div *ngIf="!caricato">
          <div class="text-center">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="5" styleClass="custom-spinner2"
              animationDuration=".5s"></p-progressSpinner>
          </div>
        </div>
        <p *ngIf="caricato && listaTipi.length < 1">
          Nessun modello disponibile
        </p>

        <table *ngIf="listaTipi.length > 0" id="datatables" class="table table-no-bordered table-hover" cellspacing="0"
          width="100%" style="width: 100%">
          <thead>
            <tr>
              <th *ngIf="!info" width="10%">#</th>
              <th width="40%">Nome Tipo</th>
              <th width="40%">Descrizione</th>
            </tr>
          </thead>
          <tbody *ngIf="!info">
            <ng-container *ngFor="let tipo of listaTipi; let i = index" id="myGroup">
              <tr>
                <td *ngIf="!info" width="10%">
                  <input type="radio" (click)="getValueRadio(tipo.id)" class="form-check-input" name="idDevice"
                    [checked]="tipo.id === valoreRadio" />
                </td>
                <td width="40%" style="font-weight: bold">
                  {{ tipo.tipoDevice }}
                </td>
                <td width="40%">{{ tipo.descrizione }}</td>
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="info">
            <ng-container *ngFor="let tipo of listaSelected; let i = index" id="myGroup">
              <tr>
                <td *ngIf="!info" width="10%">
                  <input type="radio" (click)="getValueRadio(tipo.id)" class="form-check-input" name="idDevice"
                    [checked]="tipo.id === valoreRadio" />
                </td>
                <td width="40%" style="font-weight: bold">
                  {{ tipo.tipoDevice }}
                </td>
                <td width="40%">{{ tipo.descrizione }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="submittedStep2 && !valoreRadio" class="invalid-feedback">
          Campo obbligatorio
        </div>
      </div>
      <!--end #step2 -->
      <div *ngIf="step3">
        <div *ngIf="!modSmartbed">
          <div *ngIf="!caricato">
            <div class="text-center">
              <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="5"
                styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
            </div>
          </div>

          <div *ngIf="
              caricato &&
              valoreRadio != 5 &&
              valoreRadio != 6 &&
              valoreRadio != 7 &&
              valoreRadio !=9 &&
              valoreRadio !=8 &&
              valoreRadio !=10
            " class="row">
            <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 20px">Intervallo di misurazione
              battito cardiaco e pressione</label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <label class="control-label"></label>

                <div *ngIf="!info">
                  <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="1" min="1" max="5"
                    [(ngModel)]="valoreBpm" aria-label="units"></mat-slider>
                </div>
                <div *ngIf="info">
                  <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="1" min="1" max="5"
                    style="pointer-events: none" [(ngModel)]="valoreBpm" aria-label="units">
                  </mat-slider>
                </div>
                <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed
                  un'altra</small>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="valoreRadio == 8">
            <h4>Configurazione Wi-Fi</h4>
            <form *ngIf="caricato" class="form-horizontal text-center" [formGroup]="configurazioneJ2032form">
              <div class="row flex">
                <label class="col-sm-2 label-on-left">Nome
                </label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="text" maxlength="30" placeholder="Nome" formControlName="wifiName"
                      class="form-control" [readonly]="info" />
                  </div>
                </div>
              </div>

              <div class="row flex">
                <label class="col-sm-2 label-on-left">Password <a (click)="showPassword()" rel="tooltip" title="Modifica Dispositivo"
                  class="btn btn-simple" style="padding: 2px 2px 2px 2px;"><i
                      class="material-icons notranslate pointer" style="font-size: 20px;">{{visibility}}</i></a>
                </label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input [type]="passwordType" maxlength="30" placeholder="Password" formControlName="wifiPassword"
                      class="form-control" [readonly]="info" />
                  </div>
                </div>
              </div>

              </form>
          </div>

          <div *ngIf="
          caricato &&
          valoreRadio == 8
        " class="row">
        <h4>Configurazione J2032</h4>
        <!-- <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 20px">
          Battito cardiaco</label>
        <div class="col-sm-10">
          <div class="form-group label-floating is-empty">
            <label class="control-label"></label>

            <div *ngIf="!info">
              <mat-slider thumbLabel [displayWith]="formatLabelBpmJ2032" tickInterval="auto" step="5" min="0" max="120"
                [(ngModel)]="valoreBpmJ2032" aria-label="units"></mat-slider>
            </div>
            <div *ngIf="info">
              <mat-slider thumbLabel [displayWith]="formatLabelBpmJ2032" tickInterval="auto" step="5" min="0" max="120"
                style="pointer-events: none;" [(ngModel)]="valoreBpmJ2032" aria-label="units">
              </mat-slider>
            </div>
            <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed
              un'altra</small>
          </div>
        </div> -->
      </div>

          <!-- <div *ngIf="
              caricato &&
              valoreRadio==8
            " class="row">
            <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 20px">Ossigenazione del sangue</label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <label class="control-label"></label>

                <div *ngIf="!info">
                  <mat-slider thumbLabel [displayWith]="formatLabelBpmJ2032" tickInterval="auto" step="5" min="0" max="120"
                    [(ngModel)]="valoreSpo2" aria-label="units"></mat-slider>
                </div>
                <div *ngIf="info">
                  <mat-slider thumbLabel [displayWith]="formatLabelBpmJ2032" tickInterval="auto" step="5" min="0" max="120"
                    style="pointer-events: none" [(ngModel)]="valoreSpo2" aria-label="units">
                  </mat-slider>
                </div>
                <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed
                  un'altra</small>
              </div>
            </div>
          </div> -->

          <!-- <div *ngIf="
          caricato &&
          valoreRadio==8
        " class="row">
        <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 20px">
          Pressione</label>
        <div class="col-sm-10">
          <div class="form-group label-floating is-empty">
            <label class="control-label"></label>

            <div *ngIf="!info">
              <mat-slider thumbLabel [displayWith]="formatLabelBpmJ2032" tickInterval="auto" step="5" min="0" max="120"
                [(ngModel)]="valoreSpo2" aria-label="units"></mat-slider>
            </div>
            <div *ngIf="info">
              <mat-slider thumbLabel [displayWith]="formatLabelBpmJ2032" tickInterval="auto" step="5" min="0" max="120"
                style="pointer-events: none" [(ngModel)]="valoreSpo2" aria-label="units">
              </mat-slider>
            </div>
            <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed
              un'altra</small>
          </div>
        </div>
      </div> -->


          <div *ngIf="
              caricato &&
              valoreRadio != 2 &&
              valoreRadio != 5 &&
              valoreRadio != 6 &&
              valoreRadio != 7 &&
              valoreRadio !=9 &&
              valoreRadio !=8 &&
              valoreRadio !=10
            " class="row">
            <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 26px">Intervallo di misurazione
              Temperatura</label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <label class="control-label"></label>
                <div *ngIf="!info">
                  <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="1" min="1" max="5"
                    [(ngModel)]="valoreTemp" aria-label="units"></mat-slider>
                </div>
                <div *ngIf="info">
                  <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="1" min="1" max="5"
                    style="pointer-events: none" [(ngModel)]="valoreTemp" aria-label="units">
                  </mat-slider>
                </div>
                <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed
                  un'altra
                </small>
              </div>
            </div>
          </div>

          <!----------------------------------Volume-->

          <div *ngIf="
              caricato &&
              valoreRadio != 5 &&
              valoreRadio != 6 &&
              valoreRadio != 7 &&
              valoreRadio !=9 &&
              valoreRadio !=8 &&
              valoreRadio !=10
            " class="row">
            <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 32px">Volume
            </label>
            <div class="col-sm-10">
              <div class="form-group label-floating is-empty">
                <div *ngIf="!info" class="range">
                  <mat-slider thumbLabel [displayWith]="formatLabelVolume" tickInterval="auto" step="1" min="0" max="11"
                    [disabled]="info" [(ngModel)]="valoreVolume" aria-label="units">
                  </mat-slider>
                </div>
                <div *ngIf="info">
                  <mat-slider thumbLabel [displayWith]="formatLabelVolume" tickInterval="auto" step="1" min="0" max="11"
                    style="pointer-events: none" [(ngModel)]="valoreVolume" aria-label="units">
                  </mat-slider>
                </div>
              </div>
            </div>
          </div>

          <!----------------------------------sos Number-->
          <form *ngIf="caricato" class="form-horizontal" [formGroup]="configurazioneJ2032form">
            <div class="row" *ngIf="valoreRadio == 8">
              <label class="col-sm-2 label-on-left">Numero SOS
              </label>
              <div class="col-sm-10">
                <div class="form-group label-floating is-empty">
                  <label class="control-label"></label>
                  <input type="text" maxlength="30" placeholder="Numero" formControlName="sosNumber"
                    class="form-control" [readonly]="info" />
                  <small class="text-muted"></small>
                  <div *ngIf="submittedStep3 && fJ2032.sosNumber.errors" class="invalid-feedback">
                    <div *ngIf="fJ2032.sosNumber.errors.pattern">
                      Inserisci un numero di telefono corretto
                    </div>
                    <!-- <div *ngIf="h.redNumber.errors.required">
                      Campo obbligatorio
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            </form>
          <!----------------------------------red Number-->
          <form *ngIf="caricato" class="form-horizontal" [formGroup]="configurazioneform">
            <div class="row" *ngIf="valoreRadio != 5 && valoreRadio != 6 && valoreRadio != 7 && valoreRadio != 8 && valoreRadio !=9 && valoreRadio !=10">
              <label class="col-sm-2 label-on-left">Numero Rosso<span class="colorRed"> *</span>
              </label>
              <div class="col-sm-10">
                <div class="form-group label-floating is-empty">
                  <label class="control-label"></label>
                  <input type="text" maxlength="30" placeholder="Numero" formControlName="redNumber"
                    class="form-control" [readonly]="info" />
                  <small class="text-muted"></small>
                  <div *ngIf="submittedStep3 && h.redNumber.errors" class="invalid-feedback">
                    <div *ngIf="h.redNumber.errors.pattern">
                      Inserisci un numero di telefono corretto
                    </div>
                    <div *ngIf="h.redNumber.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------------Yellow Number-->

            <div class="row" *ngIf="valoreRadio != 5 && valoreRadio != 6 && valoreRadio != 7 && valoreRadio != 8 && valoreRadio !=9 && valoreRadio !=10">
              <label class="col-sm-2 label-on-left">Numero Giallo<span class="colorRed"> *</span>
              </label>
              <div class="col-sm-10">
                <div class="form-group label-floating is-empty">
                  <label class="control-label"></label>
                  <input type="text" maxlength="30" placeholder="Numero" formControlName="yellowNumber"
                    class="form-control" [readonly]="info" />
                  <small class="text-muted"></small>
                  <div *ngIf="submittedStep3 && h.yellowNumber.errors" class="invalid-feedback">
                    <div *ngIf="h.yellowNumber.errors.pattern">
                      Inserisci un numero di telefono corretto
                    </div>
                    <div *ngIf="h.yellowNumber.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------------green Number-->

            <div class="row" *ngIf="valoreRadio != 5 && valoreRadio != 6 && valoreRadio != 7 && valoreRadio != 8 && valoreRadio !=9 && valoreRadio !=10">
              <label class="col-sm-2 label-on-left">Numero Verde<span class="colorRed"> *</span>
              </label>
              <div class="col-sm-10">
                <div class="form-group label-floating is-empty">
                  <label class="control-label"></label>
                  <input type="text" maxlength="30" placeholder="Numero" formControlName="greenNumber"
                    class="form-control" [readonly]="info" />
                  <small class="text-muted"></small>
                  <div *ngIf="submittedStep3 && h.greenNumber.errors" class="invalid-feedback">
                    <div *ngIf="h.greenNumber.errors.pattern">
                      Inserisci un numero di telefono corretto
                    </div>
                    <div *ngIf="h.greenNumber.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------------White List-->

            <div class="row" *ngIf="valoreRadio != 5 && valoreRadio != 6 && valoreRadio != 7 && valoreRadio != 8 && valoreRadio !=9 && valoreRadio !=10">
              <label class="col-sm-2 label-on-left">White List<span class="colorRed"> *</span>
              </label>
              <div class="col-sm-10">
                <div class="form-group label-floating is-empty">
                  <label class="control-label"></label>
                  <input type="text" maxlength="255" placeholder="Numero" formControlName="whiteList"
                    class="form-control" [readonly]="info" />
                  <small class="text-muted">inserisci i numeri separati da una virgola</small>
                  <div *ngIf="submittedStep3 && h.whiteList.errors" class="invalid-feedback">
                    <div *ngIf="h.whiteList.errors.pattern">
                      Inserisci i numeri di telefono in un formato corretto
                    </div>
                    <div *ngIf="h.whiteList.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="valoreRadio != 5 && valoreRadio != 6 && valoreRadio != 7 && valoreRadio !=9 && valoreRadio !=10">
              <div class="d-flex flex-row">
                <label class="col-sm-2 label-on-left p-2">Sleep Time </label>
                <input *ngIf="!info" style="margin-left: 5px; margin-right: 10px" class="p-2" type="checkbox"
                  placeholder="SleepTime" formControlName="sleepTime" class="form-check-input" />
                <input *ngIf="info" style="margin-left: 5px" type="checkbox" class="p-2" placeholder="SleepTime"
                  formControlName="sleepTime" class="form-check-input" disabled="true" />
              </div>
              <div class="col-sm-10">
                <div class="form-group label-floating is-empty">
                  <small style="display: flex" class="text-muted">Dalle ore 20:00 alle ore 08:00 il device smetterà di
                    comunicare</small>
                </div>
              </div>
            </div>
          </form>

          <form *ngIf="caricato" class="form-horizontal" [formGroup]="configurazionePT460form">
            <!----------------------------------Abilita numeri SOS-->
            <div *ngIf="valoreRadio == 5" class="row">
              <div class="ml-5">
                <span>Numeri SOS</span>
                <input [disabled]="info" type="checkbox" [checked]="sosNumbersEnabled"
                  (change)="sosNumbersEnabled = !sosNumbersEnabled" style="margin-left: 5px" />
              </div>

              <div *ngIf="sosNumbersEnabled">
                <!----------------------------------SOS Number #1-->
                <label class="col-sm-2 label-on-left">Numero SOS #1</label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="text" maxlength="30" placeholder="Numero" formControlName="sosNumber1"
                      class="form-control" [readonly]="info" />
                    <small class="text-muted"></small>
                    <div *ngIf="submittedStep3 && p.sosNumber1.errors" class="invalid-feedback">
                      <div *ngIf="p.sosNumber1.errors.pattern">
                        Inserisci un numero di telefono corretto
                      </div>
                      <div *ngIf="p.sosNumber1.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <!----------------------------------SOS Number #2-->
                <label class="col-sm-2 label-on-left">Numero SOS #2</label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="text" maxlength="30" placeholder="Numero" formControlName="sosNumber2"
                      class="form-control" [readonly]="info" />
                    <small class="text-muted"></small>
                    <div *ngIf="submittedStep3 && p.sosNumber2.errors" class="invalid-feedback">
                      <div *ngIf="p.sosNumber2.errors.pattern">
                        Inserisci un numero di telefono corretto
                      </div>
                      <div *ngIf="p.sosNumber2.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <!----------------------------------SOS Number #3-->
                <label class="col-sm-2 label-on-left">Numero SOS #3 </label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="text" maxlength="30" placeholder="Numero" formControlName="sosNumber3"
                      class="form-control" [readonly]="info" />
                    <small class="text-muted"></small>
                    <div *ngIf="submittedStep3 && p.sosNumber3.errors" class="invalid-feedback">
                      <div *ngIf="p.sosNumber3.errors.pattern">
                        Inserisci un numero di telefono corretto
                      </div>
                      <div *ngIf="p.sosNumber3.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!----------------------------------Working Mode (Modifica/Crea)-->
            <div *ngIf="valoreRadio == 5" class="row" style="margin-top: 35px">
              <label class="col-sm-2 label-on-left p-2" style="right: 12px">Modalità localizzazione</label>
              <div *ngIf="!info">
                <tr>
                  <td>
                    <input type="radio" (click)="setWorkingMode(1)" class="form-check-input" class="radioLocalizzazione"
                      [checked]="valoreRadioWorkingMode == 1" />
                  </td>
                  <td style="font-weight: bold">Normale</td>
                  <td>
                    <input type="radio" (click)="setWorkingMode(2)" class="form-check-input" class="radioLocalizzazione"
                      [checked]="valoreRadioWorkingMode == 2" />
                  </td>
                  <td style="font-weight: bold">Risparmio Energetico</td>
                  <td>
                    <input type="radio" (click)="setWorkingMode(3)" class="form-check-input" class="radioLocalizzazione"
                      [checked]="valoreRadioWorkingMode == 3" />
                  </td>
                  <td style="font-weight: bold">Alta precisione</td>
                </tr>
              </div>
              <!----------------------------------Working Mode (Read Only)-->
              <table *ngIf="info">
                <tbody>
                  <tr>
                    <td width="40%" style="padding-left: 10px; padding-top: 1px">
                      {{ getWorkingMode(valoreRadioWorkingMode) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div *ngIf="caricato && valoreRadio==9" class="row">

          <div *ngIf="!info" class="row">
            <h4>Configurazione Shelly Button1</h4>
            <div class="row">
              <app-shellybutton1-conf *ngIf="crea" [crea]="crea" [(deviceConfigured)]="valoreConfiguredShellyButton1"></app-shellybutton1-conf>
              <app-shellybutton1-conf *ngIf="edit" [edit]="edit" [device]="dispositivo" [(deviceConfigured)]="valoreConfiguredShellyButton1" (macChange)="changeMac($event)"></app-shellybutton1-conf>
            </div>
          </div>
          <div *ngIf="info" class="row">
                           <app-shellybutton1-conf [info]="info" [device]="dispositivo" [(deviceConfigured)]="valoreConfiguredShellyButton1"></app-shellybutton1-conf>
          </div>
        </div>
        <div *ngIf="modSmartbed">
          <div *ngIf="!caricato">
            <div class="text-center">
              <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="5"
                styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
            </div>
          </div>

          <div *ngIf="caricato" class="row">
            <div *ngIf="!info" class="row">
              <h4>Configurazione Smartbed</h4>
              <div class="row">
                <app-smart-conf [showAllComponent]="!edit" *ngIf="!info"></app-smart-conf>
              </div>
            </div>
            <!-- <iframe *ngIf="!info" height="500px" width="100%" src="https://test-gap.protom.com/smart_conf_test/" frameborder="0"></iframe> -->
            <form class="form-horizontal" [formGroup]="configurazioneFormSmartbed">
              <div class="row">
                <h4>Parametri Paziente</h4>
                <label class="col-sm-2 label-on-left">Sesso</label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <p-selectButton [disabled]="info" [options]="buttonFormSesso" formControlName="sesso">
                    </p-selectButton>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 label-on-left">Età<span class="colorRed"> *</span></label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="number" maxlength="3" placeholder="Anni" formControlName="eta" class="form-control"
                      onkeydown="javascript: return event.keyCode === 8 ||
                                        event.keyCode === 46 ? true : !isNaN(Number(event.key))" [readonly]="info" />
                    <small class="text-muted"></small>
                    <div *ngIf="submittedStep3 && fSmartbed.eta.errors" class="invalid-feedback">
                      <!-- <div *ngIf="fSmartbed.eta.errors.pattern">Inserisci un numero intero</div> -->
                      <div *ngIf="fSmartbed.eta.errors.max != null">
                        Età massima permessa fino a
                        {{ fSmartbed.eta.errors.max.max }}
                      </div>
                      <div *ngIf="fSmartbed.eta.errors.min != null">
                        Età minima permessa fino a
                        {{ fSmartbed.eta.errors.min.min }}
                      </div>
                      <div *ngIf="fSmartbed.eta.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 label-on-left">Peso<span class="colorRed"> *</span></label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="number" maxlength="6" placeholder="Kg" formControlName="peso" class="form-control"
                      onkeydown="javascript: return event.keyCode === 8 ||
                                        event.keyCode === 46 ? true : !isNaN(Number(event.key))" [readonly]="info" />
                    <small class="text-muted"></small>
                    <div *ngIf="submittedStep3 && fSmartbed.peso.errors" class="invalid-feedback">
                      <!-- <div *ngIf="fSmartbed.peso.errors.pattern">Inserisci un numero reale senza segno</div> -->
                      <div *ngIf="fSmartbed.peso.errors.required">
                        Campo obbligatorio
                      </div>
                      <div *ngIf="fSmartbed.peso.errors.max != null">
                        Peso massimo consentito
                        {{ fSmartbed.peso.errors.max.max }}
                      </div>
                      <div *ngIf="fSmartbed.peso.errors.min != null">
                        Peso minimo consentito
                        {{ fSmartbed.peso.errors.min.min }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 label-on-left">Altezza<span class="colorRed"> *</span></label>
                <div class="col-sm-10">
                  <div class="form-group label-floating is-empty">
                    <label class="control-label"></label>
                    <input type="text" maxlength="3" placeholder="centimetri" formControlName="altezza"
                      class="form-control" [readonly]="info" pattern="^[0-9]*$" />
                    <small class="text-muted"></small>
                    <div *ngIf="submittedStep3 && fSmartbed.altezza.errors" class="invalid-feedback">
                      <div *ngIf="fSmartbed.altezza.errors.pattern">
                        Inserisci un numero intero
                      </div>
                      <div *ngIf="fSmartbed.altezza.errors.required">
                        Campo obbligatorio
                      </div>
                      <div *ngIf="fSmartbed.altezza.errors.max != null">
                        Altezza massima consentita
                        {{ fSmartbed.altezza.errors.max.max }}
                      </div>
                      <div *ngIf="fSmartbed.altezza.errors.min != null">
                        Altezza minima consentita
                        {{ fSmartbed.altezza.errors.min.min }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--Tracking-->
        <!-- <div *ngIf="caricato && valoreRadio == 6" class="row">
          <label class="col-sm-2 label-on-left" style="text-align: end; margin-top: 32px">Tempo di invio</label>
          <div class="col-sm-10">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>

              <div>
                <mat-slider thumbLabel [displayWith]="labelTm" [ngClass]="{'p-none': info}" tickInterval="auto" step="1"
                  min="1" max="4" [(ngModel)]="valorePing" aria-label="units"></mat-slider>
              </div>
              <small class="text-muted">Determina un intervallo di tempo tra un invio ed un
                altro</small>
              <div>

              </div>
            </div>
          </div>
        </div> -->
        <!--Trackers-->
        <div *ngIf="caricato && valoreRadio == 6" class="row">
          <mat-tab-group dynamicHeight>
            <mat-tab [label]= "info? 'Operatore Associato' : 'Associa Operatore'">
              <div *ngIf="!info">
                <app-gestione-utente [selectedId]="idUser" [smartView]="true" (emitUtente)="registraId($event)" ></app-gestione-utente>
              </div>
              <div *ngIf="info">
                <div class="row flex" style="margin-left: -5px;">
                  <div class="col-sm-1">
                    <h4>Nome:</h4>
                  </div>
                  <div class="col-sm-5">
                    <h5>{{dispositivo.utente.nome}} {{dispositivo.utente.cognome}}</h5>
                  </div>
                  <div class="col-sm-1">
                    <h4>Email:</h4>
                  </div>
                  <div class="col-sm-5">
                    <h5>{{dispositivo.utente.email}}</h5>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab *ngIf = "!info" label="Associa Posizione">
              <form class="form-horizontal" [formGroup]="formTrackers">
                <div class="row flex">
                  <label class="col-sm-2 label-on-left responsiveLabel">Latitudine<span *ngIf="!edit" class="colorRed"> *</span></label>
                  <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                      <label class="control-label"></label>
                      <input type="text" placeholder="Latitudine" formControlName="latitudine" class="form-control" maxlength="30"
                        [readonly]="info" />
                      <div *ngIf="submittedStep3 && t.latitudine.errors && !edit" class="invalid-feedback">
                        <div *ngIf="t.latitudine.errors.required">
                          Campo obbligatorio
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex">
                  <label class="col-sm-2 label-on-left responsiveLabel">Longitudine<span *ngIf="!edit" class="colorRed"> *</span></label>
                  <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                      <label class="control-label"></label>
                      <input type="text" placeholder="Longitudine" formControlName="longitudine" class="form-control"
                        maxlength="30" [readonly]="info" />
                      <div *ngIf="submittedStep3 && t.longitudine.errors && !edit" class="invalid-feedback">
                        <div *ngIf="t.longitudine.errors.required">
                          Campo obbligatorio
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-tab>
            <mat-tab label="Soglia Batteria">
              <form class="form-horizontal" [formGroup]="formTrackers">
              <div class="row flex">
                <div class="col-sm-1">
                </div>
                <div class="col-sm-5">
                  <label class="control-label"></label>
                  <input type="text" [placeholder]="listaSoglie.length > 0 ? listaSoglie[0].minimo : 'Min'" formControlName="batteriaMin" class="form-control"
                    maxlength="5" [readonly]="info" />

                </div>
                <div class="col-sm-5">
                  <label class="control-label"></label>
                  <input type="text" [placeholder]="listaSoglie.length > 0 ? listaSoglie[0].massimo : 'Max'" formControlName="batteriaMax" class="form-control"
                    maxlength="5" [readonly]="info" />
                </div>
                <div class="col-sm-1">
                </div>
              </div>
            </form>
            </mat-tab>
          </mat-tab-group>
            
        </div>

        <!--Beacon-->
        <div *ngIf="caricato && valoreRadio == 7">
          <app-lista-dispositivi [idInputDispositivo]="6" [selectedDevice]="idTracker" (emitId)="registraIdTracker($event)"></app-lista-dispositivi>
          <!-- <app-double-list [inAccordion]="true" [titleAccordion]="'Obiettivi'" [nomeLista1]="'Seleziona Obiettivi'" [nomeLista2]="'Obiettivi'" [(lista1)]="[{id:0, value:'obiettivo 1'},{id:0, value:'obiettivo 2'},{id:0, value:'obiettivo 3'},{id:0, value:'obiettivo 4'},{id:0, value:'obiettivo 5'},{id:0, value:'obiettivo 6'},{id:0, value:'obiettivo 7'},{id:0, value:'obiettivo 8'},{id:0, value:'obiettivo 9'},{id:1, value:'addio'}]" [lista2]="[{id:3, value:'arrivederci'}]"></app-double-list> -->
        </div>

        <!--Shelly Plus1-->
        <div *ngIf="caricato && valoreRadio == 10">
          <app-lista-dispositivi [idInputDispositivo]="9" [selectedDevice]="idTrigger" (emitId)="registraIdTrigger($event)"></app-lista-dispositivi>
          <!-- <app-double-list [inAccordion]="true" [titleAccordion]="'Obiettivi'" [nomeLista1]="'Seleziona Obiettivi'" [nomeLista2]="'Obiettivi'" [(lista1)]="[{id:0, value:'obiettivo 1'},{id:0, value:'obiettivo 2'},{id:0, value:'obiettivo 3'},{id:0, value:'obiettivo 4'},{id:0, value:'obiettivo 5'},{id:0, value:'obiettivo 6'},{id:0, value:'obiettivo 7'},{id:0, value:'obiettivo 8'},{id:0, value:'obiettivo 9'},{id:1, value:'addio'}]" [lista2]="[{id:3, value:'arrivederci'}]"></app-double-list> -->
        </div>

      </div>

      <!--end #step3 -->
      <div *ngIf="step4 && valoreRadio !== 6 && valoreRadio !== 7 && valoreRadio !==9 && valoreRadio !==10">
        <div class="container">
          <div class="row">
            <div class="flex">
              <span [matTooltip]="
                  'Permette di impostare un intervallo di tempo durante il quale gli allarmi del dispositivo saranno soppressi. Il dispositivo continuerà ad inviare le rilevazioni.'
                " class="verticalCentered material-icons fs-12">
                help
              </span>
              <span>Sleep time</span>
              <div class="ml-5">
                <input [disabled]="info" type="checkbox" [checked]="sleepTime" (change)="sleepTime = !sleepTime" />
              </div>
            </div>
          </div>
          <div class="mt-25" *ngIf="sleepTime" [ngClass]="{ row: !isMobile, flex: !isMobile }">
            <div class="grid">
              <label for="minSleepTime"><b>Start Time</b></label>
              <input [readonly]="info" type="time" id="minSleepTime" [(ngModel)]="minSleepTime" min="00:00" max="23:59"
                (input)="dataMinimaSleepTimeSet($event)" />
            </div>
            <div class="grid" [ngClass]="{ 'ml-25': !isMobile }">
              <label for="maxSleepTime"><b>End Time</b></label>
              <input [readonly]="info" type="time" id="maxSleepTime" [(ngModel)]="maxSleepTime" min="00:00" max="23:59"
                (input)="dataMassimaSleepTimeSet($event)" />
            </div>
          </div>
        </div>
        <hr />
        <div class="container">
          <div *ngIf="!info" class="row">
            <div class="col-sm-4 label-on-left">
              <label class="switch mr-5 ml-5" *ngIf="sleepTime" [ngClass]="{ 'mb-25': isMobile }">
                <input type="checkbox" [disabled]="info" [(ngModel)]="toggleSoglia" />
                <span class="slider round"></span>
              </label>

              <label style="display: inline">Aggiungi Soglia</label>

              <button class="btn btn-primary btn-round" style="
                  padding: 8px;
                  margin-top: -5px;
                  display: inline;
                  margin-left: 10px;
                " (click)="aggiungiSoglia()">
                <i class="material-icons notranslate" style="cursor: pointer">add</i>
              </button>
            </div>
          </div>
        </div>

        <div *ngFor="let soglia of listaSoglie">
          <div class="row" style="margin-top: 20px" [ngClass]="{ target: !isDesktop }"
            *ngIf="soglieToNotShow.indexOf(soglia.telemetria) === -1">
            <div [ngClass]="{ 'flex mb-15': !isDesktop }">
              <label class="col-sm-1 label-on-left noWrap">Telemetria<span class="colorRed"> *</span></label>

              <div *ngIf="!info && !isDesktop" class="col-sm-1 ml-auto" style="margin-top: -20px">
                <!-- <div class="form-group label-floating is-empty"> -->
                <label class="control-label"></label>
                <i class="material-icons notranslate" style="
                    color: red;
                    font-size: medium;
                    margin-top: 20px;
                    cursor: pointer;
                  " (click)="deleteSoglia(soglia)">clear</i>
                <!-- </div> -->
              </div>
            </div>

            <div class="col-sm-2">
              <div *ngIf="!info" class="select flex" style="margin-top: -8px" [title]="showTipoSoglia(soglia)">
                <select class="form-control" [(ngModel)]="soglia.telemetria"
                  [ngClass]="{ 'is-invalid': submittedStep4 && soglia.errore }">
                  <ng-container *ngFor="let tipo of listaTipoSoglia">
                    <option *ngIf="tipo.telemetry !== 'Movements'" value="{{ tipo.telemetry }}">
                      {{ tipo.telemetryName }}
                    </option>
                  </ng-container>
                </select>
              </div>
              <div *ngIf="info">{{ soglia.nome }}</div>
              <div *ngIf="submittedStep4" class="invalid-feedback">
                <div *ngIf="soglia.erroreTel" class="invalid-feedback">
                  Seleziona una telemetria
                </div>
              </div>
            </div>

            <label class="col-sm-1" style="text-align: center" [ngClass]="{ 'mt-25': !isDesktop }">Range<span
                class="colorRed"> *</span></label>
            <div class="col-sm-1">
              <div *ngFor="let tipo of listaTipoSoglia">
                <div *ngIf="checkTelemetryUnit(soglia, tipo)">
                  {{ tipo.telemetryUnit }}
                </div>
              </div>
            </div>
            <div [ngClass]="{ flex: !isDesktop }">
              <div class="col-sm-3" style="margin-top: -28px">
                <div class="form-group label-floating is-empty char">
                  <input type="number" step="0.01" placeholder="MIN" class="form-control" [(ngModel)]="soglia.minimo"
                    [readonly]="info" />
                  <div *ngIf="submittedStep4" class="invalid-feedback">
                    <div *ngIf="soglia.errore" class="invalid-feedback">
                      Campo Range invalido
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3" style="margin-top: -28px">
                <div class="form-group label-floating is-empty char">
                  <input type="number" step="0.01" placeholder="MAX" class="form-control" [(ngModel)]="soglia.massimo"
                    [readonly]="info" />
                  <div *ngIf="submittedStep4" class="invalid-feedback">
                    <div *ngIf="soglia.errore" class="invalid-feedback">
                      Campo Range invalido
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!info && isDesktop" class="col-sm-1" style="margin-top: -20px">
              <!-- <div class="form-group label-floating is-empty"> -->
              <label class="control-label"></label>
              <i class="material-icons notranslate" style="
                  color: red;
                  font-size: medium;
                  margin-top: 20px;
                  cursor: pointer;
                " (click)="deleteSoglia(soglia)">clear</i>
              <!-- </div> -->
            </div>
          </div>
        </div>

        <hr *ngIf="modSmartbed" />
        <div class="container" *ngIf="modSmartbed">
          <div class="row">
            <div class="flex">
              <span [matTooltip]="testoPresenza" class="verticalCentered material-icons fs-12">
                help
              </span>

              <label class="switch mr-5" *ngIf="sleepTime">
                <input [disabled]="info" type="checkbox" [(ngModel)]="toggleLetto" />
                <span class="slider round"></span>
              </label>
              <span>Presenza a letto</span>
            </div>
          </div>
          <div class="row">
            <div class="grid">
              <div class="flex">
                <span class="l-bold">A Letto</span>
                <input class="ml-5" [disabled]="info" type="checkbox" [checked]="aLetto" (change)="aLetto = !aLetto" />
              </div>
              <div class="flex container">
                <button [disabled]="info" class="w-25p border-0" *ngIf="aLetto" (click)="
                    numberALetto - 1 < 0
                      ? (numberALetto = 0)
                      : (numberALetto = numberALetto - 1)
                  ">
                  -
                </button>
                <input [readonly]="info" type="number" [(ngModel)]="numberALetto" min="0" *ngIf="aLetto"
                  class="w-50p border-0 t-center"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" />
                <button [disabled]="info" class="w-25p border-0" *ngIf="aLetto"
                  (click)="numberALetto = numberALetto + 1">
                  +
                </button>
                <span class="ml-5" *ngIf="aLetto">minuti</span>
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="grid">
              <div class="flex">
                <span class="l-bold">Non A Letto</span>
                <input class="ml-5" [disabled]="info" type="checkbox" [checked]="nonALetto"
                  (change)="nonALetto = !nonALetto" />
              </div>
              <div class="flex container">
                <button [disabled]="info" class="w-25p border-0" (click)="
                    numberNonALetto - 1 < 0
                      ? (numberNonALetto = 0)
                      : (numberNonALetto = numberNonALetto - 1)
                  " *ngIf="nonALetto">
                  -
                </button>
                <input [readonly]="info" [(ngModel)]="numberNonALetto" type="number" min="0" *ngIf="nonALetto"
                  class="w-50p border-0 t-center"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" />
                <button [disabled]="info" class="w-25p border-0" (click)="numberNonALetto = numberNonALetto + 1"
                  *ngIf="nonALetto">
                  +
                </button>
                <span class="ml-5" *ngIf="nonALetto">minuti</span>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="modSmartbed" />
        <div class="container" *ngIf="modSmartbed">
          <div class="row">
            <div class="flex">
              <span [matTooltip]="testoMovimenti" class="verticalCentered material-icons fs-12">
                help
              </span>

              <label class="switch mr-5" *ngIf="sleepTime">
                <input [disabled]="info" type="checkbox" [(ngModel)]="toggleMovimenti" />
                <span class="slider round"></span>
              </label>
              <span>Movimenti</span>
              <div class="ml-5">
                <input [disabled]="info" type="checkbox" [checked]="movimenti" (change)="movimenti = !movimenti" />
              </div>
            </div>
          </div>
          <div class="container" *ngIf="movimenti">
            <div class="row flex">
              <div class="grid" [ngClass]="{ 'ml-25': !isMobile }">
                <div class="flex">
                  <span class="l-bold">Immobile</span>
                </div>
                <div class="flex">
                  <!-- <button [disabled]="info" class="w-25p border-0" (click)="(numberMedio === '--'? numberMedio='--':(numberMedio-5<10? (numberMedio='--'):(numberMedio=numberMedio-5))); numberMedio==='--'? numberMedioPercentuale=numberMedio:numberMedioPercentuale=numberMedioPercentuale">-</button>
                            <input [readonly]="info"  type="text" (focusin)="numberMedio==='--'? numberMedio='':numberMedio=numberMedio"  (focusout)="numberMedio = roundToFive(numberMedio)" [(ngModel)]="numberMedio" min="0" class="w-50p border-0 t-center" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57">
                          <button [disabled]="info" class="w-25p border-0" (click)="(numberMedio==='--'? numberMedio=10:numberMedio=numberMedio+5);numberMedio==10? numberMedioPercentuale=10:numberMedioPercentuale=numberMedioPercentuale">+</button>
                          <span class="ml-5">secondi</span> -->
                  <select [disabled]="info" [(ngModel)]="numberImmobile" [value]="numberImmobile">
                    <option value="0">Disattivato</option>
                    <option value="30">30 Secondi</option>
                    <option value="60">1 Minuto</option>
                    <option value="120">2 Minuti</option>
                    <option value="300">5 Minuti</option>
                    <option value="600">10 Minuti</option>
                  </select>
                </div>
              </div>
              <div class="grid ml-25">
                <div class="flex">
                  <span class="l-bold">Percentuale</span>
                </div>
                <div class="flex">
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberImmobile === '--'
                        ? (numberImmobilePercentuale = '--')
                        : numberImmobilePercentuale === '--'
                        ? (numberImmobilePercentuale = '--')
                        : numberImmobilePercentuale - 10 < 10
                        ? (numberImmobilePercentuale = 10)
                        : (numberImmobilePercentuale =
                            numberImmobilePercentuale - 10)
                    ">
                    -
                  </button>
                  <input [readonly]="info" type="text" (focusout)="
                      numberImmobilePercentuale = roundToTen(
                        numberImmobilePercentuale,
                        numberImmobile
                      )
                    " [(ngModel)]="numberImmobilePercentuale" min="0" class="w-50p border-0 t-center"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" />
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberImmobile === '--'
                        ? (numberImmobilePercentuale = '--')
                        : numberImmobilePercentuale === '--'
                        ? (numberImmobilePercentuale = 10)
                        : numberImmobilePercentuale + 10 > 100
                        ? (numberImmobilePercentuale = 100)
                        : (numberImmobilePercentuale =
                            numberImmobilePercentuale + 10)
                    ">
                    +
                  </button>
                  <span class="ml-5">%</span>
                </div>
              </div>
            </div>
            <div class="row flex">
              <div class="grid" [ngClass]="{ 'ml-25': !isMobile }">
                <div class="flex">
                  <span class="l-bold">Lieve</span>
                </div>
                <div class="flex">
                  <!-- <button [disabled]="info" class="w-25p border-0" (click)="(numberLieve === '--'? numberLieve='--':(numberLieve-5<10? (numberLieve='--'):(numberLieve=numberLieve-5))); numberLieve==='--'? numberLievePercentuale=numberLieve:numberLievePercentuale=numberLievePercentuale">-</button>
                            <input  [readonly]="info" type="text" (focusin)="numberLieve==='--'? numberLieve='':numberLieve=numberLieve" (focusout)="numberLieve = roundToFive(numberLieve)" [(ngModel)]="numberLieve" min="0" class="w-50p border-0 t-center" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57">
                          <button [disabled]="info" class="w-25p border-0" (click)="(numberLieve==='--'? numberLieve=10:numberLieve=numberLieve+5);numberLieve==10? numberLievePercentuale=10:numberLievePercentuale=numberLievePercentuale">+</button> -->
                  <select [disabled]="info" [(ngModel)]="numberLieve" [value]="numberLieve">
                    <option value="0">Disattivato</option>
                    <option value="30">30 Secondi</option>
                    <option value="60">1 Minuto</option>
                    <option value="120">2 Minuti</option>
                    <option value="300">5 Minuti</option>
                    <option value="600">10 Minuti</option>
                  </select>
                  <!-- <span class="ml-5">secondi</span> -->
                </div>
              </div>

              <div class="grid ml-25">
                <div class="flex">
                  <span class="l-bold">Percentuale</span>
                </div>
                <div class="flex">
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberLieve === '--'
                        ? (numberLievePercentuale = '--')
                        : numberLievePercentuale === '--'
                        ? (numberLievePercentuale = '--')
                        : numberLievePercentuale - 10 < 10
                        ? (numberLievePercentuale = 10)
                        : (numberLievePercentuale = numberLievePercentuale - 10)
                    ">
                    -
                  </button>
                  <input [readonly]="info" type="text" (focusout)="
                      numberLievePercentuale = roundToTen(
                        numberLievePercentuale,
                        numberLieve
                      )
                    " [(ngModel)]="numberLievePercentuale" min="0" class="w-50p border-0 t-center"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" />
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberLieve === '--'
                        ? (numberLievePercentuale = '--')
                        : numberLievePercentuale === '--'
                        ? (numberLievePercentuale = 10)
                        : numberLievePercentuale + 10 > 100
                        ? (numberLievePercentuale = 100)
                        : (numberLievePercentuale = numberLievePercentuale + 10)
                    ">
                    +
                  </button>
                  <span class="ml-5">%</span>
                </div>
              </div>
              <!-- <div class="grid ml-25">
                        <div class="flex">
                          <span class="l-bold">Alto</span>
                        </div>
                        <div class="flex">
                          <button [disabled]="info" class="w-25p border-0" (click)="numberAlto-5<0? (numberAlto=0):(numberAlto=numberAlto-5)">-</button>
                            <input type="text" (focusout)="numberAlto = roundToFive(numberAlto)" [(ngModel)]="numberAlto" min="0" class="w-50p border-0 t-center" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57">
                          <button [disabled]="info" class="w-25p border-0" (click)="numberAlto=numberAlto+5">+</button>
                          <span class="ml-5">secondi</span>
                        </div>
                      </div> -->
            </div>
            <div class="row flex">
              <div class="grid" [ngClass]="{ 'ml-25': !isMobile }">
                <div class="flex">
                  <span class="l-bold">Medio</span>
                </div>
                <div class="flex">
                  <!-- <button [disabled]="info" class="w-25p border-0" (click)="(numberMedio === '--'? numberMedio='--':(numberMedio-5<10? (numberMedio='--'):(numberMedio=numberMedio-5))); numberMedio==='--'? numberMedioPercentuale=numberMedio:numberMedioPercentuale=numberMedioPercentuale">-</button>
                          <input [readonly]="info"  type="text" (focusin)="numberMedio==='--'? numberMedio='':numberMedio=numberMedio"  (focusout)="numberMedio = roundToFive(numberMedio)" [(ngModel)]="numberMedio" min="0" class="w-50p border-0 t-center" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57">
                        <button [disabled]="info" class="w-25p border-0" (click)="(numberMedio==='--'? numberMedio=10:numberMedio=numberMedio+5);numberMedio==10? numberMedioPercentuale=10:numberMedioPercentuale=numberMedioPercentuale">+</button>
                        <span class="ml-5">secondi</span> -->
                  <select [disabled]="info" [(ngModel)]="numberMedio" [value]="numberMedio">
                    <option value="0">Disattivato</option>
                    <option value="30">30 Secondi</option>
                    <option value="60">1 Minuto</option>
                    <option value="120">2 Minuti</option>
                    <option value="300">5 Minuti</option>
                    <option value="600">10 Minuti</option>
                  </select>
                </div>
              </div>
              <div class="grid ml-25">
                <div class="flex">
                  <span class="l-bold">Percentuale</span>
                </div>
                <div class="flex">
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberMedio === '--'
                        ? (numberMedioPercentuale = '--')
                        : numberMedioPercentuale === '--'
                        ? (numberMedioPercentuale = '--')
                        : numberMedioPercentuale - 10 < 10
                        ? (numberMedioPercentuale = 10)
                        : (numberMedioPercentuale = numberMedioPercentuale - 10)
                    ">
                    -
                  </button>
                  <input [readonly]="info" type="text" (focusout)="
                      numberMedioPercentuale = roundToTen(
                        numberMedioPercentuale,
                        numberMedio
                      )
                    " [(ngModel)]="numberMedioPercentuale" min="0" class="w-50p border-0 t-center"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" />
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberMedio === '--'
                        ? (numberMedioPercentuale = '--')
                        : numberMedioPercentuale === '--'
                        ? (numberMedioPercentuale = 10)
                        : numberMedioPercentuale + 10 > 100
                        ? (numberMedioPercentuale = 100)
                        : (numberMedioPercentuale = numberMedioPercentuale + 10)
                    ">
                    +
                  </button>
                  <span class="ml-5">%</span>
                </div>
              </div>
            </div>
            <div class="row flex">
              <!-- <div class="grid">
                      <div class="flex">
                        <span class="l-bold">Basso</span>
                      </div>
                      <div class="flex">
                        <button [disabled]="info" class="w-25p border-0" (click)="numberBasso-5<0? (numberBasso=0):(numberBasso=numberBasso-5)">-</button>
                          <input type="text" (focusout)="numberBasso = roundToFive(numberBasso)"  [(ngModel)]="numberBasso" min="0" class="w-50p border-0 t-center" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57">
                        <button [disabled]="info" class="w-25p border-0" (click)="numberBasso=numberBasso+5">+</button>
                        <span class="ml-5">secondi</span>
                      </div>
                    </div> -->
              <div class="grid" [ngClass]="{ 'ml-25': !isMobile }">
                <div class="flex">
                  <span class="l-bold">Critico</span>
                </div>
                <div class="flex">
                  <!-- <button [disabled]="info" class="w-25p border-0" (click)="(numberCritico === '--'? numberCritico='--':(numberCritico-5<10? (numberCritico='--'):(numberCritico=numberCritico-5))); numberMedio==='--'? numberMedioPercentuale=numberMedio:numberMedioPercentuale=numberMedioPercentuale ">-</button>
                          <input  [readonly]="info"  type="text" (focusin)="numberCritico==='--'? numberCritico='':numberCritico=numberCritico" (focusout)="numberCritico = roundToFive(numberCritico)" [(ngModel)]="numberCritico" min="0" class="w-50p border-0 t-center" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57">
                        <button [disabled]="info" class="w-25p border-0" (click)="(numberCritico==='--'? numberCritico=10:numberCritico=numberCritico+5);numberCritico==10? numberCriticoPercentuale=10:numberCriticoPercentuale=numberCriticoPercentuale">+</button>
                        <span class="ml-5">secondi</span> -->
                  <select [disabled]="info" [(ngModel)]="numberCritico" [value]="numberCritico">
                    <option value="0">Disattivato</option>
                    <option value="30">30 Secondi</option>
                    <option value="60">1 Minuto</option>
                    <option value="120">2 Minuti</option>
                    <option value="300">5 Minuti</option>
                    <option value="600">10 Minuti</option>
                  </select>
                </div>
              </div>

              <div class="grid ml-25">
                <div class="flex">
                  <span class="l-bold">Percentuale</span>
                </div>
                <div class="flex">
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberCritico === '--'
                        ? (numberCriticoPercentuale = '--')
                        : numberCriticoPercentuale === '--'
                        ? (numberCriticoPercentuale = '--')
                        : numberCriticoPercentuale - 10 < 10
                        ? (numberCriticoPercentuale = 10)
                        : (numberCriticoPercentuale =
                            numberCriticoPercentuale - 10)
                    ">
                    -
                  </button>
                  <input [readonly]="info" type="text" (focusout)="
                      numberCriticoPercentuale = roundToTen(
                        numberCriticoPercentuale,
                        numberCritico
                      )
                    " [(ngModel)]="numberCriticoPercentuale" min="0" class="w-50p border-0 t-center"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" />
                  <button [disabled]="info" class="w-25p border-0" (click)="
                      numberCritico === '--'
                        ? (numberCriticoPercentuale = '--')
                        : numberCriticoPercentuale === '--'
                        ? (numberCriticoPercentuale = 10)
                        : numberCriticoPercentuale + 10 > 100
                        ? (numberCriticoPercentuale = 100)
                        : (numberCriticoPercentuale =
                            numberCriticoPercentuale + 10)
                    ">
                    +
                  </button>
                  <span class="ml-5">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end #step4 -->
    </div>
    <!--end .tab-content -->
  </div>
  <!--end #rootwizard -->

  <!-- FINE  WIZARD -->

  <div class="row" style="margin-top: 2%">
    <div class="col-xs-6">
      <button *ngIf="!step1" (click)="previusStep()" class="btn btn-secondary"
        style="color: white; margin-right: 2%; margin-bottom: 2%">
        Indietro
      </button>
    </div>
    <div *ngIf="(!step4 && !(valoreRadio === 6 || valoreRadio === 7 || valoreRadio === 9 || valoreRadio === 10)) || ((valoreRadio === 6 || valoreRadio === 7 || valoreRadio === 9 || valoreRadio === 10) && !step3)" class="col-xs-6 text-right">
      <button (click)="nextStep()" class="btn btn-primary">Avanti</button>
    </div>
    <div *ngIf="(step4 || (step3 && (valoreRadio == 6 || valoreRadio == 7 || valoreRadio == 9 || valoreRadio == 10))) && !info" class="col-xs-6 text-right">
      <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary">
        Salva
        <span *ngIf="loading">
          <p-progressSpinner [style]="{ width: '10px', height: '10px' }" strokeWidth="8" styleClass="custom-spinner"
            animationDuration=".5s"></p-progressSpinner>
        </span>
      </button>
    </div>
  </div>
</div>

<p-confirmDialog
  key="cancelSosNumber"
  #cdik
  icon="pi pi-exclamation-triangle"
  [style]="{ width: responsiveWidth() }"
  [acceptLabel]="'Si'"
  [baseZIndex]="5"
>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Sì"
      (click)="nextStep(true)"
    >
    </button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cdik.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>
