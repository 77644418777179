<div *ngIf="!caricato">
    <div class="text-center" style="margin-top: 5%">
      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5" styleClass="custom-spinner2"
        animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div class="container-fluid" *ngIf="caricato">
  <div *ngIf="gestione" class="content">
    <div class="row">
      <div class="col-md-12 ">
        <div class="card z-index-0">
          <div class="card-content">
            <h4 class="card-title">Gestione Assistiti</h4>
            <ul class="nav nav-pills nav-pills-primary">
              <li class="active">
                <a href="#pill1" data-toggle="tab">Assistiti</a>
              </li>
              <li>
                <a href="#pill2" data-toggle="tab">Cestino</a>
              </li>
              <div class="pull-right">
                <div class="btn btn-primary btn-round"
                    style="padding: 8px 8px 8px 8px ; margin-left: 35px;" (click)="aggiungiCliente()">
                    <i class="material-icons notranslate">add</i>
                </div>
            </div>
            </ul>
            <hr>
            <div class="tab-content">
              <div class="tab-pane active" id="pill1">
                <div class="material-datatables">

                  <div class="row">
                    <div class="col-sm-3 col-xs-3">
                      <div class="dataTables_length" id="datatables_length" [ngClass]="{'disabled':!isClickable}">
                        <div style="display: flex;">
                          <label class="form-group pl-5">Limita Risultati
                            <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                              aria-controls="datatables" class="form-control input-sm">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>

                            </select>
                            <div *ngIf="isSelectingValue" style="display: flex;align-items: end;">
                              <div class="text-center" style="position: absolute;right: 0px;">
                                <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-9 col-xs-9">
                      <div id="datatables_filter" class="dataTables_filter"
                        style="display: flex; justify-content: flex-end;">
                        <form [formGroup]="searchform">
                          <label class="form-group pull-right pr-5">Esegui una ricerca
                            <div style="display: flex;">
                              <input (input)="searchInClients(true)" type="text" formControlName="search"
                                class="form-control" placeholder="Cerca..." maxlength="30" style="width: 40vw;">
                              <div *ngIf="isSearchingOn" style="display: flex;align-items: end;">
                                <div class="text-center" style="position: absolute;right: 0px;">
                                  <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                                </div>
                              </div>
                            </div>
                          </label>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="scrollable">
                    <!-- <p-confirmDialog [style]="{width: '20vw'}" [acceptLabel]="'Si'" [baseZIndex]="5"></p-confirmDialog> -->

                    <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0"
                      width="100%" style="width:100%" *ngIf="showTable">
                      <thead style="position: sticky; background-color: white;z-index: 1;">
                        <tr style="position: sticky; z-index: 1; background-color: white;">
                          <th style="position: sticky; z-index: 1; background-color: white;">
                            Nome</th>
                          <th style="position: sticky; z-index: 1; background-color: white;">
                           Codice Fiscale</th>
                          <!-- <th style="position: sticky; z-index: 1; background-color: white;">
                                                        Gruppo</th> -->

                          <th class="text-right" style="position: sticky; z-index: 1; background-color: white;">
                            Azioni</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th>Nome</th>
                          <th>Codice Fiscale</th>
                          <th class="text-right">Azioni</th>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr *ngFor="let cliente of listaClienti ;let i =index">
                          <ng-container>
                            <td style="font-size: medium;">{{cliente.nome + " " +
                              cliente.cognome}}</td>
                            <td style="font-size: medium;">{{cliente.codiceFiscale}} 
                            </td>

                            <td class="text-right">
                              <a (click)="associate(cliente.nome,cliente.cognome,cliente.idCliente)" rel="tooltip" title="Associa gruppo"><i
                                class="material-icons notranslate pointer"
                                style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(0, 0, 105);">lock</i></a>
                              <a (click)="editCliente(cliente.idCliente)" rel="tooltip" title="Modifica assistito"><i
                                  class="material-icons notranslate pointer"
                                  style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(50, 156, 205);">create</i></a>
                              <a (click)="visualizza(cliente.idCliente)" rel="tooltip" title="Info assistito"><i
                                  class="material-icons notranslate pointer"
                                  style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(255, 217, 2);">info</i></a>
                                  <a (click)="visualizzaStorico(cliente.idCliente)" rel="tooltip" title="Info assistito"><i
                                    class="material-icons notranslate pointer"
                                    style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(153, 78, 3);">history</i></a>
                              <a (click)="cancella(cliente.idCliente)" rel="tooltip" title="Elimina assistito"><i
                                  class="material-icons notranslate pointer"
                                  style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(205, 50, 50);">delete</i></a>

                              <!-- <p-button (click)="confirm1()" icon="pi pi-check" label="Confirm"></p-button> -->
                              <!-- <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" [position]="position" [baseZIndex]="10000"></p-confirmDialog>

    <p-messages [value]="msgs"></p-messages> -->
                            </td>
                          </ng-container>

                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite"
                        style="margin-top: 5%;">{{items}} di {{maxItems}}
                        assistiti</div>
                    </div>
                    <div class="col-sm-7 text-right giustificaDestra">

                      <app-paginator *ngIf="refreshView" [pageSize]="numeroMaxPagine" [(isClickable)]="isClickable"
                        (currentPageEmitter)="cambiaPagina($event)" [startingPage]="pagina+1"></app-paginator>
                    </div>
                  </div>

                </div>
              </div>
              <!--CLIENTI CANCELLATI-->
              <div class="tab-pane" id="pill2">
                <div class="material-datatables">

                  <div class="row">
                    <div class="col-sm-3 col-xs-3">
                      <div class="dataTables_length" id="datatables_length">
                        <div style="display: flex;">
                          <label class="form-group">Limita Risultati
                            <select [(ngModel)]="selectedValueCancellati" (ngModelChange)="cambiaValueCancellati()"
                              aria-controls="datatables" class="form-control input-sm">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>

                            </select>
                            <div *ngIf="isSelectingValueCancellati" style="display: flex;align-items: end;">
                              <div class="text-center" style="position: absolute;right: 0px;">
                                <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-9 col-xs-9">
                      <div id="datatables_filter" class="dataTables_filter">
                        <form [formGroup]="searchformCancellati">
                          <label class="form-group pull-right">Esegui una ricerca
                            <input (input)="searchInClients(false)" type="text" formControlName="searchCancellati"
                              class="form-control" placeholder="Cerca..." maxlength="30" style="width: 40vw;">
                          </label>
                        </form>
                        <!-- <div *ngIf="searchInUserCancellati()"></div>   -->
                      </div>
                    </div>
                  </div>
                  <div class="scrollable">

                    <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0"
                      width="100%" style="width:100%">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Codice Fiscale</th>
                          <!-- <th>Gruppo</th> -->

                          <th class="text-right">Azioni</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th>Nome</th>
                          <th>Codice Fiscale</th>
                          <!-- <th>Gruppo</th> -->

                          <th class="text-right">Azioni</th>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr *ngFor="let cliente of clientiCancellati; let i =index;">
                          <!-- *ngIf="i>=(selectedValueCancellati*(paginaCancellati-1)) && i<(selectedValueCancellati*paginaCancellati)" -->
                          <ng-container>
                            <td style="font-size: medium;">{{cliente.nome + " " +
                              cliente.cognome}}</td>
                              <td style="font-size: medium;">{{cliente.codiceFiscale}}</td>

                            <td class="text-right">

                              <a (click)="ripristina(cliente.idCliente,i)" rel="tooltip" title="Ripristina assitito"><i
                                  class="material-icons notranslate pointer"
                                  style="color: red;">restore_from_trash</i></a>

                            </td>
                          </ng-container>

                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite"
                        style="margin-top: 5%;">{{itemsCancellati}} di {{maxItemsCancellati}}
                        assititi</div>
                    </div>
                    <div class="col-sm-7 text-right giustificaDestra">
                      <app-paginator *ngIf="refreshView" [pageSize]="numeroMaxPagineCancellate"
                        [(isClickable)]="isClickableCancellati" (currentPageEmitter)="cambiaPaginaCancellati($event)"
                        [startingPage]="paginaCancellati+1">
                      </app-paginator>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
  <!-- <div *ngIf="dettagli">
    <app-dettagli-utente [utenteInfo]="infouser" (cambia)="indietroDainfo($event)"></app-dettagli-utente>
  </div>
  <div *ngIf="modifica">
    <app-modifica-utente [utenteId]="idUtente" (tornaGestione)="indietro($event)"></app-modifica-utente>
  </div> -->
</div>

<p-confirmDialog #cd icon="pi pi-exclamation-triangle" [style]="{width:  responsiveWidth() }" [acceptLabel]="'Si'"
[baseZIndex]="5">
<p-footer>

  <button [disabled]="loading" *ngIf="cancellaCliente" type="button" pButton icon="pi pi-check" label="Sì"
    (click)="accettaCancella()">
    <span *ngIf="loading">
      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner"
        animationDuration=".5s"></p-progressSpinner>
    </span>
  </button>
  <button *ngIf="!cancellaCliente" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
  <button [disabled]="loading && cancellaCliente" type="button" pButton icon="pi pi-times" label="No"
    (click)="cd.reject()"></button>

</p-footer>
</p-confirmDialog>

<p-confirmDialog #cd2 icon="pi pi-exclamation-triangle" [key]="'ripristino'" [style]="{width: responsiveWidth()}"
[acceptLabel]="'Si'" [baseZIndex]="5">
<p-footer>

  <button [disabled]="loading" type="button" pButton icon="pi pi-check" label="Sì" (click)="accettaRipristina()">
    <span *ngIf="loading">
      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner"
        animationDuration=".5s"></p-progressSpinner>
    </span>
  </button>
  <button [disabled]="loading" type="button" pButton icon="pi pi-times" label="No"
    (click)="chiudiRipristina()"></button>

</p-footer>
</p-confirmDialog>


<div *ngIf="crea">
  <app-crea-assistito (tornaProfilo)="indietroDaCrea($event)"></app-crea-assistito>
</div>

<div *ngIf="dettagli">
<app-dettagli-assistito [clienteInfo]="infoCliente" (cambia)="indietroDainfo($event)"> </app-dettagli-assistito>
</div>

<div *ngIf="modifica">
<app-modifica-assistito [clienteId]="idCliente" (tornaGestioneCliente)="indietroDaModifica($event)"></app-modifica-assistito>
</div>

<div *ngIf="associa">
  <app-associa-gruppo [assistito]="assistito" (tornaGestioneAssistiti)="indietroDaAssocia($event)"></app-associa-gruppo>
  </div>

  <div *ngIf="infoStorico">
    <app-storico-assistito [clienteInfo]="infoCliente" (cambia)="indietroDaStorico($event)"></app-storico-assistito>
    </div>
