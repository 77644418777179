
<div id="main-div">
    <button class="btn btn-primary" (click)="connectToDevice()">Connect</button>
    <div class="tab-content clearfix p-30">
        <form *ngIf="showAllComponent">

            <div class="row flex">
                <label class="col-sm-2 label-on-left responsiveLabel">Nome</label>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <input type="text" placeholder="Nome" class="form-control" id="apname" name="fname" />
                        <!-- <div *ngIf="submittedStep1 && f.nome.errors " class="invalid-feedback">
                            <div *ngIf="f.nome.errors.required ">Campo nome obbligatorio</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row flex">
                <label class="col-sm-2 label-on-left responsiveLabel">Password   <a (click)="showPassword()" rel="tooltip" title="Modifica Dispositivo"
                    class="btn btn-simple" style="padding: 2px 2px 2px 2px;"><i
                        class="material-icons notranslate pointer" style="font-size: 20px;">{{visibility}}</i></a></label>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <input [type]="passwordType" placeholder="Password" class="form-control" id="appass" name="lname" />
                        <!-- <div *ngIf="submittedStep1 && f.nome.errors " class="invalid-feedback">
                            <div *ngIf="f.nome.errors.required ">Campo nome obbligatorio</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <label for="fname"></label><br>
            <input class="text-area" type="text" ><br>
            <label for="lname"></label><br>
            <input class="text-area" type="text" id="appass" name="lname"><br><br> -->
        </form>

    </div>

    <button *ngIf="showAllComponent" class="btn" (click)="configure()">Configura <span *ngIf="caricato"> <p-progressSpinner  [style]="{width: '15px', height: '15px'}" strokeWidth="5"
      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner></span></button>

    <div *ngIf="showAllComponent" id="status-div">

    </div>
</div>
