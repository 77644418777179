import {AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import * as L from 'leaflet';
import {Messaggio} from 'src/app/MODELS/USER/messaggio';
import {ChatbotService} from 'src/app/SERVICES/CHAT/chatbot.service';
import {ReportService} from 'src/app/SERVICES/FILE/report.service';
import {EnvService} from 'src/app/SERVICES/GENERAL/env.service';
import {ShareService} from 'src/app/SERVICES/GENERAL/share.service';
import {AccountService} from 'src/app/SERVICES/index';
import {LocaleSettings} from 'primeng/calendar';

declare var webkitSpeechRecognition: any;

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
  @ViewChild('search', {static: false}) textField: ElementRef;
  @ViewChild('chatMessageBot') chatbotMessage: ElementRef;
  noSpecial: RegExp = /[^^|\\%]+/;
  invia = '';
  idUtente = '';
  lingua = '';
  ora = new Date();
  listaMessaggi = [];
  stepPage = false;
  reactiveForm: FormGroup;
  link;
  isData;
  colore;
  reset: NodeJS.Timeout;
  mgsResetChatBot =
    'Hai impiegato troppo tempo per rispondere, la richiesta è stata resettata';
  primaVolta = true;
  listaButtons: any = [];
  elaborazioneInCorso = false;
  map;
  isVoiceSupported = false;
  isRiconoscendo = false;
  voiceMode = false;
  isParlando = false;
  disableMic = false;
  voice;
  disableSwitch = false;
  speech = window.speechSynthesis;
  isMobile = false;

  constructor(
    private formBuilder: FormBuilder,
    private chatBotService: ChatbotService,
    private service: ShareService,
    private env: EnvService,
    private router: Router,
    private accountService: AccountService,
    private reportService: ReportService,
    private det: ChangeDetectorRef,
  ) {
    if (!this.env.CHATBOT) {
      this.router.navigate(['']);
    }
    if (localStorage.getItem('path')) {
      this.isMobile = true;
    }
    if ('webkitSpeechRecognition' in window) {
      this.isVoiceSupported = true;
      this.voice = new webkitSpeechRecognition();
      this.voice.continuous = false;
      this.voice.interimResults = false;
      this.voice.lang = 'it-IT';
    }
    this.colore = localStorage.getItem('colore');
    if (localStorage.getItem('idChatbot') == this.accountService.userValue.data.id) {
      if (localStorage.getItem('chatbot')) {
        this.listaMessaggi = JSON.parse(localStorage.getItem('chatbot'));
        this.listaMessaggi.forEach(element => {
          let data = new Date(element.dataMessaggio);
          element.dataMessaggio = data;
          element.mappa = false;
        });
      }
    } else {
      let messaggio = new Messaggio();
      messaggio.messaggio =
        'Ciao, il mio nome è H.E.R.B.I.E. \nSe non sai cosa chiedermi scopri tutte le mie funzionalità scrivendo "Comandi"';
      messaggio.idUtente = 'chatbot';
      messaggio.dataMessaggio = this.ora;
      messaggio.listaButtons = [];
      let bottone = {text: 'Comandi', link: 'Comandi', attiva: true, type: '2'};
      messaggio.listaButtons.push(bottone);
      this.listaMessaggi.push(messaggio);
    }
    this.reactiveForm = this.formBuilder.group({
      Domanda: ['', []],
      Data: [''],
    });
    this.stepPage = true;
  }


  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    let ultimimessaggi = [];
    if (this.listaMessaggi.length > 1) {
      ultimimessaggi.push(this.listaMessaggi[this.listaMessaggi.length - 2]);
    }
    ultimimessaggi.push(this.listaMessaggi[this.listaMessaggi.length - 1]);
    localStorage.setItem('chatbot', JSON.stringify(ultimimessaggi));
    localStorage.setItem('idChatbot', JSON.stringify(this.accountService.userValue.data.id));
    if (this.isParlando) {
      this.speech.cancel();
    }
  }

  ngDoCheck(): void {
    this.ora = new Date();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }


  condizioni(m) {
    this.disableSwitch = true;
    this.chatBotService.messageService(m).subscribe((res) => {
      this.primaVolta = false;
      this.isData = false;
      // this.isMappa = false;
      // QUA NUOVI MESSAGGI POSSIAMO FORMATTARE
      let msg = res.data as string;
      // Verificare se il messaggio del chatbot è di reset
      if (msg == this.mgsResetChatBot) {
      } else {
        this.startResetTimer();
      }
      this.reactiveForm.enable();
      if (this.textField) {
        setTimeout(() => this.textField.nativeElement.focus());
      }
      let messaggio = new Messaggio();
      messaggio.idUtente = 'chatbot';
      messaggio.dataMessaggio = this.ora;
      // pezziMsg[0] = Token identificativo
      // pezziMsg[1||2] = Dato effettivo
      // pezziMsg[2...] = Dati aggiuntivi come la risposta all'utente
      let pezziMsg = msg.split('--');
      if (pezziMsg.length <= 1) {
        messaggio.messaggio = res.data;
        this.listaMessaggi.push(messaggio);
      } else {
        switch (pezziMsg[0]) {
          case 'DATA':
            // pezziMsg[1] messaggio
            this.isData = true;
            messaggio.messaggio = !this.isMobile ? pezziMsg[1] : pezziMsg[1] + ' nel seguente formato: "gg/mm/aaaa"';
            this.listaMessaggi.push(messaggio);
            break;
          case 'FILEDMS':
            // pezziMsg[1] = id documento
            // pezziMsg[2] = Risposta all'utente
            this.link = this.env.apiUrl + '/elastic/download_doc?id=' + pezziMsg[1];
            window.open(this.link);
            let risposta = pezziMsg[2];
            messaggio.messaggio = risposta;
            this.listaMessaggi.push(messaggio);
            break;
          case 'MAP':
            this.listaMessaggi.forEach((messaggio) => {
                messaggio.mappa = false;
              }
            );
            messaggio.messaggio = pezziMsg[1];
            messaggio.mappa = true;
            let listaCoordinate = [];
            for (let i = 2; i < pezziMsg.length - 1; i += 3) {
              if (pezziMsg[i + 1] != '91,181') {
                this.link = 'https://www.google.it/maps/search/?api=1&query=' + pezziMsg[i + 1];
                listaCoordinate.push(pezziMsg[i + 1]);
                messaggio.messaggio += pezziMsg[i] + pezziMsg[i + 2];
              } else {
                messaggio.messaggio += pezziMsg[i];
              }
            }
            this.listaMessaggi.push(messaggio);
            setTimeout(() => {
              if (listaCoordinate) {
                this.initMap();
                for (let coordinate of listaCoordinate) {
                  let coordinateSplit = coordinate.split(',');
                  this.map.setView(L.latLng(coordinateSplit[0], coordinateSplit[1]), 13);
                  let markerHtmlStyles = `
                  color: #0099F3;
                  font-size: xx-large;
                  display: block;
                  left: -1.5rem;
                  top: -1.5rem;
                  position: relative;
                  -webkit-text-stroke: 1px black;
                  `;
                  let smallIcon = L.divIcon({
                    className: 'my-custom-pin',
                    iconAnchor: [0, 24],
                    popupAnchor: [0, -36],
                    html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`
                  });
                  L.marker([coordinateSplit[0], coordinateSplit[1]], {icon: smallIcon}).addTo(this.map);
                }
              }
            }, 1000);

            break;
          case 'REDIRECT':
            // pezziMsg[1] = link
            // pezziMsg[2...] = messaggi

            this.link = pezziMsg[1];
            this.router.navigate([this.link]);
            messaggio.messaggio = pezziMsg[2];
            this.listaMessaggi.push(messaggio);
            break;
          case 'LINK':
            // pezziMsg[1] messaggio
            // pezziMsg[2] token
            // pezziMsg[3-5-7...] nomeLink
            // pezziMsg[4-6-8...] link
            messaggio.messaggio = pezziMsg[1];
            messaggio.listaButtons = [];
            if (pezziMsg[2] === '1') {
              this.reactiveForm.disable();
            }
            for (let i = 3; i < pezziMsg.length - 1; i += 2) {
              let bottone = {text: pezziMsg[i], link: pezziMsg[i + 1], attiva: true, type: pezziMsg[2]};
              messaggio.listaButtons.push(bottone);
              // this.addButton(Number(pezziMsg[2]), pezziMsg[i], pezziMsg[i + 1]);
            }
            this.listaMessaggi.push(messaggio);
            break;
          case 'REPORT':
            switch (pezziMsg[1]) {
              case 'ASSISTENZA':
                this.reportService.ottieniReportAssistenza(messaggio, pezziMsg);
                this.listaMessaggi.push(messaggio);
                break;
              case 'GEOLOCALIZZAZIONE':
                this.reportService.ottieniReportGeolocalizzazione(messaggio, pezziMsg);
                this.listaMessaggi.push(messaggio);
                break;
              case 'AULAVIRTUALE':
                this.reportService.ottieniReportAula(messaggio, pezziMsg, this.env.alfaintes);
                this.listaMessaggi.push(messaggio);
                break;
              case 'IOT':
                this.reportService.ottieniReportIot(messaggio, pezziMsg);
                this.listaMessaggi.push(messaggio);
                break;
            }
        }
      }
      if (this.voiceMode) {
        if (this.isParlando) {
          this.speech.cancel();
          this.parla(this.listaMessaggi[this.listaMessaggi.length - 1].messaggio);
        } else {
          this.parla(this.listaMessaggi[this.listaMessaggi.length - 1].messaggio);
        }
        this.disableMic = false;
        this.det.detectChanges();
      }
      this.disableSwitch = false;
    });
  }

  submitForm() {
    let messaggio = new Messaggio();
    messaggio.idUtente = 'tu';
    if (this.reactiveForm.value.Domanda) {
      messaggio.messaggio = this.reactiveForm.value.Domanda;
    } else if (this.reactiveForm.value.Data) {
      let data = this.reactiveForm.value.Data.split('-');
      messaggio.messaggio = data[2] + '/' + data[1] + '/' + data[0];
    }

    messaggio.dataMessaggio = this.ora;
    if (messaggio.messaggio.replace(/\s/g, '').length) {
      this.listaMessaggi.push(messaggio);
      this.condizioni(messaggio.messaggio);
      this.reactiveForm.reset();
      this.reactiveForm.disable();
    }
  }

  submitButton(listaButtons: any[], link: string) {
    this.elaborazioneInCorso = true;
    let messaggio = new Messaggio();
    messaggio.idUtente = 'tu';
    messaggio.messaggio = link;
    messaggio.dataMessaggio = this.ora;
    this.listaMessaggi.push(messaggio);
    this.condizioni(messaggio.messaggio);
    this.reactiveForm.reset();
    this.reactiveForm.disable();
    if (this.voiceMode) {
      this.disableMic = true;
    }
    if (this.isParlando) {
      this.speech.cancel();
    }
    for (let button of listaButtons) {
      button.attiva = false;
    }
    setTimeout(() => {
      this.elaborazioneInCorso = false;
    }, 5000);
    this.det.detectChanges();
  }

  chiudi() {
    this.stepPage = !this.stepPage;
  }

  close() {
    this.service.changeData(false);
  }

  cambiaPagina() {
    this.router.navigate([this.link]);
  }

  // StartResetTimer
  /**
   * Il metodo inizia o resetta il timer, questo timer quando si attiva richiama il metodo resetRequest
   * @see resetRequest
   */
  startResetTimer() {
    clearTimeout(this.reset);
    this.reset = setTimeout(() => {
      this.resetRequest();
    }, 300000); // 5min 300s*1000=300000 ms
  }

  /**
   * Questo metodo invia un messaggio nascosto all'utente, nel senso che non viene scritto sulla scheda della chat.
   * Il messaggio servirà lato BE a cancellare lo stato della richiesta al chatBot e inoltre permetterà quest'ultimo
   * di avvertire l'utente di ripetere
   */
  resetRequest() {
    this.listaMessaggi.filter(messaggio => messaggio.listaButtons).forEach(messaggio => {
      messaggio.listaButtons.forEach(bottone => {
        bottone.attiva = false;
      });
    });
    this.condizioni('RESETCHATBOT');
  }

  initMap(): void {
    this.map = L.map('mapChatbot', {
      center: [39.8282, -98.5795],
      zoom: 3,
      zoomControl: false
    });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
    });

    tiles.addTo(this.map);
  }


  voiceRecognition() {
    if (!this.isRiconoscendo) {
      this.voice.start();
      this.isRiconoscendo = true;
    } else {
      this.voice.stop();
      this.isRiconoscendo = false;
    }
    if (this.isParlando) {
      this.speech.cancel();
    }
    this.voice.onresult = (e) => {
      let messaggio = new Messaggio();
      messaggio.idUtente = 'tu';
      messaggio.dataMessaggio = this.ora;
      let trascrizione = e.results[0][0].transcript;
      // tslint:disable-next-line:max-line-length
      trascrizione = trascrizione.substring(trascrizione.length - 1) === '.' ? trascrizione.substring(0, trascrizione.length - 1) : trascrizione;
      if (this.isRiconoscendo) {
        this.voice.stop();
        this.isRiconoscendo = false;
      }
      messaggio.messaggio = trascrizione;
      this.disableMic = true;
      this.pushMessage(messaggio);
    };
    this.voice.onerror = (e) => {
      this.voice.stop();
      this.isRiconoscendo = false;
      this.det.detectChanges();
    };
  }

  pushMessage(messaggio: Messaggio) {
    this.listaMessaggi.push(messaggio);
    this.condizioni(messaggio.messaggio);
    this.det.detectChanges();
  }

  parla(testo: string) {
    if (this.isParlando) {
      this.speech.cancel();
    }
    this.isParlando = true;
    setTimeout(() => {
      const voices = this.getVoices();

      let utter = new SpeechSynthesisUtterance(testo);
      // Da capire come fare in base al design applicato
      utter.voice = voices[2];
      this.speech.speak(utter);
      utter.onend = (e) => {
        this.isParlando = false;
      };
    }, 10);
  }

  getVoices() {
    const voices = this.speech.getVoices();
    let italianVoices = [];
    voices.forEach(voice => {
      if (voice.lang === 'it-IT') {
        italianVoices.push(voice);
      }
    });
    return italianVoices;
  }

  changeMode() {
    this.voiceMode = !this.voiceMode;
  }

  // staticSpeaking() {
  //   if(ChatbotComponent.stopSpeaking){
  //     this.reactiveForm.controls.Domanda.setValue(ChatbotComponent.testo)
  //     ChatbotComponent.stopSpeaking = false;
  //     clearInterval(this.intervallo)
  //   }
  //   return ChatbotComponent.speaking;
  // }


  /**
   * Questa funzione aggiunge un bottone
   * @param text Testo da far apparire al bottone
   * @param link Link per reindirizzare
   */
  // addButton(token: number, text: string, link: string) {
  //   const button = this.renderer.createElement('a');
  //   const buttonText = this.renderer.createText(text);
  //   this.renderer.setAttribute(button, 'class', 'float-button');
  //   this.renderer.appendChild(button, buttonText);
  //   if (token === 1) {
  //     this.renderer.setAttribute(button, 'href', link);
  //     this.renderer.listen(button, 'click', () => {
  //       let myDiv = document.getElementById("float-buttons");
  //       myDiv.innerHTML = "";
  //     });
  //   } else {
  //     this.renderer.listen(button, 'click', () => {
  //       this.submitButton(link);
  //       let myDiv = document.getElementById("float-buttons");
  //       myDiv.innerHTML = "";
  //     });
  //   }
  //   document.querySelector('#form').addEventListener('submit', () => {
  //     this.renderer.removeChild(this.chatbotMessage.nativeElement, button);
  //   });
  //   this.renderer.appendChild(this.chatbotMessage.nativeElement, button);

  // }
  // canDeactivate(): Observable<boolean> | Promise<boolean> | boolean  {
  //   return true;
  // }
}
