export class Sessione {
    inizio_sessione?: string;
    fine_sessione?: string;
    operatore?: string;
    nome?: string;
    cognome?: string;
    ruolo?: string;
    gruppo?: string;
    latitudine?: string;
    longitudine?: string;
    motivo_chiamata?: string;
    dettaglio?: string;
    durata?: string;
}