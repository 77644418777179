<p-accordion *ngIf="inAccordion">
  <p-accordionTab [header]="titleAccordion" class="ta-center">
    <div cdkDropListGroup class="pd-bottom">
      <div class="example-container">
        <h3>{{nomeLista1}}</h3>

        <mat-form-field class="w-100 ">
          <input matInput [placeholder]="'Cerca'" [(ngModel)]="searchValueList1">
        </mat-form-field>
        <div
          cdkDropList
          [cdkDropListData]="lista1"
          class="example-list "
          (cdkDropListDropped)="drop($event)">
          <ng-container *ngFor="let item of lista1, let i = index">
            <div (click)="switchArray(i)" class="example-box" *ngIf="item[nomeParametro] && item[nomeParametro].toLowerCase().includes(searchValueList1.toLowerCase())" cdkDrag>{{item[nomeParametro]}}</div>
          </ng-container>
        </div>
      </div>

      <div class="example-container">
        <h3>{{nomeLista2}}</h3>

        <mat-form-field class="w-100">
          <input matInput [placeholder]="'Cerca'" [(ngModel)]="searchValueList2">
        </mat-form-field>
        <div
          cdkDropList
          [cdkDropListData]="lista2"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <ng-container *ngFor="let item of lista2, let i = index">
            <div (click)="switchArrayInverse(i)"class="example-box" *ngIf="item[nomeParametro] && item[nomeParametro].toLowerCase().includes(searchValueList2.toLowerCase())" cdkDrag>{{item[nomeParametro]}}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<div cdkDropListGroup *ngIf="!inAccordion" >
  <div class="example-container ">
    <h3>{{nomeLista1}}</h3>

    <mat-form-field class="w-100">
      <input matInput [placeholder]="'Cerca'" [(ngModel)]="searchValueList1">
    </mat-form-field>
    <div
      cdkDropList
      [cdkDropListData]="lista1"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let item of lista1, let i = index">
        <div (click)="switchArray(i)" class="example-box" *ngIf="item[nomeParametro] && item[nomeParametro].toLowerCase().includes(searchValueList1.toLowerCase())" cdkDrag>{{item[nomeParametro]}}</div>
      </ng-container>
    </div>
  </div>

  <div class="example-container fRight ">
    <h3>{{nomeLista2}}</h3>

    <mat-form-field class="w-100">
      <input matInput [placeholder]="'Cerca'" [(ngModel)]="searchValueList2">
    </mat-form-field>
    <div
      cdkDropList
      [cdkDropListData]="lista2"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let item of lista2, let i = index">
        <div (click)="switchArrayInverse(i)"class="example-box" *ngIf="item[nomeParametro] && item[nomeParametro].toLowerCase().includes(searchValueList2.toLowerCase())" cdkDrag>{{item[nomeParametro]}}</div>
      </ng-container>
    </div>
  </div>
</div>

