import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Cliente } from 'src/app/MODELS/USER/cliente';
import { AccountService, AlertService, EnvService } from 'src/app/SERVICES';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';

@Component({
  selector: 'app-gestione-clienti',
  templateUrl: './gestione-clienti.component.html',
  styleUrls: ['./gestione-clienti.component.scss']
})
export class GestioneClientiComponent implements OnInit {
  dettagli = false;
  modifica = false;
  crea=false;
  gestione=true
caricato=true;
carica
idCliente;
infoCliente;
searchformCancellati: FormGroup;
appoggioclick;
appoggioclickCancellati;
listaClienti:Cliente[];
allListaClienti:Cliente[];
numeroMaxPagine: number;
numeroMaxPagineCancellate: number;
maxItems;
maxItemsCancellati;
itemsCancellati;
items;
isClickableCancellati = true;
paginatorCancellati = true;
paginator = true;
cancellaCliente=false;
loading=false;
pagina;
valoreRicerca;
subscriptionListaClienti:Subscription
vediCancellati = false;
vediClienti = true;
isSearchingOn = false;
selectedValue;
isSelectingValue = false;
isClickable = true;
refreshView = true;
isSelectingValueCancellati = false;
showTable = true;
clientiCancellati: Cliente[];
allClientiCancellati:Cliente[];
selectedValueCancellati;
paginaCancellati;
idRipristina;
searchform:FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private clienteService:ClienteService,
    private confirmationService: ConfirmationService,
    public dialog: MatDialog,
    private primengConfig: PrimeNGConfig,
    private alertService:AlertService,
    private routing:Router,
    private accountService: AccountService,
    private envService:EnvService) { 

      this.pagina = 0;
      this.paginaCancellati = 0;
      this.selectedValue = 10; //tecnici da visualizzare per pagina
      this.selectedValueCancellati = 10;
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
      this.chiamataPaginata(this.pagina, this.selectedValueCancellati, false)
      this.searchform = this.formBuilder.group({
        search: ['', Validators.required]
      })
      this.searchformCancellati = this.formBuilder.group({
        searchCancellati: ['', Validators.required]
      })
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  ngDoCheck(): void {
    if (!this.envService.CLIENTI) {
      this.routing.navigate(['./'])
    }
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    if(attivo)
    {
      this.subscriptionListaClienti = this.clienteService.getAll(quantità, pagina, ricerca).subscribe((res) => {

      if (attivo == true) {
        if (res.content.length > 0) {
          this.listaClienti = res.content;
        } else {
          this.listaClienti = []
        }
        this.allListaClienti = this.listaClienti;
        this.numeroMaxPagine = Number(res.totalPages);
        this.maxItems = res.totalElements;
        this.items = res.numberOfElements

        this.carica = true;
        this.caricato = true;
        this.isClickable = true;
        this.isClickableCancellati = true;
        this.paginatorCancellati = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.isSelectingValueCancellati = false;
        this.refreshView = true;
        this.showTable = true;
      }
    })
    }
      else
      {
        //cestino
        this.clienteService.getAllEliminati(quantità, pagina, ricerca).subscribe((res)=>
        {

          
         if (res.data.content.length > 0) {
           this.clientiCancellati = res.data.content;
         } else {
           this.clientiCancellati = []
         }
         
         this.allClientiCancellati = this.clientiCancellati
         this.numeroMaxPagineCancellate = Number(res.data.totalPages)
         this.maxItemsCancellati = res.data.totalElements;
         this.itemsCancellati = res.data.numberOfElements;

         this.carica = true;
         this.caricato = true;
         this.isClickable = true;
         this.isClickableCancellati = true;
         this.paginatorCancellati = true;
         this.paginator = true;
         this.isSearchingOn = false;
         this.isSelectingValue = false;
         this.isSelectingValueCancellati = false;
         this.refreshView = true;
         this.showTable = true;
        }
        )
       
      }

}


  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  cambiaPaginaCancellati(event) {
    this.isClickableCancellati = false;
    this.paginaCancellati = event;
    this.paginator = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
    }
  }

  cambiaValueCancellati() {
    this.paginaCancellati = 0
    this.isClickableCancellati = false;
    this.paginator = false;
    this.isSelectingValueCancellati = true;
    this.refreshView = false;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
    }
  }

  searchInClients(event){
    this.valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionListaClienti) {
      this.subscriptionListaClienti.unsubscribe()
    }
    this.isSearchingOn = true;
    if (event) {
      this.valoreRicerca = this.searchform.value.search
    } else {
      this.valoreRicerca = this.searchformCancellati.value.searchCancellati;
    }
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, event, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, event)
    }
  }
  
cancella(id) {
  this.cancellaCliente = true;
  this.idCliente = id;
  this.confirmationService.confirm({
    message: 'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
    icon: 'pi pi-exclamation-triangle',
    reject: () => {
      this.cancellaCliente = false;
      this.loading = false;
      this.confirmationService.close();
    }
  });
}



   //METODO PER PASSARE DALLA GESTIONE CLIENTI AL VISUALIZZA INFO DI UN CLIENTE
 visualizza(id: string) {
  this.infoCliente = id;
  this.gestione = false;
  this.dettagli = true;
}

  vediInfo(id) {
    this.idCliente = id;
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }


  indietroDainfo(vai) {
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }


  editCliente(id?){
    this.idCliente=id;
    this.gestione=false;
    this.modifica=true;
  }

  chiudiRipristina() {
    this.confirmationService.close();
    this.loading = false;
  }

  ripristina(id,indice) {
    this.idRipristina = id;
    
    this.confirmationService.confirm({
      message: 'Ripristinare il cliente?',
      key: 'ripristino',
      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        
        this.confirmationService.close();
        this.loading = false;
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      }
    });
  }
  
  listaCancellati() {
    this.vediClienti = false;
    this.vediCancellati = true;
  }

  listaPresenti() {
    this.vediClienti = true;
    this.vediCancellati = false;
  }

  accettaCancella() {
    this.paginator = false;
    this.valoreRicerca = '';
    this.searchform.setValue({ search: '' })
    this.paginatorCancellati = false;

    
    this.clienteService.deleteCliente(this.idCliente, true).subscribe(() => {

      
      if (this.listaClienti.length === 1) {
        this.refreshView = false;
      }
      if (this.listaClienti.length == 0) {
        if (this.pagina == 1) {
          this.pagina = this.pagina - 1
          this.chiamataPaginata(this.pagina, this.selectedValue, true)
        } else {
          this.chiamataPaginata(this.pagina, this.selectedValue, true)
        }
      } else {
        this.chiamataPaginata(this.pagina, this.selectedValue, true)
      }
      if (this.clientiCancellati.length == 0) {
        if (this.paginaCancellati == 1) {
          this.paginaCancellati = this.paginaCancellati - 1
          this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
        } else {
          this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
        }
      } else {
        this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
      }
      if (this.clientiCancellati.length == 0) {
        if (this.paginaCancellati == 1) {
          this.paginaCancellati = this.paginaCancellati - 1
          this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
        } else {
          this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
        }
      } else {
        this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
      }
      this.confirmationService.close();
      this.loading = false;

    });

}

accettaRipristina() {
    this.valoreRicerca = ''
    this.searchformCancellati.setValue({ searchCancellati: '' })
    this.clienteService.deleteCliente(this.idRipristina, false).subscribe((res) => {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValue, false)
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
      this.confirmationService.close();
      this.alertService.success(res.status.description);
      this.loading = false;
    })
  }
responsiveWidth() { }

aggiungiCliente()
  {
    this.idCliente = null;
    this.gestione = false;
    this.crea = true;
  }

  indietroDaModifica(vai){

    this.chiamataPaginata(this.pagina, this.selectedValue, true);
    this.gestione = true;
    this.modifica = false;
  }

  indietroDaCrea(vai){

    this.chiamataPaginata(this.pagina, this.selectedValue, true);
    this.gestione = true;
    this.crea = false;
  }
}
