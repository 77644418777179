import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Id_gruppo } from 'src/app/MODELS/USER/id_gruppo';
import { ruolo } from 'src/app/MODELS/USER/ruolo';
import { Utente } from 'src/app/MODELS/USER/utente';
import { UsersService, AlertService, ShareService, EnvService } from 'src/app/SERVICES';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-modifica-utente-profilo',
  templateUrl: './modifica-utente-profilo.component.html',
  styleUrls: ['./modifica-utente-profilo.component.scss']
})
export class ModificaUtenteProfiloComponent implements OnInit {

  @Output() tornaGestione = new EventEmitter<boolean>();
  @Output() tornaProfilo = new EventEmitter<boolean>();
  @Input() utenteId;

  ruoli: SelectItem[];
  gruppi: SelectItem[];
  id;
  user;
  msgs: Message[] = [];
  submitted = false;
  editForm: FormGroup;
  listaRuoli = [];
  listaGruppi = [];
  modificaUtenteProfilo: boolean = false;
  loading = false;
  alfaintes = false
  alfaCall = false
  listaCarica = []
  listaAttivita = []
  idUtente
  consenso
  indexAttivita
  indexCarica
  utenteRegistratoView = false;
  listaStrutture =[];
  tag;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private service: ShareService,
    private primengConfig: PrimeNGConfig,
    private env: EnvService
  ) {
    this.alfaintes = env.alfaintes;
    this.alfaCall = env.ALFACALLCENTER;
    this.ruoli = [];
    this.gruppi = [];
    // this.editForm = this.formBuilder.group({

    //   nome: ['', Validators.required],
    //   cognome: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    //   recapito: ['', Validators.required],
    //   ruolo: ['', Validators.required],
    //   gruppo: [''],

    // });
    this.userService.getListaRuoliModifica(this.id).subscribe((res) => {
      this.listaRuoli = res.data;

      this.listaRuoli.forEach(ruolo => {
        this.ruoli.push(
          { label: ruolo.nomeRuolo, value: ruolo.id }
        )

      })

      this.userService.getListaGruppi().subscribe((res) => {
        this.listaGruppi = res.data;
        this.listaGruppi.forEach(gruppo => {
          this.gruppi.push(
            { label: gruppo.nomeGruppo, value: gruppo.id }
          )
        });
        this.modificaUtenteProfilo = true;
      }, (error) => {

      });

    }, (error) => {

    });

  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.id = this.utenteId

    if(!this.alfaintes || this.alfaCall){


    // this.userService.getListaRuoliModifica(this.id).subscribe((res) => {//recupera ruoli

    //   this.listaRuoli = res.data;
    //   this.listaRuoli.forEach(ruolo => {
    //     this.ruoli.push(
    //       { label: ruolo.nomeRuolo, value: ruolo.id }
    //     )
    //   })
    //   this.userService.getListaGruppi().subscribe((res) => {//recupera gruppi
    //     this.listaGruppi = res.data;
    //     this.listaGruppi.forEach(gruppo => {
    //       this.gruppi.push(
    //         { label: gruppo.nomeGruppo, value: gruppo.id }
    //       )
    //     }); this.modificaUtenteProfilo = true;
    //   }, (error) => {

    //   });
    // }, (error) => {

    // });



    this.userService.info(this.utenteId).subscribe((res) => {//recupera utente
      this.user = res.data;
      let gruppos = [];
      this.user.gruppos.forEach(gruppo => {
        let id;
        id = gruppo.id;
        gruppos.push(id);

      });

      this.editForm = this.formBuilder.group({//presetta form

        nome: [this.user.nome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
        cognome: [this.user.cognome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
        email: [this.user.email, [Validators.required, Validators.email]],
        recapito: [this.user.telefono,],
        ruolo: [this.user.ruolo.id, Validators.required],
        gruppo: [gruppos],
        descrizione: [this.user.dettaglio[0]?.dettaglioAggiuntivo],
        tag: [this.user.tag?.nome]
      });
    })
  }
// ------
    if (this.alfaintes && !this.alfaCall) {
      //chimate Liste
      this.idUtente = this.utenteId
      
      /*this.userService.lista_carica().subscribe((res) => {
        this.listaCarica = res.data;
      })
      this.userService.lista_attivita().subscribe((res) => {
        this.listaAttivita = res.data;
      })
      this.userService.lista_strutture_afi().subscribe((res)=>{
        this.listaStrutture=res.data;
      }) */

      this.userService.get_user_afi(this.utenteId).subscribe((res) => {

        this.user = res.data;
        let gruppos = [];

         if (this.user.gruppos.length > 0) {
          this.user.gruppos.forEach(gruppo => {
            let id;
            id = gruppo.id;
            gruppos.push(gruppo.id);

          });
        }
       /* let utenteRegistrato = false;
        if (res.data.afi) {
          if(this.user.afi.attivita){
          this.indexAttivita = this.user.afi.attivita.id - 1;
          }
          if(this.user.afi.carica){
          this.indexCarica = this.user.afi.carica.id - 1;
          }
          if (res.data.afi.consenso == 1) {
            this.consenso = true;
            this.utenteRegistratoView = true;
          } else {
            this.consenso = false;
          }
          if (res.data.afi.utenteRegistrato == 1) {
            utenteRegistrato = true;
          } else {
            utenteRegistrato = false;
          }
        } */

        this.editForm = this.formBuilder.group({
          nome: [this.user.nome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          cognome: [this.user.cognome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          email: [this.user.email, [Validators.required, Validators.email]],
          recapito: [this.user.telefono],
          ruolo: [this.user.ruolo.id, Validators.required],
          gruppo: [gruppos],
          descrizione: [this.user.dettaglio[0]?.dettaglioAggiuntivo],
          //carica: [this.user.afi?.carica],
          //attivita: [this.user.afi?.attivita],
          //isf: [this.user.afi?.isf, [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          //struttura: [this.user.afi?.idStruttura,[Validators.required]],
          //consenso: [this.consenso],
          //idmedico: [this.user.afi?.idMedico,[Validators.pattern("([0-9])+"),Validators.max(2147483647)]],
          //utenteRegistrato: [utenteRegistrato],
          tag: [this.user.tag?.nome]
        })
      })
    }
    this.service.idModifica$.subscribe(res => this.utenteId = res);
  }

  get f() { return this.editForm.controls; }

  @HostListener('window:beforeunload')
  onEdit() {

    this.submitted = true;
    if (this.editForm.invalid) {
      this.loading = false;
      return;
    }
    this.loading = true;
    if (this.alfaintes && !this.alfaCall) {
      /* let consenso;
      let utenteRegistrato;

      if (this.editForm.value.consenso == true) {
        consenso = 1;
        if (this.editForm.value.utenteRegistrato == true) {
         utenteRegistrato = 1;
        } else {
         utenteRegistrato = 0;
          }
      } else {
        consenso = 0;
        utenteRegistrato = 0;
      } */

      let ruolos: ruolo = {};
      ruolos.id = this.editForm.value.ruolo;;
      let gruppos = [];
      this.editForm.value.gruppo.forEach(gruppo => {
        gruppos.push({nomeGruppo:gruppo.nomeGruppo ,id:gruppo,descrizioneGruppo:gruppo.descrizioneGruppo});
      })
      let userAlfaItes = {
        nome: this.editForm.value.nome,
        cognome: this.editForm.value.cognome,
        email: this.editForm.value.email,
        telefono: this.editForm.value.recapito,
        ruolo: this.editForm.value.ruolo,
        listaGruppi: gruppos,
        //idCarica: this.editForm.value?.carica?.id,
        //idAttivita: this.editForm.value?.attivita?.id,
        //isf: this.editForm.value.isf,
        //idStruttura: this.editForm.value.struttura,
        //consenso: consenso,
        //idMedico: this.editForm.value.idmedico,
        dettagliAggiuntivi: this.editForm.value.descrizione,
        //utenteRegistrato: utenteRegistrato,
        tag: this.editForm.value.tag?.nome,
      }
      this.userService.modifica_afi(userAlfaItes, this.idUtente).subscribe((res) => {
        this.alertService.success(res.status.description);
        this.tornaGestione.emit(true)
        this.loading = false;
      }, (error) => {
        this.loading = false;
      })

    } else {
    let ruolos: ruolo = {};
    ruolos.id = this.editForm.value.ruolo;
    let gruppos: [Id_gruppo] = [{}];

    this.editForm.value.gruppo.forEach(gruppo => {
      let group: Id_gruppo = {};
      group.id = gruppo;
      gruppos.push(group);

    });

    let utente: Utente = {

      nome: this.editForm.value.nome,
      cognome: this.editForm.value.cognome,
      email: this.editForm.value.email,
      telefono: this.editForm.value.recapito,
      ruolo: ruolos,
      gruppos: gruppos,
      tag: this.editForm.value.tag?.nome

    }
    let descrizione = this.editForm.value.descrizione;
    this.userService.update(utente, this.id, descrizione).subscribe((res) => {
      this.alertService.success(res.status.description);
      this.tornaGestione.emit(true)
      this.loading = false;

    }, (error) => {
      this.loading = false;
    });
    //this.tornaGestione.emit(true);//dopo salvato torna a gestione profili
  }
  }
  cancella() {

    this.editForm.reset();
    this.editForm.controls.gruppo.setValue([])  }

  indietro(vai) {//torna gestione profili

    this.tornaGestione.emit(vai);

  }
  /*clickListaAttivita(e) {
    if (e.index == this.indexAttivita) {
      this.editForm.controls.attivita.setValue(null)
      this.indexAttivita = null;
    } else {
      this.indexAttivita = e.index;
    }
  }
  clickListaCarica(e) {
    if (e.index == this.indexCarica) {
      this.editForm.controls.carica.setValue(null)
      this.indexCarica = null;
    } else {
      this.indexCarica = e.index;
    }
  }
  checkOn() {
    setTimeout(() => {
      if (this.editForm.value.consenso) {
        this.utenteRegistratoView = true;
      } else {
        this.utenteRegistratoView = false;
      }
    });

  } */
}

