import { Component, Input, Output, OnInit, EventEmitter, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})

export class PaginatorComponent implements OnInit {

  @Input() pageSize = 0;
  @Input() isClickable:boolean = true;
  @Input() startingPage = 1;
  @Input() notVisibile = true;

  @Output() currentPageEmitter = new EventEmitter<number>();
  @Output() isClickableChange = new EventEmitter<boolean>();

  currentPage = 1;
  colore:String;
  larghezzaSchermo:number

  constructor() {
    this.colore=environment.colore;
  }

  ngOnInit(): void {
    if (this.pageSize >= this.startingPage && this.startingPage > 0) {
      this.currentPage = this.startingPage
    }
    this.larghezzaSchermo = window.innerWidth;
  }

  @HostListener("window:resize",["$event"])
  onResize(event){
    this.larghezzaSchermo=window.innerWidth;
  }

  changePage(page) {
    if(page > 0 && page <= this.pageSize && this.isClickable) {
      this.currentPage = page;
      this.currentPageEmitter.emit(this.currentPage-1);
    }
  }
}
