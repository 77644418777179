
<div id="top"></div>
    <div class="mobileVisionCreate">
        <div style="min-height: 339px;">
            <div>
            <div class="card-title">
                <button (click)="goBack()" id="back-button" type="button" class="btn btn-round  btn-just-icon bottoneBack"
                    >
                    <i class="material-icons notranslate">arrow_back</i>
                </button>
                <!-- step1 -->
                <h4 *ngIf="step1" class="lineDisplay">Scelta template</h4>
                <!-- step2 -->
                <h4 *ngIf="step2" class="lineDisplay">Scelta contenuti</h4>
                <!-- step3 -->
                <h4 *ngIf="step3" class="lineDisplay">Scelta date</h4>
                <!-- step4 -->
                <h4 *ngIf="step4" class="lineDisplay">Invio</h4>
            </div>
            <div id="formwizard_validation" class="form-wizard form-wizard-horizontal">
                <form class="form floating-label form-validation" role="form" novalidate="novalidate">
                </form>
                <div class="form-wizard-nav">
                    <div class="progress" id="respoBar" style="width: 100%;">
                        <div class="progress-bar progress-bar-primary"
                            [ngClass]="{'primo' : step1,'secondo' : step2, 'terzo' : step3, 'quarto' : step4}"></div>
                    </div>
                    <ul class="nav nav-justified nav-pills" id="respo">
                        <li><a [ngClass]="{'disabilita': !figlioCaricato}" (click)="goStep1()" data-toggle="tab"><span class="step"
                                    [ngClass]="{'attiva' : !step1,'disattiva' : step1}">1</span> <span class="title"
                                    [ngClass]="{'attivaTitolo' : !step1,'disattivaTitolo' : step1}">Scelta
                                    template</span></a>
                        </li>
                        <li><a [ngClass]="{'disabilita': !figlioCaricato || step2}"  (click)="goStep2()" data-toggle="tab"><span class="step"
                                    [ngClass]="{'attiva' : !step2,'disattiva' : step2}">2</span> <span class="title"
                                    [ngClass]="{'attivaTitolo' : !step2,'disattivaTitolo' : step2}">Scelta
                                    contenuti</span></a>
                        </li> 
                        <li><a [ngClass]="{'disabilita': !figlioCaricato}" (click)="goStep3()" data-toggle="tab"><span class="step"
                                    [ngClass]="{'attiva' : !step3,'disattiva' : step3}">3</span> <span class="title"
                                    [ngClass]="{'attivaTitolo' : !step3,'disattivaTitolo' : step3}">Scelta
                                    Date</span></a>
                                
                        </li>
                        <li><a [ngClass]="{'disabilita': !figlioCaricato}" (click)="goStep4()" data-toggle="tab"><span class="step"
                                    [ngClass]="{'attiva' : !step4,'disattiva' : step4}">4</span> <span class="title"
                                    [ngClass]="{'attivaTitolo' : !step4,'disattivaTitolo' : step4}">Invio</span></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div  *ngIf="step2 && !figlioCaricato" style="background-color: rgb(255, 255, 255); position: absolute; width: 98%; height:85%; z-index: 4;"  > 
   <!--  -->
                <div class="text-center" >
                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
            <!--end .form-wizard-nav -->
            <div class="tab-content clearfix p-30">
                <div *ngIf="step1">
                    <div class="material-datatables">
                        <p-confirmDialog key="template" #cd icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}"
                            [acceptLabel]="'Si'" [baseZIndex]="5">
                            <p-footer>
                                <button type="button" click="vaiStepuno()" pButton icon="pi pi-check" label="Sì" (click)="chiudipopup()">
                                </button>
                                <button type="button" pButton icon="pi pi-times" label="No"
                                    (click)="cd.reject()"></button>
                            </p-footer>
                        </p-confirmDialog>
                        <div *ngIf="loadingTemplate">
                            <div class="text-center">
                                <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                            </div>
                        </div>
                        <div *ngIf="listaTemplate.length <1 && !loadingTemplate">Nessun template disponibile
                        </div>
                        <div *ngIf="listaTemplate.length >0 && !loadingTemplate" style="display: flex; justify-content: center;" class="row">
                            <div class="col-xs-1"></div>
                            <div class="col-xs-10">
                                <div class="col-xs-4 scaleHover" *ngFor="let emailTemplate of listaTemplate">
                                    <div *ngIf="emailTemplate.nomeT === 'TemplateMio'" (click)="getPath(emailTemplate)">
                                        <div class="card card-stats">
                                            <div class="card-content centraTestoCard"> 
                                                <img src="./assets/newsLetterTemplate/TemplateTuo.png" />
                                                    
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="emailTemplate.nomeT === 'TemplateVuoto'"  (click)="getPath(emailTemplate)">
                                    <div class="card card-stats">
                                        <div class="card-content centraTestoCard" >
                                            <div >
                                                <img src="./assets/newsLetterTemplate/TemplateVuoto.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    <div *ngIf="emailTemplate.img" class="card card-stats" (click)="getPath(emailTemplate)">
                                    
                                        <div class="card-content" >
                                            <!-- <p class="category"><strong>Visite</strong></p>
                                            <h5 class="card-title">{{visite}}</h5> -->
                                            <img src="{{emailTemplate.img}}" />
                                        </div>
                                
                                    </div>
                            </div>
                            <div class="col-xs-1"></div>

                        <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
                <!-- qui apre l'editor -->
                <div *ngIf="step2">
                   
                    <app-news-letter-builder  [templateScelto]="templateScelto"
                        [richiestaObject]="richiestaObject" (returnObj)="controlloObjDiRitorno($event)" (caricato)="caricaFiglio(figlioCaricato)">
                    </app-news-letter-builder>
                </div>

                <!--end #step2 -->

                <div *ngIf="step3"> 
                    <div class="container">
                        <div class="row">
                          <div class="col-sm-2">
                          </div>
                          <div class="col-sm-8">
                      <h4 *ngIf="dataSceltaParsata">Data Scelta</h4>
                      <h4 *ngIf="!dataSceltaParsata">Scegli Data</h4>
                        <div>{{dataSceltaParsata | date: 'dd/MM/yyyy HH:mm'}}</div>
                    <div *ngIf="!dataSceltaParsata && submittedStep3" class="invalid-feedback">Seleziona una Data</div>
                    <div *ngIf="erroreData" class="invalid-feedback">Seleziona una Data Valida</div>
                          </div>
                          <div class="col-sm-2">
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                            </div>
                            <div class="col-sm-8">
                                <!-- <div class="centraData" > -->
                                    <p-calendar view="date" (onSelect)="selezionaData()" [readonlyInput]="true" [numberOfMonths]="2"
                                        yearRange="2021:2030" [(ngModel)]="dataScelta" [defaultDate]="oggiMezzora" [showOtherMonths]="true"
                                        placeholder="Seleziona Data" [minDate]="oggiMezzora" showTime="true" hourFormat="24"
                                        [inline]="true" dateFormat="dd/mm/yy hh:mm:ss" [locale]="ita" [stepMinute]="30">
                                    </p-calendar>
                                <!-- </div> -->
                            </div>
                            <div class="col-sm-2">
                            </div>
                          </div>
                      </div>
                </div>
                <!--end #step3 -->
                <!-- formControlName="calendario" -->
                <div *ngIf="step4">
                    <div class="card-content">
                        <div class="material-datatables">
                            <div *ngIf="loadingListaUtenti">
                                <div class="text-center">
                                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                                        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                                </div>
                            </div>
                            <div class="row">
                                <form [formGroup]="nomeForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                                <label *ngIf="!reminder" class="col-sm-3 label-on-left" style="text-align: left;padding-top: 0px;padding-left: 20px;">Nome Campagna<span class="colorRed"> *</span></label>
                                <label *ngIf="reminder" class="col-sm-3 label-on-left" style="padding: 0;">Nome Reminder<span class="colorRed"> *</span></label>
                                <div class="col-sm-9">
                                    <div style="margin: -12px 0 0 0" class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Nome" formControlName="nome"
                                            class="form-control" [ngClass]="{ 'is-invalid': submittedStep4}"
                                            maxlength="30" />
                                            <div *ngIf="submittedStep4 && f.nome.errors" class="invalid-feedback">
                                                <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
                                                <div *ngIf="f.nome.errors.pattern">Campo nome invalido</div>
                                            </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                           
                                <div class="col-xs-12">
                                    <div class="material-datatables">
                                        <div class="row">
                                            <div class="col-sm-3 col-xs-3">
                                                <div class="dataTables_length" id="datatables_length">
                                                    <label class="form-group">Limita Risultati
                                                        <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                                                            aria-controls="datatables" class="form-control input-sm">
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                        </select> </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-xs-9">
                                                <div class="pull-right">
                                                    <div id="datatables_filter" class="dataTables_filter">
                                                        <form [formGroup]="searchform">
                                                            <label class="form-group ">Esegui una ricerca
                                                                <div class="row">
                                                                    <div class="col-sm-10"><input type="text"
                                                                            formControlName="search" class="form-control"
                                                                            placeholder="Cerca..." maxlength="30"
                                                                            style="width: 40vw;" (input)="refreshSelezionati($event)">
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </form>
                                                        <div *ngIf="searchInNews()"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="scrollable">
                                            <div class="table-responsive">
                                                <p *ngIf="listaUtenti.length <1 && !loadingListaUtenti">Nessun Utente disponibile</p>
                                                <table *ngIf="listaUtenti.length >0 && !loadingListaUtenti" id="datatables"
                                                    class="table table-striped table-no-bordered table-hover table-responsive"
                                                    cellspacing="0" 
                                                   >
                                                    <thead>
                                                        <tr>
                                                            <th><input  type="checkbox"  [checked]="chekAll" (click)="checkAll($event)"></th>
                                                            <th (click)="ordinaArray('nome')" class="cursPointer">Nome
                                                                Cognome<i class="material-icons notranslate bottoniListaI"
                                                                   >unfold_more</i></th>
                                                            <th (click)="ordinaArray('email')"  class="cursPointer">Email
                                                                 <i class="material-icons notranslate bottoniListaI"
                                                                >unfold_more</i></th>
                                                                    <th (click)="ordinaArray('gruppo')"  class="cursPointer">Gruppo
                                                                         <i class="material-icons notranslate bottoniListaI"
                                                                        >unfold_more</i></th>
                                                        </tr>
                                                    </thead>
                                                    <tfoot>
                                                        <tr>
                                                            <!-- -->
                                                            <th># </th>
                                                            <th >Nome</th>
                                                            <th >Email</th>
                                                            <th >Gruppo</th>
                                                      
                                                        </tr>
                                                    </tfoot>
                                                    <tbody *ngIf="showTable">
            
                                                        <tr *ngFor="let user of listaUtenti;let i =index;">
                                                            <ng-container
                                                                *ngIf="i>=(selectedValue*(pagina-1)) && i<(selectedValue*pagina)">
                                                                <td><input type="checkbox" [checked]="user.isSelected" (click)="checkOne(user.id)"value="{{user}}"> </td>
                                                                <td  class="sizeMedium">{{user.nome + " " + user.cognome}}</td>
                                            <td class="sizeMedium">{{user.email}}</td>
                                            <td class="sizeMedium">
                                                <span *ngFor="let gruppo of user.gruppos; let j= index">
                                                    <span *ngIf="j <= user.gruppos.length-2 && j < 3">{{gruppo?.nomeGruppo + ', '}}</span>
                                                    <span *ngIf=" j === user.gruppos.length-1 && j < 3">{{gruppo?.nomeGruppo}}</span>
                                                    <span *ngIf=" j === 3">...</span>
                                                    </span>
                                            </td> 
                                                            </ng-container>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-5">
                                                <div class="dataTables_info" id="datatables_info" role="status"
                                                    aria-live="polite" style="margin-top: 5%;">{{selectedValue< numeroUtenti?
                                                        selectedValue: numeroUtenti}} di {{numeroUtenti}} Utenti</div>
                                                </div>
                                                <div class="col-sm-7 text-right">
                                                    <div class="dataTables_paginate paging_full_numbers"
                                                        id="datatables_paginate">
                                                        <ul class="pagination">
                                                            <li class="paginate_button pointer " id="datatables_first">
                                                                <a (click)="paginaUno()" aria-controls="datatables"
                                                                    data-dt-idx="0" tabindex="0">Inizio</a>
                                                            </li>
                                                            <li class="paginate_button pointer " id="datatables_previous">
                                                                <a (click)="back()" aria-controls="datatables"
                                                                    data-dt-idx="1" tabindex="0">Indietro</a>
                                                            </li>
                                                            <li *ngFor="let pagina of pagine; let i=index"
                                                                class="paginate_button pointer"
                                                                [class]="{active:pagina === appoggioclick}">
                                                                <a (click)="cambiaPagina(i)" aria-controls="datatables"
                                                                    data-dt-idx="2" tabindex="0">{{pagina}}</a>
            
                                                            </li>
            
                                                            <li class="paginate_button pointer" id="datatables_next">
                                                                <a (click)="next()" aria-controls="datatables"
                                                                    data-dt-idx="6" tabindex="0">Avanti</a>
                                                            </li>
                                                            <li class="paginate_button pointer" id="datatables_last">
                                                                <a (click)="fine()" aria-controls="datatables"
                                                                    data-dt-idx="7" tabindex="0">Fine</a>
                                                            </li>
            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
            
                                        </div>
            
                                  <!-- fine lista mail -->
                             
                                   
                                </div>
                        
                            <div *ngIf="submittedStep4 && listaIdUtenti.length<1" class="invalid-feedback">
                                <div style="margin-left: 80%;">Aggiungi almeno un utente</div>
                            </div>
                        </div>

                    </div>
                </div>
                <!--end #step4 -->
                <!--end .tab-content -->

            <!--end #rootwizard -->
            <!-- FINE  WIZARD -->
            <div class="row" style="margin-top: 2%">
                <div class="col-xs-6">
                    <button *ngIf="!step1" [disabled]="!figlioCaricato" type="button" (click)="previusStep()" class="btn btn-secondary"
                        style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%; margin-left: 6%;">Indietro</button>
                </div>
                <div *ngIf="!step4"  class="col-xs-6 text-right">
                    <button [disabled]="!figlioCaricato" type="button" style="margin-right: 6%;" (click)="nextStep()"
                        class="btn btn-primary">Avanti</button>
                </div>

                <div *ngIf="step4" class="col-xs-6 text-right">

                    <button [disabled]="loading" type="button" (click)="onSubmit()" style="margin-right: 6%;"
                        class="btn btn-primary">Salva
                        <span *ngIf="loading">
                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                        </span>
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
    <div class="mobileVisionErr">
     <!-- <div class="container-fluid"> -->
            <div class="card">
                <div class="card-content">
                    <div class="material-datatables">
                <div class="card-title">
                    <button (click)="goBack()" id="back-button" type="button" class="btn btn-round  btn-just-icon bottoneBack"
                      >
                        <i class="material-icons notranslate">arrow_back</i>
                    </button>
                    <h4 class="lineDisplay">Torna indietro</h4>
                    </div>
            <div style="text-align: center;">
               
                <i class="material-icons unsatisfied errorFace">
                    sentiment_dissatisfied
                </i>
                 <p class="fontErrorUno">OPS</p>
                 <p class="fontErrorDue">Questa funzionalità non è disponibile </p>
                    <p class="fontErrorDue"> a dimensioni inferiori di 1024X768</p>
                 
                 </div>
            </div>
        <!-- </div>  -->
    </div></div>
    </div>