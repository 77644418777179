import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AccountService, EnvService } from 'src/app/SERVICES';
import { NewsLetterService } from 'src/app/SERVICES/NEWSLETTER/newsLetter.service';
import { AlertService } from 'src/app/SERVICES';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.scss']
})
export class NewsLetterComponent implements OnInit, DoCheck {

  listaNewsLetter = [];
  listaReminder = [];
  creazione = false;
  whatToDoObject;
  reminderObject
  reminderView = false;
  infoNews = false
  infoReminder = false;
  primaVisione = true;
  idNewsLetter;
  idReminder
  cancellaNews = false;
  cancellaRem = false;
  loadingListaNews = false;
  loadingListaReminder = false;
  loading = false;
  info;
  counterEmail = 0;
  emailDaElaborare = 0;
  //LISTE
  pagine = []
  pagina = 0;
  selectedValue = 10;
  pagineReminder = []
  paginaReminder = 0;
  selectedValueReminder = 10;
  abilita
  disabilitaAvanti
  abilitaReminder
  disabilitaAvantiReminder
  cliccato
  numeroNews
  allListaNewsLetter = []
  ordinaNome = false;
  ordinaData = false;
  ordinaStato = false;
  searchform: FormGroup;
  secondo
  listaNewsLetterappoggio = []
  selectedUtente = []
  ordinaNomeReminder = false;
  ordinaDataReminder = false;
  ordinaStatoReminder = false;
  allListaReminder = []
  ricordaLunghezzaReminder
  ricordaPaginaReminder
  secondoReminder = false;
  flagReminder = false;
  listaReminderAppoggio

  searchformReminder: FormGroup;
  isClickable = true;
  maxItems
  items
  numeroMaxPagine
  paginator = true;
  subscriptionSearch: Subscription
  isSearchingOnReminder = false;
  isSearchingOn = false;
  maxItemsReminder
  itemsReminder
  numeroMaxPagineReminder
  paginatorReminder = true;
  subscriptionSearchReminder: Subscription
  isSelectingValueReminder
  isSelectingValue
  primaVoltaCheck = true;
  isClickableReminder = true;
  dataRem


  refreshPaginatorNews = true;
  refreshPaginatorReminder =true;
  constructor(
    private formBuilder: FormBuilder,
    private newsLetterService: NewsLetterService,
    private confirmationService: ConfirmationService,
    private dataPipe: DatePipe,
    private alertService: AlertService,
    private envService: EnvService,
    private router: Router,
    private accountService: AccountService
  ) {
      this.newsLetterService.visualizza_email().subscribe((res) => {
      this.counterEmail = res.data
    })
    this.emailProg()

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.searchformReminder = this.formBuilder.group({
      search: ['', Validators.required]
    })
  }

  emailProg() {
    this.newsLetterService.visualizza_email_prog().subscribe((res) => {
      this.emailDaElaborare = res.data
    })
  }

  ngDoCheck(): void {
    if (this.accountService.listaEnv && (!this.envService.NEWSLETTER || this.accountService.ruolo !== 1)) {
      this.router.navigate(['./'])
    }


    // if (this.pagina === 0) {
    //   this.pagina = 1;
    // }
    // if (this.paginaReminder === 0) {
    //   this.paginaReminder = 1;
    // }
  }

  ngOnInit() {
    this.getListaNews(this.pagina, this.selectedValue)
    //SOSTITUIRE NUOVO METODO RECUPERO UTENTI
  }

  resetVar() {
    this.listaReminder = []
    this.idNewsLetter = null;
  }

  //servizzi lista News
  getListaNews(pagina?, quantita?, ricerca?, campo?, cardinalita?) {
    this.emailProg()
    this.refreshPaginatorNews = false;
    //Serivizio
    this.subscriptionSearch = this.newsLetterService.getListaNews(pagina, quantita, ricerca, campo, cardinalita).subscribe((res) => {
      if (res.data.content.length > 0) {
        this.listaNewsLetter = res.data.content;
      }else{
        this.listaNewsLetter = []

      }
      this.numeroMaxPagine = Number(res.data.totalPages);
      this.maxItems = res.data.totalElements;
      this.items = res.data.numberOfElements
      this.refreshPaginatorNews = true;
      this.isClickable = true;
      this.paginator = true;
      this.isSearchingOn = false;
      this.isSelectingValue = false;
      this.loadingListaNews = false;
      this.refreshPaginatorNews = true;
    })
  }
  //servizzi lista News FINE

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.getListaNews(this.pagina, this.selectedValue, this.valoreRicerca)
    } else {
      this.getListaNews(this.pagina, this.selectedValue)
    }
  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = false;
    this.refreshPaginatorNews =false;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.getListaNews(this.pagina, this.selectedValue, this.valoreRicerca)
    } else {
      this.getListaNews(this.pagina, this.selectedValue)
    }
  }
  valoreRicerca
  search() {
    this.valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe()
    }
    this.isSearchingOn = true;
    this.paginator = false
    this.valoreRicerca = this.searchform.value.search
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.getListaNews(this.pagina, this.selectedValue, this.valoreRicerca)
    } else {
      this.getListaNews(this.pagina, this.selectedValue)
    }
  }

  spegniVariabiliVisione() {
    this.primaVisione = false;
    this.creazione = false;
    this.reminderView = false;
    this.infoNews = false;
    this.infoReminder = false;
  }

  vaiCrea() {
    this.spegniVariabiliVisione()
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe()
    }
    this.whatToDoObject = {
      whatTodo: "crea",
    }
    this.creazione = true;
    this.searchformReminder.reset();
    this.searchform.reset();
  }

  vaiModifica(news) {
    this.spegniVariabiliVisione()
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe()
    }
    this.whatToDoObject = {
      whatTodo: "modifica",
      news: news
    }
    this.creazione = true;
    this.searchformReminder.reset();
    this.searchform.reset();
  }

  vaiInfoNewsLetter(email) {
    this.newsLetterService.info_news(email.id).subscribe((res) => {
      this.spegniVariabiliVisione();
      this.info = res.data
      this.infoNews = true;
    }, (error) => {
    })
  }

  // freccia nella parte di creazione/modifica/reminder
  //menu navigazione reminder
  vaiReminderView(email?) {
    if (email) {
      this.reminderObject = email
      this.dataRem = email.dataInoltro;
    }
    this.spegniVariabiliVisione();
    this.getListaReminder(this.paginaReminder, this.selectedValueReminder);
    this.reminderView = true;
  }

  getListaReminder(pagina?, quantita?, ricerca?, campo?, cardinalita?) {
    this.emailProg()
    this.refreshPaginatorReminder = false;
    this.subscriptionSearch = this.newsLetterService.lista_remainder(this.reminderObject.id, pagina, quantita, ricerca, campo, cardinalita).subscribe((res) => {
      if (res.data.content.length > 0) {
        this.listaReminder = res.data.content;
      }else{
        this.listaReminder = []

      }
      this.numeroMaxPagineReminder = Number(res.data.totalPages);
      this.maxItemsReminder = res.data.totalElements;
      this.itemsReminder = res.data.numberOfElements

      this.isClickableReminder = true;
      this.paginatorReminder = true;
      this.isSearchingOnReminder = false;
      this.isSelectingValueReminder = false;
      this.loadingListaReminder = false;
      this.refreshPaginatorReminder = true;
    })
    // //Servizio
    // this.paginaReminder=1;
    // this.newsLetterService.lista_remainder(this.reminderObject.id).subscribe((res) => {
    //   if(res.data.length>0){
    //   this.listaReminder=res.data;
    //   this.allListaReminder=this.listaReminder
    //   this.selectedValueReminder = 10;

    //   this.loadingListaReminder = false;
    //   this.appoggioclick = 1;
    // }else{
    //   this.listaReminder = [];
    //   this.allListaReminder = [];
    //   this.loadingListaReminder = false;
    // }}, (error) => {
    // this.loadingListaReminder = false;
    // })
  }

  cambiaPaginaReminder(event) {
    this.isClickable = false;
    this.paginaReminder = event;
    if (this.valoreRicercaReminder.length > 0) {
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder,this.valoreRicercaReminder)

    } else {
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder)
    }
  }

  cambiaValueReminder() {
    this.isClickable = false;
    this.paginatorReminder = false;
    this.paginaReminder = 0;
    this.isSelectingValueReminder = true;
    this.isSearchingOnReminder = true;
    this.refreshPaginatorReminder = false;
    if (this.valoreRicercaReminder.length > 0) {
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder,this.valoreRicercaReminder)

    } else {
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder)
    }
  }
valoreRicercaReminder
  searchReminder() {
   this.valoreRicercaReminder;
    this.paginaReminder = 0;
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe()
    }
    this.isSearchingOn = true;
    this.paginatorReminder = false
    this.valoreRicercaReminder = this.searchformReminder.value.search
    if (this.valoreRicercaReminder.length > 0) {
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder, this.valoreRicercaReminder)
    } else {
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder)
    }
  }

  //METODO CANCELLA NEWS
  confermaCancellaNewsLetter(id) {
    this.cancellaNews = true;
    this.idNewsLetter = id;
    this.confirmationService.confirm({
      key: 'cancellaNews',
      message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaNews = false;
        this.loading = false;
        this.confirmationService.close();
      }
    });
  }

  cancellaNewsLetter() {
    this.loading = true;
    this.refreshPaginatorNews=false;
    this.newsLetterService.elimina_news(this.idNewsLetter).subscribe((res) => {
      if (this.listaNewsLetter && this.listaNewsLetter.length == 1)  {
        if (this.pagina && this.pagina > 1) {
          this.pagina = this.pagina - 1
          this.getListaNews(this.pagina, this.selectedValue)
        } else {
          this.getListaNews(this.pagina, this.selectedValue)
      }
        }else {
          this.getListaNews(this.pagina, this.selectedValue)
      }
      this.idNewsLetter = null
      this.cancellaNews = false;
      this.confirmationService.close();
      this.loading = false
      this.alertService.success(res.status.description);

    }, (error) => {
      this.loading = false;
      this.cancellaNews = false;
      this.confirmationService.close();
      this.refreshPaginatorNews=true;

    });
  }

  //FINE NEWS INZIO REMINDER

  vaiCreaReminder() {
    this.spegniVariabiliVisione()
    this.whatToDoObject = {
      whatTodo: "creaReminder",
      news: this.reminderObject,
      dataNews: this.dataRem,
    }
    this.creazione = true;
    this.searchformReminder.reset();
    this.searchform.reset();
  }

  goBackReminder() {
    this.resetVar()
    this.reminderObject = null
    this.spegniVariabiliVisione();
    this.primaVisione = true;
    this.reminderView = false;
  }

  confermaCancellaReminder(id) {
    this.cancellaRem = true;
    this.idReminder = id;
    this.confirmationService.confirm({
      key: 'cancellaReminder',
      message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaRem = false;
        this.loading = false;
        this.confirmationService.close();
      }
    });
  }

  cancellaReminder() {
    this.loading = true;
    this.refreshPaginatorReminder = false;

    this.newsLetterService.eliminazione_fisica_remainder(this.idReminder)
      .subscribe((res) => {
        this.alertService.success(res.status.description);
        if (this.listaReminder && this.listaReminder.length == 1) {
          if (!!this.paginaReminder && this.paginaReminder != 0) {
            this.paginaReminder = this.paginaReminder - 1
            this.getListaReminder(this.paginaReminder, this.selectedValueReminder)
          } else {
            this.getListaReminder(this.paginaReminder, this.selectedValueReminder)
          }
        }else{
          this.getListaReminder(this.paginaReminder, this.selectedValueReminder)

        }
        //  this.goBackReminder();
        this.confirmationService.close();
        this.idReminder = null
        this.loading = false

      }, (error) => {
        this.loading = false
        this.refreshPaginatorReminder = true;

      });
  }

  vaiModificaReminder(reminder) {
    this.spegniVariabiliVisione()
    this.whatToDoObject = {
      whatTodo: "modificaReminder",
      news: reminder,
      dataNews: this.dataRem,
    }
    this.creazione = true;
    this.searchformReminder.reset();
    this.searchform.reset();
  }

  vaiInfoReminder(reminder) {
    this.newsLetterService.info_remainder(reminder.id).subscribe((res) => {
      this.spegniVariabiliVisione();
      this.infoReminder = true;
      this.info = res.data
      this.infoNews = true;
    }, (error) => {
    })
  }
  //

  ordinaArray(filtro) {
    if (filtro === 'nome') {
      if (!this.ordinaNome) {
        this.listaNewsLetter.sort((a, b) => a.nome.toLowerCase() < b.nome.toLowerCase() ? -1 : a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : 0);
        this.ordinaNome = true;
      }
      else {
        this.listaNewsLetter.sort((a, b) => a.nome.toLowerCase() > b.nome.toLowerCase() ? -1 : a.nome.toLowerCase() < b.nome.toLowerCase() ? 1 : 0);
        this.ordinaNome = false;
      }
      this.allListaNewsLetter = this.listaNewsLetter
      this.ordinaStato = false;
      this.ordinaData = false;
    }
    if (filtro === 'stato') {
      if (!this.ordinaStato) {
        this.listaNewsLetter.sort((a, b) => new Date(a.dataInoltro) < new Date(b.dataInoltro) ? -1 : new Date(a.dataInoltro) > new Date(b.dataInoltro) ? 1 : 0);
        this.ordinaStato = true;
      }
      else {
        this.listaNewsLetter.sort((a, b) => new Date(a.dataInoltro) > new Date(b.dataInoltro) ? -1 : new Date(a.dataInoltro) < new Date(b.dataInoltro) ? 1 : 0);
        this.ordinaStato = false;
      }
      this.allListaNewsLetter = this.listaNewsLetter
      this.ordinaNome = false;
      this.ordinaData = false;
    }
    if (filtro === 'data') {
      if (!this.ordinaData) {
        this.listaNewsLetter.sort((a, b) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0);
        this.ordinaData = true;
      }
      else {
        this.listaNewsLetter.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
        this.ordinaData = false;
      }
      this.allListaNewsLetter = this.listaNewsLetter
      this.ordinaNome = false;
      this.ordinaStato = false;
    }
  }

  //METODI LISTA NEWS FINE

  ordinaArrayReminder(filtro) {
    if (filtro === 'nome') {
      if (!this.ordinaNome) {
        this.listaReminder.sort((a, b) => a.nome.toLowerCase() < b.nome.toLowerCase() ? -1 : a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : 0);
        this.ordinaNomeReminder = true;
      }
      else {
        this.listaReminder.sort((a, b) => a.nome.toLowerCase() > b.nome.toLowerCase() ? -1 : a.nome.toLowerCase() < b.nome.toLowerCase() ? 1 : 0);
        this.ordinaNomeReminder = false;
      }
      this.allListaReminder = this.listaReminder
      this.ordinaStatoReminder = false;
      this.ordinaDataReminder = false;
    }
    if (filtro === 'stato') {
      if (!this.ordinaStatoReminder) {
        this.listaReminder.sort((a, b) => a.stato.toLowerCase() < b.stato.toLowerCase() ? -1 : a.stato.toLowerCase() > b.stato.toLowerCase() ? 1 : 0);
        this.ordinaStatoReminder = true;
      }
      else {
        this.listaReminder.sort((a, b) => a.stato.toLowerCase() > b.stato.toLowerCase() ? -1 : a.stato.toLowerCase() < b.stato.toLowerCase() ? 1 : 0);
        this.ordinaStatoReminder = false;
      }
      this.allListaReminder = this.listaReminder
      this.ordinaNomeReminder = false;
      this.ordinaDataReminder = false;
    }
    if (filtro === 'data') {
      if (!this.ordinaDataReminder) {
        this.listaReminder.sort((a, b) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0);
        this.ordinaDataReminder = true;
      }
      else {
        this.listaReminder.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
        this.ordinaDataReminder = false;
      }
      this.allListaReminder = this.listaReminder
      this.ordinaNomeReminder = false;
      this.ordinaStatoReminder = false;

    }
  }

  backLista(i) {
    if (i == "news") {
      this.resetVar()
      this.getListaNews(this.pagina, this.selectedValue)
      this.spegniVariabiliVisione();
      this.primaVisione = true;
    }
    if (i == "rem") {
      this.resetVar();
      this.getListaReminder(this.paginaReminder, this.selectedValueReminder)
      this.spegniVariabiliVisione();
      this.reminderView = true;
    }
  }

  controllomail(email) {
    return (new Date(email.dataInoltro) > new Date())
  }

  ordinaEtichetta = 0
  ordinaArrayNews(lable) {
    if (this.ordinaEtichetta == 0 || this.ordinaEtichetta == 2) {
      this.ordinaEtichetta = 1
    } else {
      this.ordinaEtichetta = 2
    }
    this.getListaNews(this.pagina, this.selectedValue, null, lable, this.ordinaEtichetta)
  }

  ordinaEtichettaRem = 0
  ordinaArrayRem(lable) {
    if (this.ordinaEtichettaRem == 0 || this.ordinaEtichettaRem == 2) {
      this.ordinaEtichettaRem = 1
    } else {
      this.ordinaEtichettaRem = 2
    }
    this.getListaReminder(this.pagina, this.selectedValue, null, lable, this.ordinaEtichettaRem)
  }

  responsiveWidth() {    }

}
