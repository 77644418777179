<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12" *ngIf="!dettagli">
        <div class="card z-index-0">
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <h4 class="card-title">Gestione Medici</h4>
              </div>
              <div class="col-sm-12">
                <button class="btn btn-primary" (click)="downloadList()" [disabled]="isLoading"><span>Scarica</span></button>
                <span *ngIf="isLoading">Attendi il download del file</span>
              </div>
            </div>

            <div class="row pagination-container">
              <div class="col-sm-3 col-xs-3">
                <div class="dataTables_length" id="datatables_length" [ngClass]="{'disabled':!isClickable}">
                  <div style="display: flex;">
                    <label class="form-group">Limita Risultati
                      <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                        aria-controls="datatables" class="form-control input-sm" style="height: 35px">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>

                      </select>
                      <div *ngIf="isSelectingValue" style="display: flex;align-items: end;">
                        <div class="text-center" style="position: absolute;right: 0px;">
                          <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                            styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-sm-9 col-md-9">

                <div class="btn button-icon-filter pull-right" [ngClass]="{'active': filtersVisible}" (click)="openFilters()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33.75" height="23.391"
                       viewBox="0 0 33.75 23.391" class="mw-50">
                    <path id="Icon_ion-filter" data-name="Icon ion-filter"
                          d="M33.188,12.565H2.813A1.9,1.9,0,0,1,1.125,10.5,1.9,1.9,0,0,1,2.813,8.438H33.188A1.9,1.9,0,0,1,34.875,10.5,1.9,1.9,0,0,1,33.188,12.565ZM27.563,22.2H8.438A1.9,1.9,0,0,1,6.75,20.133a1.9,1.9,0,0,1,1.688-2.064H27.563a1.9,1.9,0,0,1,1.688,2.064A1.9,1.9,0,0,1,27.563,22.2Zm-6.75,9.631H15.188A1.9,1.9,0,0,1,13.5,29.764,1.9,1.9,0,0,1,15.188,27.7h5.625A1.9,1.9,0,0,1,22.5,29.764,1.9,1.9,0,0,1,20.813,31.828Z"
                          transform="translate(-1.125 -8.438)" />
                  </svg>
                </div>

                <button class="btn small-square-primary pull-right" type="submit" *ngIf="filtersVisible" (click)="submitFilters()">
                  <i class="material-icons notranslate pointer" style="font-size: 22px; padding: 2px 2px 2px 2px;">check</i>
                </button>
                <button *ngIf="filtersVisible" class="btn btn-default pull-right" type="button" (click)="deleteFilters()">
                  Rimuovi filtri
                </button>
              </div>

            </div>
            <!-- FORM FILTRI -->
            <app-form-filter [filtersVisible]="filtersVisible" (emitFilters)="updateList($event)"
                             [removeFilters]="removeFilters"></app-form-filter>
            <!-- END FORM FILTRI -->

              <div>
                <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0"
                  width="100%" style="width:100%">
                  <thead style="position: sticky; background-color: white;z-index: 1;">
                  <tr style="position: sticky; z-index: 1; background-color: white;">
                    <th style="position: sticky; z-index: 1; background-color: white;">
                      Cognome</th>
                      <th style="position: sticky; z-index: 1; background-color: white;">
                        Nome</th>
                        <th style="position: sticky; z-index: 1; background-color: white;">
                          Email</th>
                            <th style="position: sticky; z-index: 1; background-color: white;">
                              Specialità</th>
                              <th style="position: sticky; z-index: 1; background-color: white;">
                                Zona</th>
                                 <th style="position: sticky; z-index: 1; background-color: white;">
                                   ISF</th>
                                    <th *ngIf="!smartView" class="text-right"
                                     style="position: sticky; z-index: 1; background-color: white;">
                                    Azioni</th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                    <th>Cognome</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Specialità</th>
                    <th>Zona</th>
                    <th>ISF</th>
                    <th *ngIf="!smartView" class="text-right">Azioni</th>
                  </tr>
                  </tfoot>
                  <tbody>
                  <tr *ngFor="let medico of listaMedici ;let i = index "(click)="selectUser(medico.utente_id)" [ngClass]="{'activeRow': selectedId == medico.utente_id}">
                    <ng-container>
                      <td style="font-size: medium;">{{medico.cognome}}</td>
                      <td style="font-size: medium;">{{medico.nome}}</td>
                      <td style="font-size: medium;">{{medico.email_privata}}</td>
                      <td style="font-size: medium;">{{medico.specialita_1}}</td>
                      <td style="font-size: medium;">{{medico.micro_area}}</td>
                      <td style="font-size: medium;">{{medico.isf}}</td>

                      <td class="text-right" *ngIf="!smartView">

                        <span> <!--<span *ngIf="medico.passwordSet===0">--->
                          <a (click)="inviaEmail(medico.email_privata)" rel="tooltip"
                            title="Invia di nuovo l'email all'utente per impostare la password"><i
                              class="material-icons notranslate pointer"
                              style="font-size: 22px; padding: 2px 2px 2px 2px; color: limegreen;">email</i></a>
                        </span>
                        <!--<a (click)="cambiaStato(medico.utente_id)" *ngIf="medico.utente_id.attivo===1" rel="tooltip"
                          title="Disattiva utente"> -->
                        <a (click)="cambiaStato(medico.utente_id)" *ngIf="medico.attivo===1" rel="tooltip" title="Disattiva utente"><i class="material-icons notranslate pointer"
                            style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(182, 182, 182);">toggle_on</i>
                        </a>
                        <!--<a (click)="cambiaStato(medico.utente_id)" *ngIf="medico.utente_id.attivo===0" rel="tooltip"
                          title="Attiva utente"> -->
                        <a (click)="cambiaStato(medico.utente_id)" *ngIf="medico.attivo===0" rel="tooltip" title="Attiva utente"><i class="material-icons notranslate pointer"
                            style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(205, 50, 50);">toggle_off</i>
                        </a>

                        <a (click)="visualizza(medico.id)" rel="tooltip" title="Info medico"><i
                            class="material-icons notranslate pointer"
                            style="font-size: 22px; padding: 2px 2px 2px 2px; color: rgb(255, 217, 2);">info</i></a>

                      </td>
                    </ng-container>
                  </tr>

                  </tbody>
                </table>
              </div>
              <div class="row pagination-container">
                <div class="col-sm-5">
                  <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite"
                       >{{items}} di {{maxItems}}
                    medici</div>
                </div>
                <div class="col-sm-7 text-right giustificaDestra">
                  <app-paginator *ngIf="refreshView" [pageSize]="numeroMaxPagine" [(isClickable)]="isClickable"
                                 (currentPageEmitter)="cambiaPagina($event)" [startingPage]="pagina+1"
                  class="pull-right"></app-paginator>
                </div>
              </div>
          </div>
        </div>
      </div>
     <div *ngIf="dettagli">
    <app-dettagli-utente [utenteInfo]="infoMedico" [isDoctor]=true (cambia)="indietroDainfo($event)"></app-dettagli-utente>
   </div>
  </div>
 </div>
</div>
