<div class="content">
  <div class="container-fluid">
    <ng-container *ngIf="report">

      <div *ngIf="selezionaDataReport">
        <div class="col-xs-12">

          <div class="card-content" class="text-center" style="margin-bottom: 3%;">
            <h4 style="margin-bottom: 3%;">Seleziona la data</h4>
            <!--<form [formGroup]="calendar" > -->
            <!-- <p-calendar [(ngModel)]="value1" [numberOfMonths]="2" [inline]="true" (onSelect)="onDateSelection($event)" ></p-calendar> -->
            <!-- </form> -->
            <form [formGroup]="calendar" class="box-model form-support-margin overflowAuto">
              <p-calendar view="date" formControlName="calendario" (onSelect)="selezionaData()" [maxDate]="today"
                [readonlyInput]="true" [numberOfMonths]="2" yearRange="2000:2030" [(ngModel)]="rangeDates"
                [showOtherMonths]="false" placeholder="Select time period" selectionMode="range" [inline]="true"
                dateFormat="dd/mm/yy" [locale]="ita">

              </p-calendar>
              <div class="card-footer text-right">
                <!-- <a class="btn btn-secondary" (click)="indietro()" style="color: white;">Indietro</a> -->
                <button [disabled]="loading2" class="btn btn-primary" rel="tooltip" title="Scarica Report"
                  (click)="inviaDati()" style="max-width: 2%;"><i class="fa fa-folder"><span *ngIf="loading2">
                      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                    </span></i>

                </button>



              </div>
            </form>
          </div>


        </div>
      </div>

      <div *ngIf="grafici">
        <button (click)="indietroGrafici()" id="back-button" class="btn btn-round  btn-just-icon"
        style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
        <i class="material-icons notranslate">arrow_back</i>
      </button>
      <h4 style="margin-bottom: 3%; display: inline;">Report da {{inizio | date: 'dd/MM/yyyy'}} a {{fine | date:
        'dd/MM/yyyy'}}</h4>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header">
            <h4 style="display: inline;" *ngIf="!alfa">Report Satisfaction Gap</h4>
            <h4 style="display: inline;" *ngIf="alfa">Report Satisfaction Alfa Academy</h4>
            <!-- <div class="dropdown" *ngIf="selectedAule.length>1"
              style="display: inline; position: absolute; right: 6%; margin-top: 3px;;">
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli aula
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="creaGraficogruppi(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div> -->
            <div *ngIf="!mostraChartGap" class="m-20" style="justify-content: center; text-align: center;"><span>
                <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
              </span></div>
            <p-chart *ngIf="mostraChartGap" type="pie" [height]="300" [data]="dataGap" #chart></p-chart>
          </div>
        </div>
      </div>

      <div *ngIf="alfa" class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header">
            <h4 style="display: inline;">Report Satisfaction Sito Istituzionale</h4>
            <div *ngIf="!mostraChartCms" class="m-20" style="justify-content: center; text-align: center;"><span>
                <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
              </span></div>
            <p-chart *ngIf="mostraChartCms" type="pie" [height]="300" [data]="dataCms" #chart></p-chart>
          </div>
        </div>
      </div>

      </div>

    </ng-container>
  </div>
</div>