import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "../GENERAL/env.service";

@Injectable({
  providedIn: 'root'
})
export class FarmaciaService {

  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }

  getAllFile(page, size, campo=null, ricerca=null) {
    if(campo && ricerca)
      return this.http.get<any>(this.baseUrl + '/lista_farmacie?page=' + page + '&size=' + size + '&campo=' + campo + '&ricerca=' + ricerca );
    return this.http.get<any>(this.baseUrl + '/lista_farmacie?page=' + page + '&size=' + size);

  }

  getAllFileById(id) {
    return this.http.post<any>(this.baseUrl + '/recupera_farmacia_byId', id);
  }

  deleteFile(filesId) {
    return this.http.post<any>(this.baseUrl + '/elimina_farmacia', filesId);
  }

  inserisciFarmacia(farmacia) {
    return this.http.post<any>(this.baseUrl + '/creazione_farmacia', farmacia);
  }

  modificaFarmacia(farmacia) {
    return this.http.post<any>(this.baseUrl + '/modifica_farmacia?id=' + farmacia.id, farmacia);
  }

  ricercaEsclusiva(endpoint, page, size, search) {
    return this.http.get<any>(this.baseUrl + '/' + endpoint + '?page=' + page + '&size=' + size + '&ricerca=' + search );
  }


  chiamataWildix() {
    let clientId = 'oauth2-integrazione-0503525001664353218';

  let redirectUri = 'https://alfacloud.wildixin.com/v1/doc';

let authURI = "https://alfacloud.wildixin.com/authorization/oauth2"
    let params = new URLSearchParams();
    params.append('password','oU@!oQ8VLN');
    params.append('login','760');
    let headers =
      new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});

    return this.http.post<any>('https://alfacloud.wildixin.com/api/v1/personal/login', {'login':'760','password':'oU@!oQ8VLN'}, {withCredentials: true});
  }
  chiamataTokenWildix() {
    let params = new URLSearchParams();
    params.append('grant_type','authorization_code');
    params.append('password','oU@!oQ8VLN');
    params.append('login','760');
    params.append('client_id', 'oauth2-integrazione-0503525001664353218');
    // params.append('redirect_uri', 'https://alfacloud.wildixin.com/v1/doc');
    params.append('code','bDhTobGRiNX3#EprC!%zDvd2SEJ7XXVye##g#nNKUAlTJeuCknLCf7FiOx@mM6nF');
    let headers =
      new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});

    return this.http.get<any>('https://alfacloud.wildixin.com/api/v1/personal/Token/?type=JWT', {withCredentials: true});
  }


  chiamataCallHistory(dataInizio,dataFine, token, starts?) {
    let headers =
    new HttpHeaders({'Authorization': 'Bearer '+ token});
    if (starts) {
      return this.http.get<any>("https://alfacloud.wildixin.com/api/v1/PBX/CallHistory/?count=100000&filter[start][from]="+dataInizio+"&filter[start][to]="+dataFine+'&start=' + starts, {headers: headers})
    }
    return this.http.get<any>("https://alfacloud.wildixin.com/api/v1/PBX/CallHistory/?count=100000&filter[start][from]="+dataInizio+"&filter[start][to]="+dataFine, {headers: headers})
  }

  getNumbers() {
    return this.http.get<any>(this.baseUrl + '/get_all_user_numbers');
  }

}
