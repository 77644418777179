import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { BadgeService } from 'src/app/SERVICES/BADGE/badge.service';
import { AlertService } from 'src/app/SERVICES';
import { ConfigurazioneBadge } from './configurazione-badge';

@Component({
  selector: 'app-badge-config',
  templateUrl: './badge-config.component.html',
  styleUrls: ['./badge-config.component.scss']
})

export class BadgeConfigComponent implements OnInit {

  chiediFoto = true;
  distanzaMassima = 100;
  configurazione:ConfigurazioneBadge;

  constructor(
    private confirmationService: ConfirmationService,
    private badgeService: BadgeService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.badgeService.getConfiguration().subscribe((res) => {
      this.chiediFoto = res.data.photoRequired;
      this.distanzaMassima = res.data.distance;
    });
  }

  applica(){

    this.configurazione = {
      photoRequired:this.chiediFoto,
      distance: this.distanzaMassima
    }

    this.confirmationService.confirm({
      message: 'Applicare la nuova configurazione?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.badgeService.updateConfiguration(this.configurazione).subscribe((res)=>{
          this.alertService.success(res.status.description);
        })
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    })
  }

  responsiveWidth() {    }

  formatLabel(value: number) {

    if (value >= 1) {
  
  
      return Math.round(value) + 'm';
  
    }
  
  
    return value;
  }

}
