<!--Interazione con Konva-->
<div style="background-color: #EEEEEE;height: 100%;" id="allLavagna">
<!-- <div *ngIf="isEnableDraw()" class="padBottone">
    <button type="button" (click)="menu()" class="btn btn-success btn-simple" style="color: #2196f3;">
        <i class="material-icons notranslate pointer" style="font-size: 30px;">menu </i>
    </button>
    
</div> -->
<div>
    <p-button *ngIf='false' label="Salva immagine" (click)="salvaImmagine()"></p-button>
</div>


<div class="buttonShowList" (click)="toggleMenu()" *ngIf="ruoloUser==4||ruoloUser==1">
    <span class="material-icons expandMore" [@rotatedState]='state' >
        expand_more
    </span>
</div>
<div *ngIf="isEnableDraw() && showMenu" class="container pad" [@fadeInOut] #lavagnaMenu (mouseenter) ="inMenu()">
        <div [ngClass]="{'padOn': check,'padOff':!check}" >
        <div class="row" class="padBase" [ngStyle]="{'width.px': imageWidth()}" >
            <div class="col-sm-1 larghezza" >   <a *ngIf="isEnableDraw()" (click)="creaCerchio()" rel="tooltip" title="Cerchio"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">circle</i>
            </a></div>

            <div class="col-sm-1 larghezza " > <a *ngIf="isEnableDraw()" (click)="creaFreccia()" rel="tooltip" title="Freccia"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">arrow_upward</i>
            </a></div>

            <div class="col-sm-1 larghezza" > 
                <a *ngIf="isEnableDraw()" 
                (click)="abilitaPalettaImmagini()" rel="tooltip" title="Logo" class="btn btn-success btn-simple"
                [ngStyle]="{'color':palettaImmaginiAttiva()}"
                style="padding: 2px 2px 2px 2px;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">image</i>
            </a></div>

<div class="col-sm-1 larghezza" > <a *ngIf="isEnableDraw()" (click)="creaTesto()" rel="tooltip" title="Testo"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">text_fields</i>
            </a></div>
            
            <div class="col-sm-1 larghezza" >
                <a *ngIf="isEnableDraw()" (click)="creaLinea('penna')" rel="tooltip" title="Penna"
                [ngStyle]="{'color':(strumentoSelezionato=='penna'&&linea) ? 'green':'#2196f3'}"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">mode_edit</i>
            </a></div>

      
            <div class="col-sm-1 larghezza"> <a *ngIf="isEnableDraw()" (click)="creaLinea('gomma')" 
                [ngStyle]="{'color':(strumentoSelezionato=='gomma'&&linea) ? 'green':'#2196f3'}" rel="tooltip" title="Gomma"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">auto_fix_high</i>
            </a></div>

            <div class="col-sm-1 larghezza"> <a *ngIf="isEnableDraw()" (click)="attivaSeleziona()" 
                [ngStyle]="{'color':(strumentoSelezionato=='seleziona'&&seleziona) ? 'green':'#2196f3'}" rel="tooltip" title="Seleziona elementi"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">highlight_alt</i>
            </a></div>

            <div class="col-sm-1.5 " style="text-align: center;">  
                <label for="spessore-linea" style="width: 102px; text-align: center;">Spessore linea</label>
              <div class="form-group"style="margin:0 0 0 0 !important">
                <input type="number" style="text-align: center;" step="1" class="form-control" ngDefaultControl   (click)="changeAttrs()"  [(ngModel)]="sizeShape_StrokeWidth" [min]="2" [max]="36">
            </div>
        </div>

        <div class="col-sm-1.5 " style="text-align: center;" >     
            <label for="grandezza-testo" style="width: 102px; text-align: center;">Grandezza testo</label>
            <div class="form-group"style="margin:0 0 0 0 !important">
            <input type="number" style="text-align: center;" step="1" class="form-control" ngDefaultControl   (click)="changeAttrs()" [(ngModel)]="sizeText_FontSize" [min]="2" [max]="36">
        </div>
        </div>
        <!--COLORE-->

          
            <div class="col-sm-1 larghezza" > <a *ngIf="isEnableDraw()" (click)="pulisci()" rel="tooltip" title="Pulisci"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">delete_forever</i>
            </a></div>
            <div class="col-sm-1 larghezza" > <a *ngIf="isEnableDraw()" (click)="indietro()" rel="tooltip" title="Indietro"
                class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px;">
                <i class="material-icons notranslate pointer" style="font-size: 30px; color: #2196f3;">undo</i>
            </a></div>
            <div class="col-sm-1 larghezza">   <a *ngIf="isEnableDraw()" (click)="avanti()" rel="tooltip" title="Avanti" class="btn btn-success btn-simple"
                style="padding: 2px 2px 2px 2px; color: #2196f3;">
                <i class="material-icons notranslate pointer" style="font-size: 30px;">redo</i>
            </a>
    </div>
  
            <!-- <div class="col-sm-1 larghezza" >
                <input type="color" *ngIf="isEnableDraw()" (click)="changeAttrs()" ngDefaultControl [(ngModel)]="colorShape_Stroke" >
            </div> -->
    
           
        </div>
        </div>
    </div>  
    
    <!-- Konva -->
    <!-- Resize start -->
    <div class="center-whiteboard" [ngClass]="{'mt-10': checkOnlyFS()}" (mouseleave)="inMenu()">
        <div>
            <div id="lavagna" style="background: no-repeat;background-size: contain;"
                [ngStyle]="{'background-image':'url('+immagineIniziale+')','width':'auto','height':'auto'}"> 
            </div>
        </div>
    </div>
<!-- Resize end -->
<div [ngClass]="{'hidden':checkFS()}" *ngIf="(ruoloUser==4||ruoloUser==1)">
        <div style="display: flex;justify-content: center; margin-top: 35px;">
        <p-galleria [(value)]="immagini" [numVisible]="5" [containerStyle]="{'max-width': '800px'}">
            <ng-template pTemplate="thumbnail" let-immagine>
                <div id="drag-items">
                <img [src]="immagine.src" style="width: 100%;" draggable="true"/>
                </div>
            </ng-template>
        </p-galleria>
        </div>
    </div>
 </div>