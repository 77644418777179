<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content">
            <h4 class="card-title" *ngIf="!clienti">Report</h4>
            <h4 class="card-title" *ngIf="clienti">Report delle schede</h4>
            <ul class="nav nav-pills nav-pills-primary">
              <li [ngClass]="{ active: cr }">
                <a *ngIf="cr" href="#pill1" data-toggle="tab">Assistenza</a>
              </li>
              <li [ngClass]="{ active: !cr && geo }">
                <a *ngIf="geo" href="#pill2" data-toggle="tab"
                  >Geolocalizzazione</a
                >
              </li>
              <li [ngClass]="{ active: !cr && !geo && classroom }">
                <a *ngIf="classroom" href="#pill3" data-toggle="tab"
                  >Education</a
                >
              </li>
              <li [ngClass]="{ active: !cr && !geo && !classroom && iot }">
                <a *ngIf="iot" href="#pill4" data-toggle="tab">IoT</a>
              </li>
              <li [ngClass]="{ active: !cr && !geo && !classroom && !iot }">
                <a
                  *ngIf="ruolo === 1 && classroom"
                  href="#pill5"
                  data-toggle="tab"
                  >Customer Satisfaction</a
                >
              </li>
              <li
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && badge
                }"
              >
                <a
                  *ngIf="(ruolo === 1 || ruolo === 2) && badge"
                  href="#pill6"
                  data-toggle="tab"
                  >Controllo Accessi</a
                >
              </li>
              <li
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && alfaCallCenter
                }"
              >
                <a
                  *ngIf="(ruolo === 1 || ruolo === 2) && alfaCallCenter"
                  href="#pill7"
                  data-toggle="tab"
                  >Call Center</a
                >
              </li>
              <li
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && clienti
                }"
              >
                <a
                  *ngIf="ruolo === 1 && clienti"
                  href="#pill8"
                  data-toggle="tab"
                  >Eracoop</a
                >
              </li>
            </ul>
            <hr />
            <div class="tab-content">
              <div
                class="tab-pane"
                id="pill1"
                [ngClass]="{ active: cr }"
                *ngIf="cr"
              >
                <app-report-assistenza></app-report-assistenza>
              </div>

              <div
                class="tab-pane"
                id="pill2"
                [ngClass]="{ active: !cr && geo }"
                *ngIf="geo"
              >
                <app-report-geo></app-report-geo>
              </div>

              <div
                class="tab-pane"
                id="pill3"
                [ngClass]="{ active: !cr && !geo && classroom }"
                *ngIf="classroom"
              >
                <app-report-aula></app-report-aula>
              </div>

              <div
                class="tab-pane"
                id="pill4"
                [ngClass]="{ active: !cr && !geo && !classroom && iot }"
                *ngIf="iot"
              >
                <app-report-iot></app-report-iot>
              </div>
              <div
                class="tab-pane"
                id="pill5"
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && !alfaCallCenter
                }"
                *ngIf="ruolo === 1"
              >
                <app-report-satisfaction></app-report-satisfaction>
              </div>
              <div
                class="tab-pane"
                id="pill6"
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && badge
                }"
                *ngIf="ruolo === 1 || ruolo === 2"
              >
                <app-report-badge></app-report-badge>
              </div>
              <div
                class="tab-pane"
                id="pill7"
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && alfaCallCenter
                }"
                *ngIf="ruolo === 1 || ruolo === 2"
              >
                <app-report-callcenter></app-report-callcenter>
              </div>
              <div
                class="tab-pane"
                id="pill8"
                [ngClass]="{
                  active: !cr && !geo && !classroom && !iot && clienti
                }"
                *ngIf="ruolo === 1"
              >
                <app-report-eracoop></app-report-eracoop>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
