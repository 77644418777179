<div
  *ngIf="clienteId && loading"
  class="text-center"
  style="position: absolute; right: 0px"
>
  <p-progressSpinner
    [style]="{ width: '20px', height: '20px' }"
    strokeWidth="5"
    styleClass="custom-spinner2"
    animationDuration=".5s"
  ></p-progressSpinner>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <ng-container>
            <div class="card-header card-header-text">
              <h5 class="card-title">Modifica assistito</h5>
            </div>

            <div class="content">
              <mat-horizontal-stepper #stepper style="width: 90%">
                <form [formGroup]="editForm" class="form-horizontal">
                  <mat-step label="Anagrafica" state="form">
                    <div class="row">
                      <label class="col-sm-3 label-on-left">
                        <span class="ml-auto"
                          >Nome<span class="colorRed">*</span></span
                        >
                      </label>
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="nome"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.nome.errors
                            }"
                            maxlength="30"
                          />
                          <div
                            *ngIf="submitted && f.nome.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.nome.errors.required">
                              Campo nome obbligatorio
                            </div>
                            <div *ngIf="f.nome.errors.pattern">
                              Campo nome non valido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Cognome<span class="colorRed">*</span></span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="cognome"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.cognome.errors
                            }"
                            maxlength="30"
                          />
                          <div
                            *ngIf="submitted && f.cognome.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.cognome.errors.required">
                              Campo cognome obbligatorio
                            </div>
                            <div *ngIf="f.cognome.errors.pattern">
                              Campo cognome non valido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Codice fiscale<span class="colorRed">*</span></span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="codiceFiscale"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.codiceFiscale.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && f.codiceFiscale.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.codiceFiscale.errors.required">
                              Campo codice fiscale obbligatorio
                            </div>
                            <div *ngIf="f.codiceFiscale.errors.pattern">
                              Campo codice fiscale non valido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Età</span></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="number"
                            formControlName="eta"
                            class="form-control"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Sesso</span></label
                      >
                      <div class="col-sm-9">
                        <div
                          class="form-group label-floating is-empty select flex"
                        >
                          <label class="control-label"></label>
                          <select formControlName="sesso" class="form-control">
                            <option value="Maschio">Maschio</option>
                            <option value="Femmina">Femmina</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Città</span></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select formControlName="citta" class="form-control">
                            <option
                              *ngFor="let city of arrCitta"
                              [value]="city"
                            >
                              {{ city }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Occupazione</span></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select
                            formControlName="occupazione"
                            class="form-control"
                          >
                            <option value="occupato">Occupato</option>
                            <option value="disoccupato">Disoccupato</option>
                            <option value="studente">Studente</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Livello di istruzione</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select
                            formControlName="livelloDiIstruzione"
                            class="form-control"
                          >
                            <option value="Licenza elementare">
                              Licenza elementare
                            </option>
                            <option value="Licenza media">Licenza media</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Laurea">Laurea</option>
                            <option value="Post Laurea">Post Laurea</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Situazione economica</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="situazioneEconomica"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left aiBaseline mt-27"
                        ><span class="ml-auto">Diagnosi</span></label
                      >

                      <span
                        ><i
                          [@rotatedState]="statoDiagnosi"
                          class="material-icons notranslate mt-27 aiBaseline"
                          style="
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          (click)="cambiaStato('statoDiagnosi')"
                          >chevron_right</i
                        >
                      </span>
                      <div class="col-sm-9 flex mt-20">
                        <!-- <p-editor
                          [ngClass]="{ 'one-line': statoDiagnosi === 'default' }"
                          formControlName="diagnosi"
                          class="form-control"
                          [style]="{ height: '320px' }"
                        >
                          <ng-template pTemplate="header"> </ng-template
                        ></p-editor> -->
                        <textarea
                          formControlName="diagnosi"
                          class="form-control textArea"
                          rows="4"
                          cols="50"
                          [ngClass]="{
                            textAreaExpand: statoDiagnosi === 'rotated',
                            border: statoDiagnosi === 'rotated',
                            oneLine: statoDiagnosi === 'default'
                          }"
                        ></textarea>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Anno di presa in carico dal servizio</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select
                            formControlName="annoDiPresaInCaricoDalServizio"
                            class="form-control"
                          >
                            <option *ngFor="let year of years" [value]="year">
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >L'utente attualmente è in carico presso un altro
                          servizio?</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select
                            formControlName="infoCliente"
                            class="form-control"
                            (change)="showServizio()"
                            (change)="
                              setValidator('infoCliente', 'altroServizio')
                            "
                          >
                            <option value="si">Si</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="showInputServizio">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Quale?</span
                        ><span class="colorRed">*</span></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="altroServizio"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.altroServizio.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && f.altroServizio.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.altroServizio.errors.required">
                              Campo obbligatorio
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Modalità di accesso al servizio</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select
                            formControlName="inviante"
                            class="form-control"
                          >
                            <option
                              *ngFor="let inviante of arrInvianti"
                              [value]="inviante"
                            >
                              {{ inviante }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </mat-step>

                  <mat-step label="Servizi" state="form">
                    <div class="card-content">
                      <div class="row">
                        <label class="col-sm-3 label-on-left"
                          ><span class="ml-auto"
                            >Servizio di appartenenza</span
                          ></label
                        >
                        <div class="col-sm-9">
                          <div class="form-group label-floating is-empty">
                            <label class="control-label"></label>
                            <select
                              formControlName="serviziDiAppartenenza"
                              class="form-control"
                            >
                              <option
                                *ngFor="let s of listaServizi"
                                [value]="s.nomeServizio"
                              >
                                {{ s.nomeServizio }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <h4 class="centerText">
                          Azioni specifiche svolte nei relativi servizi
                        </h4>
                      </div>

                      <div class="row">
                        <!-- <label class="col-sm-3 label-on-left"
                          ><span class="ml-auto"
                            >Tipologia prevalente di attività svolte dalla coop
                            Era</span
                          ></label
                        > -->
                        <div class="col-sm-12">
                          <div class="form-group label-floating is-empty">
                            <label class="control-label"></label>
                            <!-- <p-selectButton
                              [options]="arrTipologiaAttivita"
                              formControlName="tipologiaAttivita"
                              multiple="multiple"
                            >
                            </p-selectButton> -->
                            <app-double-list
                              [inAccordion]="true"
                              [titleAccordion]="
                                'Tipologia prevalente di attività svolte dalla coop Era'
                              "
                              nomeLista1="Non selezionati"
                              nomeLista2="Selezionati"
                              [(lista1)]="arrTipologiaAttivita"
                              [(lista2)]="listaTipologiaAttivitaToSend"
                            ></app-double-list>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <!-- <label class="col-sm-3 label-on-left"
                          ><span class="ml-auto"
                            >Tipologia di laboratori/interventi attivati</span
                          ></label
                        > -->
                        <div class="col-sm-12">
                          <div class="form-group label-floating is-empty">
                            <label class="control-label"></label>
                            <!-- <p-selectButton
                              [options]="arrTipologiaLaboratori"
                              formControlName="tipologiaLaboratoriInterventi"
                              multiple="multiple"
                            >
                            </p-selectButton> -->
                            <app-double-list
                              [inAccordion]="true"
                              [titleAccordion]="
                                'Tipologia di laboratori/interventi attivati'
                              "
                              nomeLista1="Non selezionati"
                              nomeLista2="Selezionati"
                              [(lista1)]="arrTipologiaLaboratori"
                              [(lista2)]="listaLaboratoriInterventiToSend"
                            ></app-double-list>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-sm-3 label-on-left"
                          ><span class="ml-auto"
                            >Le attività vengono svolte con altre
                            istituzioni/associazioni?</span
                          ></label
                        >
                        <div class="col-sm-9">
                          <div class="form-group label-floating is-empty">
                            <label class="control-label"></label>
                            <select
                              formControlName="infoAltraAssociazione"
                              class="form-control"
                              (change)="showAssociazione()"
                              (change)="
                                setValidator(
                                  'infoAltraAssociazione',
                                  'altraAssociazione'
                                )
                              "
                            >
                              <option value="si">Si</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="showInputAssociazione">
                        <label class="col-sm-3 label-on-left"
                          ><span class="ml-auto">Quali?</span
                          ><span class="colorRed">*</span></label
                        >
                        <div class="col-sm-9">
                          <div class="form-group label-floating is-empty">
                            <label class="control-label"></label>
                            <input
                              type="text"
                              formControlName="altraAssociazione"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid':
                                  submitted && f.altraAssociazionerors
                              }"
                            />
                            <div
                              *ngIf="
                                submitted && f.altraAssociazione.errors.required
                              "
                              class="invalid-feedback"
                            >
                              Campo obbligatorio
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-sm-3 label-on-left"
                          ><span class="ml-auto"
                            >Tali partenariati sono formalizzati da protocolli
                            di intesa con la cooperativa Era?</span
                          >
                        </label>
                        <div class="col-sm-9">
                          <div class="form-group label-floating is-empty">
                            <label class="control-label"></label>
                            <select
                              formControlName="partenariati"
                              class="form-control"
                            >
                              <option value="si">Si</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-step>

                  <mat-step label="Analisi" state="form">
                    <div class="row">
                      <label class="col-sm-3 label-on-left tooltip-label aiBaseline mt-27"
                        ><span
                          class="ml-auto"
                          title="Utili alla conoscenza della storia individuale, familiare e di contesto, delle abilità e dei bisogni"
                          >Elementi storici, note e specifiche particolari</span
                        ></label
                      >
                      
                      <span
                        ><i
                          [@rotatedState]="statoNote"
                          class="material-icons notranslate mt-27 aiBaseline"
                          style="
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          (click)="cambiaStato('statoNote')"
                          >chevron_right</i
                        >
                        </span>
                      <div class="col-sm-9 flex mt-20">
                        <textarea
                          formControlName="note"
                          class="form-control textArea"
                          rows="4"
                          cols="50"
                          [ngClass]="{
                            textAreaExpand: statoNote === 'rotated',
                            border: statoNote === 'rotated',
                            oneLine: statoNote === 'default'
                          }"
                        ></textarea>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Analisi dei bisogni</span></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="analisiBisogni"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div> -->

                    <div class="row" >
                      <label class="col-sm-3 label-on-left aiBaseline mt-27"
                        ><span class="ml-auto">Analisi dei bisogni</span></label
                      >
                      <span
                        ><i
                          [@rotatedState]="statoAnalisi"
                          class="material-icons notranslate mt-27 aiBaseline"
                          style="
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          (click)="cambiaStato('statoAnalisi')"
                          >chevron_right</i
                        >
                      </span>
                      <div class="col-sm-9 flex mt-20">
                        <textarea
                        formControlName="analisiBisogni"
                        class="form-control textArea"
                        rows="4"
                        cols="50"
                        [ngClass]="{
                          textAreaExpand: statoAnalisi === 'rotated',
                          border: statoAnalisi === 'rotated',
                          oneLine: statoAnalisi === 'default'
                        }"
                      ></textarea>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <label class="col-sm-3 label-on-left tooltip-label">
                        <span
                          class="ml-auto"
                          title="Umane, economiche, personali"
                          >Analisi delle risorse</span
                        >
                      </label>
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="analisiRisorse"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div> -->

                    <div
                      class="row"
                    >
                      <label class="col-sm-3 label-on-left tooltip-label aiBaseline mt-27"
                        ><span
                          class="ml-auto"
                          title="Umane, economiche, personali"
                          >Analisi delle risorse</span
                        ></label
                      >
                      <span
                        ><i
                          [@rotatedState]="statoAnalisiRisorse"
                          class="material-icons notranslate mt-27 aiBaseline"
                          style="
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          (click)="cambiaStato('statoAnalisiRisorse')"
                          >chevron_right</i
                        >
                      </span>
                      <div class="col-sm-9 flex mt-20">
                        <textarea
                          formControlName="analisiRisorse"
                          class="form-control textArea"
                          rows="4"
                          cols="50"
                          [ngClass]="{
                            textAreaExpand: statoAnalisiRisorse === 'rotated',
                            border: statoAnalisiRisorse === 'rotated',
                            oneLine: statoAnalisiRisorse === 'default'
                          }"
                        ></textarea>
                      </div>
                    </div>

                    <div class="row">
                      <!-- <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Obiettivi generali</span></label
                      > -->
                      <div class="col-sm-12">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <!-- <p-selectButton
                            [options]="arrObiettivi"
                            formControlName="obiettiviGenerali"
                            multiple="multiple"
                          >
                          </p-selectButton> -->
                          <app-double-list
                            [inAccordion]="true"
                            [titleAccordion]="'Obiettivi generali'"
                            nomeLista1="Non selezionati"
                            nomeLista2="Selezionati"
                            [(lista1)]="arrObiettivi"
                            [(lista2)]="listaObiettiviToSend"
                          >
                          </app-double-list>
                        </div>
                      </div>
                    </div>

                    <ng-template
                      [ngTemplateOutlet]="obiettiviSpecificiTemplate"
                    >
                    </ng-template>

                    <!-- <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Metodologia specifica</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="metodologiaSpecifica"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div> -->

                    <div
                      class="row"
                    >
                      <label class="col-sm-3 label-on-left aiBaseline mt-27"
                        ><span class="ml-auto"
                          >Metodologia specifica</span
                        ></label
                      >
                      <span
                        ><i
                          [@rotatedState]="statoMetodologiaSpecifica"
                          class="material-icons notranslate mt-27 aiBaseline"
                          style="
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          (click)="cambiaStato('statoMetodologiaSpecifica')"
                          >chevron_right</i
                        >
                      </span>
                      <div class="col-sm-9 flex mt-20">
                        <textarea
                          formControlName="metodologiaSpecifica"
                          class="form-control textArea"
                          rows="4"
                          cols="50"
                          [ngClass]="{
                            textAreaExpand: statoMetodologiaSpecifica === 'rotated',
                            border: statoMetodologiaSpecifica === 'rotated',
                            oneLine: statoMetodologiaSpecifica === 'default'
                          }"
                        ></textarea>
                      </div>
                    </div>

                    <div class="row">
                      <h4 class="centerText">
                        <span class="ml-auto">Verifica</span>
                      </h4>
                    </div>

                    <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto"
                          >Obiettivi raggiunti?</span
                        ></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <select
                            formControlName="obiettiviRaggiunti"
                            class="form-control"
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                            <option value="Parzialmente">Parzialmente</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <label class="col-sm-3 label-on-left"
                        ><span class="ml-auto">Breve descrizione</span></label
                      >
                      <div class="col-sm-9">
                        <div class="form-group label-floating is-empty">
                          <label class="control-label"></label>
                          <input
                            type="text"
                            formControlName="descrizioneObiettivi"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div> -->

                    <div
                      class="row"
                    >
                      <label class="col-sm-3 label-on-left aiBaseline mt-27"
                        ><span class="ml-auto">Breve descrizione</span></label
                      >
                      
                      <span
                        ><i
                          [@rotatedState]="statoDescrizioneObiettivi"
                          class="material-icons notranslate mt-27 aiBaseline"
                          style="
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          (click)="cambiaStato('statoDescrizioneObiettivi')"
                          >chevron_right</i
                        >
                      </span>
                      <div class="col-sm-9 flex mt-20">
                        <textarea
                        formControlName="descrizioneObiettivi"
                        class="form-control textArea"
                        rows="4"
                        cols="50"
                        [ngClass]="{
                          textAreaExpand: statoDescrizioneObiettivi === 'rotated',
                          border: statoDescrizioneObiettivi === 'rotated',
                          oneLine: statoDescrizioneObiettivi === 'default'
                        }"
                      ></textarea>
                      </div>
                    </div>
                  </mat-step>
                </form>
              </mat-horizontal-stepper>

              <div class="table">
                <div class="tr">
                  <hr />

                  <!-- Indietro da Desktop -->
                  <div
                    class="btn-group d1"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      *ngIf="stepper.selectedIndex != 0 && isDesktop"
                      (click)="goBack(stepper)"
                      class="btn btn-secondary w150p"
                      style="margin-left: 14px"
                      aria-pressed="true"
                    >
                      Indietro
                    </button>
                  </div>

                  <!-- Annulla/Salva da Desktop -->
                  <div
                    *ngIf="isDesktop"
                    class="btn-group d2"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      (click)="indietro(true)"
                      class="btn btn-secondary w150p"
                      aria-pressed="true"
                      style="margin-right: 3px"
                    >
                      Annulla
                    </button>

                    <button
                      (click)="onSubmit()"
                      class="btn btn-primary w150p"
                      [disabled]="loading"
                    >
                      Salva assistito
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>
                  </div>

                  <!-- Avanti da Desktop -->
                  <div
                    *ngIf="isDesktop"
                    class="btn-group d3"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      *ngIf="stepper.selectedIndex != 2"
                      (click)="goForward(stepper)"
                      class="btn btn-primary w150p"
                      aria-pressed="true"
                    >
                      Avanti
                    </button>
                  </div>
                </div>
              </div>

              <!-- Mobile -->
              <div class="table">
                <div class="tr">
                  <!-- Indietro da Mobile -->
                  <div
                    class="btn-group d3-mobile-navigation"
                    *ngIf="!isDesktop"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      *ngIf="!isDesktop"
                      (click)="goBack(stepper)"
                      class="btn btn-secondary mr-3"
                      aria-pressed="true"
                      [disabled]="stepper.selectedIndex == 0"
                    >
                      <
                    </button>

                    <!-- Avanti da Mobile -->
                    <button
                      (click)="goForward(stepper)"
                      class="btn btn-primary"
                      aria-pressed="true"
                      [disabled]="stepper.selectedIndex == 2"
                    >
                      >
                    </button>
                  </div>
                </div>
                <div class="tr">
                  <!-- Annulla/Salva da Mobile -->
                  <div
                    *ngIf="!isDesktop"
                    class="btn-group d3-mobile-undo-save"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      (click)="indietro(true)"
                      class="btn btn-secondary w120p mr-3"
                      aria-pressed="true"
                    >
                      Annulla
                    </button>

                    <button
                      (click)="onSubmit()"
                      class="btn btn-primary w120p"
                      [disabled]="loading"
                    >
                      Salva assistito
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #obiettiviSpecificiTemplate>
  <div *ngFor="let obiettivo of obiettiviSpecifici; let i = index">
    <div class="row">
      <div class="col-sm-3 obtSpf">
        <i
          [@rotatedState]="stato[i]"
          class="material-icons notranslate openBtn"
          (click)="showAzioni(i)"
        >
          chevron_right
        </i>
        <label class="label-on-left tooltip-label"
          ><span class="ml-auto" title="Dal momento della compilazione a 4 mesi"
            >Obiettivi specifici</span
          ></label
        >
      </div>
      <div class="col-sm-8">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input
            type="text"
            [(ngModel)]="obiettivo.title"
            class="form-control"
          />
        </div>
      </div>
      <div class="centered col-sm-1">
        <div
          class="btn btn-simple btn-primary btn-icon add-delete-btns"
          (click)="addObiettivo()"
        >
          <i class="material-icons pointer fs-20">add</i>
        </div>
        <div
          class="btn btn-simple btn-danger btn-icon ml-10 add-delete-btns"
          (click)="removeObiettivo(i)"
        >
          <i class="material-icons pointer fs-20">delete</i>
        </div>
      </div>
    </div>
    <ng-container
      *ngFor="
        let azione of obiettivo.azioniRealizzazione;
        let j = index;
        trackBy: returnTrack
      "
    >
      <div
        class="row sfondoColor"
        *ngIf="stato[i] === 'rotated'"
        [ngClass]="{
          borderBottomSfondoColor: showBorderBottom(
            j,
            obiettivo.azioniRealizzazione.length
          )
        }"
      >
        <label class="col-sm-3 label-on-left tooltip-label">
          <span class="ml-auto" title="Strumenti utilizzati"
            >Azioni utili per la realizzazione</span
          ></label
        >
        <div class="col-sm-8">
          <div class="form-group label-floating is-empty">
            <label class="control-label"></label>
            <input
              type="text"
              [(ngModel)]="obiettivo.azioniRealizzazione[j]"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-1 centered">
          <div
            class="btn btn-simple btn-primary btn-icon add-delete-btns"
            (click)="addAzione(obiettivo)"
          >
            <i class="material-icons pointer fs-20">add</i>
          </div>
          <div
            class="btn btn-simple btn-danger btn-icon ml-10 add-delete-btns"
            (click)="removeAzione(obiettivo, j)"
          >
            <i class="material-icons pointer fs-20">delete</i>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
