import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { AccountService, UsersService } from 'src/app/SERVICES';


@Component({
  selector: 'app-gestione-profili',
  templateUrl: './gestione-profili.component.html',
  styleUrls: ['./gestione-profili.component.scss']
})
export class GestioneProfiliComponent implements OnInit,DoCheck {
  
  //visibilità cmp
  gestione=true;//padre
  dettagli=false;
  modifica=false;
  gestioneProfili : boolean = false;
  listaRuoli = [];
  ruoli: SelectItem[];
  
  id;
  idUtente;

  constructor(
    private userService: UsersService,
    private accountService : AccountService,
    private routing : Router
    )
    {
    this.ruoli = [];
    this.userService.getListaRuoli().subscribe((res) => {

      this.listaRuoli = res.data;      
      this.listaRuoli.forEach(ruolo =>{
        this.ruoli.push(
          {label:ruolo.nomeRuolo, value:{id:ruolo.id}}
        )
      })
      this.gestioneProfili = true;
    }, (error) => {
    
    });
  }

  ngOnInit(): void {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
  }
  ngDoCheck(): void {
    if(this.accountService.listaEnv && (this.accountService.ruolo !== 1)){
      this.routing.navigate(['./'])
    }  }
  view(id){//riceve id ruolo, rende visibile cmp dettagli profilo
    this.id = id;
    this.gestione=false;
    this.dettagli=true;

  }

  edit(id){//da dettagli profilo, riceve id di Utente che si vuole modificare, fa accedere a modifica utente

    this.idUtente=id;    
    this.gestione=false;
    this.dettagli=false;
    this.modifica=true;

  }

  vaiDettagli(vai){//da modifica utente, fa tornare a gestione profili

    this.gestione=false;
    this.modifica=false;
    this.dettagli=true;

  }

  back(vai){//da dettagli profilo fa tornare a gestione profili

    this.gestione=true;
    this.dettagli=false;

  }





}
