import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { Cliente, ObiettiviSpecifici } from 'src/app/MODELS/USER/cliente';
import { AlertService, UsersService } from 'src/app/SERVICES';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';
import { MatStepper } from '@angular/material/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';
import JsonCitta from 'src/assets/json/italian_cities.json';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-modifica-assistito',
  templateUrl: './modifica-assistito.component.html',
  styleUrls: ['./modifica-assistito.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('120ms ease-out')),
      transition('default => rotated', animate('120ms ease-in')),
    ]),
  ],
})
export class ModificaAssistitoComponent implements OnInit {
  @Output() tornaGestioneCliente = new EventEmitter<boolean>();
  @Input() clienteId;
  @ViewChild('stepper') stepper: MatStepper;
  id;
  listaTipologiaAttivitaToSend = [];
  listaLaboratoriInterventiToSend = [];
  listaObiettiviToSend = [];
  editForm: FormGroup;
  showInputAssociazione = false;
  showInputServizio = false;
  submitted = false;
  cliente: Cliente;
  stato = ['default'];
  loading = false;
  listaServizi = [];
  currentYear;
  years: Number[] = [];
  isDesktop = true;
  obiettiviSpecifici: ObiettiviSpecifici[] = [];

  arrInvianti = [
    'Servizi sociali territoriali',
    'Scuola',
    'Accesso diretto',
    'Medico curante/psichiatra',
    'Tribunale',
    'UEPE',
    'Accesso spontaneo',
  ];

  arrObiettivi = [];
  arrTipologiaAttivita = [];
  arrTipologiaLaboratori = [];

  showDiagnosi = false;
  statoDiagnosi = 'default';
  showNote = false;
  statoNote = 'default';
  showAnalisi = false;
  statoAnalisi = 'default';
  showAnalisiRisorse = false;
  statoAnalisiRisorse = 'default';
  showMetodologiaSpecifica = false;
  statoMetodologiaSpecifica = 'default';
  showDescrizioneObiettivi = false;
  statoDescrizioneObiettivi = 'default';

  arrCitta = JsonCitta;

  constructor(
    private primengConfig: PrimeNGConfig,
    private clienteService: ClienteService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService,
    private userService: UsersService
  ) {
    this.editForm = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
          Validators.maxLength(255),
        ],
      ],
      cognome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
          Validators.maxLength(255),
        ],
      ],
      codiceFiscale: [
        '',
        [
          Validators.pattern(
            '^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$'
          )
        ],
      ],
      eta: [null, [Validators.max(99), Validators.min(0)]],
      sesso: [''],
      citta: [''],
      occupazione: [''],
      livelloDiIstruzione: [''],
      situazioneEconomica: ['', Validators.maxLength(1024)],
      diagnosi: ['', Validators.maxLength(1024)],
      annoDiPresaInCaricoDalServizio: [''],
      infoAltraAssociazione: [''],
      altraAssociazione: [''],
      infoCliente: [''],
      altroServizio: [''],
      serviziDiAppartenenza: [''],
      tipologiaAttivita: [[]],
      tipologiaLaboratoriInterventi: [[]],
      partenariati: [''],
      inviante: [null],
      note: ['', Validators.maxLength(1024)],
      analisiBisogni: ['', Validators.maxLength(1024)],
      analisiRisorse: ['', Validators.maxLength(1024)],
      obiettiviGenerali: [[]],
      metodologiaSpecifica: ['', Validators.maxLength(1024)],
      obiettiviRaggiunti: [''],
      descrizioneObiettivi: ['', Validators.maxLength(1024)],
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.isDesktop = this.deviceService.isDesktop();
    this.currentYear = new Date().getFullYear();
    for (let year = this.currentYear; year >= 1980; year--) {
      this.years.push(year);
    }

    this.clienteService.getListaServizi().subscribe(
      (res) => {
        this.listaServizi = res;
      },
      (error) => {}
    );

    this.primengConfig.ripple = true;
    this.id = this.clienteId;

    this.clienteService.getInfoCliente(this.clienteId).subscribe((res) => {
      this.cliente = res.data;
      this.checkSelected(
        this.cliente.obiettiviGenerali,
        'listaObiettiviToSend',
        'arrObiettivi'
      );
      this.checkSelected(
        this.cliente.tipologiaAttivita,
        'listaTipologiaAttivitaToSend',
        'arrTipologiaAttivita'
      );
      this.checkSelected(
        this.cliente.tipologiaLaboratoriInterventi,
        'listaLaboratoriInterventiToSend',
        'arrTipologiaLaboratori'
      );
      if (
        this.cliente.obiettiviSpecifici &&
        this.cliente.obiettiviSpecifici.length > 0
      ) {
        this.obiettiviSpecifici = this.cliente.obiettiviSpecifici;
      } else {
        this.obiettiviSpecifici.push({
          title: '',
          azioniRealizzazione: [''],
        });
      }

      this.f.nome.setValue(this.cliente.nome);
      this.f.cognome.setValue(this.cliente.cognome);
      this.f.codiceFiscale.setValue(this.cliente.codiceFiscale);
      this.f.eta.setValue(this.cliente.eta);
      if (this.cliente.sesso != null && this.cliente.sesso.length != 0) {
        this.f.sesso.setValue(
          this.cliente.sesso.charAt(0).toUpperCase() +
            this.cliente.sesso.substr(1).toLowerCase()
        );
      } else {
        this.f.sesso.setValue('');
      }

      this.f.citta.setValue(this.cliente.citta);
      this.f.occupazione.setValue(this.cliente.occupazione);
      this.f.livelloDiIstruzione.setValue(this.cliente.livelloDiIstruzione);
      this.f.situazioneEconomica.setValue(this.cliente.situazioneEconomica);
      this.f.diagnosi.setValue(this.cliente.diagnosi);
      this.f.annoDiPresaInCaricoDalServizio.setValue(
        this.cliente.annoDiPresaInCaricoDalServizio
      );
      this.f.infoAltraAssociazione.setValue(this.cliente.infoAltraAssociazione);
      this.f.altraAssociazione.setValue(this.cliente.altraAssociazione);
      this.f.infoCliente.setValue(this.cliente.infoCliente);
      this.f.altroServizio.setValue(this.cliente.altroServizio);
      this.f.serviziDiAppartenenza.setValue(this.cliente.serviziDiAppartenenza);
      this.f.tipologiaAttivita.setValue(this.cliente.tipologiaAttivita);
      this.f.tipologiaLaboratoriInterventi.setValue(
        this.cliente.tipologiaLaboratoriInterventi
      );
      // this.listaTipologiaAttivitaToSend = this.getListFromArray(this.cliente.tipologiaAttivita,'nomeAttivita');
      // this.checkArray(this.listaTipologiaAttivitaToSend,this.arrTipologiaAttivita)

      // this.listaLaboratoriInterventiToSend = this.getListFromArray(this.cliente.tipologiaLaboratoriInterventi,'nomeLaboratoriInterventi')
      // this.checkArray(this.listaLaboratoriInterventiToSend,this.arrTipologiaLaboratori)

      this.f.partenariati.setValue(this.cliente.partenariati);
      this.f.inviante.setValue(this.cliente.inviante);
      this.f.note.setValue(this.cliente.note);
      this.f.analisiBisogni.setValue(this.cliente.analisiBisogni);
      this.f.analisiRisorse.setValue(this.cliente.analisiRisorse);
      this.f.obiettiviGenerali.setValue(this.cliente.obiettiviGenerali);

      // this.listaObiettiviToSend = this.getListFromArray(this.cliente.obiettiviGenerali, 'nomeObiettivo');
      // this.checkArray(this.listaObiettiviToSend,this.arrObiettivi)

      this.f.metodologiaSpecifica.setValue(this.cliente.metodologiaSpecifica);
      this.f.obiettiviRaggiunti.setValue(this.cliente.obiettiviRaggiunti);
      this.f.descrizioneObiettivi.setValue(this.cliente.descrizioneObiettivi);
      this.showServizio();
      this.showAssociazione();
      if (
        this.cliente.infoAltraAssociazione &&
        this.cliente.infoAltraAssociazione === 'si'
      ) {
        this.setValidator('infoAltraAssociazione', 'altraAssociazione');
      }
      if (this.cliente.infoCliente && this.cliente.infoCliente === 'si') {
        this.setValidator('infoCliente', 'altroServizio');
      }
      this.loading = false;
    });

    this.primengConfig.ripple = true;
  }

  checkSelected(allLista, listaSelected, listaNotSelected) {
    allLista.forEach((element) => {
      if (element.selected === true) {
        this[listaSelected].push(element);
      } else {
        this[listaNotSelected].push(element);
      }
    });
  }

  getListFromArray(element, key) {
    let array = [];
    element.forEach((element) => {
      array.push(element[key]);
    });
    return array;
  }
  getArrayFromlist(element, key) {
    let array = [];
    element.forEach((element) => {
      array.push({ cliente: { idCliente: this.clienteId } });
      array[array.length - 1][key] = element;
    });
    return array;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    if (this.editForm.invalid) {
      this.loading = false;
      return;
    }

    let newCliente = {
      idCliente: this.clienteId,
      nome: this.editForm.value.nome,
      cognome: this.editForm.value.cognome,
      codiceFiscale: this.editForm.value.codiceFiscale,
      sesso: this.editForm.value.sesso,
      eta: this.editForm.value.eta,
      citta: this.editForm.value.citta,
      occupazione: this.editForm.value.occupazione,
      livelloDiIstruzione: this.editForm.value.livelloDiIstruzione,
      situazioneEconomica: this.editForm.value.situazioneEconomica,
      diagnosi: this.editForm.value.diagnosi,
      annoDiPresaInCaricoDalServizio:
        this.editForm.value.annoDiPresaInCaricoDalServizio,
      infoAltraAssociazione: this.editForm.value.infoAltraAssociazione,
      altraAssociazione: this.editForm.value.altraAssociazione,
      infoCliente: this.editForm.value.infoCliente,
      altroServizio: this.editForm.value.altroServizio,
      serviziDiAppartenenza: this.editForm.value.serviziDiAppartenenza,
      tipologiaAttivita: this.setTrue('listaTipologiaAttivitaToSend'),
      tipologiaLaboratoriInterventi: this.setTrue(
        'listaLaboratoriInterventiToSend'
      ),
      partenariati: this.editForm.value.partenariati,
      inviante: this.editForm.value.inviante,
      note: this.editForm.value.note,
      analisiBisogni: this.editForm.value.analisiBisogni,
      analisiRisorse: this.editForm.value.analisiRisorse,
      obiettiviGenerali: this.setTrue('listaObiettiviToSend'),
      obiettiviSpecifici: this.obiettiviSpecifici,
      metodologiaSpecifica: this.editForm.value.metodologiaSpecifica,
      obiettiviRaggiunti: this.editForm.value.obiettiviRaggiunti,
      descrizioneObiettivi: this.editForm.value.descrizioneObiettivi,
    };

    this.clienteService.editCliente(this.clienteId, newCliente).subscribe(
      (res) => {
        this.loading = false;
        this.alertService.success(res.status.description);
        this.indietro();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setTrue(lista) {
    this[lista].forEach((element) => {
      element.selected = true;
    });
    return this[lista];
  }

  get f() {
    return this.editForm.controls;
  }

  showServizio() {
    if (
      this.editForm.value.infoCliente &&
      this.editForm.value.infoCliente.toLowerCase() === 'Sì'.toLowerCase()
    ) {
      this.showInputServizio = true;
      this.f.altroServizio.setValue(this.cliente.altroServizio);
    } else {
      this.cliente.altroServizio = this.editForm.value.altroServizio;
      this.showInputServizio = false;
    }
  }

  showAssociazione() {
    if (
      this.editForm.value.infoAltraAssociazione &&
      this.editForm.value.infoAltraAssociazione.toLowerCase() ===
        'Sì'.toLowerCase()
    ) {
      this.showInputAssociazione = true;
      this.f.altraAssociazione.setValue(this.cliente.altraAssociazione);
    } else {
      this.cliente.altraAssociazione = this.editForm.value.altraAssociazione;
      this.showInputAssociazione = false;
    }
  }

  setValidator(paramname, obj) {
    const form = this.editForm;
    const campo = form.get(paramname);
    const answer = form.get(obj);

    if (campo.value === 'si') {
      answer.setValidators([Validators.required, Validators.maxLength(1024)]);
    } else {
      answer.clearValidators();
      answer.reset();
    }
    answer.updateValueAndValidity();
  }

  indietro(vai?) {
    this.tornaGestioneCliente.emit(true);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  showAzioni(i) {
    this.stato[i] = this.stato[i] === 'default' ? 'rotated' : 'default';
  }

  cambiaStatoDiagnosi() {
    this.statoDiagnosi =
      this.statoDiagnosi === 'default' ? 'rotated' : 'default';
  }

  cambiaStatoNote() {
    this.statoNote = this.statoNote === 'default' ? 'rotated' : 'default';
  }

  cambiaStato(stato) {
    this[stato] = this[stato] === 'default' ? 'rotated' : 'default';
  }

  addObiettivo() {
    this.obiettiviSpecifici.push(new ObiettiviSpecifici('', ['']));
  }

  removeObiettivo(i: number) {
    if (this.obiettiviSpecifici.length > 1) {
      this.obiettiviSpecifici.splice(i, 1);
    } else {
      this.alertService.error(
        'Impossibile eliminare, è richiesto almeno un campo obiettivo specifico'
      );
    }
  }

  addAzione(obiettivo: ObiettiviSpecifici) {
    obiettivo.azioniRealizzazione.push('');
  }

  removeAzione(obiettivo: ObiettiviSpecifici, j: number) {
    if (obiettivo.azioniRealizzazione.length > 1) {
      obiettivo.azioniRealizzazione.splice(j, 1);
    } else {
      this.alertService.error(
        "E' richiesto almeno un campo azioni per ogni obiettivo specifico"
      );
    }
  }
  returnTrack(index, item) {
    return index;
  }

  showBorderBottom(j, l) {
    return j === l - 1;
  }

  checkArray(arrToSend, arrToCheck) {
    arrToSend.forEach((element) => {
      if (arrToCheck.includes(element)) {
        arrToCheck.splice(arrToCheck.indexOf(element), 1);
      }
    });
  }

  removeOneLine(id) {
    let element = document.getElementById(id);
    element.classList.remove('one-line');
  }
}
