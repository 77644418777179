
export class Posizione {
    data?: string;
    fine?: string;
    nome?: string;
    cognome?: string;
    ruolo?: string;
    gruppo?: string;
    latitudine?: string;
    longitudine?: string;
    comune?: string;
    indirizzo?: string;
    dettaglio?: string;
}