import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import {Message} from 'primeng/api';

@Component({ selector: 'alert', templateUrl: 'alert.component.html',providers: [MessageService] })
export class AlertComponent implements OnInit, OnDestroy {
    msgs : Message[] = [];


    constructor(
      private router: Router,
      private alertService: AlertService,
      private primengConfig: PrimeNGConfig,
      private messageService: MessageService
      ) { }

    ngOnInit() {
      this.primengConfig.ripple = true;
      this.alertService.alertOpen$.subscribe((res)=>{

          if(res){
            if(res.type==='info' && res.detail !== 'noSticky'){
            this.messageService.add({severity:res.type, summary:res.summary, detail:res.detail , sticky:true});
          }
          else{
            if (res.detail === 'noSticky') {
              this.messageService.add({severity:res.type, summary:res.summary, detail:''});
            } else {
              this.messageService.add({severity:res.type, summary:res.summary, detail:res.detail});
            }
          }
          // this.msgs = [{severity:res.type, summary:res.summary, detail:res.detail}];
        }else{
          this.msgs=[];
        }
      })
        }



    ngOnDestroy() {

    }

}
