
import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, ViewChild, ViewEncapsulation, OnDestroy, } from '@angular/core';

import { loadScript } from './loadScript';

import { EmailEditorModule } from 'angular-email-editor';
import { of } from 'rxjs';
import { EnvService } from 'src/app/SERVICES';


declare module unlayer {
  function init(object);
  function createEditor(object);
  function loadDesign(object);
  function saveDesign(Function);
  function exportHtml(Function);
}

export interface UnlayerOptions {
  projectId?: number;
  tools?: object;
  appearance?: object;
  locale?: string;
}

let lastEditorId = 0;

@Component({
  selector: 'app-news-letter-builder',
  templateUrl: './news-letter-builder.component.html',
  styleUrls: ['./news-letter-builder.component.scss']
})


export class NewsLetterBuilderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() editorId: string;
  @Input() options: UnlayerOptions = {};
  @Input() projectId: number;
  @Input() tools: object;
  @Input() appearance: object;
  @Input() locale: string;
  @Input() id: string;
  @Input() templateScelto;
  @Input() minHeight = '750px';
  // @Input() minWidth = '1360px';

  @Input() richiestaObject;
  @Output() returnObj = new EventEmitter<any>()
  @Output() caricato = new EventEmitter<boolean>()
  editor: any;
  setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  // private _jsonURL = './assets/template/TemplateNr1.json';
  template;
  html
  json
  btnSave
  subHtml
  subJson
  oggetto; //html json e una booleana di ritorno nel componente padre
  editortemplate = false
  test;
  alfa
  constructor(  private env : EnvService,) {
    this.id = this.editorId || `editor-${++lastEditorId}`;
this.alfa=this.env.alfaintes
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.richiestaObject) {
      this.salva();
    }
  }



  ngOnInit(): void {
  }

  ngAfterViewInit() {
    //carico il template i set timeout è dovuto al fatto che deve prima caricare la libreria
    loadScript(this.loadEditor.bind(this));
    setTimeout(async () => {
      await this.editor.loadDesign(this.templateScelto);
      this.caricato.emit(true);
  }, 5000);
  }

  //metodi della libreria 
  protected loadEditor() {
    const options: UnlayerOptions = this.options || {};
    if (this.projectId) {
      options.projectId = this.projectId;
    }
    if (this.tools) {
      options.tools = this.tools;
    }

    if (this.appearance) {
      options.appearance = this.appearance;
    }

    if (this.locale) {
      options.locale = this.locale;
    }

    this.editor = unlayer.createEditor({
      ...options,
      id: this.id,
      displayMode: 'email',
      locale: "it-IT",
      source: {
        name: "pkg.name",
        version: "pkg.version",
      },
      tools: {
      },
      // fonts: {
      //   showDefaultFonts: true,
      //   customFonts: [
      //     {
      //       label: "Comic Sans",
      //       value: "'Comic Sans MS', cursive, sans-serif"
      //     },
      //     {
      //       label: "Lobster Two",
      //       value: "'Lobster Two',cursive",
      //       url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
      //     }
      //   ]
      // }
    });

  }

  async export() {
    return new Promise((resolve, reject) => {
      this.editor.exportHtml((data) => {
        this.richiestaObject=false;
        resolve(data)
      }, error => {
        reject(error)
      })
      //this.oggettodiRitorno.emit(data)
    })
  }

  async salva() {
    this.returnObj.emit(await this.export());
  }

  //metodi per salvare fisicamente
  salvaFisicamente() {
    //questo metodo serve nel caso si voglia salvare fisicamente il json ad esempio per creare un template
    this.editor.saveDesign((data) => {
      this.fakeValidateUserData().subscribe((res) => {
        this.dyanmicDownloadByHtmlTag({
          fileName: 'My Report.json',
          text: JSON.stringify(data)
        });
      });
    })
  }
  
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
  fakeValidateUserData() {
    return of({
      userDate1: 1,
      userData2: 2
    });
  }


  
}
