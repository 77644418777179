<div class="mb-10">
  <mat-tab-group mat-align-tabs="start" (selectedIndexChange)="changeIndex($event)">
    <mat-tab label="SmartWatch">

      <div>
        <mat-table [dataSource]="dataSource">

          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="allarme">
            <th class="pd12 centerVertical" mat-header-cell *matHeaderCellDef>
              <i class="material-icons notranslate fs-15">error_outline</i>
            </th>
            <td (click)="changeId(element.id)" mat-cell *matCellDef="let element"
              class="pd12 centered centerVertical cpointer" [ngClass]="{'activeRowSelected': currentId===element.id}">
              <i *ngIf="!element.allarme" class="material-icons notranslate c-green fs-large">check_circle_outline</i>
              <i *ngIf="element.allarme" class="material-icons notranslate c-green fs-large">report_problem</i>
            </td>

          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="dispositivo">
            <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef> Dispositivo </th>
            <td (click)="changeId(element.id)" [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell
              *matCellDef="let element" class="pd12 cpointer centered one-line"><span>{{element.dispositivo}}</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="stato">
            <div class="flex centered">
              <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
                <div class="flex centered fs10">
                  Stato
                </div>
              </th>
              <td (click)="changeId(element.id)" [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell
                *matCellDef="let element" class="pd12 cpointer centered"> {{element.stato}} </td>
            </div>
          </ng-container>

          <ng-container matColumnDef="batteria">
            <div class="flex centered">
              <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
                <div class="flex centered fs10">
                  Batteria
                </div>
              </th>
              <td (click)="changeId(element.id)" [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell
                *matCellDef="let element" class="pd12 cpointer centered"> {{element.batteria}} </td>
            </div>
          </ng-container>
          <ng-container matColumnDef="mondo">
            <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
              <div class="flex centered fs10">
                <i class="material-icons notranslate fs-15">language</i>
              </div>
            </th>
            <td (click)="changeId(element.id,'mappa');showModalGeo = true"
              [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell *matCellDef="let element"
              class="pd12 cpointer centered"> <i class="material-icons notranslate fs-15">language</i> </td>

          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsSmartwatch"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSmartwatch;"></tr>


        </mat-table>

      </div>
    </mat-tab>
    <mat-tab label="SmartBed">

      <div>
        <mat-table [dataSource]="dataSource">

          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="allarme">
            <th class="pd12 centerVertical" mat-header-cell *matHeaderCellDef>
              <i class="material-icons notranslate fs-15">error_outline</i>
            </th>
            <td [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell *matCellDef="let element"
              (click)="changeId(element.id)" class="pd12 cpointer centered centerVertical">
              <i *ngIf="!element.allarme" class="material-icons notranslate c-green fs-large">check_circle_outline</i>
              <i *ngIf="element.allarme" class="material-icons notranslate c-green fs-large">report_problem</i>
            </td>

          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="dispositivo">
            <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef> Dispositivo </th>
            <td [ngClass]="{'activeRowSelected': currentId===element.id}" (click)="changeId(element.id)" mat-cell
              *matCellDef="let element" class="pd12 cpointer centered one-line"><span>{{element.dispositivo}}</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="stato">
            <div class="flex centered">
              <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
                <div class="flex centered fs10">
                  Stato
                </div>
              </th>
              <td [ngClass]="{'activeRowSelected': currentId===element.id}" (click)="changeId(element.id)" mat-cell
                *matCellDef="let element" class="pd12 cpointer centered"> {{element.stato}} </td>
            </div>
          </ng-container>
          <ng-container matColumnDef="temporizzazione">
            <div class="flex centered">
              <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
                <div class="flex centered fs10">
                  <i class="material-icons notranslate fs-15">schedule</i>
                </div>
              </th>
              <td [ngClass]="{'activeRowSelected': currentId===element.id}" (click)="changeId(element.id)" mat-cell
                *matCellDef="let element" class="pd12 cpointer centered"> {{element.temporizzazione}} </td>
            </div>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="HeartRate">
            <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
              <div class="flex centered">
                <i class="material-icons notranslate fs-15">favorite_border</i><i
                  class="material-icons notranslate fs-15">unfold_more</i>
              </div>
            </th>
            <td [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell
              (click)="changeId(element.id);showModalHeartrate = !showModalHeartrate" *matCellDef="let element"
              class="pd12 centered cpointer"> {{element.HeartRate}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="breathingRate">
            <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
              <div class="flex centered">
                <i class="material-icons notranslate fs-24 icon-c02">co2</i><i
                  class="material-icons notranslate fs-15">unfold_more</i>
              </div>
            </th>
            <td [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell
              (click)="changeId(element.id);showModalBreathRate = !showModalBreathRate" *matCellDef="let element"
              class="pd12 cpointer centered"> {{element.breathingRate}} </td>
          </ng-container>
          <ng-container matColumnDef="movimento">
            <div class="flex centered">
              <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
                <div class="flex centered fs10">
                  <i class="material-icons notranslate fs-15">directions_run </i><i
                    class="material-icons notranslate fs-15">unfold_more</i>
                </div>
              </th>
              <td [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell
                (click)="changeId(element.id);showModalMovements = !showModalMovements" *matCellDef="let element"
                class="pd12 cpointer centered">
                <div class="progress progressMid mid-border mt-7">
                  <div class="progress-bar progress-bar-striped bg-gray"
                    [matTooltip]="getTextTooltip(element.movimento)" role="progressbar"
                    [ngClass]="{'bg-color-198754': (element.movimento == 1 || element.movimento == 2),'bg-color-FFC107': (element.movimento == 3 || element.movimento == 4),'bg-color-DC3454': element.movimento == 5}"
                    style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </td>
            </div>
          </ng-container>
          <ng-container matColumnDef="mondo">
            <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
              <div class="flex centered fs10">
                <i class="material-icons notranslate fs-15">language</i>
              </div>
            </th>
            <td (click)="changeId(element.id,'mappa');showModalGeo = true"
              [ngClass]="{'activeRowSelected': currentId===element.id}" mat-cell *matCellDef="let element"
              class="pd12 cpointer centered"> <i class="material-icons notranslate fs-15">language</i> </td>

          </ng-container>

          <!-- <ng-container matColumnDef="movimento2">
            <div class="flex centered">
              <th class="pd12 centerVertical centered" mat-header-cell *matHeaderCellDef>
                <span [matTooltip]="tooltipTextMovement"><i class="material-icons notranslate fs-15">info</i></span>
              </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{'activeRowSelected': currentId===element.id}" (click)="changeId(element.id)"  class="pd12 centered cpointer">
                <div *ngIf="element.movimento==0">Immobile</div>
                <div *ngIf="element.movimento==1">Lieve</div>
                <div *ngIf="element.movimento==2">Basso</div>
                <div *ngIf="element.movimento==3">Medio</div>
                <div *ngIf="element.movimento==4">Alto</div>
                <div *ngIf="element.movimento==5">Critico</div>
              </td>
            </div>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSmartbed"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSmartbed;"></tr>


        </mat-table>

      </div>

    </mat-tab>
  </mat-tab-group>

  <div class="my-10 flex">
    <div class="mr-auto flex">
      <select class="form-select onlyBorderBottom" aria-label="Default select example">
        <option selected value="1">10</option>
        <option value="2">25</option>
        <option value="3">50</option>
      </select>
      <input type="text" name="search" placeholder="Search..">
    </div>
    <div class="right flex aicenter">
      <app-paginator [pageSize]="pageSize" [(isClickable)]="isClickable" (currentPageEmitter)="cambiaPagina($event)"
        [startingPage]="pagina"></app-paginator>
    </div>

  </div>
</div>

<p-dialog [visible]="showModalHeartrate">
  <p-header class="flex w-100">
    <span>
      Grafico Cardiaco di {{getNomeDispositivoById(currentId)}}
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalHeartrate = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div>
    <app-zoombar (currentZoomEmitter)="zoomBPM($event)"></app-zoombar>
  </div>
  <p-chart type="line" [options]="optionsBPM" [height]="250" [data]="dataSourceGrafici"></p-chart>
</p-dialog>

<p-dialog [visible]="showModalBreathRate" [style]="{minHeight: '360px'}">
  <p-header class="flex w-100">
    <span>
      Grafico Respirazione di {{getNomeDispositivoById(currentId)}}
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalBreathRate = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <app-zoombar (currentZoomEmitter)="zoomCO2($event)"></app-zoombar>
  <div class="overflow-x" >
    <div [style]="{width: graficoCO2Width+'px'}">
        <p-chart type="line" [options]="optionsBPM" [height]="250" [data]="dataSourceGrafici"></p-chart>
    </div>
  </div>
</p-dialog>

<p-dialog [visible]="showModalMovements">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di {{getNomeDispositivoById(currentId)}}
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalMovements = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="dataSourceGrafici"></p-chart>
  </div>
</p-dialog>

<p-dialog [visible]="showModalGeo" [style]="{minHeight: '360px', minWidth: '360px', backgroundColor: 'white'}">

  <p-header class="flex w-100 mb-10">
    <span>
      Mappa
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalGeo = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="map-container">
    <div class="map-frame mt-10">
      <div id="map"></div>
    </div>
  </div>
</p-dialog>
