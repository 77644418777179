import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Sessione } from 'src/app/MODELS/ASSISTENZA/sessione';
import { UsersService, AccountService, AlertService, ShareService, EnvService } from 'src/app/SERVICES';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { customerSatisfaction } from 'src/app/MODELS/CLASSROOM/customerSatisfaction';

@Component({
  selector: 'app-report-satisfaction',
  templateUrl: './report-satisfaction.component.html',
  styleUrls: ['./report-satisfaction.component.scss']

})
export class ReportSatisfactionComponent implements OnInit {

  hoveredDate: Date | null = null;
  tutti;
  fromDate;
  newDate: Date;
  toDate;
  dayDate: Date;
  cols: any[];
  today = new Date();
  oggi;
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;
  calendar: FormGroup;
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  satisfactionDataAfi :customerSatisfaction[];
  satisfactionData :customerSatisfaction;
  dataOK: boolean = true;
  value1: Date;
  value2: Date;
  dates: Date[];
  rangeDates: Date[];
  date1: string;
  report: boolean = true;
  loading = false;
  loading2 = false;
  ita;
  selezionaDataReport =true;
  grafici;
  piattaforme=[]
  selectedPiattaforma=[];
  dataForExcel = [];
  inizio;
  fine;
 
  dataGap:any;
  arrayDataGap=[];
  arrayLableGap=[];
  mostraChartGap=false;
  
  dataCms:any;
  arrayLableCms=[]
  arrayDataCms=[]
  mostraChartCms=false;
  arrayColori = ["#bce08c","#E0BBE4","#6891C3"]
  alfa;
  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private datePipe : DatePipe,
    private shareService : ShareService,
    private classroomService: ClassroomService,
    private envService : EnvService,) {
      this.alfa=envService.alfaintes;
  }

  ngOnInit() {
this.piattaforme.push({nome:"Gap",id:"1"},{nome:"Sito Istituzionale",id:"2"})
this.piattaforme.push()

    this.ita = {
      firstDayOfWeek: 1,
      dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
      monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };
    this.ita = JSON.stringify(this.ita);
    this.buildForm();

  }

  buildForm(): void {
    this.calendar = this.formBuilder.group({
      calendario: new FormControl('', Validators.required),
    });
  }
  
  spegniVariabiliVisone(){
    this.grafici = false;
    this.selezionaDataReport = false;
  }
  selectAll() { }


  avanti() {
    this.alertService.clear();
    if (this.selectedPiattaforma.length >0) {
      this.spegniVariabiliVisone();
      this.selezionaDataReport=true;
    } 
    else {
      this.alertService.error("Seleziona uno o più Piattaforme");
    }
  }

  indietroGrafici() {
    this.spegniVariabiliVisone();
    this.selezionaDataReport=true;
    this.arrayDataGap=[];
    this.arrayLableGap=[];
    this.mostraChartGap=false;
    this.arrayLableCms=[]
    this.arrayDataCms=[]
    this.mostraChartCms=false;
    
    }
  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates == undefined) {
      this.fromDay = "01";
      this.fromMonth = "01";
      this.fromYear = "1990";
      this.toDay = this.todayDay;
      this.toMonth = this.todayMonth;
      this.toYear = this.todayYear;
    }
  }
  exportExcel() {

//     Al click del tasto viene scaricato un file Excel che contiene i seguenti dati:
// •	Piattaforma selezionata
// •	Numero di feedback positivi
// •	Numero di feedback neutrali
// •	Numero di feedback negativi

      this.exportToExcel();
      this.selezionaDataReport=false;
      this.grafici=true;
      this.creaGraficoGap();
      if(this.alfa){
      this.creaGraficoCms();
}
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }



  inviaDati() {
    this.loading2 = true;
    this.selezionaData();

   this.inizio=this.fromYear+"-"+this.fromMonth+"-"+this.fromDay
   this.fine=this.toYear+"-"+this.toMonth+"-" +this.toDay
    this.classroomService.grafico_report_valutazione(this.inizio,this.fine).
      subscribe((res) => {

        if(this.alfa){
            if(res.data[0].positivi == 0 && res.data[0].neutri == 0 && res.data[0].negativi == 0 && res.data[1].positivi ==0 && res.data[1].neutri == 0 && res.data[1].negativi == 0){
              this.alertService.info('Nessun report presente per le date selezionate');
              this.loading2 = false;
            }else{
                this.satisfactionDataAfi = res.data;
        this.exportExcel(); 
            }
      }else{
          if(res.data[0].positivi == 0 && res.data[0].neutri == 0 && res.data[0].negativi == 0){
            this.alertService.info('Nessun report presente per le date selezionate');
            this.loading2 = false;
          }else{
             this.satisfactionData = res.data[0];
        this.exportExcel();
          }
      }  
      }, (error) => {
        this.loading2 = false;
      })
    
  }


  exportToExcel() {
    this.dataForExcel = [];
  let reportData ={}
    if(this.alfa){
      this.satisfactionDataAfi.forEach((row: customerSatisfaction) => {
        this.dataForExcel.push(Object.values(row))
      })
       reportData = {
        title: 'Report Customer Satisfaction',
        data: this.dataForExcel,
        headers: Object.keys(this.satisfactionDataAfi[0])
      }
    }else{
      this.dataForExcel.push(Object.values(this.satisfactionData))
       reportData = {
        title: 'Report Customer Satisfaction',
        data: this.dataForExcel,
        headers: Object.keys(this.satisfactionData)
      }
    }
   
    this.loading2=false;
    if(this.rangeDates && this.rangeDates[1]){
    this.excelService.exportExcel(reportData,undefined,this.rangeDates);
  }else{
  
      this.excelService.exportExcel(reportData,undefined,[new Date(this.inizio),new Date(this.fine)]);
  }
  }

// -------------Grafici--------------

// 1.	Customer Satisfaction GAP
// Grafico a torta che mostra il numero dei feedback positivi, neutrali e negativi ricevuti su GAP in un determinato lasso di tempo
// 2.	Customer Satisfaction CMS
// Grafico a torta che mostra il numero dei feedback positivi, neutrali e negativi ricevuti sul CMS in un determinato lasso di tempo

creaGraficoGap(){

  if(this.alfa){
    this.arrayDataGap.push(this.satisfactionDataAfi[0].positivi,this.satisfactionDataAfi[0].neutri,this.satisfactionDataAfi[0].negativi);
  }else{
    this.arrayDataGap.push(this.satisfactionData.positivi,this.satisfactionData.neutri,this.satisfactionData.negativi);
  }
  if(this.arrayDataGap[0]==0 && this.arrayDataGap[1]==0 && this.arrayDataGap[2]==0 ){
    this.arrayLableGap.push("Nessun Risultato");

    this.dataGap = {
      labels: this.arrayLableGap,
      datasets: [ 
          {
              data: [1],
              backgroundColor:  ["#cccccc"],
              hoverBackgroundColor:  ["#cccccc"],
          }]    
      };
      this.mostraChartGap=true;
  }else{
     this.arrayLableGap.push("positivi","neutri","negativi");

  this.dataGap = {
    labels: this.arrayLableGap,
    datasets: [ 
        {
            data: this.arrayDataGap,
            backgroundColor: this.arrayColori,
            hoverBackgroundColor: this.arrayColori
        }]    
    };
    this.mostraChartGap=true;
  }
 
}

creaGraficoCms(){
  this.arrayDataCms.push(this.satisfactionDataAfi[1].positivi,this.satisfactionDataAfi[1].neutri,this.satisfactionDataAfi[1].negativi);
  if(this.arrayDataCms[0] == 0 && this.arrayDataCms[1] == 0 && this.arrayDataCms[2] == 0 ){
    this.arrayLableCms.push("Nessun Risultato");
  
    this.dataCms = {
      labels: this.arrayLableCms,
      datasets: [
          {
              data:[1],
              backgroundColor: ["#cccccc"],
              hoverBackgroundColor:  ["#cccccc"],
          }]    
      };
      this.mostraChartCms=true;

      }  else{
     this.arrayLableCms.push("positivi","neutri","negativi");
  
    this.dataCms = {
      labels: this.arrayLableCms,
      datasets: [
          {
              data: this.arrayDataCms,
              backgroundColor: this.arrayColori,
              hoverBackgroundColor: this.arrayColori
          }]    
      };
      this.mostraChartCms=true;

  }
  }
 
}




