import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Utente } from 'src/app/MODELS/USER/utente';
import { EnvService, UsersService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-dettagli-utente',
  templateUrl: './dettagli-utente.component.html',
  styleUrls: ['./dettagli-utente.component.scss']
})
export class DettagliUtenteComponent implements OnInit {

  @Output() cambia = new EventEmitter<boolean>();
  @Input() utenteInfo;
  @Input() isDoctor: boolean;

  dettaglioUtente: Utente = {};
  dettaglio;
  listautenti: Utente[];
  listaUtentiDisattivati: Utente[];
  ruolo;
  tag;
  gruppi = [];
  loaded = false;
  alfaintes=false;
  alfaCall = false;
  informatore
  consenso
  struttura
  idMedico
  carica
  attivita
  utenteRegistrato
  utenteCondiviso
  constructor(
    private userService: UsersService,
    private env :EnvService
  ) {
    this.alfaintes=env.alfaintes;
    this.alfaCall = env.ALFACALLCENTER;

  }

  ngOnInit(): void {

    if (this.alfaintes && !this.alfaCall) {

      let apiName = this.isDoctor ? "getDoctorById" : "get_user_afi";
  
      this.userService[apiName](this.utenteInfo).subscribe((res) => {
        if (this.isDoctor) {
          this.dettaglioUtente = res.medico;
          this.ruolo = res?.ruolo;
          this.gruppi = res?.gruppi.map((gruppo: any) => gruppo.nome);
        } else {
          this.dettaglioUtente = res.data;
          this.dettaglio = res.data.dettaglio[0]?.dettaglioAggiuntivo;
          this.ruolo = res.data.ruolo.nomeRuolo;
          this.gruppi = res.data.gruppos;
          this.tag = res?.tag?.nome;
  
          if (res.data.afi) {
            this.utenteCondiviso = res.data.afi.utenteCondiviso;
          //  this.consenso = res.data.afi.consenso == 1 ? "si" : "no";
          //  this.utenteRegistrato = res.data.afi.utenteRegistrato == 1 ? "si" : "no";
          //  this.attivita = res.data.afi.attivita?.nomeAttivita;
          //  this.carica = res.data.afi.carica?.nomeCarica;
          //  this.informatore = res.data.afi.isf;
          //  this.struttura = res.data.afi.struttura?.struttura;
          //  this.idMedico = res.data.afi.idMedico;
          }
        }
        this.loaded = true;
      });
    } else {
      this.userService.getAll().subscribe((res) => {
        this.listautenti = res.data.listaUtentiAttivi;
        this.listaUtentiDisattivati = res.data.listaUtentiDisattivi;
        this.setUtente();
      }, (error) => {

      });
    }
  }



  setUtente() {
    if (this.listautenti) {
      let user = this.listautenti.find(x => x.id === this.utenteInfo);
      if(user === undefined || user === null) {
        user = this.listaUtentiDisattivati.find(x => x.id === this.utenteInfo);
      }
      this.userService.info(user.id).subscribe((res) => {

        this.dettaglioUtente = res.data;
        this.dettaglio = res.data.dettaglio[0]?.dettaglioAggiuntivo;
        this.ruolo = res.data.ruolo.nomeRuolo;
        this.gruppi = res.data.gruppos;
        this.tag = res.data.tag?.nome;
        this.loaded = true;
        // this.dettaglioUtente.nome=res.data.nome;
        // this.dettaglioUtente.cognome=res.data.cognome;
        // this.dettaglioUtente.email=res.data.email;
        // this.dettaglioUtente.telefono=res.data.telefono;
        // this.dettaglioUtente.ruolo=res.data.ruolo;
        // this.dettaglioUtente.gruppos=res.data.gruppos;

      });
    }


  }

  indietro(chiudi: boolean) {//torna in gestione utenti

    this.cambia.emit(chiudi);

  }

}
