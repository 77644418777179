<div *ngIf="alfa" class="allineaDestra"> <button type="button" id="btsSave"(click)="salvaFisicamente()" class="btn btn-primary">Esporta il template</button>
</div>

<!--<button type="button" id="btsSave"(click)="salva()" class="btn btn-primary">salva</button>  -->
<!-- <button type="button" (click)="caricaDesign()" class="btn btn-primary">Carica</button> -->
<div class="row" style="overflow: auto;">
<div [id]="id" class="unlayer-editor" [style.min-height]="minHeight" style="height: 500px; width: 100%;">
    <!-- <div id="rettangolo" style="position: absolute;"></div> -->
</div>
</div>
 