
<div class="row">
  <mat-card class="mb-10 col-8 d-block800">
    <app-lista-allarmi></app-lista-allarmi>
  </mat-card>
  <div class="col-8">
    <mat-card class="marginMobile">
      <app-device-list></app-device-list>
    </mat-card>
  </div>
  <div class="col-4">
    <mat-card class="mb-10 d-none800">
      <app-lista-allarmi></app-lista-allarmi>
    </mat-card>
    <mat-card class="mb-10" *ngIf="iotService.tipoDispositivo !== 'smartbed'">
      <app-card-list></app-card-list>
    </mat-card>
  </div>

</div>
