import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Sessione } from 'src/app/MODELS/ASSISTENZA/sessione';
import { Dispositivo, Telemetria } from 'src/app/MODELS/IOT/dispositivo';
import { AccountService, AlertService, EnvService } from 'src/app/SERVICES';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { DatePipe } from '@angular/common';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { UIChart } from 'primeng/chart';

@Component({
  selector: 'app-report-iot',
  templateUrl: './report-iot.component.html',
  styleUrls: ['./report-iot.component.scss'],
})
export class ReportIoTComponent implements OnInit {
  @ViewChildren('chartBPM') chartsBPM: QueryList<ElementRef>;
  @ViewChildren('chartAll') chartAll: QueryList<ElementRef>;
  @ViewChildren('chartBRPM') chartsBRPM: QueryList<ElementRef>;
  @ViewChildren('chartTemp') chartsTemp: QueryList<ElementRef>;
  @ViewChildren('chartContapassi') chartsPassi: QueryList<ElementRef>;
  @ViewChildren('chartContaalert') chartsalert: QueryList<ElementRef>;
  @ViewChildren('chartPressione') chartsPressione: QueryList<ElementRef>;
  @ViewChildren('chartBloodOxygen') chartsBloodOxygen: QueryList<ElementRef>;
  @ViewChild('chartPressione') chartPressione: UIChart;
  dataAll;
  optionsAll = {
    scales: {
      yAxes: [
        {
          ticks: {
            max: 210,
            min: 0,
            stepSize: 5,
          },
        },
      ],
    },
  };
  hoveredDate: Date | null = null;
  tutti;
  selectedVisual = 'Giorno';
  canGo = true;
  tipologiaDispositivo = '';
  fromDate;
  newDate: Date;
  toDate;
  dayDate: Date;
  cols: any[];
  dispositivi: any[];
  stringheSelezionate = [];
  telemetrie: any[];
  selectedDispositivi: any[] = [];
  selectedTelemetrie: any[] = [];
  allDispositivi: any[];
  searchform: FormGroup;
  selezionaDispositivo: boolean = true;
  selezionaTelemetria = false;
  selezionaDate = false;
  selezionaGrafici = false;
  dispositiviSelezionati: any[];
  telemetrieSelezionate: any[];
  idDispositiviSelezionati: any[];
  reportDispositivo;
  today = new Date();
  stringheTelemetrie = [];
  oggi;
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;
  calendar: FormGroup;
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  dataOK: boolean = true;
  value1: Date;
  value2: Date;
  dates: Date[];
  rangeDates: Date[];
  date1: string;
  report: boolean = false;
  loading = false;
  ita;
  mostraChartPressioneIot = false;
  mostraChartBattitoCardiacoIot = false;
  mostraChartAlertIot = false;
  mostraChartFrequenzaRespiratoriaIot = false;
  mostraChartTemperaturaIot = false;
  mostraChartContaPassiIot = false;
  dataPressioneIot: any;
  dataPressioneIot2: any;
  dataBattitoCardiacoIot: any;
  dataBattitoCardiacoIot2: any;
  dataAlertIot: any;
  dataAlertIot2: any;
  dataFrequenzaRespiratoriaIot: any;
  dataFrequenzaRespiratoriaIot2: any;
  dataTemperaturaIot: any;
  dataTemperaturaIot2: any;
  dataContaPassiIot: any;
  dataContaPassiIot2: any;
  currentDate = new Date();
  optionsBPM: any;
  optionsBPM2: any;
  optionsBRPM: any;
  optionsBRPM2: any;
  optionsPressione: any;
  optionsTemp: any;
  optionsPassi: any;
  optionsAlert: any;
  optionsAlert2: any;
  dispositivoSelezionato;
  listaVuota = true;
  caricato = false;
  //VARIABILI AGGIUNTE
  graficoPressioneWidth;
  graficoPressioneWidthConst = 3000;
  graficoAlertWidth;
  graficoAlertWidthConst = 3000;
  graficoBattitoWidth;
  graficoBattitoWidthConst = 3000;
  tempBloodOxygen;
  bloodOxygenTempo;
  bloodOxygenSoglia;
  bloodOxygen = null;
  mostraChartBloodOxygenIot = false;
  optionsSPO2;
  optionsSPO22;
  dataBloodOxygenIot;
  bodyBloodOxygenData;
  graficoBloodOxygenWidth = 3000;
  graficoBloodOxygenWidthConst = 3000;
  dataBloodOxygenIot2;
  graficoRespirazioneWidth;
  graficoRespirazioneWidthConst;
  graficoTemperaturaWidth;
  graficoTemperaturaWidthConst = 3000;
  graficoPassiWidth;
  graficoPassiWidthConst = 3000;
  pressureData;
  heartRateData;
  breathingRateData;
  bodyTemperatureData;
  stepCounterData;
  optionsPressione2: any;
  optionsTemp2: any;
  optionsPassi2: any;
  pressioneMinimaMarked: boolean;
  pressioneMassimaMarked: boolean;
  /**
   * fix Bug Gap-554, asse y non visibile rispetto a x
   */
  problemGraphAsync = false;
  tracking;

  constructor(
    private dispositiviService: IotService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private datePipe: DatePipe,
    private iotService: IotService,
    private env: EnvService
  ) {
    this.tracking = this.env.TRACKING;
  }

  ngOnInit() {
    this.opzioniGrafici();
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domenica',
        'Lunedi',
        'Martedi',
        'Mercoledi',
        'Giovedi',
        'Venerdi',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk',
    };

    this.buildForm();
    this.dispositiviService.getListaDispositivi().subscribe(
      (res) => {
        this.caricato = true;
        if (res.data.length > 0) {
          this.listaVuota = false;
        }
        this.dispositivi = res.data;
        this.allDispositivi = this.dispositivi;
        this.report = true;
      },
      (error) => {}
    );

    this.dispositiviService.getListaTelemetrie().subscribe(
      (res) => {
        this.telemetrie = res.data;
        this.telemetrie.push({
          id: -1,
          telemetry: 'Alert',
          telemetryName: 'Allarmi',
          telemetryDescription: 'Allarmi paziente',
          telemetryUnit: null,
        });

        if (
          this.tipologiaDispositivo === 'smartbed' &&
          this.selectedDispositivi.length === 1
        )
          this.telemetrie.push({
            id: -2,
            telemetry: 'Report completo',
            telemetryName: 'Report completo',
            telemetryDescription: 'Report completo',
            telemetryUnit: null,
          });
        this.telemetrie = this.telemetrie.filter(
          (x) => x.telemetryName !== 'Pressione Minima'
        );
        this.telemetrie.forEach((element) => {
          if (element.telemetryName === 'Pressione Massima') {
            element.telemetryName = 'Pressione';
          }
        });
      },
      (error) => {}
    );

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
  }
  buildForm(): void {
    this.calendar = this.formBuilder.group({
      calendario: new FormControl('', Validators.required),
    });
  }
  selectAll() {}

  searchInDispositivo() {
    let valore: string = this.searchform.value.search;
    this.dispositivi = this.allDispositivi;
    if (valore) {
      let dispositivi = [];
      this.dispositivi.forEach((dispositivo) => {
        if (
          dispositivo.deviceName.toLowerCase().includes(valore.toLowerCase()) ||
          dispositivo.deviceType.tipoDevice
            .toLowerCase()
            .includes(valore.toLowerCase())
        ) {
          if (!dispositivi.find((x) => x === dispositivo)) {
            dispositivi.push(dispositivo);
          }
        }
      });
      this.dispositivi = dispositivi;
    } else if (this.dispositivi !== this.allDispositivi) {
      this.dispositivi = this.allDispositivi;
    }
  }
  avanti(valore) {
    if (this.canGo) {
      this.canGo = false;
      if (valore === 'dispositivo') {
        this.telemetrie = [];
        this.selectedDispositivi.forEach((element) => {
          let toPush = true;
          element.deviceType.deviceTelemetryMetadata.forEach((telemetria) => {
            this.telemetrie.forEach((telemetriaPresente) => {
              if (telemetriaPresente.telemetry === telemetria.telemetry)
                toPush = false;
            });
            if (toPush) this.telemetrie.push(telemetria);
            else toPush = true;
          });
        });
        this.telemetrie.push({
          id: -1,
          telemetry: 'Alert',
          telemetryName: 'Allarmi',
          telemetryDescription: 'Allarmi paziente',
          telemetryUnit: null,
        });
        if (
          this.selectedDispositivi &&
          this.selectedDispositivi[0] &&
          this.selectedDispositivi[0].deviceType.tipologia === 'smartbed' &&
          this.selectedDispositivi.length === 1
        )
          this.telemetrie.push({
            id: -2,
            telemetry: 'Report completo',
            telemetryName: 'Report completo',
            telemetryDescription: 'Report completo',
            telemetryUnit: null,
          });
        this.alertService.clear();
        if (this.selectedDispositivi.length > 0) {
          this.dispositiviSelezionati = this.selectedDispositivi;
          this.selezionaDispositivo = false;
          this.selezionaTelemetria = true;
        } else {
          this.alertService.error('Seleziona uno o più dispositivi');
          this.telemetrie = [];
          this.canGo = true;
        }
      } else if (valore === 'tracker') {
        this.alertService.clear();
        this.canGo = true;
        this.selezionaDispositivo = false;
        this.selezionaTelemetria = false;
        this.selezionaDate = true;
        if (this.selectedDispositivi.length > 0) {
          this.dispositiviSelezionati = this.selectedDispositivi;
        } else {
          this.alertService.error('Seleziona uno o più dispositivi');
          this.canGo = true;
        }
      } else {
        this.alertService.clear();
        if (this.selectedTelemetrie.length > 0) {
          this.telemetrieSelezionate = this.selectedTelemetrie;
          this.selezionaTelemetria = false;
          this.selezionaDate = true;
        } else {
          this.alertService.error('Seleziona una o più telemetrie');
        }
      }
      this.canGo = true;
    }
  }

  indietro(valore) {
    if (this.canGo) {
      this.canGo = false;
      if (valore === 'dispositivo') {
        this.selezionaDispositivo = true;
        this.selezionaTelemetria = false;
        this.selectedDispositivi = [];
      } else if (valore === 'telemetria') {
        this.selezionaTelemetria = true;
        this.selectedTelemetrie = [];
        this.selezionaDate = false;
      } else if (valore === 'tracker') {
        this.selezionaDispositivo = true;
        this.selezionaDate = false;
      } else {
        this.selezionaGrafici = false;
        this.selezionaDate = true;
      }
      this.problemGraphAsync = false;
      this.canGo = true;
    }
  }
  selectedTelemetria(telemetria) {
    if (telemetria.telemetryName === 'Report completo') {
      this.selectedTelemetrie = [
        {
          id: -2,
          telemetry: 'Report completo',
          telemetryName: 'Report completo',
          telemetryDescription: 'Report completo',
          telemetryUnit: null,
        },
      ];
    } else {
      let selectedTelemetrie = [];
      this.selectedTelemetrie.forEach((element) => {
        if (element.id !== -2) {
          selectedTelemetrie.push(element);
        }
      });
      this.selectedTelemetrie = selectedTelemetrie;
    }
  }
  flagSelectedAll = false;
  selectedTelemetriaAll(event) {
    let selectedTelemetrie = [];
    this.selectedTelemetrie.forEach((element) => {
      if (element.id !== -2) {
        selectedTelemetrie.push(element);
      }
    });
    if (
      this.telemetrie.length - 1 === selectedTelemetrie.length &&
      this.flagSelectedAll
    ) {
      this.selectedTelemetrie = [];
      this.flagSelectedAll = false;
    } else {
      this.flagSelectedAll = true;
      this.selectedTelemetrie = selectedTelemetrie;
    }
  }
  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates === undefined) {
      this.fromDay = '01';
      this.fromMonth = '01';
      this.fromYear = '1990';

      this.toDay = this.todayDay;
      this.toMonth = this.todayMonth;
      this.toYear = this.todayYear;
    }
  }

  allarmi;
  async exportExcelDispositivo() {
    let excelscaricato = false;
    // let notAlarms = true;

    // this.reportDispositivo.data.forEach(dispositivo => {
    //   if (dispositivo.alarms === null) {
    //     notAlarms = false;
    //   }
    // });
    // if(this.tracking) {
    //   this.reportDispositivo.data.forEach((dispositivo) =>{
    //     let title;
    //     let listaDispositivi: any = {};
    //     if (dispositivo.device.utente) {
    //       title =
    //         dispositivo.device.deviceName +
    //         ' di ' +
    //         dispositivo.device.utente.nome +
    //         ' ' +
    //         dispositivo.device.utente.cognome;
    //     } else {
    //       title = dispositivo.device.deviceName;
    //     }

    //     if (JSON.stringify(listaDispositivi) !== '{}') {
    //       this.selezionaDate = false;
    //       this.selezionaDispositivo = true;
    //       excelscaricato = true;
    //       this.exportToExcel(listaDispositivi, title, this.reportDispositivo);
    //       listaDispositivi = new Array();
    //     }
    //   });
    //   this.loading = false;
    //   if (!excelscaricato) {
    //     this.alertService.info(
    //       'Nessun report presente per i dispositivi e/o le date selezionate'
    //     );
    //   }
    // } else {

    this.reportDispositivo.data.forEach((dispositivo) => {
      let title;
      let listaDispositivi: any = {};
      if (dispositivo.device.utente) {
        title =
          dispositivo.device.deviceName +
          ' di ' +
          dispositivo.device.utente.nome +
          ' ' +
          dispositivo.device.utente.cognome;
      } else {
        title = dispositivo.device.deviceName;
      }
      if (dispositivo.telemetry && dispositivo.telemetry.HeartRate) {
        listaDispositivi.HeartRate = [];
        dispositivo.telemetry.HeartRate.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.telemetryData;
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.HeartRate.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.BloodOxygen) {
        listaDispositivi.BloodOxygen = [];
        dispositivo.telemetry.BloodOxygen.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.telemetryData;
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.BloodOxygen.push(dato);
        });
      }
      if (dispositivo.alarms && dispositivo.alarms.length > 0) {
        this.allarmi = dispositivo.alarms;
        listaDispositivi.Alert = [];
        dispositivo.alarms.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.time,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.messaggio;
          dato.nota = element.nota;
          listaDispositivi.Alert.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.BreathingRate) {
        listaDispositivi.BreathingRate = [];
        dispositivo.telemetry.BreathingRate.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.telemetryData;
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.BreathingRate.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.BodyTemperature) {
        listaDispositivi.BodyTemperature = [];
        dispositivo.telemetry.BodyTemperature.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.telemetryData;
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.BodyTemperature.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.Power) {
        listaDispositivi.Power = [];
        dispositivo.telemetry.Power.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.telemetryData + '%';
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.Power.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.StepCounter) {
        listaDispositivi.StepCounter = [];
        dispositivo.telemetry.StepCounter.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = element.telemetryData;
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.StepCounter.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.WearState) {
        listaDispositivi.WearState = [];
        dispositivo.telemetry.WearState.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          if (element.telemetryData == '0') {
            dato.Valore = 'Non indossato';
          }
          if (element.telemetryData == '1') {
            dato.Valore = 'Indossato';
          }
          if (element.telemetryData == '2') {
            dato.Valore = 'Spento';
          }
          if (element.telemetryData == '3') {
            dato.Valore = 'Offline';
          }
          if (element.telemetryData == '4') {
            dato.Valore = 'A Letto';
          }
          if (element.telemetryData == '5') {
            dato.Valore = 'Non A Letto';
          }
          listaDispositivi.WearState.push(dato);
        });
      }
      if (dispositivo.telemetry && dispositivo.telemetry.SBP) {
        listaDispositivi.Pressione = [];
        dispositivo.telemetry.SBP.forEach((element) => {
          dispositivo.telemetry.DBP.forEach((element2) => {
            if (element.timestamp === element2.timestamp) {
              let dato: any = {};
              dato.Data = this.datePipe.transform(
                element.timestamp,
                'dd/MM/yy HH:mm:ss'
              );
              dato.Valore =
                'MIN:' +
                element2.telemetryData +
                '/MAX:' +
                element.telemetryData;
              if (element2.note) dato.nota = element2.note.content;
              listaDispositivi.Pressione.push(dato);
            }
          });
        });
      } else if (dispositivo.telemetry && dispositivo.telemetry.DBP) {
        listaDispositivi.Pressione = [];
        dispositivo.telemetry.DBP.forEach((element) => {
          let dato: any = {};
          dato.Data = this.datePipe.transform(
            element.timestamp,
            'dd/MM/yy HH:mm:ss'
          );
          dato.Valore = 'MIN:' + element.telemetryData;
          if (element.note) dato.nota = element.note.content;
          listaDispositivi.Pressione.push(dato);
        });
      }
      if (
        JSON.stringify(listaDispositivi) !== '{}' ||
        this.stringheTelemetrie.includes('Report completo')
      ) {
        this.selezionaDate = false;
        if (
          this.stringheTelemetrie.includes('SBP') ||
          this.stringheTelemetrie.includes('DBP') ||
          this.stringheTelemetrie.includes('HeartRate') ||
          this.stringheTelemetrie.includes('BreathingRate') ||
          this.stringheTelemetrie.includes('BloodOxygen') ||
          this.stringheTelemetrie.includes('BodyTemperature') ||
          this.stringheTelemetrie.includes('StepCounter') ||
          this.stringheTelemetrie.includes('Alert')
        ) {
          this.selezionaGrafici = true;
          this.opzioniGrafici();
          this.cambiaGrafici(this.dispositiviSelezionati[0]);
        } else this.selezionaDispositivo = true;
        excelscaricato = true;
        this.exportToExcel(listaDispositivi, title, this.reportDispositivo);
        listaDispositivi = new Array();
      }
    });
    this.loading = false;
    if (!excelscaricato) {
      this.alertService.info(
        'Nessun report presente per i dispositivi e/o le date selezionate'
      );
    }
    // }
  }

  inviaDati() {
    if (this.canGo) {
      this.canGo = false;
      this.loading = true;
      this.selezionaData();
      this.idDispositiviSelezionati = new Array();
      this.dispositiviSelezionati.forEach((dispositivo) => {
        let id;
        id = dispositivo.id;
        this.idDispositiviSelezionati.push(id);
      });
      this.stringheTelemetrie = [];
      this.telemetrieSelezionate.forEach((telemetria) => {
        this.stringheTelemetrie.push(telemetria.telemetry);
      });

      let body: any = {};
      body.id = this.idDispositiviSelezionati;
      body.telemetryName = this.stringheTelemetrie;
      this.fileService
        .inviaDatiReportDispositivo(
          body,
          this.fromDay,
          this.fromMonth,
          this.fromYear,
          this.toDay,
          this.toMonth,
          this.toYear
        )
        .subscribe(
          async (res) => {
            this.reportDispositivo = res;
            await this.exportExcelDispositivo();
            this.canGo = true;
          },
          (error) => (this.loading = false)
        );
    }
  }

  dataForExcel: any;

  exportToExcel(listaDispositivi, title, reportDispositivo?) {
    this.dataForExcel = {};
    if (listaDispositivi.HeartRate) {
      this.dataForExcel.HeartRate = [];
      listaDispositivi.HeartRate.forEach((row: any) => {
        this.dataForExcel.HeartRate.push(Object.values(row));
      });
    }
    if (listaDispositivi.BloodOxygen) {
      this.dataForExcel.BloodOxygen = [];
      listaDispositivi.BloodOxygen.forEach((row: any) => {
        this.dataForExcel.BloodOxygen.push(Object.values(row));
      });
    }
    if (listaDispositivi.Alert) {
      this.dataForExcel.Alert = [];
      listaDispositivi.Alert.forEach((row: any) => {
        this.dataForExcel.Alert.push(Object.values(row));
      });
    }
    if (listaDispositivi.BreathingRate) {
      this.dataForExcel.BreathingRate = [];
      listaDispositivi.BreathingRate.forEach((row: any) => {
        this.dataForExcel.BreathingRate.push(Object.values(row));
      });
    }
    if (listaDispositivi.BodyTemperature) {
      this.dataForExcel.BodyTemperature = [];
      listaDispositivi.BodyTemperature.forEach((row: any) => {
        this.dataForExcel.BodyTemperature.push(Object.values(row));
      });
    }
    if (listaDispositivi.Power) {
      this.dataForExcel.Power = [];
      listaDispositivi.Power.forEach((row: any) => {
        this.dataForExcel.Power.push(Object.values(row));
      });
    }
    if (listaDispositivi.WearState) {
      this.dataForExcel.WearState = [];
      listaDispositivi.WearState.forEach((row: any) => {
        this.dataForExcel.WearState.push(Object.values(row));
      });
    }
    if (listaDispositivi.Pressione) {
      this.dataForExcel.Pressione = [];
      listaDispositivi.Pressione.forEach((row: any) => {
        this.dataForExcel.Pressione.push(Object.values(row));
      });
    }
    if (listaDispositivi.StepCounter) {
      this.dataForExcel.StepCounter = [];
      listaDispositivi.StepCounter.forEach((row: any) => {
        this.dataForExcel.StepCounter.push(Object.values(row));
      });
    }

    let reportData = {
      title: 'Report Dispositivo: ' + title,
      data: this.dataForExcel,
      headers: ['Data', 'Valore', 'Note'],
    };
    // if (this.stringheTelemetrie.includes('Alert'))
    //   reportData.headers.push('Note');
    if (this.stringheTelemetrie.includes('Report completo')) {
      delete this.stringheTelemetrie[
        this.stringheTelemetrie.indexOf('Report completo')
      ];
      let br = [];
      let hr = [];
      this.dataAll = {
        labels: [],
        datasets: [
          {
            label: 'Hearth rate',
            borderColor: '#42A5F5',
            fill: false,
            lineTension: 0,
          },
          {
            label: 'Breathing rate',
            borderColor: '#42A5F5',
            fill: false,
            lineTension: 0,
          },
        ],
      };
      reportDispositivo.data.forEach((element) => {
        element.telemetry.forEach((telemetrie) => {
          this.dataAll.labels.push(
            (new Date(telemetrie.timestamp).getDate() < 10 ? '0' : '') +
              new Date(telemetrie.timestamp).getDate() +
              '-' +
              (new Date(telemetrie.timestamp).getMonth() + 1 < 10 ? '0' : '') +
              (new Date(telemetrie.timestamp).getMonth() + 1) +
              '-' +
              new Date(telemetrie.timestamp).getFullYear() +
              ' ' +
              (new Date(telemetrie.timestamp).getHours() < 10 ? '0' : '') +
              new Date(telemetrie.timestamp).getHours() +
              ':' +
              (new Date(telemetrie.timestamp).getMinutes() < 10 ? '0' : '') +
              new Date(telemetrie.timestamp).getMinutes() +
              ':' +
              (new Date(telemetrie.timestamp).getSeconds() < 10 ? '0' : '') +
              new Date(telemetrie.timestamp).getSeconds()
          );
          hr.push(telemetrie.heartRate);
          br.push(telemetrie.breathingRate);
        });
      });
      this.dataAll.datasets[0].data = hr;
      this.dataAll.datasets[1].data = br;

      setTimeout(() => {
        this.chartAll.forEach((element) => {
          this.getBase64IMG(element['chart'].canvas, 'Grafico_All').then(
            (img) => {
              this.excelService.exportExcelAll(
                reportDispositivo,
                reportData.title,
                img,
                5 * this.dataAll.datasets[0].data.length
              );
            }
          );
        });
      }, 1000);
    } else {
      this.excelService.exportExcel(reportData, this.stringheTelemetrie);
    }
  }
  /**
   * Serve per rimettere le stesse option iniziali dopo che queste
   * vengono cambiate per il download
   * @param valore del grafico da resettare
   */
  opzioniGraficiReset(valore: String) {
    if (valore === 'alert') {
      this.optionsAlert = {
        type: 'bar',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.round(Math.max(...this.arrayAlert) / 10) * 10 + 10,
                min: 0,
                display: false,
              },
            },
          ],
        },
      };
    }
    if (valore === 'BloodOxygen') {
      this.optionsSPO2 = {
        scales: {
          yAxes: [
            {
              fontColor: 'transparent',
              ticks: {
                fontColor: 'transparent',
                max: 100,
                min: 0,
                stepSize: 10,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            beforeLabel: (tooltipItems, data) => {
              const i = tooltipItems.index - 1;
              if (i < 0) return '';
              const element = this.bodyBloodOxygenData[i];
              if (element) return element.note ? element.note.content : '';
              return '';
            },
          },
        },
      };
    }
    if (valore === 'BPM') {
      this.optionsBPM = {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 210,
                min: 30,
                stepSize: 30,
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    }
    if (valore === 'BRPM') {
      this.optionsBRPM = {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 40,
                min: 0,
                stepSize: 5,
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    }
    if (valore === 'passi') {
      this.optionsPassi = {
        scales: {
          yAxes: [
            {
              fontColor: 'transparent',
              ticks: {
                fontColor: 'transparent',
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    }
    if (valore === 'pressione') {
      this.optionsPressione = {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              fontColor: 'transparent',
              ticks: {
                fontColor: 'transparent',
                max: 270,
                min: 30,
                stepSize: 30,
              },
            },
          ],
        },
      };
    }
    if (valore === 'temperatura') {
      this.optionsTemp = {
        scales: {
          yAxes: [
            {
              fontColor: 'transparent',
              ticks: {
                fontColor: 'transparent',
                max: 40,
                min: 32,
                stepSize: 2,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    }
  }

  opzioniGrafici() {
    this.optionsSPO2 = {
      scales: {
        yAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
              max: 100,
              min: 0,
              stepSize: 10,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          beforeLabel: (tooltipItems, data) => {
            const i = tooltipItems.index - 1;
            if (i < 0) return '';
            const element = this.bodyBloodOxygenData[i];
            if (element) return element.note ? element.note.content : '';
            return '';
          },
        },
      },
    };

    this.optionsSPO22 = {
      scales: {
        fontColor: 'transparent',
        yAxes: [
          {
            ticks: {
              max: 100,
              min: 0,
              stepSize: 10,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };

    this.optionsAlert = {
      type: 'bar',
      scales: {
        yAxes: [
          {
            ticks: {
              max: 40,
              min: 0,
              stepSize: 10,
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    this.optionsAlert2 = {
      type: 'bar',
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
    this.optionsBPM = {
      scales: {
        yAxes: [
          {
            ticks: {
              max: 210,
              min: 30,
              stepSize: 30,
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    this.optionsBPM2 = {
      scales: {
        fontColor: 'transparent',
        yAxes: [
          {
            ticks: {
              max: 210,
              min: 30,
              stepSize: 30,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
    this.optionsBRPM = {
      scales: {
        yAxes: [
          {
            ticks: {
              max: 40,
              min: 0,
              stepSize: 5,
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    this.optionsBRPM2 = {
      scales: {
        fontColor: 'transparent',
        yAxes: [
          {
            ticks: {
              max: 40,
              min: 0,
              stepSize: 5,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
    this.optionsTemp = {
      scales: {
        yAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
              max: 40,
              min: 32,
              stepSize: 2,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    this.optionsTemp2 = {
      scales: {
        fontColor: 'transparent',
        yAxes: [
          {
            ticks: {
              max: 40,
              min: 32,
              stepSize: 2,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };

    this.optionsPassi = {
      scales: {
        yAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    this.stepCounterData;
    this.optionsPassi2 = {
      scales: {
        yAxes: [
          {
            ticks: {},
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        fontColor: 'transparent',
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };

    this.optionsPressione = {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
              max: 270,
              min: 30,
              stepSize: 30,
            },
          },
        ],
      },
    };

    this.optionsPressione2 = {
      scales: {
        fontColor: 'transparent',
        yAxes: [
          {
            ticks: {
              max: 270,
              min: 30,
              stepSize: 30,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            fontColor: 'transparent',
            ticks: {
              fontColor: 'transparent',
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
  }

  cambiaPressione(id): Promise<any> {
    return new Promise<any>((resolve) => {
      let arrayPressMin = [];
      let arrayPressMax = [];
      let arrayLable = [];
      let dataInizio: any = new Date(
        this.fromYear,
        this.fromMonth - 1,
        this.fromDay
      );
      let dataFine: any = new Date(this.toYear, this.toMonth - 1, this.toDay);
      dataInizio = dataInizio.getTime() / 1000;
      dataFine = dataFine.getTime() / 1000 + 86340;
      this.iotService
        .getMediaPressioneForDate(dataInizio, dataFine, id)
        .subscribe((resPressioneIot) => {
          this.pressureData = resPressioneIot.data;
          this.graficoPressioneWidth = this.changeWidth(this.pressureData);
          resPressioneIot.data.forEach((element2) => {
        //    arrayPressMin.push(element2.DBP);
              arrayPressMin.push({
                x: element2.ts, //+ "GMT+2",
                y: element2.DBP,
              });
              arrayPressMax.push({
                x: element2.ts, //+ "GMT+2",
                y: element2.SBP,
              });
         //   arrayPressMax.push(element2.SBP);
            arrayLable.push(
              this.datePipe.transform(element2.ts, 'dd/MM/yy HH:mm')
            );
          });
          this.dataPressioneIot = {
            labels: arrayLable,
            datasets: [
              {
                label: 'Pressione minima',
                borderColor: '#42A5F5',
                data: arrayPressMin,
                fill: false,
                lineTension: 0,
              },
              {
                label: 'Pressione massima',
                borderColor: '#FFA726',
                data: arrayPressMax,
                fill: false,
                lineTension: 0,
              },
            ],
          };
          //TMS 18-10-2021
          //Start-Fix Bug-465
          this.dataPressioneIot2 = JSON.parse(
            JSON.stringify(this.dataPressioneIot)
          );
          for (const dataset of this.dataPressioneIot2.datasets) {
            dataset.hidden = true;
          }
          //end
          this.mostraChartPressioneIot = true;
          resolve(this.mostraChartPressioneIot);
        });
    });
  }

  cambiaBloodOxygen(id): Promise<any> {
    return new Promise<any>((resolve) => {
      let dataInizio: any = new Date(
        this.fromYear,
        this.fromMonth - 1,
        this.fromDay
      );
      let dataFine: any = new Date(this.toYear, this.toMonth - 1, this.toDay);
      dataInizio = dataInizio.getTime() / 1000;
      dataFine = dataFine.getTime() / 1000 + 86340;
      let arrayTimestamp1 = [];
      let arrayBattitoCardiaco = [];
      this.iotService
        .getTelemetryBloodOxygen(id, dataInizio, dataFine)
        .subscribe((res) => {
          this.bodyBloodOxygenData = res.data;
          this.graficoBloodOxygenWidth = this.changeWidth(this.heartRateData);
          res.data.forEach((el) => {
            if (el) {
              arrayBattitoCardiaco.push(
                {
                  id: el.id,
                  x: el.timestamp, // + " GMT+2",
                  y: el.telemetryData
                }
              );
            }
          });
          this.dataBloodOxygenIot = {
            labels: arrayTimestamp1,
            datasets: [
              {
                label: 'Blood oxygen',
                data: arrayBattitoCardiaco,
                fill: false,
                borderColor: '#f54248',
                lineTension: 0,
              },
            ],
          };
          // this.dataBloodOxygenIot2 = JSON.parse(
          //   JSON.stringify(this.dataBloodOxygenIot)
          // );
          // for (const dataset of this.dataBloodOxygenIot2.datasets) {
          //   dataset.hidden = true;
          // }
          this.mostraChartBloodOxygenIot = true;
          resolve(this.mostraChartBloodOxygenIot);
        });
    });
  }

  numMisurazioneCardiache = [];

  async cambiaBattitoEvent(
    id,
    date = new Date().getTime() - 86400000,
    isUserInput
  ) {
    if (isUserInput) {
      await this.cambiaBattito(id, date, isUserInput);
    }
  }

  numMisurazioneRespirazione = [];

  async cambiaRespirazioneEvent(
    id,
    date = new Date().getTime() - 86400000,
    isUserInput
  ) {
    if (isUserInput) {
      await this.cambiaRespiro(id, date, isUserInput);
    }
  }

  cambiaBattito(
    id,
    date = new Date().getTime() - 86400000,
    isUserInput = false
  ): Promise<any> {
    return new Promise<any>((resolve) => {
      let dataInizio: any = new Date(
        this.fromYear,
        this.fromMonth - 1,
        this.fromDay
      );
      let dataFine: any = new Date(this.toYear, this.toMonth - 1, this.toDay);
      dataInizio = dataInizio.getTime() / 1000;
      dataFine = dataFine.getTime() / 1000 + 86340;
      let arrayTimestamp1 = [];
      let arrayBattitoCardiaco = [];
      this.iotService
        .getTelemetryHeart(id, dataInizio, dataFine)
        .subscribe((res) => {
          res.data.sort(function (x, y) {
            if (x && y)
            return new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime()
          });
          if (!isUserInput) {
            this.numMisurazioneCardiache = [];
            // console.log(res.data[0]?.timestamp);
            for (
              let i = new Date(res.data[0]?.timestamp).getTime();
              i < new Date(res.data[res.data.length - 1]?.timestamp).getTime();
              i = i + 86400000
            ) {
              this.numMisurazioneCardiache.push(i);
            }
          }
          //Rimuovo i dati precedenti al 2020 perchè certamente frutto di errore
          res.data = res.data.filter((x) => {
            if (
              x &&
              new Date(x.timestamp).getTime() >=1577836800000
            )
              return x;
          });
          //Limito ai 1000 elementi più recenti
          if(res.data.length>1000)
          {
            res.data = res.data.slice(-1000);
          }
          this.heartRateData = res.data;
          this.graficoBattitoWidth = this.changeWidth(this.heartRateData);
          res.data.forEach((el) => {
            if (el) {
              // arrayTimestamp1.push(
              //   this.datePipe.transform(el.timestamp, 'dd/MM/yy HH:mm')
              // );
              arrayBattitoCardiaco.push({
                x: el.timestamp, //+ "GMT+2",
                y: el.telemetryData,
              });
            }
          });
          this.dataBattitoCardiacoIot = {
            labels: arrayTimestamp1,
            datasets: [
              {
                label: 'Battito Cardiaco',
                data: arrayBattitoCardiaco,
                fill: false,
                borderColor: '#f54248',
                lineTension: 0,
              },
            ],
          };
          this.dataBattitoCardiacoIot2 = JSON.parse(
            JSON.stringify(this.dataBattitoCardiacoIot)
          );
          for (const dataset of this.dataBattitoCardiacoIot2.datasets) {
            dataset.hidden = true;
          }
          this.mostraChartBattitoCardiacoIot = true;
          resolve(this.mostraChartBattitoCardiacoIot);
        });
    });
  }
  getDateFromMillis(millis) {
    return this.datePipe.transform(millis, 'dd/MM/yy');
  }
  arrayAlert = [10];
  selectData(event) {
    if (this.allarmi && this.allarmi.length > 0) {
      let fromMonth;
      let toMonth;
      let fromYear;
      let toYear;

      let arrayTimestamp1 = [];
      let arrayAlert: Array<number> = [];
      let dataPrecedente: Date;
      let counter = 0;
      let firstTime = true;
      if (this.selectedVisual && this.selectedVisual !== 'Giorno') {
        if (this.selectedVisual === 'Anno') {
          this.selectedVisual = 'Mese';
          fromYear = this.dataAlertIot.labels[event.element._index];
          toYear = this.dataAlertIot.labels[event.element._index];

          let allarmi = [];

          this.allarmi.forEach((element) => {
            if (new Date(element.time).getFullYear() === fromYear) {
              allarmi.push(element);
            }
          });

          allarmi.forEach((element) => {
            if (new Date(element.time).getFullYear() === fromYear) {
              if (firstTime) {
                dataPrecedente = new Date(element.time);
                firstTime = false;
              }
              if (
                dataPrecedente.getMonth() === new Date(element.time).getMonth()
              ) {
                for (
                  let i = 0;
                  i <
                  dataPrecedente.getMonth() -
                    new Date(element.time).getMonth() -
                    1;
                  i++
                ) {
                  arrayAlert.push(0);
                }
                counter = counter + 1;
              } else {
                if (
                  dataPrecedente.getFullYear() ===
                  new Date(element.time).getFullYear()
                ) {
                } else {
                  let anniPassati =
                    dataPrecedente.getFullYear() -
                    new Date(element.time).getFullYear();
                  for (
                    let i = dataPrecedente.getMonth() + 12 * anniPassati;
                    i < new Date(element.time).getMonth() - 1;
                    i++
                  ) {
                    arrayAlert.push(0);
                  }
                }
                this.selectedVisual = 'Mese';
                arrayAlert.push(counter);
                counter = 2;
                for (
                  let i = dataPrecedente.getMonth() - 1;
                  i > new Date(element.time).getMonth();
                  i--
                ) {
                  arrayAlert.push(0);
                }
                dataPrecedente = new Date(element.time);
              }
            }
          });
          arrayAlert.push(counter - 1);
          for (
            let i = 0;
            i < new Date(allarmi[allarmi.length - 1].time).getMonth();
            i++
          ) {
            arrayAlert.push(0);
          }
          // arrayAlert = arrayAlert.slice().reverse();
          arrayTimestamp1 = [];
          let counterSecondTime = 0;
          arrayAlert.forEach((element, index) => {
            if (index !== 0 && index !== arrayAlert.length - 1) {
              arrayAlert[index] = arrayAlert[index] - 1;
            }
            if (element > 0) {
              counterSecondTime = counterSecondTime + 1;
            }
          });
          if (counterSecondTime <= 1) {
            arrayAlert.forEach((element, index) => {
              if (element > 0) {
                arrayAlert[index] = arrayAlert[index] + 1;
              }
            });
          }
          let firstTimeFirstMonthCheck = true;
          let continueFirstMonthCheck = false;
          arrayAlert
            .slice()
            .reverse()
            .forEach((element, index) => {
              if (element === 0 && firstTimeFirstMonthCheck) {
                continueFirstMonthCheck = true;
                firstTimeFirstMonthCheck = false;
              }
              if (continueFirstMonthCheck) {
                if (element > 0) {
                  arrayAlert[arrayAlert.length - (index + 1)] =
                    arrayAlert[arrayAlert.length - (index + 1)] + 1;
                  continueFirstMonthCheck = false;
                }
              }
            });
          for (let i = 0; i < arrayAlert.length; i++) {
            arrayTimestamp1.push(
              this.ita.monthNames[i % 12][0] +
                this.ita.monthNames[i % 12][1] +
                this.ita.monthNames[i % 12][2] +
                '/' +
                String(fromYear)
            );
          }
        } else if (this.selectedVisual === 'Mese') {
          // counter = counter + 1;
          let string: String =
            this.dataAlertIot.labels[event.element._index][0] +
            this.dataAlertIot.labels[event.element._index][1] +
            this.dataAlertIot.labels[event.element._index][2];

          fromYear =
            this.dataAlertIot.labels[event.element._index][4] +
            this.dataAlertIot.labels[event.element._index][5] +
            this.dataAlertIot.labels[event.element._index][6] +
            this.dataAlertIot.labels[event.element._index][7];
          toYear = Number(fromYear);
          fromYear = toYear;
          if (string.toLowerCase() === 'gen') {
            fromMonth = 0;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'feb') {
            fromMonth = 1;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'mar') {
            fromMonth = 2;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'apr') {
            fromMonth = 3;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'mag') {
            fromMonth = 4;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'giu') {
            fromMonth = 5;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'lug') {
            fromMonth = 6;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'ago') {
            fromMonth = 7;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'set') {
            fromMonth = 8;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'ott') {
            fromMonth = 9;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'nov') {
            fromMonth = 10;
            toMonth = fromMonth;
          }
          if (string.toLowerCase() === 'dic') {
            fromMonth = 11;
            toMonth = fromMonth;
          }

          this.selectedVisual = 'Giorno';

          let allarmi = [];

          this.allarmi.forEach((element) => {
            if (
              new Date(element.time).getFullYear() === fromYear &&
              new Date(element.time).getMonth() === fromMonth
            ) {
              allarmi.push(element);
            }
          });

          allarmi.forEach((element) => {
            if (firstTime) {
              dataPrecedente = new Date(element.time);
              firstTime = false;
            }
            if (dataPrecedente.getDay() === new Date(element.time).getDay()) {
              counter = counter + 1;
            } else {
              arrayTimestamp1.push(
                this.datePipe.transform(dataPrecedente, 'dd/MM/yy')
              );
              dataPrecedente = new Date(element.time);
              arrayAlert.push(counter);
              counter = 1;
            }
          });
          arrayTimestamp1.push(
            this.datePipe.transform(dataPrecedente, 'dd/MM/yy')
          );
          this.selectedVisual = 'Giorno';
          arrayTimestamp1 = arrayTimestamp1.slice().reverse();
          arrayAlert.push(counter);
        }

        let bg = [];
        for (let i = 0; i < arrayAlert.length; i++) {
          bg.push('red');
        }

        this.dataAlertIot = {
          labels: arrayTimestamp1,
          datasets: [
            {
              label: 'Allarmi',
              data: arrayAlert.slice().reverse(),
              backgroundColor: bg,
            },
          ],
        };

        this.optionsAlert = {
          type: 'bar',
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: Math.round(Math.max(...arrayAlert) / 10) * 10 + 10,
                  min: 0,
                  display: false,
                },
              },
            ],
          },
        };

        this.optionsAlert2 = {
          type: 'bar',
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: Math.round(Math.max(...arrayAlert) / 10) * 10 + 10,
                  min: 0,
                },
                gridLines: {
                  drawBorder: false,
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                fontColor: 'transparent',
                ticks: {
                  fontColor: 'transparent',
                },
                gridLines: {
                  drawBorder: false,
                  display: true,
                },
              },
            ],
          },
        };
        this.dataAlertIot2 = JSON.parse(JSON.stringify(this.dataAlertIot));
        for (const dataset of this.dataAlertIot2.datasets) {
          dataset.hidden = true;
        }
        this.arrayAlert = arrayAlert;
      }
    }
  }

  generateColors(arrayAlert) {
    let bgColors = [];
    for (let i = 0; i < arrayAlert.length; i++) {
      if (arrayAlert[i] < 5) bgColors.push('green');
      if (arrayAlert[i] >= 5 && arrayAlert[i] < 10) bgColors.push('yellow');
      if (arrayAlert[i] >= 10) bgColors.push('red');
    }
    return bgColors;
  }
  changeVisual() {
    if (this.allarmi && this.allarmi.length > 0) {
      this.mostraChartAlertIot = false;
      let arrayTimestamp1 = [];
      let arrayAlert: Array<number> = [];
      let dataPrecedente: Date;
      let counter = 0;
      let firstTime = true;
      if (this.selectedVisual) {
        if (this.selectedVisual === 'Giorno') {
          this.allarmi.forEach((element) => {
            if (firstTime) {
              dataPrecedente = new Date(element.time);
              firstTime = false;
            }
            if (dataPrecedente.getDay() === new Date(element.time).getDay()) {
              counter = counter + 1;
            } else {
              arrayTimestamp1.push(
                this.datePipe.transform(dataPrecedente, 'dd/MM/yy')
              );
              dataPrecedente = new Date(element.time);
              arrayAlert.push(counter);
              counter = 1;
            }
          });
          arrayTimestamp1.push(
            this.datePipe.transform(dataPrecedente, 'dd/MM/yy')
          );
          this.selectedVisual = 'Giorno';
          arrayTimestamp1 = arrayTimestamp1.slice().reverse();
          arrayAlert.push(counter);
        } else if (this.selectedVisual === 'Mese') {
          // for(let i = 0; i < new Date(this.allarmi[this.allarmi.length-1].time).getMonth(); i++) {
          //   arrayAlert.push(0);
          // }
          this.allarmi.forEach((element) => {
            if (firstTime) {
              dataPrecedente = new Date(element.time);
              firstTime = false;
            }
            if (
              dataPrecedente.getMonth() === new Date(element.time).getMonth()
            ) {
              for (
                let i = 0;
                i <
                dataPrecedente.getMonth() -
                  new Date(element.time).getMonth() -
                  1;
                i++
              ) {
                arrayAlert.push(0);
              }
              counter = counter + 1;
            } else {
              if (
                dataPrecedente.getFullYear() ===
                new Date(element.time).getFullYear()
              ) {
                for (
                  let i = dataPrecedente.getMonth();
                  i < new Date(element.time).getMonth() - 1;
                  i++
                ) {
                  arrayAlert.push(0);
                }
              } else {
                let anniPassati =
                  dataPrecedente.getFullYear() -
                  new Date(element.time).getFullYear();
                for (
                  let i = dataPrecedente.getMonth() + 12 * anniPassati;
                  i < new Date(element.time).getMonth() - 1;
                  i++
                ) {
                  arrayAlert.push(0);
                }
              }
              this.selectedVisual = 'Mese';
              arrayAlert.push(counter);
              counter = 2;
              for (
                let i = dataPrecedente.getMonth() - 1;
                i > new Date(element.time).getMonth();
                i--
              ) {
                arrayAlert.push(0);
              }
            }
            dataPrecedente = new Date(element.time);
          });
          arrayAlert.push(counter - 1);
          for (
            let i = 0;
            i < new Date(this.allarmi[this.allarmi.length - 1].time).getMonth();
            i++
          ) {
            arrayAlert.push(0);
          }
          // arrayAlert = arrayAlert.slice().reverse();
          arrayTimestamp1 = [];
          for (let i = 0; i < arrayAlert.length; i++) {
            arrayTimestamp1.push(
              this.ita.monthNames[i % 12][0] +
                this.ita.monthNames[i % 12][1] +
                this.ita.monthNames[i % 12][2] +
                '/' +
                String(
                  new Date(
                    this.allarmi[this.allarmi.length - 1].time
                  ).getFullYear() + Math.floor(i / 12)
                )
            );
          }
          let counterSecondTime = 0;
          arrayAlert.forEach((element, index) => {
            if (index !== 0 && index !== arrayAlert.length - 1) {
              arrayAlert[index] = arrayAlert[index] - 1;
            }
            if (element > 0) {
              counterSecondTime = counterSecondTime + 1;
            }
          });
          if (counterSecondTime <= 1) {
            arrayAlert.forEach((element, index) => {
              if (element > 0) {
                arrayAlert[index] = arrayAlert[index] + 1;
              }
            });
          }
        } else {
          this.allarmi.forEach((element) => {
            if (firstTime) {
              dataPrecedente = new Date(element.time);
              firstTime = false;
            }
            if (
              dataPrecedente.getFullYear() ===
              new Date(element.time).getFullYear()
            ) {
              counter = counter + 1;
            } else {
              arrayTimestamp1.push(new Date(dataPrecedente).getFullYear());
              dataPrecedente = new Date(element.time);
              arrayAlert.push(counter);
              counter = 0;
            }
          });
          this.selectedVisual = 'Anno';
          arrayTimestamp1.push(new Date(dataPrecedente).getFullYear());
          arrayTimestamp1 = arrayTimestamp1.slice().reverse();
          arrayAlert.push(counter);
        }
      }
      let bg = [];
      for (let i = 0; i < arrayAlert.length; i++) {
        bg.push('red');
      }
      this.dataAlertIot = {
        labels: arrayTimestamp1,
        datasets: [
          {
            label: 'Allarmi',
            data: arrayAlert.slice().reverse(),
            backgroundColor: bg,
          },
        ],
      };

      this.optionsAlert = {
        type: 'bar',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.round(Math.max(...arrayAlert) / 10) * 10 + 10,
                min: 0,
                display: false,
              },
            },
          ],
        },
      };

      this.optionsAlert2 = {
        type: 'bar',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.round(Math.max(...arrayAlert) / 10) * 10 + 10,
                min: 0,
              },
              gridLines: {
                drawBorder: false,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              fontColor: 'transparent',
              ticks: {
                fontColor: 'transparent',
              },
              gridLines: {
                drawBorder: false,
                display: true,
              },
            },
          ],
        },
      };
      this.dataAlertIot2 = JSON.parse(JSON.stringify(this.dataAlertIot));
      for (const dataset of this.dataAlertIot2.datasets) {
        dataset.hidden = true;
      }
      this.arrayAlert = arrayAlert;
      this.mostraChartAlertIot = true;
    }
  }
  cambiaAlert(
    fromYear = this.fromYear,
    toYear = this.toYear,
    fromMonth = this.fromMonth,
    toMonth = this.toMonth,
    fromDay = this.fromDay,
    toDay = this.fromDay
  ): Promise<any> {
    return new Promise<any>((resolve) => {
      let arrayTimestamp1 = [];
      let arrayAlert: Array<number> = [];
      let dataPrecedente: Date;
      let counter = 0;
      let firstTime = true;
      if (this.allarmi && this.allarmi.length > 0) {
        if (fromYear === toYear) {
          if (fromMonth === toMonth) {
            if (fromDay === toDay) {
              this.selectedVisual = 'Giorno';
              arrayAlert.push(this.allarmi.length);
              arrayTimestamp1.push(
                this.datePipe.transform(
                  new Date(this.allarmi[0].time),
                  'dd/MM/yy'
                )
              );
            } else {
              this.allarmi.forEach((element) => {
                if (firstTime) {
                  dataPrecedente = new Date(element.time);
                  firstTime = false;
                }
                if (
                  dataPrecedente.getDay() === new Date(element.time).getDay()
                ) {
                  counter = counter + 1;
                } else {
                  arrayTimestamp1.push(
                    this.datePipe.transform(dataPrecedente, 'dd/MM/yy')
                  );
                  dataPrecedente = new Date(element.time);
                  arrayAlert.push(counter);
                  counter = 0;
                }
              });
              arrayTimestamp1.push(
                this.datePipe.transform(dataPrecedente, 'dd/MM/yy')
              );
              this.selectedVisual = 'Giorno';
              arrayTimestamp1 = arrayTimestamp1.slice().reverse();
              arrayAlert.push(counter);
            }
          } else {
            let counter2 = [];
            this.allarmi.forEach((element) => {
              if (firstTime) {
                dataPrecedente = new Date(element.time);
                for (
                  let i = 0;
                  i < new Date(element.time).getMonth() - 1;
                  i++
                ) {
                  counter2.push(0);
                }
                firstTime = false;
              }
              if (
                dataPrecedente.getMonth() === new Date(element.time).getMonth()
              ) {
                for (
                  let i = 0;
                  i <
                  dataPrecedente.getMonth() -
                    new Date(element.time).getMonth() -
                    1;
                  i++
                ) {
                  arrayAlert.push(0);
                }
                counter = counter + 1;
              } else {
                dataPrecedente = new Date(element.time);
                arrayTimestamp1 = this.ita.monthNames;
                this.selectedVisual = 'Mese';
                arrayAlert.push(counter);
                counter = 0;
              }
            });
            arrayAlert.push(counter);
            for (let i = 0; i < counter2.length - 1; i++) {
              arrayAlert.push(0);
            }
          }
        } else {
          this.allarmi.forEach((element) => {
            if (firstTime) {
              dataPrecedente = new Date(element.time);
              firstTime = false;
            }
            if (
              dataPrecedente.getFullYear() ===
              new Date(element.time).getFullYear()
            ) {
              counter = counter + 1;
            } else {
              arrayTimestamp1.push(new Date(dataPrecedente).getFullYear());
              dataPrecedente = new Date(element.time);
              arrayAlert.push(counter);
              counter = 0;
            }
          });
          this.selectedVisual = 'Anno';
          arrayTimestamp1.push(new Date(dataPrecedente).getFullYear());
          arrayTimestamp1 = arrayTimestamp1.slice().reverse();
          arrayAlert.push(counter);
        }
      } else {
        arrayTimestamp1 = [''];
        arrayAlert = [0];
      }
      let bg = [];

      for (let i = 0; i < arrayAlert.length; i++) {
        bg.push('red');
      }
      this.dataAlertIot = {
        labels: arrayTimestamp1,
        datasets: [
          {
            label: 'Allarmi',
            data: arrayAlert.slice().reverse(),
            backgroundColor: bg,
          },
        ],
      };

      this.optionsAlert = {
        type: 'bar',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.round(Math.max(...arrayAlert) / 10) * 10 + 10,
                min: 0,
                display: false,
              },
            },
          ],
        },
      };

      this.optionsAlert2 = {
        type: 'bar',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.round(Math.max(...arrayAlert) / 10) * 10 + 10,
                min: 0,
              },
              gridLines: {
                drawBorder: false,
                display: false,
              },
            },
          ],
        },
      };
      this.dataAlertIot2 = JSON.parse(JSON.stringify(this.dataAlertIot));
      for (const dataset of this.dataAlertIot2.datasets) {
        dataset.hidden = true;
      }
      this.mostraChartAlertIot = true;
      this.arrayAlert = arrayAlert;
      resolve(this.mostraChartAlertIot);
    });
  }

  cambiaRespiro(id,
    date = new Date().getTime() - 86400000,
    isUserInput = false): Promise<any> {
    return new Promise<any>((resolve) => {
      let dataInizio: any = new Date(
        this.fromYear,
        this.fromMonth - 1,
        this.fromDay
      );
      let dataFine: any = new Date(this.toYear, this.toMonth - 1, this.toDay);
      dataInizio = dataInizio.getTime() / 1000;
      dataFine = dataFine.getTime() / 1000 + 86340;
      let arrayTimestamp1 = [];
      let arrayFrequenzaRespiratoria = [];
      this.iotService
        .getTelemetryBreathing(id, dataInizio, dataFine)
        .subscribe((res) => {
          //console.log("ELEMENTO RICEVUTO: ", res.data);
          // res.data.sort(function (x, y) {
          //   if (x && y)
          //   return new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime()
          // });
          // if (!isUserInput) {
          //   this.numMisurazioneRespirazione = [];
          //   for (
          //     let i = new Date(res.data[0]?.timestamp).getTime();
          //     i < new Date(res.data[res.data.length - 1]?.timestamp).getTime();
          //     i = i + 86400000
          //   ) {
          //     this.numMisurazioneRespirazione.push(i);
          //   }
          // }
          // res.data = res.data.filter((x) => {
          //   if (
          //     x &&
          //     new Date(x.timestamp).getTime() <= date &&
          //     new Date(x.timestamp).getTime() + 86400000 > date
          //   )
          //     return x;
          // });
          this.breathingRateData = res.data;
          this.graficoRespirazioneWidth = this.changeWidth(
            this.breathingRateData
          );
          // console.log("ELEMENTO RICEVUTO: ", res.data);
          res.data.forEach((el) => {
            if (el) {
              arrayFrequenzaRespiratoria.push(
                {
                  id: el.id,
                  x: el.timestamp, // + " GMT+2",
                  y: el.telemetryData
                }
              );
            }
          });
          this.dataFrequenzaRespiratoriaIot = {
            labels: arrayTimestamp1,
            datasets: [
              {
                label: 'Frequenza respiratoria',
                data: arrayFrequenzaRespiratoria,
                fill: false,
                borderColor: '#f54248',
                lineTension: 0,
              },
            ],
          };
          this.dataFrequenzaRespiratoriaIot2 = JSON.parse(
            JSON.stringify(this.dataFrequenzaRespiratoriaIot)
          );
          for (const dataset of this.dataFrequenzaRespiratoriaIot2.datasets) {
            dataset.hidden = true;
          }
          this.mostraChartFrequenzaRespiratoriaIot = true;
          resolve(this.mostraChartFrequenzaRespiratoriaIot);
        });
    });
  }

  cambiaTemp(id): Promise<any> {
    return new Promise<any>((resolve) => {
      let dataInizio: any = new Date(
        this.fromYear,
        this.fromMonth - 1,
        this.fromDay
      );
      let dataFine: any = new Date(this.toYear, this.toMonth - 1, this.toDay);
      dataInizio = dataInizio.getTime() / 1000;
      dataFine = dataFine.getTime() / 1000 + 86340;
      let arrayTimestamp = [];
      let arrayTemperatura = [];
      this.iotService
        .getTelemetryTemperature(id, dataInizio, dataFine)
        .subscribe((res) => {
          this.bodyTemperatureData = res.data;
          this.graficoTemperaturaWidth = this.changeWidth(
            this.bodyTemperatureData
          );
          res.data.forEach((el) => {
            arrayTimestamp.push(
              this.datePipe.transform(el.timestamp, 'dd/MM/yy HH:mm')
            );
            arrayTemperatura.push(el.telemetryData);
          });
          this.dataTemperaturaIot = {
            labels: arrayTimestamp,
            datasets: [
              {
                label: 'Temperatura',
                data: arrayTemperatura,
                fill: false,
                borderColor: '#42A5F5',
                lineTension: 0,
              },
            ],
          };

          this.dataTemperaturaIot2 = JSON.parse(
            JSON.stringify(this.dataTemperaturaIot)
          );
          for (const dataset of this.dataTemperaturaIot2.datasets) {
            dataset.hidden = true;
          }
          this.mostraChartTemperaturaIot = true;
          resolve(this.mostraChartTemperaturaIot);
        });
    });
  }

  cambiaPassi(id): Promise<any> {
    return new Promise<any>((resolve) => {
      let dataInizio: any = new Date(
        this.fromYear,
        this.fromMonth - 1,
        this.fromDay
      );
      let dataFine: any = new Date(this.toYear, this.toMonth - 1, this.toDay);
      dataInizio = dataInizio.getTime() / 1000;
      dataFine = dataFine.getTime() / 1000 + 86340;
      let arrayTimestamp2 = [];
      let arrayContaPassi = [];
      this.iotService
        .getTelemetryPassi(id, dataInizio, dataFine)
        .subscribe((res) => {
          this.stepCounterData = res.data;
          let max = 0;
          this.graficoPassiWidth = this.changeWidth(this.stepCounterData);
          res.data.forEach((el) => {
            if (el.telemetryData > max) {
              max = el.telemetryData;
            }
            arrayContaPassi.push(
              {
                id: el.id,
                x: el.timestamp, // + " GMT+2",
                y: el.telemetryData
              }
            );
          });
          // let stepSize = 1;
          // if (max >= 10) {
          //   for (let i = 2; i <= max / 2; i++) {
          //     if (max % i == 0) {
          //       stepSize = i;
          //       i = max;
          //       i = i + 1;
          //     }
          //   }
          //   this.optionsPassi2.scales.yAxes[0].ticks = {
          //     min: 0,
          //     max: max,
          //     stepSize: stepSize,
          //   };
          //   this.optionsPassi.scales.yAxes[0].ticks = {
          //     min: 0,
          //     max: max,
          //     fontColor: 'transparent',
          //     stepSize: stepSize,
          //   };
          // } else {
          //   this.optionsPassi2.scales.yAxes[0].ticks = {
          //     min: 0,
          //     max: 1,
          //     stepSize: 0.1,
          //   };
          //   this.optionsPassi.scales.yAxes[0].ticks = {
          //     min: 0,
          //     max: 1,
          //     fontColor: 'transparent',
          //     stepSize: 0.1,
          //   };
          // }
          this.dataContaPassiIot = {
            labels: arrayTimestamp2,
            datasets: [
              {
                label: 'Passi',
                data: arrayContaPassi,
                fill: false,
                borderColor: '#ffff00',
                lineTension: 0,
              },
            ],
          };
          // this.dataContaPassiIot2 = JSON.parse(
          //   JSON.stringify(this.dataContaPassiIot)
          // );
          // for (const dataset of this.dataContaPassiIot2.datasets) {
          //   dataset.hidden = true;
          // }

          this.mostraChartContaPassiIot = true;
          resolve(this.mostraChartContaPassiIot);
        });
    });
  }
  dispositivoSelezionatoFull;
  async cambiaGrafici(dispositivo) {
    this.dispositivoSelezionatoFull = dispositivo;
    this.tipologiaDispositivo = dispositivo.deviceType.tipologia;
    this.stringheSelezionate = [];
    if (this.stringheTelemetrie.includes('Alert')) {
      this.stringheSelezionate.push('Alert');
    }
    dispositivo.deviceType.deviceTelemetryMetadata.forEach((element) => {
      if (this.stringheTelemetrie.includes(element.telemetry))
        this.stringheSelezionate.push(element.telemetry);
    });
    this.dispositivoSelezionato = dispositivo.deviceName;
    if (
      this.stringheSelezionate.includes('SBP') ||
      this.stringheSelezionate.includes('DBP')
    ) {
      await this.cambiaPressione(dispositivo.id);
    }
    if (this.stringheSelezionate.includes('StepCounter')) {
      await this.cambiaPassi(dispositivo.id);
    }
    if (this.stringheSelezionate.includes('HeartRate')) {
      await this.cambiaBattito(dispositivo.id);
    }
    if (this.stringheSelezionate.includes('BloodOxygen')) {
      await this.cambiaBloodOxygen(dispositivo.id);
    }
    if (this.stringheSelezionate.includes('Alert')) {
      this.reportDispositivo.data.forEach((element) => {
        if (element && element.device && element.device.id === dispositivo.id) {
          this.allarmi = element.alarms;
        }
      });
      await this.cambiaAlert();
    }
    if (this.stringheSelezionate.includes('BreathingRate')) {
      await this.cambiaRespiro(dispositivo.id);
    }
    if (this.stringheSelezionate.includes('BodyTemperature')) {
      await this.cambiaTemp(dispositivo.id);
    }
    setTimeout(() => {
      this.problemGraphAsync = true;
    }, 100);
  }
  bpmBianco = false;
  brpmBianco = false;
  passi = false;
  pressione = false;
  temperatura = false;

  scegliDownload(valore) {
    if (valore === 'BPM') {
      this.bpmBianco = true;
      this.optionsBPM = {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 210,
                min: 30,
                stepSize: 30,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
      setTimeout(() => {
        this.chartsBPM.forEach((element) => {
          this.downloadIMG(element['chart'].canvas, 'Grafico_BPM');
        });
      }, 1000);

      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        // this.opzioniGrafici() //dà problemi dopo il download
        this.bpmBianco = false;
      }, 1000);
    }
    if (valore === 'BloodOxygen') {
      this.bloodOxygen = true;
      this.optionsSPO2 = {
        scales: {
          yAxes: [
            {
              fontColor: 'transparent',
              ticks: {
                fontColor: 'transparent',
                max: 100,
                min: 0,
                stepSize: 10,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            beforeLabel: (tooltipItems, data) => {
              const i = tooltipItems.index - 1;
              if (i < 0) return '';
              const element = this.bodyBloodOxygenData[i];
              if (element) return element.note ? element.note.content : '';
              return '';
            },
          },
        },
      };
      setTimeout(() => {
        this.chartsBloodOxygen.forEach((element) => {
          this.downloadIMG(element['chart'].canvas, 'Grafico_SPO2');
        });
      }, 1000);

      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        // this.opzioniGrafici() //dà problemi dopo il download
        this.bloodOxygen = false;
      }, 1000);
    }
    if (valore === 'BRPM') {
      this.brpmBianco = true;
      this.optionsBRPM = {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 40,
                min: 0,
                stepSize: 5,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
      setTimeout(() => {
        this.chartsBRPM.forEach((element) => {
          this.downloadIMG(element['chart'].canvas, 'Grafico_BRPM');
        });
      }, 1000);

      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        // this.opzioniGrafici() //dà problemi dopo il download
        this.brpmBianco = false;
      }, 1000);
    }
    if (valore === 'alert') {
      this.brpmBianco = true;
      this.optionsAlert = {
        type: 'bar',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.round(Math.max(...this.arrayAlert) / 10) * 10 + 10,
                min: 0,
                display: false,
              },
            },
          ],
        },
      };
      setTimeout(() => {
        this.chartsalert.forEach((element) => {
          this.downloadIMG(element['chart'].canvas, 'Grafico_Alert');
        });
      }, 1000);

      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        // this.opzioniGrafici() //dà problemi dopo il download
        this.brpmBianco = false;
      }, 1000);
    }
    if (valore === 'passi') {
      this.passi = true;
      const oldOption = this.optionsPassi;
      this.optionsPassi = {
        scales: {
          yAxes: [
            {
              ticks: {},
            },
          ],
        },
        legend: {
          display: false,
        },
      };
      setTimeout(() => {
        this.chartsPassi.forEach((element) => {
          this.downloadIMG(element['chart'].canvas, 'Grafico_Passi');
        });
      }, 1000);

      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        this.optionsPassi = oldOption;
        //this.opzioniGrafici()
        this.passi = false;
      }, 1000);
    }
    if (valore === 'pressione') {
      this.pressione = true;
      this.optionsPressione = {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                max: 270,
                min: 30,
                stepSize: 30,
              },
            },
          ],
        },
      };
      setTimeout(() => {
        this.chartsPressione.forEach((element) => {
          this.downloadIMG(element['chart'].canvas, 'Grafico_Pressione');
        });
      }, 1000);
      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        //this.opzioniGrafici()
        this.pressione = false;
      }, 1000);
    }
    if (valore === 'temperatura') {
      this.temperatura = true;
      this.optionsTemp = {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 40,
                min: 32,
                stepSize: 2,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
      setTimeout(() => {
        this.chartsTemp.forEach(async (element) => {
          /*await*/ this.downloadIMG(
            element['chart'].canvas,
            'Grafico_Temperatura'
          );
        });
      }, 1000);
      setTimeout(() => {
        this.opzioniGraficiReset(valore);
        //this.opzioniGrafici()
        this.temperatura = false;
      }, 1000);
    }
  }

  includesSmartbed() {
    let response = false;
    this.selectedDispositivi.forEach((element) => {
      if (element.deviceType.tipologia === 'smartbed') {
        response = true;
      }
    });
    return response;
  }

  downloadIMG(canvas, nome) {
    // get the canvas 2d context
    return new Promise((resolve, reject) => {
      let ctx = canvas.getContext('2d');
      // set the ctx to draw beneath your current content
      ctx.globalCompositeOperation = 'destination-over';
      // // set the fill color to white
      ctx.fillStyle = 'white';
      // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
      // these two points are the top left and the bottom right of the canvas
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      let url_base64jp = canvas.toDataURL('image/jpeg');
      var a = document.createElement('a'); //Create <a>
      a.href = url_base64jp; //Image Base64 Goes here
      a.download = nome; //File name Here
      a.click(); //Downloaded file
      resolve(true);
      reject(false);
    });
  }
  getBase64IMG(canvas, nome) {
    // get the canvas 2d context
    return new Promise((resolve, reject) => {
      let ctx = canvas.getContext('2d');
      // set the ctx to draw beneath your current content
      ctx.globalCompositeOperation = 'destination-over';
      // // set the fill color to white
      ctx.fillStyle = 'white';
      // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
      // these two points are the top left and the bottom right of the canvas
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      let url_base64jp = canvas.toDataURL('image/jpeg');
      resolve(url_base64jp);
      reject(false);
    });
  }
  // this.opzioniGrafici()

  // let ctx = canvas.getContext('2d');
  // // set the ctx to draw beneath your current content
  // ctx.globalCompositeOperation = 'destination-over';
  // // // set the fill color to white
  // ctx.fillStyle = 'white';
  // // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
  // // these two points are the top left and the bottom right of the canvas
  // ctx.fillRect(0, 0, canvas.width, canvas.height);
  // let url_base64jp = canvas.toDataURL("image/jpeg");
  // var a = document.createElement("a"); //Create <a>
  // a.href =  url_base64jp //Image Base64 Goes here
  // a.download = nome; //File name Here
  // a.click(); //Downloaded file

  //refresh grafici bug lable

  changeWidth(list: any[] = []): number {
    const startWidth = 2000;
    return list && list.length > 48
      ? startWidth *
          Math.trunc(
            list.length % 48 !== 0 ? list.length / 48 + 1 : list.length / 48
          )
      : null;
  }

  onClickLabelPressione(isMinima: boolean) {
    if (isMinima) {
      this.pressioneMinimaMarked = !this.pressioneMinimaMarked;
      if (this.chartPressione) {
        this.chartPressione.data.datasets[0].hidden = this.pressioneMinimaMarked
          ? true
          : false;
        this.chartPressione.chart.update();
      }
    } else {
      this.pressioneMassimaMarked = !this.pressioneMassimaMarked;
      if (this.chartPressione) {
        this.chartPressione.data.datasets[1].hidden = this
          .pressioneMassimaMarked
          ? true
          : false;
        this.chartPressione.chart.update();
      }
    }
  }
  graficoPressioneWidthFunction() {
    let width = document.getElementById('graficoPressioneWidth').clientWidth;
    return width;
  }

  // printDataInConsole()
  // {
  //   console.log(this.dataBattitoCardiacoIot.datasets[0].data);
  //   console.log(this.optionsBPM);
  //   console.log(this.optionsBPM2);
  //   this.dataPressioneIot.datasets[0].data.push(10);
  //   this.dataPressioneIot.datasets[0].data.push(310);
  //   this.dataPressioneIot.labels.push("Test min");
  //   this.dataPressioneIot.labels.push("Test max");
  // }
}
