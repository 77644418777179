<mat-horizontal-stepper orientation="horizontal" [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Dati Farmacia</ng-template>
      <div *ngFor="let item of firstStepInputs; let i = index">
        <div class="flex" *ngIf="i%2==0">
          <mat-form-field class="w-45">
            <mat-label>{{getLabel(firstStepInputs[i])}}</mat-label>
            <input matInput [placeholder]="getLabel(firstStepInputs[i])" [(ngModel)]="inputForm[firstStepInputs[i]]"
              [formControlName]="firstStepInputs[i]" >
          </mat-form-field>

          <mat-form-field class="w-45 ml-auto" *ngIf="firstStepInputs[i+1] && !firstStepInputs[i+1].toLowerCase().includes('mercato')">
            <mat-label>{{getLabel(firstStepInputs[i+1])}}</mat-label>
            <input matInput [placeholder]="getLabel(firstStepInputs[i+1])" [(ngModel)]="inputForm[firstStepInputs[i+1]]"
              [formControlName]="firstStepInputs[i+1]" >
          </mat-form-field>

          <mat-form-field class="w-45 ml-auto"
            *ngIf="firstStepInputs[i+1] && firstStepInputs[i+1].toLowerCase().includes('mercato')">
            <mat-label>{{getLabel(firstStepInputs[i+1])}}</mat-label>
            <mat-select [(ngModel)]="inputForm.ricerca_Di_Mercato" name="Ricerca" [formControlName]="firstStepInputs[i+1]">
              <mat-option [value]="''">
                Nessuno
              </mat-option>
              <mat-option *ngFor="let ricerca of listaMercato" [value]="ricerca.nome">
                {{ricerca.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="flex">
      <button class="btn" (click)="emitGoBack()">Indietro</button>
      <button class="btn btn-primary ml-auto" matStepperNext>Avanti</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Dati Anagrafici</ng-template>
      <div *ngFor="let item of secondStepInputs; let i = index">
        <div class="flex" *ngIf="i%2==0">
          <mat-form-field class="w-45">
            <mat-label>{{getLabel(secondStepInputs[i])}}</mat-label>
            <input matInput [placeholder]="getLabel(secondStepInputs[i])" [(ngModel)]="inputForm[secondStepInputs[i]]"
              [formControlName]="secondStepInputs[i]">
          </mat-form-field>

          <mat-form-field class="w-45 ml-auto" *ngIf="secondStepInputs[i+1]">
            <mat-label>{{getLabel(secondStepInputs[i+1])}}</mat-label>
            <input matInput [placeholder]="getLabel(secondStepInputs[i+1])" [(ngModel)]="inputForm[secondStepInputs[i+1]]"
              [formControlName]="secondStepInputs[i+1]">
          </mat-form-field>
        </div>
      </div>
      <div>
      </div>
    </form>
    <div class="flex">
      <button class="btn" matStepperPrevious>Indietro</button>
      <button class="btn btn-primary ml-auto" matStepperNext>Avanti</button>
    </div>
  </mat-step>
  <mat-step>

    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Dati Classe</ng-template>
      <div *ngFor="let item of thirdStepInputs; let i = index">
        <div class="flex" *ngIf="i%2==0">
          <mat-form-field class="w-45">
            <mat-label>{{getLabel(thirdStepInputs[i])}}</mat-label>
            <input matInput [placeholder]="getLabel(thirdStepInputs[i])" [(ngModel)]="inputForm[thirdStepInputs[i]]"
              [formControlName]="thirdStepInputs[i]">
          </mat-form-field>

          <mat-form-field class="w-45 ml-auto" *ngIf="thirdStepInputs[i+1]">
            <mat-label>{{getLabel(thirdStepInputs[i+1])}}</mat-label>
            <input matInput [placeholder]="getLabel(thirdStepInputs[i+1])" [(ngModel)]="inputForm[thirdStepInputs[i+1]]"
              [formControlName]="thirdStepInputs[i+1]">
          </mat-form-field>
        </div>
      </div>
      <div>
      </div>
    </form>
    <div class="flex">
      <button class="btn" matStepperPrevious>Indietro</button>
      <button class="btn btn-primary ml-auto" matStepperNext>Avanti</button>
    </div>
  </mat-step>
  <mat-step>

    <form [formGroup]="fourthFormGroup">
      <ng-template matStepLabel>Altro</ng-template>
      <div *ngFor="let item of fourthStepInputs; let i = index">
        <div class="flex" *ngIf="i%2==0">
          <mat-form-field class="w-45" *ngIf="!fourthStepInputs[i].toLowerCase().includes('data') && fourthStepInputs[i+1]">
            <mat-label>{{getLabel(fourthStepInputs[i])}}</mat-label>
            <input matInput [placeholder]="getLabel(fourthStepInputs[i])" [(ngModel)]="inputForm[fourthStepInputs[i]]"
              [formControlName]="fourthStepInputs[i]" >
          </mat-form-field>
          <mat-form-field class="w-45" *ngIf="!fourthStepInputs[i].toLowerCase().includes('data') && !fourthStepInputs[i+1]">
            <mat-label>{{getLabel(fourthStepInputs[i])}}</mat-label>
            <mat-select [(ngModel)]="inputForm.elaborazione_Report" name="Ricerca" [formControlName]="fourthStepInputs[i]">
              <mat-option [value]="'No'">
                No
              </mat-option>
              <mat-option [value]="'Si'">
                Si
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-45" *ngIf="fourthStepInputs[i].toLowerCase().includes('data')">
            <mat-label>{{getLabel(fourthStepInputs[i])}}</mat-label>
            <input disabled matInput [placeholder]="getLabel(fourthStepInputs[i])" [(ngModel)]="inputForm[fourthStepInputs[i]]"
              [formControlName]="fourthStepInputs[i]" [matDatepicker]="datepicker">
              <mat-hint>GG/MM/AAAA</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker disabled="false">
                  <button mat-button matDatepickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              </mat-datepicker>
          </mat-form-field>

          <mat-form-field class="w-45 ml-auto" *ngIf="fourthStepInputs[i+1] && !fourthStepInputs[i+1].toLowerCase().includes('data')">
            <mat-label>{{getLabel(fourthStepInputs[i+1])}}</mat-label>
            <input matInput [placeholder]="getLabel(fourthStepInputs[i+1])" [(ngModel)]="inputForm[fourthStepInputs[i+1]]"
              [formControlName]="fourthStepInputs[i+1]">
          </mat-form-field>
          <mat-form-field class="w-45 ml-auto" *ngIf="fourthStepInputs[i+1] && fourthStepInputs[i+1].toLowerCase().includes('data')">
            <mat-label>{{getLabel(fourthStepInputs[i+1])}}</mat-label>
            <input disabled matInput [placeholder]="getLabel(fourthStepInputs[i+1])" [(ngModel)]="inputForm[fourthStepInputs[i+1]]"
              [formControlName]="fourthStepInputs[i+1]" [matDatepicker]="datepicker">
              <mat-hint>GG/MM/AAAA</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker disabled="false">
                  <button mat-button matDatepickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div>
      </div>
    </form>
    <div class="flex">
      <button class="btn" matStepperPrevious>Indietro</button>
      <button class="btn btn-primary ml-auto" (click)="salvaForm()">Salva</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
