<div class="card shadow ">
  <!-- <div class="card-header"> -->
  <div *ngIf="traccia" class="colorPalette"><span
      style="color: white;font-weight: bold; margin-right: 20px; font-size: small;">MENO RECENTE</span><span
      style="color: white;font-weight: bold; font-size: small; margin-left: 20px;">PIÙ RECENTE</span></div>
  <!-- <div class="row" style="margin-right: -5px; margin-left: -5px;"> -->
  <!-- </div> -->
  <!-- [ngClass]="{'col-xs-8': !traccia, 'col-xs-12': traccia}" -->
  <div style="fill: aquamarine; width: 100%; height: 100%; z-index: 9999;"></div>
  <div class="card-body">
    <div [ngStyle] = "{display: overlayVisib}" class="overlay">
      <div class="overlay-content" style="align-content: center;">
        <p-progressSpinner [style]="{width: '100px', height: '100px'}" strokeWidth="5"
                  styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
        <p>Recupero dati. Attendi...</p>
      </div>
      </div>
    <div id="sidebar" class="leaflet-sidebar collapsed">
      <!-- Nav tabs -->
      <div  class="leaflet-sidebar-tabs">
        <ul role="tablist">
          <li>
            <a href="#home" role="tab" (click)="changeRenderedList('home')"><i class="fa fa-bars"></i></a>
          </li>
          <li>
            <a href="#users" role="tab" (click)="changeRenderedList('users')"><i class="fa fa-user"></i></a>
          </li>
          <!-- <li class="disabled">
              <a href="#messages" role="tab"><i class="fa fa-envelope"></i></a>
            </li> -->
          <li>
            <a href="#devices" role="tab" class="material-icons"
              (click)="changeRenderedList('devices')">devices_other</a>
          </li>
        </ul>
        <ul [style.display] = "debug" role="tablist">
          <li>
            <a href="#settings" role="tab" (click)="changeRenderedList('settings')"><i class="fa fa-gear"></i></a>
          </li>
        </ul>
      </div>
      

      <!-- Tab panes -->
      <div class="leaflet-sidebar-content">
        <div class="leaflet-sidebar-pane" id="home">
          <h1 class="leaflet-sidebar-header">
            Generale <span *ngIf="traccia">(Tracciamento attivo)</span>
            <span class="leaflet-sidebar-close"><i class="fa fa-caret-right"></i></span>
          </h1>
          <div>
            <div *ngIf="!spinner && !this.tracker && usersStatic.length<1" class="no-coordinates-found-alert">
              <i class="material-icons nc-icon">location_off</i><br>
              <span class="nc-label">Nessun dispositivo o utente connesso!</span>
            </div>

            <div *ngIf="selectedPanel=='home'" class="coordinates-container">
              <!-- <div *ngIf="!fullScreenMap"> -->
              <div *ngIf="spinner" style="position: absolute; top: 50%; left: 50%; margin: -25px 0 0 -25px;">
                <p-progressSpinner [style]="{width: '100px', height: '100px'}" strokeWidth="5"
                  styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
              </div>
              <ng-container *ngIf="!spinner">
                <mat-card *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5">
                  <div class="rowGrid m-0 bg-color">
                    <div class="arrowFirstGrid iconCenter">
                      <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                        (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                        [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i>
                      <i *ngIf="!user.tracker && !showUser" class="material-icons notranslate" style="margin-top: 10px;"
                        [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                    </div>
                    <div class="nameSecondGrid">
                      <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                        {{user.nome}} {{user.cognome}}
                      </div>
                      <div>
                        <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                        <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                          [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                      </div>
                    </div>
                    <div class="actionTerzioGrid">

                      <div class="row m-0 iconCenter">
                        <i *ngIf="checkForAlarms(user.id)" [matTooltip]="'Gestisci allarme'"
                          (click)="apriGestioneAllarme(user.id)"
                          class="material-icons notranslate c-red fs-large siAllarme">
                          report_problem
                        </i>
                        <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                          <span *ngIf="powerMode == 0 "><i class="material-icons notranslate"
                            [matTooltip]="outletYes">battery_charging_full</i>

                        </span>
                          <span *ngIf="powerMode == 1 && batteryLevel >= 95"><i class="material-icons notranslate"
                              [matTooltip]="batteryLevel">battery_full</i>

                          </span>
                          <span *ngIf="powerMode == 1 && batteryLevel >= 79 && batteryLevel < 95">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>

                          </span>
                          <span *ngIf="powerMode == 1 && batteryLevel >= 63 && batteryLevel < 79">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>

                          </span>
                          <span *ngIf="powerMode == 1 && batteryLevel >= 47 && batteryLevel < 63">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>

                          </span>
                          <span *ngIf="powerMode == 1 && batteryLevel >= 31 && batteryLevel < 47">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>

                          </span>
                          <span *ngIf="powerMode == 1 && batteryLevel >= 15 && batteryLevel < 31">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>

                          </span>
                          <span *ngIf="powerMode == 1 && batteryLevel > 0 && batteryLevel < 15">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>

                          </span>

                          <span *ngIf="batteryLevel == 0 || !batteryLevel">
                            <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>

                          </span>
                        </a>
                        <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                            class="material-icons notranslate"
                            (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>

                          <ng-container
                            *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                          </ng-container>

                        </a>
                        <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                            class="material-icons notranslate"
                            [ngStyle]="{'color' : user.counter>=-1 ? colorArray[user.counter]: 'gray'}">place</i></a>
                        <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user.tracker)"
                          [ngStyle]="{'color' : user.counter>=-1 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                            class="material-icons notranslate">near_me</i></a>
                        <!-- PoC: riportare -1 -->
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="state[j] === 'rotated'">
                    <div class="m-0 bg-color titolo" *ngIf="!showUser">
                      Utente associato:
                    </div>
                    <div *ngIf="user.user && !showUser">
                      <div class="rowGrid m-0 bg-color">
                        <div class="arrowFirstGrid iconCenter">
                          <!-- <i *ngIf="user.tracker" class="material-icons pointer notranslate" (click)="rotate(j,user.id)" [@rotatedState]='state[j]'
                            [ngStyle]="{'color' : colorArray[user.token]}">arrow_right</i> -->
                          <i class="material-icons notranslate" style="margin-top: 10px;"
                            [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                        </div>
                        <div class="nameSecondGrid">
                          <div class="mb-10">
                            {{user.user.nome}} {{user.user.cognome}}
                          </div>
                        </div>
                        <div class="actionTerzioGrid">

                          <div class="row m-0 iconCenter">
                            <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                                class="material-icons notranslate">place</i></a>
                            <a class="track" style="color: greenyellow;"
                              (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                                class="material-icons notranslate">near_me</i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </mat-card>
              </ng-container>
              <!-- </div> -->
              <div class="stopTrackContainer">
                <button class="btn btn-light stopTrack" *ngIf="traccia" (click)="stopTracciamento()">Stop Tracciamento
                  {{nome}}</button>
              </div>
            </div>

          </div>

        </div>

        <div class="leaflet-sidebar-pane" id="users">
          <h1 class="leaflet-sidebar-header">
            Utenti <span *ngIf="traccia">(Tracciamento attivo)</span><span class="leaflet-sidebar-close"><i
                class="fa fa-caret-right"></i></span>
          </h1>
          <div *ngIf="selectedPanel=='users'" class="coordinates-container">
            <!-- <div *ngIf="!fullScreenMap"> -->
            <div *ngIf="spinner" style="position: absolute; top: 50%; left: 50%; margin: -25px 0 0 -25px;">
              <p-progressSpinner [style]="{width: '100px', height: '100px'}" strokeWidth="5" styleClass="custom-spinner"
                animationDuration=".5s"></p-progressSpinner>
            </div>

            <div *ngIf="!spinner && usersStatic.length<1" class="no-coordinates-found-alert">
              <i class="material-icons nc-icon">location_off</i><br>
              <span class="nc-label">Nessun utente connesso!</span>
            </div>
            <ng-container *ngIf="!spinner">
              <mat-card *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5">
                <div class="rowGrid m-0 bg-color">
                  <div class="arrowFirstGrid iconCenter">
                    <!-- <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                        (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                        [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i> -->
                    <!-- *ngIf="!user.tracker && !showUser" -->
                    <i class="material-icons notranslate" style="margin-top: 10px;"
                      [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                  </div>
                  <div class="nameSecondGrid">
                    <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                      {{user.nome}} {{user.cognome}}
                    </div>
                    <div>
                      <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                      <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                        [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                    </div>
                  </div>
                  <div class="actionTerzioGrid">

                    <div class="row m-0 iconCenter">

                      <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                        <span *ngIf="batteryLevel >= 95"><i class="material-icons notranslate"
                            [matTooltip]="batteryLevel">battery_full</i>

                        </span>
                        <span *ngIf="batteryLevel >= 79 && batteryLevel < 95">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 63 && batteryLevel < 79">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 47 && batteryLevel < 63">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 31 && batteryLevel < 47">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 15 && batteryLevel < 31">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>

                        </span>
                        <span *ngIf="batteryLevel > 0 && batteryLevel < 15">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>

                        </span>

                        <span *ngIf="batteryLevel == 0 || !batteryLevel">
                          <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>

                        </span>
                      </a>
                      <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                          class="material-icons notranslate"
                          (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>

                        <ng-container
                          *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                        </ng-container>

                      </a>
                      <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                          class="material-icons notranslate"
                          [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">place</i></a>
                      <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user.tracker)"
                        [ngStyle]="{'color' : user.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                          class="material-icons notranslate">near_me</i></a>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="state[j] === 'rotated'">
                  <div class="m-0 bg-color titolo" *ngIf="!showUser">
                    Utente associato:
                  </div>
                  <div *ngIf="user.user && !showUser">
                    <div class="rowGrid m-0 bg-color">
                      <div class="arrowFirstGrid iconCenter">
                        <i class="material-icons notranslate" style="margin-top: 10px;"
                          [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                      </div>
                      <div class="nameSecondGrid">
                        <div class="mb-10">
                          {{user.user.nome}} {{user.user.cognome}}
                        </div>
                      </div>
                      <div class="actionTerzioGrid">

                        <div class="row m-0 iconCenter">
                          <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                              class="material-icons notranslate">place</i></a>
                          <a class="track" style="color: greenyellow;"
                            (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                              class="material-icons notranslate">near_me</i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-0 bg-color titolo" *ngIf="!showUser && listaBeacons && listaBeacons.length > 0">
                    Beacon associati:
                  </div>
                  <div *ngIf="listaBeacons && listaBeacons.length > 0">
                    <ng-container *ngFor="let beacon of listaBeacons; let c = index">
                      <ng-container *ngIf="!showUser">
                        <div (click)="zoom(user.latitudine, user.longitudine)" class="rowGrid m-0 bg-color">
                          <div class="arrowFirstGrid iconCenter">
                            <i style="width: 24px;" [matTooltip]="'Ottimo'" class="material-icons notranslate"
                              *ngIf="beacon.rssi > -55 && beacon.rssi !== null">wifi</i>
                            <i style="width: 24px;" [matTooltip]="'Buono'" class="material-icons notranslate"
                              *ngIf="beacon.rssi >= -80 && beacon.rssi <= -55">wifi_2_bar</i>
                            <i style="width: 24px;" [matTooltip]="'Scarso'" class="material-icons notranslate"
                              *ngIf="beacon.rssi < -80">wifi_1_bar</i>
                            <i style="width: 24px;" [matTooltip]="'Fuori Portata'" class="material-icons notranslate"
                              *ngIf="!beacon.rssi">wifi_off</i>
                          </div>
                          <div class="nameSecondGrid">
                            <div class="mb-10">
                              {{beacon.device.deviceName}}
                            </div>
                          </div>

                          <div class="actionTerzioGrid">
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="showUser">
                        <div (click)="zoom(beacon.ultimaPosizione.latitudine, beacon.ultimaPosizione.longitudine)"
                          class="rowGrid m-0 bg-color" style="border-bottom: 0px">
                          <div class="arrowFirstGrid iconCenter">
                            <i class="material-icons pointer notranslate"
                              [ngStyle]="{'color' : beacon.counter>=0 ? colorArray[beacon.counter]: 'gray'}">people</i>
                          </div>
                          <div class="nameSecondGrid">
                            <div class="mb-10">
                              {{beacon.nome}}
                            </div>
                          </div>

                          <div class="actionTerzioGrid">

                            <div class="row m-0 iconCenter">

                              <a [ngClass]="{'d-none': !beacon.tracker}" class="geo tooltipIcon"><i
                                  class="material-icons notranslate"
                                  (click)="changeStatusCalendar(this.listToolTip[c], c)">download</i>

                                <ng-container
                                  *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[c], user: beacon}">
                                </ng-container>

                              </a>
                              <a class="track"
                                (click)="tracciamento(beacon.id, beacon.nome + ' ' + beacon.cognome, true)"
                                [ngStyle]="{'color' : beacon.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                                  class="material-icons notranslate">near_me</i></a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>

                  </div>
                </ng-container>
              </mat-card>
            </ng-container>
            <!-- </div> -->
          </div>
          <div class="stopTrackContainer">
            <button class="btn btn-light stopTrack" *ngIf="traccia" (click)="stopTracciamento()">Stop Tracciamento
              {{nome}}</button>
          </div>
        </div>

        <div class="leaflet-sidebar-pane" id="devices">
          <h1 class="leaflet-sidebar-header">
            Dispositivi <span *ngIf="traccia">(Tracciamento attivo)</span><span class="leaflet-sidebar-close"><i
                class="fa fa-caret-right"></i></span>
          </h1>
          <div *ngIf="selectedPanel=='devices'" class="coordinates-container">
            <!-- <div *ngIf="!fullScreenMap"> -->
            <div *ngIf="spinner" style="position: absolute; top: 50%; left: 50%; margin: -25px 0 0 -25px;">
              <p-progressSpinner [style]="{width: '100px', height: '100px'}" strokeWidth="5" styleClass="custom-spinner"
                animationDuration=".5s"></p-progressSpinner>
            </div>
            <!-- TODO ALESSANDRO-->
            <div *ngIf="!spinner && !this.tracker" class="no-coordinates-found-alert">
              <i class="material-icons nc-icon">location_off</i><br>
              <span class="nc-label">Nessun dispositivo connesso!</span>
            </div>
            <ng-container *ngIf="this.tracker && !spinner">
              <mat-card *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5">

                <div class="rowGrid m-0 bg-color">
                  <div class="arrowFirstGrid iconCenter">
                    <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                      (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                      [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i>
                    <i *ngIf="!user.tracker && !showUser" class="material-icons notranslate" style="margin-top: 10px;"
                      [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                  </div>
                  <div class="nameSecondGrid">
                    <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                      {{user.nome}} {{user.cognome}}
                    </div>
                    <div>
                      <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                      <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                        [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                    </div>
                  </div>
                  <div class="actionTerzioGrid">

                    <div class="row m-0 iconCenter">

                      <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                        <span *ngIf="batteryLevel >= 95"><i class="material-icons notranslate"
                            [matTooltip]="batteryLevel">battery_full</i>

                        </span>
                        <span *ngIf="batteryLevel >= 79 && batteryLevel < 95">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 63 && batteryLevel < 79">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 47 && batteryLevel < 63">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 31 && batteryLevel < 47">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>

                        </span>
                        <span *ngIf="batteryLevel >= 15 && batteryLevel < 31">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>

                        </span>
                        <span *ngIf="batteryLevel > 0 && batteryLevel < 15">
                          <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>

                        </span>

                        <span *ngIf="batteryLevel == 0 || !batteryLevel">
                          <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>

                        </span>
                      </a>
                      <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                          class="material-icons notranslate"
                          (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>

                        <ng-container
                          *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                        </ng-container>

                      </a>
                      <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                          class="material-icons notranslate"
                          [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">place</i></a>
                      <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user.tracker)"
                        [ngStyle]="{'color' : user.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                          class="material-icons notranslate">near_me</i></a>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="state[j] === 'rotated'">
                  <div class="m-0 bg-color titolo" *ngIf="!showUser">
                    Utente associato:
                  </div>
                  <div *ngIf="user.user && !showUser">
                    <div class="rowGrid m-0 bg-color">
                      <div class="arrowFirstGrid iconCenter">
                        <i class="material-icons notranslate" style="margin-top: 10px;"
                          [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                      </div>
                      <div class="nameSecondGrid">
                        <div class="mb-10">
                          {{user.user.nome}} {{user.user.cognome}}
                        </div>
                      </div>
                      <div class="actionTerzioGrid">

                        <div class="row m-0 iconCenter">
                          <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                              class="material-icons notranslate">place</i></a>
                          <a class="track" style="color: greenyellow;"
                            (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                              class="material-icons notranslate">near_me</i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-0 bg-color titolo" *ngIf="!showUser && listaBeacons && listaBeacons.length > 0">
                    Beacon associati:
                  </div>
                  <div *ngIf="listaBeacons && listaBeacons.length > 0">
                    <ng-container *ngFor="let beacon of listaBeacons; let c = index">
                      <ng-container *ngIf="!showUser">
                        <div (click)="zoom(user.latitudine, user.longitudine)" class="rowGrid m-0 bg-color">
                          <div class="arrowFirstGrid iconCenter">
                            <i style="width: 24px;" [matTooltip]="'Ottimo'" class="material-icons notranslate"
                              *ngIf="beacon.rssi > -55 && beacon.rssi !== null">wifi</i>
                            <i style="width: 24px;" [matTooltip]="'Buono'" class="material-icons notranslate"
                              *ngIf="beacon.rssi >= -80 && beacon.rssi <= -55">wifi_2_bar</i>
                            <i style="width: 24px;" [matTooltip]="'Scarso'" class="material-icons notranslate"
                              *ngIf="beacon.rssi < -80">wifi_1_bar</i>
                            <i style="width: 24px;" [matTooltip]="'Fuori Portata'" class="material-icons notranslate"
                              *ngIf="!beacon.rssi">wifi_off</i>
                          </div>
                          <div class="nameSecondGrid">
                            <div class="mb-10">
                              {{beacon.device.deviceName}}
                            </div>
                          </div>

                          <div class="actionTerzioGrid">
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="showUser">
                        <div (click)="zoom(beacon.ultimaPosizione.latitudine, beacon.ultimaPosizione.longitudine)"
                          class="rowGrid m-0 bg-color" style="border-bottom: 0px">
                          <div class="arrowFirstGrid iconCenter">
                            <i class="material-icons pointer notranslate"
                              [ngStyle]="{'color' : beacon.counter>=0 ? colorArray[beacon.counter]: 'gray'}">place</i>
                          </div>
                          <div class="nameSecondGrid">
                            <div class="mb-10">
                              {{beacon.nome}}
                            </div>
                          </div>

                          <div class="actionTerzioGrid">

                            <div class="row m-0 iconCenter">
                              <a [ngClass]="{'d-none': !beacon.tracker}" class="geo tooltipIcon"><i
                                  class="material-icons notranslate"
                                  (click)="changeStatusCalendar(this.listToolTip[c], c)">download</i>

                                <ng-container
                                  *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[c], user: beacon}">
                                </ng-container>

                              </a>
                              <a class="track"
                                (click)="tracciamento(beacon.id, beacon.nome + ' ' + beacon.cognome, true)"
                                [ngStyle]="{'color' : beacon.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                                  class="material-icons notranslate">near_me</i></a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>

                  </div>
                </ng-container>
              </mat-card>
            </ng-container>
            <!-- </div> -->
            <div class="stopTrackContainer">
              <button class="btn btn-light stopTrack" *ngIf="traccia" (click)="stopTracciamento()">Stop Tracciamento
                {{nome}}</button>
            </div>
          </div>
        </div>

        <div  class="leaflet-sidebar-pane" id="settings">
          <h1 class="leaflet-sidebar-header">
            Debug<span class="leaflet-sidebar-close" S><i class="fa fa-caret-right"></i></span>
          </h1>
          <p>
            Questa sezione contiene shortcut utili per testare le funzioni della mappa.
          </p>
          <button (click)="flyTo()">Raggiungi coordinate arbitrarie</button>
          <button (click)="addMarker3()">Aggiungi marker</button>
          <button (click)="debugUserlist()">Stampa lista user in console</button>
        </div>
      </div>
    </div>

    <div id="map"></div>
    

  </div>
 


  <ng-template #tooltipCalendar let-tooltip="tooltip" let-user="user">
    <div class="tooltiptext" [ngClass]="{'tooltiptextVisible':tooltip}">
      <p-calendar #myCalendar view="date" (onSelect)="selezionaData()" [maxDate]="today" [readonlyInput]="true"
        [numberOfMonths]="1" yearRange="2000:2030" [(ngModel)]="rangeDates" [showOtherMonths]="false"
        placeholder="Select time period" selectionMode="range" [inline]="true" dateFormat="dd/mm/yy" [locale]="ita">

      </p-calendar>
      <div class="rightmr-10">
        <button class="btnDwn" (click)="exportToExcel(user)">
          <!-- <i class="fa fa-download"></i> -->
          Conferma Download
        </button>
      </div>
    </div>
  </ng-template>

  <!-- <p-dialog [draggable]="false" [resizable]="false" [(visible)]="modaleAllarme" [closeOnEscape]="false" modal="true">
  <h5 class="centered">{{allarme?.monitoring.messaggio}}</h5>

  <div class="flex">
    <button class="btn btn-secondary" (click)="modaleAllarme = false">Ignora</button>
    <button class="btn btn-primary ml-auto" (click)="raggiungiAllarme()">Visualizza posizione</button>
  </div>
</p-dialog> -->

  <p-dialog [(visible)]="modaleAllarme" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <div style="color: red !important">
        <i class="material-icons no-translate" style="font-size: xx-large !important">report_problem</i>
        <p style="font-size: medium !important">ALLARME</p>
      </div>
    </ng-template>
    <table class="table table-responsive scroll">
      <tr style="font-size: small">
        <span style="font-weight: bold">{{
          allarme?.monitoring.time | date : "dd/MM/yyyy HH:mm"
          }}</span>
        -
        {{
        allarme?.monitoring.messaggio
        }}
      </tr>
    </table>
    <p-footer>
      <p-button icon="pi pi-minus-circle" (click)="modaleAllarme=false" label="Ignora"
        styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-map-marker" (click)="raggiungiAllarme()" label="Visualizza"
        styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-cog" (click)="raggiungiAllarme();apriGestioneAllarme(this.allarme.monitoring.device)"
        label="Gestisci" styleClass="p-button-text"></p-button>
    </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="modaleGestioneAllarme" [draggable]="false" [resizable]="false" [modal]="true"
    [closeOnEscape]="true">
    <ng-template pTemplate="header">
      <div style="color:#3b82fc !important">
        <i class="material-icons no-translate" style="font-size: xx-large !important">assignment</i>
        <p style="font-size: medium !important">Gestione allarme</p>
      </div>
    </ng-template>
    <table class="table table-responsive scroll">
      <tr style="font-size: small">
        <span style="font-weight: bold">{{
          allarme?.monitoring.time | date : "dd/MM/yyyy HH:mm"
          }}</span>
        -
        {{
        allarme?.monitoring.messaggio
        }}
      </tr>
    </table>
    <p-footer>
      <p-button icon="pi pi-minus-circle" (click)="modaleGestioneAllarme=false" label="Indietro"
        styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-check" (click)="getConfermaAllarme(allarme.monitoring.id, 2)" label="Segna come risolto"
        styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times-circle" (click)="getConfermaAllarme(allarme.monitoring.id, 1)"
        label="Segna come falso allarme" styleClass="p-button-text"></p-button>
      <!-- <p-button
    icon="pi pi-pencil"
    (click)="1"
    label="Aggiungi una nota"
    styleClass="p-button-text"
  ></p-button> -->
      <p-button icon="pi pi-bell" (click)="notificaOperatore()" label="Notifica l'operatore"
        styleClass="p-button-text"></p-button>

    </p-footer>
  </p-dialog>

  <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci Nota</h5>
      </div>
      <div class="modal-body">

        <input *ngIf="!infoNota" type="textarea" class="form-control" [(ngModel)]="testoNota" placeholder="Descrizione"
          maxlength="60">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
        <button *ngIf="!infoNota" type="button" class="btn btn-primary" (click)="onSubmit()" data-dismiss="modal"
          [disabled]="!errorTxt">{{noteExists ? 'Modifica' : 'Aggiungi'}}</button>
      </div>
    </div>
  </div>
</div> -->