import { Output } from '@angular/core';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { Utente } from 'src/app/MODELS/USER/utente';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';
import { UsersService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-info-file',
  templateUrl: './info-file.component.html',
  styleUrls: ['./info-file.component.scss']
})
export class InfoFileComponent implements OnInit {
@Input() file;
@Output() tornaGestione = new EventEmitter<string>()
@Input() corso;
fileView=false;
corsoView=false;
listaUtenti = [];
visib;
listaProfili = [];
listaGruppi = [];
proprietario : string;
auleListSelected =[];

  constructor(private userService : UsersService,
  private  scormService:ScormService) { }

  ngOnInit(): void {
    if(this.file){
   
    this.fileView = true;
    if (this.file.owner.includes('-'))
    {
      let tmp = this.file.owner.split('-');
      this.proprietario = tmp[1];
    }
    else
    {
      this.proprietario = this.file.owner;
    }
    this.userService.getAllAttivi().subscribe((res)=>{
        
      res.data.forEach(element => {
        // if(element.id.toString() === this.file.owner){
        //   this.proprietario = element;
        // }
        if(this.file.visibUtente){
          this.visib="utente"
          this.file.visibUtente.forEach(utenteId => {
            if(element.id.toString() === utenteId){
              this.listaUtenti.push(element)
            }
          });
        }
      });
    })
    this.userService.getListaGruppi().subscribe((res)=>{
      res.data.forEach(element => {
        if(this.file.visibGruppo){
          this.visib="gruppo"
          this.file.visibGruppo.forEach(gruppoId => {
            if(element.id.toString() === gruppoId){
              this.listaGruppi.push(element)
            }
          });
        }
      });
    })
    this.userService.getListaRuoli().subscribe((res)=>{
      res.data.forEach(element => {
        if(this.file.visibRuolo){
          this.visib="profilo"
          this.file.visibRuolo.forEach(ruoloId => {
            if(element.id.toString() === ruoloId){
              this.listaProfili.push(element)
            }
          });
        }
      });
    })
  }
  if(this.corso){
    this.scormService.lista_classroom_flaggate(this.corso.id).subscribe((res)=>{
      this.auleListSelected=res.data;
      this.corsoView = true;
    })

  
  }
}

  clear(){
    this.corso = null;
    this.file = null;
    if(this.corsoView){
      this.tornaGestione.emit("corso")

    }else{
      this.tornaGestione.emit("file")

    }
  }
}



