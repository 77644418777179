import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common'
import { Utente } from 'src/app/MODELS/USER/utente';
import { ShareService } from '../GENERAL/share.service';
import { AlertService } from '../GENERAL/alert.service';
import { EnvService } from '../GENERAL/env.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<Utente>;
    public user: Observable<Utente>;
    public listaEnv;
    baseUrl: string;
    public token;
    public ruolo;


    constructor(
        private router: Router,
        private http: HttpClient,
        private shareService: ShareService,
        private alertService: AlertService,
        private location2: Location,
        private env: EnvService
    ) {
        this.userSubject = new BehaviorSubject<Utente>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): any {
        this.userSubject = new BehaviorSubject<Utente>(JSON.parse(localStorage.getItem('user')));
        return this.userSubject.value;
    }

    login(email, password) {

      let userAgent = navigator.userAgent;
      let browserName;

      if(userAgent.match(/chrome|chromium|crios/i)){
          browserName = "chrome";
        }else if(userAgent.match(/firefox|fxios/i)){
          browserName = "firefox";
        }  else if(userAgent.match(/safari/i)){
          browserName = "safari";
        }else if(userAgent.match(/opr\//i)){
          browserName = "opera";
        } else if(userAgent.match(/edg/i)){
          browserName = "edge";
        }else{
          browserName="No browser detection";
        }
        return this.http.post<any>(`${this.env.apiUrl}/authenticate?os=` + browserName + '&gapVer=' + this.env.versione , { email, password })
            .pipe(map(user => {
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                this.shareService.changeIsLoggedIn(true);
                return user;
            }));
    }

    beforeLogout() {
        return this.http.post(`${this.env.apiUrl}/doBeforeLogout`, null)
    }

    logout() {
        this.shareService.changeData(false);
        this.beforeLogout().subscribe((res) => {
            this.shareService.changeData(false);
            this.shareService.changeDataChat(false);
            localStorage.removeItem('user');
            this.shareService.changeIsLoggedIn(false);
            this.userSubject.next(null);
            this.location2.replaceState('/account/login');
            location.reload();
        });
    }


    controllo(): Observable<any> {
        return this.http.post<any>(`${this.env.apiUrl}/fa_nulla`, null)
    }

    sendEmail(email): Observable<any> {
        return this.http.post<any>(`${this.env.apiUrl}/reset_request`, email);
    }

    changePassword(token, passwords, first): Observable<any> {
        return this.http.post<any>(`${this.env.apiUrl}/reset_password?token=${token}&first=${first}`, passwords);
    }

    PrimoAccesso(email, passwords): Observable<any> {
        return this.http.post<any>(`${this.env.apiUrl}/cambio_password?email=${email}`, passwords);
    }

    getListaEnv(): Observable<any> {
        return this.http.get<any>(`${this.env.apiUrl}/acquisto_cliente`).pipe(map(res=> {
            this.listaEnv = res.data;
            this.env.ALFACALLCENTER = this.env.alfaCall;
            this.ruolo = this.listaEnv.ruolo
            this.listaEnv.listaModuli.forEach(element => {
                switch(element.servizioAcquistato){
                    case'AR' : this.env.AR = true; break;
                    case'MOBILEVIEW': this.env.MOBILEVIEW = true; break;
                    case'CLASSROOM': this.env.CLASSROOM = true; break;
                    case'DMS': this.env.DMS = true; break;
                    case'CR': this.env.CR = true; break;
                    case'GEO': this.env.GEO = true; break;
                    case'CHATBOT': this.env.CHATBOT = true; break;
                    case'IOT': this.env.IOT = true; break;
                    case'VIEWER360': this.env.VIEWER360 = true; break;
                    case'LAVAGNA': this.env.LAVAGNA = true; break;
                    case'SCORM': this.env.SCORM = true; break;
                    case'NEWSLETTER': this.env.NEWSLETTER = true; break;
                    case'RECORDING': this.env.RECORDING = true; break;
                    case'LIVECHAT': this.env.LIVECHAT = true; break;
                    case'BADGE':this.env.BADGE = true; break;
                    case'ALFACALLCENTER':this.env.ALFACALLCENTER = true; break;
                    case'TRACKING':this.env.TRACKING = true; break;
                    case'EVENTI':this.env.EVENTI = true; break;
                    case'CLIENTI':this.env.CLIENTI = true; break;
                    case'GOTO':this.env.goTo = true; break;
                }
            });
        }));;
    }

}
