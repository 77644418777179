/**
 * Classe che contiene i pacchetti arrivati e il loro id.
 * 
 */
export class LavagnaStorico{
    private idPacchetto:number
    private jsonReceive:string

    /**
    * Classe che contiene i pacchetti arrivati e il loro id.
    *
    * @param idPacchetto numero del pacchetto ricevuto
    * @param jsonReceive il json
    */
    constructor(idPacchetto: number, jsonReceive: string) {
        this.idPacchetto = idPacchetto
        this.jsonReceive = jsonReceive
    }

    public getIdPacchetto(): number {
        return this.idPacchetto;
    }

    /**
    * 
    * @returns json 
    */
    public getJsonReceive(): string {
        return this.jsonReceive;
    }

}