<div class="content" >
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <form [formGroup]="formPassword" (ngSubmit)="onSubmitPassword()" class="form-horizontal">
                                    <div class="card-header card-header-text" >
                                        <h4 class="card-title text-center" >Cambia Password</h4>
                                    </div>
                                    <div class="card-content">
                                        <div class="row">
                                            <label class="col-sm-2 label-on-left" for="oldpassword">Password Attuale<span class="colorRed"> *</span>    <span class="input-group-text pull-right" id="basic-addon1" style="margin-left: 2%;"><i [class]="hide1 ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off' " style="font-size: 20px; cursor: pointer;"  (click)="hide1 = !hide1"></i></span></label>
                                            <div class="col-sm-10">
                                                <div class="form-group label-floating is-empty">
                                                    <label class="control-label"></label>
                                                    <input [type]="hide1 ? 'password' : 'text'" formControlName="oldpassword"
                                                        class="form-control" maxlength="100"
                                                        [ngClass]="{ 'is-invalid': submitted && g.oldpassword.errors }" />
                                                    <div *ngIf="submitted && g.oldpassword.errors" class="invalid-feedback">
                                                        <div *ngIf="g.oldpassword.errors.required">Password Attuale obbligatoria</div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-2 label-on-left" for="newpassword" >Nuova Password<span class="colorRed"> *</span> <span class="input-group-text pull-right" id="basic-addon1" style="margin-left: 2%;"><i [class]="hide2 ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off' " style="font-size: 20px; cursor: pointer;" (click)="hide2 = !hide2"></i></span></label>
                                            <div class="col-sm-10">
                                                <div class="form-group label-floating is-empty">
                                                    <label class="control-label"></label>
                                                    <input [type]="hide2 ? 'password' : 'text'" formControlName="newpassword"  class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && g.newpassword.errors }" maxlength="16" />
                                                    <div *ngIf="submitted && g.newpassword.errors" class="invalid-feedback">
                                                        <div *ngIf="g.newpassword.errors.required">Nuova Password obbligatoria</div>
                                            <div *ngIf="g.newpassword.errors.pattern">La password deve iniziare con una lettera ed essere lunga tra gli 8 e i 16 caratteri, deve contenere almeno: una lettera minuscola e maiuscola, un numero e un carattere speciale ($, @, !, %, *, ?, &)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-2 label-on-left" for="confermapassword"  >Conferma Password<span class="colorRed"> *</span> <span class="input-group-text " id="basic-addon1"><i [class]="hide3 ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off' " style="font-size: 20px;margin-left: 1%; cursor: pointer;" (click)="hide3 = !hide3"></i></span></label>
                                            <div class="col-sm-10">
                                                <div class="form-group label-floating is-empty">
                                                    <label class="control-label"></label>
                                                    <input [type]="hide3 ? 'password' : 'text'" formControlName="confermapassword"
                                                        class="form-control" maxlength="100"
                                                        [ngClass]="{ 'is-invalid': submitted && g.confermapassword.errors }"  />
                                                    <div *ngIf="submitted && g.confermapassword.errors" class="invalid-feedback">
                                                        <div *ngIf="g.confermapassword.errors.required">Conferma password obbligatoria</div>
                                            <div *ngIf="g.confermapassword.errors.mustMatch">Le password devono coincidere</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                       
                                        <br>
                                        <div class="text-right" style="margin-right: 5%;">
                                            <hr>
                                            <button class="btn btn-secondary" (click)="indietro()">
                                                Indietro
                                            </button>
                                            <button [disabled]="loading" class="btn btn-primary">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                Conferma
                                            </button>
                                        </div><br>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>