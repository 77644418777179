import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Id_gruppo } from 'src/app/MODELS/USER/id_gruppo';
import { Utente } from 'src/app/MODELS/USER/utente';
import { Doctor } from 'src/app/MODELS/USER/doctor';
import { AlertService, UsersService } from 'src/app/SERVICES';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import {FormFilterComponent} from '../../gestione-medici/form-filter/form-filter.component';
import { Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'app-modifica-gruppo',
  templateUrl: './modifica-gruppo.component.html',
  styleUrls: ['./modifica-gruppo.component.scss']
})
export class ModificaGruppoComponent implements OnInit {

  @Input() gruppo: Id_gruppo;
  @Output() vaiGestioneGruppo = new EventEmitter<boolean>();

  gruppoModForm: FormGroup;
  searchForm: FormGroup;
  searchForm2: FormGroup;

  listautenti = []; // totale per aggiungere utente
  listautentiGruppo = [];
  allListautenti: Doctor[] = [];

  idGruppo: string;
  users: Doctor[] = []; // filtrati
  allUser: Doctor[];
  allUserGruppo: Doctor[];
  submitted = false;

  // visibilità html
  modifica = true;
  add = false;
  flag = false;
  cols: any[];
  msgs: Message[] = [];
  selectedUsers3: Doctor[];
  utentiSelezionati: Doctor[];
  idUtentiSelezionati: any[];
  nomeGruppo: string;
  descrizioneGruppo: string;

  // Filtri e paginazione
  filtersVisible = false;
  removeFilters = false;
  filters: any = {};
  @ViewChild(FormFilterComponent) formFilterComponent: FormFilterComponent;
  refreshView = true;
  numeroMaxPagine: number;
  items;
  maxItems;
  isClickable = true;
  pagina;
  size = 10;
  pagination = 0;
  selectedValue;
  valoreRicerca;
  subscriptionallUser: Subscription;
    carica: boolean;
  caricato: boolean;
  isClickableCancellati: boolean;
  paginatorCancellati: boolean;
  paginator: boolean;
  isSearchingOn: boolean;
  isSelectingValue: boolean;
  isSelectingValueCancellati: boolean;
  showTable: boolean;

  constructor(
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService
  ) {
    this.gruppoModForm = this.formBuilder.group({
      nomeMod: ['', Validators.required],
      descrizioneMod: ['', Validators.required]
    });

    this.cols = [
      { field: 'email', header: 'Email' },
      { field: 'nome', header: 'Nome' },
      { field: 'cognome', header: 'Cognome' },
      { field: 'gruppos[0].nomeGruppo', header: 'Gruppo' }
    ];

    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });

    this.searchForm2 = this.formBuilder.group({
      search2: ['', Validators.required]
    });

    this.pagina = 0;
    this.selectedValue = 10;
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.userService.getAll().subscribe((res) => {
      this.listautenti = res.data.listaUtentiAttivi;
      this.allUser = this.listautenti;
    });

    this.modificaGruppo(this.gruppo.id, this.gruppo.nomeGruppo, this.gruppo.descrizione);
  }

  get l() {
    return this.gruppoModForm.controls;
  }

  modificaGruppo(id: string, nomeGruppo: string, descrizioneGruppo: string) {
    this.userService.getAllGruppo(id).subscribe((res) => {
      let temp = res.data;
      this.users = temp;
      this.allListautenti = this.users;

      this.idGruppo = this.gruppo.id;
      this.nomeGruppo = nomeGruppo;
      this.descrizioneGruppo = descrizioneGruppo;

      this.gruppoModForm = this.formBuilder.group({
        nomeMod: [this.gruppo.nomeGruppo, [Validators.required, Validators.pattern(/.*\S.*/)]],
        descrizioneMod: [this.gruppo.descrizione, [Validators.required, Validators.pattern(/.*\S.*/)]]
      });
    }, (error) => {
      // Gestione dell'errore
    });
  }

  eliminaUtenteGruppo(id: number, i) { // elimina utente da gruppo
    const user = this.users.find(x => x.id === id);
    this.confirmationService.confirm({
      message: 'Sicuro di voler rimuovere l\'utente dal gruppo?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.deleteUtenteGruppo(id, this.idGruppo).subscribe((res) => {
          this.users = this.users.filter(x => x.id !== id);
          this.allListautenti = this.allListautenti.filter(x => x.id !== id);
          this.alertService.success(res.status.description);
          this.confirmationService.close();
        }, (error) => {
          // Gestione dell'errore
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  tornaGesGruppi(vai) { // riporta gestione gruppi
    this.vaiGestioneGruppo.emit(vai);
  }

  /*aggiungi(vai) { // compare cmp per aggiungere utente
    this.userService.notGetAllGruppo(this.gruppo.id).subscribe((res) => {
      this.listautentiGruppo = res.data;
      this.allUserGruppo = this.listautentiGruppo;
      this.modifica = false;
      this.add = true;
    });
  }*/
  aggiungi(vai) { // compare cmp per aggiungere utente
    this.add = true;
    this.loadDataList(this.gruppo.id, this.pagina, this.size, this.filters);
  }

  loadDataList(id, page?, size?, filters?){
    this.refreshView = false;
    this.userService.notGetAllGruppoDoctor(id, page, size, filters).subscribe((res) => {
      this.listautentiGruppo = res.data.content;
      this.allUserGruppo = this.listautentiGruppo;
      this.modifica = false;
      this.add = true;
      this.numeroMaxPagine = Number(res.data.totalPages);
      this.maxItems = res.data.totalElements;
      this.items = res.data.numberOfElements;
      this.isClickable = true;
      this.refreshView = true;
    });
  }

  openFilters(): void {
    this.filtersVisible = !this.filtersVisible;
  }

  deleteFilters(): void {
    this.removeFilters = true;
    this.filters = {};
    this.pagina = 0;
    this.loadDataList(this.gruppo.id, this.pagina, this.size, this.filters);
  }

  submitFilters(): void {
    this.pagina = 0;
    this.formFilterComponent.onSubmitFilters();
    this.filtersVisible = false;
  }

  updateList(filtersObj: any): void {
    this.removeFilters = false;
    Object.keys(filtersObj).reduce((acc, k) => {
      if (!filtersObj[k]) {
        delete acc[k];
      }
      return acc;
    }, filtersObj);

    this.filters = { ...filtersObj };
    this.loadDataList(this.gruppo.id, this.pagina, this.size, this.filters);
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    this.size = quantità;

    /*if(attivo)
    {*/
      this.subscriptionallUser = this.userService.getDoctors(pagina, quantità).subscribe({
      next: (res) => {

      if (attivo == true) {
        if (res?.data?.content?.length > 0) {
          this.listautentiGruppo = res.data.content;
        } else {
          this.listautentiGruppo = []
        }
        this.allUserGruppo = this.listautentiGruppo;
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements

        this.carica = true;
        this.caricato = true;
        this.isClickable = true;
        this.isClickableCancellati = true;
        this.paginatorCancellati = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.isSelectingValueCancellati = false;
        this.refreshView = true;
        this.showTable = true;
      }
    },
    error: (err) => {
      console.log(err)
    }})
  }
  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  salvaGruppo() {
    this.submitted = true;
    if (this.gruppoModForm.invalid) {
      return;
    }

    let nome = this.gruppoModForm.value.nomeMod;
    let descrizione = this.gruppoModForm.value.descrizioneMod;

    this.userService.salvaGruppoService(this.gruppo.id, nome, descrizione).subscribe((res) => {
      this.alertService.success(res.status.description);
      this.userService.getAllGruppo(this.gruppo.id).subscribe((res) => {
        let temp = res.data;
        this.users = temp;
        this.allListautenti = this.users;

        this.idGruppo = this.gruppo.id;
        this.gruppo.nomeGruppo = nome;
        this.gruppo.descrizione = descrizione;

        this.gruppoModForm = this.formBuilder.group({
          nomeMod: [nome, Validators.required],
          descrizioneMod: [descrizione, Validators.required]
        });
      }, (error) => {
        // Gestione dell'errore
      });
    }, (error) => {
      // Gestione dell'errore
    });
  }

  searchInUser() {
    let valore: string = this.searchForm.value.search;
    this.users = this.allListautenti;

    if (valore) {
      let users = [];
      this.users.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;

        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email_privata.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!users.find(x => x === user)) {
            users.push(user);
          }
        }
      });
      this.users = users;
    } else if (!valore) {
      this.users = this.allListautenti;
    }
  }

  searchInUser2() {
    let valore: string = this.searchForm2.value.search2;
    this.listautentiGruppo = this.allUserGruppo;

    if (valore) {
      let users = [];
      this.listautentiGruppo.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;

        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase())) {
            if (!users.find(x => x === user)) {
              users.push(user);
            }
          }
        });

        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email_privata.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!users.find(x => x === user)) {
            users.push(user);
          }
        }
      });
      this.listautentiGruppo = users;
    } else if (!valore) {
      this.listautentiGruppo = this.allUserGruppo;
    }
  }

  // METODI SECONDA PARTE AGGIUNTA UTENTE

  avanti() {
    this.alertService.clear();
    if (this.selectedUsers3 && this.selectedUsers3.length > 0) {
      this.utentiSelezionati = this.selectedUsers3;
      this.idUtentiSelezionati = [];

      this.selectedUsers3.forEach(user => {
        let id: number = user.id;
        this.idUtentiSelezionati.push(id);
      });

      this.userService.associaUtentiGruppo(this.idGruppo, this.idUtentiSelezionati).subscribe((res) => {
        this.alertService.success(res.status.description);
        this.selectedUsers3 = [];
        this.idUtentiSelezionati = [];
        this.modificaGruppo(this.gruppo.id, this.gruppo.nomeGruppo, this.gruppo.descrizione);
        this.modifica = true;
        this.add = false;
      });
    } else {
      this.alertService.error("Seleziona uno o più utenti");
    }
  }

  indietro2() { // fa ricomparire modifica
    this.add = false;
    this.modifica = true;
  }

  responsiveWidth() {}
}
