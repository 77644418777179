
/**
 * Configurazione dello smartbed
 * @param type tipo di configurazione, per ora 2: "smartbedConfiguration", "ketronConfiguration"
 * @param userSex falso donna
 * @param userAge età del paziente
 * @param userWeight peso del paziente
 * @param userHeight altezza del paziente
 * @param sleepTime 
 */
export interface ConfigurazioneSmartbed {
    /**
     * tipo di configurazione, per ora sono le seguenti:
     * 
     *  "type": "smartbedConfiguration",
     * 
     *  "type":"ketronConfiguration",
     */
    type: string;
    /**
     * falso è una donna
     */
    userSex:boolean;
    /**
     * eta del paziente
     */
    userAge:number;
    /**
     * peso del paziente
     */
    userWeight: number;
    /**
     * altezza del paziente
     */
    userHeight: number;
    /**
     * 
     */
    sleepTime?: boolean;

  addType(type:string):ConfigurazioneSmartbed
  addUserSex(userSex:boolean):ConfigurazioneSmartbed
  addUserAge(userAge:number):ConfigurazioneSmartbed
  addUserWeight(userWeight:number):ConfigurazioneSmartbed
  addUserHeight(userHeight:number):ConfigurazioneSmartbed
  addSleepTime(sleepTimer:any):ConfigurazioneSmartbed
  
  }


  export class ConfigurazioneSmartbedClass implements ConfigurazioneSmartbed{
    type: string;
    userSex: boolean;
    userAge: number;
    userWeight: number;
    userHeight: number;
    sleepTime?: boolean;


    constructor(type: string, userSex: boolean, userAge: number, userWeight: number,
      userHeight: number, sleepTime: boolean) {
      this.type = type
      this.userSex = userSex
      this.userAge = userAge
      this.userWeight = userWeight
      this.userHeight = userHeight
      this.sleepTime = sleepTime
    }

    addType(type: string): ConfigurazioneSmartbed {
      this.type=type
      return this
    }
    addUserSex(userSex: boolean): ConfigurazioneSmartbed {
      this.userSex=userSex
      return this
    }
    addUserAge(userAge: number): ConfigurazioneSmartbed {
      this.userAge=userAge
      return this
    }
    addUserWeight(userWeight: number): ConfigurazioneSmartbed {
      this.userWeight=userWeight
      return this
    }
    addUserHeight(userHeight: number): ConfigurazioneSmartbed {
      this.userHeight=userHeight
      return this
    }
    addSleepTime(sleepTime: any): ConfigurazioneSmartbed {
      this.sleepTime=sleepTime
      return this
    }

  }