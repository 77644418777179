import {Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormFilterComponent} from './form-filter/form-filter.component';
import {AlertService, UsersService} from '../../../SERVICES';
import { Subscription } from 'rxjs';
import { User } from 'src/app/MODELS/USER/user';
import { FormGroup } from '@angular/forms';
import EventEmitter from 'wolfy87-eventemitter';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-gestione-medici',
  templateUrl: './gestione-medici.component.html',
  styleUrls: ['./gestione-medici.component.scss']
})
export class GestioneMediciComponent implements OnInit {

  @Input() smartView = false;
  @Input() selectedId = '-1';
  @Output() emitUtente = new EventEmitter();
  listaMedici: User[];
  allListaMedici: User[];
  filters: any = {};
  size = 10;
  filtersVisible = false;
  @ViewChild(FormFilterComponent) formFilterComponent: FormFilterComponent;
  removeFilters = false;
  items;
  maxItems;
  numeroMaxPagine: number;
  isClickable = true;
  pagina;
  valoreRicerca;
  pagination = 0;
  selectedValue;
  refreshView = true;

  subscriptionListaMedici:Subscription
  isSelectingValue: boolean;
  paginator: boolean;
  isSearchingOn: boolean;
  carica: boolean;
  caricato: boolean;
  isClickableCancellati: boolean;
  paginatorCancellati: boolean;
  isSelectingValueCancellati: boolean;
  showTable: boolean;
  mediciCancellati: User[];
  allMediciCancellati:User[];
  selectedValueCancellati;
  paginaCancellati;
  idRipristina;
  searchform:FormGroup;

  numeroMaxPagineCancellate: number;
  maxItemsCancellati;
  itemsCancellati;
  cancellaMedico=false;
  loading=false;
  gestione = true;
  dettagli = false;
  infoMedico;
  infoStorico = false;
  idUtente: string;

  constructor(
    private service: UsersService,
    private alertService: AlertService,
    private confirmationService: ConfirmationService
  ) {
    this.pagina = 0;
    this.selectedValue = 10;
  }

  ngOnInit(): void {
    this.getDoctors(this.pagina, this.size, this.filters);
  }

  openFilters() {
    this.filtersVisible = !this.filtersVisible;
  }
  deleteFilters() {
    this.removeFilters = true;
    this.filters = {};
    this.pagina = 0;
    this.getDoctors(this.pagina, this.size, this.filters);
  }
  submitFilters() {
    this.pagina = 0;
    this.formFilterComponent.onSubmitFilters();
    this.filtersVisible = false;
    
  }

  getDoctors(page?, size?, filters?){
    this.refreshView = false;
    this.service.getDoctors(page, size, filters).subscribe(
      (response: any) => {
        this.listaMedici = response.data.content;
        this.numeroMaxPagine = Number(response.data.totalPages);
        this.maxItems = response.data.totalElements;
        this.items = response.data.numberOfElements;
        this.isClickable = true;
        this.refreshView = true;
      },
      (error) => {
        console.error('Errore durante il recupero dei dati:', error);
      }
    );
  }

  updateList(filtersObj: any) {
    this.removeFilters = false;
    Object.keys(filtersObj).reduce((acc, k) => {
      if (!filtersObj[k]) {
        delete acc[k];
      }
      return acc;
    }, filtersObj);

    this.filters = { ...filtersObj };
    this.getDoctors(this.pagina, this.size, this.filters);
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    this.size = quantità;
    /*if(attivo)
    {*/
      this.subscriptionListaMedici = this.service.getDoctors(pagina, quantità).subscribe({
      next: (res) => {

      if (attivo == true) {
        if (res?.data?.content?.length > 0) {
          this.listaMedici = res.data.content;
        } else {
          this.listaMedici = []
        }
        this.allListaMedici = this.listaMedici;
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements

        this.carica = true;
        this.caricato = true;
        this.isClickable = true;
        this.isClickableCancellati = true;
        this.paginatorCancellati = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.isSelectingValueCancellati = false;
        this.refreshView = true;
        this.showTable = true;
      }
    },
    error: (err) => {
      console.log(err)
    }})
    /*}
      else
      {
        //cestino
        this.service.getAllEliminati(quantità, pagina, ricerca).subscribe((res)=>
        {
         
          
         if (res.data?.length > 0) {
           this.mediciCancellati = res.data.content;
         } else {
           this.mediciCancellati = []
         }
         
         this.allMediciCancellati = this.mediciCancellati
         this.numeroMaxPagineCancellate = Number(res.data.totalPages)
         this.maxItemsCancellati = res.data.totalElements;
         this.itemsCancellati = res.data.numberOfElements;

         this.carica = true;
         this.caricato = true;
         this.isClickable = true;
         this.isClickableCancellati = true;
         this.paginatorCancellati = true;
         this.paginator = true;
         this.isSearchingOn = false;
         this.isSelectingValue = false;
         this.isSelectingValueCancellati = false;
         this.refreshView = true;
         this.showTable = true;
        }
        )
      }*/
    }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  selectUser(utente_id) {
    if (this.smartView) {
      this.selectedId = utente_id;
      this.emitUtente.emit(utente_id);
    }
  }

  cambiaStato(utente_id) {
    const user = this.listaMedici.find(x => x.utente_id === utente_id);
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.abilitaUser(utente_id).subscribe((res) => {
          if (user.attivo === 0) {
            user.attivo = 1;
          }
          else if (user.attivo === 1) {
            user.attivo = 0;
          }
          this.alertService.clear;
          this.alertService.success(res.status.description);
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    })
  }

  inviaEmail(email) {

    this.confirmationService.confirm({
      message: 'Inviare di nuovo l\'email all\'utente?',

      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.service.inviaEmail(email).subscribe((res) => {
          this.alertService.success(res.status.description);
        })
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  //METODO PER PASSARE DALLA GESTIONE MEDICI AL VISUALIZZA INFO DI UN MEDICO
  visualizza(id: string) {
    this.infoMedico = id;
    this.gestione = false;
    this.dettagli = true;
  }

  visualizzaStorico(utente_id) {
    this.infoMedico = utente_id;
    this.gestione = !this.gestione;
    this.infoStorico = !this.infoStorico;
  }

  indietroDaStorico(vai) {
    this.gestione = !this.gestione;
    this.infoStorico = !this.infoStorico;
  }

  indietroDainfo(vai) {
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }

}
