export class Cliente {
  id?: string;
  nome?: string;
  cognome?: string;
  codiceFiscale?: string;
  eta?: string;
  sesso?: string;
  citta?: string;
  occupazione?: string;
  livelloDiIstruzione?: string;
  situazioneEconomica?: string;
  diagnosi?: string;
  annoDiPresaInCaricoDalServizio?: string;
  infoAltraAssociazione?: string;
  altraAssociazione?: string;
  infoCliente?: string;
  altroServizio?: string;
  serviziDiAppartenenza?: string;
  tipologiaAttivita?: any[];
  tipologiaLaboratoriInterventi?: any[];
  partenariati?: string;
  inviante?: string;
  note?: string;
  analisiBisogni?: string;
  analisiRisorse?: string;
  obiettiviGenerali?: any[];
  obiettiviSpecifici?: ObiettiviSpecifici[];
  metodologiaSpecifica?: string;
  obiettiviRaggiunti?: string;
  descrizioneObiettivi?: string;
  selected?: boolean;
  idCliente?: string;
}

export class ObiettiviSpecifici {
  title: string;
  azioniRealizzazione: string[];
  constructor(title: string, azioniRealizzazione: string[]) {
    this.title = title;
    this.azioniRealizzazione = azioniRealizzazione;
  }
}
