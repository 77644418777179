<div class="container-fluid">
    <div class="row" style="margin-left: 0px;">
        <h4>Nome</h4> {{aula.nomeAula}}


        <h4>Descrizione</h4> {{aula.descrizioneAula}}


         <span *ngIf="aula.attestato"><h4>Attestato</h4>{{aula.attestato.titolo}}</span>

        <span *ngIf="aula.start"><h4>Attiva dal <span style="font-size: small;">{{aula.start | date: 'dd/MM/yyyy'}}</span> al <span style="font-size: small;">{{aula.end | date: 'dd/MM/yyyy'}}</span> </h4> </span>
        <div *ngFor="let dettaglio of aula.dettagli"><h4>{{dettaglio.attributo}}</h4> {{dettaglio.valore}}</div>
    </div>
    <div class="row">
    <div class="col-xs-6">

        <br>
        <h4 class="card-title">
            Elenco Docenti
         </h4>
         <p *ngIf="aula.listaDocenti.length < 1">Nessun docente presente</p>

                             <div *ngIf="aula.listaDocenti.length > 0" class="material-datatables" style="border: 1px solid lightgray;
                             border-radius: 5px;">

                                 <div class="scrollable">


                                     <table id="datatables" class="table table-striped table-no-bordered table-hover"
                                         cellspacing="0" width="100%" style="width:100%">


                                         <tbody *ngIf="!visualizzaInfoDocente">
                                             <tr  *ngFor="let docente of aula.listaDocenti;let i =index;">

                                                 <td style="font-size: medium; " formcontrolname="nome">
                                                    {{docente.nome}} {{docente.cognome}}
                                                 </td>
                                                 <td style="text-align: right;">
                                                    <a (click)="vaiInfoDocente(docente)" rel="tooltip" title="Info">Visualizza info</a>

                                                 </td>

                                             </tr>

                                         </tbody>

                                         <tbody  *ngIf="visualizzaInfoDocente">

                                            <button (click)="vaiInfoDocente(null)" id="back-button" class="btn btn-round btn-sm btn-just-icon" style="margin-top: 3px; margin-bottom: 3px; margin-left: 3px; padding: 5px;color: rgb(54, 54, 54); box-shadow:none!important;">
                                                <i class="material-icons notranslate">arrow_back</i>
                                            </button>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Nome</h6>
                                                </td>
                                                <td  class="col-4">{{docenteVisualizzato?.nome || '---'}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Cognome</h6>
                                                </td>
                                                <td class="col-4">{{docenteVisualizzato?.cognome || '---'}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Email</h6>
                                                </td>
                                                <td class="col-4">{{docenteVisualizzato?.email || '---'}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Telefono</h6>
                                                </td>
                                                <td class="col-4">{{docenteVisualizzato?.telefono || '---'}}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Dettagli Aggiuntivi</h6>
                                                </td>
                                                <td class="col-4">
                                                    <span *ngFor="let dettaglio of docenteVisualizzato?.dettaglio; let i=index">
                                                        <span *ngIf="i <= docenteVisualizzato?.dettaglio.length-2">{{dettaglio?.dettaglioAggiuntivo + ', '}}</span>
                                                            <span *ngIf=" i === docenteVisualizzato?.dettaglio.length-1">{{dettaglio?.dettaglioAggiuntivo}}</span>
                                                            </span>
                                                       </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Profilo</h6>
                                                </td>
                                                <td class="col-4">{{docenteVisualizzato?.ruolo?.nomeRuolo || '---'}}

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6>Gruppo</h6>
                                                </td>
                                                <td class="col-4">
                                                    <span *ngIf="docenteVisualizzato?.gruppos && docenteVisualizzato.gruppos.length > 0; else noGroups">
                                                        <span *ngFor="let gruppo of docenteVisualizzato?.gruppos; let i = index">
                                                            <span *ngIf="i < docenteVisualizzato?.gruppos.length - 1">{{ gruppo?.nomeGruppo + ', ' }}</span>
                                                            <span *ngIf="i === docenteVisualizzato?.gruppos.length - 1">{{ gruppo?.nomeGruppo }}</span>
                                                        </span>
                                                    </span>
                                                    <ng-template #noGroups>
                                                        <span>---</span>
                                                    </ng-template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 170px;">
                                                    <h6 >Ruolo</h6>
                                                </td>
                                                <td class="col-4">{{docenteVisualizzato?.tag?.nome || '---'}}

                                                </td>
                                            </tr>
                                        </tbody>

                                     </table>
                                    <table *ngIf="alfaintes && visualizzaInfoDocente" class="table table-striped table-no-bordered table-hover"
                                    cellspacing="0" width="100%" style="width:100%">
                                     <thead>
                                         <tr>
                                             <th style="width: 20%;"></th>
                                             <th style="width: 80%;"></th>
                                         </tr>
                                     </thead>
                             <tbody >

                                     <!--<tr>
                                         <td  style="width: 170px;">
                                             <h6 >Struttura</h6>
                                         </td>
                                         <td class="col-4">{{afiDocente?.struttura?.struttura}}

                                         </td>
                                     </tr> -->
                                     

                                 </tbody>
                             </table>
                                 </div>


                             </div>
                         </div>

                        <div class="col-xs-6">
                            <br>
<h4 class="card-title" >
   Elenco Studenti
</h4>
<p *ngIf="aula.listaUtenti.length < 1"> Nessuno studente presente</p>
                    <div *ngIf="aula.listaUtenti.length > 0" class="material-datatables" style="border: 1px solid lightgray;
                    border-radius: 5px;">

                        <div class="scrollable">
                            <p-confirmDialog [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                            </p-confirmDialog>

                            <table id="datatables" class="table table-striped table-no-bordered table-hover"
                                cellspacing="0" width="100%" style="width:100%">


                                <tbody *ngIf="!visualizzaInfoStudente" >
                                    <tr *ngFor="let studente of aula.listaUtenti;let i =index;">

                                        <td  class="col-5" style="font-size: medium; " formcontrolname="nome">
                                            {{studente.nome}} {{studente.cognome}}
                                        </td>
                                        <td  class="col-5" style="text-align: right;">
                                            <a (click)="vaiInfoStudente(studente)" rel="tooltip" title="Info">Visualizza info</a>

                                        </td>

                                    </tr>

                                </tbody>

                                <tbody  *ngIf="visualizzaInfoStudente">


                                    <button (click)="vaiInfoStudente(null)" id="back-button" class="btn btn-round btn-sm btn-just-icon" style="margin-top: 3px; margin-bottom: 3px; margin-left: 3px; padding: 5px;color: rgb(54, 54, 54); box-shadow:none!important;">
                                        <i class="material-icons notranslate">arrow_back</i>
                                    </button>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6 >Nome</h6>
                                        </td>
                                        <td  class="col-4">{{studenteVisualizzato?.nome}}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6 >Cognome</h6>
                                        </td>
                                        <td class="col-4">{{studenteVisualizzato?.cognome}}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6 >Email</h6>
                                        </td>
                                        <td class="col-4">{{studenteVisualizzato?.email}}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6 >Telefono</h6>
                                        </td>
                                        <td class="col-4">{{studenteVisualizzato?.telefono}}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6 >Dettagli Aggiuntivi</h6>
                                        </td>
                                        <td class="col-4">
                                            <span *ngFor="let dettaglio of studenteVisualizzato?.dettaglio; let i=index">
                                                <span *ngIf="i <= studenteVisualizzato?.dettaglio.length-2">{{dettaglio?.dettaglioAggiuntivo + ', '}}</span>
                                                    <span *ngIf=" i === studenteVisualizzato?.dettaglio.length-1">{{dettaglio?.dettaglioAggiuntivo}}</span>
                                                    </span>
                                               </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6 >Profilo</h6>
                                        </td>
                                        <td class="col-4">{{studenteVisualizzato?.ruolo?.nomeRuolo}}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 170px;">
                                            <h6>Gruppo</h6>
                                        </td>
                                        <td class="col-4">
                                            <span *ngIf="studenteVisualizzato?.gruppos && studenteVisualizzato.gruppos.length > 0; else noGroups">
                                                <span *ngFor="let gruppo of studenteVisualizzato?.gruppos; let i = index">
                                                    <span *ngIf="i < studenteVisualizzato?.gruppos.length - 1">{{ gruppo?.nomeGruppo + ', ' }}</span>
                                                    <span *ngIf="i === studenteVisualizzato?.gruppos.length - 1">{{ gruppo?.nomeGruppo }}</span>
                                                </span>
                                            </span>
                                            <ng-template #noGroups>
                                                <span>---</span>
                                            </ng-template>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                          <!--  <table *ngIf="alfaintes && visualizzaInfoStudente"  class="table table-striped table-no-bordered table-hover"
                            cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width: 20%;"></th>
                                        <th style="width: 80%;"></th>
                                    </tr>
                                </thead>
                        <tbody >

                                <tr>
                                    <td  style="width: 170px;">
                                        <h6 >Informatore Scientifico</h6>
                                    </td>
                                    <td class="col-4">{{afiStudente?.isf}}

                                    </td>
                                </tr>
                                <tr>
                                    <td  style="width: 170px;">
                                        <h6 >Struttura</h6>
                                    </td>
                                    <td class="col-4">{{afiStudente?.struttura?.struttura}}

                                    </td>
                                </tr>
                                
                            </tbody>
                        </table> -->

                        </div>



                    </div>

                </div>

            </div>
            <div class="row top20px">
                <div class="col-sm-2" >
                    <h6 >Copertina</h6>
            </div>
                <div class="col-sm-10">
                      <div class="quadratoImmagine" >
                 <img [src]="aula.copertina">
                 </div>
                </div>
            </div>

            <div class="row top20px" *ngIf="corsi && listaCorsi.length > 0; let i = index ">

                <div class="col-sm-2" >
                <p class="textToh6" >Corsi associati:</p>
        </div>
            <div class="col-sm-10 flex">
                <p *ngFor="let corsi of listaCorsi;let i =index;">{{corsi.corso.nomeCorso}}<span *ngIf="i !== listaCorsi.length - 1">,&nbsp;</span> </p>
             </div>



    </div>
  <div>
    <button type="button" class="btn" style="background-color: #35af9a" (click)="copyLink()" value="link">Condividi Aula</button>
  </div>
  <div><span style="margin-left: 2px;"><small>Verrà copiato il link di riferimento all'aula</small></span></div>
<button (click)="goBack()" class="btn btn-secondary" style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Indietro</button>
</div>
