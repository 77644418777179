export class Studente {
    id?: string;
    nome?: string;
    cognome?: string;
    entrata?: any;
    uscita?: any;
    durata?: any;
    maniAlzate?:any;
    inattivita?:any;
    Informatore?:any;
    Struttura?:any;
}