import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Posizione } from 'src/app/MODELS/ASSISTENZA/posizione';
import { Sessione } from 'src/app/MODELS/ASSISTENZA/sessione';
import { Studente } from 'src/app/MODELS/CLASSROOM/studente';
import { environment } from 'src/environments/environment';
import { UsersService } from '..';
import { ExportExcelService } from './excel.service';
import { FileService } from './file.service';



@Injectable({ providedIn: 'root' })
export class ReportService {


    constructor(
        private userService: UsersService,
        private fileService: FileService,
        private excelService: ExportExcelService,
        private datePipe: DatePipe
    ) {

    }

    ottieniReportAssistenza(messaggio, pezziMsg) {
        this.userService.getAllReport().subscribe((res) => {
            let users = res.data.listaUtentiAttivi;
            res.data.listaUtentiCancellati.forEach(element => {
                users.push(element)
            });
            let allUsers = users;
            this.formattaExcelAssistenza(messaggio, pezziMsg, allUsers)
        })
    }

    formattaExcelAssistenza(messaggio, pezziMsg, allUsers) {
        var listaId = pezziMsg[2].split(",");
        var startDate = pezziMsg[3].split("/")
        var endDate = pezziMsg[4].split("/")
        let usersVideo;

        this.fileService.inviaDatiReport(listaId, startDate[0], startDate[1], startDate[2], endDate[0], endDate[1], endDate[2]).subscribe((res: any) => {
            usersVideo = res.data;
            let usersVid: Sessione[] = new Array;
            usersVideo.forEach(user => {
                let sessione: Sessione = new Sessione;
                allUsers.forEach(utente => {
                    if (utente.id === user.sessione.idCreatore) {
                        sessione.operatore = utente.nome + ' ' + utente.cognome
                    }
                })

                sessione.nome = user.utente.nome + ' ' + user.utente.cognome;
                sessione.dettaglio = user.utente.dettaglio[0]?.dettaglioAggiuntivo;
                sessione.gruppo = '';
                user.utente.gruppos.forEach(gruppo => {
                    sessione.gruppo = sessione.gruppo + ' ' + gruppo.nomeGruppo;
                });
                sessione.latitudine = user.latitudine;
                sessione.longitudine = user.longitudine;
                sessione.motivo_chiamata = user.motivoChiamata;
                sessione.durata = 'da ' + this.datePipe.transform(user.createdAt, 'dd/MM/yy HH:mm:ss') + ' a ' + this.datePipe.transform(user.deletedAt, 'dd/MM/yy HH:mm:ss');
                usersVid.push(sessione);
            });
            if (usersVid.length >= 1) {
                this.exportToExcel(usersVid, 'Report Assistenza');
                messaggio.messaggio = 'Il report è stato scaricato' + pezziMsg[5];
            }
            else {
                messaggio.messaggio = 'Nessun report presente per gli utenti e/o le date selezionate'
            }
        })
    }

    ottieniReportGeolocalizzazione(messaggio, pezziMsg) {
        var listaId = pezziMsg[2].split(",");
        var startDate = pezziMsg[3].split("/")
        var endDate = pezziMsg[4].split("/")

        this.fileService.inviaDatiReport2(listaId, startDate[0], startDate[1], startDate[2], endDate[0], endDate[1], endDate[2]).subscribe((res: any) => {
            let usersGeo = res.data;


            let usersLoc: Posizione[] = new Array;

            usersGeo.forEach(user => {
                let posizione: Posizione = new Posizione;

                posizione.data = this.datePipe.transform(user.createdAt, 'dd/MM/yy HH:mm:ss');
                posizione.latitudine = user.latitudine;
                posizione.longitudine = user.longitudine;
                posizione.comune = user.capComune;
                posizione.indirizzo = user.indirizzo;
                posizione.nome = user.utente.nome + ' ' + user.utente.cognome;
                posizione.gruppo = '';
                user.utente.gruppos.forEach(gruppo => {
                    posizione.gruppo = posizione.gruppo + gruppo.nomeGruppo;
                });

                posizione.dettaglio = user.utente.dettaglio[0]?.dettaglioAggiuntivo;

                usersLoc.push(posizione);
            });

            if (usersLoc.length >= 1) {
                this.exportToExcel(usersLoc, 'Report Geolocalizzazione');
                messaggio.messaggio = "Il report è stato scaricato" + pezziMsg[5];
            }
            else {
                messaggio.messaggio = 'Nessun report presente per gli utenti e/o le date selezionate';
            }
        })
    }

    ottieniReportAula(messaggio, pezziMsg, isAlfa?) {
        var listaIdAule = []
        var startDate = pezziMsg[3].split("/")
        var endDate = pezziMsg[4].split("/")

        listaIdAule.push(pezziMsg[2])

        this.fileService.inviaDatiReportAula(listaIdAule, startDate[0], startDate[1], startDate[2], endDate[0], endDate[1], endDate[2]).subscribe((res: any) => {
            let aula = res.data;
            aula.forEach(aula => {
                let title;
                let usersAula = new Array;

                aula.forEach(studente => {
                    title = studente.classroom.nomeAula;
                    let utente: Studente = new Studente;
                    utente.nome = studente.studente.nome;
                    utente.cognome = studente.studente.cognome;
                    utente.entrata = this.datePipe.transform(studente.entrata, 'dd/MM/yy HH:mm:ss');
                    utente.uscita = this.datePipe.transform(studente.uscita, 'dd/MM/yy HH:mm:ss');
                    utente.durata = studente.ore;
                    utente.maniAlzate = studente.maniAlzate;
                    utente.inattivita = studente.inattivita;
                    if(isAlfa){
                    utente.Informatore = studente.studente.afi?.isf;
                    utente.Struttura = studente.studente.afi?.struttura;
                    }
                    usersAula.push(utente)
                });
                if (usersAula.length >= 1) {
                    this.exportToExcel(usersAula, "Report " + environment.NOMEAULA + ": " + title);
                    messaggio.messaggio = "Il report è stato scaricato" + pezziMsg[5];
                } else {
                    messaggio.messaggio = 'Nessun report presente per le date selezionate'
                }
            })

        })
    }

    ottieniReportIot(messaggio, pezziMsg) {
        var dispositiviSelezionati = pezziMsg[2].split(',');
        var startDate = pezziMsg[4].split("/")
        var endDate = pezziMsg[5].split("/")
        let idDispositiviSelezionati = new Array;

        dispositiviSelezionati.forEach(dispositivo => {
            let id;
            id = dispositivo;
            idDispositiviSelezionati.push(id);
        });
        var telemetrieSelezionate = pezziMsg[3].split(',');
        var stringheTelemetrie = []
        telemetrieSelezionate.forEach(telemetria => {
            stringheTelemetrie.push(telemetria)
        })
        let body: any = {};
        body.id = idDispositiviSelezionati;
        body.telemetryName = stringheTelemetrie;
        this.fileService.inviaDatiReportDispositivo(body, startDate[0], startDate[1], startDate[2], endDate[0], endDate[1], endDate[2]).subscribe((res: any) => {
            let reportDispositivo = res;
            let excelscaricato = false;
            reportDispositivo.data.forEach(dispositivo => {
                let title;
                let listaDispositivi: any = {};
                if (dispositivo.device.utente) {
                    title = dispositivo.device.deviceName + ' di ' + dispositivo.device.utente.nome + ' ' + dispositivo.device.utente.cognome;
                }
                else {
                    title = dispositivo.device.deviceName;
                }
                if (dispositivo.telemetry.HeartRate) {
                    listaDispositivi.HeartRate = []
                    dispositivo.telemetry.HeartRate.forEach(element => {
                        let dato: any = {};
                        dato.Data = this.datePipe.transform(element.timestamp, 'dd/MM/yy HH:mm:ss')
                        dato.Valore = element.telemetryData
                        listaDispositivi.HeartRate.push(dato)
                    });
                }
                if (dispositivo.telemetry.BodyTemperature) {
                    listaDispositivi.BodyTemperature = []
                    dispositivo.telemetry.BodyTemperature.forEach(element => {
                        let dato: any = {};
                        dato.Data = this.datePipe.transform(element.timestamp, 'dd/MM/yy HH:mm:ss')
                        dato.Valore = element.telemetryData
                        listaDispositivi.BodyTemperature.push(dato)
                    });
                }
                if (dispositivo.telemetry.Power) {
                    listaDispositivi.Power = []
                    dispositivo.telemetry.Power.forEach(element => {
                        let dato: any = {};
                        dato.Data = this.datePipe.transform(element.timestamp, 'dd/MM/yy HH:mm:ss')
                        dato.Valore = element.telemetryData + '%'
                        listaDispositivi.Power.push(dato)
                    });
                }
                if (dispositivo.telemetry.StepCounter) {
                    listaDispositivi.StepCounter = []
                    dispositivo.telemetry.StepCounter.forEach(element => {
                        let dato: any = {};
                        dato.Data = this.datePipe.transform(element.timestamp, 'dd/MM/yy HH:mm:ss')
                        dato.Valore = element.telemetryData
                        listaDispositivi.StepCounter.push(dato)
                    });
                }
                if (dispositivo.telemetry.WearState) {
                    listaDispositivi.WearState = []
                    dispositivo.telemetry.WearState.forEach(element => {
                        let dato: any = {};
                        dato.Data = this.datePipe.transform(element.timestamp, 'dd/MM/yy HH:mm:ss')
                        if (element.telemetryData == '0') {
                            dato.Valore = 'Non indossato'
                        }
                        if (element.telemetryData == '1') {
                            dato.Valore = 'Indossato'
                        }
                        if (element.telemetryData == '2') {
                            dato.Valore = 'Spento'
                        }
                        if (element.telemetryData == '3') {
                            dato.Valore = 'Offline'
                        }
                        listaDispositivi.WearState.push(dato)
                    });
                }
                if (dispositivo.telemetry.SBP) {
                    listaDispositivi.Pressione = []
                    dispositivo.telemetry.SBP.forEach(element => {
                        dispositivo.telemetry.DBP.forEach(element2 => {
                            if (element.timestamp === element2.timestamp) {
                                let dato: any = {};
                                dato.Data = this.datePipe.transform(element.timestamp, 'dd/MM/yy HH:mm:ss')
                                dato.Valore = 'MIN:' + element2.telemetryData + '/MAX:' + element.telemetryData
                                listaDispositivi.Pressione.push(dato)
                            }
                        });
                    });
                }
                if (JSON.stringify(listaDispositivi) !== '{}') {
                    excelscaricato = true;
                    this.exportToExcelDispositivo(listaDispositivi, title, stringheTelemetrie);
                    messaggio.messaggio = 'Report scaricato correttamente' + pezziMsg[6]
                    listaDispositivi = new Array;
                }
            });
            if (!excelscaricato) {
                messaggio.messaggio = 'Nessun report presente per i dispositivi e/o le date selezionate'
            }
        })
    }

    exportToExcel(dato, title) {
        let dataForExcel = [];
        dato.forEach((row: any) => {
            dataForExcel.push(Object.values(row))
        })

        let reportData = {
            title: title,
            data: dataForExcel,
            headers: Object.keys(dato[0])
        }

        this.excelService.exportExcel(reportData);
    }

    exportToExcelDispositivo(listaDispositivi, title, stringheTelemetrie) {
        let dataForExcel;
        dataForExcel = {}
        if (listaDispositivi.HeartRate) {
            dataForExcel.HeartRate = []
            listaDispositivi.HeartRate.forEach((row: any) => {
                dataForExcel.HeartRate.push(Object.values(row))
            })
        }
        if (listaDispositivi.BodyTemperature) {
            dataForExcel.BodyTemperature = []
            listaDispositivi.BodyTemperature.forEach((row: any) => {
                dataForExcel.BodyTemperature.push(Object.values(row))
            })
        }
        if (listaDispositivi.Power) {
            dataForExcel.Power = []
            listaDispositivi.Power.forEach((row: any) => {
                dataForExcel.Power.push(Object.values(row))
            })
        }
        if (listaDispositivi.WearState) {
            dataForExcel.WearState = []
            listaDispositivi.WearState.forEach((row: any) => {
                dataForExcel.WearState.push(Object.values(row))
            })
        }
        if (listaDispositivi.Pressione) {
            dataForExcel.Pressione = []
            listaDispositivi.Pressione.forEach((row: any) => {
                dataForExcel.Pressione.push(Object.values(row))
            })
        }
        if (listaDispositivi.StepCounter) {
            dataForExcel.StepCounter = []
            listaDispositivi.StepCounter.forEach((row: any) => {
                dataForExcel.StepCounter.push(Object.values(row))
            })
        }

        let reportData = {
            title: 'Report Dispositivo: ' + title,
            data: dataForExcel,
            headers: ['Data', 'Valore'],
        }
        this.excelService.exportExcel(reportData, stringheTelemetrie);

    }

}