import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import { AccordionModule } from 'primeng/accordion';

import { PaginatorComponent } from "./PAGINATOR/paginator/paginator.component";
import { ZoombarComponent } from './ZoomBar/zoombar/zoombar.component';
import { DoubleListComponent } from './double-list/double-list.component';

export { PaginatorComponent } from "./PAGINATOR/paginator/paginator.component";
export { ZoombarComponent } from './ZoomBar/zoombar/zoombar.component';
export { DoubleListComponent } from './double-list/double-list.component';
@NgModule({
    declarations: [
        PaginatorComponent,
        ZoombarComponent,
        DoubleListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        AccordionModule,
        MatFormFieldModule,
        MatInputModule
    ],
    exports: [
        PaginatorComponent,
        ZoombarComponent,
        DoubleListComponent
    ],
    providers: [],
})
export class ExternalComponentsModule { }
