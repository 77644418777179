import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ViewContainerRef, NgModule, ViewChildren, QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';
import { divIcon } from 'leaflet';
import { Telemetria } from 'src/app/MODELS/IOT/dispositivo';

@Component({
  selector: 'app-grafico-iot[nomeTelemetria]',
  templateUrl: './grafico-iot.component.html',
  styleUrls: ['./grafico-iot.component.scss'],
})

export class GraficoIotComponent implements OnInit {

  @Input() date:Date;
  @Input() dispositivo;
  @Input() mostraChartIot:boolean;
  @Input() nomeTelemetria:String;
  @Input() graficoWidthConst;
  graficoWidth;
  @Input() dataIot;
  @Input() dataIot2;
  @Input() options; //asse X
  @Input() options2; // asse Y
  @Input() hasLegend=false;

  @ViewChildren('chart') charts: QueryList<ElementRef>;


  constructor() { }
  ngOnInit(): void {
    this.graficoWidth = this.graficoWidthConst;
    if(!this.options2)
    {
      this.inizializzaOptions(); //Se non vengono specificate delle Options per l'asse delle Y, vengono utilizzate quelle di default per la telemetria data
    }
  }

  modificaZoomGrafico(percent)
  {
    console.table(this.dataIot);
    console.table(this.dataIot2);
    return(this.graficoWidthConst*percent)/100;
  }

  richiediDownload()
  {
    this.charts.forEach((element) => {
      this.downloadImg(element['chart'].canvas);
    });
  }

  downloadImg(canvas) {
    return new Promise((resolve, reject) => {
      console.log(canvas);
      let ctx = canvas.getContext('2d');
      // set the ctx to draw beneath your current content
      ctx.globalCompositeOperation = 'destination-over';
      // // set the fill color to white
      ctx.fillStyle = 'white';
      // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
      // these two points are the top left and the bottom right of the canvas
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      let url_base64jp = canvas.toDataURL('image/jpeg');
      var a = document.createElement('a'); //Create <a>
      a.href = url_base64jp; //Image Base64 Goes here
      a.download = "Grafico " + this.nomeTelemetria; //File name Here
      a.click(); //Downloaded file
      resolve(true);
      reject(false);
    });
  }


  
  inizializzaOptions()
  {
    let max, min, stepSize;
    switch(this.nomeTelemetria)
    {
      case "Battito Cardiaco":
        max = 210;
        min = 30;
        stepSize = 30;
        break;
      case "Pressione":
        max = 270;
        min = 30;
        stepSize = 30;
        break;
      
      case "Temperatura":
        max = 40;
        min = 32;
        stepSize = 2;
        break;

      case "Contapassi":
        max = 1000;
        min = 0;
        stepSize = 100;
        break;

      case "Ossigenazione del Sangue":
      case "Respirazione":
        max = 40;
        min = 0;
        stepSize = 5;
        break;
      default:
        max = 100;
        min = 0;
        stepSize = 10;
        break;
    }

    this.options2 = {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        fontColor:'transparent',
        yAxes: [{
          ticks: {
            max: max,
            min: min,
            stepSize: stepSize,
          },
          gridLines: {
            drawBorder: false,
            display: false
          }
        }],
        xAxes: [{
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
          },
          gridLines: {
            drawBorder: false,
            display: true
          }
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    }
  }


}
