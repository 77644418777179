import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ConfirmationService, PrimeNGConfig} from 'primeng/api';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/SERVICES';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';

@Component({
  selector: 'app-dispositivo-config',
  templateUrl: './dispositivo-config.component.html',
  styleUrls: ['./dispositivo-config.component.scss'],
  providers: [ConfirmationService]

})
export class DispositivoConfigComponent implements OnInit {
listadispositivi=[];
dispositivo;
dispositivoId;
cancellaDispositivo=false;
loading=false;
caricato = false;
dispositivi=true;
crea=false;
info=false;
edit=false;
configurazione=false;
searchform: FormGroup;


selectedValue=10;
isSelectingValue=false;
isSearchingOn=false;
isClickable=true;
pagina=0;
maxItems
items
numeroMaxPagine
paginator = true;
subscriptionSearch : Subscription
refreshView=true;


  constructor(
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private alertService: AlertService,
    private dispositiviService: IotService,
    private formBuilder: FormBuilder,
  ) {
    this.primengConfig.ripple = true;
    // this.dispositiviService.getListaDispositivi().subscribe((res) => {
    //   this.caricato = true;
    //   if(res.data){
    //     this.listadispositivi = res.data;
    //   }
    // }, (error) => {
    //   this.caricato = true;
    // });
    this.chiamataPaginata(this.pagina, this.selectedValue)
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
   }

  ngOnInit(): void {


  }

  elimina(dispositivoId){
    this.dispositivoId=dispositivoId
    this.cancellaDispositivo=true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare?'+'<br>'+' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaDispositivo=false;
        this.loading=false;
        this.confirmationService.close();
      }
    });
  }

accettaCancella(){
  this.loading=true;
  this.refreshView = false;
  this.dispositiviService.cancellaDevice(this.dispositivoId).subscribe((res)=>{
    // this.dispositiviService.getListaDispositivi().subscribe((res)=>{
      // this.chiamataPaginata(this.pagina, this.selectedValue)
      if (this.listadispositivi.length == 1) {
        if (this.pagina > 1) {
          this.pagina = this.pagina - 2;
          this.chiamataPaginata(this.pagina, this.selectedValue)
        } else if (this.pagina > 0) {
          this.pagina = this.pagina - 1;
          this.chiamataPaginata(this.pagina, this.selectedValue)
        } else {
          this.chiamataPaginata(this.pagina, this.selectedValue)
        }
      }else{
        if (this.pagina > 0) {
          this.pagina = this.pagina - 1
        }
        this.chiamataPaginata(this.pagina, this.selectedValue)
      }
      this.listadispositivi = res.data;
      this.cancellaDispositivo=false;
      this.loading=false;
      this.confirmationService.close();
      this.alertService.success(res.status.description)
      this.refreshView = true;
    // })
   })
}

  vaiCreazione(){
    this.crea=true;
    this.dispositivi=false;
  }

  vaiCreazioneConfigurazione(){
    this.configurazione=true;
    this.dispositivi=false;
  }

  vediLista(vai){
    this.chiamataPaginata(this.pagina-1, this.selectedValue)
    // this.dispositiviService.getListaDispositivi().subscribe((res)=>{
    //   this.listadispositivi = res.data;
      this.info=false;
      this.edit=false;
      this.crea=false;
      this.configurazione=false;
      this.dispositivi=true;
    // })
  }

  vaiModifica(id){
    this.dispositivo=this.listadispositivi.find(x => x.id === id)
    this.dispositivi=false;
    this.edit=true;
  }

  vaiInfo(id){
    this.dispositivo=this.listadispositivi.find(x => x.id === id)
    this.dispositivi=false;
    this.info=true;
  }

  chiamataPaginata(pagina, quantità,ricerca?,campo?,cardinalita?) {
    this.refreshView = false;
    this.pagina = pagina+1;
    this.subscriptionSearch =  this.dispositiviService.getListaDispositivi(pagina, quantità,ricerca,campo,cardinalita).subscribe((res) => {
      if( res.data.content.length > 0){
      this.listadispositivi = res.data.content;
    }   else{
      this.listadispositivi = []
    }
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements
      this.caricato=true;
      this.isClickable = true;
      this.paginator = true;
      this.isSearchingOn=false;
      this.isSelectingValue=false;
      this.refreshView = true;

    })
  }

 cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if(this.valoreRicerca && this.valoreRicerca.length >0){
      this.chiamataPaginata(this.pagina, this.selectedValue, this.valoreRicerca)

    }else{
      this.chiamataPaginata(this.pagina, this.selectedValue)
    }  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue=true;
    this.isSelectingValue = false;
    this.refreshView = false;

    if(this.valoreRicerca && this.valoreRicerca.length >0){
      this.chiamataPaginata(this.pagina, this.selectedValue, this.valoreRicerca)

    }else{
      this.chiamataPaginata(this.pagina, this.selectedValue)
    }
  }
valoreRicerca
 search() {
    this.valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionSearch){
      this.subscriptionSearch.unsubscribe()
    }
    this.isSearchingOn=true;
      this.paginator = false
      this.valoreRicerca = this.searchform.value.search
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue)
    }
  }

  ordinaEtichettaRem=0
  ordinaArrayRem(lable){
    if(this.ordinaEtichettaRem == 0 || this.ordinaEtichettaRem == 2){
      this.ordinaEtichettaRem = 1
    }else{
      this.ordinaEtichettaRem = 2
    }
    this.chiamataPaginata(this.pagina, this.selectedValue,null,lable,this.ordinaEtichettaRem)
  }

  ordinaEtichetta=0
  ordinaArray(lable){
    if(this.ordinaEtichetta == 0 || this.ordinaEtichetta == 2){
      this.ordinaEtichetta = 1
    }else{
      this.ordinaEtichetta = 2
    }
    this.chiamataPaginata(this.pagina-1, this.selectedValue,null,lable,this.ordinaEtichetta)
  }
  responsiveWidth() {   }

}
