export class Dispositivo {
 device_name?: string;
    active?: boolean;
    device_id?: string;
    device_type?: TipoDevice;
    utente? : any;
}
export class Telemetria {
    datiTelemetria?: string;
    nomeTelemetria?: string;
    dataTelemetria?: string;
}

export class TipoDevice {
    id?: number;
}

export class FirstLocation{
    latitudine: number
    longitudine: number
}

