import { Component, OnInit, ViewChild, ViewChildren, ɵbypassSanitizationTrustStyle } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { stringify } from 'querystring';
import { Utente } from 'src/app/MODELS/USER/utente';
import { ShareService, AccountService, AlertService, UsersService } from 'src/app/SERVICES';
import { WebSocketService } from 'src/app/SERVICES/GENERAL/socket-client.service';
import { threadId } from 'worker_threads';
import { UIChart } from 'primeng/chart';
import { DatePipe } from '@angular/common';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChildren('myInput') inputs;
  @ViewChild('chart') chart: UIChart;
  visite = 0;
  dataVisite = new Date();
  contatoreModuli = 1;
  visitView = true;
  assistenzaView = false;
  iotView = false;
  aulaView = false;
  assistenza;
  iot;
  alfaCallCenter;
  classroom;
  listaUltimiUtenti = [];
  listaUltimeChiamate = [];
  listaUltimePosizioni;
  listaUltimeChiamateUtente = [];
  listaDate = [];
  utente = false;
  viewCallCenter = false;
  entrato = true;
  loading = true;
  loading2 = true
  id;
  data: any;
  data2: any;
  mostraChart = false;
  mostraChart2 = false;
  login;
  arrayColori = ["#FFCE56", "#bce08c", "#6891C3", "#AAC5E2", "#FF6384", "#36A2EB", "#E0BBE4", "#DAE3D9", "#F4ADC6", "#FDFD95"]
  //IOT
  mostraChartPressioneIot = false;
  mostraChartTelemetriaIot = false;
  mostraChartBatteriaIot = false;
  dataPressioneIot: any;
  dataTelemetriaIot: any;
  dataBatteriaIot: any;
  currentDate = new Date();
  scegliDispGrafBatt = "Scelta dispositivo";
  scegliDispGrafPress = "Scelta dispositivo";
  listaDispositivi = [];
  caricalista = false;
  dataOggi = this.datePipe.transform(this.currentDate, 'yyyy/MM/dd');
  dataOggiParsata = Date.parse(this.dataOggi);
  dataOggiTimestamp = this.dataOggiParsata / 1000;
  dataFineGiornata = this.dataOggiTimestamp + 86340;
  options;
  radarOptions;
  contenitore;
  arrayobject = [];
  deviceSelezionatiGT = [];
  nomiLabels = ['HeartRate', 'StepCounter', 'DBP', 'SBP', 'BodyTemperature', 'WearState', 'Power'];
  noDisp = false;
  //IOT
  //-------------------------------------------------------classrooom

  listaAule = [];
  noAule = false;
  counterEventiSeguiti=0;
  counterEventiInProgramma=0;
  //--------------------------------------------------------task

  taskId = 0;
  task;
  listaTasks = [];
  listaTasksVerifica = [];
  visible: boolean = false
  info = true;
  crea = true;
  ambiente;
  baseUrlImg
  constructor(
    private shareService: ShareService,
    private accountService: AccountService,
    private userService: UsersService,
    private dispositiviService: IotService,
    private datePipe: DatePipe,
    private envService: EnvService,
    private classroomService: ClassroomService,
    private router : Router,
  ) {
    setTimeout(()=>{
    this.ambiente = environment;
    this.assistenza = this.envService.CR;
    this.iot = this.envService.IOT;
    this.classroom = this.envService.CLASSROOM;
    this.baseUrlImg =this.envService.apiUrl
    this.alfaCallCenter =this.envService.ALFACALLCENTER;
    if (this.assistenza) {
      this.contatoreModuli++
    }
    if (this.alfaCallCenter) {
      this.contatoreModuli++
    }
    if (this.iot) {
      this.contatoreModuli++
    }
    if (this.classroom) {
      this.contatoreModuli++
    }
    this.userService.dataTotal().subscribe((res) => {
      this.shareService.isLoggedIn$.subscribe((res) => {
        this.login = res;
      })
      let i = 0;
      res.data.forEach(element => {
        this.data.labels.push(element.nome);
        this.data.datasets[0].data.push(element.valore);
        this.data.datasets[0].backgroundColor.push(this.arrayColori[i]);
        this.data.datasets[0].hoverBackgroundColor.push(this.arrayColori[i]);
        i++
        if (i > 9) {
          i = 0;
        }
      });

      this.mostraChart = true;
    })
    this.userService.dataMonth().subscribe((res) => {

      let datasets = [];
      let i = 0;
      res.data.forEach(element => {

        let object = {
          label: element.nome,
          data: element.valore,
          fill: false,
          borderColor: this.arrayColori[i]
        }
        datasets.push(object)
        i++
        if (i > 9) {
          i = 0;
        }

      });
      this.data2.datasets = datasets;
      this.mostraChart2 = true;
    })
    this.data2 = {
      labels: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      datasets: [
      ]
    }
    this.data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [

          ],
          hoverBackgroundColor: [

          ]
        }]
    };



    //--------------------------------- grafico telemetria iot


    this.radarOptions = {
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,

        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<thead>';

            titleLines.forEach(function (title) {
              innerHtml += '<tr><th>' + title + '</th></tr>';
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function (body, i) {
              var colors = tooltipModel.labelColors[i];
              var style = 'background:' + colors.backgroundColor;
              style += '; border-color:' + colors.borderColor;
              style += '; border-width: 2px';
              var span = '<span style="' + style + '"></span>';
              innerHtml += '<tr><td>' + span + body + '</td></tr>';
            });
            innerHtml += '</tbody>';

            var tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      }
    }

    this.options = {
      scales: {

      },
      legend: {
        display: false
      },
    }


    let z = 0;
    let dataTelemetriaIotdataset = [];

    this.dispositiviService.getCountTelemetriaForDate(this.dataOggiTimestamp, this.dataFineGiornata).subscribe((resTelemetriaIot) => {
      let x = 0;

      resTelemetriaIot.data.forEach(element1 => {
        let nomeDevice = element1.device.deviceName;
        let arrayNumeroDatiTelemetria = [0, 0, 0, 0, 0, 0, 0];
        element1.telemetryCount.forEach(element2 => {
          if (element2.telemetry_name === 'HeartRate' && element2.count !== null) {
            arrayNumeroDatiTelemetria[0] = element2.count;
          }
          if (element2.telemetry_name === 'StepCounter' && element2.telemetry_name !== null) {
            arrayNumeroDatiTelemetria[1] = element2.count;
          }
          if (element2.telemetry_name === 'DBP' && element2.telemetry_name !== null) {
            arrayNumeroDatiTelemetria[2] = element2.count;
          }
          if (element2.telemetry_name === 'SBP' && element2.telemetry_name !== null) {
            arrayNumeroDatiTelemetria[3] = element2.count;
          }
          if (element2.telemetry_name === 'BodyTemperature' && element2.telemetry_name !== null) {
            arrayNumeroDatiTelemetria[4] = element2.count;
          }
          if (element2.telemetry_name === 'WearState' && element2.telemetry_name !== null) {
            arrayNumeroDatiTelemetria[5] = element2.count;
          }
          if (element2.telemetry_name === 'Power' && element2.telemetry_name !== null) {
            arrayNumeroDatiTelemetria[6] = element2.count;
          }


        });

        this.contenitore = {
          id: x,
          nomeDevice: nomeDevice,
          telemetria: arrayNumeroDatiTelemetria
        }
        this.arrayobject.push(this.contenitore);
        dataTelemetriaIotdataset.push();

        x++
      })
      this.dataTelemetriaIot = {
        labels: this.nomiLabels,
      }
      this.mostraChartTelemetriaIot = true;
    });

  },1000)

  }
  creagraficoTelemetria(device, target) {

    let trovato = false;
    let datasett = [];
    if (this.deviceSelezionatiGT.length > 2) {
      target.checked = false
    }
    let i = 0;
    this.deviceSelezionatiGT.forEach(element => {
      if (element.id === device.id) {
        trovato = true;
        this.deviceSelezionatiGT = this.deviceSelezionatiGT.filter(x => x.id !== device.id)
        if (this.deviceSelezionatiGT.length > 2) {
          target.checked = true;
        }
      }
    })
    if (!trovato) {
      if (this.deviceSelezionatiGT.length > 2) {
        target.checked = false;
      } else { this.deviceSelezionatiGT.push(device) }
    }

    this.deviceSelezionatiGT.forEach(element => {
      datasett.push({

        label: element.nomeDevice,
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: this.arrayColori[i],
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: element.telemetria
      });

      i++
      if (i > 9) {
        i = 0;
      }
    })

    this.update(datasett);

  }
  update(data) {
    this.mostraChartTelemetriaIot = false;
    this.dataTelemetriaIot = null;
    this.dataTelemetriaIot = {
      labels: this.nomiLabels,
      datasets: data,
    }
    this.mostraChartTelemetriaIot = true;
  }

  ngOnInit(): void {
    setTimeout(()=> {

    if (this.accountService.ruolo) {
      if (this.accountService.ruolo === 3) {
        this.id = this.accountService.userValue.data.id;
        this.utente = true;
        this.userService.ultimeDieciPosizioniUtente(this.id).subscribe((res) => {
          this.listaUltimePosizioni = res.data;
          this.listaUltimePosizioni.forEach(element => {
            if (!element.capComune) {
              this.listaUltimePosizioni = this.listaUltimePosizioni.filter(x => x !== element)
            }

          }
          );
          this.listaUltimePosizioni.forEach(element => {
            let tmp = element.capComune;
            element.capComune = element.capComune.split(',', 2)
            if (element.capComune.length > 1) {
              element.capComune[1] = tmp;
            }
          })
          this.loading = false;
        })
        this.userService.ultimeDieciChiamateUtente(this.id).subscribe((res) => {
          this.listaUltimeChiamateUtente = res.data;
          this.loading2 = false;

        })
      }
      else {
        this.userService.ultimiDieci().subscribe((res) => {
          this.listaUltimiUtenti = res.data;
          this.listaUltimiUtenti.forEach(element => {
            let tmp = element.capComune;
            element.capComune = element.capComune.split(',', 2)
            if (element.capComune.length > 1) {
              element.capComune[1] = tmp;
            }
          })
          this.loading = false;

        })
        this.userService.ultimeChiamate().subscribe((res) => {
          this.listaUltimeChiamate = res.data;
          this.loading2 = false;

        })
      }
    }

    this.dispositiviService.getListaDispositivi().subscribe((res) => {
      res.data.forEach(element => {
        if(element.deviceType.tipoDevice !== "Smartbed") {
          this.listaDispositivi.push(element)
        }
      });
      if (this.listaDispositivi.length < 1) {
        this.noDisp = false;
      }
      if (this.noDisp) {
        this.scegliGraficoPressione(this.listaDispositivi[0].id, this.scegliDispGrafPress);
        this.scegliGraficoBatteria(this.listaDispositivi[0].id, this.scegliDispGrafBatt);
      } else {
        this.graficoPressioneNoDisp();
        this.graficoBatteriaNoDisp()
      }
    })
    //--------------------------------- caricamento aule - classroom

    if(this.envService.CLASSROOM)
      this.classroomService.lista_classroom_leggera().subscribe(resLeggera=>{
              this.listaAule = resLeggera.data

        });
      // this.listaAule = res.data;



this.eventiProgrammati()
this.eventiSeguiti()




    //---------------get tasks
    this.userService.getListaTask(this.accountService.userValue.data.id).subscribe((res) => {
      this.listaTasksVerifica = res.data
      this.listaTasksVerifica.forEach(element => {
        element.created = true;
        element.modifica = false;
      })
      this.listaTasks.push(this.listaTasksVerifica);
    })
    this.userService.contaAccessi().subscribe((res) => {
      this.visite = res.data;
    })
  },1000)
  }

  scegliGraficoPressione(id, nomedisp) {
    //--------------------------------- grafico pressione iot
    this.scegliDispGrafPress = nomedisp;
    let arrayPressMin = [];
    let arrayPressMax = [];
    let arrayLable = [];
    this.dispositiviService.getMediaPressioneForDate(this.dataOggiTimestamp, this.dataFineGiornata, id).subscribe((resPressioneIot) => {
      let j = 0;
      resPressioneIot.data.forEach(element2 => {
        arrayPressMin.push(element2.DBP);
        arrayPressMax.push(element2.SBP);
        arrayLable.push(this.datePipe.transform(element2.ts, 'HH:mm'))
      })

      this.dataPressioneIot = {
        labels: arrayLable,
        datasets: [
          {
            label: 'Pressione minima',
            backgroundColor: '#42A5F5',
            data: arrayPressMin
          },
          {
            label: 'Pressione massima',
            backgroundColor: '#FFA726',
            data: arrayPressMax
          }
        ]
      };

    });

    this.mostraChartPressioneIot = true;
  }


  scegliGraficoBatteria(id, nomedisp) {

    //--------------------------------- grafico batteria iot
    this.mostraChartBatteriaIot = false;
    this.scegliDispGrafBatt = nomedisp;
    let arrayBatteria = [];
    let arrayValori = [];
    let arrayTimestamp = [];
    let appoggio;

    this.dispositiviService.getTelemetryForDate(this.dataOggiTimestamp, this.dataFineGiornata, id).subscribe((resTelemetrieIot) => {

      let j = 0;
      resTelemetrieIot.data.forEach(element2 => {
        let dataOggiPower = this.datePipe.transform(element2.timestamp, 'HH:mm');
        arrayTimestamp.push(dataOggiPower);
        arrayValori.push(j++);

        if (element2.telemetryName === 'Power') {
          appoggio = element2.telemetryData;
          arrayBatteria.push(element2.telemetryData);
        } else {
          arrayBatteria.push(appoggio);
        }

      })

      this.dataBatteriaIot = {
        labels: arrayTimestamp,
        datasets: [
          {
            label: 'Rilevazioni',
            data: arrayValori,
            fill: false,
            borderColor: '#FFA726'
          }
        ]
      }

    this.mostraChartBatteriaIot = true;
    });

    //IOT

  }
  graficoPressioneNoDisp() {
    this.dataPressioneIot = {
      labels: ['00:00/04:00', '04:00/08:00', '08:00/12:00', '12:00/16:00', '16:00/20:00', '20:00/24:00'],
      datasets: [
        {
          label: 'Pressione minima',
          backgroundColor: '#42A5F5',
          data: 0
        },
        {
          label: 'Pressione massima',
          backgroundColor: '#FFA726',
          data: 0
        }
      ]
    };
    this.mostraChartPressioneIot = true;
  }

  graficoBatteriaNoDisp() {
    this.dataBatteriaIot = {
      labels: 0,
      datasets: [
        {
          label: 'Rilevazioni',
          data: 0,
          fill: false,
          borderColor: '#FFA726'
        }
      ]
    }
    this.mostraChartBatteriaIot = true;
  }

  view = 'visit'
  cambiaView(view) {
    if (this.view != view) {
      this.aulaView = false;
      this.viewCallCenter = false;
      this.assistenzaView = false;
      this.iotView = false;
      this.visitView = false;
      if (view === 'aula') {
        this.view = 'aula'
        this.aulaView = true;
      }
      else if (view === 'callCenter') {
        this.view = 'callCenter'
        this.viewCallCenter = true;
      }
      else if (view === 'iot') {
        this.view = 'iot'
        this.dataTelemetriaIot = {
          labels: this.nomiLabels,
          datasets: null
        }
        this.deviceSelezionatiGT = []
        this.iotView = true;
      }
      else if (view === 'assistenza') {
        this.view = 'assistenza'
        this.assistenzaView = true;
      }
      else if (view === 'visit') {
        this.view = 'visit'
        this.visitView = true;
      }
    }
  }

  //--------------------------------studenti per aule - classroom
eventiSeguiti(){
this.classroomService.conta_eventi_seguiti().subscribe(res => {
this.counterEventiSeguiti = res.data
  });
  }

eventiProgrammati(){
this.classroomService.conta_eventi_programmati().subscribe(res => {
this.counterEventiInProgramma = res.data
});
}
  // studentiPerClasse(idAula, nomeAula) {
  //   this.scegliAulaGrafCalendar = nomeAula;
  //   this.listaAule.forEach(element => {
  //     if (element.id === idAula) {
  //       this.listaStudenti = element.listaUtenti;
  //     }
  //     this.loading = false;
  //   })
  // }
  //--------------------------------studenti per attività - classroom
  // studentiPerAttivita(idAula, nomeAula) {
  //   this.scegliAulaGrafActivity = nomeAula;
  //   this.dataAttivita = null;
  //   this.listaAttivitaOre = [];
  //   let aulaSelezionata = this.listaAule.find(x => idAula == x.id);
  //   if (aulaSelezionata) {
  //     if (aulaSelezionata.giorni.length > 0) {
  //       aulaSelezionata.giorni.forEach(element => {
  //         let dataParsata = this.datePipe.transform(element.data, 'yyyy/MM/dd')
  //         if (dataParsata == this.dataOggi) {
  //           if (element.ore.length > 0) {
  //             this.dataAttivita = element.data;
  //             this.listaAttivitaOre = element.ore;
  //             this.listaAttivitaOre.forEach(element => {
  //               let arrayStart = element.start.split(":", 2);
  //               let arrayEnd = element.end.split(":", 2)
  //               let ap1
  //               let ap2
  //               if (arrayStart[0] <= 9 && arrayStart[0].length < 2) {
  //                 ap1 = "0" + arrayStart[0]
  //               } else {
  //                 ap1 = arrayStart[0]
  //               }
  //               if (arrayStart[1] <= 9 && arrayStart[1].length < 2) {
  //                 ap2 = "0" + arrayStart[1]
  //               } else {
  //                 ap2 = arrayStart[1]
  //               }
  //               element.start = ap1 + ":" + ap2
  //               let appoggio3
  //               let appoggio4
  //               if (arrayEnd[0] <= 9 && arrayEnd[0].length < 2) {
  //                 appoggio3 = "0" + arrayEnd[0]
  //               } else {
  //                 appoggio3 = arrayEnd[0]
  //               }
  //               if (arrayEnd[1] <= 9 && arrayEnd[1].length < 2) {
  //                 appoggio4 = "0" + arrayEnd[1]
  //               } else {
  //                 appoggio4 = arrayEnd[1]
  //               }
  //               element.end = appoggio3 + ":" + appoggio4
  //             });
  //           }
  //         }
  //       });
  //     }
  //   }
  //   this.loading = false;
  // }
  //--------------------------------studenti per lezioni - classroom
  // auleAttive() {
  //   this.listaAule.forEach(element => {
  //     if (element.suspended === 0) {
  //       if (element.start && element.end) {
  //         element.start = element.start.split(' ', 1);
  //         element.end = element.end.split(' ', 1);
  //       }
  //       if (element.createdAt) {
  //         element.createdAt = element.createdAt.split(' ', 1);
  //       }
  //       this.listaAuleAttive = element;
  //       this.arrayListaAule.push(this.listaAuleAttive);
  //       this.loading = false;
  //     }
  //   })
  // }



  ////////////////////////// Widget Task
  elimina(task) {
    this.listaTasksVerifica = this.listaTasksVerifica.filter(x => x.id !== task.id);
    if (!task.created) {
      this.crea = true;
    }
    if (task.created) {
      this.userService.deleteTask(task.id).subscribe((res) => {
      })
    }
  }

  vaiModifica(task) {
    task.modifica = true;
    setTimeout(() => {
      this.inputs.toArray().find((e) => {
        return e.nativeElement.getAttribute('name') == ('formTask');
      }).nativeElement.focus();
    })
  }

  vaiCreazione() {
    this.crea = false;
    this.info = true;
    if (this.info) {
      let task = {
        id: this.taskId,
        descrizione: null,
        modifica: true,
        stato: false,
        validator: false,
        created: false,
        idUtente: this.accountService.userValue.data.id
      };
      this.taskId++
      this.listaTasksVerifica.push(task);
      this.listaTasksVerifica.reverse();
      this.visible = true;

      setTimeout(() => {
        this.inputs.toArray().find((e) => {
          return e.nativeElement.getAttribute('name') == ('formTask');
        }).nativeElement.focus();
      })
    }
  }

  // getValueNgModel(task) {
  //   if (!task.descrizione) {
  //     task.validator = false;

  //   } else {
  //     task.validator = true;
  //     task.modifica = false;

  //   }
  // }

  checked(task) {
    task.stato = !task.stato
    this.userService.editTask(task).subscribe((res) => {
      this.userService.getListaTask(this.accountService.userValue.data.id).subscribe((res) => {
        // this.listaTasksVerifica = res.data
        this.listaTasksVerifica.forEach(element => {
          element.created = true;
          element.modifica = false;
          this.crea=true
        })
        this.listaTasks.push(this.listaTasksVerifica);
      })
    })
  }

  vaiInfo(task) {
    this.crea = true;
    if (task.created === false) {
      this.userService.creaTask(task).subscribe((res) => {
        this.listaTasksVerifica.forEach(element => {
          if (element.id === task.id) {
            task.created = true;

          }
        })
        this.listaTasks.push(this.listaTasksVerifica);
        this.visible = true;

        task.modifica = false;
        this.userService.getListaTask(this.accountService.userValue.data.id).subscribe((res) => {
          this.listaTasksVerifica = res.data
          this.listaTasksVerifica.forEach(element => {
            element.created = true;
            element.modifica = false;
          })
          this.listaTasks.push(this.listaTasksVerifica);
        })
      })
    }
    else if (task.created === true) {
      this.userService.editTask(task).subscribe((res) => {
        this.listaTasks.push(this.listaTasksVerifica);
        this.visible = true;
        task.modifica = false;
        this.userService.getListaTask(this.accountService.userValue.data.id).subscribe((res) => {
          this.listaTasksVerifica = res.data
          this.listaTasksVerifica.forEach(element => {
            element.created = true;
            element.modifica = false;
          })
          this.listaTasks.push(this.listaTasksVerifica);
        })
      })
    }
  }

  vaiAula(aula){
    this.shareService.changeAula(aula)
    this.router.navigate(['webinar',aula.token])
  }

  controlloBloccoClass(dataAula){
   return (dataAula &&  new Date(dataAula) < this.currentDate)
  }

}
