/**
 * Questa classe contiene tutte le informazioni per gestire al meglio
 * le funzionalità di screenshoot e AR
 * @param idHTMLVideo contiene id HTML utile per l'ascolto dello stream
 * @param email email dell'utente utile per indicare al creatore della CR con chi interagire, inoltre serve lato BE per inviare
 * dei disegni a chi ha una sessione AR
 * @param ar funzionalità futura, quindi optional, che mi indica se lo stream è in AR
 */
export class UserStream {
  idHTMLVideo: string;
  email: string;
  ar?: boolean;
  videoActive: boolean = false;
  azioni = [
    'aggiungi forma',
    'elimina forma',
    'cancella tutto', //
  ];
  constructor(idHTMLVideo: string, email: string, ar?: boolean) {
    this.idHTMLVideo = idHTMLVideo;
    this.email = email;
    this.ar = ar;
  }
}
export enum Shape {
  CERCHIO = 0, //Shape.Cerchio=1    --> Shape[Shape.CERCHIO]
  N_Figure = 1, //Shape[1] = LUNGHEZZA
}
