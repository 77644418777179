<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12 ">
        <div class="card">
          <div class="card-content">
            <h4 class="card-title">Configurazione</h4>
            <ul class="nav nav-pills nav-pills-primary">
              <li class="active listItem">
                <a *ngIf="!alfa" href="#pill1" data-toggle="tab">Tema</a>
              </li>
              <li class="listItem">
                <a *ngIf="!alfa" href="#pill2" data-toggle="tab">Logo</a>
              </li>
              <!-- <li>
                                <a href="#pill3" data-toggle="tab">Chatbot</a>
                            </li> -->
              <p-confirmDialog key="cancella" #cd icon="pi pi-exclamation-triangle"
                [style]="{width:  responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                <p-footer>

                  <button [disabled]="loading" *ngIf="cancella" type="button" pButton icon="pi pi-check" label="Sì"
                    (click)="onRemove()">
                    <span *ngIf="loading">
                      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                    </span>
                  </button>

                  <button [disabled]="loading" type="button" pButton icon="pi pi-times" label="No"
                    (click)="cd.reject()"></button>

                </p-footer>
              </p-confirmDialog>

              <li class="listItem">
                <a *ngIf="cr" href="#pill4" data-toggle="tab">Motivi Chiamata</a>
              </li>
              <li class="listItem" [ngClass]="{'active':alfa}">
                <a *ngIf="classroom" href="#pill3" data-toggle="tab">Education</a>
              </li>
              <li class="listItem">
                <a *ngIf="iot || avGroup" href="#pill5" data-toggle="tab">IoT</a>
              </li>
              <li class="listItem">
                <a *ngIf="badge" href="#pill6" data-toggle="tab">Controllo Accessi</a>
              </li>
              <li class="listItem">
                <a *ngIf="alfaCallCenter" id="ricercaMercato" href="#pill7" data-toggle="tab">Ricerca di mercato</a>
              </li>
              <li class="listItem">
                <a *ngIf="alfaCallCenter" href="#pill8" data-toggle="tab">Ordine</a>
              </li>
              <li class="listItem">
                <a *ngIf="alfaCallCenter" href="#pill9" data-toggle="tab">Questionari</a>
              </li>
              <li class="listItem">
                <a *ngIf="clienti" href="#pill10" data-toggle="tab">Tipologia prevalente di attività svolte</a>
              </li>
              <li class="listItem">
                <a *ngIf="clienti" href="#pill11" data-toggle="tab">Tipologia di laboratori/interventi attivati</a>
              </li>
              <li class="listItem">
                <a *ngIf="clienti" href="#pill12" data-toggle="tab">Obiettivi generali</a>
              </li>
            </ul>
            <hr>
            <div class="tab-content">

              <div *ngIf="!alfa" class="tab-pane active" id="pill1">
                <div>
                  <h4>Scegli il colore che preferisci</h4>
                  <p style="margin-top: 2%; margin-bottom:2% ;">
                    <a (click)="cambiaColore( 'rgb(255, 153, 0)')"><i class="material-icons notranslate pointer"
                        style="color:rgb(255, 153, 0); cursor: pointer; font-size: xx-large;">fiber_manual_record</i>
                    </a>
                    <a (click)="cambiaColore('rgba(35,80,153,1)')"> <i class="material-icons notranslate pointer"
                        style="color:  rgba(35,80,153,1);cursor: pointer;font-size: xx-large;">fiber_manual_record</i>
                    </a>
                    <a (click)="cambiaColore('rgb(255, 0, 0)')"><i class="material-icons notranslate pointer"
                        style="color: rgb(255, 0, 0);cursor: pointer;font-size: xx-large;">fiber_manual_record</i> </a>
                    <a (click)="cambiaColore('rgb(53, 167, 49)')"><i class="material-icons notranslate pointer"
                        style="color: rgb(53, 167, 49);cursor: pointer;font-size: xx-large;">fiber_manual_record</i>
                    </a>
                    <a (click)="cambiaColore('rgb(172,47,163)')"><i class="material-icons notranslate pointer"
                        style="color: rgb(172,47,163);cursor: pointer;font-size: xx-large;">fiber_manual_record</i> </a>
                    <a (click)="cambiaColore('rgb(255, 230, 0)')"><i class="material-icons notranslate pointer"
                        style="color: rgb(255, 230, 0);cursor: pointer;font-size: xx-large;">fiber_manual_record</i>
                    </a>
                  </p>
                </div>
                <div><button class="btn btn-primary pull-right" (click)="applica()">Applica</button></div>
              </div>
              <p-confirmDialog [style]="{width:  responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
              </p-confirmDialog>

              <div *ngIf="!alfa" class="tab-pane" id="pill2">
                <h4>Rimuovi il logo aziendale</h4>
                <button class="btn btn-secondary" (click)="cancella()">Rimuovi logo</button>
                <hr>
                <h4>Carica il logo aziendale</h4>
                <p style="margin-bottom: 2%;"> Da questa sezione potrai aggiungere il logo della tua azienda per
                  personalizzare il sito.</p>
                <p style="margin-bottom: 2%;">Il file deve rispettare i seguenti criteri: formato JPEG/JPG/PNG/GIF;
                  dimensioni massimo 1MB; 400px x 125px</p>
                <div>
                  <input style="display: none" type="file" accept="image/*" (change)="onFileChanged($event)" #fileInput>
                  <button (click)="fileInput.click()" class="btn btn-secondary" type="button">Scegli File</button>
                  <div class="img-space">



                  </div>
                </div>
                <p *ngIf="nomeFile" style="margin-top: 8px">{{nomeFile}}</p>
                <p *ngIf="!nomeFile" style="margin-top: 8px">Nessun file selezionato</p>
                <div style="text-align: end; justify-content: end;"><button class="btn btn-primary "
                    (click)="onUpload()">Carica</button></div>


              </div>



              <div class="tab-pane" id="pill4">


                <p-table [value]="listamotivi" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
                  responsiveLayout="stack">

                  <ng-template pTemplate="header">
                    <tr>

                      <th>Motivo</th>
                      <th class="tdClass">Elimina</th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-motivo>
                    <tr>

                      <td>{{motivo.motivoChiamata}}</td>
                      <td class="tdClass" (click)="rimuoviMotivo(motivo.id)"><a><i
                            class="material-icons notranslate pointer" style="color:red">delete</i></a></td>


                    </tr>

                  </ng-template>

                </p-table>
                <div class="text-right">

                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModal"
                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi Motivo</button>
                  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci Motivo<span
                              class="colorRed"> *</span></h5>

                        </div>
                        <div class="modal-body">
                          <form [formGroup]="motivoForm" class="form-horizontal">
                            <input type="text" class="form-control" placeholder="Motivo" formControlName="motivo"
                              maxlength="45">
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="onSubmit()">Aggiungi</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" [ngClass]="{'active':alfa}" id="pill3">
                <app-aula-virtuale-config></app-aula-virtuale-config>
              </div>
              <div class="tab-pane scrollMobile" id="pill5">
                <app-dispositivo-config></app-dispositivo-config>
              </div>

              <div class="tab-pane scrollMobile" id="pill6">
                <app-badge-config></app-badge-config>
              </div>


              <div class="tab-pane" id="pill7">


                <p-table [value]="listamotiviRicercaMercato" [scrollable]="true" scrollHeight="40rem"
                  scrollWidth="100rem" responsiveLayout="stack">

                  <ng-template pTemplate="header">
                    <tr>

                      <th>Ricerca di mercato</th>
                      <th class="tdClass">Elimina</th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-motivo>
                    <tr>

                      <td>{{motivo.nome}}</td>
                      <td class="tdClass" title="Elimina" (click)="rimuoviMotivoMercato(motivo.id)"><a><i
                            class="material-icons notranslate pointer" style="color:red">delete</i></a></td>


                    </tr>

                  </ng-template>

                </p-table>
                <div class="text-right">

                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModal2"
                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi Ricerca di
                    mercato</button>
                  <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci ricerca di mercato<span
                              class="colorRed"> *</span></h5>

                        </div>
                        <div class="modal-body">
                          <form [formGroup]="motivoFormMercatoOrdine" class="form-horizontal">
                            <input type="text" class="form-control" placeholder="ricerca di mercato"
                              formControlName="mercatoOrdine" maxlength="45">
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="onSubmitMercato()">Aggiungi</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="pill8">


                <p-table [value]="listamotiviOrdine" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
                  responsiveLayout="stack">

                  <ng-template pTemplate="header">
                    <tr>

                      <th>Ordine</th>
                      <th class="tdClass">Elimina</th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-motivo>
                    <tr>

                      <td>{{motivo.nomeOrdine}}</td>
                      <td class="tdClass" title="Elimina" (click)="rimuoviMotivoOrdine(motivo.id)"><a><i
                            class="material-icons notranslate pointer" style="color:red">delete</i></a></td>


                    </tr>

                  </ng-template>

                </p-table>
                <div class="text-right">

                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModal3"
                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi Ordine</button>
                  <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci ordine<span
                              class="colorRed"> *</span></h5>

                        </div>
                        <div class="modal-body">
                          <form [formGroup]="motivoFormMercatoOrdine" class="form-horizontal">
                            <input type="text" class="form-control" placeholder="Inserisci ordine"
                              formControlName="mercatoOrdine" maxlength="45">
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="onSubmitOrdini()">Aggiungi</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="pill9">

                <div *ngIf="!showAddQuestionario">
                  <mat-form-field class="w-45">
                    <input matInput (input)="search()" [placeholder]="'Ricerca questionario'" [(ngModel)]="ricercaQuestionario" />
                  </mat-form-field>
                    <p-table [value]="listaQuestionario" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
                    responsiveLayout="stack">

                    <ng-template pTemplate="header">
                      <tr>

                        <th>Questionario</th>
                        <th class="tdClass">Modifica</th>
                        <th class="tdClass">Elimina</th>

                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-motivo>
                      <tr>

                        <td>{{motivo.nomeQuestionario}}</td>
                        <td class="tdClass" (click)="modificaQuestionario(motivo.id)"><a><i
                              class="material-icons notranslate pointer">edit</i></a></td>
                        <td class="tdClass" title="Elimina" (click)="rimuoviQuestionario(motivo.id)"><a><i
                              class="material-icons notranslate pointer" style="color:red">delete</i></a></td>


                      </tr>

                    </ng-template>

                  </p-table>
                  <div class="mt-10">
                    <app-paginator *ngIf="refreshView" [pageSize]="maxPagine"
                            [(isClickable)]="isClickableQuestionari" (currentPageEmitter)="cambiaPaginaQuestionari($event)"
                            [startingPage]="1">
                          </app-paginator>
                  </div>
                  <div class="text-right">

                    <button class="btn btn-success" (click)="showAddQuestionario = true"
                      style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi
                      Questionario</button>

                  </div>
                </div>
                <div *ngIf="showAddQuestionario">
                  <h4>Aggiungi Questionario</h4>

                  <div class="flex">
                    <mat-form-field class="w-45">
                      <mat-label>Nome questionario</mat-label>
                      <input matInput [placeholder]="'Nome questionario'" [(ngModel)]="questionarioToAdd.nomeQuestionario" />

                    </mat-form-field>

                    <mat-form-field class="w-45 ml-auto">
                      <mat-label>Target</mat-label>
                      <mat-select multiple [(ngModel)]="questionarioToAdd.listaTarget" [compareWith]="compareItems">
                        <mat-option *ngFor="let target of listaTarget" [value]="target">{{target.nomeTarget}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <h4>Aggiungi Domande</h4>
                  <div class="flex v-center" *ngFor="let item of questionarioToAdd.listaDomande; let i = index">
                    <mat-form-field class="w-100">
                      <ng-container>
                        <mat-label>Domanda</mat-label>
                        <input matInput [placeholder]="'Domanda'" [(ngModel)]="questionarioToAdd.listaDomande[i].domanda" />
                      </ng-container>
                    </mat-form-field>
                    <i (click)="removeDomanda(i)" class="material-icons notranslate pointer" style="color:red;cursor: pointer;">close</i>
                  </div>
                  <button class="btn btn-primary" (click)="addDomanda()">Aggiungi Domanda</button>
                  <div class="flex">
                    <button class="btn btn-secondary" (click)="procedureCreateModify()">Torna indietro</button>
                    <button class="btn btn-primary ml-auto" (click)="addQuestionario()" [disabled]="isLoading"><span *ngIf="!isModificaQuestionario">Aggiungi</span> <span *ngIf="isModificaQuestionario">Modifica</span> Questionario</button>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="pill10">


                <p-table [value]="listaTipologiaAttivita" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
                  responsiveLayout="stack">

                  <ng-template pTemplate="header">
                    <tr>

                      <th>Lista Valori</th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-tipologiaAttivita>
                    <tr>
                      <td>{{tipologiaAttivita.nomeAttivita}}</td>
                      </tr>
                  </ng-template>

                </p-table>
                <div class="text-right">

                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModalTipologiaAttivita"
                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi Valore</button>
                  <div class="modal fade" id="exampleModalTipologiaAttivita" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci Valore<span
                              class="colorRed"> *</span></h5>

                        </div>
                        <div class="modal-body">
                          <form [formGroup]="tipologiaAttivitaForm" class="form-horizontal">
                            <input type="text" class="form-control" placeholder="Valore" formControlName="nomeAttivita"
                              maxlength="45">
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="accettaTipologiaAttivita()">Aggiungi</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p-confirmDialog key="accettaTipologiaAttivita" #ta icon="pi pi-exclamation-triangle"
                [style]="{width:  responsiveWidth()}" class="t-left" [acceptLabel]="'Si'" [baseZIndex]="5">
                <p-footer>
                  <button [disabled]="loading" type="button" pButton icon="pi pi-times" label="No"
                                      (click)="ta.reject()"></button>

                  <button [disabled]="loading" *ngIf="cancella" type="button" pButton icon="pi pi-check" label="Sì"
                    (click)="onSubmitTipologiaAttivita()">
                    <span *ngIf="loading">
                      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                    </span>
                  </button>

                </p-footer>
              </p-confirmDialog>
                </div>
              </div>

              <div class="tab-pane" id="pill11">

                <p-table [value]="listaTipologiaLaboratori" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
                  responsiveLayout="stack">

                  <ng-template pTemplate="header">
                    <tr>

                      <th>Lista Valori</th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-tipologiaLaboratori>
                    <tr>
                      <td>{{tipologiaLaboratori.nomeLaboratoriInterventi}}</td>
                    </tr>
                  </ng-template>

                </p-table>
                <div class="text-right">

                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModalTipologiaLaboratori"
                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi Valore</button>
                  <div class="modal fade" id="exampleModalTipologiaLaboratori" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci Valore<span
                              class="colorRed"> *</span></h5>

                        </div>
                        <div class="modal-body">
                          <form [formGroup]="tipologiaLaboratoriForm" class="form-horizontal">
                            <input type="text" class="form-control" placeholder="Valore" formControlName="nomeLaboratoriInterventi"
                              maxlength="45">
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="accettaTipologiaLaboratori()">Aggiungi</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p-confirmDialog key="accettaTipologiaLaboratori" #tl icon="pi pi-exclamation-triangle"
                  [style]="{width:  responsiveWidth()}" class="t-left" [acceptLabel]="'Si'" [baseZIndex]="5">
                  <p-footer>
                    <button [disabled]="loading" type="button" pButton icon="pi pi-times" label="No"
                                        (click)="tl.reject()"></button>

                    <button [disabled]="loading" *ngIf="cancella" type="button" pButton icon="pi pi-check" label="Sì"
                      (click)="onSubmitTipologiaLaboratori()">
                      <span *ngIf="loading">
                        <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                          styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                      </span>
                    </button>

                  </p-footer>
                </p-confirmDialog>
                </div>
              </div>

              <div class="tab-pane" id="pill12">


                <p-table [value]="listaObiettivi" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
                  responsiveLayout="stack">

                  <ng-template pTemplate="header">
                    <tr>

                      <th>Lista Valori</th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-obiettivo>
                    <tr>
                      <td>{{obiettivo.nomeObiettivo}}</td>
                    </tr>
                  </ng-template>

                </p-table>
                <div class="text-right">

                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModalObiettivi"
                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Aggiungi Valore</button>
                  <div class="modal fade" id="exampleModalObiettivi" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title pull-left" id="exampleModalLabel">Inserisci Valore<span
                              class="colorRed"> *</span></h5>

                        </div>
                        <div class="modal-body">
                          <form [formGroup]="obiettiviGeneraliForm" class="form-horizontal">
                            <input type="text" class="form-control" placeholder="Valore" formControlName="nomeObiettivo"
                              maxlength="45">
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="accettaObiettiviGenerali()">Aggiungi</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p-confirmDialog key="accettaObiettiviGenerali" #og icon="pi pi-exclamation-triangle"
                  [style]="{width:  responsiveWidth()}" class="t-left" [acceptLabel]="'Si'" [baseZIndex]="5">
                  <p-footer>
                    <button [disabled]="loading" type="button" pButton icon="pi pi-times" label="No"
                                        (click)="og.reject()"></button>

                    <button [disabled]="loading" *ngIf="cancella" type="button" pButton icon="pi pi-check" label="Sì"
                      (click)="onSubmitObiettivi()">
                      <span *ngIf="loading">
                        <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                          styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                      </span>
                    </button>

                  </p-footer>
                </p-confirmDialog>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
