<!-- <p-messages [value]="msgs" ></p-messages> -->



<div class="container-fluid">
    <div class="content" >

        <div class="row" >
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header" >
                            <h5 class="card-title"  style="font-weight: bold;">Crea nuovo Gruppo</h5>
                            <form [formGroup]="form" >
                                <div class="marginBottom2px">
                                    <label for="nomeMod">Nome<span class="colorRed"> *</span></label>
                                    <input  type="text" placeholder="Nome" formControlName="nome" maxlength="30" class="form-control ng-untouched ng-pristine ng-invalid">
                                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                        <div *ngIf="f.nome.errors">Campo nome obbligatorio</div>
                                      </div>
                                  </div>
                                  <div >
                                    <label for="descrizioneMod">Descrizione<span class="colorRed"> *</span></label>
                                    <input type="text" placeholder="Descrizione" formControlName="descrizione" maxlength="49" class="form-control ng-untouched ng-pristine ng-invalid">
                                <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback">
                                    <div *ngIf="f.descrizione.errors">Campo descrizione obbligatorio</div>
                                  </div>
                                  </div>
                            </form>
                        </div>
                        <div class="row pagination-container">
                          <div class="col-sm-3 col-xs-3">
                            <div class="dataTables_length" id="datatables_length" [ngClass]="{'disabled':!isClickable}">
                              <div style="display: flex;">
                                <label class="form-group">Limita Risultati
                                  <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                                    aria-controls="datatables" class="form-control input-sm" style="height: 35px">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
            
                                  </select>
                                  <div *ngIf="isSelectingValue" style="display: flex;align-items: end;">
                                    <div class="text-center" style="position: absolute;right: 0px;">
                                      <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-sm-9 col-md-9">
                            
                            <div class="btn button-icon-filter pull-right" [ngClass]="{'active': filtersVisible}" (click)="openFilters()">
                              <svg xmlns="http://www.w3.org/2000/svg" width="33.75" height="23.391"
                                   viewBox="0 0 33.75 23.391" class="mw-50">
                                <path id="Icon_ion-filter" data-name="Icon ion-filter"
                                      d="M33.188,12.565H2.813A1.9,1.9,0,0,1,1.125,10.5,1.9,1.9,0,0,1,2.813,8.438H33.188A1.9,1.9,0,0,1,34.875,10.5,1.9,1.9,0,0,1,33.188,12.565ZM27.563,22.2H8.438A1.9,1.9,0,0,1,6.75,20.133a1.9,1.9,0,0,1,1.688-2.064H27.563a1.9,1.9,0,0,1,1.688,2.064A1.9,1.9,0,0,1,27.563,22.2Zm-6.75,9.631H15.188A1.9,1.9,0,0,1,13.5,29.764,1.9,1.9,0,0,1,15.188,27.7h5.625A1.9,1.9,0,0,1,22.5,29.764,1.9,1.9,0,0,1,20.813,31.828Z"
                                      transform="translate(-1.125 -8.438)" />
                              </svg>
                            </div>
                            
                            <button class="btn small-square-primary pull-right" type="submit" *ngIf="filtersVisible" (click)="submitFilters()">
                              <i class="material-icons notranslate pointer" style="font-size: 22px; padding: 2px 2px 2px 2px;">check</i>
                            </button>
                            <button *ngIf="filtersVisible" class="btn btn-default pull-right" type="button" (click)="deleteFilters()">
                              Rimuovi filtri
                            </button>
                          </div>
            
                        </div>
                        <!-- FORM FILTRI -->
                        <app-form-filter [filtersVisible]="filtersVisible" (emitFilters)="updateList($event)"
                                         [removeFilters]="removeFilters"></app-form-filter>
                        <!-- END FORM FILTRI -->


                        <div class="material-datatables">

                            <!-- <div class="scrollable"> -->
<p-table class="table table-striped table-no-bordered table-hover" [value]="listautenti" [(selection)]="selectedUsers3" dataKey="id" [responsive]=true [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem">

    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem !important">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Cognome</th>
            <th>Nome</th>
            <th>Email</th>
            <th>Specialità</th>
            <th>Zona</th>
            <th>ISF</th>
            <th>Gruppo</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-doctor>
        <tr>
            <td style="width: 3rem !important">
                <p-tableCheckbox [value]="doctor" ></p-tableCheckbox>
            </td>
            <td class="one-line"><span>{{doctor.cognome || '---'}}</span></td>
            <td class="one-line"><span>{{doctor.nome || '---' }}</span></td>
            <td class="one-line"><span>{{doctor.email_privata || '---' }}</span></td>
            <td class="one-line"><span>{{doctor.specialita_1 || '---' }}</span></td>
            <td class="one-line"><span>{{doctor.micro_area || '---' }}</span></td>
            <td class="one-line"><span>{{doctor.isf || '---' }}</span></td>
          <!--<td class="one-line"><span>{{user.email}}</span></td>-->
            <td class="one-line">
              <span *ngIf="doctor.gruppos && doctor.gruppos.length > 0; else noGroups">
                <span *ngFor="let gruppo of doctor.gruppos; let i = index">
                    <span *ngIf="i < doctor.gruppos.length - 1">{{ gruppo?.nome + ', ' }}</span>
                    <span *ngIf="i === doctor.gruppos.length - 1">{{ gruppo?.nome }}</span>
                </span>
            </span>
            <ng-template #noGroups>
                <span>---</span>
            </ng-template>
            </td>
        </tr>

    </ng-template>
   <!-- <ng-template pTemplate="summary">
        <div class="text-right">
            <a  class="btn btn-primary" (click)="avanti()" style="color: white;">Avanti</a>
          </div>
    </ng-template> -->
</p-table>

<div class="row pagination-container">
  <div class="col-sm-5">
    <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite"
         >{{items}} di {{maxItems}}
      medici</div>
  </div>
  <div class="col-sm-7 text-right giustificaDestra">
    <app-paginator *ngIf="refreshView" [pageSize]="numeroMaxPagine" [(isClickable)]="isClickable"
                   (currentPageEmitter)="cambiaPagina($event)" [startingPage]="pagina+1"
    class="pull-right"></app-paginator>
  </div>
</div>

<!-- </div> -->
<div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;" >
    <button (click)="tornaGestioneGruppi(true)" class="btn">Chiudi</button>
    <button (click)="onSubmit()" class="btn btn-primary">Crea</button>
        </div>
</div>

</div>

</div>

</div>

</div>
</div>
