import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService, UsersService } from 'src/app/SERVICES';
import { FarmaciaService } from 'src/app/SERVICES/FARMACIE/farmacie';

@Component({
  selector: 'app-farmacie-add',
  templateUrl: './farmacie-add.component.html',
  styleUrls: ['./farmacie-add.component.scss'],
})
export class FarmacieAddComponent implements OnInit {
  @Output() goBackAdd = new EventEmitter<any>();

  @Input() inputForm: any = {
    ragione_Sociale_Farmacia: '',
    ricerca_Di_Mercato: '',
    elaborazione_Report: '',
    codice_AlfaIntes: '',
    partita_Iva: '',
    indirizzo: '',
    cap: '',
    comune: '',
    provincia: '',
    regione: '',
    class_Enasarco: '',
    nominativo_Contatto: '',
    numero_Telefonico_Fisso: '',
    numero_Telefonico_Cellulare: '',
    email: '',
    data_Ultimo_Ordine: '',
    importo_Ultimo_Ordine: '',
    classe_Principale: '',
    classe_Clienti: '',
    classe_Mercurio: '',
    classe_Di_Provenienza: '',
    email_Listino_Inviata: '',
    data_Invio_EmailListino: '',
    contratto: '',
    data_Invio_Contratto: '',
    data_Ricezione_Contratto: '',
    proposta_Ordine: '',
    data_Proposta_Ordine: '',
    motivi_No_Ordine: '',
    nome_Centro_Acquisto: '',
    note: '',
  };

  firstStepInputs: string[] = [
    'ragione_Sociale_Farmacia',
    'partita_Iva',
    'numero_Telefonico_Fisso',
    'numero_Telefonico_Cellulare',
    'email',
  ];

  secondStepInputs: string[] = [
    'indirizzo',
    'cap',
    'comune',
    'provincia',
    'regione',
  ];

  thirdStepInputs: string[] = [
    'class_Enasarco',
    'classe_Principale',
    'classe_Clienti',
    'classe_Mercurio',
    'classe_Di_Provenienza',
  ];

  fourthStepInputs: string[] = [
    'nominativo_Contatto',
    'data_Ultimo_Ordine',
    'codice_AlfaIntes',
    'importo_Ultimo_Ordine',
    'email_Listino_Inviata',
    'data_Invio_EmailListino',
    'contratto',
    'data_Invio_Contratto',
    'data_Ricezione_Contratto',
    'proposta_Ordine',
    'data_Proposta_Ordine',
    'motivi_No_Ordine',
    'nome_Centro_Acquisto',
    'note',
    'elaborazione_Report',
  ];

  firstFormGroup = this._formBuilder.group({
    ragione_Sociale_Farmacia: ['', Validators.required],
    partita_Iva: ['', [Validators.pattern('^[0-9,]+'), Validators.min(0)]],
    numero_Telefonico_Fisso: [
      '',
      [Validators.pattern('^[0-9,]+'), Validators.min(0)],
    ],
    numero_Telefonico_Cellulare: [
      '',
      [Validators.pattern('^[0-9,]+'), Validators.min(0)],
    ],
    email: [''],
  });
  secondFormGroup = this._formBuilder.group({
    indirizzo: [''],
    cap: [
      '',
      [Validators.pattern('^[0-9,]+'), Validators.min(0), Validators.required],
    ],
    comune: [''],
    provincia: [''],
    regione: [''],
  });
  thirdFormGroup = this._formBuilder.group({
    class_Enasarco: [''],
    classe_Principale: [''],
    classe_Clienti: [''],
    classe_Mercurio: [''],
    classe_Di_Provenienza: [''],
  });
  fourthFormGroup = this._formBuilder.group({
    nominativo_Contatto: [
      '',
      [Validators.pattern('^[0-9,]+'), Validators.min(0)],
    ],
    codice_AlfaIntes: ['', [Validators.minLength(10)]],
    data_Ultimo_Ordine: [''],
    importo_Ultimo_Ordine: [
      '',
      [Validators.pattern('^[0-9,]+'), Validators.min(0)],
    ],
    email_Listino_Inviata: [''],
    data_Invio_EmailListino: [''],
    contratto: [''],
    data_Invio_Contratto: [''],
    data_Ricezione_Contratto: [''],
    proposta_Ordine: [''],
    data_Proposta_Ordine: [''],
    motivi_No_Ordine: [''],
    nome_Centro_Acquisto: [''],
    note: [''],
    elaborazione_Report: [''],
  });

  listaMercato;
  constructor(
    private userService: UsersService,
    private _formBuilder: FormBuilder,
    private service: FarmaciaService,
    private alertService: AlertService
  ) {
    this.userService.getRicercaMercato().subscribe((res) => {
      this.listaMercato = res.data;
    });
  }

  ngOnInit(): void {}

  getLabel(value: string) {
    if (value === 'data_Invio_EmailListino') {
      return 'data Invio Email Listino';
    }
    try {
      return value.replace(/_/g, ' ');
    } catch (error) {
      return value;
    }
  }

  salvaForm() {
    let obj = this.inputForm;
    try {
      if (this.inputForm.nominativo_Contatto)
        obj.nominativo_Contatto = this.inputForm.nominativo_Contatto.split(',');
    } catch (error) {}
    try {
      if (this.inputForm.numero_Telefonico_Fisso)
        obj.numero_Telefonico_Fisso = this.inputForm.numero_Telefonico_Fisso
          .trim()
          .split(',');
      if (typeof obj.numero_Telefonico_Fisso === 'string')
        obj.numero_Telefonico_Fisso = [obj.numero_Telefonico_Fisso];
    } catch (error) {}
    try {
      if (this.inputForm.numero_Telefonico_Cellulare)
        obj.numero_Telefonico_Cellulare =
          this.inputForm.numero_Telefonico_Cellulare.trim().split(',');
      if (typeof obj.numero_Telefonico_Cellulare === 'string')
        obj.numero_Telefonico_Cellulare = [obj.numero_Telefonico_Cellulare];
    } catch (error) {}
    if (
      obj.data_Ultimo_Ordine !== null &&
      obj.data_Ultimo_Ordine !== '' &&
      obj.data_Ultimo_Ordine !== undefined &&
      obj.codice_AlfaIntes === ''
    ) {
      this.alertService.error('Inserire un codice alfaintes');
      return;
    }

    if (obj.elaborazione_Report !== '') {
      obj.elaborazione_Report = 'Si';
    } else {
      obj.elaborazione_Report = 'No';
    }
    obj.data_Ultimo_Ordine = this.getDateToBEFormat(
      this.inputForm.data_Ultimo_Ordine
    );
    obj.data_Invio_EmailListino = this.getDateToBEFormat(
      this.inputForm.data_Invio_EmailListino
    );
    obj.data_Invio_Contratto = this.getDateToBEFormat(
      this.inputForm.data_Invio_Contratto
    );
    obj.data_Ricezione_Contratto = this.getDateToBEFormat(
      this.inputForm.data_Ricezione_Contratto
    );
    obj.data_Proposta_Ordine = this.getDateToBEFormat(
      this.inputForm.data_Proposta_Ordine
    );
    if (this.inputForm.importo_Ultimo_Ordine)
      obj.importo_Ultimo_Ordine = parseInt(
        this.inputForm.importo_Ultimo_Ordine,
        10
      );
    if (this.inputForm.email_Listino_Inviata)
      obj.email_Listino_Inviata =
        this.inputForm.email_Listino_Inviata.length > 0 ? true : false;
    // this.service.inserisciFarmacia(obj).subscribe((res)=>{
    //   this.alertService.success('Farmacia inserita correttamente');
    this.emitGoBack();
    // })
  }

  emitGoBack() {
    this.goBackAdd.emit(this.inputForm);
  }

  getDateToBEFormat(date) {
    if (date)
      try {
        return (
          date.getFullYear() +
          '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          date.getDate()
        );
      } catch (error) {
        return date;
      }
    return undefined;
  }
}
