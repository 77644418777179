import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AlertService, AccountService } from 'src/app/SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aula-virtuale-config-go-to',
  templateUrl: './aula-virtuale-config-go-to.component.html',
  styleUrls: ['./aula-virtuale-config-go-to.component.scss'],
  providers: [ConfirmationService],
})
export class AulaVirtualeConfigGoToComponent implements OnInit {
  webinarsList = [];
  aula;
  aulaId;
  cancellaAula = false;
  loading = false;
  caricato = false;
  aule = true;
  crea = false;
  info = false;
  edit = false;
  ambiente = environment;
  listPage = 0;
  listSize = 10;
  isSelectingValue = false;
  isSearchingOn = false;
  isClickable = true;
  searchControl = new FormControl('');
  maxWebinars = 0;
  numeroMaxPagine = 0;
  paginator = true;
  refreshView = true;
  statoOrder: 'ASC' | 'DESC' = 'ASC';

  constructor(
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private alertService: AlertService,
    private classService: ClassroomService,
    private routing: Router,
    private accountService: AccountService
  ) {
    if (this.accountService.listaEnv && this.accountService.ruolo !== 1) {
      this.routing.navigate(['./']);
    }

    this.getWebinarsGoTo(this.listPage, this.listSize);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.searchControl.valueChanges
      .pipe(debounceTime(900), distinctUntilChanged())
      .subscribe((value) => {
        this.search();
      });
  }

  getWebinarsGoTo(
    page: number,
    size: number,
    options?: {
      ricerca: string;
    }
  ) {
    this.classService
      .lista_classroom_leggera(page, size, options?.ricerca, null, null)
      .subscribe((res) => {
        if (res.data.content.length > 0) {
          this.webinarsList = res.data.content;
        } else {
          this.webinarsList = [];
        }
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxWebinars = res.data.totalElements;

        this.isClickable = true;
        this.caricato = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.refreshView = true;
      });
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.listPage = event;
    this.getWebinarsGoTo(this.listPage, this.listSize, {
      ricerca: this.searchControl.value,
    });
  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.listPage = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;
    this.getWebinarsGoTo(this.listPage, this.listSize, {
      ricerca: this.searchControl.value,
    });
  }
  search() {
    this.refreshView = false;
    this.listPage = 0;
    this.isSearchingOn = true;
    this.paginator = false;
    this.getWebinarsGoTo(this.listPage, this.listSize, {
      ricerca: this.searchControl.value,
    });
  }

  elimina(aulaId) {
    this.aulaId = aulaId;
    this.cancellaAula = true;
    this.confirmationService.confirm({
      key: 'cancella',
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',

      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.cancellaAula = false;
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }

  accettaCancella() {
    this.loading = true;
    this.refreshView = false;
    this.classService.deleteAula(this.aulaId).pipe(tap(() => {
      
    })).subscribe((res) => {
      this.alertService.success(res.status.description);
      if (this.webinarsList.length == 1) {
        if (this.listPage > 0) {
          this.listPage = this.listPage - 1;
          this.getWebinarsGoTo(this.listPage, this.listSize);
        } else {
          this.getWebinarsGoTo(this.listPage, this.listSize);
        }
      } else {
        this.getWebinarsGoTo(this.listPage, this.listSize);
      }
      this.confirmationService.close();
      this.cancellaAula = false;
      this.loading = false;
      this.refreshView = true;
    }, err => {
        this.confirmationService.close();
        this.cancellaAula = false;
        this.loading = false;
        this.refreshView = true;
    });
  }

  vaiCreazione() {
    this.crea = true;
    this.aule = false;
  }

  vediLista(vai) {
    this.getWebinarsGoTo(this.listPage, this.listSize);
    this.info = false;
    this.edit = false;
    this.crea = false;
    this.aule = true;
  }

  vaiModifica(id) {
    this.aula = this.webinarsList.find((x) => x.id === id);
    this.aule = false;
    this.edit = true;
  }

  vaiInfo(id) {
    this.aula = this.webinarsList.find((x) => x.id === id);
    this.aule = false;
    this.info = true;
  }

  changeStatusOrder() {
    this.statoOrder = this.statoOrder === 'ASC' ? 'DESC' : 'ASC';
    this.webinarsList = [...this.webinarsList].sort((a, b) =>
      this.statoOrder === 'ASC'
        ? a.suspended - b.suspended
        : b.suspended - a.suspended
    );
  }
}
