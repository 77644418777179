import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CalendarModule} from 'primeng/calendar';
import {TableModule} from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { ErrorInterceptor, JwtInterceptor } from 'src/app/HELPERS';
import { EmailEditorModule } from 'angular-email-editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NewsLetterComponent } from './news-letter.component';
import { NewsLetterBuilderComponent } from './news-letter-builder/news-letter-builder.component';
import { newsLetterRoutingModule } from './news-letter-routing.module';
import { AddEditNewsLetterComponent } from './add-edit-news-letter/add-edit-news-letter.component';
import {AccordionModule} from 'primeng/accordion';
import {KnobModule} from 'primeng/knob';
import { ExternalComponentsModule } from 'src/app/MODELS/COMPONENTS/externalComponents.module';
import {StatoTemporale} from 'src/app/HELPERS/PIPES/statoTemporale'
@NgModule({
    imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ReactiveFormsModule,
    HttpClientModule,
    SelectButtonModule,
    CalendarModule,
    ProgressSpinnerModule,
    EmailEditorModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    newsLetterRoutingModule,
    AccordionModule,
    KnobModule,
    ExternalComponentsModule
  ],
    declarations: [
      NewsLetterBuilderComponent,
      NewsLetterComponent,
      AddEditNewsLetterComponent,
      StatoTemporale
            ],
    exports: [
      NewsLetterComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },                          
        ],
})


export class NewsLetterModule{

}