import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Alert, AlertType } from 'src/app/MODELS/USER/alert';


@Injectable({ providedIn: 'root' })
export class AlertService {

    private alertOpen = new BehaviorSubject(null);
    isComingFromLogin = false;

    alertOpen$ = this.alertOpen.asObservable();

    openAlert(alert : Alert) {
      this.alertOpen.next(alert)
    }

    success(summary, detail?){
        let alert = new Alert;
        alert.summary = summary;
        if(detail){
        alert.detail = detail;
        }
        alert.type = AlertType.success;
        this.openAlert(alert);
    }

    info(summary, detail?){
        let alert = new Alert;
        alert.summary = summary;
        if(detail){
        alert.detail = detail;
        }
        alert.type = AlertType.info;
        this.openAlert(alert);
    }

    infoNotSticky(summary, detail?){
        let alert = new Alert;
        alert.summary = summary;
        alert.detail = 'noSticky';
        alert.type = AlertType.info;
        this.openAlert(alert);
    }

    warn(summary, detail?){
        let alert = new Alert;
        alert.summary = summary;
        if(detail){
        alert.detail = detail;
        }
        alert.type = AlertType.warn;
        this.openAlert(alert);
    }

    error(summary, detail?){
        let alert = new Alert;
        alert.summary = summary;
        if(detail){
        alert.detail = detail;
        }
        alert.type = AlertType.error;
        this.openAlert(alert);
    }

    clear(){

    }
}
