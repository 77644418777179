<div class="content" *ngIf="loaded">
    <div class="container-fluid">
        <div class="row" >
            <div class="col-md-12">
                <div class="card">

    <div class="card-header card-header-text" style="margin-top: 2%;justify-content: center;">
        <h5 class="card-title" >{{(isDoctor ? "Info medico" : "Info utente")}}</h5>
     <br>
     <small *ngIf="(alfaintes && !alfaCall) && utenteCondiviso == '1'" class="text-muted" style="color:red">Questo profilo è condiviso</small>

    </div>
    <div class="content">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 80%;"></th>
                </tr>
            </thead>
            <tbody class="animation3">
                <tr *ngIf="dettaglioUtente.nome || '---'">
                    <td class="text-right" style="max-width: 30%;">
                        <h6 >Nome</h6>
                    </td>
                    <td  >{{dettaglioUtente.nome || '---'}}</td>
                </tr>
                <tr *ngIf="dettaglioUtente.cognome || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Cognome</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.cognome || '---'}}</td>
                </tr>
                <tr *ngIf="dettaglioUtente.email || dettaglioUtente.email_privata  || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.email || dettaglioUtente.email_privata  || '---'}}</td>
                </tr>
                <tr *ngIf="dettaglioUtente?.telefono || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Telefono</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente?.telefono || '---'}}</td>
                </tr>
                <tr *ngIf="dettaglio || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Dettagli Aggiuntivi</h6>
                    </td>
                    <td class="col-10">
                        {{dettaglio || '---'}}
                           </td>
                </tr>
                <tr *ngIf="ruolo || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Profilo</h6>
                    </td>
                    <td class="col-10">{{ruolo || '---'}}

                    </td>
                </tr>
                <tr *ngIf="!isDoctor">
                    <td class="text-right" style="font-weight: bold;">
                        <h6>Gruppo</h6>
                    </td>
                    <td class="col-10">
                        <span *ngIf="gruppi.length > 0; else noGroups">
                            <span *ngFor="let gruppo of gruppi; let i = index">
                                <span *ngIf="i < gruppi.length - 1">{{ gruppo?.nomeGruppo + ', ' }}</span>
                                <span *ngIf="i === gruppi.length - 1">{{ gruppo?.nomeGruppo }}</span>
                            </span>
                        </span>
                        <ng-template #noGroups>
                            <span>---</span>
                        </ng-template>
                    </td>
                </tr>
                
                <tr *ngIf="dettaglioUtente?.tag?.nome || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Ruolo</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente?.tag?.nome || '---'}}

                    </td>
                </tr>
                <!-- alfaIntes -->

            </tbody>
        </table>

            <table *ngIf="alfaintes && !alfaCall" class="table table-striped">
                <thead>
                    <tr>
                        <th style="width: 20%;"></th>
                        <th style="width: 80%;"></th>
                    </tr>
                </thead>
        <tbody >

                <tr *ngIf="informatore">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Informatore Scientifico</h6>
                    </td>
                    <td class="col-10">{{informatore}}

                    </td>
                </tr>
                <tr *ngIf="struttura">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Struttura</h6>
                    </td>
                    <td class="col-10">{{struttura}}

                    </td>
                </tr>
                
               </tbody>
               </table>

                <!--INFO MEDICO-->

                <table class="table table-striped" *ngIf="isDoctor">
                <thead>
                <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 80%;"></th>
                </tr>
            </thead>
            <tbody class="animation3">
      
                <td class="text-right" style="font-weight: bold;">
                    <h6 >Gruppo</h6>
                </td>
                <td class="col-10">
                    <span *ngFor="let gruppo of gruppi; let i = index">
                        <span *ngIf="i < gruppi.length - 1">{{ gruppo + ', ' }}</span>
                        <span *ngIf="i === gruppi.length - 1">{{ gruppo }}</span>
                    </span>
                </td>

                <tr *ngIf="dettaglioUtente.isf || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >ISF</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.isf  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.micro_area || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Zona</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.micro_area  || '---'}}

                    </td>
                </tr>            

                <tr *ngIf="dettaglioUtente.data_di_nascita || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Data di nascita</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.data_di_nascita  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.codice_fiscale || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Codice fiscale</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.codice_fiscale || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.numero_iscrizione_albo || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Numero iscrizione albo</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.numero_iscrizione_albo  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.stato_cliente || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Stato cliente</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.stato_cliente  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.flag_pubblico_privato || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Flag pubblico privato</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.flag_pubblico_privato  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.attivita || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Attività</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.attivita  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.carica || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Carica</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.carica  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.ragione_sociale_struttura || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Ragione sociale struttura</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.ragione_sociale_struttura  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.indirizzo_struttura || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Indirizzo struttura</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.indirizzo_struttura  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.partita_iva || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Partita iva</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.partita_iva  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.indirizzo_princ_di_visita || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Indirizzo principale di visita</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.indirizzo_princ_di_visita || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.cap || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Cap</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.cap || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.localita || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Località</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.localita || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.provincia || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Provincia</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.provincia || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.cellulare_lavoro || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Cellulare lavoro</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.cellulare_lavoro || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.cellulare_privato || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Cellulare privato</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.cellulare_privato || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.fisso_lavoro || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Fisso lavoro</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.fisso_lavoro || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.email_pubblica || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email pubblica</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.email_pubblica || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.email_primario_reparto || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email primario reparto</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.email_primario_reparto || '---'}}

                    </td>
                </tr>
              
                <tr *ngIf="dettaglioUtente.email_amministrazione || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email amministrazione</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.email_amministrazione || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.email_ufficio_formazione || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email ufficio formazione</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.email_ufficio_formazione || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.email_privata || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email amministrazione</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.email_privata || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.profilo || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Profilo</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.profilo  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.target_a || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Target A</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.target_a  || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.target_b || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Target B</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.target_b || '---'}}

                    </td>
                </tr>

                    <tr *ngIf="dettaglioUtente.specialita_1 || '---'">
                        <td class="text-right" style="font-weight: bold;">
                            <h6 >Specialità - 1</h6>
                        </td>
                        <td class="col-10">{{dettaglioUtente.specialita_1 || '---'}}
    
                        </td>
                    </tr>
                    <tr *ngIf="dettaglioUtente.percentuale_spec_1 || '---'">
                        <td class="text-right" style="font-weight: bold;">
                            <h6 >Percentuale Specialità - 1</h6>
                        </td>
                        <td class="col-10">{{dettaglioUtente.percentuale_spec_1  || '---'}}
    
                        </td>
                    </tr>
                    <tr *ngIf="dettaglioUtente.specialita_2 || '---'">
                        <td class="text-right" style="font-weight: bold;">
                            <h6 >Specialità - 2 </h6>
                        </td>
                        <td class="col-10">{{dettaglioUtente.specialita_2 || '---'}}
    
                        </td>
                    </tr>
                    <tr *ngIf="dettaglioUtente.percentuale_spec_2 || '---'">
                        <td class="text-right" style="font-weight: bold;">
                            <h6 >Percentuale Specialità - 2</h6>
                        </td>
                        <td class="col-10">{{dettaglioUtente.percentuale_spec_2 || '---'}}
    
                        </td>
                    </tr>
                    <tr *ngIf="dettaglioUtente.specialita_3 || '---'">
                        <td class="text-right" style="font-weight: bold;">
                            <h6 >Specialità - 3</h6>
                        </td>
                        <td class="col-10">{{dettaglioUtente.specialita_3 || '---'}}
    
                        </td>
                    </tr>
                    <tr *ngIf="dettaglioUtente.percentuale_spec_3 || '---'">
                        <td class="text-right" style="font-weight: bold;">
                            <h6 >Percentuale Specialità - 3</h6>
                        </td>
                        <td class="col-10">{{dettaglioUtente.percentuale_spec_3 || '---'}}
    
                        </td>
                    </tr>

                    
                <tr *ngIf="dettaglioUtente.consenso_privacy_a || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Consenso Privacy A</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.consenso_privacy_a || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.consenso_privacy_b || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Consenso Privacy B</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.consenso_privacy_b || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.consenso_privacy_c || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Consenso Privacy C</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.consenso_privacy_c || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.consenso_privacy_d || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Consenso Privacy D</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.consenso_privacy_d || '---'}}

                    </td>
                </tr>

                <tr *ngIf="dettaglioUtente.inserimento_manuale || '---'">
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Inserimento Manuale</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente.inserimento_manuale || '---'}}

                    </td>
                </tr>
                </tbody>
            </table> 
                   
    
            <hr><div class="text-right" style="margin-right: 5%;">
                <button (click)="indietro(true)" class="btn btn-primary" style="margin-left: 46%;margin-bottom: 3%;">Indietro</button>
           </div>


    </div> </div> </div> </div> </div>




