
        <div class="row">
            <div class="col-md-12">
                <div class="card">
        <div *ngIf="fileView">
            

                        <div class="card-header card-header-text"
                            style="margin-top: 2%; margin-bottom: 2%;  justify-content: center;">
                            <h5 class="card-title">Info File</h5>
                        </div>
                        <div class="content">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th style="width: 20%;"></th>
                                        <th style="width: 80%;"></th>
                                    </tr>
                                </thead>
                                <tbody class="animation3">
                                    <tr>
                                        <td class="text-right" style="max-width: 30%;">
                                            <h6>Nome File</h6>
                                        </td>
                                        <td>{{file.title}}</td>
                                    </tr>
                                    <tr *ngIf="file.category">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Categoria</h6>
                                        </td>
                                        <td class="col-10">{{file.category}}</td>
                                    </tr>
                                    <tr *ngIf="file.group">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Gruppo</h6>
                                        </td>
                                        <td class="col-10">{{file.group}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Data Upload</h6>
                                        </td>
                                        <td class="col-10">
                                            {{file.uploadDate | date: 'dd/MM/yyyy HH:mm'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Proprietario</h6>
                                        </td>
                                        <td class="col-10">
                                            {{proprietario}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="file.authors && file.authors[0]?.name">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Autore</h6>
                                        </td>
                                        <td class="col-10" >{{file.authors[0]?.name}}</td>
                                    </tr>
                                    <tr *ngIf="listaGruppi?.length > 0 ">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Visibilità per Gruppo</h6>
                                        </td>
                                        <td class="col-10">
                                            <span *ngFor="let gruppo of listaGruppi; let i=index">
                                                <span *ngIf="i <= listaGruppi.length-2">{{gruppo?.nomeGruppo + ',
                                                    '}}</span>
                                                <span *ngIf=" i === listaGruppi.length-1">{{gruppo?.nomeGruppo}}</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="listaProfili?.length > 0 ">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Visibilità per Profilo</h6>
                                        </td>
                                        <td class="col-10">
                                            <span *ngFor="let profilo of listaProfili; let i=index">
                                                <span *ngIf="i <= listaProfili.length-2">{{profilo?.nomeRuolo + ',
                                                    '}}</span>
                                                <span *ngIf=" i === listaProfili.length-1">{{profilo?.nomeRuolo}}</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr *ngIf="listaUtenti?.length > 0 ">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Visibilità per Utente</h6>
                                        </td>
                                        <td class="col-10">
                                            <span *ngFor="let utente of listaUtenti; let i=index">
                                                <span *ngIf="i <= listaUtenti.length-2">{{utente?.nome}}
                                                    {{utente?.cognome}}, </span>
                                                <span *ngIf=" i === listaUtenti.length-1">{{utente?.nome}}
                                                    {{utente?.cognome}}</span>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Estensione</h6>
                                        </td>
                                        <td class="col-10">
                                            {{file.extension}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>
        
        <div *ngIf="corsoView">
            
                        <div class="card-header card-header-text"
                            style="margin-top: 2%; margin-bottom: 2%;  justify-content: center;">
                            <h5 class="card-title">Info Corso</h5>
                        </div>
                        <div class="content">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th style="width: 20%;"></th>
                                        <th style="width: 80%;"></th>
                                    </tr>
                                </thead>
                                <tbody class="animation3">
                                    <tr>
                                        <td class="text-right" style="max-width: 30%;">
                                            <h6>Nome File</h6>
                                        </td>
                                        <td>{{corso.nomeCorso}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Categoria</h6>
                                        </td>
                                        <td class="col-10">{{corso.categoria}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Gruppo</h6>
                                        </td>
                                        <td class="col-10">{{corso.gruppo}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Data Upload</h6>
                                        </td>
                                        <td class="col-10">
                                            {{corso.createdAt | date: 'dd/MM/yyyy HH:mm'}}
                                        </td>
                                    </tr>
                                    <tr>

                                    </tr>
                                    <tr *ngIf="corso.autore"> 
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Autore</h6>
                                        </td>
                                        <td class="col-10" >{{corso.autore}}</td>
                                    </tr>
                                    <tr *ngIf="auleListSelected.length > 0">
                                        <td class="text-right" style="font-weight: bold;">
                                            <h6>Aule Associate</h6>
                                        </td>
                                        <td class="col-10" >
                                            <span *ngFor="let aule of auleListSelected; let i= index" >
                                            <i *ngIf="i <= auleListSelected.length-2" >{{aule.classroom.nomeAula + ',  '}} &nbsp;</i>
                                            <i *ngIf="i === auleListSelected.length-1" >{{aule.classroom.nomeAula}}</i>
                                          </span>
                                        </td>
                                        
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
  

        <hr>
        <div class="text-right" style="margin-right: 5%;">
            <button (click)="clear()" class="btn btn-primary"
                style="margin-left: 46%;margin-bottom: 3%;">Indietro</button>
        </div>
    </div>
</div>
