import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Password } from 'src/app/MODELS/USER/password';
import { AccountService, AlertService, UsersService, ShareService } from 'src/app/SERVICES';

@Component({
  selector: 'app-cambia-password',
  templateUrl: './cambia-password.component.html',
  styleUrls: ['./cambia-password.component.scss']
})
export class CambiaPasswordComponent implements OnInit {

  formPassword: FormGroup;
  loading = false;
  submitted = false;
  hide1 = true;
  hide2 = true;
  hide3 = true;
  icona="eye-off";
  returnUrl: string;
  lat : number;
  lng : number;
  id : number;
  email : string;
  modificaPassword = false;
  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService,
      private userService : UsersService,
      private service : ShareService
  ) { }

  ngOnInit() {

      this.formPassword = this.formBuilder.group({
          oldpassword: ['', Validators.required],
          newpassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
          confermapassword: ['', Validators.required]
      }, {
          validator: this.MustMatch('newpassword', 'confermapassword')
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields

  get g() { return this.formPassword.controls; }

  MustMatch(controlName: string, matchingControlName: string) {
      return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
  
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
        }
  
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
        } else {
          matchingControl.setErrors(null);
        }
      }
    }

 
        
indietro(){
  this.router.navigate(['']);
}
  onSubmitPassword() {
    
      this.submitted = true;
      
      let string1: string = this.formPassword.value.newpassword;
      let string2: string = this.formPassword.value.confermapassword;
      let string3: string = this.formPassword.value.oldpassword;
      let passwords : Password = new Password;
      passwords.newPassword=string1;
      passwords.currentPassword=string2;
      passwords.oldPassword= string3;
     

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
    if (this.formPassword.invalid) {
          return;
      }
     
      this.loading = true;
    
      this.accountService.PrimoAccesso(this.accountService.userValue.data.email, passwords)
      .pipe(first())
      .subscribe(
          data => {
              
              this.loading = false;
              this.alertService.info( data.status.description );
              this.router.navigate(['/']);
              this.submitted= false;
              this.accountService.login(this.accountService.userValue.data.email, string2).subscribe( res => {

              })
          },
          error => {
              this.loading = false;
              this.submitted = false;
          });
    

  }

}
