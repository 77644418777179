import {Component, OnInit, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService, AlertService, EnvService, UsersService } from '../../../../../SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { ConfirmationService, PrimeNGConfig, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {FormFilterComponent} from "../../../gestione-medici/form-filter/form-filter.component";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modifica-aula',
  templateUrl: './modifica-aula.component.html',
  styleUrls: ['./modifica-aula.component.scss'],
  providers: [ConfirmationService]

})
export class ModificaAulaComponent implements OnInit {

  //VAR PARTE MODIFICA
  @Input() aula;
  disableButton = false;
  @Output() tornaLista = new EventEmitter<void>();
  listaDettagli = [];
  i = 0;
  modificaRouting = false;
  modifica = true;
  aulaModForm: FormGroup;
  submitted = false;
  searchDocentiForm: FormGroup;
  docenti= [];
  searchStudentiForm: FormGroup;
  studenti= [];
  allStudenti = [];
  allDocenti= [];
  docenteId;
  cancellaDocente = false;
  loading = false;
  studenteId;
  cancellaStudente = false;
  caricato = false;
  listaTemplate;
  addDocente = false;
  listaTotaleDocenti = [];
  selectedAddDocenti;
  listaTotaleDocentiAppoggio = []
  addStudente = false;
  listaTotaleStudenti = [];
  selectedAddStudenti;
  listaTotaleStudentiAppoggio = []
  docenteEdit = false;
  studenteEdit = false;
  templateEdit = false;
  id;
  opzioniInd: SelectItem[];
  selectedOptionInd;
  privateOption: any[] = [
    { label: 'Si', value: 'true' },
    { label: 'No', value: 'false' }
  ];
  selectedOptionPrivate: boolean;
  ita = {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  };
  loadingSave = false;
  check;
  ambiente;
  afi
  notificheEdit: boolean;
  chekAllNotifications: any;
  formNotifiche: FormGroup;
  studenteIndex: number;
  docenteIndex: number;
  filtersVisible = false;
  removeFilters = false;
  filters: any = {};
  @ViewChild(FormFilterComponent) formFilterComponent: FormFilterComponent;
  refreshView = true;
  numeroMaxPagine: number;
  items;
  maxItems;
  isClickable = true;
  pagina;
  size = 10;
  pagination = 0;
  selectedValue;

  carica: boolean;
  isClickableCancellati: boolean;
  paginatorCancellati: boolean;
  paginator: boolean;
  isSearchingOn: boolean;
  isSelectingValue: boolean;
  isSelectingValueCancellati: boolean;
  showTable: boolean;
  valoreRicerca;
  selectedTeachersArray= [];
  currentTeachers = [];
  selectedStudentsArray= [];
  currentStudents = [];
  listaDocentiId = [];
  listaStudentiId = [];
  selectedStudents = [];
  subscriptionlistaTotaleDocenti: Subscription;
  loadingListe = false;
  chekAll = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private classService: ClassroomService,
    private _Activatedroute: ActivatedRoute,
    private routing: Router,
    private accountService: AccountService,
    private env: EnvService,
  )
  {
    this.ambiente = environment;
    this.afi = env.alfaintes
    this.opzioniInd = [{ label: "Sì", value: "1" }, { label: "No", value: "0" }];
    if (this.accountService.ruolo !== 1) {
      this.routing.navigate([''])
    }
    this.id = this._Activatedroute.snapshot.paramMap.get("id");
    this.aulaModForm = this.formBuilder.group({
      nomeMod: ['', Validators.required],
      descrizioneMod: ['', Validators.required],
    });
     this.searchDocentiForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
    this.searchStudentiForm = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.formNotifiche = new FormGroup({
      newsletters: new FormControl(false),
      social: new FormControl(false),
      whatsapp: new FormControl(false),
    });
    this.pagina = 0;
    this.selectedValue = 10;
  }

  ngOnInit(): void {
    if (this.afi) {
      this.classService.lista_attestati().subscribe((res) => {
        this.listaTemplate = res.data;
        this.listaTemplate.push({
          id: -1,
          titolo: 'Nessuno'
        })
      })
    }
    if (this.id) {
      this.modificaRouting = true;
      this.classService.lista_classroom_leggera().subscribe((res) => {
        if (!res.data.content) {
          res.data.forEach((aula) => {
            if (aula.id.toString() === this.id) {
              this.aula = aula;
            }
          });
        }
        else {
          res.data.content.forEach((aula) => {
            if (aula.id.toString() === this.id) {
              this.aula = aula;
            }
          });
        }
        if (this.aula.start) {
          this.selectedOptionInd = "1";
        }
        else {
          this.selectedOptionInd = "0";
        }
        this.initAula();
        if (!this.aula.attestato) {
          this.aula.attestato = {
            id: -1,
            titolo: 'Nessuno'
          }
        }
      })
    }
    else {
      if (this.aula.start) {
        this.selectedOptionInd = "1";
      }
      else {
        this.selectedOptionInd = "0";
      }
      this.initAula();
    }
    this.primengConfig.ripple = true;

    if (this.aula && !this.aula.attestato)
      this.aula.attestato = {
        id: -1,
        titolo: 'Nessuno'
      }
  }

  initAula() {
    this.classService.lista_dettagli_classroom(this.aula.token, true).subscribe((res) => {
      this.caricato = true;
      this.currentTeachers = res.data.listaDocenti;
      this.currentStudents = res.data.listaStudenti;
      this.listaDettagli = this.aula.dettagli;
      this.disableButton = true;
    })
    if (this.selectedOptionInd == 1) {
      this.aulaModForm = this.formBuilder.group({
        nomeMod: [this.aula.nomeAula, [Validators.required, Validators.pattern(`^[^!?@|\/'"$£%=€\s][A-Ú a-ù0-9]+$`)]],
        descrizioneMod: [this.aula.descrizioneAula, Validators.required],
        dataA: [new Date(this.aula.end)],
        dataDa: [new Date(this.aula.start)],
        selectedOptionPrivate: [this.aula.isPrivate, Validators.required]
      });
    }
    else {
      this.aulaModForm = this.formBuilder.group({
        nomeMod: [this.aula.nomeAula, [Validators.required, Validators.pattern(`^[^!?@|\/'"$£%=€\s][A-Ú a-ù0-9]+$`)]],
        descrizioneMod: [this.aula.descrizioneAula, Validators.required],
        dataA: [''],
        dataDa: [''],
        selectedOptionPrivate: [this.aula.isPrivate],
      });
    }
    this.caricato = true;
  }

  //METODI PARTE MODIFICA
  get l() { return this.aulaModForm.controls }
  salvaAula() {
    this.loadingSave = true;
    this.submitted = true;
    this.currentTeachers.forEach((docente) => {
      this.listaDocentiId.push((docente.id ? docente.id : docente.utenteId))
    });
    this.currentStudents.forEach((studente) => {
      this.listaStudentiId.push((studente.id? studente.id : studente.utenteId))
    });
    if (this.listaDocentiId.length == 0) {
      this.loadingSave = false;
      this.alertService.error('Seleziona almeno un docente')
      return
    };
    this.loading = true;
    this.check = true;
    this.controlloValidators();
    if (this.check) {
      this.aula.nomeAula = this.aulaModForm.value.nomeMod.trim();
      this.aula.descrizioneAula = this.aulaModForm.value.descrizioneMod;
      this.aula.listaDocenti = this.listaDocentiId;
      this.aula.listaUtenti = this.listaStudentiId;
      this.aula.dettagli = this.listaDettagli;
      this.aula.isPrivate = this.aulaModForm.value.selectedOptionPrivate;
      if (this.selectedOptionInd == 1) {
        this.aula.start = this.aulaModForm.value.dataDa.toString();
        this.aula.end = this.aulaModForm.value.dataA.toString();
      }
      else {
        this.aula.start = null;
        this.aula.end = null;
      }
      let date = new Date();
      let idAula = this.aula.attestato.id
      if (this.aula.attestato) {
        if (this.aula.attestato.id !== -1) {
          this.aula.idAttestato = this.aula.attestato.id;
        } else {
          this.templateEdit = false;
          this.aula.attestato = null;
        }
      }
      this.classService.editAula(this.aula, idAula).subscribe((res) => {
        if (this.selectedFileCopertina) {
          const uploadFileCopertina = new FormData();
          uploadFileCopertina.append('imageFile', this.selectedFileCopertina, this.selectedFileCopertina.name)
          this.classService.modifica_img_afi(this.aula.id, uploadFileCopertina).subscribe((res) => {
            if (this.id) {
              setTimeout(() => {
                this.loadingSave = false;
                this.alertService.success(res.status.description);
                this.goBackLista();
              }, 7000);
            }
            else {
              setTimeout(() => {
                this.alertService.success(res.status.description);
                this.loadingSave = false;
                this.tornaLista.emit();
              }, 7000);
            }
          }, (error) => {
            this.loading = false;
            this.loadingSave = false;
          })
        } else {
          this.alertService.success(res.status.description);
          if (this.id) {
            this.loadingSave = false;
            this.goBackLista();
          }
          else {
            this.loadingSave = false;
            this.tornaLista.emit();
          }
        }
      }, (error) => {
        this.loading = false;
        this.loadingSave = false;
      })
    }
  }

  controlloValidators() {
    if (this.aulaModForm.invalid) {
      this.loadingSave = false;
      this.loading = false;
      this.check = false;
      return;
    }
    this.listaDettagli.forEach(dettaglio => {
      if (!dettaglio.attributo || !dettaglio.valore) {
        this.loadingSave = false;
        this.loading = false;
        this.check = false;
        return;
      }
    })
    if (this.selectedOptionInd == 1) {
      if (!this.aulaModForm.value.dataDa || !this.aulaModForm.value.dataA) {
        this.loadingSave = false;
        this.loading = false;
        this.check = false;
        return;
      }
      if (this.aulaModForm.value.dataDa >= this.aulaModForm.value.dataA) {
        this.loadingSave = false;
        this.loading = false;
        this.check = false;
        return;
      }
    }
  }

  eliminaDocente(docenteId, i) {
    this.docenteIndex = i;
    this.docenteId = docenteId
    this.cancellaDocente = true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaDocente = false;
        this.loading = false;
        this.confirmationService.close();
      }
    });
  }

  accettaCancellaDocente() {
    this.loading = true;
    this.currentTeachers.splice(this.docenteIndex, 1);
    this.cancellaDocente = false;
    this.loading = false;
    this.confirmationService.close();
  }

  aggiungiDocente() {
    this.modifica = false;
    this.addDocente = true;
    this.getListDocenti(0,10,{});
    this.selectedTeachersArray = [...this.currentTeachers];
  }

  //METODI PARTE ADD DOCENTE
  inserisciDocente() {
    if (!this.selectedTeachersArray || this.selectedTeachersArray.length < 1) {
      this.alertService.error('Seleziona almeno un docente')
      return
    }
    this.selectedTeachersArray.forEach(docente => {
      const idDocente = docente.id
      docente.id = idDocente;
      this.currentTeachers = [...this.selectedTeachersArray]
    });
    this.modifica = true;
    this.addDocente = false;
    this.selectedTeachersArray = [];
  }

  eliminaStudente(studenteId, i) {
    this.studenteIndex = i;
    this.studenteId = studenteId
    this.cancellaStudente = true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaStudente = false;
        this.loading = false;
        this.confirmationService.close();
      }
    });
  }

  accettaCancellaStudente() {
    this.loading = true;
    this.currentStudents.splice(this.studenteIndex, 1);
    this.cancellaStudente = false;
    this.loading = false;
    this.confirmationService.close();
  }

  aggiungiStudente() {
    this.modifica = false;
    this.addStudente = true;
    this.getListDocenti(0,10,{});
    this.selectedStudentsArray = [...this.currentStudents];
  }

  //METODI PARTE ADD STUDENTE
  inserisciStudente() {
    if (!this.selectedStudentsArray || this.selectedStudentsArray.length < 1) {
      this.alertService.error('Seleziona almeno uno studente')
      return
    }
    this.selectedStudentsArray.forEach(studente => {
      const idStudente = studente.id
      studente.id = idStudente;
      this.currentStudents = [...this.selectedStudentsArray]
    });
    this.modifica = true;
    this.addStudente = false;
    this.selectedStudentsArray = [];
  }

  goBack() {
    this.tornaLista.emit();
  }

  goBackLista() {
    this.routing.navigate(['./education'])
  }

  backToModifica() {
    this.addStudente = false;
    this.addDocente = false;
    this.modifica = true;
  }

  editDocente() {
    this.studenteEdit = false;
    this.templateEdit = false;
    this.docenteEdit = !this.docenteEdit;
  }

  editStudente() {
    this.docenteEdit = false;
    this.templateEdit = false;
    this.studenteEdit = !this.studenteEdit;
  }

  editNotifiche() {
    this.docenteEdit = false;
    this.templateEdit = false;
    this.studenteEdit = false;
    this.notificheEdit = !this.notificheEdit
  }

  editTemplate() {
    this.docenteEdit = false;
    this.studenteEdit = false;
    this.templateEdit = !this.templateEdit;
  }

  aggiungiDettaglio() {
    let dettaglio = {
      id: this.i,
      attributo: '',
      valore: ''
    }
    this.listaDettagli.push(dettaglio)
    this.i++
  }

  deleteDettaglio(dettaglio) {
    this.listaDettagli = this.listaDettagli.filter(x => x.id != dettaglio.id)
  }

  showTableInsegnante = true;
  chekAllInsegnante = false;
  checkAllInsegnante(evt) {
    this.showTableInsegnante = false;
    let appoggio = [];
    if (!this.chekAllInsegnante) {
      this.chekAllInsegnante = !this.chekAll;
      this.docenti.forEach((c) => {
        c.isSelected = true;
        this.docenti.forEach((element) => {
          if (c.id == element.id) {
            element.isSelected = true;
          }
        });
      });
    } else {
      this.chekAllInsegnante = !this.chekAllInsegnante;
      this.docenti.forEach((c) => {
        c.isSelected = false;
        this.docenti.forEach((element) => {
          if (c.id == element.id) {
            element.isSelected = false;
          }
        });
      });
    }
    this.showTable = true;
  }

  checkOneInsegnante(docente) {
  const index = this.selectedTeachersArray.findIndex((teacher) => (teacher.id ? teacher.id : teacher.utenteId) === docente.utenteId);
  if (index !== -1) {
    this.selectedTeachersArray.splice(index, 1);
  }
  else {
    this.selectedTeachersArray.push(docente);
  }
  }

  isCheckedTeachers(docente) {
    const index = this.currentTeachers.findIndex((teacher) => (teacher.id ? teacher.id : teacher.utenteId) === docente.utenteId);
    if (index !== -1) {
        return true;
    }
    else {
      return false;
    }
  }

  checkOneStudent(studente) {
    const index = this.selectedStudentsArray.findIndex((student) => (student.id ? student.id : student.utenteId) === studente.utenteId);
    if (index !== -1) {
      this.selectedStudentsArray.splice(index, 1);
    }
    else {
      this.selectedStudentsArray.push(studente);
    }
    }

    isCheckedStudents(studente) {
      const index = this.currentStudents.findIndex((student) => (student.id ? student.id : student.utenteId) === studente.utenteId);
      if (index !== -1) {
          return true;
      }
      else {
        return false;
      }
    }

  erroreIMG
  caricaCopertinaChanged(event) {
    switch (event.target.files[0].type) {
      case "image/png": {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      case "image/jpeg": {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      default: {
        this.alertService.error("Il file che stai caricando non ha un formato valido")
        this.selectedFileCopertina = null;
        break;
      }
    }
  }

  nomeFileCopertina;
  selectedFileCopertina: File = null;
  path;
  controlloValiditàImmagine(event) {
    this.selectedFileCopertina = null;
    this.nomeFileCopertina = event.target.files[0].name;
    this.selectedFileCopertina = event.target.files[0];
    if (event.target.files && this.selectedFileCopertina) {
      // Size Filter Bytes
      const max_size = 1000000;
      //const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 600;
      const max_width = 600;
      if (this.selectedFileCopertina.size > max_size) {
        this.alertService.warn('Peso massimo deve essere di 1MB')
        this.selectedFileCopertina = null;
        this.nomeFileCopertina = null;
        return true;
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFileCopertina)
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.path = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height !== max_height && img_width !== max_width) {
            this.alertService.warn('L\'immagine deve avere dimensioni 600x600')
            this.selectedFileCopertina = null;
            this.nomeFileCopertina = null;
            return true;
          }
        }
      }
    }
  }
  responsiveWidth() {
  }

  checkAllNotifiche() {
  }

//-------------------------------------------------------------------------------------------------------------------------------------------------//
  getListDocenti(page?, size?, filters?): void {
    this.refreshView = false;
    this.userService.getUtenti(page, size, filters).subscribe(
      (response: any) => {
        this.docenti = response.data.content;
        this.numeroMaxPagine = Number(response.data.totalPages);
        this.maxItems = response.data.totalElements;
        this.items = response.data.numberOfElements;
        this.isClickable = true;
        this.refreshView = true;
      },
      (error) => {
        console.error('Errore durante il recupero dei dati:', error);
      }
    );
  }

  openFiltersDocenti(): void {
    this.filtersVisible = !this.filtersVisible;
  }

  deleteFiltersDocenti(): void {
    this.removeFilters = true;
    this.filters = {};
    this.pagina = 0;
    this.getListDocenti(this.pagina, this.size, this.filters);
  }

  submitFiltersDocenti(): void {
    this.pagina = 0;
    this.formFilterComponent.onSubmitFilters(true);
    this.filtersVisible = false;
  }

  updateListDocenti(filtersObj: any): void {
    this.removeFilters = false;
    Object.keys(filtersObj).reduce((acc, k) => {
      if (!filtersObj[k]) {
        delete acc[k];
      }
      return acc;
    }, filtersObj);
    this.filters = { ...filtersObj };
    this.getListDocenti(this.pagina, this.size, this.filters);
  }

  cambiaPaginaDocenti(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginataDocenti(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginataDocenti(this.pagina, this.selectedValue, true)
    }
  }

  chiamataPaginataDocenti(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    this.size = quantità;
      this.subscriptionlistaTotaleDocenti = this.userService.getUtenti(pagina, quantità).subscribe({
      next: (res) => {
      if (attivo == true) {
        if (res?.data?.content?.length > 0) {
          this.docenti = res.data.content;
        } else {
          this.docenti = []
        }
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements;
        this.carica = true;
        this.caricato = true;
        this.isClickable = true;
        this.isClickableCancellati = true;
        this.paginatorCancellati = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.isSelectingValueCancellati = false;
        this.refreshView = true;
        this.showTable = true;
      }
    },
    error: (err) => {
      console.log(err)
    }})
  }

  cambiaValueDocenti() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginataDocenti(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginataDocenti(this.pagina, this.selectedValue, true)
    }
  }

  checkAll(evt, type?) {
    if (this.chekAllNotifications) {
      this.chekAllNotifications = false
      this.formNotifiche.setValue(
        {
          newsletters: false,
          social: false,
          whatsapp: false
        }
      )
    }
    else {
      this.chekAllNotifications = true
      this.formNotifiche.setValue(
        {
          newsletters: true,
          social: true,
          whatsapp: true
        }
      )
    }
  }
}
