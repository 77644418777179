import { ChatAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { DoCheck } from '@angular/core';
import { Messaggio } from 'src/app/MODELS/USER/messaggio';
import { Utente } from 'src/app/MODELS/USER/utente';
import { ServiceChat } from 'src/app/SERVICES/CHAT/chat.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';


export class NotificaGruppo extends ChatAdapter{
  keepMessageHistory = null;
  userId: string;
  isFirstTime= true;
  idDest: any;
  message: Message;
  idCanale: any[] = [];
  listaUtenti: Utente[];
  isPartecipantiClosedClicked=true;
  sendMessagioHistory = false;
  mockedParticipants: IChatParticipant[] = [];
  mockedParticipants2: IChatParticipant[] = [];
  partecipantiMostrati = [];
  utente: IChatParticipant;
  mockedPartecipantsId = [];
  listaMessaggi;
  messaggio: any[] = [];
  keepMessaggio: any[] = [];
  lista: ParticipantResponse[] = [];
  cronologia  = [];

  constructor(private serviceChat: ServiceChat, 
    private homeService: ShareService, 
    private accountStorage: AccountService) {
    super();
    this.homeService.listaPartecipanti$.subscribe((res) => this.mockedParticipants2 = res)
    this.homeService.messaggio$.subscribe((res) => {
      if(res){
        let utente: any = {};
        if(this.mockedParticipants2){
        utente = this.mockedParticipants2.find(x => x.id === res.fromId)
        }
        if(res.fromId !== this.accountStorage.userValue.data.id){
        this.onMessageReceived(utente, res);
      }
      
      }
    })
  }



  listFriends(): Observable<ParticipantResponse[]> {
    this.mockedParticipants = [];
    this.lista=[];
    this.partecipantiMostrati=[];
    return this.serviceChat.lista_utenti().pipe(map(
      res => {
        res.data.forEach(user => {
          this.utente = {
            id: user.id,
            avatar: user.avatar,
          status: user.status,
          participantType: 0,
          displayName: user.displayName,
          };
          // this.utente.id = user.id;
          // this.utente.avatar = user.avatar;
          // this.utente.status = user.status;
          // this.utente.participantType = 0;
          // this.utente.displayName = user.displayName;
          if (!(this.userId === user.id.toString())) {
            this.partecipantiMostrati.push(this.utente)
          }
          this.mockedParticipants.push(this.utente);
          this.mockedPartecipantsId.push(this.utente.id)
          this.listaUtenti = res.data;
        });
        for (var index of this.partecipantiMostrati) {
          let part = new ParticipantResponse();
          part.participant = index;
          part.metadata = null;
          this.lista.push(part);
        }
        let lista1 = [];
        let lista2 = [];
        this.lista.forEach(element => {
          if (element.participant.status === ChatParticipantStatus.Online) {
            lista1.push(element);
          } else {
            lista2.push(element);
          }

        })
        lista2.forEach(element => {
          lista1.push(element)
        })
        this.lista.forEach((element, index) => {
          element = lista1[index]
        })
        this.homeService.changeListaPartecipanti(this.mockedParticipants);
        return this.lista;
      },
      error => {
        let participantResponse = new ParticipantResponse();
        this.lista.push(participantResponse);

        return this.lista;
      }));
  }


  getMessageHistory(destinataryId: any): Observable<Message[]>{
    let mockedHistory: Message[] =[];
    let canale;
    if(this.accountStorage.userValue.data.id < destinataryId){
    canale = this.accountStorage.userValue.data.id+'-'+destinataryId
    }
    else {
    canale = destinataryId+'-'+this.accountStorage.userValue.data.id
    }
    return this.serviceChat.recupera_chat(canale).pipe(map(
      messages => { 
                   if(!messages.data){
                       mockedHistory = [];
                   } else {
                       messages.data.forEach(msg => {
                        let tempMsg :Message = new Message();
                        if(msg.idUtente.id === destinataryId){
                          tempMsg.fromId= destinataryId;
                          tempMsg.toId= this.accountStorage.userValue.data.id;
                        }
                        else {
                          tempMsg.toId= destinataryId;
                          tempMsg.fromId= this.accountStorage.userValue.data.id;
                        }
                        tempMsg.message= msg.messaggio;
                        tempMsg.dateSent = msg.dataMessaggio;
                        mockedHistory.push(tempMsg);
                       });
                   }
                   return mockedHistory;
               }));

  }
  

  sendMessage(message: Message): void {
    let messaggio: Messaggio = new Messaggio();
    let canale = '';
    if (Number(message.fromId) > Number(message.toId)) {
      canale = message.toId + '-' + message.fromId;
    } else {
      canale = message.fromId + '-' + message.toId;
    }
    messaggio.idUtente = message.fromId;
    messaggio.idDest = message.toId;
    messaggio.idCanale = canale;
    messaggio.messaggio = message.message;
    //FORMATO DATA
    messaggio.dataMessaggio = new Date().getTime();
    this.serviceChat.creazione_canale(messaggio.idUtente, messaggio.idDest).subscribe(res=> {
      this.serviceChat.manda_messaggio(messaggio).subscribe(res => {})
    })
  
  }


}
