import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { File as File2 } from 'src/app/MODELS/FILE/file';
import { Utente } from 'src/app/MODELS/USER/utente';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import * as JSZip from 'jszip';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService } from 'src/app/SERVICES/index';
import { UsersService } from 'src/app/SERVICES/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-edit-file',
  templateUrl: './add-edit-file.component.html',
  styleUrls: ['./add-edit-file.component.scss'],
})
export class AddEditFileComponent implements OnInit {
  @Input() folderId;
  @Input() fileId;
  @Output() tornaGestione = new EventEmitter<string>();
  @Input() ilCorso;
  secondoStep = false;
  corso = false;
  auleListSelected = [];
  listaClassi = [];
  caricaCorso = false;
  addAula = false;
  caricataLista = false;
  ruolo;
  addMode = false;
  editMode = false;
  infoMode = false;
  createForm: FormGroup;
  submitted = false;
  loading = false;
  listaRuoli = [];
  listaGruppi = [];
  listaUtenti = [];
  allListaUtenti = [];
  searchUtentiForm: FormGroup; //cerca in lista studenti
  selectedUtenti = [];
  opzioni: SelectItem[];
  selectedOption: string;
  opzioniInd: SelectItem[];
  selectedOptionInd: number;
  nomeRuolo;
  ruoli: SelectItem[];
  gruppi: SelectItem[];
  crea: boolean = false;
  nomeFile;
  selectedFile: File[] = null;
  utentiSelezionati = [];
  formConvalidato = false;
  showTable = true;
  chekAll = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private scormService: ScormService,
    private fileService: FileService,
    private accountService: AccountService,
    private classroomService: ClassroomService
  ) {
    this.ruolo = this.accountService.ruolo;
    this.opzioni = [
      { label: 'All', value: '0' },
      { label: 'Gruppi', value: '1' },
      { label: 'Profili', value: '2' },
      { label: 'Utenti', value: '3' },
    ];
    this.opzioniInd = [
      { label: 'Sì', value: '1' },
      { label: 'No', value: '0' },
    ];

    this.alertService.clear;
  }

  ngOnInit() {
    if (!this.fileId && !this.ilCorso) {
      this.addMode = true;
    }
    if (this.fileId) {
      this.editMode = true;
      this.secondoStep = true;
    }
    if (this.ilCorso) {
      this.scormService
        .lista_classroom_flaggate(this.ilCorso.id)
        .subscribe((res) => {
          res.data.forEach((element) => {
            this.auleListSelected.push(element.classroom.id);
          });
        });
      this.editMode = true;
      this.secondoStep = true;
      this.corso = true;
      this.crea = true;
    }
    this.alertService.openAlert(null);
    this.primengConfig.ripple = true;
    this.userService.getAllAttivi().subscribe((res) => {
      this.listaUtenti = res.data;
      this.listaUtenti.forEach((element) => {
        element.isSelected = false;
      });
      if (this.editMode && this.fileId) {
        if (this.fileId.visibUtente) {
          this.listaUtenti.forEach((element) => {
            this.fileId.visibUtente.forEach((id) => {
              if (element.id.toString() === id) {
                this.selectedUtenti.push(element);
                element.isSelected = true;
              }
            });
          });
        }
      }
      this.allListaUtenti = this.listaUtenti;
      this.crea = true;
    });
    this.userService.getListaGruppi().subscribe(
      (res) => {
        this.gruppi = [];
        this.listaGruppi = res.data;
        this.listaGruppi.forEach((gruppo) => {
          this.gruppi.push({ label: gruppo.nomeGruppo, value: '' + gruppo.id });
        });
      },
      (error) => {}
    );
    this.searchUtentiForm = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.createForm = this.formBuilder.group({
      titolo: ['', [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      autore: ['', [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      categoria: ['', [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      gruppo: ['', [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      visibRuolo: [''],
      visibGruppo: [''],
      // indicizza: ['', Validators.required]
    });

    this.userService.getListaRuoli().subscribe(
      (res) => {
        this.ruoli = [];
        this.listaRuoli = res.data;

        this.listaRuoli.forEach((ruolo) => {
          this.ruoli.push(
            { label: ruolo.nomeRuolo, value: '' + ruolo.id } //ruolo.id deve essere una stringa altrimenti non legge
          );
        });

        if (this.editMode && this.fileId) {
          // this.createForm = this.formBuilder.group({
          //   titolo: [this.fileId.title, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          //   autore: [this.fileId.authors[0]?.name, [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          //   categoria: [this.fileId.category, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          //   gruppo: [this.fileId.group, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          //   visibRuolo: [this.fileId.visibRuolo],
          //   visibGruppo: [this.fileId.visibGruppo],
          // });
          this.f.titolo.setValue(this.fileId.title);
          if (this.fileId.authors) {
            this.f.autore.setValue(this.fileId.authors[0]?.name);
          }
          this.f.categoria.setValue(this.fileId.category);
          this.f.gruppo.setValue(this.fileId.group);
          //se ruolo è null corrisponde ad all
          this.f.visibRuolo.setValue(
            this.fileId.visibRuolo == null ? [] : this.fileId.visibRuolo
          );
          this.f.visibGruppo.setValue(this.fileId.visibGruppo);
          if (this.fileId.visibGruppo) {
            this.selectedOption = '1';
          } else if (this.fileId.visibRuolo) {
            this.selectedOption = '2';
          } else if (this.fileId.visibUtente) {
            this.selectedOption = '3';
          } else {
            this.selectedOption = '0';
          }
        }
        if (this.editMode && this.ilCorso) {
          this.createForm = this.formBuilder.group({
            titolo: [
              this.ilCorso.nomeCorso,
              [
                Validators.required,
                Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
              ],
            ],
            autore: [
              this.ilCorso.autore,
              [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")],
            ],
            categoria: [
              this.ilCorso.categoria,
              [
                Validators.required,
                Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
              ],
            ],
            gruppo: ['', []],
          });
        }
      },
      (error) => {}
    );
  }

  get f() {
    return this.createForm.controls;
  }

  convalidaForm() {
    if (this.corso) {
      this.createForm.controls.gruppo.setValue('Corso');
    }
    if (this.createForm.invalid) {
      return;
    } else {
      this.formConvalidato = true;
    }
  }

  subscriptionList: Subscription = null;

  uploadList(uploadFile, index) {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    if (uploadFile.length > index) {
      this.subscriptionList = this.fileService
        .uploadDms(uploadFile[index])
        .subscribe(
          (res) => {
            this.loading = false;
            this.alertService.success(res.status.description);
            this.fileId = null;
            this.folderId = null;
            this.uploadList(uploadFile, index + 1);
          },
          (error) => {
            this.loading = false;
          }
        );
    } else {
      this.tornaGestione.emit('file');
    }
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.checkControll();
    if (!this.corso) {
      if (this.addMode) {
        const uploadFile: FormData[] = [];
        // let idUtenti = [];
        // this.selectedUtenti.forEach(element => {
        //   idUtenti.push(element.id)
        // })
        // let profiloRuolo = this.convertStringToNumber(this.createForm.value?.visibRuolo)
        // let profiloGruppo = this.convertStringToNumber(this.createForm.value?.visibGruppo)
        // let file = {
        // folder: this.folderId,
        // file: this.selectedFile
        //title: this.createForm.value.titolo,
        // autore: this.createForm.value.autore,
        // category: this.createForm.value.categoria,
        // group: this.createForm.value.gruppo,
        // visibGruppo: profiloGruppo.length==0 ? null:profiloGruppo,
        // visibilitàProfilo: profiloRuolo.length==0 ? null:profiloRuolo,
        // visibilitàUtenti: this.listaIdUtenti,
        // indicizza: this.selectedOptionInd,
        // owner: this.accountService.userValue.data.id.toString(),
        // }

        // if (!file.visibGruppo || this.selectedOption === '0') { file.visibGruppo = []; }
        // if (!file.visibilitàProfilo || this.selectedOption === '0') { file.visibilitàProfilo = []; }
        // if (!file.visibilitàUtenti || this.selectedOption === '0') { file.visibilitàUtenti = []; }
        if (this.selectedFile) {
          // console.log("FolderId: " + this.folderId);
          this.selectedFile.forEach((element, index) => {
            uploadFile.push(new FormData());
            uploadFile[index].append('folder', this.folderId);
            uploadFile[index].append('file', element);
          });
          // uploadFile.append('visibRuolo', JSON.stringify(file.visibilitàProfilo));
          // uploadFile.append('visibUtente', JSON.stringify(file.visibilitàUtenti));
          // uploadFile.append('category', file.category)
          // uploadFile.append('group', file.group)
          // uploadFile.append('owner', file.owner)
          // uploadFile.append('title', file.title)
          // uploadFile.append('author', file.autore)
          // uploadFile.append('index', file.indicizza?.toString())
        } else {
          this.loading = false;
          return;
        }
        // if (!this.selectedOptionInd) {
        //   this.loading = false;
        //   return;
        // }

        // if (this.createForm.invalid) {
        //   this.loading = false;
        //   return;
        // }
        // if (!this.selectedOption) {
        //   this.loading = false;
        //   return;
        // }
        // if (this.selectedOption === '3') {

        //   if (this.listaIdUtenti.length < 1) {
        //     this.loading = false;
        //     return;
        //   }
        // }
        // if (this.selectedOption === '1') {
        //   if (file?.visibGruppo.length < 1) {
        //     this.loading = false;
        //     return;
        //   }
        // }
        // if (this.selectedOption === '2') {
        //   if (file?.visibilitàProfilo.length < 1) {
        //     this.loading = false;
        //     return;
        //   }
        // }

        if (this.formConvalidato) {
          // console.log("Id folder: " + this.folderId);
          this.uploadList(uploadFile, 0);
        }
      }
      if (this.editMode) {
        // let idUtenti = [];
        // this.selectedUtenti.forEach(element => {
        //   idUtenti.push(element.id)
        // })

        this.fileId.title = this.createForm.value.titolo;
        let autore = {
          name: this.createForm.value.autore,
        };

        // if(this.createForm.value?.visibRuolo!=null){
        //   //controlliamo se è any è element o array
        //   if(isArray){
        //     selezionati=this.createForm.value?.visibRuolo as string[]
        //   }else{
        //     selezionati = this.createForm.value?.visibRuolo as string
        //   }
        // }
        // if(selezionati!=null && isArray){
        //   selezionati.forEach(ruoli=>{
        //     profiloRuolo.push(Number(ruoli))
        //   })
        // }else if(selezionati!=null && !isArray){
        //   profiloRuolo.push(Number(selezionati))
        // }
        let profiloRuolo = this.convertStringToNumber(
          this.createForm.value?.visibRuolo
        );
        let profiloGruppo = this.convertStringToNumber(
          this.createForm.value?.visibGruppo
        );

        this.fileId.authors = [];
        this.fileId.authors.push(autore);
        this.fileId.category = this.createForm.value.categoria;
        this.fileId.group = this.createForm.value.gruppo;
        this.fileId.visibGruppo =
          profiloGruppo.length == 0 ? null : profiloGruppo;
        this.fileId.visibRuolo = profiloRuolo.length == 0 ? null : profiloRuolo;
        this.fileId.visibUtente = this.listaIdUtenti;

        if (!this.fileId.visibGruppo || this.selectedOption === '0') {
          this.fileId.visibGruppo = [];
        }
        if (!this.fileId.visibRuolo || this.selectedOption === '0') {
          this.fileId.visibRuolo = [];
        }
        if (!this.fileId.visibUtente || this.selectedOption === '0') {
          this.fileId.visibUtente = [];
        }
        if (this.createForm.invalid) {
          this.loading = false;
          return;
        }
        if (this.selectedOption === '3') {
          if (this.listaIdUtenti.length < 1) {
            this.loading = false;
            return;
          }
        }
        if (this.selectedOption === '1') {
          if (this.fileId?.visibGruppo.length < 1) {
            this.loading = false;
            return;
          }
        }
        if (this.selectedOption === '2') {
          if (this.fileId?.visibRuolo.length < 1) {
            this.loading = false;
            return;
          }
        }
        this.fileService.edit(this.fileId).subscribe(
          (res) => {
            this.loading = false;
            this.alertService.success(res.status.description);
            this.fileId = null;
            this.tornaGestione.emit('file');
          },
          (error) => {
            this.loading = false;
          }
        );
      }
    } else {
      if (!this.editMode) {
        const uploadFile = new FormData();
        let corsoFileForm = {
          nomeCorso: this.createForm.value.titolo,
          autore: this.createForm.value.autore,
          categoria: this.createForm.value.categoria,
          gruppo: 'Corso',
        };
        if (this.selectedFile) {
          this.selectedFile.forEach((element) => {
            uploadFile.append('file', element, element.name);
          });
          uploadFile.append('categoria', corsoFileForm.categoria);
          uploadFile.append('gruppo', corsoFileForm.gruppo);
          uploadFile.append('nomeCorso', corsoFileForm.nomeCorso);
          uploadFile.append('autore', corsoFileForm.autore);
        }
        if (this.formConvalidato) {
          this.scormService
            .creaCorso(this.auleListSelected, uploadFile)
            .subscribe(
              (res) => {
                this.alertService.success(res.status.description);
                this.loading = false;
                this.tornaGestione.emit('corso');
              },
              (error) => {
                this.loading = false;
              }
            );
        } else {
          this.loading = false;
        }
      } else {
        let corso = {
          id: this.ilCorso.id,
          nomeCorso: this.createForm.value.titolo,
          autore: this.createForm.value.autore,
          categoria: this.createForm.value.categoria,
          gruppo: 'Corso',
        };
        if (this.formConvalidato) {
          this.scormService
            .modificaCorso(corso, this.auleListSelected)
            .subscribe(
              (res) => {
                this.alertService.success(res.status.description);
                this.loading = false;
                this.tornaGestione.emit('corso');
              },
              (error) => {
                this.loading = false;
              }
            );
        } else {
          this.loading = false;
        }
      }
    }
  }

  clear() {
    this.fileId = null;
    if (this.corso) {
      this.tornaGestione.emit('corso');
    } else {
      this.tornaGestione.emit('file');
    }
  }

  searchInUtenti() {
    let valore: string = this.searchUtentiForm.value.search;

    this.listaUtenti = this.allListaUtenti;

    if (valore) {
      let users = [];
      this.listaUtenti.forEach((user) => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;

        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!users.find((x) => x === user)) {
            users.push(user);
          }
        }
      });
      this.listaUtenti = users;
    } else if (!valore) {
      this.listaUtenti;
    }
  }

  public onFileChanged(event) {
    this.createForm.reset();
    this.selectedFile = [];
    this.nomeFile = event.target.files;
    for (let i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
    }
    this.corso = false;
    this.secondoStep = false;
    switch (event.target.files[0].type) {
      case 'application/x-zip-compressed': {
        this.cercaNelZip();
        break;
      }
      case 'application/x-zip-compressed': {
        this.cercaNelZip();
        break;
      }
      case 'application/zip': {
        this.cercaNelZip();
        break;
      }
      case 'multipart/x-zip': {
        this.cercaNelZip();
        break;
      }
      default: {
        this.secondoStep = true;
        break;
      }
    }
  }

  aggiungiAula(id) {
    if (this.auleListSelected.find((x) => x == id)) {
      this.auleListSelected.splice(
        this.auleListSelected.findIndex((x) => x == id),
        1
      );
    } else {
      this.auleListSelected.push(id);
    }
  }

  addlistAula() {
    this.addAula = true;

    this.caricataLista = true;
    this.classroomService.lista_classroom_leggera().subscribe(
      (res) => {
        this.listaClassi = res.data;
        if (this.auleListSelected.length > 0) {
          this.auleListSelected.forEach((element) => {
            this.listaClassi[
              this.listaClassi.findIndex((x) => x.id == element)
            ].selezionata = true;
          });
        }
        this.caricataLista = false;
      },
      (error) => {}
    );
  }

  cercaNelZip() {
    this.caricaCorso = true;
    let i = 1;
    let zipFileLenght;
    let trovato = false;

    this.selectedFile.forEach((element) => {
      JSZip.loadAsync(element).then((zip) => {
        // <----- HERE
        mimeType: 'application/octet-stream';
        zipFileLenght = Object.keys(zip.files).length;
        Object.keys(zip.files).forEach((filename) => {
          // <----- HERE
          if (
            (filename === 'imsmanifest.xml' && this.ruolo === 1) ||
            (filename === 'imsmanifest.xml' && this.ruolo === 4)
          ) {
            trovato = true;
            this.alertService.warn('Stai caricando un corso');
          }
          i++;
        });

        if (i >= zipFileLenght) {
          if (trovato) {
            this.corso = true;
          }
          this.caricaCorso = false;
          this.secondoStep = true;
        }
      });
    });
  }

  /**
   * A causa di un update di ngprime, i valori che arrivano al be, essendo stringhe
   * non vengono visti nel p-selectButton di ngprime.
   *
   * Inoltre visto che sono stringhe se inviate al BE ci troviamo in questa situazione:
   * mando "1", il BE salva in ""1"".
   *
   * Quindi prima di mandarli al BE conviene convertirli in numeri.
   * @param value del form
   * @returns un array di numeri
   */
  convertStringToNumber(value: any): number[] {
    let idValue: number[] = [];
    let isArray = Array.isArray(value);
    let selezionati: any = null;
    if (value != null) {
      //controlliamo se è any è element o array
      if (isArray) {
        selezionati = value as string[];
      } else {
        selezionati = value as string;
      }
    }
    if (selezionati != null && isArray) {
      selezionati.forEach((ruoli) => {
        idValue.push(Number(ruoli));
      });
    } else if (selezionati != null && !isArray) {
      idValue.push(Number(selezionati));
    }
    return idValue;
  }

  checkAll(evt) {
    this.showTable = false;
    let appoggio = [];
    if (!this.chekAll) {
      this.chekAll = true;
      this.listaUtenti.forEach((c) => {
        c.isSelected = true;
        this.allListaUtenti.forEach((element) => {
          if (c.id == element.id) {
            element.isSelected = true;
          }
        });
      });
    } else {
      this.chekAll = false;
      this.listaUtenti.forEach((c) => {
        c.isSelected = false;
        this.allListaUtenti.forEach((element) => {
          if (c.id == element.id) {
            element.isSelected = false;
          }
        });
      });
    }

    this.showTable = true;
  }
  checkOne(id) {
    let controllo = this.allListaUtenti.find(
      (x) => x.id.toString() === id.toString()
    ).isSelected;
    if (!controllo) {
      this.allListaUtenti.find(
        (x) => x.id.toString() === id.toString()
      ).isSelected = true;
      this.listaUtenti.find(
        (x) => x.id.toString() == id.toString()
      ).isSelected = true;
    } else {
      this.allListaUtenti.find(
        (x) => x.id.toString() === id.toString()
      ).isSelected = false;
      this.listaUtenti.find(
        (x) => x.id.toString() == id.toString()
      ).isSelected = false;
      if (this.chekAll) {
        this.chekAll = false;
      }
    }
    var counter = 0;
    this.allListaUtenti.forEach((element) => {
      if (element.isSelected == false) {
        counter++;
      }
    });
    if (counter == 0) {
      this.chekAll = true;
    } else {
      this.chekAll = false;
    }
  }
  listaIdUtenti = [];
  checkControll() {
    this.allListaUtenti.forEach((element) => {
      if (element.isSelected) {
        if (!this.listaIdUtenti.find((x) => x == element.id)) {
          this.listaIdUtenti.push(element.id);
        }
      }
    });
  }
}
