import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, Inject, LOCALE_ID, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { setHours, setMinutes } from 'date-fns';
import { link } from 'fs';
import { AlertService } from 'src/app/SERVICES';
import { PrenotazioneTecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/prenotazione.service';
import { TecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/tecnico.service';

@Component({
  selector: 'app-creazione-evento',
  templateUrl: './creazione-evento.component.html',
  styleUrls: ['./creazione-evento.component.scss']
})
export class CreazioneEventoComponent implements OnInit, AfterViewInit {
  @Input() selectedDate = new Date();
  @Input() modifica = false;
  @Input() eventoSelezionato;
  @Output() backEmit = new EventEmitter<any>();

  @ViewChild('stepper') private myStepper: MatStepper;
  inSede = null;
  submittedSede = false;
  submittedEsterno = false;
  formEventoSede;
  formEventoEsterno;
  listaForm;
  listaTecnici;
  copertinaRimossa = false;

  constructor(
    private formBuilder: FormBuilder,
    private prenotazioneService:PrenotazioneTecnicoService,
    private tecnicoService:TecnicoService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.formEventoSede = this.formBuilder.group({
      titoloEvento: ['',[ Validators.required , Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      oraInizio: ['',[ Validators.required]],
      oraFine: ['',[ Validators.required]],
      postiDisponibili: ['', [ Validators.required,Validators.pattern("^[0-9]*$"), Validators.min(1)]],
      //gadget:['', Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")],
      copertina: [''],
      descrizione: ['', Validators.required],
      formId: ['', Validators.required]
    });

    this.formEventoEsterno = this.formBuilder.group({
      titoloEvento: ['',[ Validators.required , Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      oraInizio: ['',[ Validators.required]],
      oraFine: ['',[ Validators.required]],
      tecnicoId: ['', [Validators.required]],
      formId: ['', Validators.required]
    });

    if(this.modifica)
    {
      this.popolaForm(this.eventoSelezionato);
    }
    this.prenotazioneService.listaTipologiaAll().subscribe((res)=>{
      this.listaForm = res.data;
    })

  }

  showTecnici = true;
  canGoToStep2(event) {
    this.showTecnici = false;
    if (event.selectedIndex == 1) {
      if(!this.fe.oraInizio.value || !this.fe.oraFine.value) {
        this.alertService.error('Inserisci degli orari');
        return;
      }
      let strDataInizio:any = '';
      let strDataFine:any = '';
      try {
        strDataInizio = this.selectedDate.getFullYear() + '-' + (this.selectedDate.getMonth() + 1) + '-' + this.selectedDate.getDate() + ' ' + this.fe.oraInizio.value +':00';
        strDataFine = this.selectedDate.getFullYear() + '-' + (this.selectedDate.getMonth() + 1) + '-' + this.selectedDate.getDate() + ' ' + this.fe.oraFine.value +':00';
      } catch (error) {
        for(let i = 0; i < 11; i++) {
          strDataInizio = strDataInizio + this.selectedDate[i];
          strDataFine = strDataFine + this.selectedDate[i];
        }
        strDataInizio = strDataInizio  + this.fe.oraInizio.value +':00'
        strDataFine = strDataFine  + this.fe.oraFine.value +':00'
      }
      this.tecnicoService.getTecniciDisponibili(strDataInizio, strDataFine).subscribe((res)=>{
        this.listaTecnici = res.data;
        if (this.modifica) {
          this.listaTecnici.push(this.tecnicoValue)
        }
        this.showTecnici = true;
      }, (error)=> {
        this.myStepper.previous();
      })
    }
  }

  indietro()
  {
    this.backEmit.emit();
  }

  get fs() {
    return this.formEventoSede.controls;
  }

  get fe()
  {
    return this.formEventoEsterno.controls;
  }

  formattaData(date)
  {
    return formatDate(date, "dd/MM/yyyy", this.locale);
  }

  copertinaCaricata(event)
  {
   this.fs.copertina.setValue(event.target.files[0]);
   this.copertinaRimossa = false;
  }

  changeSelect() {
    setTimeout(()=>{
      let element = document.getElementsByClassName('ql-formats')
      element[4].className = 'd-none';
    })
  }

  rimuoviCopertina()
  {
    this.fs.copertina.value = null;
    this.copertinaRimossa = true;
  }

  setTimeFromString(date, time) //Input: Data, Stringa (Formato stringa: HH:mm)
  {
      if(this.modifica)
      {
        const str = date;
        const dateComponents = str.split(' ')[0];
        const [month, day, year] = dateComponents.split('-');
        date = new Date(+year, +month - 1, +day);

      }
      let tmpData = setHours(date, time.split(":")[0]);
      tmpData = setMinutes(tmpData, time.split(":")[1]);
      tmpData.setSeconds(0)
      let dataOutput = formatDate(tmpData, "yyyy-MM-dd HH:mm:ss", this.locale);
      return dataOutput;
  }

  onSubmit(inSede:boolean)
  {
    //Crea

    if(!this.modifica){
      if(inSede)
      {
        this.submittedSede = true;
        if(this.fs.invalid)
        {
          return;
        }
        this.fs.oraInizio.value = this.fs.oraInizio.value[0] + this.fs.oraInizio.value[1] + this.fs.oraInizio.value[2] + this.fs.oraInizio.value[3] + this.roundNearest5(Number(this.fs.oraInizio.value[4]));
        this.fs.oraFine.value = this.fs.oraFine.value[0] + this.fs.oraFine.value[1] + this.fs.oraFine.value[2] + this.fs.oraFine.value[3] + this.roundNearest5(Number(this.fs.oraFine.value[4]));
        let payload = {
          nomeEvento: this.fs.titoloEvento.value,
          inizioEvento:  this.setTimeFromString(this.selectedDate, this.fs.oraInizio.value),
          fineEvento: this.setTimeFromString(this.selectedDate, this.fs.oraFine.value),
          sede: 1,
          formId: this.fs.formId.value,
          datiEventoInterno : {
            postiEvento: this.fs.postiDisponibili.value,
            bodyEvento: this.fs.descrizione.value,
          }
        }
        let copertina = this.fs.copertina.value;
        this.prenotazioneService.salvaEvento(payload, copertina).subscribe((res)=>{
          this.alertService.success(res.status.description)
          this.backEmit.emit();
        },
        (error)=>{
          // this.alertService.error(error.description)
        })
      }
      else
      {
        this.submittedEsterno = true;
        if(this.fe.invalid)
        {
          return;
        }
        this.fs.oraInizio.value = this.fs.oraInizio.value[0] + this.fs.oraInizio.value[1] + this.fs.oraInizio.value[2] + this.fs.oraInizio.value[3] + this.roundNearest5(Number(this.fs.oraInizio.value[4]));
        this.fs.oraFine.value = this.fs.oraFine.value[0] + this.fs.oraFine.value[1] + this.fs.oraFine.value[2] + this.fs.oraFine.value[3] + this.roundNearest5(Number(this.fs.oraFine.value[4]));
        let payload = {
          nomeEvento: this.fe.titoloEvento.value,
          inizioEvento:  this.setTimeFromString(this.selectedDate, this.fe.oraInizio.value),
          fineEvento: this.setTimeFromString(this.selectedDate, this.fe.oraFine.value),
          sede: 0,
          formId: this.fe.formId.value,
          datiEventoEsterno : {
            tecnicoId: this.fe.tecnicoId.value
          }
        }
        this.prenotazioneService.salvaEvento(payload, null).subscribe((res)=>{
          this.alertService.success(res.status.description)
          this.backEmit.emit()
        },
        (error)=>{
          this.alertService.error(error.description)
        })
      }
    }
    //Modifica

    if(this.modifica)
    {
      if(inSede)
      {
        let photoOperation;
        if(this.fs.copertina.value == this.eventoSelezionato.datiEventoInterno.linkPhoto)
        {
          photoOperation = undefined;
        }
        else if(this.fs.copertina.value != this.eventoSelezionato.datiEventoInterno.linkPhoto)
        {
          if(this.copertinaRimossa)
          {
            photoOperation = "PHOTO_DELETE";
          }
          else
          {
            photoOperation = "PHOTO_UPDATE"
          }
        }
        let payload = {
          photoOperation: photoOperation,
          nomeEvento: this.fs.titoloEvento.value,
          inizioEvento:  this.setTimeFromString(this.selectedDate, this.fs.oraInizio.value),
          fineEvento: this.setTimeFromString(this.selectedDate, this.fs.oraFine.value),
          sede: 1,
          formId: this.fs.formId.value,
          datiEventoInterno : {
            postiEvento: this.fs.postiDisponibili.value,
            bodyEvento: this.fs.descrizione.value,
          }
        }
        let copertina = this.fs.copertina.value;
        this.prenotazioneService.modificaEvento(payload, this.eventoSelezionato.id,copertina).subscribe((res)=>{
          this.alertService.success(res.status.description)
          this.backEmit.emit();
        });
      }
      else
      {
        let payload = {
          nomeEvento: this.fe.titoloEvento.value,
          inizioEvento:  this.setTimeFromString(this.selectedDate, this.fe.oraInizio.value),
          fineEvento: this.setTimeFromString(this.selectedDate, this.fe.oraFine.value),
          sede: 0,
          formId: this.fe.formId.value,
          datiEventoEsterno : {
            tecnicoId: this.fe.tecnicoId.value
          }
        }
        let copertina = this.fs.copertina.value;
        this.prenotazioneService.modificaEvento(payload, this.eventoSelezionato.id,copertina).subscribe((res)=>{
          this.alertService.success(res.status.description)
          this.backEmit.emit();
        });
      }
    }
  }
  tecnicoValue;
  popolaForm(eventoSelezionato)
  {
    this.selectedDate = eventoSelezionato.dataInizio;
    //console.log("Data ricevuta: " + this.selectedDate);
    if(eventoSelezionato.sede === 1)
    {
      this.inSede = true;
      this.fs.titoloEvento.setValue(eventoSelezionato.nomeEvento);
      this.fs.postiDisponibili.setValue(eventoSelezionato.datiEventoInterno.posti);
      this.fs.oraInizio.setValue(this.datePipe.transform(eventoSelezionato.dataInizio, 'HH:mm'));
      this.fs.oraFine.setValue(this.datePipe.transform(eventoSelezionato.dataFine, 'HH:mm'));
      this.fs.formId.setValue(eventoSelezionato.tipologia.id);
      this.fs.descrizione.setValue(eventoSelezionato.datiEventoInterno.body.toString());
      this.fs.copertina.setValue(eventoSelezionato.datiEventoInterno.linkPhoto);
    }
    if(eventoSelezionato.sede === 0)
    {
      this.inSede = false;
      this.fe.titoloEvento.setValue(eventoSelezionato.nomeEvento);
      this.fe.oraInizio.setValue(this.datePipe.transform(eventoSelezionato.dataInizio, 'HH:mm'));
      this.fe.oraFine.setValue(this.datePipe.transform(eventoSelezionato.dataFine, 'HH:mm'));
      this.tecnicoValue = eventoSelezionato.tecnico;
      // this.listaTecnici.forEach((element)=>{
      //   if (eventoSelezionato.tecnico.id == element.id)
      //     this.tecnicoValue = element;
      // })
      this.fe.tecnicoId.setValue(eventoSelezionato.tecnico.id);
      this.fe.formId.setValue(eventoSelezionato.tipologia.id);
    }
  }

  roundNearest5(num) {
    return Math.round(num / 5) * 5;
  }
}
