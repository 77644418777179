<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12 ">
        <div class="card">
          <div class="card-content">
            <div *ngIf="firstView && !showForm && showCalendar && !showModifica && !showCreazioneEventi" style="padding: 20px">
              <div *ngIf="ruolo === 1" style="text-align:right;">
                <div class="btn btn-primary btn-round" (click)="changeMode()" style="padding: 8px 8px 8px 8px ; margin-right: 35px;">
                  <i *ngIf="!addMode" class="material-icons notranslate" title="Crea nuovi eventi">add</i>
                  <i *ngIf="addMode" class="material-icons notranslate" title="Visualizza dettagli eventi">format_list_bulleted</i>
                </div>
              </div>
              <div class="row text-center">
                <div class="flex" [ngClass]="{'col-xs-4 ': isDesktop, 'col-xs-12': !isDesktop }">
                  <div class="flip-card flex" *ngIf="ruolo === 1">
                    <button id="btn-responsive" class="btn-repeat btn-oggi" (click)="animation = !animation"><i class="material-icons notranslate">restart_alt</i></button>
                    <div class="flip-card-inner"  [ngClass]="{'t180': animation, 'w-init':!isDesktop}">
                      <div class="flip-card-front">
                        <div id="btn-responsive" class="btn  btn-primary btn-oggi" [ngClass]="{'btn-h': !isDesktop, 'p-none':animation}"  (click)="creaForm()">
                          Crea Tipologia
                        </div>
                      </div>
                      <div class="flip-card-back">
                        <div id="btn-responsive" class="btn  btn-primary btn-oggi" [ngClass]="{'btn-h': !isDesktop, 'p-none':!animation}" (click)="creaForm(true)">
                          Elimina Tipologia
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="btn btn-primary btn-arrow-left" id="btn-responsive" mwlCalendarPreviousView
                      [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><span
                        class="arrows">
                    <     </span>
                          <!-- <i class="material-icons notranslate"  style="font-size: 16px;">navigate_before</i> -->
                    </div>
                    <div id="btn-responsive" class="btn  btn-primary btn-oggi" mwlCalendarToday [(viewDate)]="viewDate">
                      Oggi
                    </div>
                    <div id="btn-responsive" class="btn btn-primary btn-arrow-right" mwlCalendarNextView [view]="view"
                      [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><span class="arrows">></span>
                      <!-- <i class="material-icons notranslate" style="font-size: 15px;">navigate_next</i> -->
                    </div>
                  </div>
                </div>
                <div [ngClass]="{'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }">
                  <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'it' | titlecase}}</h3>
                </div>
                <div [ngClass]="{'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }">
                  <div class="btn-group">
                    <div class="btn btn-primary " id="btn-responsive" (click)="setView(CalendarView.Month)"
                      [class.active]="view === CalendarView.Month">
                      Mese
                    </div>
                    <div class="btn btn-primary " id="btn-responsive" (click)="setView(CalendarView.Week)"
                      [class.active]="view === CalendarView.Week">
                      Settimana
                    </div>
                    <div class="btn btn-primary " id="btn-responsive" (click)="setView(CalendarView.Day)"
                      [class.active]="view === CalendarView.Day">
                      Giorno
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div [ngSwitch]="view">
                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                  [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                  (eventTimesChanged)="eventTimesChanged($event)" locale="it">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
                  [refresh]="refresh" locale="it" (eventTimesChanged)="eventTimesChanged($event)" (hourSegmentClicked)="dayClicked($event)"
                  [dayStartHour]="costanteInizio">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                  [refresh]="refresh" locale="it" (eventTimesChanged)="eventTimesChanged($event)" (hourSegmentClicked)="dayClicked($event)"
                  [dayStartHour]="costanteInizio">
                </mwl-calendar-day-view>
              </div>
            </div>

            <div *ngIf="showForm && !showModifica">
              <app-form-creator-component [isFromElimina]="isFromElimina" (backShowForm)="creaForm()"></app-form-creator-component>
            </div>
            <div *ngIf="showModifica && !showForm">
              <app-visualizzazione-form [id]="idModifica" (backEmit)="backModifica()"></app-visualizzazione-form>
            </div>
            <div *ngIf="showCreazioneEventi">
              <app-creazione-evento [selectedDate]="dataSelezionata" (backEmit)="backCreazioneEvento()"></app-creazione-evento>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
