import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'secondi',
    pure: false
})
export class TrasformatoreSecondi implements PipeTransform {

    transform(value: number, args?: any): any {
        let risultato
       
        if (value) {
          //  value=value/1000
            var ore = '' + Math.floor(value / 3600);
            var minuti = '' + Math.floor(value % 3600 / 60);
            var secondi = '' + Math.floor(value % 3600 % 60);
           
            if (Number(ore) < 10) {
              ore = '0' + ore;
            } else {
              ore = '' + ore;
            }
            if (Number(minuti) < 10) {
              minuti = '0' + minuti;
            } else {
              minuti = '' + minuti;
            }
            if (Number(secondi) < 10) {
              secondi = '0' + secondi;
            } else {
              secondi = '' + secondi;
            }
          
            risultato = ore + ':' + minuti + ':' + secondi;
        }
        return risultato;
    }

}

