import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AlertService, EnvService, ShareService } from '../SERVICES';
import { AccountService } from '../SERVICES/index';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  comingFromeHere=false;
    constructor(
        private router: Router,
        private accountService: AccountService,
        private shareService: ShareService,
        private env :EnvService,
        private alertService: AlertService,
    ) {

     }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        const user2 = localStorage.getItem('user');
        if(localStorage.getItem('versione') !== 'mobileVersion') {
            if(this.env.versione !== localStorage.getItem('versione')) {
                localStorage.clear();
                localStorage.setItem('versione', this.env.versione)
                this.alertService.info("Nuova versione disponibile. Effettua nuovamente l'accesso.")
                this.alertService.isComingFromLogin = true;
            }
        }else {
              try {
                if(!this.comingFromeHere) {
                  this.router.navigateByUrl(localStorage.getItem('path'));
                  this.comingFromeHere = true;
                }
              } catch (error) {

              }
            }
        if (user2) {

            this.shareService.changeIsLoggedIn(true);
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.shareService.changeIsLoggedIn(false);

        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
