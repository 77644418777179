<div class="content">
    <div class="container-fluid">
      <ng-container>
        <div class="col-xs-12">
            <div class="card-content" class="text-center" style="margin-bottom: 3%;">
            <h4 style="margin-bottom: 3%;">Seleziona la data</h4>
            <form [formGroup]="calendar" class="box-model form-support-margin overflowAuto">
                <p-calendar view="date" formControlName="calendario" (onSelect)="selezionaData()" [maxDate]="today"
                  [readonlyInput]="true" [numberOfMonths]="2" yearRange="2000:2030" [(ngModel)]="rangeDates"
                  [showOtherMonths]="false" placeholder="Seleziona un periodo di tempo" selectionMode="range" [inline]="true"
                  dateFormat="dd/mm/yy" [locale]="ita">

                </p-calendar>
                <div class="card-footer text-right">
                  <button [disabled]="loading" class="btn btn-primary" rel="tooltip" title="Scarica Report" (click)="inviaDati()"
                   style="max-width: 2%;"><i class="fa fa-folder"><span *ngIf="loading">
                        <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                          styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                      </span></i>

                  </button>
                </div>
              </form>
            </div>
    </div>
