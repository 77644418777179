import {
  Component,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  DoCheck,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { AccountService, AlertService, ShareService } from 'src/app/SERVICES';
import { UsersService } from 'src/app/SERVICES/index';
import { MatDialog } from '@angular/material/dialog';

import { Utente } from '../../../MODELS/USER/utente';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { Router } from '@angular/router';
import { Pageable } from 'src/app/SERVICES/GENERAL/pageable';
import { Subscription } from 'rxjs';
import { TecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/tecnico.service';

@Component({
  selector: 'app-gestione-tecnici',
  templateUrl: './gestione-tecnici.component.html',
  styleUrls: ['./gestione-tecnici.component.scss'],
  providers: [ConfirmationService],
})
export class GestioneTecniciComponent implements OnInit, DoCheck {
  searchform: FormGroup;
  searchformCancellati: FormGroup;
  edituser;
  edituserID;
  selectedValue;
  selectedValueCancellati;
  utente: Utente;
  pagina;
  paginaCancellati;
  mostraInfo: boolean = false;
  abilita = true;
  informazioni = false;
  idUtente;
  cancellaUser = false;
  cliccato = true;
  carica;
  showTable = true;
  appoggioclick;
  appoggioclickCancellati;
  pagine = [];
  pagineCancellati = [];
  dettaglio = [];
  idRipristina;
  loading = false;
  gruppos = [];
  infouser;
  listautenti: Utente[];
  utentiCancellati: Utente[];
  allUtentiCancellati: Utente[];
  allListautenti: Utente[];
  gestione = true;
  dettagli = false;
  modifica = false;
  msgs: Message[] = [];
  vediCancellati = false;
  vediUtenti = true;
  family = false;
  utentefamily;
  ioT;
  numeroMaxPagine: number;
  numeroMaxPagineCancellate: number;
  isClickable = true;
  isClickableCancellati = true;
  subscriptionListaUtenti: Subscription;
  paginatorCancellati = true;
  paginator = true;
  maxItems;
  maxItemsCancellati;
  isSearchingOn = false;
  items;
  itemsCancellati;
  isSelectingValue = false;
  isSelectingValueCancellati = false;
  refreshView = true;

  @Output() vaiModificaUtente = new EventEmitter<string>();
  @Output() vediDettagliUtente = new EventEmitter<string>();
  caricato: boolean = false;
  constructor(
    private userService: UsersService,
    private tecnicoService: TecnicoService,
    private alertService: AlertService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private envService: EnvService,
    private routing: Router
  ) {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
    this.pagina = 0;
    this.paginaCancellati = 0;
    this.selectedValue = 10; //tecnici da visualizzare per pagina
    this.selectedValueCancellati = 10;
    this.chiamataPaginata(this.pagina, this.selectedValue, true);
    this.chiamataPaginata(this.pagina, this.selectedValueCancellati, false);
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.searchformCancellati = this.formBuilder.group({
      searchCancellati: ['', Validators.required],
    });
  }
  ngDoCheck(): void {
    if (this.accountService.listaEnv && this.accountService.ruolo !== 1) {
      this.routing.navigate(['./']);
    }
  }
  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    // console.log("Attivo = " + attivo);
    if (attivo) {
      this.subscriptionListaUtenti = this.tecnicoService
        .getAll(quantità, pagina, ricerca)
        .subscribe((res) => {
          this.appoggioclick = 1;
          this.appoggioclickCancellati = 1;
          if (attivo == true) {
            // console.log(res.data.content);
            if (res.data.content.length > 0) {
              this.listautenti = res.data.content;
            } else {
              this.listautenti = [];
            }
            this.allListautenti = this.listautenti;
            this.numeroMaxPagine = Number(res.data.totalPages);
            this.maxItems = res.data.totalElements;
            this.items = res.data.numberOfElements;

            this.carica = true;
            this.caricato = true;
            this.isClickable = true;
            this.isClickableCancellati = true;
            this.paginatorCancellati = true;
            this.paginator = true;
            this.isSearchingOn = false;
            this.isSelectingValue = false;
            this.isSelectingValueCancellati = false;
            this.refreshView = true;
            this.showTable = true;
          }
        });
    } else {
      //cestino
      this.tecnicoService
        .getAllEliminati(quantità, pagina, ricerca)
        .subscribe((res) => {
          if (res.data.content.length > 0) {
            this.utentiCancellati = res.data.content;
          } else {
            this.utentiCancellati = [];
          }
          this.allUtentiCancellati = this.utentiCancellati;
          this.numeroMaxPagineCancellate = Number(res.data.totalPages);
          this.maxItemsCancellati = res.data.totalElements;
          this.itemsCancellati = res.data.numberOfElements;

          this.carica = true;
          this.caricato = true;
          this.isClickable = true;
          this.isClickableCancellati = true;
          this.paginatorCancellati = true;
          this.paginator = true;
          this.isSearchingOn = false;
          this.isSelectingValue = false;
          this.isSelectingValueCancellati = false;
          this.refreshView = true;
          this.showTable = true;
        });
    }
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(
        this.pagina,
        this.selectedValue,
        true,
        this.valoreRicerca
      );
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true);
    }
  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(
        this.pagina,
        this.selectedValue,
        true,
        this.valoreRicerca
      );
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true);
    }
  }

  cambiaPaginaCancellati(event) {
    this.isClickableCancellati = false;
    this.paginaCancellati = event;
    this.paginator = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(
        this.paginaCancellati,
        this.selectedValueCancellati,
        false,
        this.valoreRicerca
      );
    } else {
      this.chiamataPaginata(
        this.paginaCancellati,
        this.selectedValueCancellati,
        false
      );
    }
  }

  cambiaValueCancellati() {
    this.paginaCancellati = 0;
    this.isClickableCancellati = false;
    this.paginator = false;
    this.isSelectingValueCancellati = true;
    this.refreshView = false;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(
        this.paginaCancellati,
        this.selectedValueCancellati,
        false,
        this.valoreRicerca
      );
    } else {
      this.chiamataPaginata(
        this.paginaCancellati,
        this.selectedValueCancellati,
        false
      );
    }
  }
  valoreRicerca;
  searchInUser(event) {
    this.valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionListaUtenti) {
      this.subscriptionListaUtenti.unsubscribe();
    }
    this.isSearchingOn = true;
    if (event) {
      this.valoreRicerca = this.searchform.value.search;
    } else {
      this.valoreRicerca = this.searchformCancellati.value.searchCancellati;
    }
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(
        this.pagina,
        this.selectedValue,
        event,
        this.valoreRicerca
      );
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, event);
    }
  }

  cancella(id) {
    this.cancellaUser = true;
    this.idUtente = id;
    this.confirmationService.confirm({
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaUser = false;
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }

  //METODO PER PASSARE DALLA GESTIONE UTENTI AL VISUALIZZA INFO DI UN UTENTE
  visualizza(id: string) {
    this.infouser = id;
    this.gestione = false;
    this.dettagli = true;
  }

  vediInfo(id) {
    this.idUtente = id;
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }

  indietroDainfo(vai) {
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }

  onVoted(agreed: boolean) {
    agreed ? (this.informazioni = false) : (this.informazioni = true);
  }

  editUtente(id?) {
    this.idUtente = id;
    this.gestione = false;
    this.modifica = true;
  }

  chiudiRipristina() {
    this.confirmationService.close();
    this.loading = false;
  }

  ripristina(id, indice) {
    this.idRipristina = id;
    this.confirmationService.confirm({
      message: "Ripristinare l'utente?",
      key: 'ripristino',
      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.confirmationService.close();
        this.loading = false;
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      },
    });
  }

  listaCancellati() {
    this.vediUtenti = false;
    this.vediCancellati = true;
  }

  listaPresenti() {
    this.vediUtenti = true;
    this.vediCancellati = false;
  }

  accettaCancella() {
    this.paginator = false;
    this.valoreRicerca = '';
    this.searchform.setValue({ search: '' });
    this.paginatorCancellati = false;
    this.tecnicoService.deleteTecnico(this.idUtente, true).subscribe((res1) => {
      if (this.listautenti.length === 1) {
        this.refreshView = false;
      }
      if (this.listautenti.length == 0) {
        if (this.pagina == 1) {
          this.pagina = this.pagina - 1;
          this.chiamataPaginata(this.pagina, this.selectedValue, true);
        } else {
          this.chiamataPaginata(this.pagina, this.selectedValue, true);
        }
      } else {
        this.chiamataPaginata(this.pagina, this.selectedValue, true);
      }
      if (this.utentiCancellati.length == 0) {
        if (this.paginaCancellati == 1) {
          this.paginaCancellati = this.paginaCancellati - 1;
          this.chiamataPaginata(
            this.paginaCancellati,
            this.selectedValueCancellati,
            false
          );
        } else {
          this.chiamataPaginata(
            this.paginaCancellati,
            this.selectedValueCancellati,
            false
          );
        }
      } else {
        this.chiamataPaginata(
          this.paginaCancellati,
          this.selectedValueCancellati,
          false
        );
      }
      this.confirmationService.close();
      this.alertService.success(res1.status.description);
      this.loading = false;
    });
  }

  accettaRipristina() {
    this.valoreRicerca = '';
    this.searchformCancellati.setValue({ searchCancellati: '' });
    this.tecnicoService
      .deleteTecnico(this.idRipristina, false)
      .subscribe((res) => {
        this.chiamataPaginata(this.paginaCancellati, this.selectedValue, false);
        this.chiamataPaginata(this.pagina, this.selectedValue, true);
        this.confirmationService.close();
        this.alertService.success(res.status.description);
        this.loading = false;
      });
  }
  responsiveWidth() {}

  aggiungiTecnico() {
    this.idUtente = null;
    this.gestione = false;
    this.modifica = true;
  }

  indietro(vai) {
    // console.log("Gestione tecnici: torna a gestione");
    this.chiamataPaginata(this.pagina, this.selectedValue, true);
    this.gestione = true;
    this.modifica = false;
  }
}
