import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "../GENERAL/env.service";

@Injectable({
  providedIn: 'root'
})
export class WhiteBoardService {
  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }


  saveImage(image, idAula): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/salva_img_lavagna?idAula=' + idAula, image);
  }

  getImage(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/recupero_img_lavagna?idAula=' + idAula);
  }

  saveLavagna(image, idAula): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/salva_dati_lavagna?idAula=' + idAula, image);
  }

  getLavagna(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/recupero_dati_lavagna?idAula=' + idAula);
  }

  getStatus(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lavagna_esistente?idAula=' + idAula);
  }

  deleteLavagna(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/elimina_lavagna?idAula=' + idAula);
  }
}
