

export class ScormInvio {
        idCorso:any;
        idUtente:any;
        idModulo:any;
        statoModulo:any;
        aulaId:any;
        tempo:any;
        recuperoPercorso:any;
        voto:any;
   
}