<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <ng-container *ngIf="modifica">

                        <div class="card-header card-header-text">
                            <h4 class="card-title">Modifica Utente</h4>
                        </div>
                        <div class="card-content">
                            <form [formGroup]="editForm" class="form-horizontal">
                                <div class="row">
                                    <label class="col-sm-3 label-on-left">Nome<span class="colorRed"> *</span></label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" class="form-control" placeholder="Nome"
                                                formControlName="nome"
                                                 maxlength="30">
                                            <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                                <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
                                                <div *ngIf="f.nome.errors.pattern">Campo nome invalido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-sm-3 label-on-left">Cognome<span class="colorRed"> *</span></label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" class="form-control" placeholder="Cognome"
                                                formControlName="cognome"
                                                [ngClass]="{ 'is-invalid': submitted && f.cognome.errors }"
                                                maxlength="30" />
                                            <div *ngIf="submitted && f.cognome.errors" class="invalid-feedback">
                                                <div *ngIf="f.cognome.errors.required">Campo cognome obbligatorio</div>
                                                <div *ngIf="f.cognome.errors.pattern">Campo cognome invalido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-sm-3 label-on-left">Email<span class="colorRed"> *</span></label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="email" class="form-control" placeholder="Email"
                                                formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                maxlength="50">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.email">Formato email non corretto</div>
                                                <div *ngIf="f.email.errors.required">Campo email obbligatorio</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-sm-3 label-on-left">Recapito Telefonico</label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" class="form-control" pKeyFilter="pint"
                                                placeholder="Recapito Telefonico" formControlName="recapito"
                                                [ngClass]="{ 'is-invalid': submitted && f.recapito.errors }"
                                                maxlength="11">

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-sm-3 label-on-left">Dettagli Aggiuntivi</label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" class="form-control" placeholder="Descrizione"
                                                formControlName="descrizione" maxlength="99">

                                        </div>
                                    </div>
                                </div>
                                <!-- <br>
                                &nbsp; &nbsp; -->
                                <div class="row" *ngIf="ruoli">
                                    <label class="col-sm-3 label-on-left">Profilo<span class="colorRed"> *</span></label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">

                                            <p-selectButton [options]="ruoli" formControlName="ruolo"></p-selectButton>

                                            <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback-2">
                                                <div *ngIf="f.ruolo.errors.required">Campo ruolo obbligatorio</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="gruppi.length > 0" class="row">
                                    <label class="col-sm-3 label-on-left">Gruppo</label>
                                    <div class="col-sm-9">
                                        <div class="form-group label-floating is-empty">

                                            <p-selectButton [options]="gruppi" formControlName="gruppo"
                                                multiple="multiple"></p-selectButton>

                                            <!-- <div *ngIf="submitted && f.gruppo.errors" class="invalid-feedback-2">
                                  <div *ngIf="f.gruppo.errors.required">Campo gruppo obbligatorio</div>
                              </div> -->
                                        </div>
                                    </div>
                                </div>
                      
                                    <div class="row">
                                        <label class="col-sm-3 label-on-left" >Ruolo</label>
                                        <div class="col-sm-9">
                                            <div class="form-group label-floating is-empty">
                                                <label class="control-label"></label>
                                                <select formControlName="tag" class="form-control">
                                                    <option value="">-</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="AM">AM</option>
                                                    <option value="FSM">FSM</option>
                                                    <option value="ISF">ISF</option>
                                                    <option value="SP">SP</option>
                                                </select>
                                              </div>
                                        </div>
                                    </div>
                                    
                                
                            </form>

                            <br>


                            <div class="text-right" style="margin-right: 5%;">
                                <hr>
                                <div class="btn-group"  aria-label="Basic example">

                                    <a (click)="indietro(true)"><button type="button" class="btn btn-secondary"
                                            aria-pressed="true">Indietro</button></a>
                                </div>
                                <div class="btn-group"  aria-label="Basic example">

                                    <a (click)="cancella()"><button type="button" class="btn btn-secondary"
                                            aria-pressed="true">Cancella</button></a>
                                </div>
                                <div class="btn-group" type="button" aria-label="Basic example" aria-pressed="true">
                                    <button [disabled]="loading" (click)="onEdit()" class="btn btn-primary">Salva
                                        <span *ngIf="loading">
                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <br>
                        </div>

                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
