import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { GoToClassroomService } from 'src/app/SERVICES/CLASSROOM/go-to-classroom.service';

@Component({
  selector: 'app-access-token-go-to',
  templateUrl: './access-token-go-to.component.html',
  styleUrls: ['./access-token-go-to.component.scss'],
})
export class AccessTokenGoToComponent implements AfterViewInit {
  isLoading = true;

  constructor(
    private service: GoToClassroomService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    setTimeout(()=>{
      try{
        this.retrieveCode();
      }catch(e){
        console.error(e)
      }
    },5000)
  }

  retrieveCode() {
    //3 Passo
    //recupero il code inserito nel navigate che si trova in app.component
    const code = this.route.snapshot.params['code'];
    //è il redirectURI
    const redirecURI = `http://` + location.host + `/access_token_go_to`;
    //this.service.redirectURI contiene ancora quello locale FE
    this.service
      .setToken(code, redirecURI)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe(
        //5 Passo è ultimo
        () => {
          close();
        },
        (err) => {
          console.error(err);
          close();
        }
      );
  }
}
