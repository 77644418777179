import {Component, Input, OnInit, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UsersService} from '../../../../SERVICES';

@Component({
  selector: 'app-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss']
})
export class FormFilterComponent implements OnInit {

  doctorsFiltersForm: FormGroup;
  @Input() filtersVisible: any;
  @Input() showTag = false;
  @Input() removeFilters: any;
  @Output() emitFilters = new EventEmitter<any>();
  @Output() submitFilters = new EventEmitter<void>();
  listaMicroArea: any = [];
  listaIsf: any = [];
  listaProvincia: any = [];
  listaLocalita: any = [];
  listaCarica: any = [];
  listaAttivita: any = [];
  listaTargetA: any = [];
  listaTargetB: any = [];
  listaSpecialita: any = [];
  listaProfilo: any = [];
  listaGruppi: any = [];
  listaTag: string[] = ["Admin", "AM", "FSM", "ISF", "SP"];

  constructor(
    private formBuilder: FormBuilder,
    private service: UsersService
  ) { }

  ngOnInit(): void {
    this.initFormFilter();
    this.populateSelect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['removeFilters'] && this.removeFilters) {
      this.doctorsFiltersForm.reset();
    }
  }

  initFormFilter() {
    this.doctorsFiltersForm = this.formBuilder.group({
      nome: [''],
      cognome: [''],
      data_di_nascita: [''],
      micro_area: [''],
      isf: [''],
      indirizzo_princ_di_visita: [''],
      cap: [''],
      provincia: [''],
      localita: [''],
      carica: [''],
      attivita: [''],
      numero_iscrizione_albo: [''],
      flag_pubblico_privato: [''],
      ragione_sociale_struttura: [''],
      codice_fiscale: [''],
      partita_iva: [''],
      email_pubblica: [''],
      email_privata: [''],
      email_amministrazione: [''],
      email_ufficio_formazione: [''],
      email_primario_reparto: [''],
      profilo: [''],
      target_a: [''],
      target_b: [''],
      specialita: [''],
      consenso_privacy_a: [''],
      consenso_privacy_b: [''],
      consenso_privacy_c: [''],
      consenso_privacy_d: [''],
      gruppoId: [''],
      tag: [''],
    });
  }

  populateSelect(){

    this.service.populateSelectFormFilter('micro-area').subscribe((res) => {
      this.listaMicroArea = res;
    });
    this.service.populateSelectFormFilter('isf').subscribe((res) => {
      this.listaIsf = res;
    });
    this.service.populateSelectFormFilter('provincia').subscribe((res) => {
      this.listaProvincia = res;
    });
    this.service.populateSelectFormFilter('localita').subscribe((res) => {
      this.listaLocalita = res;
    });
    this.service.populateSelectFormFilter('carica').subscribe((res) => {
      this.listaCarica = res;
    });
    this.service.populateSelectFormFilter('attivita').subscribe((res) => {
      this.listaAttivita = res;
    });
    this.service.populateSelectFormFilter('target-a').subscribe((res) => {
      this.listaTargetA = res;
    });
    this.service.populateSelectFormFilter('target-b').subscribe((res) => {
      this.listaTargetB = res;
    });
    this.service.populateSelectFormFilter('specialita').subscribe((res) => {
      this.listaSpecialita = res;
    });
    this.service.populateSelectFormFilter('profilo').subscribe((res) => {
      this.listaProfilo = res;
    });

    this.service.getListaGruppi().subscribe((res) => {
      this.listaGruppi = res.data;

    }, (error) => {

    });

    /* this.service.getListaTag().subscribe((res) => {
      this.listaTag = res.data;

    }, (error) => {

    }); */
  }

  onSubmitFilters(useCamelCase: boolean = false){
    const filtersToSend: any = {
      nome: this.doctorsFiltersForm.get('nome')?.value,
      cognome: this.doctorsFiltersForm.get('cognome')?.value,
      data_di_nascita: this.doctorsFiltersForm.get('data_di_nascita')?.value,
      micro_area: this.doctorsFiltersForm.get('micro_area')?.value,
      isf: this.doctorsFiltersForm.get('isf')?.value,
      indirizzo_princ_di_visita: this.doctorsFiltersForm.get('indirizzo_princ_di_visita')?.value,
      cap: this.doctorsFiltersForm.get('cap')?.value,
      provincia: this.doctorsFiltersForm.get('provincia')?.value,
      localita: this.doctorsFiltersForm.get('localita')?.value,
      carica: this.doctorsFiltersForm.get('carica')?.value,
      attivita: this.doctorsFiltersForm.get('attivita')?.value,
      numero_iscrizione_albo: this.doctorsFiltersForm.get('numero_iscrizione_albo')?.value,
      flag_pubblico_privato: this.doctorsFiltersForm.get('flag_pubblico_privato')?.value,
      ragione_sociale_struttura: this.doctorsFiltersForm.get('ragione_sociale_struttura')?.value,
      codice_fiscale: this.doctorsFiltersForm.get('codice_fiscale')?.value,
      partita_iva: this.doctorsFiltersForm.get('partita_iva')?.value,
      email_pubblica: this.doctorsFiltersForm.get('email_pubblica')?.value,
      email_privata: this.doctorsFiltersForm.get('email_privata')?.value,
      email_amministrazione: this.doctorsFiltersForm.get('email_amministrazione')?.value,
      email_ufficio_formazione: this.doctorsFiltersForm.get('email_ufficio_formazione')?.value,
      email_primario_reparto: this.doctorsFiltersForm.get('email_primario_reparto')?.value,
      profilo: this.doctorsFiltersForm.get('profilo')?.value,
      target_a: this.doctorsFiltersForm.get('target_a')?.value,
      target_b: this.doctorsFiltersForm.get('target_b')?.value,
      specialita: this.doctorsFiltersForm.get('specialita')?.value,
      consenso_privacy_a: this.doctorsFiltersForm.get('consenso_privacy_a')?.value,
      consenso_privacy_b: this.doctorsFiltersForm.get('consenso_privacy_b')?.value,
      consenso_privacy_c: this.doctorsFiltersForm.get('consenso_privacy_c')?.value,
      consenso_privacy_d: this.doctorsFiltersForm.get('consenso_privacy_d')?.value,
      gruppi: this.doctorsFiltersForm.get('gruppoId')?.value,
      tagNome: this.doctorsFiltersForm.get('tag')?.value,

    };
    // Converti le chiavi in camelCase se necessario
    if (useCamelCase === true) {
      this.emitFilters.emit(this.convertToCamelCase(filtersToSend));
    } else{
      this.emitFilters.emit(filtersToSend);
    }

    // this.emitFilters.emit(filtersToSend);
  }

  convertToCamelCase(obj: any): any {
    const toCamel = (str: string) =>
      str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

    return Object.keys(obj).reduce((result: any, key: string) => {
      const newKey = toCamel(key);
      result[newKey] = obj[key];
      return result;
    }, {});
  }


  onInputDate(event: any): void {
    const input = event.target;
    let value = input.value.replace(/[^0-9]/g, '');
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    const day = value.slice(0, 2);
    const month = value.slice(2, 4);
    const year = value.slice(4, 8);
    input.value = `${day}${month ? '/' : ''}${month}${year ? '/' : ''}${year}`;
  }


}
