import {Component, OnInit, OnChanges, Output, EventEmitter, DoCheck, Input, ViewChild} from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { AccountService, AlertService, ShareService } from 'src/app/SERVICES';
import { UsersService } from 'src/app/SERVICES/index';
import { MatDialog } from '@angular/material/dialog';

import { Utente } from '../../../MODELS/USER/utente';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { Router } from '@angular/router';
import { Pageable } from 'src/app/SERVICES/GENERAL/pageable';
import { Subscription } from 'rxjs';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import {FormFilterComponent} from "../gestione-medici/form-filter/form-filter.component";

@Component({
  selector: 'app-gestione-utente',
  templateUrl: './gestione-utente.component.html',
  styleUrls: ['./gestione-utente.component.scss'],
  providers: [ConfirmationService]

})
export class GestioneUtenteComponent implements OnInit, DoCheck {
  @Input() smartView = false;
  @Input() selectedId = '-1';
  @Output() emitUtente = new EventEmitter();
  searchform: FormGroup;
  searchformCancellati: FormGroup;
  edituser;
  edituserID;
  selectedValue;
  selectedValueCancellati;
  utente: Utente;
  pagina;
  paginaCancellati;
  mostraInfo: boolean = false;
  abilita = true;
  informazioni = false;
  idUtente;
  cancellaUser = false;
  cliccato = true;
  carica;
  showTable = true;
  appoggioclick;
  appoggioclickCancellati;
  pagine = [];
  pagineCancellati = [];
  dettaglio = [];
  idRipristina;
  loading = false;
  gruppos = [];
  infouser:any;
  listautenti: Utente[];
  utentiCancellati: Utente[];
  allUtentiCancellati: Utente[];
  allListautenti: Utente[];
  gestione = true;
  dettagli = false;
  modifica = false;
  msgs: Message[] = [];
  vediCancellati = false;
  vediUtenti = true;
  family = false;
  utentefamily;
  ioT;
  numeroMaxPagine: number
  numeroMaxPagineCancellate: number
  isClickable = true;
  isClickableCancellati = true;
  subscriptionListaUtenti: Subscription
  paginatorCancellati = true
  paginator = true;
  maxItems
  maxItemsCancellati
  isSearchingOn = false;
  items
  itemsCancellati
  isSelectingValue = false;
  isSelectingValueCancellati = false;
  refreshView = true;
  dataForExcel: any[];
  loadingExcel: boolean = false;
  valoreRicerca
  allUsers: any;


  // Sezione Smartwatch
  dialogSmartwatch = false;
  dialogSmartwatchFirstTime = false;
  idPerSmartwatch: any = 0;
  inputSmartwatch = '';
  inputOTP = '';
  idDeviceSmartwatch: any = '';
  timer = 300;
  intervalSmartwatch
  // Sezione Smartwatch


  @Output() vaiModificaUtente = new EventEmitter<string>();
  @Output() vediDettagliUtente = new EventEmitter<string>();
  caricato: boolean = false;


  constructor(
    private userService: UsersService,
    private alertService: AlertService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private envService: EnvService,
    private excelService: ExportExcelService,
    private routing: Router,
    private ioTService: IotService
  ) {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
    this.pagina = 0;
    this.paginaCancellati = 0;
    this.selectedValue = 10;
    this.selectedValueCancellati = 10;
    this.chiamataPaginata(this.pagina, this.selectedValue, true,)
    this.chiamataPaginata(this.pagina, this.selectedValueCancellati, false)
    this.ioT = this.envService.IOT
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.searchformCancellati = this.formBuilder.group({
      searchCancellati: ['', Validators.required]
    })
  }
  ngDoCheck(): void {
    if (this.accountService.listaEnv && (this.accountService.ruolo !== 1)) {
      this.routing.navigate(['./'])
    }

  }
  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    let utenteChiamante = true
    this.subscriptionListaUtenti = this.userService.getAll(pagina, quantità, attivo, utenteChiamante, ricerca).subscribe((res) => {
      this.appoggioclick = 1;
      this.appoggioclickCancellati = 1;
      if (attivo == true) {
        if (res.data.content.length > 0) {
          this.listautenti = res.data.content;
        } else {
          this.listautenti = []
        }
        this.allListautenti = this.listautenti;
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements
      }
      if (attivo == false) {
        if (res.data.content.length > 0) {
          this.utentiCancellati = res.data.content;
        } else {
          this.utentiCancellati = []
        }
        this.allUtentiCancellati = this.utentiCancellati
        this.numeroMaxPagineCancellate = Number(res.data.totalPages)
        this.maxItemsCancellati = res.data.totalElements;
        this.itemsCancellati = res.data.numberOfElements
      }
      this.carica = true;
      this.caricato = true;
      this.isClickable = true;
      this.isClickableCancellati = true;
      this.paginatorCancellati = true;
      this.paginator = true;
      this.isSearchingOn = false;
      this.isSelectingValue = false;
      this.isSelectingValueCancellati = false;
      this.refreshView = true;
      this.showTable = true;
    })
    this.userService.getAll().subscribe((res) => {
      this.allUsers = [...res.data.listaUtentiAttivi, ...res.data.listaUtentiDisattivi, {}, ...res.data.listaUtentiCancellati];
    })
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  cambiaPaginaCancellati(event) {
    this.isClickableCancellati = false;
    this.paginaCancellati = event;
    this.paginator = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
    }
  }

  cambiaValueCancellati() {
    this.paginaCancellati = 0
    this.isClickableCancellati = false;
    this.paginator = false;
    this.isSelectingValueCancellati = true;
    this.refreshView = false;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
    }
  }

  searchInUser(event) {
    this.valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionListaUtenti) {
      this.subscriptionListaUtenti.unsubscribe()
    }
    this.isSearchingOn = true;
    if (event) {
      this.valoreRicerca = this.searchform.value.search
    } else {
      this.valoreRicerca = this.searchformCancellati.value.searchCancellati;
    }
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, event, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, event)
    }
  }

  inviaEmail(email) {

    this.confirmationService.confirm({
      message: 'Inviare di nuovo l\'email all\'utente?',

      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.userService.inviaEmail(email).subscribe((res) => {
          this.alertService.success(res.status.description);
        })
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  cambiaStato(id) {
    const user = this.listautenti.find(x => x.id === id);
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.abilitaUser(id).subscribe((res) => {
          if (user.attivo === 0) {
            user.attivo = 1;
          }
          else if (user.attivo === 1) {
            user.attivo = 0;
          }
          this.alertService.clear;
          this.alertService.success(res.status.description);
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    })
  }

  exportExcelUtenti() {
    this.loadingExcel = true;
    let excelscaricato = false;
    let utentiList = new Array;
    let title = 'Lista Utenti';
    this.allUsers.forEach(ut => {
      let utente: Utente = new Utente;
      utente.nome = ut.nome;
      utente.cognome = ut.cognome;
      utente.email = ut.email;
      utente.stato = ut.deletedAt ? 'cancellato' : '';
      utentiList.push(utente)
    });
    if (utentiList.length > 0) {
      excelscaricato = true;
      this.downloadExcel(utentiList, title);
      utentiList = new Array;
    }
    if (!excelscaricato) {
      // this.loadingExcel = false;
      this.alertService.info('Errore');
    }
  }

  downloadExcel(list, title) {

    this.dataForExcel = [];
    list.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: title,
      data: this.dataForExcel,
      headers: Object.keys(list[0])
    }

    this.excelService.exportExcel(reportData);
    this.loadingExcel = false;
  }

  cancella(id, i) {
    this.cancellaUser = true;
    this.idUtente = id;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.cancellaUser = false;
        this.loading = false;
        this.confirmationService.close();
      }
    });
  }

  //METODO PER PASSARE DALLA GESTIONE UTENTI AL VISUALIZZA INFO DI UN UTENTE
  visualizza(id: string) {
    this.infouser = id;
    this.gestione = false;
    this.dettagli = true;
  }

  vediInfo(id) {
    this.idUtente = id;
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }

  indietroDainfo(vai) {
    this.gestione = !this.gestione;
    this.dettagli = !this.dettagli;
  }

  onVoted(agreed: boolean) {
    agreed ? this.informazioni = false : this.informazioni = true;
  }

  editUtente(id) {
    this.idUtente = id;
    this.gestione = false;
    this.modifica = true;

  }

  chiudiRipristina() {
    this.confirmationService.close();
    this.loading = false;
  }

  ripristina(id, indice) {
    this.idRipristina = id;
    this.confirmationService.confirm({
      message: 'Ripristinare l\'utente?',
      key: 'ripristino',
      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.confirmationService.close();
        this.loading = false;
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      }
    });
  }

  listaCancellati() {
    this.vediUtenti = false;
    this.vediCancellati = true;
  }

  listaPresenti() {
    this.vediUtenti = true;
    this.vediCancellati = false;
  }

  indietro(utente) {
    this.chiamataPaginata(this.pagina, this.selectedValue, true)
    //this.listautenti.filter
    this.gestione = true;
    this.family = false;
    this.modifica = false;
  }

  accettaCancella() {
    this.paginator = false;
    this.valoreRicerca = '';
    this.searchform.setValue({ search: '' })
    this.paginatorCancellati = false;
    this.userService.delete(this.idUtente).subscribe((res1) => {

      if (this.listautenti.length === 1) {
        this.refreshView = false;
      }
      if (this.listautenti.length == 0) {
        if (this.pagina == 1) {
          this.pagina = this.pagina - 1
          this.chiamataPaginata(this.pagina, this.selectedValue, true)
        } else {
          this.chiamataPaginata(this.pagina, this.selectedValue, true)
        }
      } else {
        this.chiamataPaginata(this.pagina, this.selectedValue, true)
      }
      if (this.utentiCancellati.length == 0) {
        if (this.paginaCancellati == 1) {
          this.paginaCancellati = this.paginaCancellati - 1
          this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
        } else {
          this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
        }
      } else {
        this.chiamataPaginata(this.paginaCancellati, this.selectedValueCancellati, false)
      }
      this.confirmationService.close();
      this.alertService.success(res1.status.description);
      this.loading = false;

    });

  }

  visualizzaFamily(id) {
    this.utentefamily = id;
    this.gestione = false;
    this.family = true;
  }

  accettaRipristina() {
    this.valoreRicerca = ''
    this.searchformCancellati.setValue({ searchCancellati: '' })
    this.userService.restore(this.idRipristina).subscribe((res1) => {
      this.chiamataPaginata(this.paginaCancellati, this.selectedValue, false)
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
      this.confirmationService.close();
      this.alertService.success(res1.status.description);
      this.loading = false;

    })
  }

  apriModaleSmartwatch(id) {
    this.idPerSmartwatch = id;
    this.ioTService.recuperoDatiSmartwatch(id).subscribe((response) => {
      this.dialogSmartwatchFirstTime = false;
      this.dialogSmartwatch = true;
      this.inputSmartwatch = response.data.deviceId;
      this.idDeviceSmartwatch = response.data.id;
      this.ioTService.recuperoOTP(this.idDeviceSmartwatch).subscribe((response) => {
        this.inputOTP = response.data.otp;
        this.intervalSmartwatch = setInterval(() => {
          if (this.timer > 0) {
            this.timer = this.timer - 1;
          } else {
            this.ioTService.recuperoOTP(this.idDeviceSmartwatch).subscribe((response) => {
              this.inputOTP = response.data.otp
              this.timer = 300;
            })
          }
        }, 1000)
      })
    }, (error) => {
      if (error.data == null) {
        this.dialogSmartwatchFirstTime = true;
        this.dialogSmartwatch = true;
      }
    })
  }
  responsiveWidth() { }
  chiudiModaleSmartwatch() {
    this.inputOTP = '';
    this.timer = 300;
    this.inputSmartwatch = '';
    this.dialogSmartwatchFirstTime = false;
    this.dialogSmartwatch = false;
    if (this.intervalSmartwatch) {
      clearInterval(this.intervalSmartwatch);
    }
  }
  confermaModaleSmartwatch() {
    if (this.dialogSmartwatchFirstTime) {
      let body = {
        name: 'SmartWatch' + this.idPerSmartwatch,
        device_id: this.inputSmartwatch,
        user: {
          id: this.idPerSmartwatch
        }
      }
      this.ioTService.inserimentoDatiSmartwatch(body).subscribe((res) => {
        this.idDeviceSmartwatch = res.data.id;
        this.dialogSmartwatchFirstTime = false;
        this.ioTService.recuperoOTP(this.idDeviceSmartwatch).subscribe((response) => {
          this.inputOTP = response.data.otp;
          this.intervalSmartwatch = setInterval(() => {
            if (this.timer > 0) {
              this.timer = this.timer - 1;
            } else {
              this.ioTService.recuperoOTP(this.idDeviceSmartwatch).subscribe((response) => {
                this.inputOTP = response.data.otp
                this.timer = 300;
              })
            }
          }, 1000)
        })
      })
    } else {
      let body = {
        id: this.idDeviceSmartwatch,
        name: 'SmartWatch' + this.idPerSmartwatch,
        device_id: this.inputSmartwatch,
        user: {
          id: this.idPerSmartwatch
        }
      }
      this.ioTService.inserimentoDatiSmartwatch(body).subscribe((res) => {
        this.chiudiModaleSmartwatch();
      })
    }
  }
  deleteSmartwatch() {
    this.ioTService.eliminazioneDatiSmartwatch(this.idDeviceSmartwatch).subscribe((res) => {
      this.chiudiModaleSmartwatch()
    })
  }
  selectUser(id) {
    if (this.smartView) {
      this.selectedId = id;
      this.emitUtente.emit(id);
    }
  }
}
