import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Cliente } from 'src/app/MODELS/USER/cliente';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';

@Component({
  selector: 'app-storico-table',
  templateUrl: './storico-table.component.html',
  styleUrls: ['./storico-table.component.scss'],
  animations: [
    trigger('notFireTheFirstTime', [transition(':enter', [])]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('120ms ease-out')),
      transition('default => rotated', animate('120ms ease-in')),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translate(-50%, -50%)', top: '49.5%' }),
        animate(
          '400ms ease-in',
          style({ transform: 'translate(15%, -50%)', top: '49.5%' })
        ),
      ]),
    ]),
    trigger('slideOutIn', [
      transition(':enter', [
        style({ transform: 'translate(0%, -50%)', top: '49.5%' }),
        animate(
          '400ms ease-in',
          style({ transform: 'translate(-50%, -50%)', top: '49.5%' })
        ),
      ]),
    ]),
  ],
})
export class StoricoTableComponent implements OnInit {
  @Output() tornaStorico = new EventEmitter<boolean>();
  @Input() assistitoId;
  @Input() modificheAssistito;
  @Input() data;

  dettaglioCliente: Cliente = {};
  showAltraAssociazione = false;
  showAltroServizio = false;
  tipologieFullString = '';
  tipologieLaboratoriInterventiFullString = '';
  obiettiviGeneraliFullString = '';
  stato = ['default'];
  statoButton = 'default';
  loading = false;
  isChecked = true;
  textToShow;
  constructor(private clienteService: ClienteService) {}

  ngOnInit(): void {
    this.loading = true;

    this.clienteService.getInfoCliente(this.assistitoId).subscribe((res) => {
      this.dettaglioCliente.nome = res.data.nome;
      this.dettaglioCliente.cognome = res.data.cognome;
      this.dettaglioCliente.codiceFiscale = res.data.codiceFiscale;
      this.dettaglioCliente.eta = res.data.eta;
      this.dettaglioCliente.sesso = res.data.sesso;
      this.dettaglioCliente.citta = res.data.citta;
      this.dettaglioCliente.occupazione = res.data.occupazione;
      this.dettaglioCliente.livelloDiIstruzione = res.data.livelloDiIstruzione;
      this.dettaglioCliente.situazioneEconomica = res.data.situazioneEconomica;
      this.dettaglioCliente.diagnosi = res.data.diagnosi;
      this.dettaglioCliente.annoDiPresaInCaricoDalServizio =
        res.data.annoDiPresaInCaricoDalServizio;
      this.dettaglioCliente.infoAltraAssociazione =
        res.data.infoAltraAssociazione;
      this.dettaglioCliente.altraAssociazione = res.data.altraAssociazione;
      this.dettaglioCliente.infoCliente = res.data.infoCliente;
      this.dettaglioCliente.altroServizio = res.data.altroServizio;
      this.dettaglioCliente.serviziDiAppartenenza =
        res.data.serviziDiAppartenenza;
      this.dettaglioCliente.tipologiaAttivita = this.getListFromArray(
        res.data.tipologiaAttivita,
        'nomeAttivita'
      );
      this.tipologieFullString = this.getFullString(
        this.dettaglioCliente.tipologiaAttivita
      );
      this.dettaglioCliente.tipologiaLaboratoriInterventi =
        this.getListFromArray(
          res.data.tipologiaLaboratoriInterventi,
          'nomeLaboratoriInterventi'
        );
      this.tipologieLaboratoriInterventiFullString = this.getFullString(
        this.dettaglioCliente.tipologiaLaboratoriInterventi
      );
      this.dettaglioCliente.partenariati = res.data.partenariati;
      this.dettaglioCliente.inviante = res.data.inviante;
      this.dettaglioCliente.note = res.data.note;
      this.dettaglioCliente.analisiBisogni = res.data.analisiBisogni;
      this.dettaglioCliente.analisiRisorse = res.data.analisiRisorse;
      this.dettaglioCliente.obiettiviGenerali = this.getListFromArray(
        res.data.obiettiviGenerali,
        'nomeObiettivo'
      );
      this.obiettiviGeneraliFullString = this.getFullString(
        this.dettaglioCliente.obiettiviGenerali
      );
      this.dettaglioCliente.obiettiviSpecifici = res.data.obiettiviSpecifici;
      this.dettaglioCliente.metodologiaSpecifica =
        res.data.metodologiaSpecifica;
      this.dettaglioCliente.obiettiviRaggiunti = res.data.obiettiviRaggiunti;
      this.dettaglioCliente.descrizioneObiettivi =
        res.data.descrizioneObiettivi;
      this.checkInfoServizi();
      this.checkInfoAssociazione();
      this.swapState();
      this.loading = false;
    });
  }

  getListFromArray(element, key) {
    let array = [];
    element.forEach((element) => {
      if (element.selected === true) {
        array.push(element[key]);
      }
    });
    return array;
  }

  getFullString(array: any[]): string {
    let fullString = '';
    array.forEach((el, index) => {
      if (index === array.length - 1) fullString = fullString += el;
      else fullString = fullString += el + ', ';
    });

    return fullString;
  }

  indietro(chiudi: boolean) {
    this.tornaStorico.emit(chiudi);
  }

  checkInfoAssociazione() {
    if (
      this.dettaglioCliente.infoAltraAssociazione &&
      this.dettaglioCliente.infoAltraAssociazione.toLowerCase() ===
        'Sì'.toLowerCase()
    ) {
      this.showAltraAssociazione = true;
    }
  }

  checkInfoServizi() {
    if (
      this.dettaglioCliente.infoCliente &&
      this.dettaglioCliente.infoCliente.toLowerCase() === 'Sì'.toLowerCase()
    ) {
      this.showAltroServizio = true;
    }
  }

  showAzioni(i) {
    this.stato[i] = this.stato[i] === 'default' ? 'rotated' : 'default';
  }

  moveButton() {
    this.statoButton = this.statoButton === 'default' ? 'moved' : 'default';
  }

  swapState() {
    let data = new Date(this.data);
    if (this.isChecked) {
      this.isChecked = !this.isChecked;
      this.textToShow =
        'Modifica in data ' +
        ('0' + data.getDate()).slice(-2) +
        '-' +
        ('0' + (data.getMonth() + 1)).slice(-2) +
        '-' +
        data.getFullYear();
    } else {
      this.isChecked = !this.isChecked;
      this.textToShow = 'Stato attuale';
    }
  }

  removeFocus() {
    document.getElementById('title').blur();
  }
}
