<div *ngIf="loading" class="text-center" style="margin-top: 5%">
  <p-progressSpinner
    [style]="{ width: '40px', height: '40px' }"
    strokeWidth="5"
    styleClass="custom-spinner2"
    animationDuration=".5s"
  ></p-progressSpinner>
</div>
<div class="container-fluid">
  <div *ngIf="!loading">
    <div *ngIf="!storicoTable">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Storico modifiche</h4>
              <p-confirmDialog
                [style]="{ width: responsiveWidth() }"
                [acceptLabel]="'Si'"
                [baseZIndex]="5"
              ></p-confirmDialog>
              <div class="ofx-auto">
                <table
                  id="datatables"
                  class="table table-striped table-no-bordered table-hover"
                  cellspacing="0"
                  width="100%"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>
                        Data
                        <span
                          ><i
                            [@rotatedState]="stato"
                            class="material-icons notranslate middle"
                            style="
                              cursor: pointer;
                              font-size: 16px;
                              font-weight: bold;
                            "
                            (click)="
                              cambiaStato('stato'); changeStatusCalendar()
                            "
                            >chevron_right</i
                          >
                          <ng-container *ngTemplateOutlet="tooltipCalendar">
                          </ng-container>
                        </span>
                      </th>
                      <th>Operatore</th>
                      <th
                        class="disabled-sorting text-right"
                        style="margin-right: 20%"
                      >
                        Visualizza
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngIf="modificheUser.length < 1">
                      <td>Nessuna modifica effettuata</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr *ngFor="let modifica of modificheUser; let j = index">
                      <td>{{ modifica.data | date : "dd/MM/yyyy" }}</td>
                      <td>{{ modifica.operatore }}</td>
                      <td class="text-right">
                        <a
                          ><i
                            (click)="
                              openTable(modifica.modifiche, modifica.data)
                            "
                            class="material-icons notranslate pointer"
                            rel="tooltip"
                            title="Modifica gruppo"
                            >visibility</i
                          ></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="btn-group d2 p-bottom25"
                role="group"
                aria-label="Basic example"
              >
                <button
                  (click)="indietro(true)"
                  class="btn btn-secondary w150p"
                  aria-pressed="true"
                  style="margin-right: 3px"
                >
                  Indietro
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storicoTable">
  <app-storico-table
    [modificheAssistito]="assistitoModifiche"
    [data]="dataDaPassare"
    [assistitoId]="clienteInfo"
    (tornaStorico)="backFromTable($event)"
  >
  </app-storico-table>
</div>

<ng-template #tooltipCalendar let-tooltip="tooltip">
  <div class="tooltiptext" *ngIf="statusCalendar" class="calendar">
    <p-calendar
      #myCalendar
      view="date"
      (onSelect)="selezionaData()"
      [maxDate]="today"
      [readonlyInput]="true"
      [numberOfMonths]="1"
      yearRange="2000:2030"
      [(ngModel)]="rangeDates"
      [showOtherMonths]="false"
      placeholder="Select time period"
      selectionMode="range"
      [inline]="true"
      dateFormat="yy/mm/dd"
      [locale]="ita"
    >
    </p-calendar>
    <div class="rightmr-10">
      <button class="btnDwn" (click)="filtraStorico()">Conferma</button>
    </div>
  </div>
</ng-template>
