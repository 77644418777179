import { ruolo } from './ruolo';
import { Id_gruppo } from './id_gruppo';

export class Utente {
  id?: string;
  nome?: string;
  cognome?: string;
  email?: string;
  telefono?: string;
  attivo?: any;
  token?: string;
  ruolo?: ruolo;
  gruppos?: [Id_gruppo];
  password?: string;
  latitudine?: number;
  longitudine?: number;
  capcomune?: any;
  dettaglio?: [];
  presenza?: boolean;
  relatore?: boolean;
  userNonInCr?: boolean;
  tracker?: boolean;
  user?: any;
  counter?: any;
  stato?: any;
  specialita_1?:string;
  specialita_2?:string;
  specialita_3?:string;
  micro_area?:string;
  isf?:string;
  email_privata?:string;

  data_di_nascita?: string;
  codice_fiscale?: string;
  numero_iscrizione_albo?: string;
  stato_cliente?: string;
  flag_pubblico_privato?: string;
  attivita?: string;
  carica?: string;
  ragione_sociale_struttura?: string;
  indirizzo_struttura?: string;
  partita_iva?: string;
  indirizzo_princ_di_visita?: string;
  cap?: string;
  localita?: string;
  provincia?: string;
  cellulare_lavoro?: string;
  cellulare_privato?: string;
  fisso_lavoro?: string;
  email_pubblica?: string;
  email_primario_reparto?: string;
  email_amministrazione?: string;
  email_ufficio_formazione?: string;
  profilo?: string;
  target_a?: string;
  target_b?: string;
  percentuale_spec_1?: string;
  percentuale_spec_2?: string;
  percentuale_spec_3?: string;
  consenso_privacy_a?: string;
  consenso_privacy_b?: string;
  consenso_privacy_c?: string;
  consenso_privacy_d?: string;
  inserimento_manuale?: boolean;
  listaMedicoGruppo?: any;
  tag?: string;
}
