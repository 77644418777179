<div class="flex">
  <button
    (click)="indietro()"
    id="back-button"
    class="btn btn-round btn-just-icon"
    *ngIf="showPage"
  >
    <i class="material-icons notranslate">arrow_back</i>
  </button>
  <h3 class="title m-7" *ngIf="showPage">{{ idDaVisualizzare.title }}</h3>
  <div
    class="iconaModTecnico"
    *ngIf="showPage"
    style="display: flex; align-items: center"
    [innerHTML]="idDaVisualizzare.actions[0].label"
  ></div>
  <div class="right">
    <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
  </div>
  <div class="right" *ngIf="showPage">
    <button
      (click)="elimina()"
      id="back-button"
      class="btn btn-round deleteIcon btn-just-icon"
      *ngIf="showPage"
    >
      <i
        class="material-icons red notranslate"
        rel="tooltip"
        title="Elimina chiamata"
        >delete</i
      >
    </button>
  </div>
</div>
<div *ngIf="showPage">
  <div class="customContainer">
    <div class="rowForm flex">
      <div class="row flex">
        <div class="col-6">
          <div class="titleForm">Ordini</div>
          <input
            type="text"
            disabled
            [(ngModel)]="infoChiamata.ordine"
            class="valueForm"
          />
        </div>
        <div class="col-6">
          <div class="titleForm">Ricerca di mercato</div>
          <input
            type="text"
            disabled
            [(ngModel)]="infoChiamata.ricercaMercato"
            class="valueForm"
          />
        </div>
      </div>
    </div>

    <div *ngFor="let item of infoCampagnaDomande; let i = index">
      <div class="rowForm flex" *ngIf="i % 2 == 0">
        <div class="row flex">
          <div class="col-6">
            <div class="titleForm">{{ item.domanda }}</div>
            <input
              type="text"
              disabled
              [(ngModel)]="item.risposta"
              class="valueForm"
            />
          </div>
          <div class="col-6" *ngIf="infoCampagnaDomande[i + 1]">
            <div class="titleForm">
              {{ infoCampagnaDomande[i + 1].domanda }}
            </div>
            <input
              type="text"
              disabled
              [(ngModel)]="infoCampagnaDomande[i + 1].risposta"
              class="valueForm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
