import { Injectable, Host } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvService } from '../GENERAL/env.service';
import { UtentiSelezionati } from 'src/app/MODELS/BADGE/utenti-selezionati';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  baseUrl: string;
  isFirstTimeCr = true;
  constructor(private http: HttpClient, private env: EnvService) {
    //this.baseUrl = "http://192.168.15.29:5442" //server
    //this.baseUrl = "http://192.168.15.89:8080" //daniele
    this.baseUrl = this.env.apiUrl;
    //this.baseUrl = "https://api-collaborative.protom.com/v1" //amazon server
    //this.baseUrl = "http://192.168.15.92:8080" //luca
    //this.baseUrl= "http://10.2.3.3:8080" //vpn luca
  }

  downloadVideochiamata(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/visualizza_sessioni`);
  }

  downloadGeo(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/download_geo');
  }

  downloadGeoLocation(id, startToday, today): Observable<any> {
    return this.http.get<any>(
      this.baseUrl +
        '/device/' +
        id +
        '/location?start=' +
        startToday +
        '&end=' +
        today
    );
  }

  downloadImageLocation(long, lat): Observable<any> {
    let zoomLevel = 14;
    var row = Math.floor((long + 180) / (360 / Math.pow(2, zoomLevel)));
    var col = Math.floor((90 + lat * -1) / (180 / Math.pow(2, zoomLevel - 1)));
    return this.http.get<any>(
      'https://a.tile.openstreetmap.org/14/' + row + '/' + col + '.png'
    );
  }
  inviaDatiReport2(
    listaId: String[],
    dayFrom,
    monthFrom,
    yearFrom,
    dayTo,
    monthTo,
    yearTo
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/download_geo_data?createdAt=${yearFrom}-${monthFrom}-${dayFrom}&createdAt2=${yearTo}-${monthTo}-${dayTo}`,
      listaId
    );
  }

  inviaDatiReport(
    listaId: String[],
    dayFrom,
    monthFrom,
    yearFrom,
    dayTo,
    monthTo,
    yearTo
  ) {
    return this.http.post<any>(
      `${this.baseUrl}/report_sessioni?createdAt=${yearFrom}-${monthFrom}-${dayFrom}&deletedAt=${yearTo}-${monthTo}-${dayTo}`,
      listaId
    );
  }

  inviaDatiReportAula(
    listaId: any[],
    dayFrom,
    monthFrom,
    yearFrom,
    dayTo,
    monthTo,
    yearTo
  ) {
    let url = `${this.baseUrl}/report_aula?createdAt=${yearFrom}-${monthFrom}-${dayFrom}&deletedAt=${yearTo}-${monthTo}-${dayTo}`;
    if (this.env.goTo) {
      url = `${this.baseUrl}/go_to_webinar/report_aula?createdAt=${yearFrom}-${monthFrom}-${dayFrom}&deletedAt=${yearTo}-${monthTo}-${dayTo}`;
    }
    return this.http.post(url, listaId);
  }

  inviaDatiReportDispositivo(
    body,
    dayFrom,
    monthFrom,
    yearFrom,
    dayTo,
    monthTo,
    yearTo
  ) :any {
    return this.http.post(
      `${this.baseUrl}/device/device_report_filter?start=${yearFrom}-${monthFrom}-${dayFrom}&end=${yearTo}-${monthTo}-${dayTo}`,
      body
    );
  }

  inviaDatiReportBadge(
    listaId: String[],
    dayFrom,
    monthFrom,
    yearFrom,
    dayTo,
    monthTo,
    yearTo
  ) {
    let payload: UtentiSelezionati;
    payload = { reportUsers: listaId };
    return this.http.post(
      `${this.baseUrl}/badge/report?start=${yearFrom}-${monthFrom}-${dayFrom}&end=${yearTo}-${monthTo}-${dayTo}`,
      payload
    );
  }

  getAll(page?, size?, attivo?, ricerca?): Observable<any> {
    if ((page || page == 0) && size && ricerca) {
      return this.http.get<any>(
        this.baseUrl +
          '/elastic/get_doc_filter?page=' +
          page +
          '&size=' +
          size +
          '&attivo=' +
          attivo +
          '&ricerca=' +
          ricerca
      );
    } else if ((page || page == 0) && size && !ricerca) {
      return this.http.get<any>(
        this.baseUrl +
          '/elastic/get_doc_filter?page=' +
          page +
          '&size=' +
          size +
          '&attivo=' +
          attivo
      );
    } else {
      return this.http.get<any>(this.baseUrl + '/elastic/get_doc_filter');
    }
  }

  getAllAula(id): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/elastic/get_doc_aula?idAula=' + id
    );
  }

  getAllEsercitazione(id): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/elastic/get_doc_esercitazione?idEsercitazione=' + id
    );
  }

  // getAll():Observable<any> {
  //   return this.http.get<any>(this.baseUrl+"/elastic/get_docs_elastic")
  // }

  getReportImage(id): Observable<any> {
    return this.http.get(this.baseUrl + '/badge/report/image/' + id);
  }

  findAll(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/elastic/get_docs_elastic_keys?author=ciao'
    );
  }

  delete(id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/elastic/delete_doc?id=' + id,
      null
    );
  }

  deleteDef(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/elastic/delete?id=' + id, null);
  }

  // delete(id):Observable<any> {
  //   return this.http.post<any>(this.baseUrl+"/elastic/delete_all", null)
  // }

  restore(id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/elastic/restore_doc?id=' + id,
      null
    );
  }

  upload(file): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/elastic/save_doc_body', file);
  }

  edit(file): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/elastic/change_doc', file);
  }

  ricercaAvanzata(
    title,
    category,
    author,
    group,
    owner,
    content,
    extension,
    start,
    end
  ): Observable<any> {
    return this.http.get<any>(
      this.baseUrl +
        '/elastic/get_docs_elastic_keys?title=' +
        title +
        '&category=' +
        category +
        '&author=' +
        author +
        '&group=' +
        group +
        '&owner=' +
        owner +
        '&content=' +
        content +
        '&extension=' +
        extension +
        '&start=' +
        start +
        '&end=' +
        end
    );
  }

  getRoot(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dms/folder');
  }

  getPermission(id): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/dms/folder/' + id + '/getPermission'
    );
  }

  getFolder(id: number): Observable<any> {
    if (id) return this.http.get<any>(this.baseUrl + '/dms/folder/' + id);
    return this.getRoot();
  }

  getIdOrderFolder(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/dms/folder?name=Schede Tecniche'
    );
  }

  getIdCR(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dms/folder?name=Control Room');
  }
  //Ottiene la cartella personale dell'utente
  getUserFolder(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dms/user');
  }

  getAllUserFolders(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dms/users');
  }

  createUserFolder(): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/dms/user', null);
  }

  uploadDms(message): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/dms/file', message);
  }

  editFileDms(id, message): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/dms/file/' + id, message);
  }

  getUrlFile(id) {
    return this.http.get<any>(this.baseUrl + '/dms/file/' + id);
  }

  public sendFiles(userId, file): Observable<any> {
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('file', file);
    return this.http.post<any>(this.baseUrl + '/condividi_file_cr', formData);
  }

  condividiFileCr(userId, file) {
    let input = new FormData();
    input.append('userId', userId);
    input.append('file', file);
    return this.http
      .post(this.baseUrl + '/condividi_file_cr', input)
      .pipe(map((resp: any) => resp));
  }

  deleteFolder(id) {
    return this.http.post<any>(
      this.baseUrl + '/dms/folder/' + id + '/delete',
      null
    );
  }

  deleteFile(id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/dms/file/' + id + '/delete',
      null
    );
  }

  createFolder(body, id?) {
    if (id)
      return this.http.post<any>(this.baseUrl + '/dms/folder/' + id, body);
    return this.http.post<any>(this.baseUrl + '/dms/folder', body);
  }

  getTrashcanRoot(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dms/trashcan');
  }

  getTrashcanUserFolder(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dms/trashcan/user/' + id);
  }

  getOrphans() {
    return this.http.get<any>(this.baseUrl + '/dms/folder/restored');
  }

  restoreFile(id) {
    return this.http.post<any>(
      this.baseUrl + '/dms/trashcan/file/' + id + '/restore',
      null
    );
  }

  restoreFolder(id) {
    return this.http.post<any>(
      this.baseUrl + '/dms/trashcan/folder/' + id + '/restore',
      null
    );
  }

  pshysicalDeleteFolder(id) {
    return this.http.post<any>(
      this.baseUrl + '/dms/trashcan/folder/' + id + '/delete',
      null
    );
  }

  pshysicalDeleteFile(id) {
    return this.http.post<any>(
      this.baseUrl + '/dms/trashcan/file/' + id + '/delete',
      null
    );
  }

  condividiImagineCr(userId, file) {
    let input = new FormData();
    input.append('userId', userId);
    input.append('file', file);
    if (this.isFirstTimeCr) {
      this.isFirstTimeCr = false;
      return this.http
        .post(this.baseUrl + '/condividi_immagine_cr', input)
        .pipe(map((resp: any) => resp));
    } else {
      return this.http
        .post(this.baseUrl + '/condividi_immagine_cr?update=true', input)
        .pipe(map((resp: any) => resp));
    }
  }
}
