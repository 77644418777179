import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  DoCheck,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, Message, PrimeNGConfig } from 'primeng/api';
import { Utente } from 'src/app/MODELS/USER/utente';
import { File } from 'src/app/MODELS/FILE/file';
import { UsersService, AlertService, AccountService } from 'src/app/SERVICES';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { Router } from '@angular/router';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';
import { Subscription } from 'rxjs';
import { Cartella } from 'src/app/MODELS/FILE/cartella';
import { CartellaFiglia } from 'src/app/MODELS/FILE/cartella-figlia';
import { trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { type } from 'os';

@Component({
  selector: 'app-lista-file',
  templateUrl: './lista-file.component.html',
 // styleUrls: ['./lista-file.component.scss'],
})
export class ListaFileComponent implements OnInit, DoCheck, AfterViewInit {
  @Input() fromPrenotaTecnico = false;
  @Input() fromCR = false;
  @Input() prodotti = [];
  @Input() disabled = false;
  @Output() emitterFileInCR = new EventEmitter<any>();
  @Output() emitFilesSelected = new EventEmitter<any>();
  paginatorMat: MatPaginator;

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator
  ) {
    this.paginatorMat = paginator;

    if (this.listUsers) {
      this.listUsers.paginator = paginator;
    }
  }
  True = true;
  False = false;
  backButtonLoading = false;
  listaComandi = [];
  isPersonal: Boolean = false;
  isPersonalCestino: Boolean = false;
  showCartelleUser = true;
  idCartellaPersonale: Number = -1;
  isRoot: Boolean = false;
  step1 = true;
  isRootCestino: Boolean = false;
  eliminaCartella: Boolean;
  cartelle: Array<Cartella> = new Array();
  cartelleCestino: Array<Cartella> = new Array();
  cartellaCorrenteCestino: Cartella = new Cartella();
  cartellaCorrente: Cartella = new Cartella();
  cartellePrecedentiCestino: Array<Cartella> = new Array();
  cartellePrecedenti: Array<Cartella> = new Array();
  userIDs: Array<Number> = new Array();
  fileSelezionato;
  searchform: FormGroup;
  searchformCancellati: FormGroup;
  selectedValue;
  selectedValueCancellati;
  appoggioPagina;
  appoggioPaginaCancellati;
  displayedColumnsSmartwatch: string[] = [
    'nome',
    'cognome',
    'ruolo',
    'visualizza',
    'scrittura',
  ];
  listafileappoggio;
  pagina = 1;
  loading = false;
  paginaCancellati = 1;
  mostraInfo: boolean = false;
  abilita = true;
  abilitaCancellati = true;
  disabilitaAvanti = false;
  disabilitaAvantiCancellati = false;
  informazioni = false;
  idFile;
  cancellaFile = false;
  ricerca;
  listaFiltrata = [];
  cliccato = true;
  carica;
  searchWordCartelle = '';
  appoggioclick;
  appoggioclickCancellati;
  pagine = [];
  pagineCancellati = [];
  dettaglio = [];
  k;
  idRipristina;
  j;
  infofile;
  listaFile;
  fileCancellati;
  allFileCancellati;
  allListaFile;
  numeroFile;
  showAddModifyFolder = false;
  numeroFileCancellati;
  gestione = true;
  dettagli = false;
  modifica = false;
  ricordaPagina = 1;
  ricordaPaginaCancellati = 1;
  ricordaLunghezza = 0;
  ricordaLunghezzaCancellati = 0;
  msgs: Message[] = [];
  vediCancellati = false;
  vediFile = true;
  position: string;
  flag = false;
  isFinishedResizing = true;
  secondo = false;
  flagCancellati = false;
  secondoCancellati = false;
  link;
  @Output() vaiModificaFile = new EventEmitter<string>();
  @Output() vediDettagliFile = new EventEmitter<string>();
  gestioneFile: boolean = false;
  userID;
  userRole;
  ricercaAvanzata = false;
  listaAvanzata = false;
  stato;
  listaCorsi = [];
  allListaCorsi = [];
  appoggioPaginaCorsi;
  pagineCorsi = [];
  appoggioclickCorsi;
  numeroFileCorsi;
  ricordaPaginaCorso;
  searchformCorso: FormGroup;
  ricordaLunghezzaCorso;
  secondoCorso;
  flagCorso;
  listaCorsiappoggio;
  ruolo;
  loadingCorso;
  statoCorso;
  idCorso;
  corso;
  infoCorsoB;
  editCorsoB;
  c;
  cancellaCorsob;
  envScorm;
  maxUsersPaginator = 3;
  // --- Corsi
  paginaCorsi = 0;
  selectedValueCorsi = 10;
  subscriptionSearchCorsi: Subscription;
  numeroMaxPagineCorsi;
  maxItemsCorsi;
  itemsCorsi;
  isClickableCorsi;
  paginatorCorsi;
  isSearchingOnCorsi;
  isSelectingValueCorsi;
  idRipristinaCorsi;
  statoCorsi;
  // --- CorsiCestino
  listaCorsiCestino = [];
  selectedValueCestinoCorsi = 10;
  paginaCestinoCorsi = 0;
  isSelectingValueCestinoCorsi = false;
  isSearchingOnCestinoCorsi = false;
  isClickableCestinoCorsi = true;
  searchformCestinoCorsi: FormGroup;
  maxItemsCestinoCorsi;
  itemsCestinoCorsi;
  numeroMaxPagineCestinoCorsi;
  paginatorCestinoCorsi = true;
  subscriptionSearchCestinoCorsi: Subscription;
  paginatorCestino = true;
  isClickableCestino = true;
  paginator = true;
  isClickable = true;
  refreshView = false;
  maxPag;
  maxPagCancellati;
  ordinaNome = false;
  ordinaCategoria = false;
  ordinaGruppo = false;
  ordinaData = false;
  ordinaEstensione = false;
  alfaintes;
  //navigazione
  activeTab;

  constructor(
    private fileService: FileService,
    private userService: UsersService,
    private alertService: AlertService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private env: EnvService,
    private router: Router,
    private scormService: ScormService
  ) {
    this.alfaintes = this.env.alfaintes;
    this.envScorm = this.env.SCORM;
    this.userID = this.accountService.userValue.data.id;
    this.userRole = this.accountService.ruolo;
    this.link = this.env.apiUrl + '/elastic/download_doc?id=';
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.searchformCorso = this.formBuilder.group({
      searchCorsi: ['', Validators.required],
    });
    this.searchformCancellati = this.formBuilder.group({
      searchCancellati: ['', Validators.required],
    });
    this.searchformCestinoCorsi = this.formBuilder.group({
      searchCorsiCancellati: ['', Validators.required],
    });

    if (!this.alfaintes) {
      this.maxUsersPaginator = 6;
    }
  }

  ngOnInit(): void {
    if (this.userRole === 1) {
      this.getIdUtenti();
    }
    this.fileService.getAll().subscribe(
      (res) => {
        //this.listaAvanzata = false;
        // this.listaFile = res.data.risultati;
        // if (this.fromPrenotaTecnico) {
        //   this.listaFile.forEach((element) => {
        //     if (this.prodotti.indexOf(element.id) === -1)
        //       element.selected = false;
        //     if (this.prodotti.indexOf(element.id) !== -1)
        //       element.selected = true;
        //   });
        // }
        // this.allListaFile = this.listaFile;
        //this.fileCancellati = res.data.eliminati;
        //this.allFileCancellati = this.fileCancellati;
        // this.numeroFile = this.listaFile.length;
        //this.numeroFileCancellati = this.allFileCancellati.length;
        this.selectedValue = 10;
        this.selectedValueCancellati = 10;
        this.getNumeroPagine();
        this.getNumeroPagineCancellati();
        this.carica = true;
        this.appoggioclick = 1;
        this.appoggioclickCancellati = 1;
        this.gestioneFile = true;
      },
      (error) => {}
    );

    if (!this.fromPrenotaTecnico) {
      this.getRoot(); //Ottiene dal backend la cartella principale
      this.getRootCestino();
    } else {
      this.fileService.getIdOrderFolder().subscribe((res) => {
        this.apriCartella(res.data.id);
      });
    }
    this.primengConfig.ripple = true;
    this.pagina = 1;
    // this.scormService.getLista().subscribe((res) => {
    //   this.listaCorsi=res.data;
    //   this.allListaCorsi=this.listaCorsi
    //   this.numeroFileCorsi = this.listaCorsi.length
    //   this.selectedValueCorsi= 10;
    //   this.appoggioclickCorsi=1;
    //   this.getNumeroPagineCorsi()
    //   }, (error) => {

    //   });
    this.getListaCorsiCestino(
      this.paginaCestinoCorsi,
      this.selectedValueCestinoCorsi
    );
    this.getListaCorsi(this.paginaCorsi, this.selectedValueCorsi);
    this.changeDialogSize();
  }

  ngAfterViewInit() {
    this.listUsers.paginator = this.paginatorMat;
  }
  ngDoCheck() {
    //quando nel filtra utenti non ritorna alcun utente la pagina si setta a 0, dunque cancellando il valore inserito riporta a pagina 1
    if (this.accountService.listaEnv && !this.env.DMS) {
      this.router.navigate(['']);
    }

    if (this.pagina === 0) {
      this.pagina = 1;
    }
    if (this.paginaCancellati === 0) {
      this.paginaCancellati = 1;
    }

    if (
      this.carica &&
      this.listaFile &&
      this.listaFile.length % this.selectedValue === 0
    ) {
      //divide numero utenti per il valare da mostrare scelto per capire quante pagine servono
      this.maxPag = this.listaFile.length / this.selectedValue;
    } else if (this.carica && this.listaFile) {
      this.maxPag = Math.trunc(this.listaFile.length / this.selectedValue) + 1;
    }

    if (
      this.carica &&
      this.allFileCancellati &&
      this.allFileCancellati.length % this.selectedValueCancellati === 0
    ) {
      //divide numero utenti per il valare da mostrare scelto per capire quante pagine servono
      this.maxPagCancellati =
        this.allFileCancellati.length / this.selectedValueCancellati;
    } else if (this.carica && this.allFileCancellati) {
      this.maxPagCancellati =
        Math.trunc(
          this.allFileCancellati.length / this.selectedValueCancellati
        ) + 1;
    }

    //console.log("fileCancellati: " + this.fileCancellati)
    // this.maxPag=this.allListaFile.lenght/this.selectedValue;

    // this.maxPagCancellati=this.allFileCancellati.lenght/this.selectedValueCancellati;
  }

  //METODI PER OTTENERE LA QUANTITà DI PAGINE DA MOSTRARE APPENA CARICATA LA PAGINA, QUINDI CON SELECTEDVALUE PREIMPOSTATO A 10
  getNumeroPagine() {
    this.pagine.length = 0; //array per paginazione, richiamato in html per far comparire numero di pagine
    this.k = 1; //var per la paginazione
    this.appoggioclick = this.pagina - 1; //si imposta la classe active sulla pagina in modo tale che risulta evidente quella su cui ci si trova

    if (this.listaFile) {
      if (this.listaFile.length % this.selectedValue === 0) {
        //divide numero utenti per il valare da mostrare scelto per capire quante pagine servono
        this.appoggioPagina = this.listaFile.length / this.selectedValue;
      } else {
        this.appoggioPagina =
          Math.trunc(this.listaFile.length / this.selectedValue) + 1;
      }
      for (let i = 0; i < this.appoggioPagina; i++) {
        //scorre appggioPagina che contiene il numero totale di pagine che bisogna generare
        this.pagine.push(this.k);
        this.k++;
      }
    }
  }
  getNumeroPagineCancellati() {
    //stessa logica di sopra, per utenti cancellato e in Cestino tab

    this.pagineCancellati.length = 0;
    this.j = 1;
    this.appoggioclickCancellati = this.paginaCancellati;

    if (this.fileCancellati) {
      if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
        this.appoggioPaginaCancellati =
          this.fileCancellati.length / this.selectedValueCancellati;
      } else {
        this.appoggioPaginaCancellati =
          Math.trunc(
            this.fileCancellati.length / this.selectedValueCancellati
          ) + 1;
      }
      for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
        this.pagineCancellati.push(this.j);
        this.j++;
      }
    }
  }

  //STESSA LOGICA DI GETNUMEROPAGINE, MA STAVOLTA RICHIAMATO OGNI VOLTA CHE SI CAMBIA SELECTED VALUE
  cambiaValue() {
    this.paginator = false;

    if (this.listaFile) {
      if (this.listaFile.length % this.selectedValue === 0) {
        this.appoggioPagina = this.listaFile.length / this.selectedValue;
        this.pagina = 1; //ogni volta che si cambia il valore si fa partire l'utente da pagina 1
      } else {
        this.appoggioPagina =
          Math.trunc(this.listaFile.length / this.selectedValue) + 1;
        this.pagina = 1;
      }
      for (let i = 0; i < this.appoggioPagina; i++) {
        this.pagine.push(this.k);
        this.k++;
      }
    }
    setTimeout(() => {
      this.paginator = true;
    });
  }
  cambiaValueCancellati() {
    //stessa logica di sopra, per utenti cancellato e in Cestino tab
    this.paginatorCestino = false;
    if (this.listaCancellati) {
      if (this.listaCancellati.length % this.selectedValueCancellati === 0) {
        this.appoggioPaginaCancellati =
          this.listaCancellati.length / this.selectedValueCancellati;
        this.paginaCancellati = 1;
      } else {
        this.appoggioPaginaCancellati =
          Math.trunc(
            this.listaCancellati.length / this.selectedValueCancellati
          ) + 1;
        this.paginaCancellati = 1;
      }
      for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
        this.pagineCancellati.push(this.k);
        this.k++;
      }
    }
    setTimeout(() => {
      this.paginatorCestino = true;
    });
  }

  //CLICK SU TASTO AVANTI
  next() {
    if (!(this.pagina * this.selectedValue > this.listaFile.length - 1)) {
      //se la pagina su cui ci troviamo moltiplicato per il valore da mostrare scelto è minore della quantità di utenti, allora va avanti; quindi non va avanti se stai all'ultima pagina
      this.pagina++;
      this.appoggioclick = this.pagina; //per bottone attivo
    }
  }

  nextCancellati() {
    //stessa logica sopra
    if (
      !(
        this.paginaCancellati * this.selectedValueCancellati >
        this.fileCancellati.length - 1
      )
    ) {
      this.paginaCancellati++;
      this.appoggioclickCancellati = this.paginaCancellati;
    }
  }

  //CLICK SU TASTO INDIETRO PAGINAZIONE
  back() {
    if (this.pagina > 1) {
      this.pagina--;
    }
    if (this.pagina === 1) {
      this.abilita = true;
    }
    if (this.pagina * this.selectedValue <= this.listaFile.length - 1) {
      this.disabilitaAvanti = false;
    }

    this.appoggioclick = this.pagina;
  }
  backCancellati() {
    if (this.paginaCancellati > 1) {
      this.paginaCancellati--;
    }
    if (this.paginaCancellati === 1) {
      this.abilitaCancellati = true;
    }
    if (
      this.paginaCancellati * this.selectedValueCancellati <=
      this.fileCancellati.length - 1
    ) {
      this.disabilitaAvantiCancellati = false;
    }
    this.appoggioclickCancellati = this.paginaCancellati;
  }

  //METODI PER CAMBIARE PAGINE QUANDO SI CLICCA PROPRIO SULLE PAGINE
  cambiaPagina(num) {
    this.cliccato = true;
    this.isClickable = false;
    if (this.listaFile.length > num * this.selectedValue) {
      this.pagina = num + 1;
      this.appoggioclick = this.pagina;
    }
    this.isClickable = true;
  }

  cambiaPaginaCancellati(num) {
    this.cliccato = true;
    this.isClickableCestino = false;
    if (this.fileCancellati.length > num * this.selectedValueCancellati) {
      this.paginaCancellati = num + 1;
      this.appoggioclickCancellati = this.paginaCancellati;
    }
    this.isClickableCestino = true;
  }

  //CLICK INIZIO PAGINAZIONE
  paginaUno() {
    this.pagina = 1;
    this.appoggioclick = this.pagina;
  }
  paginaUnoCancellati() {
    this.paginaCancellati = 1;
    this.appoggioclickCancellati = this.paginaCancellati;
  }

  //CLICK FINE PAGINAZIONE
  fine() {
    if (this.listaFile.length % this.selectedValue === 0) {
      this.pagina = this.listaFile.length / this.selectedValue;
      this.appoggioclick = this.pagina;
    } else {
      this.pagina = Math.trunc(this.listaFile.length / this.selectedValue) + 1;
      this.appoggioclick = this.pagina;
    }
  }

  fineCancellati() {
    if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
      this.paginaCancellati =
        this.fileCancellati.length / this.selectedValueCancellati;
      this.appoggioclickCancellati = this.paginaCancellati;
    } else {
      this.paginaCancellati =
        Math.trunc(this.fileCancellati.length / this.selectedValueCancellati) +
        1;
      this.appoggioclickCancellati = this.paginaCancellati;
    }
  }

  lastValue;
  //RICERCA FILTRO PER LISTA UTENTI TAB
  searchInFile() {
    this.ricordaPagina = 1;
    if (this.searchform.value.search.length > 0) {
      this.ricordaLunghezza = this.searchform.value.search.length;
      this.flag = true;
      if (this.pagina !== 1) {
        this.secondo = true;
        this.ricordaPagina = this.pagina;
      }
      if (!this.secondo) {
        this.pagina = 1;
      }
    } else if (
      this.ricordaLunghezza !== this.searchform.value.search.length &&
      this.flag
    ) {
      this.ricordaPagina = this.pagina;
      this.flag = false;
    }

    let valore: string = this.searchform.value.search;
    this.listaFile = this.allListaFile;

    if (valore) {
      this.listafileappoggio = [];
      this.listaFile.forEach((file) => {
        if (
          file.title.toLowerCase().includes(valore.toLowerCase()) ||
          /*file.category.toLowerCase().includes(valore.toLowerCase()) ||
          file.group.toLowerCase().includes(valore.toLowerCase()) ||
          (file.authors &&
            file.authors[0] &&
            file.authors[0].name &&
            file.authors[0]?.name?.toLowerCase() &&
            file.authors[0]?.name
              ?.toLowerCase()
              .includes(valore.toLowerCase())) ||*/
          file.extension.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!this.listafileappoggio.find((x) => x === file)) {
            this.listafileappoggio.push(file);
          }
        }
      });
      this.listaFile = this.listafileappoggio;
      this.numeroFile = this.listaFile.length;
      this.getNumeroPagine();
      if (this.pagina > this.appoggioPagina) {
        this.pagina = this.appoggioPagina - 1;
        this.appoggioclick = this.pagina;
      }
    } else if (!valore) {
      this.listaFile = this.allListaFile;
      if (this.listaFile) {
        this.numeroFile = this.listaFile.length;
      } else {
        this.numeroFile = 0;
      }
      this.getNumeroPagine();

      // this.cd.markForCheck();
      // return
    }
    if (this.lastValue !== valore) {
      this.cambiaPagina(0);
      this.lastValue = valore;
    }
  }
  onKeyDownEvent(event) {
    this.paginator = false;
    setTimeout(() => {
      this.paginator = true;
    }, 100);
  }
  searchInFileCancellati() {
    this.ricordaPaginaCancellati = 1;
    if (this.searchformCancellati.value.searchCancellati.length > 0) {
      this.ricordaLunghezzaCancellati =
        this.searchformCancellati.value.searchCancellati.length;
      this.flagCancellati = true;
      if (this.paginaCancellati !== 1) {
        this.secondoCancellati = true;
        this.ricordaPaginaCancellati = this.paginaCancellati;
      }
      if (!this.secondoCancellati) {
        this.paginaCancellati = 1;
      }
    } else if (
      this.ricordaLunghezzaCancellati !==
        this.searchformCancellati.value.searchCancellati.length &&
      this.flagCancellati
    ) {
      this.paginaCancellati = this.ricordaPaginaCancellati;
      this.flagCancellati = false;
    }
    let valore: string = this.searchformCancellati.value.searchCancellati;
    this.fileCancellati = this.allFileCancellati;
    if (valore) {
      let listaFile = [];
      this.fileCancellati.forEach((file) => {
        if (
          file.title.toLowerCase().includes(valore.toLowerCase()) ||
          /*file.category.toLowerCase().includes(valore.toLowerCase()) ||
          file.group.toLowerCase().includes(valore.toLowerCase()) ||
          (file.authors &&
            file.authors[0] &&
            file.authors[0].name &&
            file.authors[0]?.name?.toLowerCase() &&
            file.authors[0]?.name
              ?.toLowerCase()
              .includes(valore.toLowerCase())) |*/
          file.extension.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!listaFile.find((x) => x === file)) {
            listaFile.push(file);
          }
        }
      });
      this.fileCancellati = listaFile;
      this.numeroFileCancellati = this.fileCancellati.length;
      if (this.numeroFileCancellati <= this.selectedValueCancellati) {
        this.paginaCancellati = 1;
      }
      this.getNumeroPagineCancellati();
      if (this.paginaCancellati > this.appoggioPaginaCancellati) {
        this.paginaCancellati = this.appoggioPaginaCancellati;
        this.appoggioclickCancellati = this.paginaCancellati;
      }
    } else if (!valore) {
      this.fileCancellati = this.allFileCancellati;
      if (this.fileCancellati) {
        this.numeroFileCancellati = this.fileCancellati.length;
      } else {
        this.numeroFileCancellati = 0;
      }
      this.getNumeroPagineCancellati();
    }
  }

  elimina(id, isFolder: Boolean) {
    this.eliminaCartella = isFolder;
    this.idFile = id;
    this.confirmationService.confirm({
      key: 'cancellaFisica',
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }

  cestina(id) {
    this.cancellaFile = true;
    this.idFile = id;
    this.confirmationService.confirm({
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' Il file verrà cestinato.',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.loading = false;
        this.cancellaFile = false;
        this.confirmationService.close();
      },
    });
  }

  //METODO PER PASSARE DALLA GESTIONE UTENTI AL VISUALIZZA INFO DI UN UTENTE
  visualizza(file) {
    this.fileSelezionato = file;
    this.gestione = false;
    this.dettagli = true;
  }

  vediInfo(id) {
    this.idFile = id;
    this.gestione = false;
    this.dettagli = true;
  }

  indietroDainfo(event) {
    this.refreshView = true;
    this.gestione = true;
    this.dettagli = false;
    this.fileSelezionato = null;
    this.infoCorsoB = false;
    if (event == 'corso') {
      setTimeout(() => {
        let crx = document.getElementById('corsiClick');
        crx.click();
        this.refreshView = false;
      });
    } else {
      this.refreshView = false;
    }
  }

  indietroDaRicerca() {
    this.gestione = !this.gestione;
    this.ricercaAvanzata = !this.ricercaAvanzata;
  }

  listaRicercaAvanzata(lista) {
    this.listaAvanzata = true;
    this.gestione = !this.gestione;
    this.ricercaAvanzata = !this.ricercaAvanzata;
    this.listaFile = lista;
    this.allListaFile = this.listaFile;
    this.numeroFile = this.listaFile.length;
    this.selectedValue = 10;
    this.getNumeroPagine();
    this.carica = true;
    this.appoggioclick = 1;
    this.pagina = 0;
    this.cambiaPagina(0);
  }

  visualizzaTuttiFile() {
    this.fileService.getAll().subscribe(
      (res) => {
        this.listaFile = res.data.risultati;
        this.allListaFile = this.listaFile;
        this.numeroFile = this.listaFile.length;
        this.selectedValue = 10;
        this.getNumeroPagine();
        this.carica = true;
        this.appoggioclick = 1;
        this.listaAvanzata = false;
      },
      (error) => {}
    );
  }

  vaiRicerca() {
    this.gestione = false;
    this.ricercaAvanzata = true;
  }

  onVoted(agreed: boolean) {
    agreed ? (this.informazioni = false) : (this.informazioni = true);
  }
  editFileModal = false;
  idFileToModify;
  editFileService() {
    let body = {
      title: this.nameFile,
      idCartella: this.folders[this.folders.length - 1].id,
    };
    this.fileService.editFileDms(this.idFileToModify, body).subscribe((res) => {
      this.alertService.success('Operazione effettuata con successo');
      this.editFileModal = false;
      this.apriCartella(this.cartellaCorrente.id, false, true);
      this.allowClickOpenFolder = true;
    });
  }
  editFile(file) {
    this.idFileToModify = file.id;
    this.showTable = false;
    this.nameFile = file.title;
    this.rememberPathFolders = this.rememberPath + this.cartellaCorrente.nome;
    this.fileService.getFolder(this.cartellaCorrente.id).subscribe((res) => {
      this.folders = res.data.childFolders;
      this.folders.unshift({
        folderName: '..',
        id: res.data.parent_id,
      });
      this.folders.push({
        folderName: 'doNotShow',
        id: res.data.id,
      });
    });
    this.allowClickOpenFolder = false;
    this.editFileModal = !this.editFileModal;
  }

  ripristina(id) {
    this.idRipristina = id;
    //this.stato = stato;
    this.confirmationService.confirm({
      key: 'ripristina',
      message: 'Ripristinare il file?',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.confirmationService.close();
        this.loading = false;
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      },
    });
  }

  listaCancellati() {
    this.vediFile = false;
    this.vediCancellati = true;
  }

  listaPresenti() {
    this.vediFile = true;
    this.vediCancellati = false;
  }
  indietroFlag = false;
  indietro(event) {
    this.editCorsoB = false;
    this.indietroFlag = true;
    this.refreshView = true;
    this.idFile = null;
    this.getListaCorsi(this.paginaCorsi, this.selectedValue);
    this.apriCartella(this.cartellaCorrente.id);
    if (event == 'corso') {
      setTimeout(() => {
        let crx = document.getElementById('corsiClick');
        crx.click();
        this.refreshView = false;
      }, 10);
    } else {
      this.refreshView = false;
    }
    this.modifica = false;
    this.gestione = true;

    // this.scormService.getLista().subscribe((res) => {
    //   this.listaCorsi = res.data;
    //   this.allListaCorsi = this.listaCorsi
    //   this.numeroFileCorsi = this.listaCorsi.length
    //   this.selectedValueCorsi = 10;
    //   this.appoggioclickCorsi = 1;
    //   this.getNumeroPagineCorsi()
    // }, (error) => {

    // });
  }

  accettaCancella() {
    this.loading = true;
    this.fileService.deleteFile(this.idFile).subscribe((res1) => {
      this.fileService.getAll().subscribe(
        (res) => {
          this.listaAvanzata = false;
          this.listaFile = res.data.risultati;
          this.allListaFile = this.listaFile;
          this.fileCancellati = res.data.eliminati;
          this.allFileCancellati = this.fileCancellati;
          this.numeroFile = this.listaFile.length;
          this.numeroFileCancellati = this.allFileCancellati.length;
          if (this.listaFile) {
            if (this.listaFile.length % this.selectedValue === 0) {
              this.appoggioPagina = this.listaFile.length / this.selectedValue;
              this.pagina = this.appoggioPagina;
              this.paginator = false;
              setTimeout(() => {
                this.paginator = true;
              });
            } else {
              this.appoggioPagina =
                Math.trunc(this.listaFile.length / this.selectedValue) + 1;
            }
            for (let i = 0; i < this.appoggioPagina; i++) {
              this.pagine.push(this.k);
              this.k++;
            }
          }
          if (this.fileCancellati) {
            if (
              this.fileCancellati.length % this.selectedValueCancellati ===
              0
            ) {
              this.appoggioPaginaCancellati =
                this.fileCancellati.length / this.selectedValueCancellati;
              this.paginaCancellati = this.appoggioPaginaCancellati;
              this.paginatorCestino = false;
              setTimeout(() => {
                this.paginatorCestino = true;
              });
            } else {
              this.appoggioPaginaCancellati =
                Math.trunc(
                  this.fileCancellati.length / this.selectedValueCancellati
                ) + 1;
            }
            for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
              this.pagineCancellati.push(this.j);
              this.j++;
            }
          }
          this.carica = true;
          this.gestioneFile = true;
          this.alertService.success(res1.status.description);
          this.confirmationService.close();
          this.cancellaFile = false;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    });
  }

  /*  accettaRipristina() {
    this.loading = true;
    this.scormService.verifica_corso_file(this.idRipristina).subscribe((res) => {
      if (res.data === true) {
        if (!this.stato) {
          this.recuperaCorso(this.idRipristina);
        } else {
          this.eliminaCorsoDefinitivo(this.idRipristina);
        }
      } else {
        if (!this.stato) {
          this.fileService.restore(this.idRipristina).subscribe((res1) => {
            this.fileService.getAll().subscribe((res) => {
              this.listaAvanzata = false;
              this.listaFile = res.data.risultati;
              this.allListaFile = this.listaFile;
              this.fileCancellati = res.data.eliminati;
              this.allFileCancellati = this.fileCancellati
              this.numeroFile = this.listaFile.length
              this.numeroFileCancellati = this.allFileCancellati.length;
              if (this.listaFile) {
                if (this.listaFile.length % this.selectedValue === 0) {
                  this.appoggioPagina = this.listaFile.length / this.selectedValue;
                  this.pagina = this.appoggioPagina;
                  this.paginator = false
                  setTimeout(() => {
                    this.paginator = true
                  })
                }
                else {
                  this.appoggioPagina = Math.trunc((this.listaFile.length / this.selectedValue)) + 1;
                }
                for (let i = 0; i < this.appoggioPagina; i++) {
                  this.pagine.push(this.k);
                  this.k++;
                }
              }
              if (this.fileCancellati) {
                if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
                  this.appoggioPaginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
                  this.paginaCancellati = this.appoggioPaginaCancellati;
                  this.paginatorCestino = false
                  setTimeout(() => {
                    this.paginatorCestino = true;
                  });
                }
                else {
                  this.appoggioPaginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
                }
                for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
                  this.pagineCancellati.push(this.j);
                  this.j++;
                }
              }
              this.carica = true;
              this.gestioneFile = true;
              this.loading = false;
              this.confirmationService.close();
              this.alertService.success(res1.status.description);
            }, (error) => {
              this.loading = false;
            });
          });
        }
        else {
          this.eliminaDef(this.idFile)
        }
      }
    })
  }*/

  accettaRipristina() {
    this.loading = true;
    this.fileService.restoreFile(this.idRipristina).subscribe(
      (res) => {
        this.apriCartellaCestino(this.cartellaCorrenteCestino.id);
        this.apriCartella(this.cartellaCorrente.id, false, false, true);
        this.listaAvanzata = false;
        //this.loading = false;
        this.confirmationService.close();
        this.alertService.success(res.status.description);
        this.activeTab = '#pill2'; //fix 1357
      },
      (error) => {
        this.loading = false;
        this.confirmationService.close();
        this.alertService.error(error.description);
      }
    );
  }

  addFile() {
    this.idFile = null;
    this.gestione = false;
    this.modifica = true;
  }

  ordinaArray(filtro) {
    if (filtro === 'nome') {
      if (!this.ordinaNome) {
        this.listaFile.sort((a, b) =>
          a.title.toLowerCase() < b.title.toLowerCase()
            ? -1
            : a.title.toLowerCase() > b.title.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaNome = true;
      } else {
        this.listaFile.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase()
            ? -1
            : a.title.toLowerCase() < b.title.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaNome = false;
      }
      this.allListaFile = this.listaFile;
      this.ordinaCategoria = false;
      this.ordinaGruppo = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'categoria') {
      if (!this.ordinaCategoria) {
        this.listaFile.sort((a, b) =>
          a.category.toLowerCase() < b.category.toLowerCase()
            ? -1
            : a.category.toLowerCase() > b.category.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaCategoria = true;
      } else {
        this.listaFile.sort((a, b) =>
          a.category.toLowerCase() > b.category.toLowerCase()
            ? -1
            : a.category.toLowerCase() < b.category.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaCategoria = false;
      }
      this.allListaFile = this.listaFile;
      this.ordinaNome = false;
      this.ordinaGruppo = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'gruppo') {
      if (!this.ordinaGruppo) {
        this.listaFile.sort((a, b) =>
          a.group.toLowerCase() < b.group.toLowerCase()
            ? -1
            : a.group.toLowerCase() > b.group.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaGruppo = true;
      } else {
        this.listaFile.sort((a, b) =>
          a.group.toLowerCase() > b.group.toLowerCase()
            ? -1
            : a.group.toLowerCase() < b.group.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaGruppo = false;
      }
      this.allListaFile = this.listaFile;
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'data') {
      if (!this.ordinaData) {
        this.listaFile.sort((a, b) =>
          a.uploadDate < b.uploadDate ? -1 : a.uploadDate > b.uploadDate ? 1 : 0
        );
        this.ordinaData = true;
      } else {
        this.listaFile.sort((a, b) =>
          a.uploadDate > b.uploadDate ? -1 : a.uploadDate < b.uploadDate ? 1 : 0
        );
        this.ordinaData = false;
      }
      this.allListaFile = this.listaFile;
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaGruppo = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'estensione') {
      if (!this.ordinaEstensione) {
        this.listaFile.sort((a, b) =>
          a.extension.toLowerCase() < b.extension.toLowerCase()
            ? -1
            : a.extension.toLowerCase() > b.extension.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaEstensione = true;
      } else {
        this.listaFile.sort((a, b) =>
          a.extension.toLowerCase() > b.extension.toLowerCase()
            ? -1
            : a.extension.toLowerCase() < b.extension.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaEstensione = false;
      }
      this.allListaFile = this.listaFile;
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaData = false;
      this.ordinaGruppo = false;
    }
  }

  eliminaDef(id) {
    this.fileService.deleteDef(id).subscribe((res1) => {
      this.fileService.getAll().subscribe(
        (res) => {
          this.listaAvanzata = false;
          this.listaFile = res.data.risultati;
          this.allListaFile = this.listaFile;
          this.fileCancellati = res.data.eliminati;
          this.allFileCancellati = this.fileCancellati;
          this.numeroFile = this.listaFile.length;
          this.numeroFileCancellati = this.allFileCancellati.length;
          if (this.listaFile) {
            if (this.listaFile.length % this.selectedValue === 0) {
              this.appoggioPagina = this.listaFile.length / this.selectedValue;
              this.pagina = this.appoggioPagina;
              this.paginator = false;
              setTimeout(() => {
                this.paginator = true;
              });
            } else {
              this.appoggioPagina =
                Math.trunc(this.listaFile.length / this.selectedValue) + 1;
            }
            for (let i = 0; i < this.appoggioPagina; i++) {
              this.pagine.push(this.k);
              this.k++;
            }
          }
          if (this.fileCancellati) {
            if (
              this.fileCancellati.length % this.selectedValueCancellati ===
              0
            ) {
              this.appoggioPaginaCancellati =
                this.fileCancellati.length / this.selectedValueCancellati;
              this.paginaCancellati = this.appoggioPaginaCancellati;
              this.paginatorCestino = false;
              setTimeout(() => {
                this.paginatorCestino = true;
              });
            } else {
              this.appoggioPaginaCancellati =
                Math.trunc(
                  this.fileCancellati.length / this.selectedValueCancellati
                ) + 1;
            }
            for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
              this.pagineCancellati.push(this.j);
              this.j++;
            }
          }
          this.carica = true;
          this.gestioneFile = true;
          // this.alertService.success(res1.status.description);
          this.confirmationService.close();
          this.cancellaFile = false;
          this.loading = false;
          this.alertService.success('Operazione effettuata con successo');
        },
        (error) => {
          this.loading = false;
          this.confirmationService.close();
          this.alertService.error(error.description);
        }
      );
    });
  }

  getNumeroPagineCorsi() {
    this.pagineCorsi.length = 0; //array per paginazione, richiamato in html per far comparire numero di pagine
    this.c = 1; //var per la paginazione
    this.appoggioclickCorsi = this.paginaCorsi; //si imposta la classe active sulla pagina in modo tale che risulta evidente quella su cui ci si trova
    if (this.listaCorsi) {
      if (this.listaCorsi.length % this.selectedValueCorsi === 0) {
        //divide numero utenti per il valare da mostrare scelto per capire quante pagine servono
        this.appoggioPaginaCorsi =
          this.listaCorsi.length / this.selectedValueCorsi;
      } else {
        this.appoggioPaginaCorsi =
          Math.trunc(this.listaCorsi.length / this.selectedValueCorsi) + 1;
      }
      for (let i = 0; i < this.appoggioPaginaCorsi; i++) {
        //scorre appggioPagina che contiene il numero totale di pagine che bisogna generare
        this.pagineCorsi.push(this.c);
        this.c++;
      }
    }
  }

  //  cambiaValueCorsi() {

  //     if (this.listaCorsi) {
  //       if (this.listaCorsi.length % this.selectedValueCorsi === 0) {
  //         this.appoggioPaginaCorsi = this.listaCorsi.length / this.selectedValueCorsi;
  //         this.paginaCorsi = 1;//ogni volta che si cambia il valore si fa partire l'utente da pagina 1
  //       }
  //       else {
  //         this.appoggioPaginaCorsi = Math.trunc((this.listaCorsi.length / this.selectedValueCorsi)) + 1;
  //         this.paginaCorsi = 1;

  //       }
  //       for (let i = 0; i < this.appoggioPaginaCorsi; i++) {
  //         this.pagineCorsi.push(this.c);
  //         this.c++;
  //       }
  //     }
  //   }
  // nextCorsi() {
  //   if (!(this.paginaCorsi * this.selectedValueCorsi > this.listaCorsi.length - 1))//se la pagina su cui ci troviamo moltiplicato per il valore da mostrare scelto è minore della quantità di utenti, allora va avanti; quindi non va avanti se stai all'ultima pagina
  //   {
  //     this.paginaCorsi++;
  //     this.appoggioclickCorsi = this.paginaCorsi;//per bottone attivo
  //   }
  // }
  // backCorsi() {
  //   if (this.paginaCorsi > 1) {
  //     this.paginaCorsi--;
  //   }
  //   if (this.paginaCorsi === 1) {
  //     this.abilita = true;
  //   }
  //   if (this.paginaCorsi * this.selectedValueCorsi <= this.listaCorsi.length - 1) {
  //     this.disabilitaAvanti = false;
  //   }

  //   this.appoggioclickCorsi = this.paginaCorsi;
  // }
  // cambiaPaginaCorso(num) {
  //   this.cliccato = true;

  //   if (this.listaCorsi.length > num * this.selectedValueCorsi){
  //     this.paginaCorsi = num + 1;
  //     this.appoggioclickCorsi = this.paginaCorsi;
  //   }

  // }
  // paginaUnoCorsi() {

  //   this.paginaCorsi = 1;
  //   this.appoggioclickCorsi= this.paginaCorsi;
  // }
  // fineCorsi() {
  //   if (this.listaCorsi.length % this.selectedValueCorsi === 0) {
  //     this.paginaCorsi = this.listaCorsi.length / this.selectedValueCorsi;
  //     this.appoggioclickCorsi = this.paginaCorsi;

  //   } else {
  //     this.paginaCorsi = Math.trunc((this.listaCorsi.length / this.selectedValueCorsi)) + 1;
  //     this.appoggioclickCorsi = this.paginaCorsi;
  //   }

  // }
  ordinaArrayCorsi(filtro) {
    if (filtro === 'nome') {
      if (!this.ordinaNome) {
        this.listaCorsi.sort((a, b) =>
          a.nomeCorso.toLowerCase() < b.nomeCorso.toLowerCase()
            ? -1
            : a.nomeCorso.toLowerCase() > b.nomeCorso.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaNome = true;
      } else {
        this.listaCorsi.sort((a, b) =>
          a.nomeCorso.toLowerCase() > b.nomeCorso.toLowerCase()
            ? -1
            : a.nomeCorso.toLowerCase() < b.nomeCorso.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaNome = false;
      }
      this.allListaCorsi = this.listaCorsi;
      this.ordinaCategoria = false;
      this.ordinaGruppo = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'categoria') {
      if (!this.ordinaCategoria) {
        this.listaCorsi.sort((a, b) =>
          a.categoria.toLowerCase() < b.categoria.toLowerCase()
            ? -1
            : a.categoria.toLowerCase() > b.categoria.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaCategoria = true;
      } else {
        this.listaCorsi.sort((a, b) =>
          a.categoria.toLowerCase() > b.categoria.toLowerCase()
            ? -1
            : a.categoria.toLowerCase() < b.categoria.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaCategoria = false;
      }
      this.allListaCorsi = this.listaCorsi;
      this.ordinaNome = false;
      this.ordinaGruppo = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'gruppo') {
      if (!this.ordinaGruppo) {
        this.listaCorsi.sort((a, b) =>
          a.gruppo.toLowerCase() < b.gruppo.toLowerCase()
            ? -1
            : a.gruppo.toLowerCase() > b.gruppo.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaGruppo = true;
      } else {
        this.listaCorsi.sort((a, b) =>
          a.gruppo.toLowerCase() > b.gruppo.toLowerCase()
            ? -1
            : a.gruppo.toLowerCase() < b.gruppo.toLowerCase()
            ? 1
            : 0
        );
        this.ordinaGruppo = false;
      }
      this.allListaCorsi = this.listaCorsi;
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaData = false;
      this.ordinaEstensione = false;
    }
    if (filtro === 'data') {
      if (!this.ordinaData) {
        this.listaCorsi.sort((a, b) =>
          a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
        );
        this.ordinaData = true;
      } else {
        this.listaCorsi.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
        );
        this.ordinaData = false;
      }
      this.allListaCorsi = this.listaCorsi;
      this.ordinaNome = false;
      this.ordinaCategoria = false;
      this.ordinaGruppo = false;
      this.ordinaEstensione = false;
    }
  }

  // searchInCorsi() {

  //   this.ricordaPaginaCorso = 1;
  //   if (this.searchformCorso.value.searchCorsi.length > 0) {

  //     this.ricordaLunghezzaCorso = this.searchformCorso.value.searchCorsi.length;
  //     this.flagCorso = true

  //     if (this.paginaCorsi !== 1) {
  //       this.secondoCorso = true
  //       this.ricordaPaginaCorso = this.pagina;
  //     }
  //     if (!this.secondoCorso) {
  //       this.paginaCorsi = 1;
  //     }
  //   } else if (this.ricordaLunghezzaCorso !== this.searchformCorso.value.searchCorsi.length && this.flagCorso) {

  //     this.ricordaPaginaCorso = this.paginaCorsi;
  //     this.flagCorso = false
  //   }

  //   let valore: string = this.searchformCorso.value.searchCorsi;
  //   this.listaCorsi = this.allListaCorsi;

  //   if (valore) {

  //     this.listaCorsiappoggio = []

  //     this.listaCorsi.forEach(file => {

  //       if (file.nomeCorso.toLowerCase().includes(valore.toLowerCase()) ||
  //         file.categoria.toLowerCase().includes(valore.toLowerCase()) ||
  //         file.gruppo.toLowerCase().includes(valore.toLowerCase())
  //       ) {
  //         if (!(this.listaCorsiappoggio.find(x => x === file))) {
  //           this.listaCorsiappoggio.push(file);
  //         }
  //       }
  //     });

  //     this.listaCorsi = this.listaCorsiappoggio;
  //     this.numeroFileCorsi = this.listaCorsi.length
  //     this.getNumeroPagineCorsi();

  //     if (this.paginaCorsi > this.appoggioPaginaCorsi) {
  //       this.paginaCorsi = this.appoggioPaginaCorsi;
  //       this.appoggioclickCorsi = this.paginaCorsi;
  //     }

  //   } else if (!valore) {
  //     this.listaCorsi = this.allListaCorsi;
  //     if (this.listaCorsi) {
  //       this.numeroFileCorsi = this.listaCorsi.length;
  //     }
  //     else {
  //       this.numeroFileCorsi = 0;
  //     }
  //     this.getNumeroPagineCorsi();
  //   }
  // }
  infoCorso(corso) {
    this.corso = corso;
    this.infoCorsoB = true;
    this.gestione = false;
  }

  editCorso(corso) {
    this.corso = corso;
    this.editCorsoB = true;
    this.gestione = false;
  }

  cancellaCorso(idCorso, stato?) {
    this.cancellaCorsob = true;
    this.idCorso = idCorso;
    if (stato) {
      this.statoCorso = stato;
    }
    this.confirmationService.confirm({
      key: 'cancellaCorso',
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.cancellaCorsob = false;
        this.loadingCorso = false;
        this.confirmationService.close();
      },
    });
  }

  cancellaCorsoMethod() {
    this.loading = false;
    this.scormService.cestino(this.idCorso).subscribe(
      (res1) => {
        this.alertService.success(res1.status.description);
        this.confirmationService.close();
        if (this.listaCorsi.length == 0) {
          if (this.paginaCorsi != 0) {
            this.paginaCorsi = this.paginaCorsi - 1;
            this.getListaCorsi(this.paginaCorsi, this.selectedValue);
            this.paginaCorsi = this.numeroMaxPagineCorsi - 1;
          } else {
            this.getListaCorsi(this.paginaCorsi, this.selectedValue);
          }
        } else {
          this.getListaCorsi(this.paginaCorsi, this.selectedValue);
        }
        if (this.listaCorsiCestino.length == 0) {
          if (this.paginaCestinoCorsi != 0) {
            this.paginaCestinoCorsi = this.paginaCestinoCorsi - 1;
            this.getListaCorsiCestino(
              this.paginaCestinoCorsi,
              this.selectedValue
            );
            this.paginaCestinoCorsi = this.numeroMaxPagineCestinoCorsi - 1;
          } else {
            this.getListaCorsiCestino(
              this.paginaCestinoCorsi,
              this.selectedValue
            );
          }
        } else {
          this.getListaCorsiCestino(
            this.paginaCestinoCorsi,
            this.selectedValue
          );
        }

        // this.scormService.getLista().subscribe((res) => {
        //   this.listaCorsi=res.data;
        //   this.allListaCorsi=this.listaCorsi
        //   this.numeroFileCorsi = this.listaCorsi.length
        //   if (this.listaCorsi) {
        //     if (this.listaCorsi.length % this.selectedValueCorsi === 0) {
        //       this.appoggioPaginaCorsi = this.listaCorsi.length / this.selectedValueCorsi;
        //       this.paginaCorsi = this.appoggioPaginaCorsi;
        //     }
        //     else {
        //       this.appoggioPaginaCorsi = Math.trunc((this.listaCorsi.length / this.selectedValueCorsi)) + 1;
        //     }
        //     for (let i = 0; i < this.appoggioPaginaCorsi; i++) {
        //       this.pagineCorsi.push(this.c);
        //       this.c++;
        //     }
        //   }
        //   this.gestioneFile = true;
        //   this.loading = false;
        //   this.confirmationService.close();
        //   }, (error) => {
        //     this.loading=false;
        //   });

        this.fileService.getAll().subscribe(
          (res) => {
            this.fileCancellati = res.data.eliminati;
            this.allFileCancellati = this.fileCancellati;
            this.numeroFileCancellati = this.allFileCancellati.length;
            if (this.fileCancellati) {
              if (
                this.fileCancellati.length % this.selectedValueCancellati ===
                0
              ) {
                this.appoggioPaginaCancellati =
                  this.fileCancellati.length / this.selectedValueCancellati;
                this.paginaCancellati = this.appoggioPaginaCancellati;
                this.paginatorCestino = false;
                setTimeout(() => {
                  this.paginatorCestino = true;
                });
                //     this.loading = false;
              } else {
                this.appoggioPaginaCancellati =
                  Math.trunc(
                    this.fileCancellati.length / this.selectedValueCancellati
                  ) + 1;
              }
              for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
                this.pagineCancellati.push(this.j);
                this.j++;
              }
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      (error) => {}
    );
  }

  recuperaCorso(idCorso) {
    this.loading = true;
    this.paginatorCorsi = false;
    this.paginatorCestinoCorsi = false;
    this.scormService.ripristinoDaCestino(idCorso).subscribe(
      (res1) => {
        this.getListaCorsi(this.paginaCorsi, this.selectedValue);
        if (this.listaCorsi.length == 1) {
          if (this.paginaCorsi != 0) {
            this.paginaCorsi = this.paginaCorsi - 1;
            this.getListaCorsi(this.paginaCorsi, this.selectedValue);
          } else {
            this.getListaCorsi(this.paginaCorsi, this.selectedValue);
          }
        } else {
          this.getListaCorsi(this.paginaCorsi, this.selectedValue);
        }
        if (this.listaCorsiCestino.length == 1) {
          if (this.paginaCestinoCorsi != 0) {
            this.paginaCestinoCorsi = this.paginaCestinoCorsi - 1;
            this.getListaCorsiCestino(
              this.paginaCestinoCorsi,
              this.selectedValue
            );
            this.paginaCestinoCorsi = this.numeroMaxPagineCestinoCorsi - 1;
          } else {
            this.getListaCorsiCestino(
              this.paginaCestinoCorsi,
              this.selectedValue
            );
          }
        } else {
          this.getListaCorsiCestino(
            this.paginaCestinoCorsi,
            this.selectedValue
          );
        }
        this.alertService.success(res1.status.description);
        //   this.scormService.getLista().subscribe((res) => {
        //     this.listaCorsi=res.data;
        //     this.allListaCorsi=this.listaCorsi
        //     this.numeroFileCorsi = this.listaCorsi.length
        //     if (this.listaCorsi) {
        //       if (this.listaCorsi.length % this.selectedValueCorsi === 0) {
        //         this.appoggioPaginaCorsi = this.listaCorsi.length / this.selectedValueCorsi;
        //         this.paginaCorsi = this.appoggioPaginaCorsi;
        //       }
        //       else {
        //         this.appoggioPaginaCorsi = Math.trunc((this.listaCorsi.length / this.selectedValueCorsi)) + 1;
        //       }
        //       for (let i = 0; i < this.appoggioPaginaCorsi; i++) {
        //         this.pagineCorsi.push(this.c);
        //         this.c++;
        //       }
        //     }
        //     this.gestioneFile = true;
        //     this.loading = false;
        //     this.confirmationService.close();
        //     }, (error) => {
        //       this.loading=false;
        //     });
        //   this.fileService.getAll().subscribe((res) => {
        //     this.fileCancellati = res.data.eliminati;
        //     this.allFileCancellati = this.fileCancellati
        //     this.numeroFileCancellati = this.allFileCancellati.length;
        //     if (this.fileCancellati) {
        //       if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
        //         this.appoggioPaginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
        //         this.paginaCancellati = this.appoggioPaginaCancellati;
        //         this.paginatorCestino = false
        //         setTimeout(() => {
        //           this.paginatorCestino = true;
        //         });
        //       }
        //       else {
        //         this.appoggioPaginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
        //       }
        //       for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
        //         this.pagineCancellati.push(this.j);
        //         this.j++;
        //       }
        //     }
        //     this.loading = false;
        //   }, (error) => {
        //     this.loading = false;
        //   });
        // }, (error) => {
        this.confirmationService.close();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.confirmationService.close();
        this.alertService.error(error.description);
      }
    );
  }

  eliminaCorsoDefinitivo(idCorso) {
    this.loading = true;
    this.paginatorCorsi = false;
    this.paginatorCestinoCorsi = false;
    this.scormService.eliminazioneFisicaCorso(idCorso).subscribe(
      (res1) => {
        this.alertService.success(res1.status.description);
        if (this.listaCorsi.length == 1) {
          if (this.paginaCorsi != 0) {
            this.paginaCorsi = this.paginaCorsi - 1;
            this.getListaCorsi(this.paginaCorsi, this.selectedValue);
            this.paginaCorsi = this.numeroMaxPagineCorsi - 1;
          } else {
            this.getListaCorsi(this.paginaCorsi, this.selectedValue);
          }
        } else {
          this.getListaCorsi(this.paginaCorsi, this.selectedValue);
        }
        if (this.listaCorsiCestino.length == 1) {
          if (this.paginaCestinoCorsi != 0) {
            this.paginaCestinoCorsi = this.paginaCestinoCorsi - 1;
            this.getListaCorsiCestino(
              this.paginaCestinoCorsi,
              this.selectedValue
            );
            this.paginaCestinoCorsi = this.numeroMaxPagineCestinoCorsi - 1;
          } else {
            this.getListaCorsiCestino(
              this.paginaCestinoCorsi,
              this.selectedValue
            );
          }
        } else {
          this.getListaCorsiCestino(
            this.paginaCestinoCorsi,
            this.selectedValue
          );
        }
        //   this.fileService.getAll().subscribe((res) => {
        //     this.fileCancellati = res.data.eliminati;
        //     this.allFileCancellati = this.fileCancellati
        //     this.numeroFileCancellati = this.allFileCancellati.length;
        //     if (this.fileCancellati) {
        //       if (this.fileCancellati.length % this.selectedValueCancellati === 0) {
        //         this.appoggioPaginaCancellati = this.fileCancellati.length / this.selectedValueCancellati;
        //         this.paginaCancellati = this.appoggioPaginaCancellati;
        //       }
        //       else {
        //         this.appoggioPaginaCancellati = Math.trunc((this.fileCancellati.length / this.selectedValueCancellati)) + 1;
        //       }
        //       for (let i = 0; i < this.appoggioPaginaCancellati; i++) {
        //         this.pagineCancellati.push(this.j);
        //         this.j++;
        //       }
        //     }
        //     this.confirmationService.close();
        //     this.loading = false;
        //   }, (error) => {
        this.loading = false;
        this.confirmationService.close();
        //   });
        // }, (error) => {
        //   this.confirmationService.close();
      },
      (error) => {
        this.confirmationService.close();
        this.loading = false;
      }
    );
  }

  /*eliminaFisica(id, isFolder:Boolean) {
    this.idRipristina = id;
    this.eliminaCartella = isFolder;
    this.confirmationService.confirm({
      key: 'cancellaFisica',
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.confirmationService.close();
        this.loading = false;
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      },
    });
  }*/

  //CORSI
  getListaCorsi(pagina?, quantità?, ricerca?) {
    this.subscriptionSearchCorsi = this.scormService
      .getLista(pagina, quantità, ricerca)
      .subscribe((res) => {
        if (res.data.content.length > 0) {
          this.listaCorsi = res.data.content;
        } else {
          this.listaCorsi = [];
        }
        this.numeroMaxPagineCorsi = Number(res.data.totalPages);
        this.maxItemsCorsi = res.data.totalElements;
        this.itemsCorsi = res.data.numberOfElements;
        this.isClickableCorsi = true;
        this.paginatorCorsi = true;
        this.isSearchingOnCorsi = false;
        this.isSelectingValueCorsi = false;
      });
  }

  cambiaPaginaCorsi(event) {
    this.isClickableCorsi = false;
    this.paginaCorsi = event;
    this.getListaCorsi(this.paginaCorsi, this.selectedValueCorsi);
  }

  cambiaValueCorsi() {
    this.isClickableCorsi = false;
    this.paginatorCorsi = false;
    this.paginaCorsi = 0;
    this.isSelectingValueCorsi = true;
    this.getListaCorsi(this.paginaCorsi, this.selectedValueCorsi);
  }

  searchCorsi() {
    let valoreRicerca;
    this.paginaCorsi = 0;
    if (this.subscriptionSearchCorsi) {
      this.subscriptionSearchCorsi.unsubscribe();
    }
    this.isSearchingOnCorsi = true;
    valoreRicerca = this.searchformCorso.value.searchCorsi;
    if (valoreRicerca.length > 0) {
      this.getListaCorsi(
        this.paginaCorsi,
        this.selectedValueCorsi,
        valoreRicerca
      );
    } else {
      this.getListaCorsi(this.paginaCorsi, this.selectedValueCorsi);
    }
  }

  //CestinoCorsi
  cancellaCorsoCestino(id, stato?) {
    this.idRipristinaCorsi = id;
    this.statoCorsi = stato;
    let message = '';
    if (stato) {
      message =
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!';
    } else {
      message = 'Ripristinare il file?';
    }
    this.confirmationService.confirm({
      key: 'cancellaCorsoCestino',
      message: message,
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.confirmationService.close();
        this.loading = false;
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      },
    });
  }

  getListaCorsiCestino(pagina?, quantità?, ricerca?) {
    this.subscriptionSearchCestinoCorsi = this.scormService
      .getListaCestino(pagina, quantità, ricerca)
      .subscribe((res) => {
        if (res.data.content.length > 0) {
          this.listaCorsiCestino = res.data.content;
        } else {
          this.listaCorsiCestino = [];
        }
        this.numeroMaxPagineCestinoCorsi = Number(res.data.totalPages);
        this.maxItemsCestinoCorsi = res.data.totalElements;
        this.itemsCestinoCorsi = res.data.numberOfElements;
        this.isClickableCestinoCorsi = true;
        this.paginatorCestinoCorsi = true;
        this.isSearchingOnCestinoCorsi = false;
        this.isSelectingValueCestinoCorsi = false;
      });
  }

  cambiaPaginaCestinoCorsi(event) {
    this.isClickableCestinoCorsi = false;
    this.paginaCestinoCorsi = event;
    this.getListaCorsiCestino(
      this.paginaCestinoCorsi,
      this.selectedValueCestinoCorsi
    );
  }

  restoreCancellaCorsi() {
    this.loading = true;
    if (this.statoCorsi) {
      //cancella
      this.eliminaCorsoDefinitivo(this.idRipristinaCorsi);
      // this.subscriptionSearchCestinoCorsi = this.scormService.eliminazioneFisicaCorso(this.idRipristinaCorsi).subscribe((res) => {
      //   this.getListaCorsiCestino(this.paginaCestinoCorsi, this.selectedValueCestinoCorsi)
      //   this.getListaCorsi(this.paginaCorsi, this.selectedValueCorsi)
      //   this.confirmationService.close();
      //   this.loading=false;
      // },(error)=>{
      //   this.confirmationService.close();
      //   this.loading=false;

      // })
    } else {
      //ripristina
      this.recuperaCorso(this.idRipristinaCorsi);
      this.confirmationService.close();
      // this.subscriptionSearchCestinoCorsi = this.scormService.ripristinoDaCestino(this.idRipristinaCorsi).subscribe((res) => {
      //   this.getListaCorsiCestino(this.paginaCestinoCorsi, this.selectedValueCestinoCorsi)
      //   this.getListaCorsi(this.paginaCorsi, this.selectedValueCorsi)
      //   this.confirmationService.close();
      //   this.loading=false;
      // } ,(error)=>{
      //   this.confirmationService.close();
      //   this.loading=false;
      // })
    }
  }

  cambiaValueCestinoCorsi() {
    this.isClickableCestinoCorsi = false;
    this.paginatorCestinoCorsi = false;
    this.paginaCestinoCorsi = 0;
    this.isSelectingValueCestinoCorsi = true;
    this.getListaCorsiCestino(
      this.paginaCestinoCorsi,
      this.selectedValueCestinoCorsi
    );
  }

  listaToEmit = [];
  emitFileSelected(file) {
    this.True = true;
    this.False = false;
    let listaId = [];
    this.listaToEmit.forEach((el) => {
      listaId.push(el.id);
    });
    let index = listaId.indexOf(file.id);
    if (index === -1) {
      this.listaToEmit.push(file);
    } else {
      this.listaToEmit.forEach((el, index) => {
        if (el.id === file.id) {
          this.listaToEmit.splice(index, 1);
        }
      });
    }
    this.emitFilesSelected.emit(this.listaToEmit);
  }

  checkCheckbox(file) {
    if (this.disabled) {
      let listProducts = [];
      this.prodotti.forEach((element) => {
        listProducts.push(element.url);
      });
      return listProducts.indexOf(file.id);
    }
    let listaId = [];
    this.listaToEmit.forEach((el) => {
      listaId.push(el.id);
    });

    return listaId.indexOf(file.id);
  }

  searchCestinoCorsi() {
    let valoreRicerca;
    this.paginaCestinoCorsi = 0;
    if (this.subscriptionSearchCestinoCorsi) {
      this.subscriptionSearchCestinoCorsi.unsubscribe();
    }
    this.isSearchingOnCestinoCorsi = true;
    valoreRicerca = this.searchformCestinoCorsi.value.searchCorsiCancellati;
    if (valoreRicerca.length > 0) {
      this.getListaCorsiCestino(
        this.paginaCestinoCorsi,
        this.selectedValueCestinoCorsi,
        valoreRicerca
      );
    } else {
      this.getListaCorsiCestino(
        this.paginaCestinoCorsi,
        this.selectedValueCestinoCorsi
      );
    }
  }
  responsiveWidth() {}
  canWrite = false;
  updateCartella(cartellaRicevuta) {
    this.canWrite = cartellaRicevuta.data.can_write;
    cartellaRicevuta.data.childFolders.sort((a, b) =>
      a.folderName.localeCompare(b.folderName)
    );
    this.cartellaCorrente.nome = cartellaRicevuta.data.folder_name;
    cartellaRicevuta.data.childFolders.forEach((childFolder) => {
      let sottocartella: CartellaFiglia = new CartellaFiglia(
        childFolder.id,
        childFolder.folderName,
        childFolder.persistentFolder
      );
      this.cartellaCorrente.cartelle.push(sottocartella);
    });
    this.updateFile(cartellaRicevuta.data.files);
    this.cartellaCorrente.parentId = cartellaRicevuta.data.parent_id;
    this.cartellaCorrente.id = cartellaRicevuta.data.id;
    if (this.cartellaCorrente.id == this.idCartellaPersonale) {
      this.isPersonal = true;
    }
    this.cartellaCorrente.canWrite = cartellaRicevuta.data.can_write;
  }

  updateCartellaCestino(cartellaRicevuta) {
    this.cartellaCorrenteCestino.nome = cartellaRicevuta.data.folder_name;
    cartellaRicevuta.data.childFolders.forEach((childFolder) => {
      let sottocartella: CartellaFiglia = new CartellaFiglia(
        childFolder.id,
        childFolder.folderName,
        childFolder.persistentFolder
      );
      this.cartellaCorrenteCestino.cartelle.push(sottocartella);
    });
    this.updateFileCestino(cartellaRicevuta.data.files);
    this.cartellaCorrenteCestino.parentId = cartellaRicevuta.data.parent_id;
    this.cartellaCorrenteCestino.id = cartellaRicevuta.data.id;

    if (this.userRole == 1) {
      this.userIDs.forEach((userID) => {
        this.fileService.getTrashcanUserFolder(userID).subscribe((res) => {
          res.data.childFolders.forEach((childFolder) => {
            let sottocartella: CartellaFiglia = new CartellaFiglia(
              childFolder.id,
              childFolder.folderName,
              childFolder.persistentFolder
            );
            this.cartellaCorrenteCestino.cartelle.push(sottocartella);
          });
          this.updateFileCestino(res.data.files, true);
        });
      });
    }
    // if(this.cartellaCorrente.id == this.idCartellaPersonale)
    // {
    //   this.isPersonal = true;
    // }
  }

  updateFile(fileRicevuti) {
    this.listaFile = new Array();
    if (fileRicevuti) {
      this.carica = false;
      fileRicevuti.sort((a, b) => a.title.localeCompare(b.title));
      fileRicevuti.forEach((file) => {
        //let tmpFile:File = new File;
        //  tmpFile.id = file.id;
        // tmpFile.titolo = file.title;
        // tmpFile.link = file.link;
        // tmpFile.proprietario = file.owner;
        // tmpFile.estensione = file.extension;
        // tmpFile.data = file.uploadDate;
        // this.listaFile.push(tmpFile);
        this.listaFile.push(file);
      });
      // this.listaFile = fileRicevuti;
      // this.listaAvanzata = false;
      // this.allListaFile = this.listaFile;
      // this.numeroFile = this.listaFile.length
      // this.getNumeroPagine();
      // this.getNumeroPagineCancellati();
      // this.carica = true;
      // this.appoggioclick = 1;
      // this.appoggioclickCancellati = 1;
      // this.gestioneFile = true;
    }
    this.listaAvanzata = false;
    this.allListaFile = this.listaFile;
    //this.fileCancellati = res.data.eliminati;
    //this.allFileCancellati = this.fileCancellati
    this.numeroFile = this.listaFile.length;
    //this.numeroFileCancellati = this.allFileCancellati.length;
    //this.selectedValue = 10;
    //this.selectedValueCancellati = 10;
    this.getNumeroPagine();
    //this.getNumeroPagineCancellati();
    this.carica = true;
    this.appoggioclick = 1;
    this.appoggioclickCancellati = 1;
    this.gestioneFile = true;
  }

  updateFileCestino(fileRicevuti, skipReset = false) {
    if (!skipReset) {
      this.fileCancellati = new Array();
    }
    if (fileRicevuti) {
      this.carica = false;
      fileRicevuti.forEach((file) => {
        this.fileCancellati.push(file);
      });
    }
    this.listaAvanzata = false;
    this.allFileCancellati = this.fileCancellati;
    this.numeroFileCancellati = this.allFileCancellati.length;
    this.selectedValueCancellati = 10;
    this.getNumeroPagineCancellati();
    this.carica = true;
    this.appoggioclickCancellati = 1;
    // this.gestioneFile = true;
  }
  rememberPath = '';

  apriCartella(
    id: number,
    isBack = false,
    isNew = false,
    isFromElimina = false
  ) {
    setTimeout(() => {
      if (this.allowClickOpenFolder) {
        this.isAreaPersonale = false;
        this.loading = true;
        if (isBack && !isNew) {
          let str = '';
          let strArr = this.rememberPath.split('/');
          strArr.forEach((element, index) => {
            if (index != strArr.length - 1 && index != strArr.length - 2) {
              str = str + element + '/';
            }
          });
          this.rememberPath = str;
        }
        this.cartellaCorrente.cartelle = new Array();
        if (id == null) {
          if (!this.fromPrenotaTecnico) {
            this.getRoot();
          } else {
            this.alertService.error(
              'Non puoi accedere alla cartella di root da qui!'
            );
            this.fileService.getFolder(id).subscribe(
              (res) => {
                if (
                  !isBack &&
                  !isNew &&
                  this.cartellaCorrente.nome &&
                  !isFromElimina &&
                  this.alfaintes
                )
                  this.rememberPath =
                    this.rememberPath + this.cartellaCorrente.nome + '/';
                this.updateCartella(res);
                this.isRoot = false;
                this.backButtonLoading = false;
                this.allowClickOpenFolder = true;
                this.loading = false;
              },
              (error) => {}
            );
          }
        } else {
          this.fileService.getFolder(id).subscribe(
            (res) => {
              // let strArr = this.rememberPath.split('/')[this.rememberPath.split('/').length-1];
              if (
                !isBack &&
                !isNew &&
                this.cartellaCorrente.nome &&
                !this.indietroFlag &&
                !isFromElimina &&
                this.alfaintes
              )
                this.rememberPath =
                  this.rememberPath + this.cartellaCorrente.nome + '/';
              this.indietroFlag = false;
              this.updateCartella(res);
              this.isRoot = false;
              this.backButtonLoading = false;
              this.allowClickOpenFolder = true;
              this.loading = false;
            },
            (error) => {}
          );
        }
      } else {
        this.backButtonLoading = false;
      }
    });
  }

  apriCartellaCestino(id: number) {
    setTimeout(() => {
      if (this.allowClickOpenFolder) {
        this.cartellaCorrenteCestino.cartelle = new Array();
        if (id == null) {
          this.getRootCestino();
        } else {
          this.fileService.getTrashcanUserFolder(id).subscribe(
            (res) => {
              this.updateCartellaCestino(res);
              this.isRootCestino = false;
              this.backButtonLoading = false;
              this.allowClickOpenFolder = true;
            },
            (error) => {}
          );
        }
      }
    });
  }

  indietroCartelleCestino() {
    this.cartellaCorrenteCestino = this.cartellePrecedentiCestino.pop();
  }

  //Funzione usata in fase di testing
  /*getRoot()
  {
    let cartella: Cartella = new Cartella;
    cartella.nome = "Cartella principale";
    for(let i:number=0; i<3; i++)
    {
      cartella.cartelle[i] = new Cartella;
      cartella.cartelle[i].nome = "Cartella " + (i+1)
      cartella.cartelle[i].cartelle[0] = new Cartella;
      cartella.cartelle[i].cartelle[0].nome = "Cartella ancora più giù!"
    }
    return cartella;
  }*/

  getRoot() {
    this.cartellaCorrente.cartelle = new Array();
    this.isPersonal = false; //Serve a sapere se l'utente può caricare file e cartelle all'interno di una cartella.
    //Viene settato a vero nel momento in cui si entra nella cartella personale, e a false solo quando si accede alla Root.
    //Questo per preservare le possibilità di creazione anche all'interno delle sottocartelle della cartella personale.
    // if(this.userRole==1)        //Se l'utente è admin,
    // {
    //   this.getCartelleUtenti(); //ottiene tutte le cartelle utente
    // }
    // else
    // {
    //   this.getCartellaUtente(); //Viene ottenuta la cartella personale dell'utente
    // }
    //Ottenimento di tutte le altre cartelle delle quali si hanno i permessi
    this.fileService.getRoot().subscribe(
      (res) => {
        this.updateCartella(res);
        this.isRoot = true;
        this.backButtonLoading = false;
        this.allowClickOpenFolder = true;
        this.loading = false;
      },
      (error) => {}
    );
  }
  isAreaPersonale = false;
  apriAreaPersonale() {
    this.isRoot = false;
    this.isAreaPersonale = true;
    this.cartellaCorrente.cartelle = [];
    if (this.userRole == 1) {
      //Se l'utente è admin,
      this.getCartelleUtenti(); //ottiene tutte le cartelle utente
    } else {
      this.getCartellaUtente(); //Viene ottenuta la cartella personale dell'utente
    }
  }

  getRootCestino() {
    this.cartellaCorrenteCestino.cartelle = new Array();
    // //this.isPersonalCestino = false
    this.fileService.getTrashcanRoot().subscribe(
      (res) => {
        //Viene ottenuta la cartella cestino dell'utente
        this.updateCartellaCestino(res);
        this.isRootCestino = true;
        this.backButtonLoading = false;
        this.allowClickOpenFolder = true;
      },
      (error) => {}
    );
  }

  getCartellaUtente() {
    this.fileService.getUserFolder().subscribe(
      (res) => {
        let sottocartella: CartellaFiglia = new CartellaFiglia(
          res.data.id,
          res.data.folder_name,
          res.data.persistentFolder
        );
        this.idCartellaPersonale = res.data.id;
        this.cartellaCorrente.cartelle.push(sottocartella);
        this.loading = false;
      },
      (error) => {
        if (error.status == 404) {
          //Se l'utente non posiede una cartella personale
          this.confirmationService.confirm({
            key: 'creaCartella',
            message:
              'Non si dispone di una Cartella personale. Crearne una?' +
              '<br>' +
              " Attenzione: l'Amministratore avrà accesso a tutti i File condivisi in essa.",
            icon: 'pi pi-exclamation-triangle',
            reject: () => {
              this.loading = false;
              this.confirmationService.close();
            },
          });
        }
      }
    );
  }

  getCestinoUtente() {
    this.fileService.getTrashcanRoot().subscribe(
      (res) => {
        let sottocartella: CartellaFiglia = new CartellaFiglia(
          res.data.id,
          'Cestino',
          res.data.persistentFolder
        );
        //this.idCartellaPersonale = res.data.id;
        this.cartellaCorrenteCestino.cartelle.push(sottocartella);
      },
      (error) => {}
    );
  }

  accettaCreazione() {
    this.loading = true;
    this.fileService.createUserFolder().subscribe((res) => {
      this.confirmationService.close();
      this.loading = false;
      this.alertService.success(res.status.description);
      this.getRoot();
    });
  }

  getCartelleUtenti() {
    this.userIDs = new Array();
    this.fileService.getAllUserFolders().subscribe(
      (res) => {
        res.data.childFolders.forEach((folder) => {
          let sottocartella: CartellaFiglia = new CartellaFiglia(
            folder.id,
            folder.folderName,
            folder.persistentFolder
          );
          this.cartellaCorrente.cartelle.push(sottocartella);
          this.loading = false;
          this.userIDs.push(folder.userId);
        });
      },
      (error) => {}
    );
  }

  getIdUtenti() {
    this.userIDs = new Array();
    this.fileService.getAllUserFolders().subscribe(
      (res) => {
        res.data.childFolders.forEach((folder) => {
          this.userIDs.push(folder.userId);
        });
      },
      (error) => {}
    );
  }

  getCartelleUtentiCestino() {
    this.fileService.getAllUserFolders().subscribe(
      (res) => {
        res.data.childFolders.forEach((folder) => {
          let sottocartella: CartellaFiglia = new CartellaFiglia(
            folder.id,
            folder.folderName,
            folder.persistentFolder
          );
          this.cartellaCorrente.cartelle.push(sottocartella);
        });
      },
      (error) => {}
    );
  }

  allowClickOpenFolder = true;
  fileToModify;
  listUsers: any = [];
  nameFile;
  nameFolder;
  showTable = true;
  deleteFolder(id) {
    this.allowClickOpenFolder = false;
    this.idFile = id;
    this.confirmationService.confirm({
      key: 'cestinaCartella',
      message:
        'Sicuro di voler continuare? ' +
        '<br>' +
        ' La cartella verrà cestinata.',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.loading = false;
        this.confirmationService.close();
        this.allowClickOpenFolder = true;
      },
    });
  }

  isModifica = false;
  folders = [];
  rememberPathFolders = '';
  idDaModificare = -1;
  gruppi;
  loadingUser = false;
  editFolder(cartella) {
    this.showTable = false;
    this.nameFolder = cartella.nome;
    this.allowClickOpenFolder = false;
    if (this.nameFolder == '') {
      this.isModifica = false;
      this.idDaModificare = -1;
      this.allowClickOpenFolder = false;
      this.showAddModifyFolder = !this.showAddModifyFolder;
      if (this.userRole === 1)
        this.userService.getAll().subscribe(
          (res) => {
            this.userService.getListaGruppi().subscribe(
              (resGruppi) => {
                this.gruppi = resGruppi.data;
                let listaFiltrata = res.data.listaUtentiAttivi.filter(
                  (element) => {
                    return element.ruolo.id != 1;
                  }
                );
                this.listUsers = new MatTableDataSource(listaFiltrata);
                setTimeout(() => {
                  this.listUsers.paginator = this.paginatorMat;
                  this.showTable = true;
                  if (!this.alfaintes) {
                    this.isFinishedResizing = false;
                    setTimeout(() => {
                      let box = document
                        .getElementById('modalChangeFolder')
                        .getElementsByClassName('p-dialog-mask')[0]
                        .getElementsByClassName('p-dialog')[0];
                      let boxContent =
                        box.getElementsByClassName('p-dialog-content')[0];
                      boxContent.className += ' p-dialog-content-fullSize';
                      box.className += ' p-dialog-fullSize';
                      this.isFinishedResizing = true;
                    });
                  }
                }, 100);
              },
              (error) => {
                this.showAddModifyFolder = false;
                this.allowClickOpenFolder = true;
              }
            );
          },
          (error) => {
            this.showAddModifyFolder = false;
            this.allowClickOpenFolder = true;
          }
        );
    } else {
      this.showAddModifyFolder = !this.showAddModifyFolder;
      this.isModifica = true;
      this.idDaModificare = cartella.id;
      this.rememberPathFolders = this.rememberPath + this.cartellaCorrente.nome;
      this.fileService.getFolder(this.cartellaCorrente.id).subscribe(
        (res) => {
          this.folders = res.data.childFolders;
          if (!this.isRoot)
            this.folders.unshift({
              folderName: '..',
              id: res.data.parent_id,
            });
          this.folders.push({
            folderName: 'doNotShow',
            id: res.data.id,
          });
          if (
            (this.cartellaCorrente.id || this.isRoot) &&
            this.userRole === 1
          ) {
            this.loadingUser = true;
            this.userService.getAll().subscribe((res) => {
              this.userService.getListaGruppi().subscribe((resGruppi) => {
                this.fileService
                  .getPermission(cartella.id)
                  .subscribe((resPermission) => {
                    let listId = [];
                    resPermission.data.forEach((element) => {
                      listId.push(element.id);
                    });
                    res.data.listaUtentiAttivi.forEach((element, index) => {
                      if (listId.indexOf(element.id) !== -1) {
                        res.data.listaUtentiAttivi[index].visualizza = true;
                        if (
                          resPermission.data[listId.indexOf(element.id)]
                            .canWrite
                        ) {
                          res.data.listaUtentiAttivi[index].scrittura = true;
                        }
                      }
                    });
                    this.gruppi = resGruppi.data;
                    let listaFiltrata = res.data.listaUtentiAttivi.filter(
                      (element) => {
                        return element.ruolo.id != 1;
                      }
                    );
                    this.listUsers = new MatTableDataSource(listaFiltrata);
                    setTimeout(() => {
                      this.listUsers.paginator = this.paginatorMat;
                      this.loadingUser = false;
                      this.showTable = true;
                      if (!this.alfaintes) {
                        this.isFinishedResizing = false;
                        setTimeout(() => {
                          let box = document
                            .getElementById('modalChangeFolder')
                            .getElementsByClassName('p-dialog-mask')[0]
                            .getElementsByClassName('p-dialog')[0];
                          let boxContent =
                            box.getElementsByClassName('p-dialog-content')[0];
                          boxContent.className += ' p-dialog-content-fullSize';
                          box.className += ' p-dialog-fullSize';
                          this.isFinishedResizing = true;
                        });
                      }
                    }, 100);
                  });
              });
            });
          }
        },
        (error) => {
          this.allowClickOpenFolder = true;
          this.showAddModifyFolder = !this.showAddModifyFolder;
        }
      );
    }
  }

  createEditFolder() {
    this.step1 = true;
    let parentFolder = this.cartellaCorrente.id;
    if (this.isModifica) {
      parentFolder = this.folders[this.folders.length - 1].id;
    }
    let body = {
      folder_name: this.nameFolder,
      parent_id: parentFolder,
      permission: this.listaComandi,
    };
    if (this.isModifica) {
      this.fileService.createFolder(body, this.idDaModificare).subscribe(
        (res) => {
          this.listaComandi = [];
          this.allowClickOpenFolder = true;
          this.showAddModifyFolder = false;
          this.apriCartella(this.cartellaCorrente.id, false, true);
        },
        (error) => {
          this.allowClickOpenFolder = true;
          this.showAddModifyFolder = false;
        }
      );
    } else {
      this.fileService.createFolder(body).subscribe((res) => {
        this.allowClickOpenFolder = true;
        this.showAddModifyFolder = false;
        this.apriCartella(this.cartellaCorrente.id, false, true);
      });
    }
  }
  changeFolderModify(folder) {
    if (folder.id != null && folder.id != undefined) {
      this.fileService.getFolder(folder.id).subscribe((res) => {
        if (folder.folderName == '..') {
          let str = '';
          let strArr = this.rememberPathFolders.split('/');
          strArr.forEach((element, index) => {
            if (index != strArr.length - 1 && index != strArr.length - 2) {
              str = str + element + '/';
            }
          });
          this.rememberPathFolders = str;
        } else {
          this.rememberPathFolders =
            this.rememberPathFolders + '/' + folder.folderName;
        }
        this.folders = res.data.childFolders;
        this.folders.unshift({
          folderName: '..',
          id: res.data.parent_id,
        });
        this.folders.push({
          folderName: 'doNotShow',
          id: res.data.id,
        });
      });
    } else {
      this.fileService.getRoot().subscribe((res) => {
        if (this.userRole === 1) {
          this.fileService.getAllUserFolders().subscribe((res2) => {
            if (folder.folderName == '..') {
              let str = '';
              let strArr = this.rememberPathFolders.split('/');
              strArr.forEach((element, index) => {
                if (index != strArr.length - 1 && index != strArr.length - 2) {
                  str = str + element + '/';
                }
              });
              this.rememberPathFolders = str;
            } else {
              this.rememberPathFolders =
                this.rememberPathFolders + folder.folderName;
            }
            this.folders = res.data.childFolders;
            res2.data.childFolders.forEach((folder2) => {
              let sottocartella: any = {
                id: folder2.id,
                folderName: folder2.folderName,
              };
              this.folders.push(sottocartella);
            });
            // this.folders.unshift({folderName: '..', id: res.data.parentId});
          });
        } else {
          this.fileService.getUserFolder().subscribe((res2) => {
            if (folder.folderName == '..') {
              let str = '';
              let strArr = this.rememberPathFolders.split('/');
              strArr.forEach((element, index) => {
                if (index != strArr.length - 1 && index != strArr.length - 2) {
                  str = str + element + '/';
                }
              });
              this.rememberPathFolders = str;
            } else {
              this.rememberPathFolders =
                this.rememberPathFolders + folder.folderName;
            }
            this.folders = res.data.childFolders;
            res2.data.childFolders.forEach((folder2) => {
              let sottocartella: any = {
                id: folder2.id,
                folderName: folder2.folderName,
              };
              this.folders.push(sottocartella);
            });
            // this.folders.unshift({folderName: '..', id: res.data.parentId});
          });
        }
      });
    }
  }

  ripristinaCartella(id) {
    this.idRipristina = id;
    //this.stato = stato;
    this.confirmationService.confirm({
      key: 'ripristinaCartella',
      message: 'Ripristinare la cartella?',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.loading = false;
        this.confirmationService.close();
        //this.msgs = [{severity:'error', summary:'Rejected'}];
      },
    });
  }

  accettaRipristinoCartella() {
    this.loading = true;
    this.fileService.restoreFolder(this.idRipristina).subscribe(
      (res) => {
        this.apriCartellaCestino(this.cartellaCorrenteCestino.id);
        this.apriCartella(this.cartellaCorrente.id);
        this.listaAvanzata = false;
        //this.loading = false;
        this.confirmationService.close();
        this.alertService.success(res.status.description);
        this.activeTab = '#pill2'; //fix 1357
      },
      (error) => {}
    );
  }

  getCartellaOrfani() {
    this.fileService.getOrphans().subscribe((res) => {
      this.cartellaCorrente.cartelle = new Array();
      this.updateCartella(res);
      this.isRoot = false;
      this.backButtonLoading = false;
    });
  }

  pushCommand(tipoComando, id, canWrite, addRemove) {
    let item = this.listaComandi.find((x) => x.id === id);
    if (item) {
      if (item.type == 'REMOVE_USERS' && item.canWrite === true) {
        item.canWrite = false;
      } else {
        if (canWrite && addRemove) {
          this.listaComandi[this.listaComandi.indexOf(item)] = {
            type: tipoComando == 'USER' ? 'ADD_USERS' : 'ADD_GROUPS',
            id: id,
            canWrite: canWrite,
          };
        } else if (canWrite && !addRemove) {
          item.type === 'USER'
            ? (this.listaComandi[this.listaComandi.indexOf(item)] = {
                type: tipoComando == 'ADD_USER',
                id: id,
                canWrite: false,
              })
            : this.listaComandi.splice(this.listaComandi.indexOf(item), 1);
        } else if (!canWrite && addRemove) {
          if (this.listaComandi[this.listaComandi.indexOf(item)]) {
            this.listaComandi.splice(this.listaComandi.indexOf(item), 1);
          }
          this.listaComandi.push({
            type:
              tipoComando == 'USER'
                ? addRemove
                  ? 'ADD_USERS'
                  : 'REMOVE_USERS'
                : addRemove
                ? 'ADD_GROUPS'
                : 'REMOVE_GROUPS',
            id: id,
            canWrite: canWrite,
          });
        } else if (!canWrite && !addRemove) {
          //this.listaComandi.splice(this.listaComandi.indexOf(item), 1);
          this.listaComandi.push({
            type:
              tipoComando == 'USER'
                ? addRemove
                  ? 'ADD_USERS'
                  : 'REMOVE_USERS'
                : addRemove
                ? 'ADD_GROUPS'
                : 'REMOVE_GROUPS',
            id: id,
            canWrite: canWrite,
          });
        }
      }
    } else {
      let obj = {
        type:
          tipoComando == 'USER'
            ? addRemove
              ? 'ADD_USERS'
              : 'REMOVE_USERS'
            : addRemove
            ? 'ADD_GROUPS'
            : 'REMOVE_GROUPS',
        id: id,
        canWrite: canWrite,
      };

      if (obj.type === 'REMOVE_USERS' && obj.canWrite === true) {
        obj.type = 'ADD_USERS';
        obj.canWrite = false;
      }

      this.listaComandi.push(obj);
    }
  }

  accettaEliminaDefinitivamente() {
    this.loading = true;
    if (this.eliminaCartella) {
      this.fileService.pshysicalDeleteFolder(this.idFile).subscribe(
        (res) => {
          this.listaAvanzata = false;
          //this.loading = false;
          this.confirmationService.close();
          this.alertService.success(res.status.description);
          this.apriCartella(this.cartellaCorrente.id, false, false, true);
          this.apriCartellaCestino(this.cartellaCorrenteCestino.id);
          this.activeTab = '#pill2'; //fix 1357
        },
        (error) => {
          this.confirmationService.close();
          this.alertService.error(error.description);
        }
      );
    } else {
      this.fileService.pshysicalDeleteFile(this.idFile).subscribe(
        (res) => {
          this.listaAvanzata = false;
          //this.loading = false;
          this.confirmationService.close();
          this.alertService.success(res.status.description);
          this.apriCartella(this.cartellaCorrente.id, false, false, true);
          this.apriCartellaCestino(this.cartellaCorrenteCestino.id);
          this.activeTab = '#pill2'; //fix 1357
        },
        (error) => {}
      );
    }
  }

  accettaCestina() {
    this.fileService.deleteFile(this.idFile).subscribe(
      (res) => {
        this.listaAvanzata = false;
        this.loading = false;
        this.confirmationService.close();
        this.alertService.success(res.status.description);
        this.apriCartella(this.cartellaCorrente.id, false, true);
        this.apriCartellaCestino(this.cartellaCorrenteCestino.id);
      },
      (error) => {}
    );
  }

  accettaCestinaCartella() {
    this.fileService.deleteFolder(this.idFile).subscribe(
      (res) => {
        this.listaAvanzata = false;
        this.loading = false;
        this.confirmationService.close();
        this.alertService.success(res.status.description);
        this.apriCartella(this.cartellaCorrente.id);
        this.apriCartellaCestino(this.cartellaCorrenteCestino.id);
        this.allowClickOpenFolder = true;
      },
      (error) => {}
    );
  }
  checkIfGoToStep2() {
    if (!this.isRoot) {
      this.createEditFolder();
    }
  }

  ricercaFile() {
    // console.log(this.listaFile);
    this.listaFile = this.allListaFile;
  }

  changeDialogSize() {
    if (!this.alfaintes) {
    }
  }

  emitLink(idFile) {
    this.fileService.getUrlFile(idFile).subscribe((res) => {
      if (res.data.extension === 'jpg' || res.data.extension === 'png') {
        this.emitterFileInCR.emit(res.data.url);
      } else {
        this.alertService.error('Formato file non valido');
      }
    });
  }
}
