<div class="content" *ngIf="crea">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="form-horizontal">

                        <div class="card-header card-header-text" >
                            <h4 class="card-title text-center" *ngIf="addMode" >Aggiungi un file</h4>
                            <h4 class="card-title text-center" *ngIf="editMode" >Modifica un file</h4>

                        </div>

                        <div class="card-content">
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Nome File<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Titolo" formControlName="titolo"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.titolo.errors }" maxlength="50" />
                                        <div *ngIf="submitted && f.titolo.errors" class="invalid-feedback">
                                            <div *ngIf="f.titolo.errors.required">Campo nome file obbligatorio</div>
                                            <div *ngIf="f.titolo.errors.pattern">Campo nome file invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Categoria<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Categoria"
                                            formControlName="categoria" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.categoria.errors }"
                                            maxlength="30" />
                                        <div *ngIf="submitted && f.categoria.errors" class="invalid-feedback">
                                            <div *ngIf="f.categoria.errors.required">Campo categoria obbligatorio</div>
                                            <div *ngIf="f.categoria.errors.pattern">Campo categoria invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Gruppo<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Gruppo"
                                            formControlName="gruppo" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.gruppo.errors }"
                                            maxlength="30" />
                                        <div *ngIf="submitted && f.gruppo.errors" class="invalid-feedback">
                                            <div *ngIf="f.gruppo.errors.required">Campo gruppo obbligatorio</div>
                                            <div *ngIf="f.gruppo.errors.pattern">Campo gruppo invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <label class="col-sm-2 label-on-left" >Tipo di visibilità</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty char">

                                        <p-selectButton [options]="opzioni" [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}"></p-selectButton>

                                    </div>
                                </div></div>
                            <div class="row" *ngIf="selectedOption === '1'">
                                <label class="col-sm-2 label-on-left" >Seleziona i gruppi</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">

                                        <p-selectButton [options]="gruppi" formControlName="visibGruppo" multiple="multiple"></p-selectButton>

                                </div></div>
                            </div>

                            <div class="row" *ngIf="selectedOption === '2'">
                                <label class="col-sm-2 label-on-left" >Seleziona i profili</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty char">

                                        <p-selectButton [options]="ruoli" formControlName="visibRuolo" multiple="multiple"></p-selectButton>


                                </div></div>
                            </div>
                            <div class="row" *ngIf="selectedOption === '3'">
                                <label class="col-sm-2 label-on-left" >Seleziona gli utenti</label>
                            <div class="col-sm-10" >
                                <form [formGroup]="searchUtentiForm" class="ng-untouched ng-pristine ng-invalid">
                                    <input type="text" formControlName="search" placeholder="Cerca..." maxlength="28" style="margin-bottom: 2%; margin-top: 10px;" class="form-control ">
                                </form>
                                <div *ngIf="searchInUtenti()"></div>
                                <div class="material-datatables" style="border: 1px solid lightgray;
                        border-radius: 5px;">
                                <p-table class="table table-striped table-no-bordered table-hover" [value]="listaUtenti" [(selection)]="selectedUtenti" dataKey="email" [responsive]=true [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem" >

                                    <ng-template pTemplate="header" >
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>Nome</th>
                                            <th>Cognome</th>
                                            <th>Email</th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-user>
                                        <tr>
                                            <td style="width: 3rem">
                                                <p-tableCheckbox [value]="user" ></p-tableCheckbox>
                                            </td>
                                            <td>{{user.nome}}</td>
                                            <td>{{user.cognome}}</td>
                                            <td>{{user.email}}</td>
                                        </tr>

                                    </ng-template>

                                </p-table></div>
                            </div></div> -->
                            <div class="row" *ngIf="addMode">
                                <label class="col-sm-2 label-on-left" >Indicizzare il testo<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty char">

                                        <p-selectButton [options]="opzioniInd" [(ngModel)]="selectedOptionInd" [ngModelOptions]="{standalone: true}"></p-selectButton>
                                        <div *ngIf="submitted" class="invalid-feedback">
                                        <div *ngIf="!selectedOptionInd">Scelta obbligatoria</div>
                                    </div>
                                    </div>
                                </div></div>

                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Autore</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Autore"
                                                formControlName="autore" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.autore.errors }"
                                                maxlength="30" />
                                            <div *ngIf="submitted && f.autore.errors" class="invalid-feedback">

                                                <div *ngIf="f.autore.errors.pattern">Campo autore invalido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="addMode">
                                    <label class="col-sm-2 label-on-left" >Seleziona il file<span class="colorRed"> *</span></label>
                                    <div class="col-sm-10">
                                        <div>
                                            <input
                                            style="display: none"
                                            type="file"

                                            (change)="onFileChanged($event)"
                                            #fileInput>
                                          <button (click)="fileInput.click()"class="btn btn-secondary" type="button">Scegli File</button>
                                          <div class="img-space">



                                        </div>
                                    </div>
                                    <p *ngIf="nomeFile" style="margin-top: 8px" >{{nomeFile}}</p>
                                    <p *ngIf="!nomeFile && !submitted" style="margin-top: 8px">Nessun file selezionato</p>
                                    <p *ngIf="!nomeFile && submitted" style="margin-top: 8px; color: rgb(189, 0, 0); font-size: xx-small;">Campo File obbligatorio</p>

                                    </div></div>






                            <br>
                            <div class="text-right" style="margin-right: 5%;">
                                <hr>
                                <div class="btn-group" role="group" aria-label="Basic example">

                                    <button type="button" (click)="clear()" class="btn btn-secondary"
                                        aria-pressed="true">Indietro</button>
                                </div>
                                <div class="btn-group" role="group" aria-label="Basic example" aria-pressed="true">
                                    <button [disabled]="loading" type="sumbit" class="btn btn-primary" (click)="convalidaForm()">Salva
                                        <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                    </button>
                                </div>
                            </div>

                            <br>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
