import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService, EnvService } from '..';


@Injectable({
  providedIn: 'root'
})
export class NewsLetterService {
  baseUrl: string;
  constructor(private http: HttpClient, private accountService: AccountService, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }

   /** 
   * controllo paginazione
   * se tutto null richiede tutta la lista senza paginazione
   * @param page: numero pagina desiderirata
   * @param size: numero di quanti risultati vuoi 
   * @param ricerca: parametro per cercare su tutta la lista
  **/
  getListaNews(page?,size?,ricerca?,campo?,cardinalita?): Observable<any> {
    if((page || page == 0) && size && ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/lista_news?page='+page+'&size='+size+'&ricerca='+ricerca)
    }else if((page || page == 0) && size && !ricerca && campo){
      return this.http.get<any>(this.baseUrl + '/lista_news?page='+page+'&size='+size+'&campo='+campo+'&cardinalita='+cardinalita)
    }else if((page || page == 0) && size && !ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/lista_news?page='+page+'&size='+size)
    }else{
      return this.http.get<any>(this.baseUrl + '/lista_news')
    }
  }

  salvaNewsLetter(news): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/salva_news',news)
  }
  manda_json(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/manda_json?idNews='+id,null)
  }
  modifica_news(id,news): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_news?idNews='+id,news)
  }
  elimina_news(idNews): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/eliminazione_fisica_news?idNews='+idNews,null)
  }
  salva_remainder(idNews,remainder): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/salva_remainder?idNews='+idNews,remainder)
  }
  
 /** 
   * controllo paginazione
   * se tutto null richiede tutta la lista senza paginazione
   * @param page: numero pagina desiderirata
   * @param size: numero di quanti risultati vuoi 
   * @param ricerca: parametro per cercare su tutta la lista
  **/
  lista_remainder(idNews,page?,size?,ricerca?,campo?,cardinalita?): Observable<any> {
    if((page || page == 0) && size && ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/lista_remainder?idNews='+idNews+'&page='+page+'&size='+size+'&ricerca='+ricerca)
    }else if((page || page == 0) && size && !ricerca && campo){
      return this.http.get<any>(this.baseUrl + '/lista_remainder?idNews='+idNews+'&page='+page+'&size='+size+'&campo='+campo+'&cardinalita='+cardinalita)
    }else if((page || page == 0) && size && !ricerca && !campo){
      return this.http.get<any>(this.baseUrl + '/lista_remainder?idNews='+idNews+'&page='+page+'&size='+size)
    }else{
      return this.http.get<any>(this.baseUrl + '/lista_remainder?idNews='+idNews)
    }
  }

  eliminazione_fisica_remainder(idRemainder): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/eliminazione_fisica_remainder?idRemainder='+idRemainder,null)
  }
  modifica_remainder(idRemainder,remainder): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_remainder?idRemainder='+idRemainder,remainder)
  }
  manda_json_modificaRem(idRemainder): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/manda_json_modifica?idRemainder='+idRemainder,null)
  }
  info_remainder(idRemainder): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/info_remainder?idRemainder='+idRemainder)
  }
  info_news(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/info_news?idNews='+id)
  }
  visualizza_email(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/visualizza_email')
  }
  visualizza_email_prog(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/visualizza_email_prog')
  }

}