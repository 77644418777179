import { Component, OnInit } from '@angular/core';
import { ShareService } from 'src/app/SERVICES';
import { IOTService } from '../serviceIOT.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  telemetrie
  dataSourceGrafici = {
    labels: [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
      '23:59',
    ],
    datasets: [
      {
        label: 'Company1',
        data: [
          50, 60, 40, 80, 90, 70, 60, 50, 80, 90, 100, 80, 50, 40, 60, 60, 60,
          90, 80, 60, 30, 60, 50, 80, 75,
        ],
        borderColor: '#ea4c89',
        fill: false,
        lineTension: 0,
      },
    ],
  };
  optionsBPM = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 210,
            min: 30,
            stepSize: 30,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            max: '23:59',
            min: '00:00',
            stepSize: '60',
          },
          type: 'time',
          time: {
            format: 'HH:mm',
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm',
              min: '00:00',
              max: '23:59',
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      mode: 'single',
    },
  };
  showModalBattery = false;
  showModalHR = false;
  showModalBR = false;
  showModalMovements = false;
  showModalTemperature = false;
  showModalPression = false;
  constructor(private shareService: ShareService, public iotService: IOTService) {
    this.telemetrie = [
      {
        telemetria: 'HearthRate',
        icon: 'battery_charging_full'
      },
      {
        telemetria: 'BreathingRate',
        icon: 'battery_charging_full'
      },
      {
        telemetria: 'BreathingRate',
        icon: 'battery_charging_full'
      },
      {
        telemetria: 'BreathingRate',
        icon: 'battery_charging_full'
      },
      {
        telemetria: 'BreathingRate',
        icon: 'battery_charging_full'
      },
      {
        telemetria: 'BreathingRate',
        icon: 'battery_charging_full'
      }
    ]
  }

  ngOnInit(): void {
  }

}
