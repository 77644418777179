<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content">
            <div
              *ngIf="
                firstView &&
                !showForm &&
                showCalendar &&
                !showModifica &&
                !showInfo
              "
              style="padding: 20px"
            >
              <div class="row text-center">
                <div
                  class="flex"
                  [ngClass]="{
                    'col-xs-4 ': isDesktop,
                    'col-xs-12': !isDesktop
                  }"
                >
                  <div class="flex">
                    <div
                      id="btn-responsive"
                      class="btn btn-primary btn-oggi"
                      (click)="showModalSearch = true"
                    >
                      Ricerca
                    </div>
                  </div>
                  <div class="flex">
                    <div
                      class="btn btn-primary btn-arrow-left"
                      id="btn-responsive"
                      mwlCalendarPreviousView
                      [view]="view"
                      [(viewDate)]="viewDate"
                      (viewDateChange)="closeOpenMonthViewDay()"
                    >
                      <span class="arrows"> < </span>
                      <!-- <i class="material-icons notranslate"  style="font-size: 16px;">navigate_before</i> -->
                    </div>
                    <div
                      id="btn-responsive"
                      class="btn btn-primary btn-oggi"
                      mwlCalendarToday
                      [(viewDate)]="viewDate"
                    >
                      Oggi
                    </div>
                    <div
                      id="btn-responsive"
                      class="btn btn-primary btn-arrow-right"
                      mwlCalendarNextView
                      [view]="view"
                      [(viewDate)]="viewDate"
                      (viewDateChange)="closeOpenMonthViewDay()"
                    >
                      <span class="arrows">></span>
                      <!-- <i class="material-icons notranslate" style="font-size: 15px;">navigate_next</i> -->
                    </div>
                  </div>
                </div>
                <div
                  [ngClass]="{ 'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }"
                >
                  <h3>
                    {{
                      viewDate
                        | calendarDate : view + "ViewTitle" : "it"
                        | titlecase
                    }}
                  </h3>
                </div>
                <div
                  [ngClass]="{ 'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }"
                >
                  <div class="btn-group">
                    <div
                      class="btn btn-primary"
                      id="btn-responsive"
                      (click)="setView(CalendarView.Month)"
                      [class.active]="view === CalendarView.Month"
                    >
                      Mese
                    </div>
                    <div
                      class="btn btn-primary"
                      id="btn-responsive"
                      (click)="setView(CalendarView.Week)"
                      [class.active]="view === CalendarView.Week"
                    >
                      Settimana
                    </div>
                    <div
                      class="btn btn-primary"
                      id="btn-responsive"
                      (click)="setView(CalendarView.Day)"
                      [class.active]="view === CalendarView.Day"
                    >
                      Giorno
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div [ngSwitch]="view">
                <mwl-calendar-month-view
                  *ngSwitchCase="CalendarView.Month"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  [activeDayIsOpen]="activeDayIsOpen"
                  (dayClicked)="dayClicked($event.day)"
                  (eventTimesChanged)="eventTimesChanged($event)"
                  locale="it"
                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                  *ngSwitchCase="CalendarView.Week"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  locale="it"
                  (eventTimesChanged)="eventTimesChanged($event)"
                  (hourSegmentClicked)="dayClicked($event)"
                  [dayStartHour]="costanteInizio"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                  *ngSwitchCase="CalendarView.Day"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  locale="it"
                  (eventTimesChanged)="eventTimesChanged($event)"
                  (hourSegmentClicked)="dayClicked($event)"
                  [dayStartHour]="costanteInizio"
                >
                </mwl-calendar-day-view>
              </div>
            </div>
            <div *ngIf="showInfo">
              <app-info-chiamata [idDaVisualizzare]="idDaVisualizzare" (indietroEmit)="indietroInfo()"></app-info-chiamata>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  (onHide)="resetValue()"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="showModalSearch"
  [closeOnEscape]="true"
  modal="true"
  [dismissableMask]="true"
>
  <h5 class="centered">Vuoi applicare un filtro?</h5>

  <div class="centered mw-750 flex" *ngIf="!farmaciaSelector">
    <div class="flex mt-10 w-100">
      <div>
        <button
          class="btn btn-outline saveButton"
          [ngClass]="{ 'btn-primary': filtroObj.soloPrecedenti }"
          (click)="
            filtroObj = {
              active: false,
              idFarmacia: filtroObj.idFarmacia,
              da: null,
              a: null,
              soloPrecedenti: !filtroObj.soloPrecedenti,
              soloProssime: false
            }
          "
        >
          Solo precedenti
        </button>
      </div>
      <div class="ml-auto">
        <button
          class="btn saveButton"
          [ngClass]="{ 'btn-outline': !filtroObj.soloProssime }"
          (click)="
            filtroObj = {
              active: false,
              idFarmacia: filtroObj.idFarmacia,
              da: null,
              a: null,
              soloPrecedenti: false,
              soloProssime: !filtroObj.soloProssime
            }
          "
        >
          Solo prossime
        </button>
      </div>
    </div>
  </div>
  <div class="centered mw-750 flex">
    <app-farmacie-list
      *ngIf="farmaciaSelector"
      (farmaciaSelectedEmit)="
        filtroObj.idFarmacia = $event.id;
        farmaciaSelected = $event;
        farmaciaSelector = false;
        stampa($event)
      "
    ></app-farmacie-list>
  </div>
  <div class="centered mw-750 flex" *ngIf="!farmaciaSelector">
    <!--
    <mat-form-field appearance="" (click)="filtroObj.active = false;">
      <mat-label>Farmacia</mat-label>
      <mat-select [(ngModel)]="filtroObj.idFarmacia" name="farmacia">
        <mat-option [value]="null">Nessuno</mat-option>
        <mat-option *ngFor="let farmacia of farmacieList" [value]="farmacia.id">
          {{farmacia.ragioneSocialeFarmacia}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <div>
      <button class="btn btn-primary" (click)="farmaciaSelector = true">
        Seleziona Farmacia
      </button>
      <div
        *ngIf="farmaciaSelected && farmaciaSelected.ragione_Sociale_Farmacia"
      >
        {{ farmaciaSelected.ragione_Sociale_Farmacia }}
      </div>
    </div>
    <div class="ml-auto">
      <mat-form-field appearance="fill" (click)="filtroObj.active = false">
        <mat-label>Inserisci periodo di tempo</mat-label>
        <mat-date-range-input [rangePicker]="picker" disabled>
          <input
            [(ngModel)]="filtroObj.da"
            matStartDate
            placeholder="Data d'inizio"
          />
          <input
            [(ngModel)]="filtroObj.a"
            matEndDate
            placeholder="Data di fine"
          />
        </mat-date-range-input>
        <mat-hint>GG/MM/AAAA – GG/MM/AAAA</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <p-footer>
    <div class="flex mt-10">
      <button class="btn saveButton" (click)="showModalSearch = false">
        Annulla
      </button>
      <button
        class="btn btn-primary saveButton ml-auto"
        *ngIf="!filtroObj.active"
        (click)="
          filtroObj.active = true;
          showModalSearch = false;
          getEvents(startOfMonth, endOfMonth)
        "
      >
        Salva
      </button>
      <button
        class="btn btn-primary saveButton ml-auto"
        *ngIf="filtroObj.active"
        (click)="
          filtroObj = {
            active: false,
            idFarmacia: null,
            da: null,
            a: null,
            soloPrecedenti: false,
            soloProssime: false
          };
          showModalSearch = false;
          getEvents(startOfMonth, endOfMonth)
        "
      >
        Elimina Filtro
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  [(visible)]="showModal"
  [closeOnEscape]="true"
  modal="true"
  (onHide)="resetValue()"
  [dismissableMask]="true"
>
  <h5 class="centered">Vuoi aggiungere una chiamata?</h5>

  <div class="centered mw-750 flex" style="justify-content: center;">
    <app-farmacie-list
      *ngIf="farmaciaSelector"
      (farmaciaSelectedEmit)="
        selectedValue = $event.id;
        farmaciaSelected = $event;
        farmaciaSelector = false;
        stampa($event)
      "
    ></app-farmacie-list>
  </div>
  <div class="centered mw-750 flex" *ngIf="!farmaciaSelector">
    <!-- <mat-form-field appearance="">
      <mat-label>Farmacia</mat-label>
      <mat-select [(ngModel)]="selectedValue" name="farmacia">
        <mat-option *ngFor="let farmacia of farmacieList" [value]="farmacia.id">
          {{farmacia.ragioneSocialeFarmacia}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <div>
      <button class="btn btn-primary" (click)="farmaciaSelector = true">
        Seleziona Farmacia
      </button>
      <div
        *ngIf="farmaciaSelected && farmaciaSelected.ragione_Sociale_Farmacia"
      >
        {{ farmaciaSelected.ragione_Sociale_Farmacia }}
      </div>
    </div>
    <div class="ml-auto verticalCenter">
      <span>Dalle:</span><input [(ngModel)]="startInputDate" type="time" />
      <span>Alle:</span><input [(ngModel)]="startEndingDate" type="time" />
    </div>
  </div>
  <p-footer>
    <div class="flex mt-10">
      <button class="btn saveButton" (click)="showModal = false">
        Annulla
      </button>
      <button class="btn btn-primary saveButton ml-auto" (click)="saveFiles()">
        Salva
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  [(visible)]="showModalModify"
  [closeOnEscape]="true"
  modal="true"
  (onHide)="resetValue()"
  [dismissableMask]="true"
  (onHide)="lockRimanda = false; showRimanda = false"
>
  <h5 class="centered">Compila la seguente chiamata</h5>
  <div *ngIf="!showRimanda && !lockRimanda">
    <div class="flex mt-10">
      <button class="btn saveButton" (click)="showFarmacia = true">
        Dati Farmacia
      </button>
    </div>
    <app-farmacie-add
      (goBackAdd)="backAddFarmacia($event)"
      [inputForm]="listaInputFarmacia"
      *ngIf="showFarmacia"
    >
    </app-farmacie-add>
    <div *ngIf="!showFarmacia">
      <div class="centered mw-750 flex">
        <mat-form-field appearance="">
          <mat-label>Ordine</mat-label>
          <mat-select [(ngModel)]="selectedOrdine" name="Ordine">
            <mat-option [value]="''"> Nessuno </mat-option>
            <mat-option
              *ngFor="let ordine of listaOrdini"
              [value]="ordine.nomeOrdine"
            >
              {{ ordine.nomeOrdine }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="" class="ml-auto">
          <mat-label>Ricerca Di Mercato</mat-label>
          <mat-select [(ngModel)]="selectedRicerca" name="Ricerca">
            <mat-option [value]="''"> Nessuno </mat-option>
            <mat-option
              *ngFor="let ricerca of listaMercato"
              [value]="ricerca.nome"
            >
              {{ ricerca.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="centered flex">
        <mat-form-field appearance="">
          <mat-label>Modalità comunicazione</mat-label>
          <mat-select
            [(ngModel)]="selectedComunicazione"
            name="Modalità comunicazione"
          >
            <mat-option
              *ngFor="let comunicazione of listaComunicazione"
              [value]="comunicazione.id"
            >
              {{ comunicazione.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="campagnaScelta && campagnaScelta.domande_Associate.length == 0"
      >
        Nessuna campagna trovata.
      </div>
      <div
        *ngIf="campagnaScelta && campagnaScelta.domande_Associate.length > 0"
      >
        <h5 class="centered">Compila la seguenti domande</h5>
      </div>
      <div *ngIf="campagnaScelta">
        <div
          *ngFor="let item of campagnaScelta.domande_Associate; let i = index"
        >
          <div class="flex">
            <mat-form-field class="w-100">
              <mat-label>{{ item.domanda }}</mat-label>
              <input
                matInput
                [placeholder]="item.domanda"
                [(ngModel)]="item.risposta"
              />
            </mat-form-field>

            <!-- <mat-form-field class="w-45 ml-auto" *ngIf="campagnaScelta && campagnaScelta.domande_Associate[i+1]">
              <mat-label>{{campagnaScelta.domande_Associate[i+1].domanda}}</mat-label>
              <input matInput [placeholder]="campagnaScelta.domande_Associate[i+1].domanda"
                [(ngModel)]="campagnaScelta.domande_Associate[i+1].risposta">
            </mat-form-field> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showRimanda" class="centered mw-750 flex">
    <mat-form-field>
      <input
        matInput
        type="datetime-local"
        placeholder="data e orario inizio"
        [(ngModel)]="dateInizioRimando"
      />
    </mat-form-field>
    <mat-form-field class="ml-auto">
      <input
        matInput
        type="time"
        placeholder="orario fine"
        [(ngModel)]="dateFineRimando"
      />
    </mat-form-field>
  </div>
  <p-footer *ngIf="!showFarmacia">
    <div class="flex mt-10">
      <button
        class="btn btn-primary saveButton"
        *ngIf="showRimanda && !lockRimanda"
        (click)="showRimanda = false"
      >
        Indietro
      </button>
      <button
        class="btn btn-primary saveButton"
        *ngIf="showRimanda"
        (click)="elimina()"
      >
        Elimina
      </button>
      <button
        class="btn btn-primary saveButton"
        *ngIf="!showRimanda"
        (click)="showRimanda = true"
      >
        Rimanda
      </button>
      <button
        class="btn btn-primary saveButton ml-auto"
        (click)="saveChiamata()"
      >
        Salva
      </button>
    </div>
  </p-footer>
</p-dialog>
