import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, ShareService } from 'src/app/SERVICES';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';

@Component({
  selector: 'app-iot-layout',
  templateUrl: './iot-layout.component.html',
  styleUrls: ['./iot-layout.component.scss']
})
export class IotLayoutComponent implements OnInit, OnDestroy,DoCheck {
  alarmView : boolean = false;
  configView : boolean = false;
  ruolo;
  constructor(private env : EnvService, private router : Router, private shareService : ShareService,
    private accountService : AccountService) {
   
   }
  ngDoCheck(): void {
    if (this.accountService.listaEnv && !this.env.IOT) {
      this.router.navigate(['./'])
    }
  }
  ngOnDestroy(): void {
    this.shareService.changePaginaAllarmi(false)
  }

  ngOnInit(): void {
    this.ruolo = this.accountService.ruolo
    this.shareService.changePaginaAllarmi(true)
  }

  goConfigView(){
    this.alarmView = false;
    this.configView = true;
    this.shareService.changePaginaAllarmi(false)
  }

  goAlarmView(){
    this.alarmView = true;
    this.configView = false;
    
  }

  switch(bool : boolean){
    this.configView=false;
    if(bool){
      this.alarmView=true;
   
    }
    if(!bool){
      this.alarmView=false;
    }
    this.shareService.changePaginaAllarmi(bool)
  }
}
