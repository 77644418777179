<div class="flex" [ngClass]="{'disabled':!isClickable}">
    
    <span class="material-icons numero" (click)="changePage(currentPage-1)">
        arrow_back_ios
    </span>

    <span class="numero" [ngClass]="{'active':currentPage===1}" [ngStyle]="{'background-color':currentPage===1?colore:'transparent','border-color':currentPage===1?colore:'transparent'}" (click)="changePage(1)">1</span>
    <span class="numero" (click)="changePage(2)" *ngIf="((currentPage === 1 || currentPage === 2 || currentPage === 3) || (currentPage === 4 && pageSize === 4)) && pageSize > 1" [ngClass]="{'active':currentPage===2}" [ngStyle]="{'background-color':currentPage===2?colore:'transparent','border-color':currentPage===2?colore:'transparent'}">2</span>
    <span class="numero" (click)="changePage(3)" *ngIf="((currentPage === 1 || currentPage === 2 || currentPage === 3) || (currentPage === 4 && pageSize === 4)) && pageSize > 2" [ngClass]="{'active':currentPage===3}" [ngStyle]="{'background-color':currentPage===3?colore:'transparent','border-color':currentPage===3?colore:'transparent'}">3</span>
    
    <span class="numero" (click)="changePage(currentPage-1)" *ngIf="currentPage>3 && (currentPage === pageSize-2) && larghezzaSchermo >= 385 && pageSize !== 4 && pageSize !== 6" >...</span>
    <span class="numero" (click)="changePage(currentPage-1)" *ngIf="currentPage>3 && (currentPage !== pageSize-2 || pageSize === 6) && larghezzaSchermo >= 385 && pageSize !== 4" >...</span>
    <span class="numero" (click)="changePage(pageSize-3)" *ngIf="currentPage===pageSize-2 && pageSize > 5" >{{pageSize-3}}</span>

    <span class="numero" (click)="changePage(currentPage-1)" *ngIf="currentPage !== 1 && currentPage !== 2 && currentPage !== 3 && currentPage !== pageSize && currentPage !== pageSize-1 && currentPage !== pageSize-2">{{currentPage-1}}</span>
    <span class="numero active" [ngStyle]="{'background-color':colore,'border-color':colore}" *ngIf="currentPage !== 1 && currentPage !== 2 && currentPage !== 3 && currentPage !== pageSize && currentPage !== pageSize-1 && currentPage !== pageSize-2">{{currentPage}}</span>
    <span class="numero" (click)="changePage(currentPage+1)" *ngIf="currentPage !== 1 && currentPage !== 2 && currentPage !== 3 && currentPage !== pageSize && currentPage !== pageSize-1 && currentPage !== pageSize-2">{{currentPage+1}}</span>

    <span class="numero" (click)="changePage(currentPage + 1)" *ngIf="currentPage < pageSize-2 && currentPage!==3 && larghezzaSchermo >= 385 && pageSize !== 4" >...</span>
    <span class="numero" (click)="changePage(4)" *ngIf="currentPage===3 && pageSize > 4" >4</span>
    <span class="numero" (click)="changePage(currentPage + 1)" *ngIf="currentPage===3 && pageSize > 4 && pageSize !== 5" >...</span>

    <span class="numero" (click)="changePage(pageSize-2)" *ngIf="(currentPage === pageSize || currentPage === pageSize-1 || currentPage === pageSize-2) && ((pageSize > 5 || currentPage === 5 || currentPage === 4) && pageSize !== 4)" [ngClass]="{'active':currentPage===pageSize-2}" [ngStyle]="{'background-color':currentPage===pageSize-2?colore:'transparent','border-color':currentPage===pageSize-2?colore:'transparent'}">{{pageSize-2}}</span>
    <span class="numero" (click)="changePage(pageSize-1)" *ngIf="(currentPage === pageSize || currentPage === pageSize-1 || currentPage === pageSize-2) && ((pageSize > 5 || currentPage === 5 || currentPage === 4) && pageSize !== 4)" [ngClass]="{'active':currentPage===pageSize-1}" [ngStyle]="{'background-color':currentPage===pageSize-1?colore:'transparent','border-color':currentPage===pageSize-1?colore:'transparent'}">{{pageSize-1}}</span>
    <span class="numero" (click)="changePage(pageSize)"   *ngIf="pageSize > 3"  [ngClass]="{'active':currentPage===pageSize}" [ngStyle]="{'background-color':currentPage===pageSize?colore:'transparent','border-color':currentPage===pageSize?colore:'transparent'}">{{pageSize}}</span>

    <span class="material-icons numero frecciaDestra" (click)="changePage(currentPage+1)">
        arrow_back_ios
    </span>

</div>